import React, { createRef } from 'react'
import './ReleaseList.css'
import cn from 'classnames'

import Button from '../Button/Button'
import Search from '../Search/Search'
import Icon from '../Icon2/Icon'

interface ReleaseType {
  title: string
  isLocked?: Boolean
  isExpandable?: Boolean
  selected?: Boolean
}

interface ReleaseListProps {
  releases: ReleaseType[]
}

const ReleaseList = (props: ReleaseListProps) => {
  const { releases } = props
  return (
    <div className="ReleaseList">
      <Button className="ReleaseList__CTA">Create New Release</Button>
      <Search placeholder="3 Releases" width="full" />
      <div className="ReleaseList__list">
        {releases &&
          releases.map((release, index) => {
            const { title, isLocked, isExpandable, selected } = release
            return (
              <div className="ReleaseList__release" key={index}>
                <div className={cn('ReleaseList__release-title', { selected: selected })}>
                  {title}
                </div>
                {(isLocked || isExpandable) && (
                  <div className="ReleaseList__release-icons">
                    <div className="ReleaseList__icon">
                      {isLocked && <Icon icon="Lock" className="lock-icon" />}
                    </div>
                    <div className="ReleaseList__icon">
                      {isExpandable && (
                        <Icon width={12} height={12} icon="ChevronRight" className="expand-icon" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

ReleaseList.defaultProps = {} as Partial<ReleaseListProps>

export default ReleaseList
