import React, { Component } from 'react'
import Icon from '../Icon2'
import './DragDropFile.css'

type MyProps = {
  handleDrop: Function
  dragFlag: boolean
  folderName?: string
  dragOverlayContent?: string
  dragDropFileHandler: Function
  hideDragOverlayContent?: boolean
}
type MyState = {
  drag: boolean
}

class DragDropFile extends Component<MyProps, MyState> {
  dragCounter: number = 0
  state: MyState = {
    drag: false
  }
  dropRef: any = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }
  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    // this.setState({ drag: false })
    if (this.dragCounter === 0) {
      this.setState({ drag: false })
      this.props.dragDropFileHandler(this.state.drag)
    }
  }
  handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }

  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  render() {
    const { dragFlag, dragOverlayContent, folderName, hideDragOverlayContent } = this.props;
    const { drag } = this.state;
    return (
      <div
        className="dragDropFileWrapper"
        style={{
          position: 'relative',
          display: drag || dragFlag ? 'inline-block' : 'none'
        }}
        ref={this.dropRef}>
        {drag || dragFlag ? (
          <div className="OverlayDropWrapper">
            <div className="DropWrapper">
              <div className="dropBox">
                {
                  !hideDragOverlayContent && (
                    <p>
                      <span>
                        <Icon icon="UploadIcon" />
                      </span>
                      <span className="content">{dragOverlayContent} {' '}
                        <b> {folderName ? folderName : 'Assets'}</b> instantly 
                      </span>
                    </p>
                  )
                }
              </div>
            </div>
          </div>
        ) : null}
        {this.props.children}
      </div>
    )
  }
}
export default DragDropFile
