import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from '../style.module.css'
import { H6Icon } from '../../../utils/Icon'
import { HeadingIcon } from '../../../utils/Icon'
export * from './deserializer'
export const H6 = (props) => {
  const { className } = props.attrs || {}
  return (
    <div {...props.attrs} className={styles['heading']}>
      <h6 {...props.attributes} className={cx(
        className,
        css`padding-top: var(--scrte-padding-top, 0px);`
      )}>
        {props.children}
      </h6>
    </div>
  )
}

Registry.register(
  {
    Component: H6,
    // iconName: 'ri-h-6',
    slashIconName: <H6Icon />,
    slashTitle: 'Heading 6',
    title: <h6 className={styles['h6']}>Heading 6</h6>,
    subtitle: 'Add heading level 6',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+6' },
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['heading six', 'heading 6']
  },
  { id: 'h6', registry: 'v2.element' }
)
