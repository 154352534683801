import React, { createRef, useState } from 'react';
import { Icon, } from '../../../../../../../index'
import styles from './View/index.module.css';
import './index.css';

import { createFolder } from '../../request';
import { NewFolderIcon, NewFolderIconLarge, NewTabIcon } from '../../../../../../RichTextEditor/SuperChargedRte/elements/utils/Icon';

const CreateFolder = ({ editor, folderUid, type = 'list', setUploading }) => {
  const [state, setState] = useState({
    folderName: 'New Folder',
    isDisable: true,
  });
  const inputRef: any = createRef();
  const handleEdit = (e) => {
    e.preventDefault();
    if (!state.isDisable) return;
    inputRef.current.focus();
    setState(state => ({ ...state, isDisable: false, folderName: '' }));
  }

  const handleChange = (e) => {
    e.persist();
    setState(state => ({ ...state, [e.target.name]: e.target.value }));
  }

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState(state => ({ ...state, isDisable: true, folderName: 'New Folder' }))
  }

  const handleOk = (e) => {
    e.preventDefault();
    e.stopPropagation();
    folderUid = folderUid === 'cs_root' ? '' : folderUid;
    setUploading(state => ({ ...state, uploading: true }));
    createFolder({ ...editor.requestProps, parentUid: folderUid, folderName: state.folderName })
      .then(res => setUploading(state => {
        let assets = state.assets;
        assets.splice(1, 0, res.asset)
        setState(state => ({ ...state, isDisable: true, folderName: 'New Folder' }))
        return { ...state, assets: assets, uploading: false }
      }))
      .catch(err => { });
  }

  return (

    type === 'list' ? (
      <div onClick={handleEdit} style={{ display: 'flex', alignItems: 'center' }}>
        <span className={styles['list-icon']}>
          <NewFolderIcon />
        </span>
        <input ref={inputRef} className='new-folder-input' autoFocus={!state.isDisable} placeholder="Enter folder name" value={state.folderName} onChange={handleChange} name='folderName' />
        {!state.isDisable &&
          (
            <React.Fragment>
              <Icon style={{ marginRight: '0.5rem' }} icon='CancelCircle' onClick={handleCancel}></Icon>
              <Icon icon='CheckedCircle' onClick={handleOk}></Icon>
            </React.Fragment>
          )}
      </div>
    ) : (
        <div onClick={handleEdit} id='create-folder-grid-card' className={`create-folder-asset-card`}>
          <div className={`flex-v-center flex-h-center create-folder-asset-body`}>
            {state.isDisable ? <NewFolderIconLarge /> :
              (
                <div style={{ textAlign: 'right' }}>
                  <Icon size='large' style={{ marginRight: '0.5rem' }} icon='CancelCircle' onClick={handleCancel}></Icon>
                  <Icon size='large' icon='CheckedCircle' onClick={handleOk}></Icon>
                </div>
              )}
          </div>
          <div className='create-folder-asset-description'>
            <input autoComplete='false' ref={inputRef} style={{ 'textAlign': state.isDisable ? 'center' : 'left' }} className={`new-folder-input full-width`} autoFocus={!state.isDisable} placeholder="Enter folder name" value={state.folderName} onChange={handleChange} name='folderName' />
          </div>
        </div>
      )


  )
}


export default CreateFolder;