import React, { useEffect, useState, FocusEventHandler, memo } from 'react';
import { useRef, useMemo, useLayoutEffect } from 'react';
import { cx } from '@emotion/css';

import { getSanitizingConverter, Editor, Extra } from './lib/node-pagedown';
import { Toolbar } from './Toolbar';
import { PreviewIcon } from './assets/svg'
import styles from './styles.module.css';
import { EditorProvider, IEditorProvider } from './EditorProvider';
import './styles.css'
import commands from './utils/commands';
import { useEditorResize } from '../../../utils/hooks';
import { resizerSvgString } from './assets/svg';

export type PagedownProps = {
  className?: string;
  disabled?: boolean;
  name?: string;
  defaultValue?: string;
  onUpdate?: (value: string) => void;
  requestProps: Record<any, string>;
}

const ExtraToolbarComponent = ({ togglePreview, isPreview }) => {
  return (
    <span
      className={styles['semibold']}
      onMouseDown={(e) => {
        e.preventDefault()
      }}
    >
      <span
        data-testid="preview-icon"
        onClick={togglePreview}
        className={cx("cs-toolbar-btn", isPreview && 'cs-toolbar-btn--active')}
      >
        <PreviewIcon className={styles['mr-5']} /> Preview
      </span>
    </span>
  )
}

const PagedownEditor = memo((props: PagedownProps) => {  
  const editor = useRef<typeof Editor>();
  const editorRef = useRef<HTMLTextAreaElement | null>(null);
  const markdownRef = useRef<HTMLDivElement | null>(null);
  const previewRef = useRef<HTMLDivElement | null>(null);
  const editorResizeRef = useRef(null)
  const [initValue, setInitValue] = useState('')
  const [isPreview, setPreview] = useState(false)

  const postfix = useMemo(generatePostfix, []);

  useLayoutEffect(() => {
    const converter = getSanitizingConverter();
    editor.current = new Editor(converter, postfix);

    // add onUpdate func to editor
    editor.current.onUpdate = props.onUpdate

    // init extra options for converter (table, tab space etc.)
    Extra.init(converter)
    editor.current.run();
    editor.current.requestProps = props.requestProps

    // to add resize icon to div
    const resizer = editorResizeRef.current;
    resizer.innerHTML = resizerSvgString
    // resizer.replaceWith(resizer.cloneNode(true))
    editorResizeRef.current = resizer
  }, []);

  // @todo This probably doesn't trigger on button actions, dialogs, etc.
  // @todo We should do some debouncing here as we're listening to all
  // keyup, change and input events.
  function onAction(e) {
    if (props.onUpdate && editorRef.current) {
      props.onUpdate(editorRef.current.value);
    }
    // for keyup
    if (e.shiftKey && !e.ctrlKey && !e.metaKey) {
      const keyCode = e.charCode || e.keyCode;
      // Character 13 is Enter
      if (keyCode === 13) {
        const state = new editor.current.TextareaState(editor.current.panels)
        if (!state) {
          return;
        }
        const chunks = state.getChunks();
        const fixupInputArea = function () {

          editor.current.panels.input.focus();

          if (chunks) {
            state.setChunks(chunks);
          }

          state.restore();
          editor.current.previewManager.refresh();
        };

        // const noCleanup = editor.commandManagerProto[id](chunks, fixupInputArea, editor.commandManager.getString);
        const noCleanup = commands['doAutoindent'](chunks, fixupInputArea, editor.current);
        if (!noCleanup) {
          fixupInputArea();
        }
      }
    } else if (e.key === "Tab") { // to add tab indent on pressing TAB key
      e.preventDefault();

      const { selectionStart, selectionEnd } = e.target;

      editorRef.current.value =
        editorRef.current.value.substring(0, selectionStart) + "  " + editorRef.current.value.substring(selectionEnd);

      editorRef.current.setSelectionRange(
        selectionStart + 2,
        selectionStart + 2
      );
    }
  }

  const showHidePreviewDiv = () => {
    if (editorRef.current.style.display === 'none') {
      editorRef.current.style.display = 'block'
      previewRef.current.style.display = 'none'
    } else {
      editorRef.current.style.display = 'none'
      previewRef.current.style.display = 'block'
    }
  }

  const togglePreview = () => {
    setPreview(!isPreview)
    showHidePreviewDiv()
  }

  const handleFocus = (e) => {
    markdownRef.current.classList.add('active');
  }

  const handleBlur = (e) => {
    markdownRef.current.classList.remove('active');
  }

  const editorContext: IEditorProvider = useMemo(() => {
    return {
      editorContext: editor.current
    }
  }, [editor.current])

  useEffect(() => {
    setInitValue(props.defaultValue)
  }, [props.defaultValue])

  useEffect(() => {
    // to load preview the first time
    editor.current.refreshPreview()
  }, [initValue])

  useEditorResize(editorResizeRef, false);

  return (
    <>
      <div className={'cs-markdown-editor'} ref={markdownRef} data-testid="markdown-wrapper">
        <EditorProvider.Provider value={editorContext}>
          <Toolbar
            isPreview={isPreview}
            className="cs-markdown-toolbar"
            extra={<ExtraToolbarComponent isPreview={isPreview} togglePreview={togglePreview} />}
            isAssetPublic={props.requestProps?.['isAssetPublic']}
            postfix={postfix}
            editorRef={editorRef}
          />
        </EditorProvider.Provider>
        <div className={styles['wmd-container']} id="wmd-container">
          <textarea
            data-testid="wmd-input"
            ref={editorRef}
            id={"wmd-input" + postfix}
            className={styles["wmd-input"]}
            name={props.name}
            disabled={props.disabled}
            onInput={onAction}
            onChange={onAction}
            onKeyUp={onAction}
            onKeyDown={onAction}
            defaultValue={initValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <div
            data-testid="wmd-preview"
            ref={previewRef}
            id={"wmd-preview" + postfix}
            className={styles["wmd-preview"]}
            contentEditable={false}
          />
        </div>
        <div className="editor-resize" ref={editorResizeRef}></div>
      </div>
    </>
  )
})

function randomWithin(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

function generatePostfix() {
  return "-" + Math.floor(randomWithin(10000, 99999)).toString(16);
}

export default PagedownEditor