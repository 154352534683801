import React, { RefObject, useContext, useEffect, useRef, useState } from 'react'
import { cx } from '@emotion/css'
import ResizeObserver from 'resize-observer-polyfill'

import { useRteFullScreen } from 'utils'
import { IDiscussionContext, IEditorRequestProps, IEntryMetadata, IFieldMetadata, IMentionList, IMessageDTO, IStackMetadata, IUserState } from '../../utils/types'
import { DiscussionProvider, EditorProvider } from '../ContextProvider'
import Popover from '../Popover'
import DiscussionBody from './DiscussionBody'
import DiscussionFooter from './DiscussionFooter'
import DiscussionHeader from './DiscussionHeader'
import styles from './style.module.css'
import { getUserName, isNewDiscussion, checkIsRangeComment, getTargetDOM } from './utils'
import { handlePopupPosition } from './utils/handlePopupPosition'
import { failureNotification, fetchBatchMessagesForDiscussion, IfetchBatchMessagesForDiscussion } from './utils/request'
import { useSlate } from 'slate-react'

interface IDiscussionPopup {
  activeDiscussion: string,
  docUID: string,
  scrollRef: RefObject<HTMLDivElement>,
  editorMetadata: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  stackMetadata: IStackMetadata,
  fieldMetadata: IFieldMetadata,
  setDiscussions: Function,
  setActiveDiscussion: Function,
  removeDiscussion: (blockUID: string) => void
}

interface DiscussionPopupState {
  isLoading: boolean
  newDiscussion: boolean
  blockUID?: string
  commentCount: number
  comments: Array<IMessageDTO>
  userState: IUserState
  editComment: string
}
const scrollOffset = 3
const DiscussionPopup: React.FC<IDiscussionPopup> = ({ activeDiscussion, docUID, scrollRef, editorMetadata, fieldMetadata, entryMetadata, stackMetadata, setDiscussions, setActiveDiscussion, removeDiscussion }) => {
  const popupRef = useRef<HTMLDivElement>(null)
  const handleOnSave = useRef(null)
  // Network state
  const [fetchingState, setFetchingState] = useState({
    creatingNewDiscussion: false,
    fetchingMoreComments: false
  })
  const [error, setError] = useState({ hasError: false, message: "" })
  const [state, setState] = useState<DiscussionPopupState>({
    isLoading: true,
    newDiscussion: true,
    blockUID: "",
    commentCount: 0,
    comments: [],
    editComment: "",
    userState: { mentionsList: [], currentUser: stackMetadata?.currentUser, userMap: {}, roleMap: {} }
  })
  // TODO : Set range comment properly
  const isRangeComment = checkIsRangeComment(activeDiscussion || '')
  const editor = useSlate()

  const handlePosition = (options = { animate: false }, isNewDiscussion = false) => {
    const popupDOM = popupRef.current
    if (popupDOM && activeDiscussion) {
      if (options.animate) { // set true to add animation
        popupRef.current.classList.add('scrte-discussion-smooth-animation')
        setTimeout(() => {
          popupRef.current.classList.remove('scrte-discussion-smooth-animation')
        }, 700)
      }
      const targetDiscussionDOM = getTargetDOM(activeDiscussion, isNewDiscussion)
      if(targetDiscussionDOM){
        handlePopupPosition(popupDOM, targetDiscussionDOM, editorMetadata, isNewDiscussion, isRangeComment)
      }
    } else {
      popupDOM.setAttribute('style', '')
    }
  }
  const handleOutsideClick = (event: any) => {
    // @ts-ignore
    editor.createRangeCommentSelection = null
    if(event.target.parentElement.hasAttribute('data-discussion-uid')) return
    if(popupRef.current && popupRef.current.contains(event.target)) return
      setActiveDiscussion(null)
    
  }
  const loadMoreMessages = (skip: number, limit: number, callback: (res: IfetchBatchMessagesForDiscussion) => void) => {
    return fetchBatchMessagesForDiscussion(editorMetadata, activeDiscussion, entryMetadata.contentTypeUid, entryMetadata.entryUid, entryMetadata.locale, skip, limit)
      .then(callback)
      .catch((error) => {
        // TODO: If discussion is resolved, update discussion state
        failureNotification(error?.data?.error_message || "Error while fetching comments.", error?.data?.errors)
      })
  }
  useEffect(() => {
    const commentListContainer = document.getElementById('scrte-discussion-comment--list');
    if (!commentListContainer) {
      return () => { }
    }
    function scrollEvent() {
      if (commentListContainer && commentListContainer.scrollHeight + commentListContainer.scrollTop - commentListContainer.clientHeight < scrollOffset && !fetchingState.fetchingMoreComments) {
        if (state.commentCount > state.comments.length) {
          setFetchingState((prevState) => ({ ...prevState, fetchingMoreComments: true }))
          loadMoreMessages(state.comments.length, 10, (res) => {
            setState((prevState) => ({
              ...prevState,
              comments: [...prevState.comments, ...res.conversations]
            }))
          })
            .finally(() => {
              setFetchingState((prevState) => ({ ...prevState, fetchingMoreComments: false }))
            })
        }
      }
    }
    commentListContainer.addEventListener('scroll', scrollEvent, true)
    return () => {
      commentListContainer.removeEventListener('scroll', scrollEvent, true)
    }
  }, [state.commentCount, state.comments.length, fetchingState.fetchingMoreComments])

  useEffect(() => {
    const newDiscussion = isNewDiscussion(activeDiscussion || '')
    handlePosition({ animate: false }, newDiscussion)
    // fetch all comments for discussion
    if (activeDiscussion) {
      if (scrollRef?.current) {
        scrollRef.current.classList.add('scroll-hidden')
      }

      let blockUID = ""
      if(!isRangeComment){
        const targetDiscussionDOM: HTMLDivElement = document.querySelector(`[data-discussion-uid="${activeDiscussion}"]`)
        blockUID = targetDiscussionDOM.getAttribute('data-discussion-block-uid')
      }
      else{
        setState({...state, isLoading: true})
      }

      if (!newDiscussion && !fetchingState.creatingNewDiscussion) {
        loadMoreMessages(0, 10, (res) => {
          setState({
            newDiscussion,
            blockUID,
            isLoading: false,
            commentCount: res.count,
            comments: res.conversations,
            userState: state.userState,
            editComment: ""
          })
        })
      } else {
        setState({
          newDiscussion, blockUID, isLoading: false, commentCount: 0, comments: [], userState: state.userState, editComment: ""
        })
      }
    } else {
      if (scrollRef?.current) {
        scrollRef.current.classList.remove('scroll-hidden')
      }
      setState({ newDiscussion: true, blockUID: "", isLoading: true, commentCount: 0, comments: [], userState: state.userState, editComment: "" })
    }
    setFetchingState((prevState) => ({ ...prevState, fetchingMoreComments: false }))
  }, [activeDiscussion])

  useEffect(() => {
    const userList: Array<IMentionList> = []
    const userMap = {}
    const roleMap = {}
    if (stackMetadata?.users?.length) {
      stackMetadata.users.forEach((user) => {
        if (user?.active) {
          const userName = getUserName(user)
          userList.push({
            display: userName,
            id: user.uid,
            uid: user.uid,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            username: user.username
          })
          userMap[user.uid] = { ...user, display: userName }
        }
      })
    }
    if (stackMetadata?.roles?.length) {
      stackMetadata.roles.forEach((role) => {
        userList.push({
          display: role.name,
          id: role.uid,
          uid: role.uid
        })
        roleMap[role.uid] = role
      })
    }
    setState((prevState) => ({ ...prevState, userState: { mentionsList: userList, userMap, roleMap, currentUser: stackMetadata?.currentUser } }));
  }, [])

  // Reposition on overlay height change
  useEffect(() => {
    if (activeDiscussion && popupRef.current) {
      const observer = new ResizeObserver(_entries => {
        handlePosition({ animate: false })
      })
      observer.observe(popupRef.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [activeDiscussion])

  const context: IDiscussionContext = {
    docUID: docUID,
    editorMetadata: editorMetadata,
    entryMetadata: entryMetadata,
    fieldMetadata: fieldMetadata,
    setDiscussions: setDiscussions,
    stackMetadata: stackMetadata,
    newDiscussion: state.newDiscussion,
    blockUID: state.blockUID,
    discussionUID: activeDiscussion,
    userState: state.userState,
    commentCount: state.commentCount,
    error,
    setError: setError,
    setDiscussionState: setState,
    setFetchingState: setFetchingState,
    editComment: state.editComment,
    setActiveDiscussion
  }
  return (
    <Popover
      ref={popupRef}
      data-testid='scrte-discussion-popup'
      className={cx('scrte-discussion--popup', styles['scrte-discussion--popup'])}
      handleOutsideClick={handleOutsideClick}
    >
      <DiscussionProvider.Provider value={context}>
        <div className='scrte-discussion--wrapper'>
          <DiscussionHeader
            editor={editor}
            commentCount={state.commentCount}
            discussionUID={activeDiscussion}
            entryMetadata={entryMetadata}
            requestProps={editorMetadata}
            isLoading={state.isLoading}
            displayResolve={!state.newDiscussion}
            setActiveDiscussion={setActiveDiscussion}
            removeDiscussion={removeDiscussion}
            blockUID={state.blockUID}
          />
          <DiscussionBody
            isLoading={state.isLoading}
            handleOnSave={handleOnSave}
            userState={state.userState}
            comments={state.comments}
            fetchingMore={fetchingState.fetchingMoreComments}
            editComment={state.editComment}
            editorMetadata={editorMetadata}
          />
          <DiscussionFooter setActiveDiscussion={setActiveDiscussion} isDisabled={error.hasError} handleOnSave={handleOnSave} editComment={state.editComment} />
        </div>
      </DiscussionProvider.Provider>
    </Popover>
  )
}

export default DiscussionPopup