import React from 'react'
import { useSlate } from 'slate-react'
import { Registry } from '@react-registry'
import { isNodeAligned, alignNode } from '../utils'
import { elementCategories } from '../../elementCategories'
import { CenterAlignmentIcon } from '../../../utils/Icon'

export const ShortcutAlignmentCenter = (event, editor, format, registryObj) => {
  alignNode(editor, 'center')
}

export const AlignCenter = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    alignNode(editor, 'center')
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isNodeAligned(editor, 'center')
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <CenterAlignmentIcon />,
    category: elementCategories.ALIGNMENT_DROPDOWN,
    shortcut: { key: { win: 'mod+alt+e', mac: 'cmd+ctrl+e' }, callback: ShortcutAlignmentCenter },
    title: 'Center',
    template: '',
    IngressComponent: AlignCenter,
    isContentStackElement: true,
    inBasicToolbar: true,
    canCustomize: false
  },
  { id: 'center-align', registry: 'element' }
)
