import { Registry } from '@react-registry'

export const SocialEmbeds = (el) => {
  let src;
  const iframe = el.querySelector('iframe');
  const facebook = iframe?.getAttribute?.('data-testid')
  const tiktok = el.querySelector('blockquote')
  if (facebook) {
    if (facebook === 'fb:post Facebook Social Plugin')
      if (el.querySelector('.fb-post')) {
        src = el.querySelector('.fb-post').getAttribute('data-href')
      }
  }
  if (tiktok) {
    if (tiktok.getAttribute('class') === 'tiktok-embed')
      src = tiktok.getAttribute('cite')
  }
  return {
    type: 'social-embeds',
    attrs: {
      src: src ? src : iframe.getAttribute('src')
    },
    children: [
      {
        text: ''
      }
    ]
  }
}

Registry.register(SocialEmbeds, { id: 'SocialEmbeds', registry: 'v2.deserializer' })
