//@ts-nocheck
import React, { useEffect } from 'react'
import Tooltip from '../Tooltip'
import { css, cx } from '@emotion/css'
//@ts-ignore
import styles from './style.module.css'

const config = {
  placement: 'bottom',
  arrow: false,
  offset: [0, 0],
  delay: [300, 0],
  duration: [0, 0],
  hideOnClick: true,
  theme: 'small',
}

export declare interface DndIconTyes extends React.HTMLAttributes<HTMLSpanElement> { }
export const DndIcon = React.forwardRef((props: DndIconTyes, ref) => {
  return (
    <Tooltip
      content={<span className={styles['draggable-tooltip']}>Drag to move</span>}
      config={config}
    >
      <span
        data-testid="dnd-icon"
        ref={ref}
        className={cx(css`
          display: flex;
          align-items: center;
        `, styles['dnd-icon'])}
      >
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M8.5 9C9.32843 9 10 9.67157 10 10.5C10 11.3284 9.32843 12 8.5 12C7.67157 12 7 11.3284 7 10.5C7 9.67157 7.67157 9 8.5 9Z"
              fill="#647696"
            />
            <path
              d="M10 6C10 5.17157 9.32843 4.5 8.5 4.5C7.67157 4.5 7 5.17157 7 6C7 6.82843 7.67157 7.5 8.5 7.5C9.32843 7.5 10 6.82843 10 6Z"
              fill="#647696"
            />
            <path
              d="M8.5 0C9.32843 -3.62117e-08 10 0.671573 10 1.5C10 2.32843 9.32843 3 8.5 3C7.67157 3 7 2.32843 7 1.5C7 0.671573 7.67157 3.62117e-08 8.5 0Z"
              fill="#647696"
            />
            <path
              d="M3.5 9C4.32843 9 5 9.67157 5 10.5C5 11.3284 4.32843 12 3.5 12C2.67157 12 2 11.3284 2 10.5C2 9.67157 2.67157 9 3.5 9Z"
              fill="#647696"
            />
            <path
              d="M5 6C5 5.17157 4.32843 4.5 3.5 4.5C2.67157 4.5 2 5.17157 2 6C2 6.82843 2.67157 7.5 3.5 7.5C4.32843 7.5 5 6.82843 5 6Z"
              fill="#647696"
            />
            <path
              d="M3.5 0C4.32843 -3.62117e-08 5 0.671573 5 1.5C5 2.32843 4.32843 3 3.5 3C2.67157 3 2 2.32843 2 1.5C2 0.671573 2.67157 3.62117e-08 3.5 0Z"
              fill="#647696"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </Tooltip>
  )
})
