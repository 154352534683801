import React, { useState } from 'react'
import { components } from 'react-select'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import Checkbox from '../Checkbox/Checkbox'
import ContentEditor from './ContentEditor'
import FieldLabel from '../FieldLabel/FieldLabel'
import SkeletonTile from '../SkeletonTile/SkeletonTile'

const SingleValue = (props) => {
  const options = props.getValue()
  const { updateOption, ...rest } = props
  return (
    <components.SingleValue {...rest}>
      {options.map((option: any, index: number) => {
        return <ContentEditor key={index} value={option.label} option={option} updateOption={updateOption} />
      })}
    </components.SingleValue>
  )
}

const MultiValueLabel = (props: any) => {
  const { updateOption, ...rest } = props
  return (
    <components.MultiValueLabel {...rest}>
      <ContentEditor value={rest.data.label} option={rest.data} updateOption={updateOption} />
    </components.MultiValueLabel>
  )
}

export const SkeletonLoader = ({ size = 2, isMulti = false }) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              {isMulti && (
                <SkeletonTile
                  numberOfTiles={1}
                  tileHeight={16}
                  tileWidth={16}
                  tileRadius={4}
                  tileBottomSpace={10}
                  tileTopSpace={10}
                  tileleftSpace={20}
                />
              )}
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={12}
                tileWidth={isMulti ? 105 : 145}
                tileRadius={6}
                tileBottomSpace={12}
                tileTopSpace={12}
                tileleftSpace={isMulti ? 12 : 20}
              />
            </div>
          )
        })}
    </div>
  )
}

const getValueContainer = ({
  showAllSelectedItems: showAll,
  setShowAllSelectedItems: setShowAll,
  multiDisplayLimit,
  isValueContainer
}: {
  showAllSelectedItems: boolean
  setShowAllSelectedItems: React.Dispatch<React.SetStateAction<boolean>>
  multiDisplayLimit: number
  isValueContainer: boolean
}) => {
  return ({ children, hasValue, ...props }) => {
    if (!hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>
    }

    const [selectedItems, otherChildren] = children
    const itemsToDisplay = showAll ? selectedItems : selectedItems.slice(0, multiDisplayLimit)
    const overflowCounter = selectedItems.length - multiDisplayLimit

    return (
      <components.ValueContainer {...props}>
        <div className="Select__value-container--custom">
          {itemsToDisplay}
          {!showAll && overflowCounter > 0 && (
            <button
              className="Select__see-more-button"
              id="see-more-button"
              onClick={() => {
                setShowAll(true)
              }}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              {`+${overflowCounter} More`}
            </button>
          )}
          {showAll && overflowCounter > 0 && (
            <button
              className="Select__see-less-button"
              id="see-less-button"
              onClick={() => {
                setShowAll(false)
              }}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              {`See Less`}
            </button>
          )}
          {otherChildren}
        </div>
      </components.ValueContainer>
    )
  }
}

const MenuList = wrapMenuList((props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.isLoading && <SkeletonLoader size={2} isMulti={props.isMulti} />}
    </components.MenuList>
  )
})

const Option = (props: any) => {
  if (props.isMulti) {
    return (
      <components.Option {...props}>
        <Checkbox checked={props.isSelected} text={props.children} fullWidth={true} />
        {/* {props.children} */}
      </components.Option>
    )
  }
  return <components.Option {...props} />
}

const customComponents = ({
  updateOption,
  canEditOption,
  isMulti,
  multiDisplayLimit,
  showAllSelectedItems,
  setShowAllSelectedItems
}: any) => {
  if (canEditOption) {
    return {
      SingleValue: (props: any) => <SingleValue {...props} updateOption={updateOption} />,
      MultiValueLabel: (props: any) => <MultiValueLabel {...props} updateOption={updateOption} />,
      Option,
      MenuList
    }
  }
  if (isMulti && multiDisplayLimit) {
    return {
      Option,
      ValueContainer: getValueContainer({
        showAllSelectedItems,
        setShowAllSelectedItems,
        multiDisplayLimit,
        isValueContainer: true
      }),
      MenuList
    }
  }
  return {
    Option,
    MenuList
  }
}

type LoadMoreArgsProps = {
  search: string
  skip: number
  limit: number
  prevOptions: any
}

type InitArrayProps = {
  label: String
  value: String
}

export type LoadMoreOptReturnProps = {
  data: any
  hasMore: boolean
}

export type SelectProps = {
  width?: string
  maxWidth?: string
  minWidth?: string
  name?: string
  isDisabled?: boolean
  isClearable?: boolean
  isMulti?: boolean
  isSearchable?: boolean
  placeholder?: string
  hideSelectedOptions?: boolean
  menuShouldScrollIntoView?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  menuIsOpen?: boolean
  maxMenuHeight?: number
  minMenuHeight?: number
  onChange: Function
  onBlur?: Function
  value: any
  canEditOption?: boolean
  selectLabel?: string
  updateOption?: (UpdateOptionProps: { label: any; id: any }) => void
  limit?: number
  loadMoreOptions: (loadmoreArgs: LoadMoreArgsProps) => LoadMoreOptReturnProps
  // totalCounts: number
  initialOptions?: Array<InitArrayProps>
  defaultOptions?: boolean
  error?: boolean
  debounceTimeout?: number
  multiDisplayLimit?: number
  version?: string
}

const AsyncSelectBox: React.FunctionComponent<SelectProps> = (props) => {
  let limit = props.limit || 10
  let controlledMultidisplayLimit = props.multiDisplayLimit || 2
  const [showAllSelectedItems, setShowAllSelectedItems] = useState(false)

  const loadOptions = async (search: any, prevOptions: any) => {
    try {
      let skip = prevOptions.length
      const response: any = await props.loadMoreOptions({ search: search || '', skip, limit, prevOptions })
      return {
        options: response.options,
        hasMore: response.hasMore
      }
    } catch (error) {
      console.log('loadOptions error', error)
    }
  }

  const allComponents = customComponents({
    updateOption: props.updateOption,
    canEditOption: props.canEditOption,
    isMulti: props.isMulti,
    multiDisplayLimit: controlledMultidisplayLimit,
    showAllSelectedItems: showAllSelectedItems,
    setShowAllSelectedItems: setShowAllSelectedItems
  })

  let widthStyleObj: any = {}
  if (props.width) {
    widthStyleObj = { width: props.width }
  } else {
    widthStyleObj = { maxWidth: props.maxWidth || '500px', minWidth: props.minWidth || '200px' }
  }

  return (
    <div
      data-test-id="async-select-test"
      className={`Select ${props.error ? 'Select--error' : ''} ${props.version === 'v2'? 'Select__v2' : ''} `}
      style={{ ...widthStyleObj }}>
      {props.selectLabel && <FieldLabel htmlFor="selectLabel">{props.selectLabel}</FieldLabel>}
      <AsyncPaginate
        name={props.name}
        closeMenuOnSelect={!props.isMulti}
        isDisabled={props.isDisabled || false}
        isClearable={props.isClearable || false}
        isMulti={props.isMulti || false}
        multiDisplayLimit={props.multiDisplayLimit}
        isSearchable={props.isSearchable || false}
        placeholder={props.placeholder || 'Select ...'}
        hideSelectedOptions={props.hideSelectedOptions || false}
        closeMenuOnScroll={true}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={props.menuShouldScrollIntoView}
        menuPlacement={props.menuPlacement}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={props.maxMenuHeight || 200}
        minMenuHeight={props.minMenuHeight}
        onChange={props.onChange}
        // onInputChange={handleInputChange}
        onBlur={props.onBlur}
        value={props.value}
        components={allComponents}
        backspaceRemovesValue={props.canEditOption ? false : true}
        loadOptions={loadOptions}
        defaultOptions={typeof props.defaultOptions === 'boolean' ? props.defaultOptions : true}
        classNamePrefix="Select"
        loadingMessage={() => ''}
        options={props.initialOptions}
        aria-label={'cs-async-select-aria'}
        debounceTimeout={props.debounceTimeout}
      />
    </div>
  )
}

export default React.memo(AsyncSelectBox)
