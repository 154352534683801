import React, { Component, useState } from 'react'

import Select from '../Select/Select'
import Icon from '../Icon2/Icon'

import AutoComplete from './AutoComplete'
import { AdvanceQueryDropdown } from './AdvanceSearchQuery'
import SavedViews from './SavedViews'
import SearchBar from './SearchBar'
import { AdvanceSearchBody, AdvanceSearchFooter } from './SearchBodyFooter'
import SearchTooltip from './SearchTooltip'
import TagRemoveTooltip from './TagRemoveTooltip'
import SearchPortal from './SearchPortal'
import Connector from './Connector'
import './AdvanceSearch.css'

import {
  options,
  iconName,
  defaultTextContent,
  switchMatchAllAny,
  stringOperators,
  tooltipWrapperId,
  portalId,
  inputTextShadow
} from './util/constants'

import { setCounterForAdvanceQuery, getQueryType, validateBasicQuery, isEmptyFilters, truncate } from './util/helpers'
import { languageLabel, localizedInLabel } from './util/entries'
import { getFiltersBySelectedOption, filterQueryObjectBuilder, getAdvanceQueryByFilters, checkSearchFilterValue } from './SearchFilter/utils'
import { SearchContext } from './searchContext'

import {
  updateSelectedContentTypeStatus,
  validateAdvanceQuery,
  buildQueryObjectForAdvCase,
  formatOlduiEntriesQueries
} from './util/advanceQueryEntries'

import {
  queryBuilder,
  basicQueryParser,
  maxSuggetsedItemsCount,
  maxSavedSearchesCount,
  notify,
  getOperatorTextContent,
  advanceQueryParser,
  getQueryTypeAndParseQuery,
} from './util/common'

import { buildRecentSearches } from './util/recentSearchHelper'

const CustomDropdownIndicator = () => {
  return <Icon icon={iconName.DropdownDark} className="AdvancedSearch__category-icon"></Icon>
}

const FilterInput = (props) => {
  const [type, setType] = useState('text')

  const focusInput = () => {
    if (props.filterInputRef?.current) {
      props.filterInputRef.current.focus()
    }
  }

  const inputValueLength = props.inputValue.length
  const modulePlaceholder = props.selectedOption.placeholder.split('spacebar')

  // show module placeholder if suggestion dropdown not open
  const modulePlaceholderCheck = !props.isAutoCompleteOpen && (<>{modulePlaceholder[0]}<strong>spacebar</strong>{modulePlaceholder[1]}</>)
  //if input value is not too long show using mirrorSpan else using input itself
  const mirrorPlaceholderCheck = props.inputValue ? inputValueLength <= 20 ? true : false : props.activeSelection || modulePlaceholderCheck
  return (
    <div className="AdvancedSearch__current-query">
      <div className="AdvancedSearch__input-wrapper">
        <input
          style={{ textShadow: inputValueLength > 20 ? inputTextShadow : '' }}
          disabled={type === 'tagLabelCase'}
          type={type}
          value={props.inputValue}
          className="AdvancedSearch__query-input"
          name="query-input"
          onChange={props.updateInputValue}
          onInput={props.openAutoComplete}
          ref={props.filterInputRef}
          autoComplete="off"
          onKeyDown={props.handleKeyDown}
          aria-label="input-search-query"
        // onKeyUp={props.handleKeyDown}
        />
        {mirrorPlaceholderCheck && (
          <span
            className={`AdvancedSearch__query-autocomplete ${props.inputValue ? 'AdvancedSearch__query-autocomplete-mirror' : ''
              }`}
            onClick={focusInput}
          >
            {props.activeSelection || props.inputValue || modulePlaceholderCheck}
          </span>
        )}
      </div>

      {props.isAutoCompleteOpen && (
        <AutoComplete
          resetInputValue={props.resetInputValue}
          inputValue={props.inputValue}
          closeAutoComplete={props.closeAutoComplete}
          selectedOption={props.selectedOption.value}
          fetchData={props.fetchData}
          setPlaceholder={props.setPlaceholder}
          isAutoCompleteOpen={props.isAutoCompleteOpen}
          case={props.case}
          isResetInput={props.isResetInput}
          suggestedSyntaxCase={props.suggestedSyntaxCase}
          setInputType={setType}
          inputType={type}
          ref={props.autoCompleteRef}
        />
      )}

      {props.isAdvanceQueryDropdownOpen && (
        <AdvanceQueryDropdown
          onClickMatchAnyOrAll={props.onClickMatchAnyOrAll}
          closeAdvQueryModal={props.closeAdvQueryModal}
        />
      )}
    </div>
  )
}

const localizedData = {
  key: [{ label: 'Localized In', value: '$localization' }],
  operator: [stringOperators[0]],
  value: [{ label: 'Any Locales', value: [{ label: 'Any Locales', value: '$any' }] }],
  queryType: 'localizedInLabel',
  nextQuery: 'inLocalized',
  isQueryCompleted: true,
}

const setQueryArray = (searchQueryData = {}, queryArrayState = []) => {
  const { queryArray = [], action, queryType }: any = searchQueryData

  if (action === 'appendWithLang') {
    let lang = ['languageLabel', 'localizedInLabel']
    const filteredQuery = queryArrayState.filter((query) => !lang.includes(query.queryType))
    return [...filteredQuery, ...queryArray]
  }

  if (action === 'appendWithCt') {
    // let queryType = ['languageLabel', 'localizedInLabel', 'contentTypeLabel']
    let queryType = 'contentTypeLabel'
    const filteredQuery = queryArrayState.filter((query) => query.queryType !== queryType)
    const foundLangQuery = filteredQuery.find(query => query.queryType === 'languageLabel')
    if (foundLangQuery) {
      const contentTypeQuery = queryArray.find(query => query.queryType === queryType)
      const finalQueries = contentTypeQuery ? [contentTypeQuery, ...filteredQuery] : filteredQuery
      return finalQueries
    } else {
      return [...filteredQuery, ...queryArray]
    }
  }

  if (action === 'tableFilterAppendAll') {
    if (queryType === 'languageLabel') {
      let lang = ['languageLabel', 'localizedInLabel']
      const filteredQuery = queryArrayState.filter((query) => !lang.includes(query.queryType))
      return [...filteredQuery, ...queryArray]
    }
    //filtering out only modified At since date query type can filter createdAt publishedAt too
    if (queryType === 'date') {
      const filteredQuery = queryArrayState.filter((query = {}) => {
        const { key = [] } = query
        if (key.length && key[0].value === "updated_at") {
          return false
        }
        return true
      })
      return [...filteredQuery, ...queryArray]
    }
    const filteredQuery = queryArrayState.filter((query) => query.queryType !== queryType)
    return [...filteredQuery, ...queryArray]
  }

  if (action === 'tableFilterClearAll') {
    if (queryType === 'languageLabel') {
      let lang = ['languageLabel', 'localizedInLabel']
      const filteredQuery = queryArrayState.filter((query) => !lang.includes(query.queryType))
      return [...filteredQuery]
    }

    const filteredQuery = queryArrayState.filter((query) => query.queryType !== queryType)
    return [...filteredQuery]
  }

  return queryArray
}

const setDefaultHLength = (savedSearches = [], recentSearches = []) => {
  const entriesSavedSearches = savedSearches.filter((s) => s.type === options[0].value)
  const assetsSavedSearches = savedSearches.filter((s) => s.type === options[1].value)

  const length = recentSearches.length

  const defaultHLength = {
    [length]: {
      entries: maxSuggetsedItemsCount.entries,
      assets: maxSuggetsedItemsCount.assets,
    },
    [length + 1]: {
      entries: entriesSavedSearches.length > 5 ? maxSavedSearchesCount - 1 : entriesSavedSearches.length - 1,
      assets: assetsSavedSearches.length > 5 ? maxSavedSearchesCount - 1 : assetsSavedSearches.length - 1,
    },
  }
  return defaultHLength
}

// function setCaret(id, offset) {
//   const el = document.getElementById(id);
//   const range = document.createRange();
//   const sel = window.getSelection();
//   range.setStart(el.childNodes[0], offset);
//   range.collapse(true);
//   sel.removeAllRanges();
//   sel.addRange(range);
//   el.focus();
// }

class AdvanceSearch extends Component<any, any> {
  state = {
    queryArray: setQueryArray(this.props.searchQueryData),
    //for adv search
    isSearchVisible: false,
    //for editing
    tagEditingIndex: undefined,
    isEditing: false,
    tagCursor: -1,
    isAdvanceQueryDropdownOpen: false,
    selectedOption: options[0],
    //for autocomplete
    isAutoCompleteOpen: false,
    inputValue: '',
    isResetInput: false,
    tagInputValue: '',
    isContentEditableFocused: false,
    placeholder: '',
    case: 'basicQuery',
    advanceQueryArray: [],
    horizontalAdvQueryLength: 0,
    savedSearches: [],
    recentSearches: [],
    //to detect if dropdown open in advancequery ui
    isDropdownOpen: false,
    filters: [],
    advanceQueryByFilters: [],
    //to check when ct is selected and need uid, single or multi
    selectedCtStatus: null,
    //to handle suggestedSyntaxCase click case
    suggestedSyntaxCase: false,
    //to control async load
    isLoading: false,
    //for loading status of saveAndRecent search
    isLoadingSearches: false,
    isBuildingRecentSearches: false,
    errorMessage: '',
    //vertical curosr
    vCursor: -1,
    //horizontal cursor
    hCursor: 0,
    //required to set horizontal length of saved and suggetsed syntax
    hLength: setDefaultHLength(),
    //use to detect change in queryObject
    queryInitUid: '',
    selectedModuleUid: '',
    searchQueryDataUid: ''
  }

  filterInputRef: any = React.createRef()
  child: any = React.createRef()
  savedViewRef: any = React.createRef()
  autoCompleteRef: any = React.createRef()

  isCursorInside = false
  disableRecentSearchCall = false
  textContent =
    this.props.textContent && this.props.textContent.advance_search_ui ? this.props.textContent : defaultTextContent
  canFetchSavedAndRecentSearch = false

  async componentDidMount() {
    try {
      document.addEventListener('click', this.handleClickOutside, false)
      document.addEventListener('keyup', this.registerKeybindings, false)

      const selectedOption = this.state.selectedOption
      const filters = getFiltersBySelectedOption(selectedOption)

      this.setState({
        // savedSearches: savedViewsResponse.saved_searches,
        filters,
        // hLength,
      })
    } catch (error) {
      console.log('componentDidMount error', error)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false)
    document.removeEventListener('keyup', this.registerKeybindings, false)
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      if (
        this.props.recentSearchId &&
        prevProps.recentSearchId &&
        this.props.recentSearchId !== prevProps.recentSearchId
      ) {
        console.log('recent search change detected')
        const recentSearchResponse = await this.props.fetchData.getRecentSearch()

        let recentSearches = recentSearchResponse.recent_searches || []
        let recentSearchesSearchText = recentSearches.filter(query => query.search_text)
        recentSearchesSearchText = recentSearchesSearchText.slice(0, 5)

        this.setRecentSearch(recentSearchesSearchText)
      }

      if (
        prevProps.searchQueryData &&
        this.props.searchQueryData &&
        this.props.searchQueryData.uid !== this.state.searchQueryDataUid
      ) {
        console.log('searchQueryData change detected', this.props.searchQueryData)
        const query = setQueryArray(this.props.searchQueryData, this.state.queryArray)
        const searchQueryDataAction = this.props.searchQueryData.action
        const searchSubmitActionList = ['tableFilterAppendAll', 'appendWithLang', 'appendWithCt', 'tableFilterClearAll', 'tableFilterContentType']

        const isSearchSubmit = searchSubmitActionList.includes(searchQueryDataAction)
        // const isSearchSubmit = searchQueryDataAction === 'appendWithLang' || searchQueryDataAction === 'appendWithCt'

        this.onChangeSelect({ selectedOption: options[0], queryArray: query, isSearchSubmit, action: searchQueryDataAction })
        this.setState({ searchQueryDataUid: this.props.searchQueryData.uid })
      }

      const { query, searchText, type, uid } = this.props.queryObject || {}
      if (
        (query || searchText) &&
        !this.state.queryArray.length &&
        !this.state.advanceQueryArray.length &&
        !this.state.advanceQueryByFilters.length &&
        this.state.queryInitUid !== uid
      ) {
        console.log('Should run only once parser queryObject change detected')

        const filteredQuery = formatOlduiEntriesQueries(query, type)
        const data = {
          selectedModule: type,
          query: filteredQuery,
          search_text: searchText,
          fetchData: this.props.fetchData,
        }

        this.setState({ queryInitUid: uid })
        const { queryType, parsedQueryArray } = await getQueryTypeAndParseQuery(data)
        console.log('after parsing globalsearch queryType, parsedQueryArray', queryType, parsedQueryArray)
        const option = type === 'assets' ? options[1] : options[0]
        this.setState({
          queryArray: queryType === 'basicQuery' ? parsedQueryArray : [],
          advanceQueryArray: queryType === 'advanceQuery' ? parsedQueryArray : [],
          selectedOption: option,
          vCursor: -1,
          hCursor: 0,
          hLength: setDefaultHLength(this.state.savedSearches, this.state.recentSearches),
          case: queryType || 'basicQuery',
        })
      }

      const { moduleValue, selectedModuleUid } = this.props.selectedModule
      if (this.state.selectedModuleUid !== selectedModuleUid) {
        this.setState({ selectedModuleUid })
        const foundModule = moduleValue ? options.find((option) => option.value === moduleValue) : options[0]
        this.onChangeSelect({ selectedOption: foundModule })
      }

      if (this.props.searchShortcutKey !== prevProps.searchShortcutKey) {
        if (!this.state.isSearchVisible) {
          this.setState({ isSearchVisible: true }, () => {
            if (this.filterInputRef.current) {
              this.filterInputRef.current.focus()
            }
          })
        }
      }

    } catch (error) {
      console.log('adv search didupdate', error)
    }
  }

  setRecentSearch = async (recentSearches) => {
    try {
      this.setState({ isBuildingRecentSearches: true })
      const recentSearchWithQueryArrays = await buildRecentSearches({
        recentSearchResponse: recentSearches,
        // fetchData: this.props.fetchData,
      })
      this.setState({ recentSearches: recentSearchWithQueryArrays, isBuildingRecentSearches: false })
    } catch (error) {
      this.setState({ isBuildingRecentSearches: false })
      console.log('setRecentSearch error ', error)
    }
  }

  handleClickOutside = (event) => {
    if (!this.isCursorInside && this.state.isSearchVisible) {
      let updateState: any = { isSearchVisible: false }
      if (this.state.case === 'searchFilter' && isEmptyFilters(this.state.filters)) {
        updateState.case = 'basicQuery'
        updateState.advanceQueryByFilters = []
      }
      this.setState(updateState)
    }
  }

  handleKeyDown = (event) => {
    const { key } = event

    const queryArrayLength = this.state.queryArray.length

    let { tagCursor, isAutoCompleteOpen, isEditing, vCursor, isAdvanceQueryDropdownOpen } = this.state
    if (key === 'Backspace' && !this.state.inputValue && queryArrayLength) {
      if (tagCursor !== queryArrayLength - 1) {
        this.setState({ tagCursor: queryArrayLength - 1 })
      } else if (tagCursor === queryArrayLength - 1) {
        if (this.state.isAutoCompleteOpen) {
          this.closeAutoComplete()
        }
        if (this.state.isEditing) {
          this.closeEditDropdown()
        }
        this.deleteSearchQuery()
      }
    }

    if (
      key === 'ArrowRight' &&
      tagCursor < queryArrayLength - 1 &&
      !isAutoCompleteOpen &&
      !isEditing &&
      !isAdvanceQueryDropdownOpen
    ) {
      // console.log('running tag cursor')
      tagCursor = tagCursor + 1
      this.updateTagCursor(tagCursor)
    } else if (key === 'ArrowLeft' && tagCursor > 0 && !isAutoCompleteOpen && !isEditing) {
      tagCursor = tagCursor - 1
      // console.log('running tag cursor')

      this.updateTagCursor(tagCursor)
    }

    if (key === 'Enter' && vCursor === -1 && !isAdvanceQueryDropdownOpen) {
      //open edit dropdwon only when not creating and selected
      if (tagCursor >= 0 && !this.state.isAutoCompleteOpen) {
        this.openEditDropdown(tagCursor)
        return
      }
      if (queryArrayLength && !(this.state.isAutoCompleteOpen || this.state.isEditing)) {
        // console.log('onSearch submit called')
        this.onSearchSubmit({})
      }
    }

    if (key === 'Delete') {
      if (tagCursor >= 0) {
        this.deleteSearchQuery(tagCursor)
      }
    }
  }

  registerKeybindings = (event) => {
    const { key } = event

    const queryArrayLength = this.state.queryArray.length
    let {
      tagCursor,
      isAutoCompleteOpen,
      isEditing,
      hCursor,
      vCursor,
      selectedOption,
      hLength,
      recentSearches,
      isAdvanceQueryDropdownOpen,
      isSearchVisible
    } = this.state

    if (key === 'Escape' && isSearchVisible) {
      this.setState({ isSearchVisible: false })
      this.onChangeSelect({ selectedOption: this.state.selectedOption, queryArray: [] })
      return
    }

    // if (event.keyCode === 32 && isEditing && isSearchVisible) {
    //   console.log('fgsdfgsdfgs', event.keyCode, event.key)
    //   this.closeEditDropdown()
    // }

    //todo handle key bindings
    if (!isAutoCompleteOpen && !isEditing && !isAdvanceQueryDropdownOpen && this.state.case === 'basicQuery' && isSearchVisible) {
      if (recentSearches.length ? vCursor >= recentSearches.length && vCursor <= recentSearches.length + 1 : vCursor === 0 || vCursor === 1) {
        const horizontalLength = hLength[vCursor][selectedOption.value]
        if (key === 'ArrowRight' && hCursor < horizontalLength) {
          hCursor = hCursor + 1
          // console.log('ArrowRight  hCursor ', hCursor)
          this.setState({ hCursor })
        } else if (key === 'ArrowLeft' && hCursor > 0) {
          hCursor = hCursor - 1
          // console.log('ArrowLeft  hCursor ', hCursor)
          this.setState({ hCursor })
        }
      }
      if (!queryArrayLength && tagCursor === -1) {
        if (key === 'ArrowDown' && vCursor < recentSearches.length + 1) {
          vCursor = vCursor + 1
          // console.log('ArrowDown  vCursor ', vCursor)
          this.setState({ vCursor, hCursor: 0 })
        } else if (key === 'ArrowUp' && vCursor >= 0) {
          vCursor = vCursor - 1
          // console.log('ArrowUp  vCursor', vCursor)

          this.setState({ vCursor, hCursor: 0 })
        }
      }
    }

    if (key === 'Enter' && vCursor >= 0 && !isAutoCompleteOpen && !isEditing && isSearchVisible) {
      if (recentSearches.length && vCursor >= 0 && vCursor < recentSearches.length) {
        const recentSearchCursor = vCursor;
        // handleRecentSearchClick
        this.handleRecentSearchClick(recentSearches[recentSearchCursor])
      } else if (recentSearches.length ? vCursor === recentSearches.length : vCursor === 0) {
        this.child.current.simulateCursorClick(hCursor, 'syntaxes')
      } else if (recentSearches.length ? vCursor === recentSearches.length + 1 : vCursor === 1) {
        this.child.current.simulateCursorClick(hCursor, 'save_search')
      }
    }
  }

  updateHLengthByVCursor = (data) => {
    // console.log("updateHLengthByVCursor -> data", data)
    let hLength = JSON.parse(JSON.stringify(this.state.hLength))
    const selectedOption = this.state.selectedOption
    const vCursor = data.vCursor

    hLength[vCursor][selectedOption.value] = data[selectedOption.value]
    this.setState({
      hLength: { ...hLength },
      hCursor: data.resetHcursor ? 0 : this.state.hCursor,
    })
  }

  updateTagCursor = (updatedCursor) => {
    this.setState({ tagCursor: updatedCursor })
  }

  updateQueryArray = (currentSearch, action, editableQueryIndex, isMulti) => {
    const queryTypeText = ['inEntries', 'inAssets', 'textLabel']
    const canSubmit = currentSearch.isQueryCompleted && queryTypeText.includes(currentSearch.queryType)

    const copyQueryArray = [...this.state.queryArray]
    const selectedModule = this.state.selectedOption.value
    if (action === 'update') {
      copyQueryArray.splice(copyQueryArray.length - 1, 1, currentSearch)
      if (
        selectedModule === 'entries' &&
        currentSearch.queryType === 'languageLabel' &&
        currentSearch.isQueryCompleted
      ) {
        const selectedLanguage = currentSearch.value[0].value
        const localizeIn = JSON.parse(JSON.stringify(localizedData))
        copyQueryArray.push({ ...localizeIn, selectedLanguage })
      }

      this.setState({ queryArray: copyQueryArray, suggestedSyntax: false }, () => {
        if (canSubmit) {
          this.onSearchSubmit({})
        }
      })
    } else if (action === 'create') {
      copyQueryArray.push(currentSearch)
      this.setState({ queryArray: copyQueryArray, suggestedSyntax: false }, () => {
        if (canSubmit) {
          this.onSearchSubmit({})
        }
      })

      this.child.current.updateSuggestedSyntaxOrder(copyQueryArray)


    } else if (action === 'edit') {
      copyQueryArray.splice(editableQueryIndex, 1, currentSearch)

      if (selectedModule === 'entries' && currentSearch.queryType === 'languageLabel') {
        const selectedLanguage = currentSearch.value[0].value
        const localizedData = copyQueryArray[editableQueryIndex + 1]
        if (localizedData && localizedData.queryType === 'localizedInLabel') {
          localizedData.value = [{ label: 'Any Locales', value: [{ label: 'Any Locales', value: '$any' }] }]
          localizedData.selectedLanguage = selectedLanguage
        }
      }
      this.setState({ queryArray: copyQueryArray, suggestedSyntax: false }, () => {
        if (canSubmit && !isMulti) {
          this.onSearchSubmit({})
        }
      })
    }



    if (this.filterInputRef.current) {
      this.filterInputRef.current.focus()
    }
    console.log('%c UPDATEQUERYARRAY copyQueryArray', 'background: #010101; color: #fff', copyQueryArray)
  }

  deleteSearchQuery = (tagCursor = null) => {
    const copyQueryArray = [...this.state.queryArray]
    const selectedModule = this.state.selectedOption.value

    if (tagCursor !== null) {
      const deletedQuery = copyQueryArray.splice(tagCursor, 1)
      if (selectedModule === 'entries' && deletedQuery[0].queryType === 'languageLabel') {
        copyQueryArray.splice(tagCursor, 1)
      }
      if (selectedModule === 'entries' && deletedQuery[0].queryType === 'localizedInLabel') {
        copyQueryArray.splice(tagCursor - 1, 1)
      }
      this.setState({ queryArray: copyQueryArray, tagCursor: -1, placeholder: '' }, () => {
        this.closeAutoComplete()
      })
      return
    }

    if (copyQueryArray.length) {
      const deletedQuery = copyQueryArray.splice(copyQueryArray.length - 1)
      if (selectedModule === 'entries' && deletedQuery[0].queryType === 'localizedInLabel') {
        copyQueryArray.splice(tagCursor - 1, 1)
      }
      this.setState({ queryArray: copyQueryArray, tagCursor: -1, placeholder: '' }, () => {
        this.closeAutoComplete()
      })
    }
  }

  setSavedAndRecentSearch = async () => {
    try {
      if (!this.canFetchSavedAndRecentSearch) {
        this.canFetchSavedAndRecentSearch = true
        this.setState({ isLoadingSearches: true })
        const { getAllSavedViews, getRecentSearch } = this.props.fetchData
        const [savedViewsResponse, recentSearchResponse] = await Promise.all([getAllSavedViews(), getRecentSearch()])

        let recentSearches = recentSearchResponse.recent_searches || []
        let recentSearchesSearchText = recentSearches.filter(query => query.search_text)
        recentSearchesSearchText = recentSearchesSearchText.slice(0, 5)

        this.setRecentSearch(recentSearchesSearchText)
        const hLength = setDefaultHLength(savedViewsResponse.saved_searches, recentSearchesSearchText)
        this.setState({
          savedSearches: savedViewsResponse.saved_searches,
          hLength,
          isLoadingSearches: false
        })
      }
    } catch (error) {
      this.setState({ isLoadingSearches: false })
      console.log("AdvanceSearch -> error", error)
    }
  }

  setSearchVisibility = (isSearchVisible) => {
    if (isSearchVisible) {
      this.setState({ isSearchVisible: true }, () => {
        if (this.filterInputRef.current) {
          this.filterInputRef.current.focus()
        }
      })
      this.setSavedAndRecentSearch()
    } else {
      this.setState({ isSearchVisible: false })
    }
  }

  openEditDropdown = (queryIndex, event = null) => {
    if (!this.state.isEditing) {
      // console.log('=======================> edit running')
      const cancelClass = 'AdvancedSearch__cancel'
      if (event && (event.target.parentNode.className === cancelClass || event.target.className === cancelClass)) {
        return
      }

      const queryArray = this.state.queryArray
      if (queryArray[queryIndex].isQueryCompleted) {
        if (this.state.isAutoCompleteOpen) {
          const lastSearchQuery = queryArray[queryArray.length - 1]
          const { key, operator, value } = lastSearchQuery
          //if last query not completed then delete and close
          if (!(key.length && operator.length && value.length)) {
            queryArray.splice(queryArray.length - 1)
          }
          this.closeAutoComplete()
        }
        this.setState({
          tagEditingIndex: queryIndex,
          isEditing: true,
          tagCursor: -1,
        })

        //todo discuss this ======
        // this.filterInputRef.current.blur()
        // setTimeout(() => {
        //   const activeQueryValue = queryArray[queryIndex].value[0]
        //   const length = activeQueryValue && activeQueryValue.value.length
        //   setCaret(`queryTagInput${queryIndex}`, length)
        // })

      }
    }
  }

  closeEditDropdown = () => {
    // console.trace()
    console.log('close eidt dropdiwn called')
    this.setState({
      isEditing: false,
      tagEditingIndex: -1,
      tagInputValue: '',
      tagCursor: -1,
    })
  }

  updateInputValue = (e) => {
    const inputValue = e.target.value

    const controlledInputValue = inputValue.length >= 2 ? inputValue.trimStart() : inputValue
    this.setState({
      inputValue: controlledInputValue,
      isResetInput: false,
      suggestedSyntaxCase: false,
      vCursor: -1,
      hCursor: 0,
    })
  }

  resetInputValue = () => {
    this.setState({ inputValue: '', isResetInput: true })
  }

  onEditTag = (e) => {
    const inputValue = e.target.textContent
    this.setState({ tagInputValue: inputValue, isContentEditableFocused: true })
    // console.log("AdvanceSearch -> inputValue onEditTag", inputValue)
  }

  onBlurTagEditing = () => {
    // console.log('onBlur called')
    this.setState({ isContentEditableFocused: false })
  }

  onClickTagInput = () => {
    this.setState({ isContentEditableFocused: true })
  }

  onChangeSelect = ({ selectedOption, queryArray = [], isSearchSubmit = false, canSetDefaultHLength = true, action = '' }) => {
    const filters = getFiltersBySelectedOption(selectedOption)
    const hLength = canSetDefaultHLength ? setDefaultHLength(this.state.savedSearches, this.state.recentSearches) : this.state.hLength
    this.setState(
      {
        selectedOption,
        queryArray: queryArray,
        inputValue: '',
        isResetInput: true,
        tagEditingIndex: -1,
        tagCursor: -1,
        isEditing: false,
        isAdvanceQueryDropdownOpen: false,
        isAutoCompleteOpen: false,
        case: 'basicQuery',
        advanceQueryArray: [],
        error_cursors: [],
        filters,
        advanceQueryByFilters: [],
        errorMessage: '',
        placeholder: '',
        vCursor: -1,
        hCursor: 0,
        hLength
      },
      () => {
        if (isSearchSubmit) {
          this.onSearchSubmit({ action })
        }
      }
    )
    // this.closeAutoComplete()
    // this.resetInputValue()
    if (this.filterInputRef.current) {
      this.filterInputRef.current.focus()
    }
  }

  //handling basic search Submit
  onSearchSubmit = async (data) => {
    const { queryObject = '', search_text = '', queryType = null, selectedModule = null, action } = data
    const { selectedOption, queryArray, advanceQueryArray } = this.state

    //run quick search with queryObject|searchtext
    if (queryObject || search_text) {
      this.props.onSearchSubmit({
        type: queryType || 'basicQuery',
        selectedOption: selectedModule || selectedOption,
        queryObject,
        search: search_text,
      })

      /**
       * Mixpanel event, metadata capture
       **/
      let eventName = 'Run a quick search'
      let eventMetadata = {
        search_text: search_text,
        query: queryObject,
        module: (selectedModule && selectedModule.value) || (selectedOption && selectedOption.value),
        queryType,
      }
      this.props.userAnalytics.trackEvent(eventName, eventMetadata)
      return
    }

    //run search with queryArray
    const { isValid, errorMessage } = validateBasicQuery(queryArray, selectedOption.value)
    if (!isValid) {
      notify(errorMessage, 'warning')
      return
    }

    this.setState({
      isSearchVisible: false,
      isEditing: false,
      isAutoCompleteOpen: false,
      advanceQueryArray: [],
      advanceQueryByFilters: [],
    })
    const queryBuilderResponse = queryBuilder({
      queryArray,
      selectedOption: selectedOption.value,
      queryCase: this.state.case,
      advanceQueryArray,
      action
    })
    console.log('onSearchSubmit queryBuilderResponse', queryBuilderResponse)
    //just for testing
    // const response = await basicQueryParser({
    //   selectedOption: selectedOption.value,
    //   queryObject: queryBuilderResponse.queryObject,
    //   fetchData: this.props.fetchData,
    //   search_text: ''
    // })
    // console.log("parseQuery -> queryArray", response)
    this.props.onSearchSubmit({
      type: 'basicQuery',
      selectedOption: this.state.selectedOption,
      queryArray,
      queryObject: queryBuilderResponse.queryObject,
      search: queryBuilderResponse.search,
      action
    })

    /**
     * Mixpanel event, metadata capture
     **/
    let eventName = 'Run a quick search'
    let eventMetadata = {
      search_text: queryBuilderResponse.search,
      query: queryBuilderResponse.queryObject,
      module: selectedOption.value,
      queryType: 'basicQuery',
    }
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)
  }

  openAutoComplete = (event) => {
    const value = event.target.value
    if ((value === ':' || value === ';') && !this.state.isAutoCompleteOpen) {
      console.log('Opening Advance search view')
      this.openAdvanceQueryDropdown()
    } else if (!this.state.isAutoCompleteOpen) {
      //close edit dropdown if it is open
      if (this.state.isEditing) {
        this.closeEditDropdown()
      }
      this.closeAdvanceQueryDropdown()
      this.setState({
        isAutoCompleteOpen: true,
        case: 'basicQuery',
        suggestedSyntaxCase: false,
        tagCursor: -1,
        isEditing: false,
      })
    }
  }

  closeAutoComplete = () => {
    this.setState({
      isAutoCompleteOpen: false,
      placeholder: '',
      tagCursor: -1,
      isEditing: false,
    })
  }

  // Clear the search box
  clearAndCloseSearch = () => {

    const eventNameByType = {
      basicQuery: 'Clear the search box',
      advanceQuery: `Cancel ‘Run advanced search typing colon’`,
      searchFilter: `Cancel ‘Run advanced search using Filters option’`
    }

    if (this.state.case === 'advanceQuery') {
      this.setState({
        case: 'basicQuery',
        advanceQueryArray: [],
        errorMessage: '',
        inputValue: '',
      })
      if (this.props.fetchData.onResetSearch) {
        this.props.fetchData.onResetSearch()
      }
      /**
       * Mixpanel event, metadata capture
       **/
      let eventMetadata = {
        queryType: 'advanceQuery',
      }
      this.props.userAnalytics.trackEvent(eventNameByType.advanceQuery, eventMetadata)
      return
    }

    this.setState({ isSearchVisible: false })
    this.onChangeSelect({ selectedOption: this.state.selectedOption, queryArray: [], isSearchSubmit: false, canSetDefaultHLength: false })
    if (this.props.fetchData.onResetSearch) {
      this.props.fetchData.onResetSearch()
    }
    /**
     * Mixpanel event, metadata capture
     **/
    const queryType = this.state.case
    let eventMetadata = {
      queryType
    }
    this.props.userAnalytics.trackEvent(eventNameByType[queryType], eventMetadata)
  }

  setPlaceholder = (placeholder) => {
    this.setState({ placeholder })
  }

  //==== advance query CRUD
  openAdvanceQueryDropdown = () => {
    this.setState({ isAdvanceQueryDropdownOpen: true })
  }

  closeAdvanceQueryDropdown = () => {
    this.setState({ isAdvanceQueryDropdownOpen: false })
  }

  onClickMatchAnyOrAll = (selectedMatch) => {
    // console.log('AdvanceSearch -> selectedMatch', selectedMatch)

    if (this.props.getAdvanceSearchStatus) {
      this.props.getAdvanceSearchStatus({ isSearchVisible: this.state.isSearchVisible, searchCase: 'advanceQuery' })
    }

    let { advanceQueryArray } = this.state
    if (!advanceQueryArray.length) {
      let obj: any = {
        root: true,
        queryType: selectedMatch,
        children: [],
        queryArray: [],
        cursorY: 0,
      }
      advanceQueryArray = [obj]
    }
    this.setState({
      case: 'advanceQuery',
      advanceQueryArray,
      isAdvanceQueryDropdownOpen: false,
      // queryArray: [],
      // advanceQueryByFilters: []
    })
  }

  updateAdvanceQueryMatchCase = (selectedMatch, currentQuery) => {
    let { advanceQueryArray } = this.state
    let ct_status = currentQuery.advanceQueryObject.ct_status || {}
    let { show_ct, ...restCtStatus } = ct_status
    restCtStatus = Object.keys(restCtStatus).length ? restCtStatus : ''
    if (currentQuery.case === 'duplicate') {
      currentQuery.advanceQueryArray.push({
        queryType: selectedMatch,
        children: [],
        queryArray: [],
        //todo in case of duplicate , pass info from common parent, not from self
        ct_status: restCtStatus,
      })
      const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
      this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
      return
    }

    if (currentQuery.case === 'nest') {
      let { advanceQueryObject } = currentQuery
      if (advanceQueryObject.children) {
        advanceQueryObject.children.push({
          queryType: selectedMatch,
          children: [],
          queryArray: [],
          ct_status: restCtStatus,
        })
      } else {
        advanceQueryObject.children = [
          { queryType: selectedMatch, children: [], queryArray: [], ct_status: restCtStatus },
        ]
      }
      const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
      this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
    }
  }

  updateAdvanceQueryArray = (data) => {
    const { currentSearch, action, queryArray, editableQueryIndex, indexToDelete, queryObject } = data

    let { advanceQueryArray, selectedOption } = this.state
    console.log('%c UPDATEAdvanceQUERYARRAY advanceQueryArray', 'background: #d11450; color: #fff', advanceQueryArray)

    if (action === 'update') {
      queryArray.splice(queryArray.length - 1, 1, currentSearch)

      //in case of entries module localized in handling
      if (
        selectedOption.value === 'entries' &&
        currentSearch.queryType === 'languageLabel' &&
        currentSearch.isQueryCompleted
      ) {
        const selectedLanguage = currentSearch.value[0].value
        const localizeIn = JSON.parse(JSON.stringify(localizedData))
        queryArray.push({ ...localizeIn, selectedLanguage })
      }

      const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)

      if (selectedOption.value === options[0].value) {
        let errorMessage = updateSelectedContentTypeStatus(queryObject, 'default')
        this.setState({ errorMessage })
      }
      this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
      return
    }

    if (action === 'create') {
      queryArray.push(currentSearch)
      const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
      if (selectedOption.value === options[0].value) {
        let errorMessage = updateSelectedContentTypeStatus(queryObject, 'default')
        this.setState({ errorMessage })
      }
      this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
      return
    }

    if (action === 'deleteIncompleteQuery' && queryArray.length) {
      const lastSearchQuery = queryArray[queryArray.length - 1]
      const { key, operator, value } = lastSearchQuery
      //if last query not completed then delete and close
      if (!(key.length && operator.length && value.length)) {
        const deletedQuery = queryArray.splice(queryArray.length - 1)

        //delete handling for long and localize
        if (selectedOption.value === 'entries' && deletedQuery[0].queryType === 'localizedInLabel') {
          queryArray.splice(queryArray.length - 1, 1)
        }
        const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
        this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
      }
      return
    }

    if (action === 'edit') {
      queryArray.splice(editableQueryIndex, 1, currentSearch)

      //in case of entries module localized in handling
      if (selectedOption.value === 'entries' && currentSearch.queryType === 'languageLabel') {
        const selectedLanguage = currentSearch.value[0].value
        const localizedData = queryArray[editableQueryIndex + 1]
        if (localizedData && localizedData.queryType === 'localizedInLabel') {
          localizedData.value = [{ label: 'Any Locales', value: [{ label: 'Any Locales', value: '$any' }] }]
          localizedData.selectedLanguage = selectedLanguage
        }
      }

      const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
      if (selectedOption.value === options[0].value) {
        let errorMessage = updateSelectedContentTypeStatus(queryObject, 'default')
        this.setState({ errorMessage })
      }
      this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
      return
    }

    if (action === 'deleteByIndex' && queryArray.length) {
      const deletedQuery = queryArray.splice(indexToDelete, 1)

      //delete handling for long and localize
      if (selectedOption.value === 'entries' && deletedQuery[0].queryType === 'languageLabel') {
        queryArray.splice(indexToDelete, 1)
      }
      if (selectedOption.value === 'entries' && deletedQuery[0].queryType === 'localizedInLabel') {
        queryArray.splice(indexToDelete - 1, 1)
      }

      const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
      //call only if contetnty queries has been deleted and pass updated info to child
      if (selectedOption.value === options[0].value && deletedQuery[0].queryType === 'contentTypeLabel') {
        let errorMessage = updateSelectedContentTypeStatus(queryObject, 'delete')
        this.setState({ errorMessage })
      }
      this.setState({ advanceQueryArray: [...advanceQueryArray], horizontalAdvQueryLength })
      return
    }

    if (action === 'toggle') {
      queryObject.queryType = switchMatchAllAny[queryObject.queryType]
      this.setState({ advanceQueryArray: [...advanceQueryArray] })
    }
  }

  onDeleteAdvanceQuery = ({ currentAdvanceQueryArray, index }) => {
    let { advanceQueryArray } = this.state
    const queryObj = currentAdvanceQueryArray[index]
    if (queryObj.root) {
      this.setState({
        advanceQueryArray: [],
        isAdvanceQueryDropdownOpen: false,
        case: 'basicQuery',
        inputValue: '',
      })
      return
    }
    currentAdvanceQueryArray.splice(index, 1)
    const horizontalAdvQueryLength = setCounterForAdvanceQuery(advanceQueryArray, -1)
    this.setState({
      advanceQueryArray: [...advanceQueryArray],
      horizontalAdvQueryLength,
    })
  }

  setDropdownOpenStatus = (status) => {
    this.setState({ isDropdownOpen: status })
  }

  //adv search submit handling
  onClickSeeResults = async () => {
    const { advanceQueryArray, selectedOption } = this.state
    let errorMessage = ''
    let errorObject = {
      isQueryEmpty: true,
      isErrorCursorFound: false,
      errorMessage: '',
    }
    validateAdvanceQuery(advanceQueryArray, errorObject)
    console.log('AdvanceSearch -> errorObject', errorObject)

    if (errorObject.isQueryEmpty) {
      errorMessage = this.textContent.validations.advance_query.no_conditions
    }
    if (errorObject.isErrorCursorFound) {
      // errorMessage = this.textContent.validations.advance_query.no_cursor_found
      errorMessage = errorObject.errorMessage
    }

    if (errorMessage) {
      this.setState({ errorMessage })
      return
    }

    const queryObjectsForAdvQuery = buildQueryObjectForAdvCase(this.state.advanceQueryArray, selectedOption.value)

    // for testing only
    // const advanceQueryArr = await advanceQueryParser({
    //   query: queryObjectsForAdvQuery,
    //   selectedModule: this.state.selectedOption.value,
    //   fetchData: this.props.fetchData,
    // })
    // console.log("AdvanceSearch -> advanceQueryArr", advanceQueryArr)

    this.setState({
      isSearchVisible: false,
      isEditing: false,
      isAutoCompleteOpen: false,
      advanceQueryByFilters: [],
      queryArray: [],
      errorMessage: '',
    })

    this.props.onSearchSubmit({
      type: 'advanceQuery',
      selectedOption: this.state.selectedOption,
      queryObject: queryObjectsForAdvQuery,
      search: '',
    })

    const eventNameByType = {
      basicQuery: 'Run advanced search typing colon',
      advanceQuery: 'Run advanced search with nested conditions'
    }

    //checking double matchAll in advanceQuery
    const queryType = getQueryType(JSON.stringify(queryObjectsForAdvQuery), selectedOption.value)
    /**
     * Mixpanel event, metadata capture
     **/
    let eventName = eventNameByType[queryType]
    let eventMetadata = {
      search_text: '',
      query: queryObjectsForAdvQuery,
      module: selectedOption.value,
      queryType: 'advanceQuery',
    }
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)

    console.log('Cool!, Advance searchdisableRecentSearchCall = false submit successfuly', queryObjectsForAdvQuery)
  }

  //saved view
  fetchAllviews = async () => {
    try {
      const response = await this.props.fetchData.getAllSavedViews()
      this.setState({
        savedSearches: response.saved_searches,
        vCursor: -1,
        hCursor: 0,
        hLength: setDefaultHLength(this.state.savedSearches, this.state.recentSearches),
      })
    } catch (error) {
      console.log('fetchAllviews', error)
    }
  }

  createSavedView = async (name) => {
    try {
      const { selectedOption, queryArray, advanceQueryArray } = this.state
      const { queryObject, search } = queryBuilder({
        queryArray,
        selectedOption: selectedOption.value,
        queryCase: this.state.case,
        advanceQueryArray,
      })
      const data: any = {
        saved_search: {
          filters: {},
          query: queryObject,
          type: selectedOption.value,
          name,
        },
      }
      if (search) {
        data.saved_search.search_text = search
      }
      const { createView } = this.props.fetchData
      if (createView) {
        const response = await createView({ data })

        /**
         * Mixpanel event, metadata capture
         **/
        let eventName = this.state.case === 'basicQuery' ? 'Save a quick search' : 'Save an advanced search'
        let eventMetadata = {
          search_text: search,
          search_name: name,
          query: queryObject,
          module: selectedOption.value,
          queryType: this.state.case,
        }
        this.props.userAnalytics.trackEvent(eventName, eventMetadata)

        console.log('createSavedView -> response', response)
        await this.fetchAllviews()
      }
    } catch (error) {
      throw error
    }
  }

  handleRecentSearchClick = async (search) => {
    if (!this.disableRecentSearchCall) {
      try {
        this.disableRecentSearchCall = true
        const { search_text, type, queryArray = [], query, queryType } = search
        const queryObject = query ? JSON.parse(query) : ''

        //New change recent search text should be applied only for active module
        const selectedModule = this.state.selectedOption.value
        const option = selectedModule === 'assets' ? options[1] : options[0]

        /**
         * Mixpanel event, metadata capture
         **/
        let eventName = 'Select recent searches'
        let eventMetadata = {
          search_text: search_text,
          query: queryObject,
          module: selectedModule,
          queryType,
        }
        this.props.userAnalytics.trackEvent(eventName, eventMetadata)

        this.onSearchSubmit({ search_text, queryObject, queryType, selectedModule: option })

        let newState = {
          queryArray: [],
          selectedOption: option,
          case: queryType || 'basicQuery',
          advanceQueryArray: [],
          isSearchVisible: false,
          isEditing: false,
          isAutoCompleteOpen: false,
        }

        if (queryType === 'advanceQuery') {
          newState.advanceQueryArray = queryArray
        }
        if (queryType === 'basicQuery') {
          newState.queryArray = queryArray
        }
        this.setState(newState, () => {
          this.disableRecentSearchCall = false
        })
      } catch (err) {
        console.log('handleRecentSearchClick -> err', err)
        this.disableRecentSearchCall = false
      }
    }
  }

  handleSavedSearchClick = async (search) => {
    try {
      this.setState({ isLoading: true })
      const { query, search_text, type } = search

      const queryType = getQueryType(query, type)
      const option = type === 'assets' ? options[1] : options[0]

      if (query || search_text) {
        let queryObject = query ? JSON.parse(query) : ''

        /**
         * Mixpanel event, metadata capture
         **/
        let eventName = 'Select saved searches'
        let eventMetadata = {
          search_text: search_text,
          query: queryObject,
          module: type,
          queryType,
        }
        this.props.userAnalytics.trackEvent(eventName, eventMetadata)
        this.onSearchSubmit({ queryObject, search_text, selectedModule: option, queryType })

        const filteredQuery = formatOlduiEntriesQueries(queryObject, type)
        console.log("AdvanceSearch -> filteredQuery", filteredQuery)
        let response
        if (queryType === 'advanceQuery') {
          response = await advanceQueryParser({
            // query: queryObject,
            query: filteredQuery,
            selectedModule: type,
            fetchData: this.props.fetchData,
          })
        }

        if (queryType === 'basicQuery') {
          response = await basicQueryParser({
            selectedOption: option.value,
            queryObject,
            search_text,
            fetchData: this.props.fetchData,
          })
        }

        const newState = {
          case: queryType,
          queryArray: [],
          isEditing: false,
          isAutoCompleteOpen: false,
          isSearchVisible: false,
          advanceQueryArray: [],
          advanceQueryByFilters: [],
          isLoading: false,
          selectedOption: option,
          vCursor: -1,
          hCursor: 0,
          hLength: setDefaultHLength(this.state.savedSearches, this.state.recentSearches),
        }
        if (queryType === 'advanceQuery') {
          newState.advanceQueryArray = response
        }
        if (queryType === 'basicQuery') {
          newState.queryArray = response
        }
        this.setState(newState)


      }
    } catch (error) {
      this.setState({ isLoading: false })
      console.log('handleSavedSearchClick error', error)
    }
  }

  //filters
  onClickFilters = () => {
    const serchCase = this.state.case
    const queryArray = this.state.queryArray

    if (this.state.isAutoCompleteOpen) {
      const lastSearchQuery = queryArray[queryArray.length - 1]
      const { key, operator, value } = lastSearchQuery
      //if last query not completed then delete and close
      if (!(key.length && operator.length && value.length)) {
        queryArray.splice(queryArray.length - 1)
        this.setState({ isAutoCompleteOpen: false, queryArray, placeholder: '' })
      }
    }
    if (serchCase === 'basicQuery') {
      this.setState({ case: 'searchFilter', isEditing: false })
    } else {
      this.setState({ case: 'basicQuery', isEditing: false })
    }
  }

  updateFilter = (filterData) => {
    const { filters } = this.state
    const { type, value, uid, isMulti, isChecked, isText, isNumeric, action } = filterData

    const foundFilter = filters.find((filter) => filter.uid === uid)
    const isLanguageFilter = foundFilter.key.value === 'locale'
    const hasOperatorChanged = foundFilter.prevOperator && foundFilter.prevOperator.value !== foundFilter.operator.value

    if (type !== 'operator' && 'prevOperator' in foundFilter) foundFilter.prevOperator = null

    if (type === 'value' && action === 'set') {
      foundFilter.value = value
      this.setState({ filters: [...filters] })
      return
    }

    if (type === 'value' && isMulti) {
      if (!foundFilter.value.length && isChecked) {
        foundFilter.value.push(value)
      } else if (isChecked) {
        foundFilter.value = [...foundFilter.value, value]
      } else if (!isChecked) {
        foundFilter.value = foundFilter.value.filter((v) => v.value !== value.value)
      }
      this.setState({ filters: [...filters] })
      return
    }

    if (type === 'value' && (isText || isNumeric)) {
      if (value && value.value) {
        foundFilter.value = value
      } else {
        foundFilter.value = ''
      }

      this.setState({ filters: [...filters] })
      return
    }

    if (type === 'value' && !isMulti) {
      if (foundFilter.value.value === value.value && !hasOperatorChanged) {
        foundFilter.value = {}
        // clear localized on language clear
        if (isLanguageFilter) {
          const localizedFilter = filters.find((filter) => filter.key.value === 'localization')
          localizedFilter.value = []
        }
      } else {
        foundFilter.value = value
      }

      this.setState({ filters: [...filters] })
      return
    }

    if (type === 'operator') {
      foundFilter.prevOperator = foundFilter.operator
      foundFilter.operator = value
      this.setState({ filters: [...filters] })
      return
    }
  }

  onClickFilterResult = () => {
    const { queryObject, search } = filterQueryObjectBuilder({
      filters: this.state.filters,
      selectedModuleVal: this.state.selectedOption.value,
    })

    const advanceQueryByFilters = getAdvanceQueryByFilters(this.state.filters)
    setCounterForAdvanceQuery(advanceQueryByFilters, -1)
    this.setState({ advanceQueryByFilters, advanceQueryArray: [], queryArray: [] })

    this.props.onSearchSubmit({
      type: 'searchFilter',
      selectedOption: this.state.selectedOption,
      queryObject,
      search,
    })
    console.log('onClickFilterResult queryObject', queryObject)
    this.setSearchVisibility(false)
    /**
     * Mixpanel event, metadata capture
     **/
    let eventName = 'Run advanced search using Filters option'
    let eventMetadata = {
      search_text: search,
      query: queryObject,
      module: this.state.selectedOption.value,
      queryType: 'searchFilter',
    }
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)
  }

  onClickSuggestedSyntax = (syntax) => {
    console.log('AdvanceSearch -> syntax', syntax)

    /**
       * Mixpanel event, metadata capture
       **/
    let eventName = 'Use suggested fields to run an advanced search query'
    let eventMetadata = {
      syntax,
      module: this.state.selectedOption.value
    }
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)

    if (this.state.isAutoCompleteOpen) {
      this.setState({ isAutoCompleteOpen: false }, () => {
        this.setState({
          inputValue: syntax,
          suggestedSyntaxCase: true,
          isAutoCompleteOpen: true,
          case: 'basicQuery',
          vCursor: -1,
          hCursor: 0,
          hLength: setDefaultHLength(this.state.savedSearches, this.state.recentSearches),
        })
      })
      return
    }
    this.setState({
      inputValue: syntax,
      suggestedSyntaxCase: true,
      isAutoCompleteOpen: true,
      case: 'basicQuery',
      vCursor: -1,
      hCursor: 0,
      hLength: setDefaultHLength(this.state.savedSearches, this.state.recentSearches),
    })
  }

  handleRemove = (e, queryIndex) => {
    e.stopPropagation()
    this.deleteSearchQuery(queryIndex)
  }

  resetRecentSearch = async () => {
    try {
      const response: any = await this.props.fetchData.resetRecentSearch({ type: this.state.selectedOption.value })
      const hLength = setDefaultHLength(this.state.savedSearches, [])
      this.setState({ recentSearches: [], hLength })
      notify(response.message, 'success')
    } catch (error) {
      console.log("resetRecentSearch error", error)
    }
  }

  onClickSearchIcon = () => {
    const { queryArray, inputValue, advanceQueryArray, advanceQueryByFilters } = this.state

    if (!queryArray.length && !inputValue.length && !advanceQueryArray.length && !checkSearchFilterValue(this.state.filters)) {
      return
    }

    if (this.state.case === 'basicQuery') {
      //incomplete query check
      const isQueryCompleted = queryArray.length ? queryArray[queryArray.length - 1].isQueryCompleted : true
      //search text check
      const checkForSearchTextCase = inputValue.length && (!queryArray.length || queryArray.length && queryArray[queryArray.length - 1].isQueryCompleted)

      if (this.autoCompleteRef.current?.handleSearchTextOnClickSearchIcon && (!isQueryCompleted || checkForSearchTextCase)) {
        this.autoCompleteRef.current.handleSearchTextOnClickSearchIcon()
        return
      }

      this.onSearchSubmit({})
      return
    }

    if (this.state.case === 'advanceQuery') {
      this.onClickSeeResults()
      return
    }

    if (this.state.case === 'searchFilter') {
      this.onClickFilterResult()
      return
    }

  }

  render() {
    const textContent = this.textContent
    const { recentSearches, selectedOption } = this.state
    const savedSearches = this.state.savedSearches.filter((s) => s.type === selectedOption.value)

    const { updateSavedView, deleteSavedView } = this.props.fetchData
    const savedViews = (
      <SavedViews
        queryCase={this.state.case}
        textContent={textContent}
        savedSearches={savedSearches}
        queryArray={this.state.queryArray}
        advanceQueryArray={this.state.advanceQueryArray}
        createSavedView={this.createSavedView}
        handleSavedSearchClick={this.handleSavedSearchClick}
        updateSavedView={updateSavedView}
        deleteSavedView={deleteSavedView}
        fetchAllviews={this.fetchAllviews}
        isSearchVisible={this.state.isSearchVisible}
        userAnalytics={this.props.userAnalytics}
        setSavedAndRecentSearch={this.setSavedAndRecentSearch}
        canFetchSavedAndRecentSearch={this.canFetchSavedAndRecentSearch}
        ref={this.savedViewRef}
      />
    )
    const queryCheck = this.state.queryArray.length || this.state.advanceQueryArray.length || this.state.inputValue.length || checkSearchFilterValue(this.state.filters)

    return (
      <SearchContext.Provider
        value={{
          queryArray: this.state.queryArray,
          updateQueryArray: this.updateQueryArray,
          updateAdvanceQueryMatchCase: this.updateAdvanceQueryMatchCase,
          updateAdvanceQueryArray: this.updateAdvanceQueryArray,
          advanceQueryArray: this.state.advanceQueryArray,
          onDeleteAdvanceQuery: this.onDeleteAdvanceQuery,
          setDropdownOpenStatus: this.setDropdownOpenStatus,
          isDropdownOpen: this.state.isDropdownOpen,
          selectedCtStatus: this.state.selectedCtStatus,
          textContent,
          isSearchVisible: this.state.isSearchVisible
        }}
      >
        <div
          id="AdvanceSearchComponent"
          className="AdvancedSearch"
          onMouseOver={() => {
            this.isCursorInside = true
          }}
          onMouseOut={() => {
            this.isCursorInside = false
          }}
        >
          {/* Search bar dummy */}
          <SearchBar
            textContent={textContent}
            setSearchVisibility={this.setSearchVisibility}
            selectedOption={this.state.selectedOption}
            queryArray={this.state.queryArray}
            advanceQueryByFilters={this.state.advanceQueryByFilters}
            advanceQueryArray={this.state.advanceQueryArray}
            savedViews={savedViews}
            clearAndCloseSearch={this.clearAndCloseSearch}
            searchCase={this.state.case}
          />

          {/* Advance Search ui */}
          <div
            className={`AdvancedSearch__area AdvancedSearch__area--primary${this.state.isSearchVisible ? ' AdvancedSearch__area--show' : ''
              }`}
          >
            {/* Search bar active one */}
            <div className="AdvancedSearch__main">
              <div className='AdvancedSearch__resetBtn-wrapper'>
                <SearchTooltip content={textContent.tooltips.clear_search} position="top">
                  <button className="AdvancedSearch__resetBtn" onClick={this.clearAndCloseSearch}>
                    <Icon icon={iconName.DeleteQueryBuilder} size="extraSmall" />
                  </button>
                </SearchTooltip>
              </div>

              <div className="AdvancedSearch__category">
                <Select
                  value={this.state.selectedOption}
                  onChange={(selectedOption) => this.onChangeSelect({ selectedOption })}
                  options={options}
                  placeholder="Select .."
                  decorators={{ CustomDropdownIndicator }}
                  width="70px"
                />
              </div>

              {(this.state.case === 'basicQuery' || this.state.case === 'searchFilter') && (
                <div
                  className={`AdvancedSearch__query-wrapper${this.state.isAdvanceQueryDropdownOpen ? ' AdvancedSearch__query-wrapper--builder' : ''
                    }`}
                >
                  {this.state.queryArray.map((query, queryIndex) => {
                    const { key, operator, value, queryType = '' } = query

                    const localizedQuery = this.state.queryArray[queryIndex + 1]
                    const isCurrLang =
                      queryType === languageLabel.queryType &&
                      localizedQuery &&
                      localizedQuery.queryType === localizedInLabel.queryType
                    const isPrevLanguage =
                      queryIndex > 0 && this.state.queryArray[queryIndex - 1].queryType === languageLabel.queryType
                    const isCurrLocalization = queryType === localizedInLabel.queryType && isPrevLanguage

                    return key.map((queryKey, index) => {
                      const operatorForKey = operator[index]
                      const valueForKey = (value[index] && value[index]) || {}

                      let tooltipContent = getOperatorTextContent({
                        textContent,
                        operatorKey: operatorForKey,
                        queryType,
                      })

                      const frontConnectorType = isCurrLocalization ? 'and' : null
                      const addClass = isCurrLang ? 'AdvancedSearch__matched-criteria-count-mid' : ''
                      const { truncatedText, isOverflow } = truncate(valueForKey.label || '')
                      const isCurQueryEditing = this.state.isEditing && this.state.tagEditingIndex === queryIndex

                      return (
                        <div className="flex-v-center" key={index}>
                          <div
                            id={`AdvanceSearch__matched-criteria-${queryIndex}`}
                            className={`AdvancedSearch__matched-criteria ${addClass} ${this.state.tagCursor === queryIndex ? ` AdvancedSearch__matched-criteria-active` : ''
                              } `}
                            key={index}
                            onClick={(e) => this.openEditDropdown(queryIndex, e)}
                          >
                            <TagRemoveTooltip
                              isCursorActive={this.state.tagCursor === queryIndex}
                              onRemove={(e) => this.handleRemove(e, queryIndex)}
                            >
                              <div className="flex-v-center AdvancedSearch__remove-tag">
                                {this.state.queryArray.length > 1 ? <Connector type={frontConnectorType} /> : null}
                                <span className="AdvancedSearch__matched-type">{queryKey.label}</span>
                                <SearchTooltip content={tooltipContent} position="top">
                                  <span className="AdvancedSearch__matched-operator">
                                    <Icon icon={operatorForKey.icon}></Icon>
                                  </span>
                                </SearchTooltip>
                                <span
                                  className="AdvancedSearch__matched-string"
                                  contentEditable={this.state.isEditing}
                                  onInput={this.onEditTag}
                                  onFocus={this.onClickTagInput}
                                  onBlur={this.onBlurTagEditing}
                                  suppressContentEditableWarning={true}
                                  // id={`queryTagInput${queryIndex}`}
                                  spellCheck="false"
                                >
                                  {isOverflow ? (
                                    <SearchTooltip content={valueForKey.label} position="top">
                                      {truncatedText}
                                    </SearchTooltip>
                                  ) : (
                                    valueForKey.label
                                  )}
                                </span>

                                {query.isQueryCompleted && !isCurQueryEditing && (
                                  <div className="AdvancedSearch__dropdown">
                                    <Icon icon={iconName.DropdownDark} className="Dropdown__icon" />
                                  </div>
                                )}
                                {this.state.queryArray.length > 1 && index === this.state.queryArray.length - 1 ? (
                                  <Connector />
                                ) : null}
                              </div>
                            </TagRemoveTooltip>
                            {isCurQueryEditing && (
                              <SearchPortal domId={portalId}>
                                <AutoComplete
                                  editInputRef={document.getElementById(
                                    `AdvanceSearch__matched-criteria-${queryIndex}`
                                  )}
                                  resetInputValue={() => { }}
                                  inputValue={this.state.tagInputValue}
                                  closeAutoComplete={this.closeEditDropdown}
                                  isAutoCompleteOpen={this.state.isAutoCompleteOpen}
                                  queryType={query.queryType}
                                  nextQuery={query.nextQuery}
                                  currentSearch={query}
                                  needOperator={true}
                                  action="edit"
                                  editableQueryIndex={queryIndex}
                                  selectedOption={this.state.selectedOption.value}
                                  fetchData={this.props.fetchData}
                                  setPlaceholder={this.setPlaceholder}
                                  case="basicQuery"
                                  selectedOperator={operatorForKey.value}
                                  queryArray={this.state.queryArray}
                                  disableArrowKey={this.state.isContentEditableFocused}
                                  ref={this.autoCompleteRef}
                                />
                              </SearchPortal>
                            )}
                          </div>
                        </div>
                      )
                    })
                  })}

                  <FilterInput
                    filterInputRef={this.filterInputRef}
                    fetchData={this.props.fetchData}
                    selectedOption={this.state.selectedOption}
                    handleKeyDown={this.handleKeyDown}
                    inputValue={this.state.inputValue}
                    isResetInput={this.state.isResetInput}
                    resetInputValue={this.resetInputValue}
                    updateInputValue={this.updateInputValue}
                    isAutoCompleteOpen={this.state.isAutoCompleteOpen}
                    openAutoComplete={this.openAutoComplete}
                    closeAutoComplete={this.closeAutoComplete}
                    activeSelection={this.state.placeholder}
                    setPlaceholder={this.setPlaceholder}
                    isAdvanceQueryDropdownOpen={this.state.isAdvanceQueryDropdownOpen}
                    onClickMatchAnyOrAll={this.onClickMatchAnyOrAll}
                    closeAdvQueryModal={this.closeAdvanceQueryDropdown}
                    case="basicQuery"
                    suggestedSyntaxCase={this.state.suggestedSyntaxCase}
                    autoCompleteRef={this.autoCompleteRef}
                  />
                </div>
              )}
              <div className="flex-v-center AdvancedSearch__right-actions">
                <div onClick={this.onClickSearchIcon} tabIndex={0} className={`flex-v-center AdvancedSearch__activeSearch-icon mr-10 ${queryCheck ? '' : 'AdvancedSearch__activeSearch-disabledIcon'}`}>
                  {queryCheck ? (
                    <SearchTooltip content={'See Results'} position="top">
                      <Icon icon="SearchSmall" className='ml-5' />
                    </SearchTooltip>
                  ) : (
                    <Icon icon="SearchSmall" className='ml-5' />
                  )}
                </div>
                <div className='mr-10 AdvancedSearch__right-actions-divider'></div>
                {savedViews}
              </div>
            </div>
            <AdvanceSearchBody
              textContent={textContent}
              savedSearches={savedSearches.slice(0, maxSavedSearchesCount)}
              recentSearches={recentSearches}
              handleRecentSearchClick={this.handleRecentSearchClick}
              handleSavedSearchClick={this.handleSavedSearchClick}
              onClickSuggestedSyntax={this.onClickSuggestedSyntax}
              isLoading={this.state.isLoading}
              searchCase={this.state.case}
              advanceQueryArray={this.state.advanceQueryArray}
              fetchData={this.props.fetchData}
              selectedOption={this.state.selectedOption}
              horizontalAdvQueryLength={this.state.horizontalAdvQueryLength}
              queryArray={this.state.queryArray}
              updateHLengthByVCursor={this.updateHLengthByVCursor}
              hCursor={this.state.hCursor}
              vCursor={this.state.vCursor}
              hLength={this.state.hLength}
              ref={this.child}
              isLoadingSearches={this.state.isLoadingSearches}
              isBuildingRecentSearches={this.state.isBuildingRecentSearches}
              resetRecentSearch={this.resetRecentSearch}
              savedViewRef={this.savedViewRef}
              storedRecentUsedFields={this.props.storedRecentUsedFields || {}}
              onChangeRecentUsedFieldsOrder={this.props.onChangeRecentUsedFieldsOrder}
            />
            <AdvanceSearchFooter
              textContent={textContent}
              case={this.state.case}
              onClickFilters={this.onClickFilters}
              selectedOption={this.state.selectedOption}
              fetchData={this.props.fetchData}
              updateFilter={this.updateFilter}
              onClickFilterResult={this.onClickFilterResult}
              filters={this.state.filters}
              onClickSeeResults={this.onClickSeeResults}
              errorMessage={this.state.errorMessage}
              openAdvanceSearchView={this.onClickMatchAnyOrAll}
            />
          </div>
        </div>
        <div id={tooltipWrapperId}></div>
        <div id={portalId}></div>
      </SearchContext.Provider>
    )
  }
}

export default AdvanceSearch

//todo set case string in variable and use and change its name to queryCase
//todo rename selectedOption to Modules and selectedModule

// hotkeys.filter = (e: KeyboardEvent) => true;
// shortcutKeyMap.map((data) => {
//   hotkeys(data.key, (event) => {
//     if (this.state.isSearchVisible) {
//       event.preventDefault()
//       if (data.name === shortcutKeyMap[0].name) {
//         this.onClickMatchAnyOrAll(advanceQueryOpts[0].value)
//       }
//       if (data.name === shortcutKeyMap[1].name) {
//         this.onClickFilters()
//       }
//     }
//   })
// })

// shortcutKeyMap.map((data) => {
//   hotkeys.unbind(data.key)
// })


// export const shortcutKeyMap = [
//   {
//     key: 'command+Q, alt+q',
//     name: 'OpenAdvanceSearch',
//     macLabel: `(⌘Q)`,
//     otherLabel: `(Alt+Q)`
//   },
//   {
//     key: 'command+F, alt+f',
//     name: 'OpenFilter',
//     macLabel: `(⌘F)`,
//     otherLabel: `(Alt+F)`
//   },
// ]