export const getAttributesObject = (element: HTMLElement) => {
  const attributesNodeMap = element.attributes;
  const attributesMap = {};
  const attrsLen = attributesNodeMap.length;
  for (let i = 0; i < attrsLen; i++) {
    const attr = attributesNodeMap[i];
    if (attr && attr.name === "style") {
      continue
    }
    attributesMap[attr.name] = attr.value;
  }

  delete attributesMap['element'];
  return attributesMap;
}