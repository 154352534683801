import React, { useState } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Radio from '../../Radio'
import Help from '../../Help/Help'

const Url = (props: any) => {
  const { onChange, uniqueKey, schema, options, setOptions, type, isNonLocalizable, hasNonLocalizablePlan } = props
  const [initialSchema, setSchema] = useState(schema)
  const [initialOptions, setCTOptions] = useState(options)
  const onchange = () => {
    onChange && onChange()
    setSchema({ ...schema })
    // setCTOptions({ ...options })
  }

  const onUrlPrefixChange = (event: any) => {
    options.url_prefix = event.target.value
    setCTOptions({ ...options })
    setOptions(options)
    // onchange()
  }
  const onUrlPatternChange = (event: any) => {
    console.log('onUrlPatternChange -> event', event.target.value)
    options.url_pattern = event.target.value !== 'false' ? event.target.value : false
    if (event.target.value === 'false') {
      options.url_prefix = '/'
    }
    setCTOptions({ ...options })
    setOptions(options)
    // onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }
  const onCustomUrlChange = (event: any) => {
    options.url_pattern = event.target.value
    setCTOptions({ ...options })
    setOptions(options)
    // onchange()
  }
  let customUrl =
    options.url_pattern !== '/:title' &&
    options.url_pattern !== '/:year/:month/:day/:title' &&
    options.url_pattern !== '/:year/:month/:title' &&
    options.url_pattern !== false

  let disable = options.url_pattern === false && options.url_prefix === '/'

  if (type === 'basic') {
    return (
      <div>
        <div className="FormFields">
          <Field>
            <FieldLabel htmlFor="urlPrefix"> URL Pattern</FieldLabel>
            <Help text="Auto-fills the URL field based on pattern selected from the options given below. You can optionally add a prefix for the URL (e.g., ‘/blog’, ‘/articles/seo’)." />
            <TextInput
              name={`${uniqueKey}.urlPrefix`}
              placeholder="URL prefix (optional)"
              disabled={options.url_prefix === '/' && options.url_pattern === false}
              value={options.url_prefix ? (options.url_prefix !== '/' ? options.url_prefix : '') : ''}
              onChange={onUrlPrefixChange}
            />
          </Field>
          <Field>
            <div className="FormFields__selection-wrapper">
              <Radio
                radioDisplayType="block"
                name={`${uniqueKey}.url`}
                value="/:title"
                text="Title"
                checked={options.url_pattern === '/:title'}
                onChange={onUrlPatternChange}
              />
              <Radio
                radioDisplayType="block"
                name={`${uniqueKey}.url`}
                value="/:year/:month/:day/:title"
                text="Date and Title"
                checked={options.url_pattern === '/:year/:month/:day/:title'}
                onChange={onUrlPatternChange}
              />
              <Radio
                radioDisplayType="block"
                name={`${uniqueKey}.url`}
                value="/:year/:month/:title"
                text="Month and Title"
                checked={options.url_pattern === '/:year/:month/:title'}
                onChange={onUrlPatternChange}
              />
              <div className="FormFields__with-help">
                <Radio
                  radioDisplayType="block"
                  name={`${uniqueKey}.url`}
                  text="Custom"
                  value=""
                  checked={customUrl}
                  onChange={onUrlPatternChange}
                />
                <div className="FormFields__help-tooltip">
                  <Help
                    alignment="left"
                    type="secondary"
                    tippyProps={{
                      interactive: false
                    }}
                    text={
                      <>
                        <div className="mb-20">
                          Select this to set a custom pattern for the URLs by using the following available variables or
                          custom text, or a combination of both.
                        </div>
                        <div>
                          <span className="font-bold">:title</span> – Displays the title of the entry
                        </div>
                        <div>
                          <span className="font-bold">:unique_id</span> – Displays the unique ID of the entry
                        </div>
                        <div>
                          <span className="font-bold">:year</span> – Displays the year of creation
                        </div>
                        <div>
                          <span className="font-bold">:year_short</span> – Displays the short version of the year of
                          creation
                        </div>
                        <div>
                          <span className="font-bold">:month</span> – Displays the month(numeric) of creation
                        </div>
                        <div>
                          <span className="font-bold">:monthname</span> – Displays the name of the month (e.g. january)
                          of creation
                        </div>
                        <div>
                          <span className="font-bold">:monthname_short</span> – Displays the name of the month (in
                          short, e.g., jan)
                        </div>
                        <div>
                          <span className="font-bold">:day</span> – Displays the day (numeric, e.g., 30) of creation
                        </div>
                        <div>
                          <span className="font-bold">:year-:month-:day</span> – Displays the year/month/date of
                          creation
                        </div>
                        <div className="mt-20">
                          Example – If you use <span className="font-bold">‘:year-:month-:day’</span> in the custom
                          field, your URL will look like this :{' '}
                          <span className="font-bold">www.example.com/2016-04-30</span>"
                        </div>
                      </>
                    }
                  />
                </div>
                <TextInput
                  name={`${uniqueKey}.customUrl`}
                  disabled={!customUrl || disable}
                  className="mt-8"
                  value={
                    customUrl
                      ? options.url_pattern
                        ? options.url_pattern === 'false'
                          ? ''
                          : options.url_pattern || ''
                        : ''
                      : ''
                  }
                  placeholder="Enter custom URL"
                  onChange={onCustomUrlChange}
                />
              </div>
              <Radio
                name={`${uniqueKey}.url`}
                text="Disable"
                value="false"
                checked={options.url_pattern === false || options.url_pattern === 'false'}
                onChange={onUrlPatternChange}
              />
            </div>
          </Field>
        </div>
      </div>
    )
  } else if (type === 'advanced') {
    if (hasNonLocalizablePlan) {
      return (
        <div className="FormFields">
          <Field>
            <FieldLabel htmlFor="options">Options</FieldLabel>
            <div className="FormFields__selection-wrapper">
              {isNonLocalizable ? (
                <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                  <Checkbox
                    text="Non-localizable"
                    name={`${uniqueKey}.nonLocalization`}
                    checked={schema.non_localizable}
                    onChange={onOptionsChange}
                    disabled={isNonLocalizable}
                  />
                </Tooltip>
              ) : (
                <Checkbox
                  text="Non-localizable"
                  name={`${uniqueKey}.nonLocalization`}
                  checked={schema.non_localizable}
                  onChange={onOptionsChange}
                  disabled={isNonLocalizable}
                />
              )}
              <div className="nl-note">
                If enabled, editing this field is restricted in localized entries. The field will use the value of the
                master-language entry in all localized entries.
              </div>
            </div>
          </Field>
        </div>
      )
    }
  }
  return null
}
export default React.memo(Url)
