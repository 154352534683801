import React, { useEffect, useRef } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import Icon from '../Icon2/Icon'

const setCaretPos = (input_node, pos) => {
  if (input_node.setSelectionRange) {
    input_node.setSelectionRange(pos, pos)
  } else if (input_node.createTextRange) {
    input_node.createTextRange().move('character', pos)
  }
  input_node.focus()
}

const autoSize = node => {
  let mirror = document.querySelector('.Tag__item__mirror')
  if (!mirror) {
    mirror = document.createElement('div')
    mirror.setAttribute('class', 'Tag__item__mirror')
    document.querySelector('.Tag')?.appendChild(mirror)
  }
  let stringValue = node.value || ''
  mirror.textContent = stringValue
  const numberOfSpace = stringValue.split(' ').length - 1
  const widthWithoutSpace = +getComputedStyle(mirror).width.split('px')[0]
  const offsetforSpace = numberOfSpace * 7
  const totalwidthOfTag = widthWithoutSpace + offsetforSpace

  node.style.width = `${totalwidthOfTag}px`
}

const RenderTag = props => {
  const inputRef = useRef(null)
  const divRef = useRef(null)
  useEffect(() => {
    const node = inputRef.current
    if (node) {
      if (props.active) {
        node.focus()
      } else {
        node.blur()
      }
      autoSize(node)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const node = inputRef.current
    if (node) {
      if (props.active) {
        setCaretPos(node, props.caret)
      } else {
        node.blur()
      }
      autoSize(node)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active])

  const handleClick = e => {
    e.stopPropagation()
    const node = e.target
    if (node.parentNode.id.includes('remove_tag') || (node.childElementCount === 0 && node.type !== 'text')) {
      props.onRemove()
    }
  }
  const handleFocus = () => {
    divRef.current.children[0].classList.add('Tag--active')
    props.onFocus()
  }

  const handleBlur = () => {
    divRef.current.children[0].classList.remove('Tag--active')
    const node = inputRef.current
    props.onSave(node.value)
  }

  const handleChange = e => {
    const node = inputRef.current
    autoSize(node)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const node = inputRef.current
      if (node.value.trim()) {
        props.disableEditing()
        node.blur()
        setTimeout(() => {
          props.handleClick()
        })
      }
    }
    if(event.key === 'Escape'){
      event.target?.closest('.Tag')?.focus()
    }
    if(event.key === 'Tab' && event.target?.tabIndex === -1){
      const inputNodes = event?.target?.closest('.Tag')?.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>
      if (inputNodes?.length && inputNodes[0]?.getAttribute("tabindex") !== "0") {
        let i = 0
        while (i < inputNodes?.length) {
          inputNodes[i]?.setAttribute("tabIndex", "0")
          i++
        }
      } 
    }
    props.isLastTag && props.customKeyDown && props.customKeyDown(event)
  }

  return (
    <Draggable
      draggableId={props.tagId}
      index={props.index}
      key={props.tagId}
      isDragDisabled={!props.sortTag}
    >
      {(provided: any, snapshot: any) => {
        const dragHandleProps = { ...provided.dragHandleProps }
        delete dragHandleProps['role']
        return (
        <div ref={divRef} id={props.tagId}>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...dragHandleProps}
              className="Tag__itemWrapper"
              tabIndex={-1}
            >
              <div className="Tag__item__move-icon"><Icon icon="MoveIcon" size="extraSmall" /></div>
              <div
                className={'Tag__item flex' + (props.error ? ' Tag__item--error' : '')}
                onClick={handleClick}
              >
                <input
                  type="text"
                  defaultValue={props.children.toString()}
                  ref={inputRef}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  aria-label={`tag-${props.tagId}`}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                />
                <i className="Tag__item__close" id={`remove_tag-${props.index}`}>
                  <Icon icon="CancelSec" height="8" width="8" className="remove_tag" />
                </i>
              </div>
            </div>
          </div>
        // </div>
      )}}
    </Draggable>
  )
}

export default RenderTag