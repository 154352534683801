import React from 'react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from './style.module.css'
import { elementCategories } from '../elementCategories'


const blockquote = (props) => {
  return (
    <div {...props.attributes} {...props.attrs} className={styles['blockquote']}>
      <blockquote >
        {props.children}
      </blockquote>
    </div>
  )
}

Registry.register(
  {
    Component: blockquote,

    // iconName: 'ri-double-quotes-l',
    title: <blockquote>Block Quote</blockquote>,
    subtitle: 'Capture a Quote',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+7' },
    isContentStackElement: true,
    inBasicToolbar: true,
  },
  { id: 'blockquote', registry: 'element' }
)

export * from './deserializer'
export * from './withBlockquote'
export default blockquote
