import { SkeletonTile } from '../../../../../SkeletonTile/SkeletonTile'
import React from 'react'

const DiscussionHeaderLoader = () => {
  return (
    <SkeletonTile
      numberOfTiles={1}
      tileHeight={10}
      tileWidth={150}
      tileBottomSpace={0}
      tileTopSpace={0}
      tileleftSpace={15}
    />
  )
}

export default DiscussionHeaderLoader