import { Registry } from '@react-registry'

import { isEntireEditorSelected } from '../../../utils/isEntireEditorSelected'
import { clearEditor } from './clearEditor'

export const withClearEditor = (editor) => {
  const { deleteFragment, deleteBackward } = editor

  editor.deleteFragment = (args) => {
    if (isEntireEditorSelected(editor)) {
      clearEditor(editor)
      return
    }
    deleteFragment(args)
  }

  // convert to paragraph if nothing on the editor
  editor.deleteBackward = (args) => {
    if (isEntireEditorSelected(editor)) {
      clearEditor(editor)
      return
    }
    deleteBackward(args)
  }

  return editor
}

Registry.register(withClearEditor, {
  id: 'withClearEditor',
  registry: 'plugin'
})
