import React, { ReactNode } from 'react'
import cn from 'classnames'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import './ListRow.css'

export type ListRowProps = {
  content: string | ReactNode
  active: boolean
  onClick?: Function
  onKeyDown?: any
  leftIcon?: React.ReactNode
  rightArrow?: boolean
  lockIcon?: boolean
  className?: string
  id?: string
  linkToRow?: string
  newEntryIcon?: boolean
  newEntryOnClick?: Function
  testId?: string
}
const GetLeftIconContent = ({ content, lockIcon }) => {
  if (typeof content === 'string' && content.length > 15) {
    return (
      <Tooltip content={content} position="top" interactive={false}>
        <div className={`ListRow__content ${lockIcon ? 'ListRow__lock-width' : 'ListRow__content-width'}`}>
          {content}
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={`ListRow__content ${lockIcon ? 'ListRow__lock-width' : 'ListRow__content-width'}`}>{content}</div>
  )
}
const GetContent = ({ content, lockIcon, leftIcon }) => {
  let listContent = null
  if (!leftIcon) {
    listContent =
      typeof content === 'string' && content.length > 20 ? (
        <Tooltip content={content} position="top" interactive={false}>
          <div className={`ListRow__content ${lockIcon ? 'ListRow__content-width' : 'ListRow__maxcontent-width'}`}>
            {content}
          </div>
        </Tooltip>
      ) : (
        <div className={`ListRow__content ${lockIcon ? 'ListRow__content-width' : 'ListRow__maxcontent-width'}`}>
          {content}
        </div>
      )
  }
  return listContent
}

const ListRow = (props: ListRowProps) => {
  const {
    active,
    leftIcon,
    rightArrow,
    lockIcon,
    className,
    onClick,
    id,
    onKeyDown,
    content,
    linkToRow,
    newEntryIcon,
    newEntryOnClick,
    testId = 'cs-list-row'
  } = props

  const onClickFunction = (event) => {
    if (onClick && !event.ctrlKey) {
      onClick(event)
    }
  }

  const newEntryClickFunction = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (newEntryOnClick) {
      newEntryOnClick(event, id)
    }
  }

  const classNames = cn('ListRow', className, {
    [`ListRow--active`]: active,
    [`ListRow--newEntry--active`]: newEntryIcon
  })

  const ListRowNode = () => {
    return (
      <div
        data-test-id={testId}
        onClick={onClickFunction}
        onKeyDown={onKeyDown}
        className={classNames}
        id={id}
        tabIndex={linkToRow ? null : 0}>
        {leftIcon && <span className="ListRow__left-icon">{leftIcon}</span>}
        {leftIcon ? (
          <GetLeftIconContent content={content} lockIcon={lockIcon} />
        ) : (
          <GetContent content={content} lockIcon={lockIcon} leftIcon={leftIcon} />
        )}
        {lockIcon && <Icon icon="Lock" className="ListRow__lock-icon" />}
        {rightArrow && <Icon icon="Right" className="ListRow__right-icon" />}
        {newEntryIcon && (
          <div
            className="ListRow__new-entry-wrapper"
            id="ListRow__new-entry-create-icon"
            onClick={newEntryClickFunction}>
            <Tooltip content="Create Entry" position="top">
              <div className="ListRow__new-entry" id="ListRow__new-entry-create-icon">
                <Icon icon="NewEntry" id="ListRow__new-entry-create-icon" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {linkToRow ? (
        <a href={linkToRow || undefined} className="ListRow-wrapper">
          <ListRowNode />
        </a>
      ) : (
        <ListRowNode />
      )}
    </>
  )
}

export default ListRow
