import axios from "axios";
import { isBlt, only_query } from "../../../../../../../../utils/helpers/query";

function ClearPrevRequest() {
  let prevRequests = {};
  return {
    addReq: function (reqName) {
      prevRequests[reqName] = axios.CancelToken.source();
      return prevRequests[reqName];
    },
    cancelAll: function () {
      Object.keys(prevRequests).forEach(key => {
        const cancelToken = prevRequests[key];
        cancelToken && cancelToken.cancel();
      });
      prevRequests = {}
    }
  }
}

const { addReq, cancelAll } = ClearPrevRequest();

export const fetchEntries = async (props) => {
  let { contentTypeUid, editor, limitPerPage, query, skipEntries } = props;
  const requestProps = editor['requestProps'] || {};
  let { apiKey, authToken, assetUrl, locale, branch, customizedPagination } = requestProps;
  if (!query) {
    query = ''
  } else {
    if (!customizedPagination) {
      query = `&typeahead=${query}`
    } else {
      if (isBlt(query)) {
        query = `&query={"uid": "${query}"}`
      } else {
        query = `&query={"title": {"$regex":"${query}", "$options": "i"}}`
      }
    }
    cancelAll();
  }
  let onlyFields = only_query(['_version', 'locale', 'uid', 'ACL', 'created_at', 'created_by', 'title', 'updated_at', 'updated_by', 'publish_details', 'url'])
  let url = `${assetUrl}/content_types/${contentTypeUid}/entries?desc=updated_at${query}&include_publish_details=true&locale=${locale}&limit=${limitPerPage}&skip=${skipEntries}&${onlyFields}`
  let countUrl = `${assetUrl}/content_types/${contentTypeUid}/entries?locale=${locale}${query}&count=true`
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);
  let res;
  const fetchEntriesCall = await axios({
    method: 'GET',
    url,
    headers,
    cancelToken: addReq(`${contentTypeUid}_${skipEntries}`).token
  }).then(res => {
    const { entries } = res['data'];
    if (customizedPagination) {
      const newCount = skipEntries + entries.length;
      res['data']['count'] = entries.length >= limitPerPage ? newCount + limitPerPage : newCount;
    }
    return res
  });
  const countCall = async () => {
    if (customizedPagination) {
      return {
        data: { entries: 0 }
      };
    } else {
      return await axios({
        method: 'GET',
        url: countUrl,
        headers
      })
    }
  };
  return Promise.all([
    fetchEntriesCall,
    countCall()
  ]).then(_res => {
    res = _res[0].data;
    if (!customizedPagination) {
      res['count'] = _res[1].data.entries;
    }
    let resJson = res;

    const modifiedEntries = resJson.entries.map((entry) => {
      return {
        ...entry,
        key: entry['uid'],
      }
    })
    resJson.entries = modifiedEntries
    return resJson
  }).catch(err => {
    console.log(err, 'ERROR');
  })

}

export const fetchEntry = async (props) => {
  const { contentTypeUid, entryUid, editor } = props
  const requestProps = editor['requestProps'] || {};
  const { apiKey, authToken, assetUrl, branch } = requestProps
  let url = `${assetUrl}/content_types/${contentTypeUid}/entries/${entryUid}`
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);
  let res = await axios({
    method: 'GET',
    url,
    headers
  })
  return res.data
}

export const uploadImage = async (props) => {
  const { requestProps, files } = props
  const { apiKey, assetUrl, authToken, branch } = requestProps
  let url = `${assetUrl}/assets`

  let response = []
  try {
    const res = await Promise.all(
      files.map(async (file) => {
        let formData = new FormData()
        formData.append('asset[upload]', file.originFileObj, file.name)
        const headers = {
          api_key: apiKey,
          'Content-Type': 'application/json'
        }
        authToken && (headers['authToken'] = authToken);
        branch && (headers['branch'] = branch);
        return await axios({
          method: 'POST',
          url,
          headers,
          data: formData
        });
      })
    )
    response = await Promise.all(res.map((res: any) => res.data))
  } catch (err) { }

  return response
}
