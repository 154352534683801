import moment from 'moment'

import { queryBuilderEntries, queryParserEntries, fetchDataForQuery, getQueryType } from './helpers'
import { options, defaultTextContent, operatorByValue } from './constants'
import { queryBuilderAssets, queryParserAssets } from './assets'
// import { setCounterForAdvanceQuery } from './helpers'

const textInputLabel = {
  group: '',
  queryType: 'textInputLabel',
  nextQuery: 'inTextInput',
  type: 'key',
  options: []
}

export const inTextInput = {
  group: '',
  queryType: 'inTextInput',
  type: 'value',
  options: []
}

const advTextInputLabel = {
  group: '',
  queryType: 'advTextInputLabel',
  nextQuery: 'inTextInput',
  type: 'key',
  options: []
}

const selectLabel = {
  group: '',
  queryType: 'selectLabel',
  nextQuery: 'inSelect',
  type: 'key',
  options: []
}

export const inSelect = {
  group: '',
  queryType: 'inSelect',
  type: 'value',
  options: []
}

export const numberLabel = {
  group: '',
  queryType: 'numberLabel',
  nextQuery: 'inNumber',
  type: 'key',
  options: []
}

export const inNumber = {
  group: '',
  queryType: 'inNumber',
  type: 'value',
  options: []
}

export const booleanLabel = {
  group: '',
  queryType: 'booleanLabel',
  nextQuery: 'inBoolean',
  type: 'key',
  options: []
}

export const inBoolean = {
  group: '',
  queryType: 'inBoolean',
  type: 'value',
  options: [
    { label: 'true', value: 'true' },
    { label: 'false', value: 'false' }
  ]
}

const dateLabel = {
  group: '',
  queryType: 'date',
  nextQuery: 'inDate',
  type: 'key',
  options: []
}

export const fileLabel = {
  group: '',
  queryType: 'fileLabel',
  nextQuery: 'inFile',
  type: 'key',
  options: []
}

export const inFile = {
  group: '',
  queryType: 'inFile',
  type: 'value',
  options: []
}

export const linkLabel = {
  group: '',
  queryType: 'linkLabel',
  nextQuery: 'inTextInput',
  type: 'key',
  options: []
}

export const referenceLabel = {
  group: '',
  queryType: 'referenceLabel',
  nextQuery: 'inReference',
  type: 'key',
  options: []
}

export const inReference = {
  group: '',
  queryType: 'inReference',
  type: 'value',
  options: []
}


export const getCtUserDefinedFields = (schema = [], userDefinedFields, parentUid, parentDisplayName) => {
  schema.forEach((schemaObj) => {
    let finalUid = parentUid ? parentUid + '.' + schemaObj.uid : schemaObj.uid
    let label = parentDisplayName ? parentDisplayName + ' / ' + schemaObj.display_name : schemaObj.display_name
    //console.log('label test', label, finalUid)

    const field_metadata = schemaObj.field_metadata || {}

    const { markdown, allow_rich_text, multiline } = field_metadata

    if (schemaObj.data_type === 'text') {
      if (markdown || allow_rich_text || multiline) {
        const advTextInputOption = [
          { label: schemaObj.display_name, value: finalUid, info: parentUid ? label : `/ ${label}`, nestingLevel: label ? label.split('/').length - 1 : 0 }
        ]

        userDefinedFields.push({ ...advTextInputLabel, options: advTextInputOption })
      } else if (
        schemaObj.display_type === 'dropdown' ||
        schemaObj.display_type === 'radio' ||
        schemaObj.display_type === 'checkbox'
      ) {
        let valueOptions = schemaObj.enum.choices.map((choice) => ({
          label: choice.value,
          value: choice.value
        }))
        let selectLabelOption = [
          {
            label: schemaObj.display_name,
            value: finalUid,
            valueOptions,
            info: parentUid ? label : `/ ${label}`,
            nestingLevel: label ? label.split('/').length - 1 : 0
          }
        ]
        const select = {
          ...selectLabel,
          options: selectLabelOption
        }
        userDefinedFields.push(select)
      } else {
        const textInputOption = [
          { label: schemaObj.display_name, value: finalUid, info: parentUid ? label : `/ ${label}`, nestingLevel: label ? label.split('/').length - 1 : 0 }
        ]
        userDefinedFields.push({ ...textInputLabel, options: textInputOption })
      }
    }

    if (schemaObj.data_type === 'number') {
      const numberOption = [{ label: schemaObj.display_name, value: finalUid, info: parentUid ? label : `/ ${label}`, nestingLevel: label ? label.split('/').length - 1 : 0 }]
      userDefinedFields.push({ ...numberLabel, options: numberOption })
    }

    if (schemaObj.data_type === 'boolean') {
      const booleanOption = [{ label: schemaObj.display_name, value: finalUid, info: parentUid ? label : `/ ${label}`, nestingLevel: label ? label.split('/').length - 1 : 0 }]
      userDefinedFields.push({ ...booleanLabel, options: booleanOption })
    }
    if (schemaObj.data_type === 'isodate') {
      const dateLabelOption = [
        { label: schemaObj.display_name, value: finalUid, info: parentUid ? label : `/ ${label}`, nestingLevel: label ? label.split('/').length - 1 : 0 }
      ]
      userDefinedFields.push({ ...dateLabel, options: dateLabelOption })
    }

    if (schemaObj.data_type === 'file') {
      let fileLabelOption: any = [
        { label: schemaObj.display_name, value: finalUid, info: parentUid ? label : `/ ${label}`, nestingLevel: label ? label.split('/').length - 1 : 0 }
      ]
      userDefinedFields.push({ ...fileLabel, options: fileLabelOption })
    }
    if (schemaObj.data_type === 'link') {
      const nestingLevel = label ? label.split('/').length - 1 : 0;
      const linkLabelOption = [
        { label: 'Title', value: `${finalUid}.title`, info: parentUid ? label : `/ ${label}`, nestingLevel: nestingLevel + 1 },
        { label: 'URL', value: `${finalUid}.href`, info: parentUid ? label : `/ ${label}`, nestingLevel: nestingLevel + 1 }
      ]
      userDefinedFields.push({
        ...linkLabel,
        group: schemaObj.display_name,
        options: linkLabelOption,
        nestingLevel
      })
    }

    if (schemaObj.data_type === 'reference') {
      const referenceLabelOption = [
        {
          label: schemaObj.display_name,
          value: finalUid,
          valueOptions: typeof schemaObj.reference_to === 'string' ? [schemaObj.reference_to] : schemaObj.reference_to,
          info: parentUid ? label : `/ ${label}`,
          nestingLevel: label ? label.split('/').length - 1 : 0
        }
      ]
      userDefinedFields.push({ ...referenceLabel, options: referenceLabelOption })
    }

    if (schemaObj.data_type === 'group') {
      const parentGroup = {
        group: schemaObj.display_name,
        options: [],
        nestingLevel: label ? label.split('/').length - 1 : 0
      }
      userDefinedFields.push(parentGroup)
      getCtUserDefinedFields(schemaObj.schema, userDefinedFields, finalUid, label)
    }
    if (schemaObj.data_type === 'global_field') {
      const parentGroup = {
        group: schemaObj.display_name,
        options: [],
        nestingLevel: label ? label.split('/').length - 1 : 0
      }
      userDefinedFields.push(parentGroup)
      getCtUserDefinedFields(schemaObj.schema, userDefinedFields, finalUid, label)
    }

    if (schemaObj.data_type === 'blocks') {
      let blockUids = parentUid ? parentUid + '.' + schemaObj.uid : schemaObj.uid
      let blockLabel = parentDisplayName ? parentDisplayName + ' / ' + schemaObj.display_name : schemaObj.display_name

      const parentGroup = {
        group: schemaObj.display_name,
        options: [],
        nestingLevel: label ? label.split('/').length - 1 : 0
      }
      userDefinedFields.push(parentGroup)

      schemaObj.blocks.forEach((block) => {
        let singleBlockUids = blockUids + '.' + block.uid
        let singleBlockLabel = blockLabel + ' / ' + block.title
        const parentGroup = {
          group: block.title,
          options: [],
          nestingLevel: singleBlockLabel ? singleBlockLabel.split('/').length - 1 : 0
        }
        userDefinedFields.push(parentGroup)
        getCtUserDefinedFields(block.schema, userDefinedFields, singleBlockUids, singleBlockLabel)
      })
    }
  })

  return userDefinedFields
}


//fn checks for error
//and update info to its chekcildren
//set Error cursor and updatecontent type child ct_status

let allFiledsExceptUserDefined = [
  'created_by',
  'updated_by',
  '_published_by',
  '_publish_environment',
  'created_at',
  'updated_at',
  '_published_at',
  'locale',
  'tags',
  'uid',
  '_content_type_uid'
]

let errorMessage = ''
const setErrorCursorAndCtStatus = (advanceQueryChildren, ct_status, errorCursorObj, ctLength) => {
  for (let child of advanceQueryChildren) {
    child.ct_status = ct_status
    const queryArray = child.queryArray
    const foundCtType = queryArray.find((query) => query.key[0].value === '_content_type_uid')

    const checkForUserDefFields = queryArray.find((query) => !allFiledsExceptUserDefined.includes(query.key[0].value))

    //todo think if we need to reset err here in else
    if (foundCtType && ctLength) {
      child.error_cursor = child.cursorY
      errorCursorObj.cursor = child.cursorY
      errorMessage = defaultTextContent.validations.advance_query.no_cursor_found
      child.errorMessage = errorMessage
    } else if (checkForUserDefFields && (ctLength > 1 || !ctLength)) {
      child.error_cursor = child.cursorY
      errorCursorObj.cursor = child.cursorY
      errorMessage = defaultTextContent.validations.advance_query.userdef_field_error
      child.errorMessage = errorMessage
    } else if ((foundCtType && !ctLength) || (checkForUserDefFields && ctLength === 1)) {
      child.error_cursor = -1
      errorCursorObj.cursor = -1
      errorMessage = ''
      child.errorMessage = ''
    }

    if (child.children.length) {
      setErrorCursorAndCtStatus(child.children, ct_status, errorCursorObj, ctLength)
    }
  }
}

export const updateSelectedContentTypeStatus = (queryObject, type) => {
  errorMessage = ''
  let ct_status
  const queryArray = queryObject.queryArray
  const selectedContentTypes = queryArray.filter((query) => {
    const { key, value } = query
    if (value.length && key[0].value === '_content_type_uid') {
      return true
    }
    return false
  })

  if (selectedContentTypes.length) {
    if (selectedContentTypes.length === 1) {
      ct_status = {
        isMullti: false,
        ct_uid: selectedContentTypes[0].value[0].value
      }
      queryObject.ct_status = { ...ct_status, show_ct: true }
    } else {
      // console.log('selctedct types multi', selectedContentTypes)
      const ctUids = selectedContentTypes.map((ct) => ct.value[0].value)
      // console.log('updateSelectedContentTypeStatus -> ctUids', ctUids)
      ct_status = {
        isMullti: true,
        ct_uid: ctUids
      }
      queryObject.ct_status = { ...ct_status, show_ct: true }
    }

    let errorCursorObj = { cursor: -1 }
    setErrorCursorAndCtStatus(queryObject.children, ct_status, errorCursorObj, selectedContentTypes.length)

    if (errorCursorObj.cursor >= 0) {
      // error_cursors = [...error_cursors, foundErrorCursor]
      console.log('here -> error_cursors', errorCursorObj.cursor)
    } else if (queryObject.error_cursor >= 0) {
      queryObject.error_cursor = -1
    }
  } else if (type === 'delete') {
    // console.log('on delete lenght ccheck', selectedContentTypes.length)
    queryObject.ct_status = ''
    queryObject.error_cursor = -1
    setErrorCursorAndCtStatus(queryObject.children, '', { cursor: -1 }, selectedContentTypes.length)
  }
  return errorMessage
}

export const validateAdvanceQuery = (advanceQueryArray, errorObject) => {
  for (let queryObject of advanceQueryArray) {
    const queryArray = queryObject.queryArray

    if (queryObject.error_cursor && queryObject.error_cursor > 0) {
      errorObject.isErrorCursorFound = true
      errorObject.errorMessage = queryObject.errorMessage
    }

    if (queryArray.length) {
      errorObject.isQueryEmpty = false
    }

    if (queryArray.length && !queryArray[queryArray.length - 1].isQueryCompleted) {
      errorObject.isQueryEmpty = true
    }

    if (queryObject.children.length) {
      validateAdvanceQuery(queryObject.children, errorObject)
    }
  }
}

// const methodNameByModule = {
//   entries: queryBuilderEntries,
//   assets: queryBuilderAssets
// }

const removeEmptyAndOrQuery = (queryObjectsForAdvQuery) => {
  queryObjectsForAdvQuery.forEach((queryObject, index) => {
    const key = Object.keys(queryObject)[0]

    if ((key === '$and' || key === '$or') && !queryObject[key].length) {
      queryObjectsForAdvQuery.splice(index, 1)
    }
    if ((key === '$and' || key === '$or') && queryObject[key].length) {
      removeEmptyAndOrQuery(queryObject[key])
    } else if ((key === '$and' || key === '$or') && !queryObject[key].length) {
      queryObjectsForAdvQuery.splice(index, 1)
    }
  })
}

let isCtFoundInParent = false
const buildQueryObject = (advanceQueryArray, nestedArray, selectedModule) => {
  for (let queryObject of advanceQueryArray) {
    const operator = queryObject.queryType === 'matchAll' ? '$and' : '$or'
    const queryArray = queryObject.queryArray

    const foundCtType = queryArray.find((query) => query.key[0].value === '_content_type_uid')
    // console.log('buildQueryObject -> foundCtType', foundCtType)
    if (foundCtType) {
      isCtFoundInParent = true
    }
    let newArray = []
    let currentQueryObject = { [operator]: newArray }
    nestedArray.push(currentQueryObject)

    let queryBuilderForModule: any

    if (selectedModule === options[0].value) {
      queryBuilderForModule = queryBuilderEntries
    } else if (selectedModule === options[1].value) {
      queryBuilderForModule = queryBuilderAssets
    }

    const queryBuilderResult: any = queryBuilderForModule({ queryArray, queryCase: 'advanceQuery' })
    const { queryObject: { $and = [], ...rest } = {} } = queryBuilderResult

    const queryObjectForQueryArray = queryBuilderResult.queryObject ? queryBuilderResult.queryObject.$and : []
    queryObjectForQueryArray.forEach((query) => {
      newArray.push(query)
    })

    //inserting any other key thann and array
    const keyArray = Object.keys(rest)
    if (keyArray.length) {
      keyArray.forEach((key) => {
        if (!(key === '_content_type_uid' && isCtFoundInParent)) {
          currentQueryObject[key] = rest[key]
        }
      })
    }

    if (queryObject.children.length) {
      buildQueryObject(queryObject.children, newArray, selectedModule)
    }
  }
}

export const buildQueryObjectForAdvCase = (advanceQueryArray, selectedModule) => {
  let queryObjectsForAdvQuery = []
  isCtFoundInParent = false

  buildQueryObject(advanceQueryArray, queryObjectsForAdvQuery, selectedModule)

  //todo write a better method to remove all nested empty query array
  removeEmptyAndOrQuery(queryObjectsForAdvQuery)
  return queryObjectsForAdvQuery[0]
}

const checkSelectedContentType = (currentQueryObjectArray) => {
  const ctQueries = currentQueryObjectArray.filter(query => Object.keys(query)[0] === '_content_type_uid')

  if (ctQueries.length) {
    if (ctQueries.length === 1) {
      return {
        isMulti: false,
        ct_Uid: Object.values(ctQueries[0]._content_type_uid)[0]
      }
    } else {
      const ctUids = ctQueries.map((ct) => Object.values(ct._content_type_uid)[0])

      return {
        isMulti: true,
        ct_Uid: ctUids
      }
    }
  }
  return

}


const getUserDefinedQueryValue = async (query, value, queryType, fetchData) => {
  try {
    let formattedValue: any = [{ label: value, value }]
    if (queryType === 'date') {
      const localDate = moment(value).format('YYYY-MM-DD')
      formattedValue = [{ label: localDate, value: localDate }]
    }

    if (queryType === 'referenceLabel' && query.contentTypeUid) {
      const response = await fetchData.fetchEntryByUid({ contentTypeUid: query.contentTypeUid, entryUid: value })
      formattedValue = [{ label: response.title || value, value, ctUid: query.contentTypeUid }]
    }

    if (queryType === 'fileLabel') {
      const response = await fetchData.fetchAssets({ assetUid: value })
      const assetLabel = response.data[0] && response.data[0].label || value
      formattedValue = [{ label: assetLabel, value }]
    }

    return formattedValue
  } catch (error) {
    throw error
  }
}

const getUserDefinedQueryByQueryKey = async (query, userDefinedFields, fetchData) => {
  try {
    const queryKey = Object.keys(query)[0]
    const value = Object.values(query[queryKey])[0]
    const operator = Object.keys(query[queryKey])[0]

    for (let suggestion of userDefinedFields) {
      const foundSuggestionOptIndex = suggestion.options.findIndex(option => option.value === queryKey)
      if (foundSuggestionOptIndex !== -1) {
        const formattedValue = await getUserDefinedQueryValue(query, value, suggestion.queryType, fetchData)
        return {
          key: [suggestion.options[foundSuggestionOptIndex]],
          operator: [operatorByValue[operator]],
          value: formattedValue,
          isQueryCompleted: true,
          queryType: suggestion.queryType,
          nextQuery: suggestion.nextQuery
        }
      }
    }
  } catch (error) {
    throw error
  }
}

const parsedUserdefinedQueryObject = async (ctSchema, userdefinedQueryObjectArray, fetchData) => {
  try {
    const userDefinedFields = getCtUserDefinedFields(ctSchema, [], '', '')

    let queryArray = []
    for (let query of userdefinedQueryObjectArray) {
      const parsedQuery = await getUserDefinedQueryByQueryKey(query, userDefinedFields, fetchData)
      if (parsedQuery) {
        queryArray.push(parsedQuery)
      }
    }
    return queryArray
  } catch (error) {
    console.log("parsedUserdefinedQueryObject", error)
    throw error
  }

}

const parsedWorkFlowQueryObject = (workflowQueryObjectArray, cacheResponse) => {
  const workflowStagesResponse = cacheResponse.workflowStagesResponse || []
  console.log("Adv serach parsing -> globalsearch  workflowStagesResponse", workflowStagesResponse)

  const key = { label: "Workflow Stage", value: "_workflow" }
  const queryType = "workflowLabel"
  const nextQuery = "inWorkflow"
  let queryArray = []
  workflowQueryObjectArray.forEach(query => {
    const queryKey = Object.keys(query)[0]
    const value = Object.values(query[queryKey])[0]
    const operator = Object.keys(query[queryKey])[0]

    let workflowStageLabel = value
    let valueOptions = workflowStagesResponse

    const foundStages = workflowStagesResponse.find(stage => stage.value === value)

    if (foundStages) {
      workflowStageLabel = foundStages.label
      valueOptions = workflowStagesResponse.filter(stage => stage.workflowUid === foundStages.workflowUid)
    }

    console.log("parsedWorkFlowQueryObject ->globalsearch  foundStages", foundStages)


    queryArray.push({
      key: [{ ...key, valueOptions }],
      operator: [operatorByValue[operator]],
      value: [{ label: workflowStageLabel, value }],
      isQueryCompleted: true,
      queryType,
      nextQuery
    })
  })
  return queryArray
}

export const advanceQueryParserForEntries = async (queryObject, cacheResponse, fetchData, selectedCtStatus = null, isRoot = true) => {
  try {
    const parentMatchOperator = Object.keys(queryObject)[0]
    const currentQueryObjectArray = queryObject[parentMatchOperator]

    const matchoperator = ['$and', '$or']

    let childQueryArray = []
    let currentSyntaxQueryObjectArray = []
    let userdefinedQueryObjectArray = []
    let workflowQueryObjectArray = []
    let children = []

    //todo test selected `ctStatus` when duplicate cases fixes
    const selectedCtResponse = checkSelectedContentType(currentQueryObjectArray)
    if (selectedCtResponse) {
      selectedCtStatus = selectedCtResponse
    }

    if (matchoperator.includes(parentMatchOperator)) {
      currentQueryObjectArray.forEach(query => {
        if (matchoperator.includes(Object.keys(query)[0])) {
          //push all and/or query object
          childQueryArray.push(query)
        } else if (allFiledsExceptUserDefined.includes(Object.keys(query)[0])) {
          //push all syntax query Object
          currentSyntaxQueryObjectArray.push(query)
        } else if (Object.keys(query)[0] === '_workflow') {
          //workflow case
          workflowQueryObjectArray.push(query)
        }
        else {
          //all userdefined case
          userdefinedQueryObjectArray.push(query)
        }
      })
    }

    //parsed userdefined query
    let userDefinedQueryArray = []
    let workflowQueryArray = []
    if (selectedCtStatus && !selectedCtStatus.isMulti && userdefinedQueryObjectArray.length) {
      const singleCtResponse = await fetchData.fetchSingleContentType(selectedCtStatus.ct_Uid)
      userDefinedQueryArray = await parsedUserdefinedQueryObject(singleCtResponse.content_type.schema, userdefinedQueryObjectArray, fetchData)
    }

    if (workflowQueryObjectArray.length) {
      workflowQueryArray = parsedWorkFlowQueryObject(workflowQueryObjectArray, cacheResponse)
      // const selectedCtUid = selectedCtStatus.isMulti ? selectedCtStatus.ct_Uid : [selectedCtStatus.ct_Uid]
      // const response = await fetchData.fetchWorkflowByCtUids(selectedCtUid)
    }

    const currentQuery = { $and: currentSyntaxQueryObjectArray }
    let queryArray = await queryParserEntries(currentQuery, '', fetchData, cacheResponse)
    for (let subQuery of childQueryArray) {
      const parsedQuery = await advanceQueryParserForEntries(subQuery, cacheResponse, fetchData, selectedCtStatus, false)

      if (parsedQuery) {
        children.push(parsedQuery)
      }
    }

    const data: any = {}
    if (isRoot) data.root = true
    data.children = children
    data.queryArray = [...queryArray, ...userDefinedQueryArray, ...workflowQueryArray]
    data.queryType = parentMatchOperator === '$and' ? 'matchAll' : 'matchAny'
    return data
  } catch (error) {
    console.log("advanceQueryParserForEntries error", error)
    throw error
  }
}


const filterAdvanceQueryObject = (queryObject) => {
  const parentMatchOperator = Object.keys(queryObject)[0]
  const currentQueryObjectArray = queryObject[parentMatchOperator]

  const matchoperator = ['$and', '$or']

  let childQueryArray = []
  if (matchoperator.includes(parentMatchOperator)) {
    currentQueryObjectArray.forEach((query, index) => {
      const queryKey = Object.keys(query)[0]
      const valueOfQuery = query[queryKey]

      if (matchoperator.includes(Object.keys(query)[0])) {
        childQueryArray.push(query)
      }
      //query.content_type_uid
      else if (valueOfQuery.content_type_uid) {
        const { content_type_uid, ...rest } = valueOfQuery

        const operator = valueOfQuery.$in ? '$eq' : '$ne'
        const value = valueOfQuery.$in ? valueOfQuery.$in[0] : valueOfQuery.$nin[0]

        const updatedQuery = { [queryKey]: { [operator]: value }, contentTypeUid: valueOfQuery.content_type_uid }

        currentQueryObjectArray.splice(index, 1, updatedQuery)
      }
    });
  }

  for (let subQuery of childQueryArray) {
    filterAdvanceQueryObject(subQuery)
  }
};

export const formatOlduiEntriesQueries = (query: any = {}, selectedModule) => {
  try {
    const queryObjectArray = query.$and || query.$or
    const contentTypeQuery = query._content_type_uid
    const rootQueryKey = Object.keys(query).includes('$and') ? '$and' : '$or'

    const stringifiedQuery = JSON.stringify(query)
    const queryType = getQueryType(stringifiedQuery, selectedModule)

    if (selectedModule === options[0].value && queryType === 'basicQuery' && contentTypeQuery && Array.isArray(contentTypeQuery.$in) && contentTypeQuery.$in.length) {
      let ctQuery = []
      let controlledQueryObjectArray = queryObjectArray || []
      const contentTypeInQuery = contentTypeQuery.$in
      contentTypeInQuery.forEach(ctUid => {
        ctQuery.push({ _content_type_uid: { $eq: ctUid } })
      })
      let formattedQuery = { $and: [...ctQuery, ...controlledQueryObjectArray] }
      return formattedQuery
    }

    if (selectedModule === options[0].value && contentTypeQuery && Array.isArray(queryObjectArray)) {
      let ctQuery = []
      let formattedQuery = {}
      const contentTypeInQuery = contentTypeQuery.$in || Array.isArray(contentTypeQuery) && contentTypeQuery
      if (Array.isArray(contentTypeInQuery) && contentTypeInQuery.length) {
        contentTypeInQuery.forEach(ctUid => {
          ctQuery.push({ _content_type_uid: { $eq: ctUid } })
        })
      }
      formattedQuery = ctQuery.length ? { [rootQueryKey]: [...ctQuery, { [rootQueryKey]: queryObjectArray }] } : { [rootQueryKey]: queryObjectArray }
      filterAdvanceQueryObject(formattedQuery)
      return formattedQuery
    }
    return query
  } catch (error) {
    console.log("formatOlduiEntriesQueries -> error", error)
  }
}