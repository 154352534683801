import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { tooltipWrapperId } from './util/constants';

const SearchTooltip = (props) => {
  const {
    content = '',
    position = 'top',
    children
  } = props || {}

  return (
    <Tooltip
      content={content}
      position={position}
      showArrow={false}
      appendTo={document.getElementById(tooltipWrapperId)}
      distance={10}
      interactive={false}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default SearchTooltip;