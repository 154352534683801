import React, { Component } from 'react'
import cn from 'classnames'
import './ValidationMessage.css'

export type ValidationMessageProps = {
  /**
   * Lets you pass the class names to be appended to this prop.
   */
  className?: string
  /**
   * Pass the CSS properties for the validation message.
   */
  style?: React.CSSProperties
  /**
   * Add child elements defined within a component.
   */
  children: React.ReactNode
  /**
   * Pass an ID that you can use for testing purposes. It is applied as a data attribute (data-test-id).
   */
  testId?: string
  /**
   * A new version of ValidationMessage.
   */
  version?: 'v2'
} & typeof defaultProps

const defaultProps = {
  testId: 'cs-validation-message'
}
export class ValidationMessage extends Component<ValidationMessageProps> {
  static defaultProps = defaultProps

  render() {
    const { className, children, testId, ...otherProps } = this.props
    const classNames = cn('ValidationMessage', className)
    const versionClass = `${otherProps?.version === 'v2' ? 'ValidationMessage__v2' : ''}`

    return (
      <div {...otherProps} className={classNames} data-test-id={testId}>
        <p className={`${versionClass ? ` ${versionClass}` : 'ValidationMessage__text'}`}>{children}</p>
      </div>
    )
  }
}

export default ValidationMessage
