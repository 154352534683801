import React from 'react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from './style.module.css'
import { elementCategories } from '../elementCategories'
import { QuoteIcon } from '../../utils/Icon'
const blockquote = (props) => {
  return (
    <div {...props.attributes} {...props.attrs} className={styles['blockquote']}>
      <blockquote>{props.children}</blockquote>
    </div>
  )
}

Registry.register(
  {
    Component: blockquote,
    // iconName: 'ri-double-quotes-l',
    title: <blockquote>Block quotes</blockquote>,
    slashTitle: 'Blockquote',
    slashIconName: <QuoteIcon />,
    subtitle: 'Capture a Quote',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+7' },
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['blockquote']
  },
  { id: 'blockquote', registry: 'v2.element' }
)

export * from './deserializer'
export * from './withBlockquote'
export default blockquote
