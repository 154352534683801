//@ts-nocheck
import React from 'react'
import { cbModal } from '../../../../../../../index'
import { useSlateStatic } from 'slate-react'
import ImageModal from './Modal/ModalComponent'
import UploadModal from './Modal/UploadModal'
import { setOldSelection } from '../../../../utils/setOldSelection'

const openChooseFromImageModal = (e, editor, type) => {
  e.preventDefault()
  cbModal({
    component: (props) => <ImageModal editor={editor} type={type} {...props} />,
    modalProps: {
      size: 'max',
      shouldReturnFocusAfterClose: false,
      customClass: 'reference__modal scrte-reference__modal'
    },
  })
}
export const ImageOverlay = (props) => {
  const editor = useSlateStatic()

  const handleMouseDown = (e, { editor }) => {
    openChooseFromImageModal(e, editor, props.type)
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: handleMouseDown,
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}

export const shortcutOpenChooseFromImageModal = (event, editor, format, registryObj, type) => {
  openChooseFromImageModal(event, editor, type)
}

export const UploadOverlay = (props) => {
  const editor = useSlateStatic();

  const handleMouseDown = (e, { editor }) => {
    e.preventDefault()
    cbModal({
      component: (props) => <UploadModal editor={editor} {...props} />,
      modalProps: {
        size: 'max',
        shouldReturnFocusAfterClose: false,
        customClass: 'reference__modal scrte-reference__modal'
      },
    })
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: handleMouseDown,
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}
