import React, { useState } from 'react'

import Icon from '../Icon2'
import {
  Condition,
  ConditionOptions,
  Property,
  PropertyConditionConstraint,
  queryFragment,
  QueryFragment
} from './types'
import { className } from './utils'

export const QueryFragmentBuilder = ({
  query,
  onUpdateQuery,
  properties,
  conditions,
  propertyLabel,
  conditionLabel,
  propertyConditionConstraints,
}: {
  query: QueryFragment | undefined,
  onUpdateQuery: (query: QueryFragment) => void,
  properties: Property[],
  conditions: Condition[],
  propertyLabel: string,
  conditionLabel: string,
  propertyConditionConstraints: PropertyConditionConstraint[],
}) => {
  const [
    showConditionList,
    setShowConditionListState,
  ] = useState(false);
  const [
    selectedCondition,
    setSelectedCondition,
  ] = useState(undefined);

  const [
    showPropertyList,
    setShowPropertyList,
  ] = useState(false);
  const [
    selectedProperty,
    setSelectedProperty,
  ] = useState(undefined);
  const [
    propertyFilter,
    setPropertyFilter,
  ] = useState('');
  
  const [
    showRemoveQueryIcon,
    setShowRemoveQueryIcon,
  ] = useState(false);

  const [
    selectedConditionOptionChoice,
    setSelectedConditionOptionChoice,
  ] = useState(undefined);
  const [
    showConditionOptionChoices,
    setShowConditionOptionChoices,
  ] = useState(false);

  const updateQueryWithSelectedProperty = () => {
    const propertyConditionConstraint = getPropertyConditionConstraint(selectedProperty, propertyConditionConstraints);
    let condition: Condition | undefined;
    if (propertyConditionConstraint) {
      condition = conditions.find((condition) => condition.id === propertyConditionConstraint.conditionId);
    } else {
      condition = query?.condition;
    }
    onUpdateQuery(queryFragment(selectedProperty, condition));
  }

  const propertyConditionConstraint = getPropertyConditionConstraint(query?.property, propertyConditionConstraints);

  const setShowConditionList = (condition: boolean) => {
    if (!propertyConditionConstraint) {
      setShowConditionListState(condition);
    }
  }

  const onConditionOptionsChange = (value: any) => {
    const conditionOptions = query?.condition?.conditionOptions;

    onUpdateQuery(queryFragment(query?.property, {
      ...query?.condition,
      conditionOptions: {
        ...conditionOptions,
        value: conditionOptions.type === 'NumberConditionOptions' ?
          parseFloat(value) :
          value,
      } as ConditionOptions
    }));
  }

  return (
    <div
      className={className('QueryFragmentContainer')}
      onMouseEnter={() => setShowRemoveQueryIcon(true)}
      onMouseLeave={() => setShowRemoveQueryIcon(false)}>
      {
        query?.property ?
          <div className={className('TagView')}>
            <button
              type="button"
              className={className('TagView', 'Nested')}
              onClick={() => {
                setShowPropertyList(true);
              }}>
                {query?.property?.displayName}
            </button>
            {
              conditions.length > 0 &&
              <div className={className('ConditionSelectionContainer')}>
                <button
                  type="button"
                  autoFocus={true}
                  className={`${className('TagView', 'Nested')} ${className('ConditionView')}`}
                  onFocus={() => {
                    setShowConditionList(true);
                  }}
                  onClick={() => {
                    setShowConditionList(true);
                  }}>
                    {
                      getConditionDisplayName(query, propertyConditionConstraint)
                    }
                </button>
                {
                  showConditionList &&
                  <div className={className('QueryFragmentDropdown')}>
                    <label htmlFor="conditionList" className={className('QueryFragmentDropdown', 'Header')}>{conditionLabel}</label>
                    <select
                      autoFocus={true}
                      name="conditionList"
                      size={6}
                      className={className('QueryFragmentDropdown', 'Select')}
                      onBlur={() => {
                        setShowConditionList(false);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                          setShowConditionList(false);
                          onUpdateQuery(queryFragment(query?.property, selectedCondition));
                        }
                        if (event.key === 'Escape') {
                          setShowConditionList(false);
                        }
                      }}
                      onClick={() => {
                        setShowConditionList(false);
                        onUpdateQuery(queryFragment(query?.property, selectedCondition));
                      }}
                      onChange={(event) => setSelectedCondition(conditions.find(condition => condition.id === event.target.value))}>
                      {
                        conditions.map((condition) => {
                          return (
                            <option value={condition.id}>
                              {condition.displayName}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>
                }
              </div>
            }
            {
              query?.condition?.conditionOptions &&
              <input
                autoFocus={true}
                onFocus={() => {
                  if (propertyConditionConstraint) {
                    setShowConditionOptionChoices(true);
                  }
                }}
                className={`${className('TagView', 'Nested')} ${className('ConditionOptions')}`}
                value={getConditionOptionsDisplayValue(propertyConditionConstraint, query?.condition?.conditionOptions?.value as string)}
                style={{
                  width: String(getConditionOptionsDisplayValue(propertyConditionConstraint, query?.condition?.conditionOptions?.value as string)).length + 1 + 'ch',
                }}
                onChange={(event) => {
                  onConditionOptionsChange(event.target.value);
                }} />
            }
            {
              showConditionOptionChoices &&
              <div className={className('QueryFragmentDropdown')}>
                <label htmlFor="conditionOptionChoices" className={className('QueryFragmentDropdown', 'Header')}>{query?.property?.displayName}</label>
                <select
                  autoFocus={true}
                  name="conditionOptionChoices"
                  size={6}
                  className={className('QueryFragmentDropdown', 'Select')}
                  onBlur={() => {
                    setShowConditionOptionChoices(false);
                  }}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      setShowConditionOptionChoices(false);
                      onConditionOptionsChange(selectedConditionOptionChoice);
                    }
                    if (event.key === 'Escape') {
                      setShowConditionOptionChoices(false);
                    }
                  }}
                  onClick={() => {
                    setShowConditionOptionChoices(false);
                    onConditionOptionsChange(selectedConditionOptionChoice);
                  }}
                  onChange={(event) => setSelectedConditionOptionChoice(event.target.value)}>
                  {
                    propertyConditionConstraint?.conditionOptionChoices.map((conditionOptionChoice) => {
                      return (
                        <option value={conditionOptionChoice.value}>
                          {conditionOptionChoice.displayName || conditionOptionChoice.value}
                        </option>
                      );
                    })
                  }
                </select>
              </div>
            }
            {
              (showRemoveQueryIcon && !showConditionList && !showPropertyList) &&
              <div className={`${className('TagView', 'Nested')} ${className('RemoveQuery')}`}>
                <button
                  type="button"
                  className={className('TagView', 'Nested')}
                  onClick={() => onUpdateQuery(undefined)}>
                  <Icon icon="CancelSec" />
                </button>
              </div>
            }
          </div> :
          <input
            className={className('QueryFragmentInput')}
            placeholder='Type / Select a syntax'
            onFocus={() => setShowPropertyList(true)}
            onClick={() => setShowPropertyList(true)}
            onChange={(event) => setPropertyFilter(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Escape') {
                setShowPropertyList(false);
              }
            }} />
      }
      {
        showPropertyList &&
        <div className={className('QueryFragmentDropdown')}>
          <label htmlFor="propertyList" className={className('QueryFragmentDropdown', 'Header')}>{propertyLabel}</label>
          <select
            autoFocus={true}
            name="propertyList"
            size={6}
            className={className('QueryFragmentDropdown', 'Select')}
            onBlur={() => {
              setShowPropertyList(false);
            }}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                setShowPropertyList(false);
                updateQueryWithSelectedProperty();
              }
              if (event.key === 'Escape') {
                setShowPropertyList(false);
              }
            }}
            onClick={() => {
              setShowPropertyList(false);
              updateQueryWithSelectedProperty();
            }}
            onChange={(event) => setSelectedProperty(properties.find(property => property.id === event.target.value))}>
            {
              properties.filter(property => property.displayName.match(new RegExp(propertyFilter, 'i'))).map((property) => {
                return (
                  <option value={property.id}>
                    {property.displayName}
                  </option>
                );
              })
            }
          </select>
        </div>
      }
    </div>
  );
}

function getPropertyConditionConstraint(
  property: Property | undefined,
  propertyConditionConstraints: PropertyConditionConstraint[]
): PropertyConditionConstraint | undefined {
  if (!property) {
    return undefined;
  }

  return propertyConditionConstraints.find((constraint) => constraint.propertyId === property?.id);
}

function getConditionDisplayName(
  query: QueryFragment | undefined,
  propertyConditionConstraint: PropertyConditionConstraint | undefined,
) {
  if (query?.condition) {
    if (propertyConditionConstraint?.conditionDisplayNameOverride) {
      return propertyConditionConstraint?.conditionDisplayNameOverride;
    } else {
      return query?.condition?.displayName;
    }
  } else {
    return '?';
  }
}

function getConditionOptionsDisplayValue(
  propertyConditionConstraint: PropertyConditionConstraint | undefined,
  conditionOptionsValue: string | undefined,
) {
  if (conditionOptionsValue === undefined) {
    return '';
  }

  if (!propertyConditionConstraint) {
    return conditionOptionsValue;
  }

  const matchingConditionOption = propertyConditionConstraint
    .conditionOptionChoices.find(choice => choice.value === conditionOptionsValue);

  if (matchingConditionOption?.displayName) {
    return matchingConditionOption?.displayName;
  } else {
    return conditionOptionsValue;
  }
}
