import React from 'react'
import { Registry } from '@react-registry'


const SPAN = ({ attributes, children }) => {
    return (
        <span {...attributes}>{children}</span>
    )
}

export default SPAN
export * from './withSpan'
Registry.register(
    {
        Component: SPAN,
        isContentStackElement: true,
        dndOptions: {
            DisableDND: true,
            DisableSelectionHalo: true
        },
        toolbar: {
            inHoveringToolbar: false
        },
    },
    { id: 'span', registry: 'v2.element' }
)