import { Registry } from '@react-registry'
import { difference } from 'lodash'
import { Editor, Text } from 'slate'
import { getDiscussionMarksOnTextNode } from './helpers'

export const withRangeComment = (editor) => {
  const { insertText } = editor

  editor.insertText = (text) => {
    try {
      const leaf = Editor.leaf(editor, editor.selection)
      if (!leaf) return insertText(text)

      const nextLeaf = Editor.next(editor, { at: leaf[1], match: Text.isText })

      if (!nextLeaf) return insertText(text)

      const isAtEnd = Editor.isEnd(editor, editor.selection.focus, leaf[1])
      const currentDiscussionMarks = getDiscussionMarksOnTextNode(leaf[0])
      const nextDiscussionMarks = getDiscussionMarksOnTextNode(nextLeaf[0])

      const discussionMarksToRemove = difference(currentDiscussionMarks, nextDiscussionMarks)

      if (isAtEnd) discussionMarksToRemove.forEach((mark) => Editor.removeMark(editor, mark))

      insertText(text)
    } catch (e) {
      insertText(text)
    }
  }
  return editor
}

Registry.register(withRangeComment, {
  id: 'withRangeComment',
  registry: 'v2.plugin'
})
