import { Editor, Element } from 'slate'

import { ElementWithType } from '../../../../../../../utils/types'
export const calculateColSize = (event, editor) => {
    const [table] = Editor.nodes(editor, {
        match: (n: ElementWithType) => {
            return Element.isElement(n) && n.type === 'table'
        },
        mode: 'lowest'
    }) as any;
    if (table) {
        return table[0]?.attrs?.cols ?? 0
    }
    return 0
}