//@ts-nocheck
import React from 'react'

import { ToolbarWrapper } from './ToolbarWrapper'
import { HoveringToolbar } from './HoveringToolbar'
import BaseToolbar from './Toolbar'

export declare interface MainToolbarProps {
  contentStack: boolean
  customToolbarComponents: any
  toolbarMode: any
  // templateregistrycondition: any,
  className: any
  fieldName: string
  editorRef: any
  pluginslen: any
}

const MainToolbar = React.memo(
  (props: Partial<MainToolbarProps>) => {
    const { contentStack, customToolbarComponents, toolbarMode = 'advance', fieldName, editorRef, pluginslen } = props

    return (
      <ToolbarWrapper {...props}>
        <BaseToolbar
          toolbarType={'mainToolbar'}
          contentStack={contentStack}
          toolbarMode={toolbarMode}
          customToolbarComponents={customToolbarComponents}
          fieldName={fieldName}
          editorRef={editorRef}
          pluginslen={pluginslen}
        />
      </ToolbarWrapper>
    )
  },
  (prev, next) => {
    return prev.pluginslen === next.pluginslen
  }
)

export { MainToolbar, HoveringToolbar }
