import { Registry } from '@react-registry'

export const CheckList = (el) => {

    const checked = el.getAttribute('data-checked') === 'true'
    return {
        type: 'check-list',
        checked
    }
}

Registry.register(CheckList, { id: 'check-list', registry: 'deserializer' })