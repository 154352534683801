import { isString } from 'lodash'
import { isMac } from '../isMac'
import { TScrteShortcutKey } from '../types'

export const formatShortcut = (shortcut: TScrteShortcutKey = '') => {
  const formattedShortcutArray = []

  let shortcutText = ''

  if (!shortcut) return ''

  if (isString(shortcut)) {
    shortcutText = shortcut
  } else if (isMac() && shortcut.mac) {
    shortcutText = shortcut.mac
  } else if (shortcut.win) {
    shortcutText = shortcut.win
  }

  shortcutText
    .trim()
    .replace(/\+\+/g, '+')
    .split('+')
    .forEach((shortcut) => {
      let uppercaseShortcut = shortcut.toString().toUpperCase()

      let foundInCommon = true

      // Common symbols
      switch (uppercaseShortcut) {
        case 'ENTER':
        case 'RETURN':
          formattedShortcutArray.push('⏎')
          break
        case 'UP':
          formattedShortcutArray.push('↑')
          break
        case 'DOWN':
          formattedShortcutArray.push('↓')
          break
        case 'LEFT':
          formattedShortcutArray.push('←')
          break
        case 'RIGHT':
          formattedShortcutArray.push('→')
          break
        case 'BACKSPACE':
          formattedShortcutArray.push('⌫')
          break
        case 'SPACE':
          formattedShortcutArray.push('⎵')
          break
        case '':
        case 'ADD':
          formattedShortcutArray.push('+')
          break
        default:
          foundInCommon = false
      }

      if (!foundInCommon)
        if (isMac()) {
          switch (uppercaseShortcut) {
            case 'CMD':
            case 'MOD':
              formattedShortcutArray.push('⌘')
              break
            case 'CTRL':
              formattedShortcutArray.push('⌃')
              break
            case 'SHIFT':
              formattedShortcutArray.push('⇧')
              break
            case 'ALT':
            case 'OPTION':
              formattedShortcutArray.push('⌥')
              break
            case 'DELETE':
              formattedShortcutArray.push('⌦')
              break
            case 'ESC':
            case 'ESCAPE':
              formattedShortcutArray.push('⎋')
              break
            case 'TAB':
              formattedShortcutArray.push('⇥')
              break
            case 'PAGE UP':
              formattedShortcutArray.push('⇞')
              break
            case 'PAGE DOWN':
              formattedShortcutArray.push('⇟')
              break
            case 'HOME':
              formattedShortcutArray.push('↖︎')
              break
            case 'END':
              formattedShortcutArray.push('↘︎')
              break
            default:
              formattedShortcutArray.push(uppercaseShortcut)
          }
        } else {
          switch (uppercaseShortcut) {
            case 'CTRL':
            case 'CMD':
            case 'MOD':
              formattedShortcutArray.push('ctrl')
              break
            case 'SHIFT':
              formattedShortcutArray.push('shift')
              break
            case 'ALT':
            case 'OPTION':
              formattedShortcutArray.push('alt')
              break
            case 'DELETE':
              formattedShortcutArray.push('del')
              break
            case 'ESC':
            case 'ESCAPE':
              formattedShortcutArray.push('esc')
              break
            case 'TAB':
              formattedShortcutArray.push('tab')
              break
            case 'PAGE UP':
              formattedShortcutArray.push('pg up')
              break
            case 'PAGE DOWN':
              formattedShortcutArray.push('pg down')
              break
            case 'HOME':
              formattedShortcutArray.push('home')
              break
            case 'END':
              formattedShortcutArray.push('end')
              break
            default:
              if (uppercaseShortcut.length > 1) {
                formattedShortcutArray.push(uppercaseShortcut.toLowerCase())
              } else {
                formattedShortcutArray.push(uppercaseShortcut)
              }
          }
        }
    })

  return formattedShortcutArray.join('+')
}