import { Registry } from '@react-registry'

export const underlineDeserializer = () => {
  return {
    underline: true,
  }
}

Registry.register(underlineDeserializer, {
  id: 'U',
  registry: 'inline-deserializer',
})
