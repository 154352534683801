//@ts-nocheck
import { createCell } from "./createCell"

export const createRowWithColSize = (cols) => {
    let row = {
        type: 'tr',
        attrs: {},
        children: [],
    }

    let col = new Array(cols).fill().map((child) => createCell())

    row.children = col.slice()
    return Object.assign({}, row)
}
