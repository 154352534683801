import React from 'react'
import { AssetCardVertical } from '../../../../../../../../../../../index'
import { getAssetType } from '../../../../../../../../../../RichTextEditor/SuperChargedRte/elements/Element/contentstack/image/Modal/util'

const assetType = {
  "null": 'image',
  "Document": "document",
  'Group': "folder",
  'Pdf': 'pdf',
  'Archive': 'zip',
  'Video': 'video',
  'Audio': 'audio'
}
export declare interface AssetImageProps {
  handleClick: any
  asset: any,
  checked: boolean
}

export default React.memo(
  (props: AssetImageProps) => {
    const { handleClick, asset, checked } = props
    const { url, title, file_size, uid, content_type, name, file_name } = asset

    return (
      <AssetCardVertical
        onClick={() => handleClick(asset)}
        url={url}
        title={title ?? name ?? file_name}
        size={file_size}
        type={assetType[getAssetType(content_type)]}
        imageWidth={'100%'}
        checked={checked}
        testId={'asset-grid-item'}
      />
    )
  },
  (prev, curr) => {
    return prev.checked === curr.checked
  }
)
