import React, { useRef, useState, useLayoutEffect } from 'react'
import './EntryReferenceDetails.css'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
const defaultProps = {
  testId: 'cs-entry-reference-details'
}

type DefaultProps = Readonly<typeof defaultProps>
const containerMaxWidth = 500;
const responsiveWidth = 768;
export type EntryReferenceDetailsProps = {
  title?: string
  contentType?: string
  onEdit?: () => void
  onDelete?: () => void
  onViewNestedEntries?: () => void,
  testId?: string
  LinkComponent?: any
  linkToRow?: string
} & Partial<DefaultProps>

export type EntryTitleAndCTProps = {
  title?: string
  contentType?: string,
  isentryReferenceMaxWidth?: any
}

export type EditIconDomProps = {
  onEdit?: () => void
  LinkComponent?: any
  linkToRow?: string
}

export const EntryTitleAndCT = ({ title, contentType }: EntryTitleAndCTProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const contentTypeRef = useRef<HTMLDivElement>(null);
  const [isentryReferenceTitleWidth, setEntryReferenceTitleWidth] = useState(false);
  const [isentryReferenceContentWidth, setEntryReferenceContentWidth] = useState(false);

  const isEllipsisActive = (ref) => {
    return ref?.current?.offsetWidth < ref?.current?.scrollWidth;
  }
  useLayoutEffect(() => {
    if (contentTypeRef.current) {
      setEntryReferenceContentWidth(isEllipsisActive(contentTypeRef))
    }
    if (titleRef.current) {
      setEntryReferenceTitleWidth(isEllipsisActive(titleRef));
    }
  }, [title, contentType]);
  return (
    <div className="EntryReferenceDetails">
      <div className='EntryReferenceDetails__tooltip'>
        <Tooltip content={title} position='top' disabled={!isentryReferenceTitleWidth}>
          <h4 className='EntryReferenceDetails__title' ref={titleRef}> {title}</h4>
        </Tooltip>

        <Tooltip content={contentType} position='top' disabled={!isentryReferenceContentWidth}>
          <span className='EntryReferenceDetails__content-type' ref={contentTypeRef}>{contentType}</span>
        </Tooltip>
      </div>
    </div>
  )
}

export const EditIconDom = ({ onEdit, LinkComponent, linkToRow }: EditIconDomProps) => {

  if (LinkComponent && linkToRow) {
    return (<Tooltip position="top" content="Edit">
      <>
        <LinkComponent
          className="EntryReferenceDetails__action EntryReferenceDetails__action--edit flex"
          to={linkToRow}
        >
          <Icon icon="Edit" />
        </LinkComponent>
      </>
    </Tooltip>)
  }
  if (onEdit) {
    return (<Tooltip position="top" content="Edit">
      <div
        onClick={() => onEdit()}
        className="EntryReferenceDetails__action EntryReferenceDetails__action--edit flex"
      >
        <Icon icon="Edit" />
      </div></Tooltip>)
  }
  return null;
}

const EntryReferenceDetails = (props: EntryReferenceDetailsProps) => {
  let { title, contentType, onEdit, onDelete, onViewNestedEntries, testId, LinkComponent, linkToRow } = props



  return (
    <div className='EntryReferenceDetailsWrapper' data-test-id={testId}>
      {LinkComponent && linkToRow ?
        <LinkComponent
          to={linkToRow}
        >
          <EntryTitleAndCT title={title} contentType={contentType} />
        </LinkComponent>
        :
        <EntryTitleAndCT title={title} contentType={contentType} />
      }
      <div className="Wrapper__actions EntryReferenceDetails__actions flex">
        {onViewNestedEntries ? <Tooltip position="top" content="View Nested Entries">
          <div onClick={() => onViewNestedEntries()} className="EntryReferenceDetails__action EntryReferenceDetails__action--view flex">
            <Icon icon="ViewNestedEntries" size="small" />
          </div>
        </Tooltip> : null}
        <EditIconDom onEdit={onEdit} LinkComponent={LinkComponent} linkToRow={linkToRow} />
        <Tooltip position="top" content="Remove">
          <div
            onClick={() => onDelete()}
            className="EntryReferenceDetails__action EntryReferenceDetails__action--remove flex"
          >
            <Icon icon="RemoveFilled" />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

EntryReferenceDetails.defaultProps = defaultProps

export default EntryReferenceDetails