import { Notification } from '../../../../../../../index';
import axios from 'axios';

export const getAssets = async (props) => {
  const {
    assetUrl,
    apiKey,
    authToken,
    limit = 30,
    skipSize = 0,
    query = '',
    asc_field = '',
    dsc_field = '',
    folderUid,
    branch,
    isEmbed
  } = props

  const queryParams: Record<string, string> = {
    skip: skipSize,
    limit: limit,
    include_count: 'true',
    asc: asc_field,
    dsc: dsc_field,
    include_publish_details: 'true',
    include_folders: 'true',
    folder: folderUid,
  }

  if (query !== '') {
    delete queryParams['folder'];
    queryParams['query'] = JSON.stringify({
      "$and": [
        {
          "$or": [
            {
              "filename": {
                "$regex": query,
                "$options": "i"
              }
            },
            {
              "uid": {
                "$regex": query,
                "$options": "i"
              }
            },
            {
              "name": {
                "$regex": query,
                "$options": "i"
              }
            }
          ]
        }
      ]
    });
  }

  const queryParamsString = new URLSearchParams(queryParams).toString()
  let url = `${assetUrl}/assets/images?${queryParamsString}`;
  if (isEmbed)
    url = `${assetUrl}/assets?${queryParamsString}`;
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json',
    web_ui_api_key: '607a456d7f3afc20cd9fcb1f'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);
  const res = await axios({
    method: 'GET',
    url,
    headers
  })

  const assets = res.data;
  return assets
}

export const getFolders = async (props) => {
  const {
    assetUrl,
    apiKey,
    authToken,
    limit = 30,
    skipSize = 0,
    query = '',
    asc_field = '',
    dsc_field = '',
    folderUid = '',
    branch
  } = props

  const queryParams: Record<string, string> = {
    skip: skipSize,
    limit: limit,
    include_count: 'true',
    asc: asc_field,
    dsc: dsc_field,
    include_publish_details: 'true',
    include_folders: 'true',
    folder: folderUid
  }

  const queryVal = `{${query !== '' ? `"name":{"$regex":"${query}","$options": "i"},` : ''} "is_dir": true}`;

  const queryParamsString = new URLSearchParams(queryParams).toString()
  const url = `${assetUrl}/assets?${queryParamsString}&query=${queryVal}`;
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);

  const res = await axios({
    method: 'GET',
    url,
    headers
  })

  const assets = await res.data
  return assets
}

export const uploadImage = async (props) => {
  const { apiKey, assetUrl, authToken, files, folderUid, branch } = props
  let url = `${assetUrl}/assets`;

  return Promise.all(files.map(async file => {
    let formData = new FormData()
    formData.append('asset[upload]', file, file.name);
    formData.append('asset[parent_uid]', folderUid);
    const headers = {
      api_key: apiKey,
      'Content-Type': 'application/json'
    }
    authToken && (headers['authToken'] = authToken);
    branch && (headers['branch'] = branch);

    const res = await axios({
      method: 'POST',
      url,
      headers,
      data: formData
    })
    const json = await res.data
    return json
  }))
}

export const createFolder = (props) => {
  const { folderName, parentUid, assetUrl, authToken, apiKey, branch } = props;
  const url = `${assetUrl}/assets/folders`;
  let body = {
    "asset": {
      "name": folderName,
      "parent_uid": parentUid
    }
  }
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);

  return axios({
    method: 'POST',
    url,
    headers,
    data: JSON.stringify(body)
  })
    .then(res => res.data)
    .then(res => {
      if (res.error_message) throw res;
      return res;
    })
    .catch(err => Notification({
      displayContent: { text: err.error_message },
      notifyProps: { hideProgressBar: true },
      type: 'error',
    }))
}

export const getCurrentUserId = async (props) => {
  const { assetUrl, authToken, branch } = props;
  const url = `${assetUrl}/user`;
  const headers = {
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);
  const res = await axios({
    method: 'GET',
    url,
    headers
  });
  const json = await res.data;
  return json?.user?.uid;
}

export const getUsers = async (props) => {
  const { assetUrl, authToken, apiKey, branch } = props;
  const url = `${assetUrl}/stacks?include_collaborators=true`;
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);
  const res = await axios({
    method: 'GET',
    url,
    headers
  });
  const json = await res.data;
  let collaborators = json['stack']?.collaborators || [];
  let collaboratorsMap = {};
  let currentUid = await getCurrentUserId(props);
  collaborators.forEach(user => collaboratorsMap[user.uid] = (user.uid === currentUid ? 'Me' : `${user.first_name} ${user.last_name}`));
  return collaboratorsMap;
}