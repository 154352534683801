import React from 'react';
import styles from './style.module.css';
import Icon from '../../../../../../../Icon2';
import { getAssetType } from '../../image/Modal/util';
import { useFocused, useSelected } from 'slate-react';
import { cx, css } from '@emotion/css';
import Tooltip from '../../../../../../../Tooltip/Tooltip';
import { EmbedBtnGrp } from '../inlineReference';
import { handleDelete } from '../../../../utils/actions';
import { cbModal } from '../../../../../../../..';
import { EditCaptionAlt } from './EditCaptionAlt';

const InfoIcon = ({ error, isDisabled }) => {
  if (error) {
    return (
      <Tooltip appendTo={document.body} className={'embed-error'} content={error} position='top' variantType='light'>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.82106 2.8538L1.24076 12.7743C0.303345 14.4409 1.50763 16.5 3.41969 16.5H14.5803C16.4924 16.5 17.6967 14.4409 16.7592 12.7743L11.1789 2.85381C10.2232 1.15468 7.77682 1.15467 6.82106 2.8538Z" fill="#FF5630" stroke="#FF5630" />
          <rect x="7.75" y="8" width="2.5" height="6" rx="1" fill="white" />
          <circle cx="9" cy="5.25" r="1.25" fill="white" />
        </svg>
      </Tooltip>
    )
  }
  if (isDisabled) {
    return (
      <Tooltip appendTo={document.body} className={'embed-error'} content='This feature is not available in your plan.' position='top' variantType='light'>
        <Icon icon="Docs" size='medium' />
      </Tooltip>
    )
  }

  return null;
}

const Asset = (props) => {
  const { children, attributes, editor, entry, element, attrs } = props;
  const icon = getAssetType(element?.attrs?.['asset-type']);
  const isFocused = useFocused();
  const isSelected = useSelected();
  const isHighlight = isFocused && isSelected;
  const isError = entry['error'],
    canUpdate = entry?.['_embed_permission']?.['update'],
    canRead = entry?.['_embed_permission']?.['read'];
  // const isDisabled = entry['disable'];
  const isDisabled = false;

  const { history, apiKey } = editor?.requestProps || {};
  const assetUid = element?.attrs?.['asset-uid'];

  const handleEdit = () => {
    history.push(`/stack/${apiKey}/assets/${assetUid}`);
  }

  const handleCaption = () => {
    return cbModal({
      component: (props) => <EditCaptionAlt editor={editor} element={element} {...props}></EditCaptionAlt>
    })
  }

  return (
    <Tooltip
      offset={[0, -15]}
      zIndex={909} className="p-0" position='bottom' variantType='light'
      content={
        <div contentEditable={false} className={styles['embed--btn-group']}>
          <EmbedBtnGrp isDisabled={isDisabled} isError={isError} handleCaption={canUpdate && handleCaption} handleEdit={canRead && handleEdit} handleDelete={(e) => handleDelete(editor, element)} handleUnlink={undefined} editor={editor} type='asset' attributes={{ ...props.attributes, ...props.attrs, element }} />
        </div>
      }
    >
      <div {...attributes} {...attrs} data-testid="asset-image" data-type='asset' className={cx(styles['embed-asset'], isHighlight && styles['embed-asset-active'], !icon && !entry['error'] && !isDisabled && css`
        background-image: url(${entry['url']});
        background-size: cover;
        background-position: 50% 50%;
      `)}>
        <div className={styles['embed-icon']}>
          <Icon icon='Embed' />
        </div>
        {(isError || isDisabled) ? <InfoIcon isDisabled={isDisabled} error={entry['error']} /> : <Icon icon={icon} size='large' />}
        {children}
      </div>
    </Tooltip>
  )
}

export default Asset;