//@ts-nocheck
import { Registry } from '@react-registry'
import { Element, Transforms } from 'slate'
import { v4 } from 'uuid';

const generateUid = () => v4().split('-').join('');

export const withSave = (editor) => {
    const { normalizeNode } = editor
    editor.normalizeNode = (entry) => {
        if (Element.isElement(entry[0])) {
            if (entry[0].type === 'error-block') {
                Transforms.setNodes(editor, { type: "p" }, { at: entry[1] })
            }
            if (!entry[0].uid) {
                Transforms.setNodes(editor, { uid: generateUid() }, { at: entry[1] })
            }
            if (!entry[0]?.attrs?.dirty) {
                Transforms.setNodes(editor, { attrs: { ...entry[0].attrs, dirty: true } }, { at: entry[1] })
            }
        }
        normalizeNode(entry)
    }
    return editor
}

Registry.register(withSave, {
    id: 'withSave',
    registry: 'v2.plugin'
})