import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { reorder } from '../../utils/index';
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc';
import RenderTag from './RenderTag'
import './TagEditor.css'

export type TagEditorProps = {
  tags: string[]
  label?: string
  onChange: (tagToUpdate: string[]) => any
  isSortable?: boolean
  placeholder?: string
  testId?: string
  error?: boolean
  id?: string
  version?: "v2"
  customKeyDown?: (event: any) => any
}

const TagEditor = (props: TagEditorProps) => {
  let tags: any = props.tags && props.tags.length ? props.tags : []
  let sortTag = props.isSortable || false
  tags = tags.map((tag, i) => ({ label: tag, id: tag + i }))
  let isDragging = false;
  let [controlledTags, setControlledTags] = useState(tags)
  let [editing, setEditing] = useState(null)
  let [caret, setCaret] = useState(null)

  const setIsDragging = (val) => {
    isDragging = val;
  }

  useEffect(() => {
    setControlledTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags.map(tag => tag.label).join(',')])

  const removeTag = (index: number) => {
    let filteredTags = controlledTags
      .filter((tag, i) => i !== index)
      .map(tag => tag.label)
      .filter(Boolean)
    props.onChange(filteredTags)
  }

  const handleClick = () => {
    if (!isDragging) {
      let filteredTags = [...controlledTags, { label: '', id: `${controlledTags.length}` }]
      setEditing(filteredTags.length - 1)
      setCaret(0)
      setControlledTags(filteredTags)
    }
  }

  const handleTagSave = (tag, text) => {
    let filteredTags = [...controlledTags]
    let foundTag = filteredTags.find(t => t.id === tag.id)
    foundTag.label = text
    filteredTags = filteredTags.filter(t => t.label.trim())
    setControlledTags(filteredTags)
    setCaret(null)
    setEditing(null)
    props.onChange(filteredTags.map(tag => tag.label))
  }

  const onDragEnd = (result: any) => {
    if (result && result.destination) {
      const filteredTags = reorder(controlledTags, result.source.index, result.destination.index)
      setControlledTags(filteredTags)
      props.onChange(filteredTags.map((tag: any) => tag.label))
      setIsDragging(false)
      document?.querySelector(`#${result?.draggableId}`)?.classList.remove('Tag--draggable');
    }
  }

  const onDragStart = ({ draggableId }) => {
    setIsDragging(true)
    document?.querySelector(`#${draggableId}`)?.classList.add('Tag--draggable');
  }

  const handleTagFocus = (tag, i) => {
    setEditing(i)
    setCaret(tag.label.length)
  }

  const disableEditing = () => {
    setEditing(null)
    setCaret(null)
  }

  if (!controlledTags.length) {
    return (
      <>
        {props.label && <p>{props.label}</p>}
        <div
          className={`Tag ${props.version === "v2" ? "Tag_v2" : ""} ${typeof editing === 'number' ? 'Tag_v2--active' : ''} ${sortTag ? 'Tag--sortable' : ''} ${props.error ? 'Tag--error' : ''} ${typeof editing === 'number' ? ' Tag--active' : ''
            }`}
          data-test-id={props.testId}
          onClick={handleClick} id={props.id}
          tabIndex={0}
          onKeyDown={(e: any)=>{
            if(e.key === "Enter" || e.key === " "){
              handleClick()
            }else{
              e.target.classList.contains("Tag") && props.customKeyDown && props.customKeyDown(e)
            }
          }}
        >
          <div className="Tag__placeholder">{props.placeholder}</div>
        </div>
      </>
    )
  }
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {props.label && <p>{props.label}</p>}
      <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!sortTag}>
        {(provided: any, snapshot: any) => (
          <div
            data-test-id={props.testId}
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`Tag flex-wrap ${props.version === "v2" ? "Tag_v2" : ""} ${typeof editing === 'number' ? 'Tag_v2--active' : ''} ${sortTag ? 'Tag--sortable' : ''} ${props.error ? 'Tag--error' : ''} ${typeof editing === 'number' ? ' Tag--active' : ''
              }`}
            onClick={handleClick}
            tabIndex={0}
            onFocus={(e)=>{
              const inputNodes = e.target.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>
              if (inputNodes.length) {
                let i = 0
                while (i < inputNodes.length) {
                  inputNodes[i].setAttribute("tabIndex", "-1")
                  i++
                }
              }
            }}
            onKeyDown={(e: any)=>{
              if(e.key === "Enter" || (e.key === " " && e.target?.getAttribute("data-test-id") === "cs-editable-tags")){
                e.preventDefault()
                const inputNodes = e.target?.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>
                if (inputNodes.length && inputNodes[0].getAttribute("tabindex") !== "0") {
                  let i = 0
                  while (i < inputNodes.length) {
                    inputNodes[i].setAttribute("tabIndex", "0")
                    i++
                  }
                  inputNodes[0].focus()
                }
              }
              e.target.classList.contains("Tag") && props.customKeyDown && props.customKeyDown(e)
            }}
          >
            {controlledTags.map((tag, i) => {
              return (
                <RenderTag
                  sortTag={sortTag}
                  index={i}
                  tagId={tag.id}
                  active={editing === i ? true : false}
                  caret={editing === i ? caret : null}
                  onSave={text => handleTagSave(tag, text)}
                  onFocus={() => handleTagFocus(tag, i)}
                  onRemove={() => removeTag(i)}
                  key={tag.id}
                  disableEditing={disableEditing}
                  handleClick={handleClick}
                  customKeyDown={props.customKeyDown}
                  isLastTag={controlledTags.length - 1 === i}
                >
                  {tag.label}
                </RenderTag>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

TagEditor.defaultProps = {
  testId: 'cs-editable-tags'
} as Partial<TagEditorProps>;

export const EditableTags = withDeprecatedProp(TagEditor, { 'sort': 'isSortable', 'updateTag': 'onChange' })

export default withDeprecatedProp(TagEditor, { 'sort': 'isSortable', 'updateTag': 'onChange' }, { 'TagEditor': 'EditableTags' })