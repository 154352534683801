import React from 'react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'

export * from './deserializer'

const TableBody = ({ attributes, children, ...props }) => {
  return (
    <div data-type="table-body" data-testid="tbody" className={styles['div-table-body']} {...attributes}>
      {children}
      <div data-drag='no' data-testid="row-extra-cell" contentEditable={false} className={cx(styles['table-add-column-extra-cell'], 'scrte-table-row-extra-cell')}></div>

    </div>
  )
}

export default TableBody

Registry.register(
  {
    Component: TableBody,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
  },
  { id: 'tbody', registry: 'element' }
)
