import { Editor } from 'slate';
import { v4 } from 'uuid';

export * from './requests'
export * from './checkProps'

export const isEmbedLink = (editor) => {
  const selection = editor.selection || editor.savedSelection;
  if (!selection) return false;
  const [embedLink] = Editor.nodes(editor, {
    //@ts-ignore
    match: (n) => n.type === 'reference' && n?.attrs?.['display-type'] === 'link',
    at: selection
  })
  return Boolean(embedLink)
}

export const setEntryInEditor = (editor, entry) => {
  const { key, data } = entry;
  if (typeof editor.requestProps?.['uidToEntry'] === 'object')
    editor.requestProps['uidToEntry'][key] = {
      locale: data['locale'],
      title: data['title'],
      uid: data['uid'],
      url: data['url'],
      publish_details: data['publish_details'],
      _version: data['_version'],
      _embed_permission: {
        read: true,
        update: true,
        create: true,
        publish: true,
        delete: true
      }
    };
}

export const getReferenceNode = ({ type, displayType, entry, linkDetails }) => {
  const node = {
    type: 'reference',
    attrs: {
      'display-type': type || displayType,
      'entry-uid': entry.uid,
      'content-type-uid': entry['contentTypeUid'],
      'locale': entry.locale,
      'type': 'entry',
      'class-name': displayType === 'link' ? 'embedded-entry' : `embedded-entry redactor-component ${displayType}-entry`
    },
    uid: v4().split('-').join(''),
    children: [{ text: type === 'link' ? (linkDetails.text || entry.url || '/undefined') : '' }]
  }
  if (type === 'link')
    node['attrs'] = {
      //@ts-ignore
      target: linkDetails.inNewTab ? '_blank' : '_self',
      href: entry.url,
      ...node['attrs']
    }
  return node;
}