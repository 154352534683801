import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={9}
      height={8}
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: '3px', marginRight: "1px" }}
      {...props}
    >
      <path
        d="M4.5 1v6M7.5 4h-6"
        stroke="#647696"
        strokeWidth={1.4}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgComponent
