import { Registry } from "@react-registry";
import properties from "./properties";
import actions from "./actions";
import events from "./events";
import React from "react";

export const RTE = (function () {
  var instance;
  function createInstance({ editor, editorRef }) {
    return {
      ...properties(editor),
      ...actions(editor),
      ...events(editor),
      ref: editorRef.current
    };
  }

  return {
    init: function (props) {
      if (!instance) {
        instance = createInstance(props);
      }
      return instance;
    }
  };
})();

const setEditorCallback = (editorCallbacks = {}, id, rte) => {
  Object.entries(editorCallbacks).forEach(([key, val]) => {
    if (key === 'keydown') {
      Registry.register((event) => {
        delete event.editor;
        event.rte = rte;
        //@ts-ignore
        val(event)
      }, {
        id: id,
        registry: 'extended.keydown'
      });
      return;
    }
    Registry.register({ callback: val, props: { rte } }, {
      id,
      registry: key
    });
  })
}

const setComponents = (_plugin, id, rte) => {
  const meta = _plugin.meta;
  const registry = _plugin.registry;
  if (meta && registry) {
    const isLeaf = meta?.elementType && typeof meta.elementType !== 'function' && meta?.['elementType']?.includes('text');
    const registryName = isLeaf ? 'v2.leaf' : 'v2.element';

    // modify registry
    if (registry['handleMouseDown']) {
      registry['handleMouseDown'] = registry['handleMouseDown'].bind(null, rte);
    }

    if (!registry['isContentStackElement']) {
      registry['isContentStackElement'] = true;
    }
    if (registry.shouldOverride) {
      registry['isContentStackElement'] = false
    }

    if (registry['Component']) {
      const Component = registry['Component'];
      registry['Component'] = (props) => React.createElement(Component, { ...props, rte });
    }

    if (registry['icon']) {
      registry['iconName'] = registry['icon'];
      delete registry['icon']
    }

    registry['isExtension'] = true;

    Registry.register({
      ...registry
    }, {
      id: meta['id'], registry: registryName, conditions: {
        overide: true
      }
    });

    Registry.register({ type: meta['id'], elementType: meta['elementType'] }, { id: meta['id'], registry: 'v2.extension' });
  }
}

const withRTE = ({ plugin, key, rte }) => {
  // handle editor callbacks
  setEditorCallback(plugin?.meta?.editorCallbacks, key, rte)
  // handle component creation
  setComponents(plugin, key, rte);
  return null;
}

export default withRTE;