import React from 'react'
import { Registry } from '@react-registry'
import { ImageOverlay } from '../../image/handleMouseDown'
import { elementCategories } from '../../../elementCategories'

//@ts-ignore

const AssetEmbed = (props) => <ImageOverlay {...props} type='asset' />

Registry.register(
  {
    // iconName: <ImageIcon />,
    title: 'Asset',
    category: elementCategories.CONTENTSTACK_REFERENCE,
    IngressComponent: AssetEmbed,
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true
    },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
  },
  { id: 'assetReference', registry: 'element' }
)

export * from './deserializer'