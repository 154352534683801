import './StackCard.css'

import React, { useMemo, useState } from 'react'

import Avatar, { AvatarData } from '../Avatar/Avatar'
import { iconName } from '../Icon2/constants'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import OutlineTag from '../Tag/OutlineTag'

const defaultProps = {
  title: 'Title',
  active: false,
  stats: [],
  avatars: [],
  type: 'image',
  modified: '',
  isTrial: false,
  onActiveToggle: (state, event) => { },
  starActiveTooltip: 'Starred',
  starInActiveTooltip: 'Not starred'
}

type DefaultProps = Readonly<typeof defaultProps>

type CardStats = {
  number: string
  category: string
}

export type StackCardProps = {
  title?: string
  active?: boolean
  stats: CardStats[]
  data?: any
  avatars?: AvatarData[]
  modified: string
  isTrial?: boolean
  newUser?: boolean
  type?: 'text' | 'image'
  starActiveTooltip?: string
  starInActiveTooltip?: string
  onActiveToggle: (state, event) => void
} & Partial<DefaultProps>

const StackCardFooter = ({ avatars, type, modified }) => {
  return useMemo(() => {
    return (
      <div className="StackCard__footer">
        {avatars && (
          <Avatar
            avatars={avatars}
            type={type}
            showPlusSignAfter={2}
            showExtraType="link"
            showExtraColor="dark"></Avatar>
        )}
        {modified && (
          <Tooltip content="Last modified" position="top">
            <div className="StackCard__modified flex-v-center">
              <Icon className="StackCard__modified-icon" icon={'ModifiedBy'} />
              <span className="StackCard__modified-date">{modified}</span>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }, [modified, avatars, type])
}

const TrialStackCardFooter = (props) => {

  return useMemo(() => {
    return (
      <div className={`StackCard__footer Trial_footer ${props.newUser ? '' : 'firstLogin'}`}>
        {

          !props.newUser ?
            <div className='FooterContainer'>
              <Icon icon='RocketPurple' />
              <div className='FooterContent'>Get started with the guided tour</div>
            </div>
            :
            <div className='FooterContainer'>
              <Icon icon='GuidedTour' />
              <div className='FooterContent' >View the guided tour</div>
            </div>
        }

      </div>)
  }, [props.newUser])

}

const StackCard = (props: StackCardProps) => {
  let { title, active, stats, avatars, modified, onActiveToggle, type, data, isTrial, newUser } = props

  let [isActive, setIsActive] = useState(active)

  const handleStarClick = (event: any) => {
    setIsActive(!isActive)
    onActiveToggle({ isActive: !isActive, data }, event)
  }

  const shortName = (name: any) => {
    if (name) {
      if (name.length < 15) {
        return name
      } else {
        return `${name.slice(0, 15)}`
      }
    }
  }

  const returnDigitSize = (count: any) => {
    if (!count) {
      return 0
    }
    let formatNumber =
      Math.abs(Number(count)) >= 1.0e12
        ? (Math.abs(Number(count)) / 1.0e12).toFixed(2) + ' T'
        : Math.abs(Number(count)) >= 1.0e9
          ? (Math.abs(Number(count)) / 1.0e9).toFixed(2) + ' B'
          : Math.abs(Number(count)) >= 1.0e6
            ? (Math.abs(Number(count)) / 1.0e6).toFixed(2) + ' M'
            : Math.abs(Number(count)) >= 1.0e3
              ? (Math.abs(Number(count)) / 1.0e3).toFixed(2) + ' K'
              : Math.abs(Number(count))
    return formatNumber
  }

  return (
    <div className={`${isTrial ? `StackCard Trial_StackCard` : 'StackCard'}`}>
      <div className="StackCard__heading">
        <h4 className="StackCard__title flex-v-center">
            {(!isTrial && title?.length > 18) || (isTrial && title?.length > 15) ? (
              <Tooltip content={title} position="top">
                <span className='StackCard__title--truncate' >{title}</span>
              </Tooltip>
            ) : (
              <span>{title}</span>
            )}
          {
            !isTrial ?
              <button
                className="StackCard__star-button"
                onClick={handleStarClick}
                aria-label={`${isActive ? `Click to unstar ${title} stack` : `Click to star ${title} stack`}`}
              >
                <Tooltip content={isActive ? props.starActiveTooltip : props.starInActiveTooltip} position="left">
                  <Icon
                    icon={iconName.Star}
                    className={`StackCard__star-icon ${isActive ? ' StackCard__star-icon--active' : ''}`}
                  ></Icon>
                </Tooltip>
              </button>
              :
              <OutlineTag content="Demo Stack" type="primary" />}
        </h4>
      </div>
      <div className="StackCard__content">
        {
          !isTrial ?
            <div className="StackCard__stats">
              {stats.map((statsUnit, index) => {
                return (
                  <div className="StackCard__stats-unit" key={index}>
                    <span className="StackCard__stats-number">{returnDigitSize(statsUnit.number)}</span>
                    <h5 className="StackCard__stats-category">{statsUnit.category}</h5>
                  </div>
                )
              })}
            </div>
            :
            <div>
              <div className='TrialCard_content_body'>Sample E-commerce is a demo stack that we have created to demonstrate Contentstack’s features and capabilities. Click on it to learn more.</div>
            </div>
        }
      </div>
      {!isTrial ?
        <StackCardFooter avatars={avatars} type={type} modified={modified} />
        :
        <TrialStackCardFooter newUser={newUser} />

      }
    </div>
  )
}

StackCard.defaultProps = defaultProps

export default StackCard
