//@ts-nocheck

import React from 'react'
import { AssetCardVertical } from '../../../../../../index'
import { getAssetType } from '../../../../commons/util'

const assetType = {
  "null": 'image',
  "Document": "document",
  'Group': "folder",
  'Pdf': 'pdf',
  'Archive': 'zip',
  'Video': 'video',
  'Audio': 'audio'
}

export default React.memo(
  (props) => {
    const { asset, checked, updateSelection, isMultiple, readOnly } = props
    const { url, title, file_size, uid, content_type, name, file_name } = asset

    return (
      <AssetCardVertical
        onClick={() => updateSelection(checked, asset)}
        url={url}
        title={title ?? name ?? file_name}
        size={file_size}
        type={assetType[getAssetType(content_type)]}
        imageWidth={'100%'}
        checked={checked}
        isCardDisabled={asset?.invalid_asset}
        isRadio={!isMultiple}
        {...((!asset.is_dir && !readOnly) && { onChecked: () => updateSelection(checked, asset) })}
      />
    )
  },
  (prev, curr) => {
    return prev.checked === curr.checked
  }
)
