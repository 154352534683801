import { Editor } from 'slate'
import { getPointBefore } from './getPointBefore'

export const getRangeBefore = (editor, at, options) => {
  const anchor = getPointBefore(editor, at, options)
  if (!anchor) return

  const focus = Editor.point(editor, at, { edge: 'end' })

  return {
    anchor,
    focus
  }
}
