import React, { useMemo, useCallback, useState, useRef, useEffect, Fragment } from 'react'
import { createEditor, Transforms, Editor, Node } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import { cx, css } from '@emotion/css'
import { cloneDeep } from 'lodash'
import { v4 } from 'uuid'
import siteViewStyles from '../../styles.module.css'
import { savedSelection } from '../../utils/savedSelection'
//@ts-ignore
import styles from './style.module.css'
import { Element, Leaf } from '../../elements'
import { MainToolbar, HoveringToolbar } from '../Toolbar'
import { applyPlugins } from '../../utils'
import { HtmlEditComponent } from '../../elements/utils/editHtml'
import { EditorProvider, ScrollRefProvider, ReduxProvider } from '../ContextProvider'
import { handleShortcut } from '../../utils/shortcuts/handleShortcut'
import { Icon } from '../../../../..'
import { useEditorResize, handleRteOverlayClick } from '../../../../../utils/hooks'
import dndStyles from '../Dnd/style.module.css'
import { htmlToJson, jsonToHtml } from '../../utils/serialize/jsonHtmlTransformations'

export declare interface EditorElementProps {
  customToolbarComponents?: any
  value
  hideToolbarOnBlur?: boolean
  onChange?
  toolbar: boolean
  hoveringToolbar: boolean
  contentStack: boolean
  toolbarMode: any
  templateRegistryCondition: any
  requestProps: any
  onFocus: any
  onBlur: any
  onMouseEnter: any
  onMouseLeave: any
  className: any
  required: boolean
  allowExtraTags: object
  customPasteOptions: object
  contentTypeNameMap: object
  disabled: boolean
  fieldName: string,
  csOnlyBreakline: boolean,
  copyPasteConfig: object
}

export const initialHtmlValue = 'scrte-_-initial-value'
let toolbarHeight = 37

const EditorElement = ({
  customToolbarComponents,
  value,
  hideToolbarOnBlur = false,
  onChange,
  toolbar = true,
  hoveringToolbar = true,
  contentStack,
  toolbarMode,
  templateRegistryCondition,
  allowExtraTags,
  customPasteOptions,
  csOnlyBreakline,
  contentTypeNameMap,
  disabled = false,
  fieldName = 'RichTextEditor',
  copyPasteConfig,
  ...props
}: EditorElementProps) => {
  const scrollRef = useRef(null)
  const editorRef = useRef(null)
  const editorResizeRef = useRef(null)
  const singleLineRef = useRef(null)
  const [slateValue, setSlateValue] = useState(value)
  const [showEditHtml, setEditHtml] = useState(false)
  let customToolbarComponentsCopy = cloneDeep(customToolbarComponents)

  useEffect(() => {
    setSlateValue(value);
    editor.children = value;
  }, [value])
  const [valueHtml, setValueHtml] = useState(initialHtmlValue)
  useEffect(() => {
    editor.requestProps = props.requestProps
  }, [props.requestProps])

  let customToolbarElement = cloneDeep(customToolbarComponents['element'] || [])
  let customToolbarLeaf = cloneDeep(customToolbarComponents['leaf'] || [])
  let customToolbarEditorButtons = cloneDeep(customToolbarComponents['editorButtons'] || [])

  if (customToolbarComponents?.element && Array.isArray(customToolbarComponents?.element)) {
    if (customToolbarElement.includes('table')) {
      customToolbarElement.push(
        'table-create-table',
        'table-delete-table',
        'table-insert-row-top',
        'table-insert-row-bottom',
        'table-delete-row',
        'table-insert-col-before',
        'table-insert-col-after',
        'table-create-header',
        'thead',
        'table-merge-cells',
        'tableHeaderBtn',
      )
    }

    if (customToolbarElement.includes('alignment')) {
      customToolbarElement.push('left-align', 'center-align', 'right-align', 'justify-align')
    }

    if (customToolbarElement.includes('lists')) {
      customToolbarElement.push('ol', 'ul')
    }

    if (customToolbarElement.includes('img')) {
      customToolbarElement.push('uploadImage', 'chooseImage')
    }
  }
  customToolbarComponentsCopy = {
    element: customToolbarElement,
    leaf: customToolbarLeaf,
    editorButtons: customToolbarEditorButtons
  }
  useEffect(() => {
    if (showEditHtml) {
      let htmlValue = jsonToHtml(slateValue[0])
      setValueHtml(htmlValue)
    } else if (valueHtml !== initialHtmlValue) {
      let slateJson = htmlToJson(valueHtml, editor.allowExtraTags)
      Transforms.removeNodes(editor, { at: [0] })
      Transforms.insertNodes(editor, slateJson, { at: [0] })
    }
  }, [showEditHtml])
  const editor = useMemo(() => applyPlugins(withHistory(withReact(createEditor() as ReactEditor))), [])

  const handleKeyDown = useCallback((event) => {
    handleShortcut(event, editor, {
      customToolbarComponents,
      contentStack,
      toolbarMode
    })
  }, [])

  const elementRenderer = useCallback(
    (props) => (
      <Element singleLineRef={singleLineRef} {...props} templateRegistryCondition={templateRegistryCondition} />
    ),
    []
  )
  const leafRenderer = useCallback((props) => <Leaf {...props} />, [])

  let newProps = {
    ...props,
    value: slateValue,
    setSlateValue: setSlateValue,
    showEditHtml: showEditHtml,
    setEditHtml: setEditHtml,
    editorRef: editorRef
  }

  const handleFocus = (e) => {
    props.onFocus(e)
  }

  const handleEmptyClick = (event) => {
    const endPoint = Editor.end(editor, [])
    const { path } = endPoint
    const node = Node.get(editor, path.slice(0, 2))
    ReactEditor.focus(editor)
    if (Editor.isVoid(editor, node)) {
      Transforms.select(editor, path)
    } else {
      Transforms.select(editor, endPoint)
    }
  }

  useEffect(() => {
    if (!editor.savedSelection) {
      savedSelection(editor)
    }
  }, [])

  const handleBlur = (e) => {
    props.onBlur(e)
    savedSelection(editor)
  }

  useEffect(() => {
    editor.uniqueId = v4().split('-').join('')
    editor.allowExtraTags = allowExtraTags
    editor.customPasteOptions = customPasteOptions || {}
    editor.contentTypeNameMap = contentTypeNameMap
    editor.csOnlyBreakline = csOnlyBreakline
    editor.copyPasteConfig = copyPasteConfig
  }, [copyPasteConfig])

  useEditorResize(editorResizeRef, showEditHtml)
  const shouldNotRenderHtmlComponent = ((valueHtml === initialHtmlValue) || !showEditHtml)
  return (
    <ReduxProvider>
      <EditorProvider.Provider value={newProps}>
        <ScrollRefProvider.Provider value={{ scrollRef }}>
          <Slate
            editor={editor}
            value={slateValue}
            onChange={(newValue) => {
              // console.log('editor', JSON.parse(JSON.stringify({
              //   selection: editor.selection,
              //   history: editor.history,
              // })));
              setSlateValue(newValue)
              onChange(newValue)
            }}>
            <div ref={singleLineRef} contentEditable={false} className={cx(dndStyles['scrte-dragline'])} />
            <div
              ref={editorRef}
              id="scrte-editor"
              className={cx(styles['super-charged-rte'], 'super-charged-rte active', disabled && styles['disabled'], css`
                display: flex;
                flex-direction: column;
                height: 100%;
                background: ${showEditHtml ? '#4C5566' : '#fff'};
              `)}
            >
              {toolbar && (
                <MainToolbar
                  customToolbarComponents={customToolbarComponentsCopy}
                  className={cx('scrte-toolbar', styles['scrte-main-toolbar'])}
                  contentStack={contentStack}
                  toolbarMode={toolbarMode}
                  templateregistrycondition={templateRegistryCondition}
                  fieldName={fieldName}
                  editorRef={editorRef}
                />
              )}
              {hoveringToolbar && !showEditHtml && (
                <HoveringToolbar
                  customToolbarComponents={customToolbarComponentsCopy}
                  scrollRef={scrollRef}
                  contentStack={contentStack}
                  toolbarMode={toolbarMode}
                />
              )}
              <div
                id="scroller"
                className={cx('scrte-scroller', `scrte-editor-${editor.uniqueId}`, {
                  [styles['scrte-scroller-edit-html']]: showEditHtml
                })}
                ref={scrollRef}>
                {shouldNotRenderHtmlComponent ? (
                  <Fragment>
                    <Editable
                      onDrop={() => { }}
                      onDragExit={() => { }}
                      onDragLeave={() => { }}
                      onDragOver={() => { }}
                      onDrag={() => { }}
                      onDragStart={() => { }}
                      onDragEnter={() => { }}
                      onDragEnd={() => { }}
                      onMouseDown={handleEmptyClick}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      renderElement={elementRenderer}
                      renderLeaf={leafRenderer}
                      onKeyDown={handleKeyDown}
                      autoCorrect="true"
                      spellCheck="true"
                      id="scrte-editable"
                      readOnly={disabled}
                      //@ts-ignore
                      className={cx(
                        siteViewStyles['editable'],
                        props.className,
                        styles['scrte-editable'],
                        'scrte-editable'
                      )}
                      required={props.required}></Editable>
                    <div className="editor-resize" ref={editorResizeRef}>
                      <Icon icon="Resize" className={styles['resize-icon']} />
                    </div>
                  </Fragment>
                ) : (
                  <HtmlEditComponent
                    valueHtml={valueHtml}
                    setValueHtml={(html) => {
                      setValueHtml(html)
                      onChange(html)
                    }}
                  />
                )}
              </div>
            </div>
            <div className="rte-fullscreen-overlay" onClick={() => handleRteOverlayClick(editorRef)}></div>
          </Slate>
        </ScrollRefProvider.Provider>
      </EditorProvider.Provider>
    </ReduxProvider>
  )
}

EditorElement.defaultProps = {
  onChange: () => {
    // intentionally blank
  },
  value: [
    {
      type: 'docs',
      children: [
        {
          type: 'h1',
          attrs: {},
          children: [{ text: 'Hello, world!' }]
        }
      ]
    }
  ],
  requestProps: {},
  onFocus: () => {
    // intentionally blank
  },
  onBlur: () => {
    // intentionally blank
  },
  onMouseEnter: () => {
    // intentionally blank
  },
  onMouseLeave: () => {
    // intentionally blank
  },
  placeholder: `Type '/' for options`,
  uid: null,
  required: false,
  allowExtraTags: {},
  customPasteOptions: {},
  contentTypeNameMap: {},
  disabled: false
}

export default EditorElement
