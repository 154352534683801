import React, { useState, useEffect, Component, useContext, useRef } from 'react'

import Icon from '../Icon2/Icon'
import AutoComplete from './AutoComplete'

import { iconName, advanceQueryOpts, tooltipWrapperId, portalId, inputTextShadow } from './util/constants'
import { getOperatorTextContent } from './util/common'
import { SearchContext } from './searchContext'
import { languageLabel, localizedInLabel } from './util/entries'
import SearchPortal from './SearchPortal'
import SearchTooltip from './SearchTooltip'
import { truncate } from './util/helpers'
import TagRemoveTooltip from './TagRemoveTooltip'

export class AdvanceQueryDropdown extends Component<any, any> {
  state = {
    cursor: 0,
  }

  showSuggestions = true
  isCursorInside = false

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false)
    document.addEventListener('keydown', this.registerKeybindings, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false)
    document.removeEventListener('keydown', this.registerKeybindings, false)
  }

  handleClickOutside = () => {
    if (!this.isCursorInside && this.props.closeAdvQueryModal) {
      console.log('Click outside detected closing closeAdvQueryModal')
      this.props.closeAdvQueryModal()
    }
  }

  registerKeybindings = (event) => {
    let { cursor } = this.state

    const { key } = event
    if (key === 'ArrowRight' && cursor < 1) {
      cursor = cursor + 1
      this.setState({ cursor })
    } else if (key === 'ArrowLeft' && cursor > 0) {
      cursor = cursor - 1
      this.setState({ cursor })
    }

    if (key === 'Enter' && cursor >= 0) {
      const suggestionItem = advanceQueryOpts[cursor]
      this.props.onClickMatchAnyOrAll(suggestionItem.value)
    }
  }

  render() {
    const customStyles: any = {}
    const topOffset = 15
    const leftOffset = 15

    if (this.props.tagBtnRef) {
      const { top: targetTop, left: targetLeft, height } = this.props.tagBtnRef.getBoundingClientRect()
      const { top: gsTop, left: gsLeft } = document.querySelector('#AdvanceSearchComponent').getBoundingClientRect()

      customStyles.top = targetTop - gsTop + height + topOffset
      customStyles.left = targetLeft - gsLeft + leftOffset
    }

    return (
      <div
        style={customStyles}
        className={`AdvancedSearch__suggestion-dropdown ${this.showSuggestions ? ' AdvancedSearch__suggestion-dropdown--show' : ''
          } AdvancedSearch__suggestion-dropdown--wide`}
        onMouseOver={() => {
          this.isCursorInside = true
        }}
        onMouseOut={() => {
          this.isCursorInside = false
        }}
      >
        <React.Fragment>
          <h6 className="AdvancedSearch__suggestion-heading">SUGGESTED CONDITION SETS</h6>
          <ul className="AdvancedSearch__suggestion_matches">
            {advanceQueryOpts.map((suggestionItem, index) => {
              return (
                <li key={index} className="AdvancedSearch__suggestion_match">
                  <button
                    className={`AdvancedSearch__match-button ${this.state.cursor === index ? 'AdvancedSearch__matched-unit-active' : ''
                      }`}
                    onClick={() => this.props.onClickMatchAnyOrAll(suggestionItem.value)}
                  >
                    {suggestionItem.name}
                  </button>
                </li>
              )
            })}
          </ul>
        </React.Fragment>
      </div>
    )
  }
}

class MatchAllAnyTag extends Component<any, any> {
  componentDidMount() {
    document.addEventListener('keyup', this.regiterKeyBindings, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.regiterKeyBindings, false)
  }

  regiterKeyBindings = (event) => {
    const { key } = event

    if (this.props.cursorY === this.props.queryObj.cursorY && this.context.isSearchVisible) {
      if (key === 'Delete') {
        const queryObj = this.props.queryObj
        if (queryObj.root) {
          return
        }
        this.props.OnClickDeleteQuery(this.props.advanceQueryArray, this.props.queryObj, this.props.index)
      } else if (key === 'Enter') {
        this.toggleMatchAnyAll()
      }
    }
  }

  toggleMatchAnyAll = () => {
    this.context.updateAdvanceQueryArray({ action: 'toggle', queryObject: this.props.queryObj })
  }

  render() {
    const isMatchAllActive = this.props.cursorY === this.props.queryObj.cursorY
    return (
      <div
        className={`AdvancedSearch__matched-unit ${isMatchAllActive ? 'AdvancedSearch__matched-unit-active' : ''}`}
        onClick={this.toggleMatchAnyAll}
      >
        {this.props.foundOpt.name}
        <Icon icon={iconName.DropdownDark} className={isMatchAllActive ? 'AdvancedSearch__chevron-active' : ''}></Icon>
      </div>
    )
  }
}

MatchAllAnyTag.contextType = SearchContext

const RenderQueries = (props) => {
  const searchContextObj = useContext(SearchContext)
  const [tagEditingIndex, updateTagEditingIndex] = useState(undefined)

  const [isContentEditableFocused, updateContentEditableFocus] = useState(false)
  const [tagInputValue, updateTagInputValue] = useState('')

  const tagRef = useRef(null)
  const { textContent, isSearchVisible } = searchContextObj

  //todo should  we register here discuss
  useEffect(() => {
    const hanldekeyBindings = (event) => {
      // event.stopPropagation()
      const { key } = event
      const queryArray = props.queryObject.queryArray
      if (key === 'Enter' && isSearchVisible) {
        for (let i = 0; i < queryArray.length; i++) {
          // let query = queryArray[i]
          let isCursorActive = props.cursorY === props.queryObject.queryCursorY && props.cursorX === i
          // console.log("hanldekeyBindings ~ isCursorActive", isCursorActive)
          if (isCursorActive) {
            openEditDropdown(i)
            return
          }
        }
      } else if (key === 'Delete' && isSearchVisible) {
        for (let i = 0; i < queryArray.length; i++) {
          let isCursorActive = props.cursorY === props.queryObject.queryCursorY && props.cursorX === i
          if (isCursorActive) {
            searchContextObj.updateAdvanceQueryArray({
              action: 'deleteByIndex',
              queryArray,
              indexToDelete: i,
              queryObject: props.queryObject,
            })
            return
          }
        }
      }
    }

    document.addEventListener('keyup', hanldekeyBindings, false)

    return () => {
      document.removeEventListener('keyup', hanldekeyBindings, false)
    }
  }, [props.cursorX, props.cursorY, isSearchVisible])

  useEffect(() => {
    if (props.isResetEditing) {
      updateTagEditingIndex(undefined)
    }
  }, [props.isResetEditing])

  const openEditDropdown = (queryIndex, event = null) => {
    const cancelClass = 'AdvancedSearch__cancel'
    if (event && (event.target.parentNode.className === cancelClass || event.target.className === cancelClass)) {
      return
    }

    if (props.queryObject.queryArray[queryIndex].isQueryCompleted) {
      if (props.isAutoCompleteOpen) {
        searchContextObj.updateAdvanceQueryArray({
          action: 'deleteIncompleteQuery',
          queryArray: props.queryObject.queryArray,
        })
        props.closeAutoComplete()
      }
      searchContextObj.setDropdownOpenStatus(true)
      props.setResetCursor()
      updateTagEditingIndex(queryIndex)
    }
  }

  const closeEditDropdown = () => {
    searchContextObj.setDropdownOpenStatus(false)
    // props.setResetCursor()
    updateTagEditingIndex(undefined)
    updateTagInputValue('')

  }

  const onEditTag = (e) => {
    const inputValue = e.target.textContent
    updateTagInputValue(inputValue)
    updateContentEditableFocus(true)
  }

  const onFocusTagInput = () => {
    updateContentEditableFocus(true)
  }

  const onBlurTagEditing = () => {
    updateContentEditableFocus(false)
  }



  const removeTag = (index: any) => {
    searchContextObj.updateAdvanceQueryArray({
      action: 'deleteByIndex',
      queryArray,
      indexToDelete: index,
      queryObject: props.queryObject,
    })
  }


  const handleRemove = (event, queryIndex) => {
    event.stopPropagation();
    removeTag(queryIndex)

  }

  const queryArray = props.queryObject.queryArray || []
  return queryArray.map((query, queryIndex) => {
    const { key, operator, value, queryType } = query

    const localizedQuery = queryArray[queryIndex + 1]
    const isCurrLang =
      queryType === languageLabel.queryType && localizedQuery && localizedQuery.queryType === localizedInLabel.queryType

    return key.map((queryKey, index) => {
      const operatorForKey = operator[index]
      const valueForKey = (value[index] && value[index]) || {}
      let isCursorActive = props.cursorY === props.queryObject.queryCursorY && props.cursorX === queryIndex

      let tooltipContent = getOperatorTextContent({
        operatorKey: operatorForKey,
        queryType,
        textContent,
      })

      let addClass = ''
      if (isCurrLang) {
        addClass = 'AdvancedSearch__matched-criteria-count-mid-lang'
      }

      const { truncatedText, isOverflow } = truncate(valueForKey.label)

      return (
        <div className="flex-v-center" key={props.queryObject.cursorY + index}>
          <div
            id={`AdvanceSearch-tag-${props.queryObject.cursorY}-${queryIndex}`}
            ref={tagRef}
            className={`AdvancedSearch__matched-criteria  ${isCursorActive ? ` AdvancedSearch__matched-criteria-active` : ''
              }`}
            onClick={(e) => openEditDropdown(queryIndex, e)}
          >
            <TagRemoveTooltip isCursorActive={isCursorActive} onRemove={(e) => handleRemove(e, queryIndex)}>
              <div className="flex-v-center AdvancedSearch__remove-tag">
                <span className="AdvancedSearch__matched-type">{queryKey.label}</span>
                <SearchTooltip content={tooltipContent} position="top">
                  <span className="AdvancedSearch__matched-operator">
                    <Icon icon={operatorForKey.icon}></Icon>
                  </span>
                </SearchTooltip>

                <span
                  className="AdvancedSearch__matched-string"
                  contentEditable={tagEditingIndex === queryIndex}
                  onInput={onEditTag}
                  onFocus={onFocusTagInput}
                  onBlur={onBlurTagEditing}
                  suppressContentEditableWarning={true}
                >
                  {isOverflow ? (
                    <SearchTooltip content={valueForKey.label} position="top">
                      {truncatedText}
                    </SearchTooltip>
                  ) : (
                    valueForKey.label
                  )}
                </span>
                {query.isQueryCompleted && tagEditingIndex !== queryIndex && (
                  <div className={`AdvancedSearch__dropdown `}>
                    <Icon
                      icon={iconName.DropdownDark}
                      className={`Dropdown__icon ${isCursorActive ? 'AdvancedSearch__chevron-active' : ''}`}
                    />
                  </div>
                )}
                {tagEditingIndex === queryIndex && (
                  <SearchPortal domId={portalId}>
                    <AutoComplete
                      editInputRef={document.getElementById(
                        `AdvanceSearch-tag-${props.queryObject.cursorY}-${queryIndex}`
                      )}
                      resetInputValue={() => { }}
                      inputValue={tagInputValue}
                      disableArrowKey={isContentEditableFocused}
                      closeAutoComplete={closeEditDropdown}
                      isAutoCompleteOpen={props.isAutoCompleteOpen}
                      queryType={query.queryType}
                      nextQuery={query.nextQuery}
                      currentSearch={query}
                      queryArray={queryArray}
                      needOperator={true}
                      action="edit"
                      editableQueryIndex={queryIndex}
                      selectedOption={props.selectedOption.value}
                      fetchData={props.fetchData}
                      setPlaceholder={props.setPlaceholder}
                      case="advanceQuery"
                      inputName={props.inputName}
                      queryObject={props.queryObject}
                      selectedOperator={operatorForKey.value}
                    />
                  </SearchPortal>
                )}
              </div>
            </TagRemoveTooltip>
          </div>
          {isCurrLang && (
            <span className="AdvancedSearch__connector-count AdvancedSearch__connector-count-lang">{'&'}</span>
          )}
        </div>
      )
    })
  })
}

class FilterInput extends Component<any, any> {
  state = {
    inputValue: '',
    isFocused: false,
    isAdvanceQueryDropdownOpen: false,
    isResetInput: false,
    inputType: 'text',
  }
  filterInputRef: any = React.createRef()

  async componentDidMount() {
    if (this.filterInputRef && this.filterInputRef.current) {
      this.filterInputRef.current.focus()
    }
  }

  componentDidUpdate(prevProps) {
    const queryArray = this.props.queryObject.queryArray
    if (this.props.isAutoCompleteOpen && queryArray.length && !queryArray[queryArray.length - 1].isQueryCompleted) {
      if (this.filterInputRef && this.filterInputRef.current) {
        this.filterInputRef.current.focus()
      }
    }
  }

  closeAdvQueryModal = () => {
    this.setState({ isAdvanceQueryDropdownOpen: false, inputValue: '' })
  }

  handleKeyDown = (event) => {
    const { key } = event
    const queryArrayLength = this.props.queryObject.queryArray.length

    if (key === 'Backspace' && !this.state.inputValue && queryArrayLength) {
      const cursorYmatch = this.props.cursorY === this.props.queryObject.cursorY + 1
      const cursorXmatch = this.props.cursorX === queryArrayLength - 1
      if (cursorYmatch && cursorXmatch) {
        if (this.props.isAutoCompleteOpen) {
          this.props.closeAutoComplete()
        }
        this.props.resetEditing()
        this.context.updateAdvanceQueryArray({
          action: 'deleteByIndex',
          queryArray: this.props.queryObject.queryArray,
          indexToDelete: queryArrayLength - 1,
          queryObject: this.props.queryObject,
        })
      } else {
        this.props.setResetCursor(this.props.queryObject.cursorY + 1, queryArrayLength - 1)
      }
    }
  }

  //autocomplte logic handling
  updateInputValue = (e) => {
    const inputValue = e.target.value
    // const value = inputValue
    const queryArray = this.props.queryObject.queryArray

    if (
      ((inputValue === ':' && !this.props.queryObject.root) || inputValue === ';') &&
      (!queryArray.length || !queryArray.find((query) => query.isQueryCompleted === false))
    ) {
      this.props.closeAutoComplete()
      this.openAdvanceQueryDropdown()
    } else {
      this.setState({ isAdvanceQueryDropdownOpen: false }, () => {
        if (!this.props.isAutoCompleteOpen) {
          this.props.openAutoComplete()
        }
      })
    }
    this.setState({ inputValue, isResetInput: false })
  }

  resetInputValue = () => {
    this.setState({ inputValue: '', isResetInput: true })
  }

  handleOnClick = () => {
    this.setState({ isFocused: true })
    this.props.openAutoComplete()
  }

  closeAutoComplete = () => {
    this.setState({ inputValue: '', isFocused: false })
    this.props.closeAutoComplete()
  }

  closeAdvanceQueryDropdown = () => {
    this.setState({ isAdvanceQueryDropdownOpen: false })
  }

  openAdvanceQueryDropdown = () => {
    this.setState({ isAdvanceQueryDropdownOpen: true })
  }

  setInputType = (inputType) => {
    this.setState({ inputType })
  }

  onClickMatchAnyOrAll = (selectedMatch) => {

    // console.log('')
    const nestOrDuplicate = this.state.inputValue === ';' ? 'nest' : 'duplicate'

    let currentQuery: any = {
      advanceQueryObject: this.props.queryObject,
      case: nestOrDuplicate,
    }

    if (nestOrDuplicate === 'duplicate') {
      currentQuery = { ...currentQuery, advanceQueryArray: this.props.advanceQueryArray }
    }

    this.context.updateAdvanceQueryMatchCase(selectedMatch, currentQuery)
    this.setState({ isAdvanceQueryDropdownOpen: false, inputValue: '' })
  }

  render() {
    const queryArray = this.props.queryObject.queryArray

    const inputValueLength = this.state.inputValue.length

    // show module placeholder if suggestion dropdown not open
    const modulePlaceholderCheck = !this.props.isAutoCompleteOpen && this.context.textContent.placeholders.advance_search_query
    //if input value is not too long show using mirrorSpan else using input itself
    const mirrorPlaceholderCheck = this.state.inputValue ? inputValueLength <= 20 ? true : false : this.state.isFocused || !queryArray.length
    const activeSelectionCheck = this.state.inputValue && this.props.activeSelection ? this.props.activeSelection.toLowerCase().startsWith(this.state.inputValue.toLocaleLowerCase()) : true
    return (
      <div className="AdvancedSearch__current-query">
        <div className="AdvancedSearch__input-wrapper">
          <input
            style={{ textShadow: inputValueLength > 20 ? inputTextShadow : '' }}
            disabled={this.state.inputType === 'tagLabelCase'}
            type={this.state.inputType}
            value={this.state.inputValue}
            className="AdvancedSearch__query-input"
            aria-label="input-search-query"
            name={this.props.inputName}
            onChange={this.updateInputValue}
            onClick={this.handleOnClick}
            ref={this.filterInputRef}
            autoComplete="off"
            onKeyDown={this.handleKeyDown}
            autoFocus
          />
          {mirrorPlaceholderCheck && (
            <span
              className={`AdvancedSearch__query-autocomplete ${this.state.inputValue ? 'AdvancedSearch__query-autocomplete-mirror' : ''
                }`}
            >
              {activeSelectionCheck && this.props.activeSelection || this.state.inputValue || modulePlaceholderCheck}
            </span>
          )}
        </div>

        {this.props.isAutoCompleteOpen && (
          <SearchPortal domId={portalId}>
            <AutoComplete
              editInputRef={this.filterInputRef.current}
              resetInputValue={this.resetInputValue}
              inputValue={this.state.inputValue}
              closeAutoComplete={this.closeAutoComplete}
              selectedOption={this.props.selectedOption.value}
              fetchData={this.props.fetchData}
              setPlaceholder={this.props.setPlaceholder}
              case={this.props.case}
              queryArray={queryArray}
              isAutoCompleteOpen={this.props.isAutoCompleteOpen}
              inputName={this.props.inputName}
              isResetInput={this.state.isResetInput}
              queryObject={this.props.queryObject}
              setInputType={this.setInputType}
              inputType={this.state.inputType}
            />
          </SearchPortal>
        )}

        {this.state.isAdvanceQueryDropdownOpen && (
          <SearchPortal domId={portalId}>
            <AdvanceQueryDropdown
              tagBtnRef={this.filterInputRef.current}
              onClickMatchAnyOrAll={this.onClickMatchAnyOrAll}
              closeAdvQueryModal={this.closeAdvQueryModal}
            />
          </SearchPortal>
        )}
      </div>
    )
  }
}

FilterInput.contextType = SearchContext

class RenderAdvanceQuery extends Component<any, any> {
  state = {
    addQueryRootIndex: -1,
    addQueryNestedIndex: -1,
    currentQuery: null,
    inputValue: '',
    activeInputIndex: -1,
    placeholder: '',
    isMouseOverQuery: false,
    isResetEditing: false,
  }

  closeModal = () => {
    this.setState({ addQueryRootIndex: -1, addQueryNestedIndex: -1 })
  }

  handleClickAddQueryRoot = (i, currentQuery) => {
    this.setState({ currentQuery })
    setTimeout(() => {
      this.setState({ addQueryRootIndex: i })
    })
  }

  handleClickAddQueryNested = (i, currentQuery) => {
    this.setState({ currentQuery })
    setTimeout(() => {
      this.setState({ addQueryNestedIndex: i })
    })
  }

  OnClickDeleteQuery = (advanceQueryArray, queryObj, i) => {
    this.props.setResetCursor()
    this.context.onDeleteAdvanceQuery({
      currentAdvanceQueryArray: advanceQueryArray,
      index: i,
    })
  }

  onClickMatchAnyOrAll = (selectedMatch) => {
    this.context.updateAdvanceQueryMatchCase(selectedMatch, this.state.currentQuery)
    this.closeModal()
  }

  openAutoComplete = (event, index) => {
    if (this.state.activeInputIndex !== index) {
      //set drop down statue
      this.context.setDropdownOpenStatus(true)
      //reset cursor on open
      this.props.setResetCursor()
      this.resetEditing()
      this.setState({ activeInputIndex: index })
    }
  }

  closeAutoComplete = () => {
    console.log('Advance Search query closeAutoComplete')
    this.context.setDropdownOpenStatus(false)
    // this.props.setResetCursor()
    this.setState({ activeInputIndex: -1, placeholder: '' })
  }

  setPlaceholder = (placeholder) => {
    this.setState({ placeholder })
  }

  onMouseOver = () => {
    this.setState({ isMouseOverQuery: true })
  }

  onMouseOut = () => {
    this.setState({ isMouseOverQuery: false })
  }

  resetEditing = () => {
    this.setState({ isResetEditing: true }, () => {
      this.setState({ isResetEditing: false })
    })
  }

  render() {
    const { textContent } = this.context

    return this.props.advanceQueryArray.map((queryObj, i) => {
      const foundOpt = advanceQueryOpts.find((opt) => opt.value === queryObj.queryType)
      return (
        <div
          className={`AdvancedSearch__matched-group ${queryObj.cursorY === queryObj.error_cursor ? 'AdvancedSearch__matched-group-error' : ''
            } ${this.props.advanceQueryArray.length - 1 !== i ? 'AdvancedSearch__matched-group__no-nested-actions' : ''}`}
          key={i}
        >
          <MatchAllAnyTag
            foundOpt={foundOpt}
            cursorY={this.props.cursorY}
            queryObj={queryObj}
            advanceQueryArray={this.props.advanceQueryArray}
            index={i}
            OnClickDeleteQuery={this.OnClickDeleteQuery}
          />
          {!queryObj.root && (
            <div className="AdvancedSearch__actions">
              <button
                id={`AdvanceSearch_query-${queryObj.cursorY}`}
                key={i}
                className="AdvancedSearch__add-query"
                onClick={() => {
                  this.handleClickAddQueryRoot(i, {
                    advanceQueryObject: queryObj,
                    advanceQueryArray: this.props.advanceQueryArray,
                    case: 'duplicate',
                  })
                }}
              >
                <SearchTooltip content={textContent.tooltips.advance_query_right_remove_hover} position="top">
                  <Icon icon={iconName.AddQueryBuilder}></Icon>
                </SearchTooltip>
              </button>

              <SearchTooltip content={textContent.tooltips.clear_search} position="top">
                <button
                  className="AdvancedSearch__remove-query"
                  onClick={() => this.OnClickDeleteQuery(this.props.advanceQueryArray, queryObj, i)}
                >
                  <Icon icon={iconName.DeleteQueryBuilder}></Icon>
                </button>
              </SearchTooltip>
              {this.state.addQueryRootIndex === i && (
                <SearchPortal domId={portalId}>
                  <AdvanceQueryDropdown
                    tagBtnRef={document.getElementById(`AdvanceSearch_query-${queryObj.cursorY}`)}
                    closeAdvQueryModal={this.closeModal}
                    onClickMatchAnyOrAll={this.onClickMatchAnyOrAll}
                  />
                </SearchPortal>
              )}
            </div>
          )}

          <div className="AdvancedSearch__matched-criterias">
            <RenderQueries
              queryObject={queryObj}
              closeAutoComplete={this.closeAutoComplete}
              isAutoCompleteOpen={this.state.activeInputIndex === i}
              selectedOption={this.props.selectedOption}
              setPlaceholder={this.setPlaceholder}
              fetchData={this.props.fetchData}
              inputName={`query-input${queryObj.cursorY}`}
              cursorY={this.props.cursorY}
              cursorX={this.props.cursorX}
              setResetCursor={this.props.setResetCursor}
              isResetEditing={this.state.isResetEditing}
            />
            {queryObj.children &&
              queryObj.children.length &&
              !(queryObj.queryArray && queryObj.queryArray.length) ? null : (
              <FilterInput
                openAutoComplete={(e) => this.openAutoComplete(e, queryObj.cursorY + i)}
                isAutoCompleteOpen={this.state.activeInputIndex === queryObj.cursorY + i}
                closeAutoComplete={this.closeAutoComplete}
                activeSelection={this.state.placeholder}
                setPlaceholder={this.setPlaceholder}
                fetchData={this.props.fetchData}
                inputName={`query-input${queryObj.cursorY}`}
                selectedOption={this.props.selectedOption}
                case="advanceQuery"
                queryObject={queryObj}
                resetEditing={this.resetEditing}
                advanceQueryArray={this.props.advanceQueryArray}
                cursorY={this.props.cursorY}
                cursorX={this.props.cursorX}
                setResetCursor={this.props.setResetCursor}
              />
            )}
          </div>
          {queryObj.children && queryObj.children.length > 0 && (
            <RenderAdvanceQuery
              advanceQueryArray={queryObj.children}
              fetchData={this.props.fetchData}
              selectedOption={this.props.selectedOption}
              cursorY={this.props.cursorY}
              cursorX={this.props.cursorX}
              setResetCursor={this.props.setResetCursor}
            />
          )}
          {this.props.advanceQueryArray.length - 1 === i ? (
            <div className="relative AdvancedSearch__add-nested-query">
              <button
                id={`AdvanceSearch_query_nested-${queryObj.cursorY}`}
                className="AdvancedSearch__add-query"
                onClick={() =>
                  this.handleClickAddQueryNested(i, {
                    advanceQueryObject: queryObj,
                    case: 'nest',
                  })
                }
              >
                <SearchTooltip content={textContent.tooltips.advance_query_bottom_remove_hover} position="top">
                  <Icon icon={iconName.AddQueryBuilder}></Icon>
                </SearchTooltip>
              </button>
              {this.state.addQueryNestedIndex === i && (
                <SearchPortal domId={portalId}>
                  <AdvanceQueryDropdown
                    tagBtnRef={document.getElementById(`AdvanceSearch_query_nested-${queryObj.cursorY}`)}
                    closeAdvQueryModal={this.closeModal}
                    onClickMatchAnyOrAll={this.onClickMatchAnyOrAll}
                  />
                </SearchPortal>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      )
    })
  }
}

RenderAdvanceQuery.contextType = SearchContext

//imp do not put this inside its a special case tested otherwise returs undefined
let queryArrayLength
const getQueryArrayLengthByCursor = (advanceQueryArray, cursorY) => {
  for (let queryObj of advanceQueryArray) {
    // console.log('queryObj.cursor', queryObj.cursorY, cursorY)
    if (queryObj.cursorY === cursorY || queryObj.queryCursorY === cursorY) {
      // console.log('data foound', queryObj, queryObj.queryArray.length)
      queryArrayLength = queryObj.queryArray.length
    } else if (queryObj.children.length) {
      getQueryArrayLengthByCursor(queryObj.children, cursorY)
    }
  }

  return queryArrayLength
}

class AdvanceSearchQuery extends Component<any, any> {
  state = {
    cursorY: -1,
    cursorX: 0,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.regiterKeyBindings, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.regiterKeyBindings, false)
  }

  setResetCursor = (cursorY = -1, cursorX = 0) => {
    this.setState({ cursorY, cursorX })
  }

  regiterKeyBindings = (event) => {
    const { key } = event

    let { cursorY, cursorX } = this.state

    //if any(edit,create) dropdown not open then only allow row, column wise nav for adv query
    if (!this.context.isDropdownOpen && this.context.isSearchVisible) {
      if (key === 'ArrowDown' && cursorY < this.props.horizontalAdvQueryLength) {
        cursorY = cursorY + 1
        console.log('ArrowDown  cursorY cursorX', cursorY)
        this.setState({ cursorY, cursorX: 0 })
      } else if (key === 'ArrowUp' && cursorY >= 0) {
        cursorY = cursorY - 1
        console.log('ArrowUp  cursorY', cursorY)

        this.setState({ cursorY, cursorX: 0 })
      } else if (key === 'ArrowRight') {
        const queryArrayLength = getQueryArrayLengthByCursor(this.props.advanceQueryArray, cursorY)
        if (cursorX < queryArrayLength - 1) {
          cursorX = cursorX + 1
          this.setState({ cursorX })
          console.log('==> ArrowRight  cursorX', cursorX)
        }
      } else if (key === 'ArrowLeft' && cursorX > 0) {
        cursorX = cursorX - 1
        console.log('ArrowLeft  cursorX', cursorX)

        this.setState({ cursorX })
      }
    }
  }



  render() {
    return (
      <div className="AdvancedSearch__query-builder-wrapper" >
        <div className="AdvancedSearch__query-builder">
          <RenderAdvanceQuery
            advanceQueryArray={this.props.advanceQueryArray}
            fetchData={this.props.fetchData}
            selectedOption={this.props.selectedOption}
            cursorY={this.state.cursorY}
            cursorX={this.state.cursorX}
            setResetCursor={this.setResetCursor}
          />
        </div>
      </div>
    )
  }
}

AdvanceSearchQuery.contextType = SearchContext

export default AdvanceSearchQuery

//text content in adv search footer
//workflow stage fetch on scroll handle -> discuss for count issue for worklfow stage
//workflow stage uid populate all to fetch together

//recent search issues for workflow case