//@ts-nocheck
import React, { useState, useRef, useEffect } from 'react'
import { Transforms } from 'slate'
import { useSlateStatic, ReactEditor, useSelected, useFocused } from 'slate-react'
import { cx } from '@emotion/css'
import { Registry } from '@react-registry'
import { Resizable } from 're-resizable'

import { cbModal, ActionTooltip, Icon } from '../../../../../../index'
import { FullscreenIcon } from '../../utils/Icon'
import { handleDelete } from '../../utils/actions'
//@ts-ignore
import styles from './style.module.css'
import { ImageEditModal } from './ImageEditModal'
import { ImageFullScreen } from './ImageFullScreen'
import { handleImageError } from '.'

export const ImageElement = ({ attributes, children, element, attrs, type, entry, ...props }) => {
  const selected = useSelected()
  const focused = useFocused()
  const editor = useSlateStatic()
  // TODO: textAlign to text-align
  const alignment = attrs?.style?.["textAlign"] || attrs?.style?.["text-align"]
  const imgRef = useRef(null)
  const parentRef = useRef(null)
  const [maxWidth, setMaxWidth] = useState(null)


  let isInline = (element?.attrs?.inline || element?.attrs?.["redactor-attributes"]?.inline) ? true : false

  useEffect(() => {
    setMaxWidth(attributes.ref.current.parentElement.clientWidth - 48)
  }, [maxWidth])


  const onResizeStop = (event, dir, el, size) => {
    let path = ReactEditor.findPath(editor, element)
    let { attrs } = element
    let width = imgRef.current.clientWidth
    const newAttrs = { ...attrs };

    newAttrs['width'] = width;
    const newStyles = { ...newAttrs.style }
    newAttrs.style = { ...newStyles }
    newAttrs.style['max-width'] = `${width}px`
    newAttrs.style['width'] = `${width}px`
    newAttrs['max-width'] = `${width}`
    newAttrs['width'] = `${width}`
    Transforms.setNodes(
      editor,
      { attrs: { ...newAttrs } },
      {
        at: path
      }
    )
  }


  const onResize = (event, dir, el, size) => {
    setMaxWidth(attributes.ref.current.parentElement.clientWidth - 48)
    let width = imgRef.current.clientWidth
    if (attributes?.ref?.current) {
      attributes.ref.current.setAttribute('width', width)
      attributes.ref.current.setAttribute('max-width', width)
      attributes.ref.current.style.width = `${width}px`;
      attributes.ref.current.style.maxWidth = `${width}px`;
    }
  }

  const handleClick = () => {
    const selection = window.getSelection()
    selection.removeAllRanges()
    const range = document.createRange()
    range.selectNodeContents(imgRef.current)
    selection.addRange(range)
  }

  let alignmentStyle
  if (alignment) {
    if (alignment === 'center' || alignment === 'justify') {
      delete attrs.style['float']
    }
    else {
      attrs.style['float'] = alignment
    }
    const marginAlignment = {
      center: { margin: 'auto' },
      left: { marginRight: 'auto' },
      right: { marginLeft: 'auto' }
    }
    alignmentStyle = marginAlignment[alignment]
  }
  /* istanbul ignore if */
  if (isInline) {
    alignmentStyle = { display: "inline-block" }
    if (alignment) {
      alignmentStyle.float = alignment
    }
  }

  alignmentStyle = isInline
    ?
    { ...alignmentStyle }
    : alignmentStyle

  const toolList = [
    {
      label: <FullscreenIcon />,
      title: 'Fullscreen',
      action: () => {
        editor.setFullScreen();
        cbModal({
          component: (props) => <ImageFullScreen url={type === 'asset' ? entry?.['asset-link'] : element?.attrs?.url} {...props} />
        })
      }
    },
    {
      label: <Icon icon="Edit" size="small" />,
      title: 'Edit',
      action: () => {
        editor.setFullScreen();
        cbModal({
          component: (props) => <ImageEditModal type={type} element={element} editor={editor} {...props} />
        })
      }
    },
    {
      label: <Icon icon="Trash" size="small" />,
      title: 'Delete',
      action: () => {
        handleDelete(editor, element)
      }
    }
  ]
  /* istanbul ignore if */
  if (type === 'asset') {
    toolList.unshift(
      {
        label: <Icon icon='View' size='small' />,
        title: 'View',
        action: () => {
          const { history, apiKey } = editor?.['requestProps'] || {};
          const assetUid = attrs?.['asset-uid'];
          if (!history || !apiKey) return;
          history.push(`/stack/${apiKey}/assets/${assetUid}`);
        }
      }
    )
  }
  return (
    <span {...attributes} {...attrs} onClick={handleClick} data-type="img" style={{ ...attrs.style, ...alignmentStyle }} data-testid={'image-wrapper'}>
      <span
        ref={parentRef}
        contentEditable={false}
        style={{
          paddingTop: '3px',
          paddingBottom: '3px',
          display: `${isInline ? 'block' : 'flow-root'}`,
        }}>
        <Resizable
          onResize={onResize}
          onResizeStop={onResizeStop}
          lockAspectRatio={false}
          size={{
            width: `${isInline ? 'auto' : element.attrs.width ? element.attrs.width + "px" : 'auto'}`,
            height: 'auto'
          }}
          maxWidth={maxWidth}
          className={cx(styles['scrte--img-container'], {
            [styles['scrte--img-inline']]: Boolean(isInline)
          })}
          handleStyles={{
            right: { right: 0, width: '15px' },
            left: { left: 0, width: '15px' },
            bottom: { bottom: '5px' },
            bottomLeft: { left: '-10px', bottom: '-5px' },
            bottomRight: { bottom: '-5px', right: '-10px' }
          }}
          handleWrapperClass="test2"
          minWidth={165}
        // enable={{ top: !isInline, right: !isInline, bottom: !isInline, left: !isInline, topRight: !isInline, bottomRight: !isInline, bottomLeft: !isInline, topLeft: !isInline }}
        >
          <div ref={imgRef}>
            <ActionTooltip list={toolList} className={styles['scrte--action-tooltip']}>
              <img
                id="image"
                src={type === 'asset' ? entry['url'] : element.attrs.url}
                onError={handleImageError}
                className={cx(styles['scrte-img'], {
                  [styles['scrte--img-selected']]: Boolean(selected && focused)
                })}
                alt={element.attrs.alt}
                data-testid={'scrte-image'}
              />
            </ActionTooltip>
            <span contentEditable={false} className={styles['scrte-image-resizer-feedback-left']}>
              <span
                contentEditable={false}
                className={styles['scrte-image-resizer-feedback']}></span>
            </span>
            <span contentEditable={false} className={styles['scrte-image-resizer-feedback-right']}>
              <span
                contentEditable={false}
                className={styles['scrte-image-resizer-feedback']}></span>
            </span>
          </div>
          {element?.attrs?.caption && (
            <p className={styles['scrte--caption']}>{element.attrs.caption}</p>
          )}
        </Resizable>
      </span>
      {children}
    </span>
  )
}

const beforeElementRender = (props) => {
  if (props?.element?.attrs?.inline || props?.element?.attrs?.style?.float === 'right' || props?.element?.attrs?.style?.float === 'left') {
    props.DisableDND = true
    props.DisableSelectionHalo = true
  }
}
Registry.register(
  {
    Component: ImageElement,
    //iconName: 'ri-image-line',
    title: 'Image',
    subtitle: 'Add beautiful images',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false
    },
    beforeElementRender: beforeElementRender
  },
  { id: 'img', registry: 'v2.element' }
)


