import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'

import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup/ButtonGroup'
import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import ActionTooltip from '../ActionTooltip/ActionTooltip'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import Tooltip from '../Tooltip/Tooltip'
import TableRowActionWrapper from './TableRowAction'

import { getRowsLength, defaultValue, getTableRowClassString, getFreezeColumnCount, getMinMaxStyle } from './util'

const getDefaultColumnStyleWidth = (equalWidthColumns, columnWidthMultiplier, tableRowAction, column, isResizable) => {
  //retaining def column width for larger table for table row action
  if (tableRowAction && column.width && !isResizable) {
    return { width: column.width }
  }

  let defaultColumnStyleWidth = equalWidthColumns
    ? {}
    : {
        ...(columnWidthMultiplier ? { width: `${(columnWidthMultiplier * defaultValue.baseColumnWidth) / 16}rem` } : {})
      }

  return defaultColumnStyleWidth
}

const getTableCellStyle = (argsData) => {
  const {
    column,
    cellProps,
    // columnWidth = null,
    columnResizing = {},
    columnWidthsObjectState,
    columnWidthsObjectProp,
    equalWidthColumns,
    isResizable,
    singleColumnWidth,
    tableRowAction
  } = argsData

  let columnWidth = column.width
  if (singleColumnWidth > 0 && column.id !== 'tableActions' && tableRowAction) {
    return { ...cellProps.style, width: singleColumnWidth }
  }

  let defaultColumnStyleWidth = getDefaultColumnStyleWidth(
    equalWidthColumns,
    column.columnWidthMultiplier,
    tableRowAction,
    column,
    isResizable
  )

  if (!isResizable) {
    return defaultColumnStyleWidth
  }

  const columnWidthsArrayProp = Object.keys(columnWidthsObjectProp)
  const columnWidthsArrayState = Object.keys(columnWidthsObjectState)

  if (column.isResizing && columnResizing.isResizingColumn === column.id) {
    return { ...cellProps.style, width: columnResizing.columnWidth }
  }

  if (
    (columnWidthsArrayProp.length > 0 && columnWidthsObjectProp.hasOwnProperty(column.id)) ||
    (columnWidthsArrayState.length > 0 && columnWidthsObjectState.hasOwnProperty(column.id))
  ) {
    return { ...cellProps.style }
  }

  const width = columnWidth || defaultColumnStyleWidth
  return { width }
}

const getColumnStyle = (argsData) => {
  const {
    column,
    cellProps,
    columnWidthsObjectState,
    columnWidthsObjectProp,
    equalWidthColumns,
    isResizable,
    totalCounts,
    singleColumnWidth,
    tableRowAction
  } = argsData

  let columnWidth = column.width

  if (totalCounts === 0) {
    return { width: defaultValue.noDataColumnWidth }
  }

  if (singleColumnWidth > 0 && column.id !== 'tableActions' && tableRowAction) {
    return { width: singleColumnWidth }
  }

  let defaultColumnStyleWidth = getDefaultColumnStyleWidth(
    equalWidthColumns,
    column.columnWidthMultiplier,
    tableRowAction,
    column,
    isResizable
  )

  if (!isResizable) {
    return defaultColumnStyleWidth
  }

  const columnWidthsArrayProp = Object.keys(columnWidthsObjectProp)
  const columnWidthsArrayState = Object.keys(columnWidthsObjectState)

  if (
    (columnWidthsArrayProp.length > 0 && columnWidthsObjectProp.hasOwnProperty(column.id)) ||
    (columnWidthsArrayState.length > 0 && columnWidthsObjectState.hasOwnProperty(column.id))
  ) {
    return { ...cellProps.style }
  }

  const width = columnWidth || defaultColumnStyleWidth
  return { width }
}

const getTableRowProps = (rowProps, cells, style, isResizable, singleColumnWidth, tableRowAction) => {
  if (singleColumnWidth > 0 && tableRowAction) {
    return { ...rowProps, style: { ...rowProps.style, ...style, width: 'auto' } }
  }

  if (!isResizable) {
    return { ...rowProps, style: { ...rowProps.style, ...style } }
  }

  //removing width from react window list style to avoid changing cell width on resize
  const { width, ...restWindowListStyle } = style
  let isResizingColumnIndex = cells.findIndex((cell) => cell.column.isResizing)
  if (isResizingColumnIndex >= 0) {
    let columnWidth = 0
    let cellsTotalWidth = cells.map((cell) => {
      columnWidth += cell.column.width
      return columnWidth
    })
    return {
      ...rowProps,
      style: { display: 'flex', width: cellsTotalWidth + 'px', ...restWindowListStyle }
    }
  }
  return { ...rowProps, style: { ...rowProps.style, ...restWindowListStyle } }
}

export const SingleRowSelectedUi = (props) => {
  return (
    <>
      {props.children}
      <Icon icon="SelectSingle" size="original" className="single-select" />
    </>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  (
    { indeterminate, maxSelect, selectedData = [], rowData = {}, uniqueKey, totalCount, rowsLength, ...rest }: any,
    ref
  ) => {
    const [disable, setDisable] = useState(false)
    const maxSelectInt = parseInt(maxSelect)
    const defaultRef = React.useRef()
    const resolvedRef: any = ref || defaultRef

    useEffect(() => {
      if (
        selectedData.length >= maxSelectInt &&
        rowData &&
        !selectedData.find((data) => data[uniqueKey] === rowData[uniqueKey]) &&
        !rowData.isHeader
      ) {
        setDisable(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indeterminate])

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resolvedRef, indeterminate])

    const headerCheckboxCondCheck = rowData.isHeader && !rowsLength

    const tooltipContent = headerCheckboxCondCheck
      ? 'Cannot bulk select any items in the list below'
      : disable || (rowData.isHeader && maxSelectInt === selectedData.length)
      ? 'Bulk selection limit reached'
      : ''

    return tooltipContent ? (
      <div className="max-select-tooltip">
        <Tooltip content={tooltipContent} position="bottom">
          <Checkbox
            inputRef={resolvedRef}
            {...rest}
            id="rowSelect"
            disabled={headerCheckboxCondCheck ? true : disable}
          />
        </Tooltip>
      </div>
    ) : (
      <Checkbox inputRef={resolvedRef} {...rest} id="rowSelect" disabled={headerCheckboxCondCheck ? true : disable} />
    )
  }
)

export const pushActionColumn = (hooks, { rowActionData, updateRowActionData }) => {
  hooks.visibleColumns.push((columns: any) => [
    ...columns,
    {
      id: 'tableActions',
      disableSortBy: true,
      width: 93,
      Header: 'Actions',
      Cell: ({ row }) => {
        return (
          <div
            className={classnames(
              'three-dots-vertical-icon',
              rowActionData.rowIndex === row.index && 'three-dots-vertical-icon-selected'
            )}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              const { target }: any = e

              const parentWithClass = target.closest('div.Table__body__cell--tableRowAction')

              let targetTop = parentWithClass.getBoundingClientRect().top
              let targetLeft = parentWithClass.getBoundingClientRect().left

              if (rowActionData.rowIndex !== row.index) {
                //first close will run on unmount of TableRowAction and then open
                setTimeout(() => {
                  updateRowActionData({
                    rowIndex: row.index,
                    clientY: targetTop,
                    clientX: targetLeft
                  })
                })
              }
            }}>
            <Icon icon="DotsThreeLargeVertical" width="20px" height="32px" viewBox="0 0 32 32" />
          </div>
        )
      }
    }
  ])
}

export const pushCheckboxInRow = (
  hooks,
  { rowSelectCheckboxProp, selectedData, maxSelect, totalCount, uniqueKey, rowDisableProp }
) => {
  hooks.visibleColumns.push((columns: any) => [
    {
      id: 'selection',
      disableSortBy: true,
      minWidth: defaultValue.selectColumnWidth,
      width: defaultValue.selectColumnWidth,
      maxWidth: defaultValue.selectColumnWidth,
      Header: ({
        getToggleAllRowsSelectedProps,
        toggleRowSelected,
        toggleAllRowsSelected,
        selectedFlatRows,
        rows
      }: any) => {
        //todo refactor this in a func
        let checkboxProps = { ...getToggleAllRowsSelectedProps() }
        const rowsLength = getRowsLength(rows, rowSelectCheckboxProp)
        if (maxSelect) {
          let currentSelectLimit = maxSelect - selectedData.length

          const overriddenChecked =
            selectedData.length &&
            rows.length &&
            (selectedData.length === maxSelect || (rowsLength < maxSelect && selectedFlatRows.length === rowsLength)
              ? true
              : false)
          const overriddenIndeterminate = !overriddenChecked && selectedData.length > 0

          const overriddenOnChange = (e) => {
            if (currentSelectLimit > 0 && e.currentTarget.checked) {
              let count = 0
              for (let i = 0; i < rows.length; i++) {
                let tableRow = rows[i]
                if (count >= currentSelectLimit) {
                  return
                }
                if (rowSelectCheckboxProp) {
                  if (
                    tableRow.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value &&
                    !tableRow.isSelected
                  ) {
                    toggleRowSelected(tableRow.id, e.currentTarget.checked)
                    count = count + 1
                  }
                } else if (!tableRow.isSelected) {
                  count = count + 1
                  toggleRowSelected(tableRow.id, e.currentTarget.checked)
                }
              }
            } else if (!e.currentTarget.checked) {
              toggleAllRowsSelected(e.currentTarget.checked)
            }
          }
          checkboxProps = {
            ...checkboxProps,
            onChange: overriddenOnChange,
            checked: overriddenChecked,
            indeterminate: overriddenIndeterminate
          }
        }
        return (
          <div className="flex-v-center">
            <IndeterminateCheckbox
              {...checkboxProps}
              totalCount={totalCount}
              selectedData={selectedData}
              rowData={{ isHeader: true }}
              maxSelect={maxSelect}
              uniqueKey={uniqueKey}
              rowsLength={rowsLength}
            />
          </div>
        )
      },
      Cell: ({ row }: any) => {
        if (rowSelectCheckboxProp) {
          if (row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
            if (rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value) {
              return (
                <div className="checkbox-wrapper">
                  <Checkbox disabled={true} />
                </div>
              )
            }
            return (
              <div className="checkbox-wrapper">
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  totalCount={totalCount}
                  rowData={row.original}
                  selectedData={selectedData}
                  maxSelect={maxSelect}
                  uniqueKey={uniqueKey}
                />
              </div>
            )
          }
          return <div className="no-checkbox-space"></div>
        } else {
          if (rowDisableProp) {
            if (row.original[rowDisableProp.key] === rowDisableProp.value) {
              return (
                <div className="checkbox-wrapper">
                  <Checkbox disabled={true} />
                </div>
              )
            }
          }
          return (
            <div className="checkbox-wrapper">
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                totalCount={totalCount}
                rowData={row.original}
                selectedData={selectedData}
                maxSelect={maxSelect}
                uniqueKey={uniqueKey}
              />
            </div>
          )
        }
      }
    },
    ...columns
  ])
}

export const RowSelectAction = ({ selectedData, name, onRowSelectProp }: any) => {
  const hanldeClick = (action) => {
    const type = typeof action.label === 'string' ? action.label.toLowerCase() : ''
    action.cb({ data: selectedData, type })
  }
  let showSelectCtaDom: any
  let hasShowSelectCta: boolean
  // eslint-disable-next-line react-hooks/exhaustive-deps
  onRowSelectProp.map((action: any, i: number) => {
    if (action.showSelected) {
      hasShowSelectCta = true
      showSelectCtaDom = (
        <ButtonGroup>
          <Button
            aria-label={action.ariaLabel}
            key={i}
            buttonType={action.type ? action.type : 'primary'}
            icon={action.icon ? action.icon : undefined}
            onClick={() => hanldeClick(action)}
            className={action.label === 'Unpublish' ? 'Button--unpublish' : ''}>
            {action.label}
          </Button>
        </ButtonGroup>
      )
    }
  })

  return (
    <div className="TableActionPanel flex-justify flex-v-center">
      <div className="selected-items">
        {hasShowSelectCta ? (
          showSelectCtaDom
        ) : (
          <span>
            {selectedData.length === 1
              ? `${selectedData.length} ${name.singular} selected `
              : `${selectedData.length} ${name.plural} selected `}
          </span>
        )}
      </div>
      <div>
        <ButtonGroup>
          {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            onRowSelectProp.map((action: any, i: number) => {
              if (!action.showSelected) {
                return (
                  <Button
                    aria-label={action.ariaLabel}
                    key={i}
                    buttonType={action.type ? action.type : 'primary'}
                    icon={action.icon ? action.icon : undefined}
                    onClick={() => hanldeClick(action)}
                    className={action.label === 'Unpublish' ? 'Button--unpublish' : ''}>
                    {action.label}
                  </Button>
                )
              }
            })
          }
        </ButtonGroup>
      </div>
    </div>
  )
}

const isColumnFreezed = (freezeColumnCount, index) => freezeColumnCount > 0 && index < freezeColumnCount

/**
 * It renders table header data
 * And handle sorting of column
 **/
export const TableHead = (props) => {
  let tableBody: HTMLElement = document.querySelector('.Table__body')
  let scrollBarWidth = tableBody != null ? tableBody.offsetWidth - tableBody.clientWidth : 0
  const { columnWidthsObjectState, columnWidthsObjectProp, equalWidthColumns, listData, isRowSelect } = props
  return (
    <div
      className={`Table__head ${props.isTableWidthGreater ? 'Table__head--scroll ' : ''} ${
        props.viewBy === 'Compact' ? 'Table__head--compact' : ''
      }`}
      ref={props.tableHeadRef}
      style={props.isResizable ? {} : { paddingRight: scrollBarWidth + 'px' }}>
      {props.headerGroups.map((headerGroup: any) => (
        <div
          role="row"
          {...headerGroup.getHeaderGroupProps()}
          className={`Table__head__row ${props.equalWidthColumns ? 'Table__head__row--equal-width-columns' : ''}`}>
          {headerGroup.headers.map((column: any, index) => {
            let columnHeaderProps = column.getHeaderProps()

            if (props.isResizable) {
              columnHeaderProps = column.getHeaderProps({
                style: getMinMaxStyle(props.tableWidth)
              })
            }

            let AllSortByHeaderProps = {
              ...column.getHeaderProps([
                column.getSortByToggleProps(),
                { style: { display: 'inline' }, role: 'presentation' }
              ])
            }
            let { title, key, ...sortByHeaderProps } = AllSortByHeaderProps
            const { style, ...headerPropsWithoutStyle }: any = sortByHeaderProps

            let columnHeaderStyle = getColumnStyle({
              column,
              cellProps: columnHeaderProps,
              columnWidthsObjectState,
              columnWidthsObjectProp,
              equalWidthColumns,
              isResizable: props.isResizable,
              totalCounts: props.totalCounts,
              singleColumnWidth: props.singleColumnWidth,
              tableRowAction: props.v2Features.tableRowAction
            })
            let freezeColumnCount = 0
            let isColumnFreezedResponse
            if (props.canFreezeColumn) {
              freezeColumnCount = getFreezeColumnCount(listData, isRowSelect)
              isColumnFreezedResponse = isColumnFreezed(freezeColumnCount, index)
              if (index > 0 && isColumnFreezedResponse) {
                columnHeaderStyle = {
                  ...columnHeaderStyle,
                  left: column.totalLeft
                }
              }
            }
            if (column.id === 'selection') {
              return (
                <div
                  {...column.getHeaderProps()}
                  className={'Table-select-head flex-v-center' + (!isColumnFreezedResponse ? '' : ' freezed')}>
                  <TableErrorBoundary>{column.render('Header')}</TableErrorBoundary>
                </div>
              )
            }
            return (
              <div
                {...columnHeaderProps}
                className={classnames(
                  'Table__head__column',
                  !!column.cssClass && column.cssClass,
                  column.isSorted && 'Table__head__cell--sorted',
                  ((props.isResizable && column.canResize) || !column.disableSortBy) && 'Table__head__column__hover',
                  isColumnFreezedResponse && 'freezed',
                  freezeColumnCount > 0 && freezeColumnCount === index + 1 && 'freezed_border',
                  column.id === 'tableActions' &&
                    props.v2Features.tableRowAction &&
                    'Table__head__cell--tableRowAction',
                  column.isResizing && 'isResizing'
                )}
                style={columnHeaderStyle}>
                <span className="Table__head__column-text" {...sortByHeaderProps}>
                  <TableErrorBoundary>{column.render('Header')}</TableErrorBoundary>
                </span>
                {!column.disableSortBy && (
                  <span className="sortingIcon-wrapper" {...headerPropsWithoutStyle}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon icon="SortingDesc" hover={false} />
                      ) : (
                        <Icon icon="SortingAsc" hover={false} />
                      )
                    ) : (
                      <Icon icon="SortingNew" hover={false} />
                    )}
                  </span>
                )}
                {column.filter && <column.filter />}
                {props.isResizable &&
                  index >= 0 &&
                  column.canResize &&
                  props.totalCounts > 0 &&
                  !props.loading &&
                  column.id !== 'tableActions' && (
                    <span
                      data-testid={`table_head_resizer_${index}`}
                      {...column.getResizerProps()}
                      className={`resizer `}></span>
                  )}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

const LoadingTableRow = (props) => {
  const {
    viewBy,
    equalWidthColumns,
    style,
    headerGroups,
    columnWidthsObjectProp,
    columnWidthsObjectState,
    isResizable,
    columnResizing,
    singleColumnWidth,
    tableRowAction
  } = props
  return (
    <div
      className={
        'Table__body__row ' +
        (viewBy === 'Compact' ? 'Table__body__row--compact ' : '') +
        (equalWidthColumns ? 'Table__body__row--equal-width-columns' : '' + 'Table__body__row-loading')
      }
      style={style}>
      {Array.isArray(headerGroups) &&
        headerGroups.length > 0 &&
        headerGroups[0].headers.map((column: any, loadingIndex) => {
          let cellProps = column.getHeaderProps()

          if (column.id === 'selection') {
            return (
              <div key={loadingIndex} className={'Table-select-body flex-center'}>
                <div className="skeleton-square"></div>
              </div>
            )
          }

          const { width } = getTableCellStyle({
            column: column,
            cellProps: cellProps,
            columnWidthsObjectState,
            columnWidthsObjectProp,
            equalWidthColumns,
            columnResizing,
            isResizable,
            singleColumnWidth,
            tableRowAction
          })
          return (
            <div
              key={loadingIndex}
              className={classnames(
                'Table__body__column flex-center',
                !!column.cssClass && column.cssClass,
                column.id === 'tableActions' && props.tableRowAction && 'Table__body__cell--tableRowAction'
              )}
              style={{ width }}>
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={10}
                tileWidth={80}
                tileBottomSpace={7}
                tileTopSpace={5}
                tileleftSpace={5}
              />
            </div>
          )
        })}
    </div>
  )
}

const RenderCell = (props) => {
  const {
    row,
    // cellRef,
    viewBy,
    isResizable,
    equalWidthColumns,
    columnWidthsObjectProp,
    columnWidthsObjectState,
    columnResizing,
    isRowSelect,
    listData
  } = props
  return row.cells.map((cell: any, i) => {
    let cellProps = cell.getCellProps()
    if (isResizable) {
      cellProps = cell.getCellProps({
        style: getMinMaxStyle(props.tableWidth)
      })
    }

    let cellStyle = getTableCellStyle({
      column: cell.column,
      cellProps,
      columnWidthsObjectState,
      columnWidthsObjectProp,
      equalWidthColumns,
      columnResizing,
      isResizable,
      singleColumnWidth: props.singleColumnWidth,
      tableRowAction: props.v2Features.tableRowAction
    })

    let freezeColumnCount = 0
    if (isResizable) {
      //todo remove this is not being used test in master branch first
      cellStyle = { ...cellStyle, position: 'relative' }
    }
    if (props.canFreezeColumn) {
      freezeColumnCount = getFreezeColumnCount(listData, isRowSelect)
      if (i > 0 && isColumnFreezed(freezeColumnCount, i)) {
        cellStyle = { ...cellStyle, left: cell.column.totalLeft }
      }
    }
    if (cell.column.id === 'selection') {
      return (
        <div
          {...cell.getCellProps()}
          className={'Table-select-body flex-v-center' + (isColumnFreezed(freezeColumnCount, i) ? ' freezed ' : ' ')}>
          <TableErrorBoundary>{cell.render('Cell')}</TableErrorBoundary>
        </div>
      )
    }

    return (
      <div
        role="cell"
        className={classnames(
          'Table__body__column',
          isColumnFreezed(freezeColumnCount, i) && 'freezed',
          !!cell.column.cssClass && cell.column.cssClass,
          cell.column.isSorted && 'Table__body__column--sorted',
          viewBy === 'Compact' && 'Table__body__column--compact',
          isResizable && 'Table__body__column__resize',
          freezeColumnCount > 0 && freezeColumnCount === i + 1 && 'freezed_border',
          cell.column.id === 'tableActions' && props.v2Features.tableRowAction && 'Table__body__cell--tableRowAction',
          row.index + 1 === props.totalCounts &&
            cell.column.id === 'tableActions' &&
            'Table__body__cell--lastTableRowAction',
          row.index === 0 &&
            cell.column.id === 'tableActions' &&
            props.totalCounts > 1 &&
            !props.customRowAdd &&
            'Table__body__cell--firstTableRowAction'
        )}
        style={cellStyle}
        key={cell.column.id + i}>
        <TableErrorBoundary>{cell.render('Cell')}</TableErrorBoundary>
        {isResizable && i >= 0 ? <span className={`resizer `}></span> : ''}
      </div>
    )
  })
}

const TableRow = (props) => {
  const {
    row,
    isRowDisabled,
    rowSelectCheckboxProp,
    viewBy,
    isSingleRowSelected,
    equalWidthColumns,
    rowDisableProp,
    handleRowClick,
    style,
    isResizable,
    rowActionData,
    updateRowActionData,
    tableRowActionList = [],
    singleColumnWidth,
    v2Features
  } = props

  const TableRowActionComponent = useCallback(() => {
    return (
      rowActionData.rowIndex === row.index &&
      tableRowActionList.length > 0 && (
        <TableRowActionWrapper
          list={tableRowActionList}
          data={row.original}
          rowActionData={rowActionData}
          viewBy={viewBy}
          closeDropdown={() => {
            updateRowActionData({ rowIndex: -1 })
          }}
        />
      )
    )
  }, [rowActionData])

  return (
    <div
      {...getTableRowProps(
        row.getRowProps(),
        row.cells,
        style,
        isResizable,
        singleColumnWidth,
        v2Features.tableRowAction
      )}
      className={`${getTableRowClassString(
        row,
        isRowDisabled,
        rowSelectCheckboxProp,
        viewBy,
        isSingleRowSelected,
        equalWidthColumns,
        rowDisableProp
      )} ${rowActionData.rowIndex === row.index ? 'Table__row_action-selected' : ''}`}
      onClick={(e) => handleRowClick(e, isRowDisabled, row)}
      key={row.id}>
      {props.children}
      {TableRowActionComponent()}
    </div>
  )
}

const ComposeTableRow = (props) => {
  //v2 features
  if (props.v2Features.tableRowAction && props.tableRowActionList) {
    return (
      <TableRow {...props}>
        <RenderCell {...props} />
      </TableRow>
    )
  }

  if (props.tableRowActionList) {
    return (
      <TableRow {...props}>
        <ActionTooltip list={props.tableRowActionList} data={props.row.original} right={props.toolTipPosFromRight}>
          <RenderCell {...props} />
        </ActionTooltip>
      </TableRow>
    )
  }

  if (props.isSingleRowSelected) {
    return (
      <TableRow {...props}>
        <SingleRowSelectedUi>
          <RenderCell {...props} />
        </SingleRowSelectedUi>
      </TableRow>
    )
  }

  if (props.disabledMessage && props.isRowDisabled) {
    return (
      <TableRow {...props}>
        <Tooltip content={props.disabledMessage} position="top" showArrow={false}>
          <RenderCell {...props} />
        </Tooltip>
      </TableRow>
    )
  }

  return (
    <TableRow {...props}>
      <RenderCell {...props} />
    </TableRow>
  )
}

/**
 * It renders each row data
 * If data is avaibale and not loading
 * And on loading it shows skeleton loader for that row
 **/
export const RenderRow = (props) => {
  const row = props.rows[props.index]

  if (props.index >= props.totalCounts) {
    return null
  }

  if (props.itemStatusMap[props.index] === 'loaded' && row) {
    props.prepareRow(row)
    const isSingleRowSelected = !props.isRowSelect && row.id === props.singleSelectedRowId
    const onHoverListCondition = props.onHoverListCondition

    let isRowDisabled = false
    let disabledMessage
    if (props.conditionalSingleSelect) {
      isRowDisabled = row.original[props.conditionalSingleSelect.key] === props.conditionalSingleSelect.value
      disabledMessage = props.conditionalSingleSelect.message
    } else if (props.rowDisableProp) {
      isRowDisabled = row.original[props.rowDisableProp.key] === props.rowDisableProp.value
    }

    const tableRowActionList = onHoverListCondition
      ? row.original[onHoverListCondition.key]
        ? onHoverListCondition.truthyList
        : onHoverListCondition.falsyList
      : props.tableRowActionList

    const handleTableLinkRowClick = (e: any) => {
      if (e.target.id === 'rowSelect') {
        e.stopPropagation()
        e.preventDefault()
      }
    }

    const linkRowCheck = !(props.fullRowSelect || typeof props.singleSelectedRowId === 'string') && props.linkToRow
    const { LinkComponent, linkToRow } = props
    let hoverText = ''

    if (props.onRowHoverText) {
      hoverText = props.onRowHoverText(row.original)
    }

    //rendering head within body , so need padding to shift rest element down
    let top = props.style.top
    if (props.canFreezeColumn || props.v2Features.tableRowAction) {
      top = top + defaultValue.TABLE_HEAD_HEIGHT + (props.customRowAdd ? 60 : 0)
    }
    const styleFromFixedList = { ...props.style, top }

    const TableRowNodes = (
      <ComposeTableRow
        row={row}
        isRowDisabled={isRowDisabled}
        rowSelectCheckboxProp={props.rowSelectCheckboxProp}
        viewBy={props.viewBy}
        isSingleRowSelected={isSingleRowSelected}
        equalWidthColumns={props.equalWidthColumns}
        rowDisableProp={props.rowDisableProp}
        handleRowClick={props.handleRowClick}
        style={styleFromFixedList}
        tableRowActionList={tableRowActionList}
        toolTipPosFromRight={props.toolTipPosFromRight}
        disabledMessage={disabledMessage}
        listData={props.listData}
        // cellRef={props.cellRef}
        isResizable={props.isResizable}
        columnWidthsObjectProp={props.columnWidthsObjectProp}
        columnWidthsObjectState={props.columnWidthsObjectState}
        columnResizing={props.columnResizing}
        isRowSelect={props.isRowSelect}
        canFreezeColumn={props.canFreezeColumn}
        v2Features={props.v2Features}
        tableWidth={props.tableWidth}
        rowActionData={props.rowActionData}
        updateRowActionData={props.updateRowActionData}
        totalCounts={props.totalCounts}
        singleColumnWidth={props.singleColumnWidth}
        customRowAdd={props.customRowAdd}
      />
    )

    if (LinkComponent) {
      return (
        <div title={hoverText}>
          <LinkComponent
            onClick={handleTableLinkRowClick}
            tabIndex={-1}
            to={typeof props.linkToRow === 'function' ? linkToRow(row.original) : linkToRow}
            title={hoverText}>
            {TableRowNodes}
          </LinkComponent>
        </div>
      )
    }

    if (linkRowCheck) {
      return (
        <div title={hoverText}>
          <a
            onClick={handleTableLinkRowClick}
            tabIndex={-1}
            href={typeof linkToRow === 'function' ? linkToRow(row.original) : linkToRow}>
            {TableRowNodes}
          </a>
        </div>
      )
    }

    return <div title={hoverText}>{TableRowNodes}</div>
  }

  if (props.itemStatusMap[props.index] === 'loading') {
    return (
      <LoadingTableRow
        viewBy={props.viewBy}
        equalWidthColumns={props.equalWidthColumns}
        style={props.style}
        headerGroups={props.headerGroups}
        columnWidthsObjectProp={props.columnWidthsObjectProp}
        columnWidthsObjectState={props.columnWidthsObjectState}
        isResizable={props.isResizable}
        singleColumnWidth={props.singleColumnWidth}
        tableRowAction={props.v2Features.tableRowAction}
      />
    )
  }

  return null
}

export class TableErrorBoundary extends React.Component {
  state = {
    error: false
  }

  static getDerivedStateFromError(error) {
    return { error: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('TableErrorBoundary', error, errorInfo)
    //this.props.handleError();
  }

  render() {
    if (this.state.error) {
      return null
    }
    return this.props.children
  }
}
