import React from 'react'
import { Registry } from '@react-registry'
// import { Editor, Node, Element, Text, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import { TextClearFormatting } from '../../../../../JsonRTE/SuperChargedRte/elements/utils/Icon'
// import { isSelectionCollapsed } from '../../../utils/queries'
import { leafCategories } from '../leafCategories'
import { removeFormatting } from './utils'

export function RemoveFormattingButton(props) {
  const editor = useSlateStatic()

  function handleMouseDown(event) {
    removeFormatting(event, editor)
  }

  return <React.Fragment>{props.children({ handleMouseDown })}</React.Fragment>
}

export function shortcutRemoveFormatting(event, editor, format, registryObj) {
  removeFormatting(event, editor)
}

export function RemoveFormattingElement(props) {
  return <span>{props.children}</span>
}

Registry.register(
  {
    Component: RemoveFormattingElement,
    IngressComponent: RemoveFormattingButton,
    title: 'Remove Formatting',
    shortcut: { key: 'mod+\\', callback: shortcutRemoveFormatting },
    iconName: <TextClearFormatting />,
    category: leafCategories.TEXT_STYLE_SEE_MORE,
    isContentStackElement: true,
    toolbar: {
      inHoveringToolbar: false,
    }
  },
  { id: 'removeFormatting', registry: 'leaf' }
)
