import { Registry } from '@react-registry'

export const code = (el) => {
    let language = el.getElementsByTagName('code')[0].getAttribute('class').split('-')[1]
    return {
        type: 'code',
        attrs: {
            language: language
        }
    }
}

Registry.register(code, { id: 'PRE', registry: 'v2.deserializer' })