//@ts-nocheck
import React, { useState } from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../elementCategories'
import styles from './style.module.css'
import { cx } from '@emotion/css'
import Tippy from '@tippyjs/react'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import { handleDelete } from '../../utils/actions'
import Dropdown from '../../../../../DropDown/Dropdown'
import { list } from './languageList'
import { CodeIcon } from '../../utils/Icon'
import { CopyIcon, DeleteIcon } from './Icons'
import { customCodeBlockKeydown } from './withCustomKeydown';
import './prism.css';

const Code = (props) => {
  let editor = useSlate()
  const [currentLanguage, setCurrentLanguage] = useState('html')
  const [copy, setCopy] = useState(false)
  const [modifiedList, setModifiedList] = useState(list)

  const handleCopy = () => {
    if (editor.requestProps?.userAnalytics) {
      const userAnalytics = editor.requestProps.userAnalytics;
      const metadata = {
        content_type_uid: editor.requestProps['content_type_uid'],
        stack_api_key: editor.requestProps['apiKey'],
        json_rte_field_uid: editor.requestProps['field_uid'],
        json_rte_field_type: editor.requestProps['field_type'],
        code_block_language: currentLanguage,
        mixpanel: true
      };
      userAnalytics.trackEvent("Copy a code block from JSON RTE", metadata);
    }
    if (copy === false) {
      setCopy(true)
      navigator?.clipboard?.writeText(props.element.children[0].text);
    }
    else {
      setCopy(false);
    }
  }

  const handleClick = (lang) => {
    if (editor.requestProps?.userAnalytics) {
      const userAnalytics = editor.requestProps.userAnalytics;
      const metadata = {
        content_type_uid: editor.requestProps['content_type_uid'],
        stack_api_key: editor.requestProps['apiKey'],
        json_rte_field_uid: editor.requestProps['field_uid'],
        json_rte_field_type: editor.requestProps['field_type'],
        code_block_language: lang,
        mixpanel: true
      };
      userAnalytics.trackEvent("Apply language format to code block within JSON RTE", metadata);
    }
    let attrs;
    if (editor.selection || editor.savedSelection) {
      Transforms.select(editor, editor.selection || editor.savedSelection)
      Transforms.setNodes(editor, {
        type: 'code',
        attrs: {
          ...attrs,
          language: lang
        }
      })
    }
    else {
      Transforms.setNodes(editor, {
        type: 'code',
        attrs: {
          ...attrs,
          language: lang
        }
      }, {
        at: props.slatePath
      })
    }
    setCurrentLanguage(lang)
  }

  const changeList = (lang) => {
    let listCopy = [...modifiedList]
    if (lang) {
      listCopy.forEach((element, index) => {
        if (element.hasOwnProperty('default')) {
          delete element["default"];
        }
        if (element.value === lang) {
          listCopy[index] = {
            ...listCopy[index],
            default: true
          }
          setCurrentLanguage(element.value)
        }
      })
      setModifiedList(listCopy)
    }
  }

  return (
    <div
      style={{ userSelect: "none" }}
    >
      <Tippy
        content={
          <div data-test-id="code-toolbar" className={styles['container']}>
            <div className="Dropdown-wrapper" test-data-id="dropdown">
              <Dropdown
                closeAfterSelect={true}
                list={modifiedList}
                type="select"
                dropDownType="secondary"
                withSearch={true}
                dropDownPosition={'bottom'}
                onChange={(val) => {
                  handleClick(val.value)
                }}
                highlightActive={true}
                searchPlaceholder="Search languages"
              />
            </div>
            <div test-data-id="seperator" className={styles['seperator']} contentEditable={false} style={{ userSelect: 'none' }}></div>
            <Tippy
              content={copy ? 'Copied' : 'Copy'}
              className={'tippy-box-dark'}
              interactive={true}
              placement={'bottom'}
              hideOnClick={false}
              onTrigger={() => setCopy(false)}>
              <div data-test-id="copy-btn" contentEditable={false} className={styles['copy']}>
                <CopyIcon handleCopy={handleCopy} />
              </div>
            </Tippy>
            <Tippy content={'Delete'} className={'tippy-box-dark'} placement={'bottom'} interactive={true}>
              <div contentEditable={false} data-test-id="delete-btn" className={styles['delete']}>
                <DeleteIcon handleDelete={handleDelete} editor={editor} element={props.element} />
              </div>
            </Tippy>
          </div>
        }
        trigger="click"
        animation="fade"
        arrow={true}
        interactive={true}
        placement="bottom-end"
        onTrigger={() => changeList(props.element.attrs.language)}
        onClickOutside={() => setCopy(false)}
        onUntrigger={() => setCopy(false)}
      >
        <div {...props.attributes} {...props.attrs} className={cx(styles['code'], 'scrte-code', props.className)}><pre ><code className={props?.element?.attrs?.language ? `language-${props.element.attrs.language}` : ""}>{props.children}</code></pre></div>
      </Tippy>
    </div>
  )
}

export default Code
export * from './deserializer'
export * from './codePreInsert'
export * from './withCustomKeydown'

Registry.register(
  {
    Component: Code,
    title: (
      <span className={styles['code']} style={{ width: '100' }}>
        <pre>
          <code style={{ color: '#222' }}>Code Snippet</code>
        </pre>
      </span>
    ),
    slashTitle: 'Code',
    subtitle: 'Write some Code',
    // iconName: <CodeIcon />,
    slashIconName: <CodeIcon />,
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+8' },
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['code'],
    customKeydown: [{ key: 'enter', event: customCodeBlockKeydown, defaultKeydown: false }]
  },
  { id: 'code', registry: 'v2.element' }
)
