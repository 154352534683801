import React from 'react'
//@ts-ignore
import Tippy from '@tippyjs/react';
import { ReferenceComponent } from '../inlineReference';
import { handleDelete } from '../../../../utils/actions';


export const BlockReference = function ({ entry, editor, element, ...props }) {
  const { history, apiKey } = editor?.requestProps || {};
  const contentTypeUid = element?.attrs?.['content-type-uid'],
    entryUid = element?.attrs?.['entry-uid'],
    locale = element?.attrs?.['locale'];

  const handleEdit = () => {
    history.push(`/stack/${apiKey}/content-type/${contentTypeUid}/${locale}/entry/${entryUid}/edit`)
  }
  const contentTypeNameMap = editor.contentTypeNameMap || {}
  const contentTypeName = contentTypeNameMap[contentTypeUid] || contentTypeUid
  return (
    <Tippy placement='bottom' content={entry['error'] && <span className="tippy-box-dark tippy-box"
      style={{ padding: '.25rem .3rem', fontFamily: 'Inter' }} data-testid={'block-reference-error'}>{entry['error']}</span>}
    >
      <ReferenceComponent editor={editor} type='block' handleEdit={handleEdit} handleDelete={(e) => handleDelete(editor, element)} entry={entry} content_type_uid={contentTypeName} attributes={{ ...props.attributes, ...props.attrs, element }} children={props.children} />
    </Tippy>
  )
}

export * from './deserializer';