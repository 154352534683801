import React, { Component } from 'react'

class MultiValueLabels extends Component<any, any> {
  render() {
    const { children, getValue, selectProps } = this.props
    const newChildren = `${getValue && getValue().length} ${selectProps?.selectedLabel || 'items selected'}`

    if (getValue && getValue().length < 2) {
      const labelTitle = children?.toString()
      return (
        <div className="multi-value-wrapper" title={labelTitle}>
          {children}
        </div>
      )
    }
    return (
      <div className="multi-value-wrapper" data-test-id="multi-value-wrapper">
        {
          <>
            <span className="multi-value-count">{newChildren}</span>
            <span className="multi-value-list">{children}</span>
          </>
        }
      </div>
    )
  }
}

export default MultiValueLabels
