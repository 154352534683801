import React from 'react'
import { PillIconPosition, PillVariant } from './type'
import cn from 'classnames'

interface PillIconProps {
  icon: React.ReactNode
  variant: PillVariant
  pillIconPosition: PillIconPosition
  testId?: string
  className?: string
  tabIndex?: number
}

const PillIcon: React.FC<PillIconProps> = ({ icon, variant, testId, pillIconPosition, className, ...rest }) => {
  const iconWrapClassName = cn(`PillIcon PillIcon__${variant}--${pillIconPosition}`, `flex-center`, {
    [className]: !!className
  })

  return (
    <div {...rest} className={iconWrapClassName} data-test-id={testId}>
      {icon}
    </div>
  )
}

PillIcon.defaultProps = {
  icon: <></>,
  variant: 'label',
  pillIconPosition: 'start',
  testId: 'pillIcon'
} as Partial<PillIconProps>

export default PillIcon
