import { Editor, Path, Range, Transforms } from 'slate'
import { useSelected, useSlate } from 'slate-react'
import { isPathInRectangle } from '../utils'

function useIsCellSelected(path: Path, table) {
  const editor = useSlate()
  const selected = useSelected()
  const { selection } = editor
  if (!selection || !Path.isPath(path) || !table) return false

  if (Range.isCollapsed(selection)) return selected



  if (!Range.includes(selection, table[1])) return false

  const isInRectangle = isPathInRectangle(editor, path, table[1])
  return isInRectangle
}

export default useIsCellSelected
