import React from 'react'
import { Registry } from '@react-registry'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from '../style.module.css'
import { elementCategories } from '../../elementCategories'
import { H1Icon } from '../../../utils/Icon'
import { HeadingIcon } from '../../../utils/Icon'
export * from './deserializer'
export const H1 = (props) => {
  const { className } = props.attrs || {}

  return (
    <div {...props.attrs} className={styles['heading']} >
      <h1 {...props.attributes} className={cx(
        className,
        css`padding-top: var(--scrte-padding-top, 0);`
      )}>
        {props.children}
      </h1>
    </div>
  )
}

Registry.register(
  {
    Component: H1,
    slashIconName: <H1Icon />,
    slashTitle: 'Heading 1',
    title: <h1 className={styles['h1']}>Heading 1</h1>,
    subtitle: 'Big text for Title',
    shortcut: { key: 'mod+alt+1' },
    category: elementCategories.TEXT_FORMAT,
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['heading 1', 'heading one', 'h1']
  },
  { id: 'h1', registry: 'v2.element' }
)
