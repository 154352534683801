import { Registry } from '@react-registry'


export const strikeThroughDeserializer = () => {
    return {
        strikethrough: true
    }
}

Registry.register(strikeThroughDeserializer, { id: 'STRIKE', registry: 'v2.inline-deserializer' })

