import React, { useState, useRef, useEffect } from 'react'
import './FieldProperties.css'

import Icon from '../Icon2'
import { iconName } from '../Icon2/constants'
import Tabs from '../Tabs/Tabs'

import Form from '../Form/Form'
import Field from '../Field/Field'
import TextInput from '../TextInput/TextInput'
import FieldLabel from '../FieldLabel/FieldLabel'


const BasicForm = (
  <Form>
    <div className="FormFields" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="FormFields__column">
        <Field>
          <FieldLabel required htmlFor="name">
            Display Name
          </FieldLabel>
          <TextInput required name="name" id="name" placeholder="Enter value"></TextInput>
        </Field>
        <Field>
          <FieldLabel htmlFor="placeholder">Placeholder Value</FieldLabel>
          <TextInput
            name="placeholder"
            id="placeholder"
            placeholder="Enter value"
          ></TextInput>
        </Field>
        <Field>
          <FieldLabel htmlFor="help">Help Text</FieldLabel>
          <TextInput name="help" id="help" placeholder="Enter value"></TextInput>
        </Field>
      </div>
      <div className="FormFields__column">
        <Field>
          <FieldLabel required htmlFor="id">
            Unique ID
          </FieldLabel>
          <TextInput required name="id" id="id" placeholder="Enter value"></TextInput>
        </Field>
        <Field>
          <FieldLabel htmlFor="name">Instruction Value</FieldLabel>
          <TextInput
            name="instruction"
            id="instruction"
            placeholder="Enter value"
          ></TextInput>
        </Field>
      </div>
    </div>
  </Form>
)

const AdvancedForm = (
  <Form>
    <div className="FormFields" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="FormFields__column">
        <Field>
          <FieldLabel required htmlFor="name">
            Display Name
          </FieldLabel>
          <TextInput required name="name" id="name" placeholder="Enter value"></TextInput>
        </Field>
        <Field>
          <FieldLabel htmlFor="placeholder">Placeholder Value</FieldLabel>
          <TextInput
            name="placeholder"
            id="placeholder"
            placeholder="Enter value"
          ></TextInput>
        </Field>
      </div>
      <div className="FormFields__column">
        <Field>
          <FieldLabel required htmlFor="id">
            Unique ID
          </FieldLabel>
          <TextInput required name="id" id="id" placeholder="Enter value"></TextInput>
        </Field>
        <Field>
          <FieldLabel htmlFor="name">Instruction Value</FieldLabel>
          <TextInput
            name="instruction"
            id="instruction"
            placeholder="Enter value"
          ></TextInput>
        </Field>
      </div>
    </div>
  </Form>
)

const FieldProperties = (props: any) => {
  const { basicForm, advancedForm, title = '', icon = '', isSingle, isUrl, isCTBuilder } = props

  useEffect(() => {
    console.log('FieldProperties changed', props);
  }, [props.field])

  const TabInfo = [
    {
      id: 'basic',
      title: 'Basic',
      componentData: basicForm || BasicForm,
      disabled: isCTBuilder && isUrl && isSingle ? true : false
    },
    {
      id: 'advanced',
      title: 'Advanced',
      componentData: advancedForm || AdvancedForm,
    },
  ]
  return (
    <div className="FieldProperties">
      <div className="FieldProperties__header flex-v-center">
        <h4 className="FieldProperties__heading flex-v-center">
          <Icon icon={icon + 'Small'} className="FieldProperties__heading-icon"></Icon>
          {title} - Properties
        </h4>
      </div>
      <div className="FieldProperties__content">
        <Tabs tabInfo={TabInfo} activeTab={isCTBuilder && isUrl && isSingle ? 'advanced' : undefined} />
      </div>
    </div>
  )
}

export default FieldProperties
