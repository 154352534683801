import React from 'react'

export const DeleteHeaderIcon = (props) => {
    return (
        <svg
            width={18}
            height={18}
            viewBox="3 3 1700 1500"
            preserveAspectRatio="none"
            fill='currentColor'
            {...props}
        >
            <path
                d="M1670.55 812.881H496.65c-25.284 0-45.15 19.87-45.15 45.16 0 25.289 19.866 45.16 45.15 45.16h1173.9c25.28 0 45.15-19.871 45.15-45.16 0-25.29-19.87-45.16-45.15-45.16z"
            />
            <path
                d="M542 1422.43V835.571c0-12.64-19.8-22.571-45-22.571s-45 9.931-45 22.571v586.859c0 12.64 19.8 22.57 45 22.57s45-9.93 45-22.57zM1716 1401.21V834.786c0-12.2-19.8-21.786-45-21.786s-45 9.586-45 21.786v566.424c0 12.2 19.8 21.79 45 21.79s45-9.59 45-21.79zM1129 1401.21V834.786c0-12.2-19.8-21.786-45-21.786s-45 9.586-45 21.786v566.424c0 12.2 19.8 21.79 45 21.79s45-9.59 45-21.79z"
            />
            <path
                d="M504.667 1174H1663.33c29.5 0 52.67-19.8 52.67-45s-23.17-45-52.67-45H504.667c-29.494 0-52.667 19.8-52.667 45s23.173 45 52.667 45zM1670.55 1354.8H496.65c-25.284 0-45.15 19.87-45.15 45.16 0 25.29 19.866 45.16 45.15 45.16h1173.9c25.28 0 45.15-19.87 45.15-45.16 0-25.29-19.87-45.16-45.15-45.16zM1670.55 180.641H496.65c-25.284 0-45.15 19.87-45.15 45.16v361.28c0 25.289 19.866 45.16 45.15 45.16h1173.9c25.28 0 45.15-19.871 45.15-45.16v-361.28c0-25.29-19.87-45.16-45.15-45.16zm-45.15 361.28H541.8v-270.96h1083.6v270.96zM352.371 270.408c-5.776-5.771-13.863-8.08-20.795-8.08-6.931 0-15.018 2.309-20.794 8.08l-115.526 115.42c-11.553 11.542-11.553 30.01 0 40.398l115.526 115.42c11.552 11.542 30.036 11.542 40.434 0 11.552-11.542 11.552-30.009 0-40.397l-94.732-94.645 94.732-94.645c11.552-11.542 11.552-30.009 1.155-41.551z"
            />
            <path
                d="M352.371 270.408c-5.776-5.771-13.863-8.08-20.795-8.08-6.931 0-15.018 2.309-20.794 8.08l-115.526 115.42c-11.553 11.542-11.553 30.01 0 40.398l115.526 115.42c11.552 11.542 30.036 11.542 40.434 0 11.552-11.542 11.552-30.009 0-40.397l-94.732-94.645 94.732-94.645c11.552-11.542 11.552-30.009 1.155-41.551z"
            />
            <path
                d="M86.26 542.223c5.776 5.771 13.862 8.08 20.794 8.08s15.018-2.309 20.795-8.08l115.526-115.42c11.552-11.542 11.552-30.01 0-40.398l-115.526-115.42c-11.553-11.542-30.037-11.542-40.434 0-11.553 11.542-11.553 30.009 0 40.397l94.731 94.645-94.731 94.645c-11.553 11.542-11.553 30.009-1.156 41.551z"
            />
        </svg>
    )
}