import { Transforms, Node, Editor } from 'slate'
import { ElementWithType } from '../../../../../../../utils/types'

import { normalTextElement } from '../../paragraph/utils'
import { LIST_TYPES } from '../utils'

export const outdentListItem = (editor, li) => {
  const liPath = li[1]
  const liPathLastIndex = liPath.length - 1
  const liIndex = liPath[liPathLastIndex]

  const parentPath = liPath.slice(0, liPathLastIndex)
  const parent: any = Node.get(editor, parentPath)

  const prevSiblings = parent.children.slice(0, liIndex)
  const nextSiblings = parent.children.slice(liIndex + 1, parent.children.length)

  Transforms.removeNodes(editor, { at: parentPath })

  if (prevSiblings.length && nextSiblings.length) {
    const prevNode = {
      type: parent.type,
      attrs: {},
      children: prevSiblings,
    }

    const nextNode = {
      type: parent.type,
      attrs: {},
      children: nextSiblings,
    }

    Transforms.insertNodes(editor, nextNode, { at: parentPath })
    Transforms.insertNodes(editor, li[0], { at: parentPath })
    Transforms.insertNodes(editor, prevNode, { at: parentPath })

    Transforms.select(editor, [...parentPath.slice(0, parentPath.length - 1), parentPath[parentPath.length - 1] + 1])
    Transforms.collapse(editor, { edge: 'start' })
  } else if (prevSiblings.length) {
    const prevNode = {
      type: parent.type,
      attrs: {},
      children: prevSiblings,
    }

    Transforms.insertNodes(editor, li[0], { at: parentPath })
    Transforms.insertNodes(editor, prevNode, { at: parentPath })

    Transforms.select(editor, [...parentPath.slice(0, parentPath.length - 1), parentPath[parentPath.length - 1] + 1])
    Transforms.collapse(editor, { edge: 'start' })
  } else if (nextSiblings.length) {
    const nextNode = {
      type: parent.type,
      attrs: {},
      children: nextSiblings,
    }

    Transforms.insertNodes(editor, nextNode, { at: parentPath })
    Transforms.insertNodes(editor, li[0], { at: parentPath })

    Transforms.select(editor, parentPath)
    Transforms.collapse(editor, { edge: 'start' })
  } else {
    Transforms.insertNodes(editor, li[0], { at: parentPath })
    Transforms.select(editor, parentPath)
    Transforms.collapse(editor, { edge: 'start' })
  }
}

export const indentListItem = (editor, li) => {
  const liItem = li[0]
  const liPath = li[1]
  const liPathLastIndex = liPath.length - 1
  const parent = Node.get(editor, liPath.slice(0, liPathLastIndex)) as any;

  // return null if user try to create new inner list on first li child
  if (liPath[liPathLastIndex] == 0) {
    return null
  }

  const prevSiblingData = (liPath, liPathLastIndex) => {
    const prevSiblingPath = [...liPath.slice(0, liPathLastIndex), liPath[liPathLastIndex] - 1]
    const prevSibling = Node.get(editor, prevSiblingPath) as any;

    if (prevSibling.type === 'ol' || prevSibling.type === 'ul') {
      return { childen: prevSibling.children, path: prevSiblingPath }
    }
    return null
  }

  const nextSiblingData = (liPath, liPathLastIndex, parent) => {
    const doesNextSiblingExist = parent.children.length - 1 > liPath[liPathLastIndex]

    if (!doesNextSiblingExist) {
      return null
    }

    const nextSiblingPath = [...liPath.slice(0, liPathLastIndex), liPath[liPathLastIndex] + 1]

    const nextSibling = Node.get(editor, nextSiblingPath) as any;

    if (nextSibling.type === 'ol' || nextSibling.type === 'ul') {
      return { childen: nextSibling.children, path: nextSiblingPath }
    }
    return null
  }

  const prevSibling: any = prevSiblingData(liPath, liPathLastIndex)
  const nextSibling: any = nextSiblingData(liPath, liPathLastIndex, parent)

  if (prevSibling && nextSibling) {
    const children = [...prevSibling.childen, liItem, ...nextSibling.childen]
    const newNode = { type: parent.type, attrs: {}, children }

    Transforms.removeNodes(editor, { at: nextSibling.path })
    Transforms.removeNodes(editor, { at: liPath })
    Transforms.removeNodes(editor, { at: prevSibling.path })
    Transforms.insertNodes(editor, newNode, { at: prevSibling.path })

    Transforms.select(editor, [...prevSibling.path, prevSibling.childen.length])
    Transforms.collapse(editor, { edge: 'start' })
  } else if (prevSibling) {
    const liNewPath = [...prevSibling.path, prevSibling.childen.length]
    Transforms.removeNodes(editor, { at: liPath })
    Transforms.insertNodes(editor, liItem, { at: liNewPath })

    Transforms.select(editor, liNewPath)
    Transforms.collapse(editor, { edge: 'start' })
  } else if (nextSibling) {
    const liNewPath = [...nextSibling.path, 0]

    Transforms.insertNodes(editor, liItem, { at: liNewPath })
    Transforms.removeNodes(editor, { at: liPath })

    Transforms.select(editor, [
      ...nextSibling.path.slice(0, nextSibling.path.length - 1),
      nextSibling.path[nextSibling.path.length - 1] - 1,
      0,
    ])
    Transforms.collapse(editor, { edge: 'start' })
  } else {
    const newNode = { type: parent.type, attrs: {}, children: [liItem] }
    Transforms.removeNodes(editor, { at: liPath })
    Transforms.insertNodes(editor, newNode, { at: liPath })

    Transforms.select(editor, [...liPath, 0])
    Transforms.collapse(editor, { edge: 'start' })
  }
}

export const exitList = (editor) => {
  if (editor.selection) {
    const [listItemObjPath] = Editor.nodes(editor, {
      match: (n: ElementWithType) => {
        return n.type === 'li'
      },
    })

    if (listItemObjPath) {
      const [listItem, listItemPath] = listItemObjPath

      // check if text exist
      if (Node.string(listItem) === '') {
        const [listParentObjPath] = Editor.nodes(editor, {
          match: (n: any) => {
            return LIST_TYPES.includes(n.type)
          },
        })

        if (listParentObjPath) {
          const listParentPath = listParentObjPath[1]

          const nextPath = listParentPath.slice()
          nextPath[nextPath.length - 1] += 1

          Transforms.insertNodes(editor, normalTextElement, { at: nextPath })
          Transforms.removeNodes(editor, { at: listItemPath })

          try {
            Transforms.select(editor, nextPath)
          } catch (e) {
            Transforms.select(editor, listParentPath)
          }
          return true
        }
      }
    }
  }
}
