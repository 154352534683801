import React from 'react'
import { Registry } from '@react-registry'
import { useSlate } from 'slate-react'

import { Icon } from '../../../../../../../index';
import { isActionDisable, performHistoryAction } from '../utils'

export const UndoButton = ({ format = 'undo', children }) => {
  const editor = useSlate()
  const handleMouseDown = (event) => {
    event.preventDefault()
    performHistoryAction(editor, format)
  }
  return (
    <React.Fragment>
      {children({ handleMouseDown, isDisable: { func: isActionDisable, params: { editor, format } } })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <Icon style={{ width: '14px', height: '14px' }} icon='Undo' />,
    title: 'Undo',
    IngressComponent: UndoButton,
    isContentStackElement: true,
    inBasicToolbar: true,
    shortcut: {
      key: 'mod+z', callback: () => {
        // this is intentional
      }
    },
    toolbar: {
      inHoveringToolbar: false
    }
  },
  { id: 'undo', registry: 'editorButtons' }
)
