import axios from "axios";
import Q from '../../../../../../lib/requestModule'

export const fetchEnvironment = async (props) => {
  const { assetUrl, authToken, apiKey, branch, isPlugin } = props;
  const url = `${assetUrl}/environments?include_color=true`;
  try {
    const headers = {
      api_key: apiKey,
      'Content-Type': 'application/json'
    }
    authToken && (headers['authToken'] = authToken);
    branch && (headers['branch'] = branch);
    let res = {
      data: []
    };

    if (isPlugin) {
      res = await Q({
        action: 'getEnvironments',
        params: {
          include_color: true
        }
      })
    } else {
      res = await axios({
        method: 'GET',
        url,
        headers
      });
    }
    const json = await res.data;
    const environments = {};
    json['environments'].forEach(env => environments[env['uid']] = env);
    return environments;
  } catch (err) {
    // console.error(err);
    return {};
  }
}
