import { Registry } from '@react-registry'

import { elementCategories } from '../../elementCategories'
import { ImageOverlay, UploadOverlay, shortcutOpenChooseFromImageModal } from './handleMouseDown'

Registry.register(
  {
    title: 'Choose from uploads',
    IngressComponent: ImageOverlay,
    category: elementCategories.IMAGE,
    shortcut: { key: 'mod+shift+f', callback: shortcutOpenChooseFromImageModal },
    isContentStackElement: true,
    inBasicToolbar: false
  },
  { id: 'chooseImage', registry: 'element' }
)

Registry.register(
  {
    title: 'Upload new file(s)',
    IngressComponent: UploadOverlay,
    category: elementCategories.IMAGE,
    isContentStackElement: true,
    inBasicToolbar: false
  },
  { id: 'uploadImage', registry: 'element' }
)
