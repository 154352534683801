import * as React from "react"
const SvgComponent = React.forwardRef<SVGSVGElement>((props, ref) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2H2v5h1.512a4.524 4.524 0 000 2H2v5h5v-1.59c.66.134 1.345.119 2-.046V14h5V9h-1.712c.15-.658.15-1.342 0-2H14V2H9v1.636a4.523 4.523 0 00-2-.046V2zm2 14H0V0h16v16H9zM5.6 5.703a.75.75 0 011.062 0L7.899 6.94l1.237-1.237a.75.75 0 111.06 1.061L8.96 8.001l1.237 1.237a.75.75 0 01-1.06 1.061L7.898 9.062l-1.237 1.236a.75.75 0 11-1.06-1.06L6.838 8 5.601 6.763a.75.75 0 010-1.06z"
        fill="#647696"
      />
    </svg>
  )
})

export default SvgComponent
