import { getRegistry } from '../../../utils'

export const allElements = (registry = null) => {
  if (!registry) {
    return []
  } else {
    return Object.entries(getRegistry(registry)).map(([key, value]) => {
      return [key, value['components'][0]['component']]
    })
  }
}

export const allCategory = (category = {}) => {
  return Object.entries(category)
}