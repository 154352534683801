const emptyFunc = () => {
  // Does nothing
};

export const dndResetProps = {
  onDrop: emptyFunc,
  onDragExit: emptyFunc,
  onDragLeave: emptyFunc,
  onDragOver: emptyFunc,
  onDrag: emptyFunc,
  onDragStart: emptyFunc,
  onDragEnter: emptyFunc,
  onDragEnd: emptyFunc,
}

export { applyPlugins } from './applyPlugins'

export * from './markdown/withMarkdown'

export * from './getRegistry'

export * from './softbreak'

// export * from './selectAll' // use default selectAll from slate.js 

export * from './hooks/withId'

export * from './hooks'

export * from './isMac'

export * from './deserializer'

export * from './rtePlugins';
