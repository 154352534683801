import isUrl from 'is-url'
import { Registry } from '@react-registry'

import { insertImage, isImageUrl } from '../image'

export const withEmbeds = editor => {
    const { isVoid } = editor
    editor.isVoid = element => {
        return (element.type === 'embed' ? true : isVoid(element))
    }

    editor.insertEmbed = url => {
        if (!url || !isUrl(url)) return false;
        if (isImageUrl(url)) {
            insertImage(editor, url)
            return
        }
        let embed = {
            type: 'embed',
            attrs: {
                url,
            },
            children: [{ text: '' }]
        }
        editor.insertNode(embed);
    }

    return editor
}

Registry.register(withEmbeds, { id: 'withEmbed', registry: 'plugin' })