import { Editor } from 'slate'
import { getBlockAbove } from './getBlockAbove'
import { getPointFromLocation } from './getPointFromLocation'

export const getRangeFromBlockStart = (editor, options = {}) => {
  const [, path] = getBlockAbove(editor, options)

  const start = Editor.start(editor, path)

  const focus = getPointFromLocation(editor, options)

  if (!focus) return

  return { anchor: start, focus }
}
