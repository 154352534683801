import React from 'react'
import { Registry } from '@react-registry'


const FRAGMENT = ({ children }) => {
  return (
    <span style={{ display: "inline" }}>{children}</span>
  )
}

export default FRAGMENT
Registry.register(
  {
    Component: FRAGMENT,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    },
    toolbar: {
      inHoveringToolbar: false
    }
  },
  { id: 'fragment', registry: 'v2.element' }
)