import { Editor, Node, Text } from 'slate'
import { isInlineElement } from "../../../utils/queries"
import { getNormalTextElement } from "../../Element/paragraph/utils"
// export const wrapWithParagraph = (editor, fragments) => {
//   let newFragment = []
//   let currentSegment = []

//   for (const fragment of fragments) {
//     if (
//       Text.isText(fragment) ||
//       (Node.isNode(fragment) && Editor.isInline(editor, fragment))
//     ) {
//       currentSegment.push(fragment)
//     } else {
//       if (currentSegment.length) {
//         newFragment.push({
//           type: 'p',
//           attrs: {},
//           children: currentSegment
//         })
//         currentSegment = []
//       }
//       newFragment.push(fragment)
//     }
//   }

//   if (currentSegment.length) {
//     newFragment.push({
//       type: 'p',
//       attrs: {},
//       children: currentSegment
//     })
//   }

//   return newFragment
// }

// export const preInsertFormatter = (editor, fragment) => {
//   let fragmentLength = fragment.length
//   let currentFragment = [...fragment]

//   while (fragmentLength) {
//     if (Text.isText(currentFragment[0])) {
//       let text = String(currentFragment[0].text).replace(/\n/g, '')
//       if (text === '') {
//         currentFragment.shift()
//         --fragmentLength
//       } else {
//         break
//       }
//     } else {
//       break
//     }
//   }
//   while (fragmentLength) {
//     if (Text.isText(currentFragment[fragmentLength - 1])) {
//       let text = String(currentFragment[fragmentLength - 1].text).replace(
//         /\n/g,
//         ''
//       )
//       if (text === '') {
//         currentFragment.pop()
//         --fragmentLength
//       } else {
//         break
//       }
//     } else {
//       break
//     }
//   }

//   currentFragment = wrapWithParagraph(editor, fragment)

//   return currentFragment
// }
export const preInsert = (editor, fragment) => {
  // const firstNodeType = fragment[0]?.type || undefined
  // if (isBlockAboveEmpty(editor) && firstNodeType && !isInlineElement(fragment[0])) {
  //   Transforms.setNodes(editor, { type: fragment[0].type })
  // }
  const firstNodeType = fragment[0]?.type || undefined
  if (firstNodeType && !isInlineElement(fragment[0]) && firstNodeType !== 'fragment' && firstNodeType !== 'p') {
    fragment.unshift(getNormalTextElement())
    fragment.push(getNormalTextElement());
  }
  return fragment
}