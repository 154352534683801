import { JsonNode } from 'components/RichTextEditor/SuperChargedRte/utils/types'
import { Editor, Text, NodeEntry } from 'slate'

export function getLowestElementAtSelection(editor, selection): NodeEntry<JsonNode> | undefined {
  let lowestElementAtSelection: NodeEntry<JsonNode> = Editor.node(editor, selection)

  if (lowestElementAtSelection[0].type === 'docs') return undefined

  if (!Text.isText(lowestElementAtSelection[0])) return lowestElementAtSelection

  return Editor.node(editor, selection, { depth: -1 })
}