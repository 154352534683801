import React from 'react'
import { handleDelete } from '../../../../utils/actions'
import { ReferenceComponent } from './components';


export const InlineReference = ({ entry, editor, element, ...props }) => {
  const { history, apiKey } = editor?.requestProps || {};
  const contentTypeUid = element?.attrs?.['content-type-uid'],
    entryUid = element?.attrs?.['entry-uid'],
    locale = element?.attrs?.['locale'];

  const handleEdit = (e: Event) => {
    history.push(`/stack/${apiKey}/content-type/${contentTypeUid}/${locale}/entry/${entryUid}/edit`)
  }
  return (
    <ReferenceComponent type='inline' entry={entry} editor={editor} content_type_uid={contentTypeUid} handleEdit={handleEdit} handleDelete={() => handleDelete(editor, element)} attributes={{ ...props.attributes, ...props.attrs, element }} children={props.children} />
  )
}

export * from './withInlineReference'
export * from './components'
export * from './deserializer'