import { Registry } from '@react-registry'

import { elementCategories } from '../../elementCategories'
import { UploadOverlay } from './handleMouseDown'

Registry.register(
  {
    title: 'Upload new asset(s)',
    IngressComponent: UploadOverlay,
    category: elementCategories.IMAGE,
    isContentStackElement: true,
    inBasicToolbar: false.valueOf,
    toolbar: {
      inSlashToolbar: false
    }
  },
  { id: 'uploadImage', registry: 'v2.element' }
)
