import React, { Fragment, useCallback, useState } from 'react'
import './InlineForms.css'
import Icon from '../Icon2/Icon'
import TextInput from '../TextInput/TextInput'

interface InlineFormsProps {
  /**
   * An optional function to be called when form is closed
   **/
  onCancel?: (arg?: any) => void
  /**
   * An optional function to be called when form is saved
   **/
  onSave?: (arg?: any) => void
  defaultTitle?: string
  fieldArray?: Array<React.ReactNode>
  disableSave?: boolean
  defaultOpen?: boolean
}

const InlineForms = (props: InlineFormsProps) => {
  const { onCancel, onSave, defaultTitle, fieldArray, disableSave, defaultOpen } = props
  const [isOpen, setOpen] = useState(defaultOpen)

  const cancelClicked = () => {
    if (onCancel) {
      onCancel()
    }

    setOpen(false)
  }

  const saveClicked = () => {
    if (onSave) {
      onSave()
    }

    setOpen(false)
  }

  return (
    <div className="InlineForms">
      {/* <div className="InlineForms__add-icon add-wrapper flex-v-center flex-h-center mt-8 mb-8" onClick={(e) => { */}
      <div
        className={`InlineForms__plus-icon ${
          isOpen ? 'InlineForms__plus-icon--default' : ''
        } flex-v-center flex-h-center mt-8 mb-8`}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}>
        <Icon icon="AddCircle" />
      </div>
      {!isOpen ? (
        // <div onClick={() => setOpen(true)} className="InlineForms__add-text ml-20">
        //   Add forms
        // </div>
        <div
          onClick={(e: any) => {
            e.stopPropagation()
            setOpen(true)
          }}
          className="InlineForms__add-text ml-20">
          {defaultTitle !== undefined ? defaultTitle : 'Add Item'}
        </div>
      ) : (
        <Fragment>
          <div className="InlineForms__forms">
            {fieldArray !== undefined ? (
              fieldArray.length &&
              fieldArray.map((field: any, i: any) => {
                return <Fragment key={i}>{field}</Fragment>
              })
            ) : (
              <>
                <TextInput placeholder="Placeholder" className="InlineForms__forms-fields" />
                <TextInput placeholder="Placeholder 2" className="InlineForms__forms-fields" />
              </>
            )}
            {/* <TextInput placeholder="Placeholder" className="InlineForms__forms-fields" />
            <TextInput placeholder="Placeholder 2" className="InlineForms__forms-fields" /> */}
          </div>
          <div className="InlineForms__add-icon" onClick={cancelClicked}>
            <Icon icon="CancelCircle" />
          </div>
          <div
            className={`InlineForms__add-icon ${disableSave ? 'InlineForms--disable' : ''} ml-20`}
            onClick={disableSave ? () => {} : () => saveClicked()}>
            <Icon icon="CheckedCircle" disabled={disableSave} />
          </div>
        </Fragment>
      )}
    </div>
  )
}

InlineForms.defaultProps = {
  disableSave: false,
  defaultOpen: false
} as Partial<InlineFormsProps>

export default InlineForms
