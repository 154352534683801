import moment from 'moment'
import sortBy from 'lodash/sortBy'

import {
  stringOperators,
  comparisonOperator,
  basicOperators,
  operatorByValue,
  allOperators,
  usersKey,
  dateKey,
  tagKey,
  environmentKey,
  fileNameKey,
  fileSizeKey,
  urlKey,
  titleKey,
  assetTypeKey,
  assetQueryFormat,
  uidKey
} from './constants'

import { searchInStaticData, getFileSizes, getFileSizeLabel, getPopulatedQueryUids, fetchDataByValue, fetchDataForQuery } from './helpers'

/**
 * Syntax QueryTypes | Ops
 * 1. Created At  | compare
 * 2. Created By | compare
 * 3. File Name | stringOps
 * 4. File Size | compare
 * 5. Published At | compare
 * 6. Published By | compare
 * 7. Published Enviornment | basicOperators
 * 8. Tags | basicOperators
 * 9. Title | stringOps
 * 10. Type | basicOperators
 * 11. Updated At | compare
 * 12. Updated By | basicOperators
 * 13. URL | stringops
 */

export const suggestedSyntaxes = [
  { label: 'Text', value: 'textLabel', info: 'Search assets using any keyword', uid: '_text_assets' },
  { label: 'File Name', value: 'File Name', info: 'Search assets by their filename', uid: 'filename' },
  { label: 'File Size', value: 'File Size', info: 'Search assets using asset file size', uid: 'file_size' },
  { label: 'Environment', value: 'Environment', info: 'Search assets by the environment(s) they are published on', uid: '_publish_environment' },
  { label: 'Tags', value: 'Tags', info: 'Search assets based on the tags applied', uid: 'tags' },
  { label: 'Title', value: 'Title', info: 'Search assets by their title', uid: 'title' },
  { label: 'Type', value: 'Type', info: 'Search assets by type', uid: '_content_type_uid' },
  { label: 'URL', value: 'URL', info: 'Search assets using its URL', uid: 'url' },
  { label: 'Published At', value: 'Published At', info: 'Search assets published at a specific time', uid: '_published_at' },
  { label: 'Published By', value: 'Published By', info: 'Search assets published by a user', uid: '_published_by' },
  { label: 'Created At', value: 'Created At', info: 'Search assets by their creation date', uid: 'created_at' },
  { label: 'Created By', value: 'Created By', info: 'Search assets created by a user', uid: 'created_by' },
  { label: 'Updated At', value: 'Updated At', info: 'Search assets updated at a specific time', uid: 'updated_at' },
  { label: 'Updated By', value: 'Updated By', info: 'Search assets updated by a user', uid: 'updated_by' },
  { label: 'UID', value: 'UID', info: 'Search assets by UID', uid: 'uid' }
]

export const suggestedSyntaxByUid = {
  _text_assets: {
    label: "Text",
    value: "textLabel",
    info: "Search assets using any keyword",
    uid: "_text_assets",
  },
  filename: {
    label: "File Name",
    value: "File Name",
    info: "Search assets by their filename",
    uid: "filename",
  },
  file_size: {
    label: "File Size",
    value: "File Size",
    info: "Search assets using asset file size",
    uid: "file_size",
  },
  _publish_environment: {
    label: "Environment",
    value: "Environment",
    info: "Search assets by the environment(s) they are published on",
    uid: "_publish_environment",
  },
  tags: {
    label: "Tags",
    value: "Tags",
    info: "Search assets based on the tags applied",
    uid: "tags",
  },
  title: {
    label: "Title",
    value: "Title",
    info: "Search assets by their title",
    uid: "title",
  },
  _content_type_uid: {
    label: "Type",
    value: "Type",
    info: "Search assets by type",
    uid: "_content_type_uid",
  },
  url: {
    label: "URL",
    value: "URL",
    info: "Search assets using its URL",
    uid: "url",
  },
  _published_at: {
    label: "Published At",
    value: "Published At",
    info: "Search assets published at a specific time",
    uid: "_published_at",
  },
  _published_by: {
    label: "Published By",
    value: "Published By",
    info: "Search assets published by a user",
    uid: "_published_by",
  },
  created_at: {
    label: "Created At",
    value: "Created At",
    info: "Search assets by their creation date",
    uid: "created_at",
  },
  created_by: {
    label: "Created By",
    value: "Created By",
    info: "Search assets created by a user",
    uid: "created_by",
  },
  updated_at: {
    label: "Updated At",
    value: "Updated At",
    info: "Search assets updated at a specific time",
    uid: "updated_at",
  },
  updated_by: {
    label: "Updated By",
    value: "Updated By",
    info: "Search assets updated by a user",
    uid: "updated_by",
  },
  uid: {
    label: "UID",
    value: "UID",
    info: "Search assets by UID",
    uid: "uid",
  },
};

const inSyntax = {
  group: 'Matching Fields',
  queryType: 'inSyntax',
  nextQuery: 'inUser',
  type: 'key',
  options: [
    { label: 'Created By', value: 'created_by' },
    { label: 'Updated By', value: 'updated_by' },
    { label: 'Published By', value: '_published_by' }
  ]
}

const userLabel = {
  group: '',
  queryType: 'userLabel',
  nextQuery: 'inUser',
  type: 'key',
  options: [
    { label: 'Created by', value: 'created_by' },
    { label: 'Updated by', value: 'updated_by' },
    { label: 'Published by', value: '_published_by' }
  ]
}

export const inAssets = {
  group: 'In Assets',
  queryType: 'inAssets',
  type: 'value',
  nextQuery: 'inAssets',
  options: []
}

export const textLabel = {
  group: '',
  queryType: 'textLabel',
  nextQuery: 'inText',
  type: 'key',
  options: [{ label: 'Text', value: '_text_assets' }]
}

const inText = {
  group: '',
  queryType: 'inText',
  type: 'value',
  options: []
}

export const uidLabel = {
  group: '',
  queryType: 'uidLabel',
  nextQuery: 'inText',
  type: 'key',
  options: [{ label: 'UID', value: 'uid' }]
}

const inUser = {
  group: '',
  queryType: 'inUser',
  type: 'value',
  options: []
}

const dateLabel = {
  group: '',
  queryType: 'date',
  nextQuery: 'inDate',
  type: 'key',
  options: [
    { label: 'Created At', value: 'created_at' },
    { label: 'Updated At', value: 'updated_at' },
    { label: 'Published At', value: '_published_at' }
  ]
}

const inEnvironment = {
  group: '',
  queryType: 'inEnvironment',
  type: 'value',
  options: []
}

const environmentLabel = {
  group: '',
  queryType: 'environmentLabel',
  nextQuery: 'inEnvironment',
  type: 'key',
  options: [{ label: 'Published Environment', value: '_publish_environment' }]
}

const inFileName = {
  group: '',
  queryType: 'TextInput',
  type: 'value',
  options: []
}

export const fileNameLabel = {
  group: '',
  queryType: 'fileNameLabel',
  nextQuery: 'inFileName',
  type: 'key',
  options: [{ label: 'File Name', value: 'filename' }]
}

const inFileSize = {
  group: '',
  queryType: 'inFileSize',
  type: 'value',
  options: []
}

export const fileSizeLabel = {
  group: '',
  queryType: 'fileSizeLabel',
  nextQuery: 'inFileSize',
  type: 'key',
  options: [{ label: 'File Size', value: 'file_size' }]
}

const inUrl = {
  group: '',
  queryType: 'TextInput',
  type: 'value',
  options: []
}

export const urlLabel = {
  group: '',
  queryType: 'urlLabel',
  nextQuery: 'inUrl',
  type: 'key',
  options: [{ label: 'URL', value: 'url' }]
}

const inAssetTag = {
  group: '',
  queryType: 'inAssetTag',
  type: 'value',
  options: []
}

const assetTagLabel = {
  group: '',
  queryType: 'assetTagLabel',
  nextQuery: 'inAssetTag',
  type: 'key',
  options: [{ label: 'Tags', value: 'tags' }]
}

const inTitle = {
  group: '',
  queryType: 'TextInput',
  type: 'value',
  options: []
}

export const titleLabel = {
  group: '',
  queryType: 'titleLabel',
  nextQuery: 'inTitle',
  type: 'key',
  options: [{ label: 'Title', value: 'title' }]
}

const inType = {
  group: '',
  queryType: 'inType',
  type: 'value',
  options: []
}

const typeLabel = {
  group: '',
  queryType: 'typeLabel',
  nextQuery: 'inType',
  type: 'key',
  options: [{ label: 'Type', value: '_content_type_uid' }]
}

const operatorsByQueryType = {
  date: allOperators.filter((op) => op.value != '$ne'),
  fileSizeLabel: allOperators,
  fileNameLabel: stringOperators,
  titleLabel: stringOperators,
  urlLabel: stringOperators
}

export const suggestForAssets = async ({
  queryType,
  fetchData,
  queryCase,
  inputValue,
  skip,
  limit,
  currentSearch,
  action
}) => {
  try {
    let queryData: any = []
    let withOperator = false
    let isQueryCompleted = false
    let count = 0
    let defaultOperator: any = ''

    let operators = operatorsByQueryType[queryType] || basicOperators

    const { value = [] } = currentSearch || {}
    const selectedInputValue = action === 'edit' && value[0].value

    switch (queryType) {
      case 'init': {
        if (queryCase === 'basicQuery') {
          inAssets.options = [{ label: inputValue, value: inputValue, type: 'text' }]
          count = 1

          let staticQueryData = [
            inSyntax,
            fileNameLabel,
            fileSizeLabel,
            titleLabel,
            urlLabel,
            dateLabel,
            typeLabel,
            environmentLabel,
            assetTagLabel,
            uidLabel
          ]

          let searchResponse = searchInStaticData(staticQueryData, inputValue)
          queryData = [inAssets, ...searchResponse.data, textLabel]
          count = count + searchResponse.count
        }

        if (queryCase === 'advanceQuery') {
          let staticQueryData = [
            userLabel,
            fileNameLabel,
            fileSizeLabel,
            titleLabel,
            urlLabel,
            dateLabel,
            typeLabel,
            environmentLabel,
            assetTagLabel,
            uidLabel
          ]
          let searchResponse = searchInStaticData(staticQueryData, inputValue)
          queryData = searchResponse.data
          count = searchResponse.count
        }

        break
      }

      case 'date': {
        count = 0
        withOperator = true
        defaultOperator = allOperators.find((op) => op.value === '$eq')
        break
      }

      case 'environmentLabel': {
        const response: any = await fetchData.fetchEnvironments({ skip, limit, inputValue })
        inEnvironment.options = response.data
        count = response.count
        queryData = [inEnvironment]
        withOperator = true
        break
      }

      case 'textLabel': {
        if (action === 'edit') {
          inText.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inText.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        queryData = [inText]
        count = 1
        defaultOperator = stringOperators[1]
        break
      }

      case 'uidLabel': {
        if (action === 'edit') {
          inText.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inText.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        queryData = [inText]
        count = 1
        withOperator = true
        break
      }

      case 'fileNameLabel': {
        if (action === 'edit') {
          inFileName.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inFileName.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        count = 1
        queryData = [inFileName]
        withOperator = true
        defaultOperator = stringOperators[1]
        break
      }

      case 'fileSizeLabel': {
        let num: any = parseInt(inputValue) || parseFloat(inputValue)

        num = num || ''
        const fileSizeOptions = getFileSizes({ inputValue: num })
        inFileSize.options = fileSizeOptions
        count = 1
        queryData = [inFileSize]
        withOperator = true
        defaultOperator = allOperators[0]
        break
      }

      case 'urlLabel': {
        if (action === 'edit') {
          inUrl.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inUrl.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        count = 1
        queryData = [inUrl]
        withOperator = true
        defaultOperator = stringOperators[1]
        break
      }

      case 'assetTagLabel': {
        const response: any = await fetchData.fetchAssetTags({ skip, limit, inputValue })
        let filteredTags = response.data

        // if (inputValue) {
        //   filteredTags = filteredTags.filter((tag) => {
        //     return tag.label.toLowerCase().includes(inputValue.toLowerCase())
        //   })
        // }
        // filteredTags = sortBy(filteredTags, (tag) => tag.label)

        inAssetTag.options = filteredTags
        count = response.count
        queryData = [inAssetTag]
        withOperator = true
        break
      }

      case 'titleLabel': {
        if (action === 'edit') {
          inTitle.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inTitle.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        count = 1
        queryData = [inTitle]
        withOperator = true
        defaultOperator = stringOperators[1]
        break
      }
      case 'typeLabel': {
        const response: any = await fetchData.fetchAssetTypes({
          skip,
          limit,
          inputValue: inputValue || ''
        })
        inType.options = response.data
        count = response.count
        queryData = [inType]
        withOperator = true
        break
      }

      case 'userLabel':
      case 'inSyntax': {
        const response: any = await fetchData.fetchUsers({ skip, limit, inputValue })
        inUser.options = response.data
        count = response.count
        queryData = [inUser]
        withOperator = true
        break
      }

      case 'TextInput':
      case 'inFileSize':
      case 'inEnvironment':
      case 'inUser':
      case 'inAssetTag':
      case 'inType':
      case 'inDate':
      case 'inAssets':
      case 'inText':
        isQueryCompleted = true
        break
    }

    if (defaultOperator && withOperator) {
      operators = operators.filter((operator) => operator.value !== defaultOperator.value)
      operators.unshift(defaultOperator)
    }

    return {
      withOperator,
      queryData,
      isQueryCompleted,
      count,
      operators,
      defaultOperator
    }
  } catch (error) {
    console.log('suggestForAssets', error)
  }
}

/*
 **this method is being used in adv querycase also ,
 ** when do chaneg in resposne format handle there also
 */
export const queryBuilderAssets = ({ queryArray }) => {
  let queryObj = { $and: [] }
  let search = ''

  queryArray.forEach((query) => {
    const { key, operator, value, queryType } = query

    if (queryType === 'inAssets' || queryType === 'textLabel') {
      search = value[0].value
    } else if (queryType === 'environmentLabel') {
      const envOpsMapping = {
        $eq: '$in',
        $ne: '$nin'
      }
      const env_operator = envOpsMapping[operator[0].value]
      queryObj.$and.push({ [key[0].value]: { [env_operator]: [value[0].value] } })
    } else if (value[0].type === 'text' && operator[0].value === 'contains') {
      queryObj.$and.push({ [key[0].value]: { $regex: value[0].value } })
    } else if (queryType === 'date') {
      if (operator[0].value === '$eq') {
        let startTime = moment(value[0].value).startOf('day').toISOString()
        let endTime = moment(value[0].value).endOf('day').toISOString()
        let startTimeQuery = { [key[0].value]: { $gte: startTime } }
        let endTimeQuery = { [key[0].value]: { $lte: endTime } }
        queryObj.$and.push(startTimeQuery)
        queryObj.$and.push(endTimeQuery)
      } else if (operator[0].value === '$lte') {
        let startTime = moment(value[0].value).endOf('day').toISOString()
        let query = { [key[0].value]: { [operator[0].value]: startTime } }
        queryObj.$and.push(query)
      } else {
        let startTime = moment(value[0].value).startOf('day').toISOString()

        let query = { [key[0].value]: { [operator[0].value]: startTime } }
        queryObj.$and.push(query)
      }
    } else if (operator[0].value !== 'contains') {
      let query = { [key[0].value]: { [operator[0].value]: value[0].value } }
      queryObj.$and.push(query)
    }
  })
  return { queryObject: queryObj.$and.length ? queryObj : '', search }
}

const parseQueryForObject = ({ queryKey, query, users, types, queryArray }) => {
  const value = Object.values(query[queryKey])[0]
  const operator = Object.keys(query[queryKey])[0]
  const sampleQuery = assetQueryFormat[queryKey]

  if (usersKey.includes(queryKey)) {
    const foundUser = users.find((user) => user.value === value)
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: foundUser ? foundUser.label : value, value }]
    }
    queryArray.push(rawQuery)
    return
  }

  if (assetTypeKey === queryKey) {
    const foundAssetType = types.find((assetType) => assetType.value === value)
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: foundAssetType ? foundAssetType.label : value, value }]
    }
    queryArray.push(rawQuery)
  }

  if (uidKey === queryKey) {
    // const operator = Object.keys(query[queryKey])[0]
    // const sampleQuery = entriesQueryFormat[queryKey]
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: value, value, type: 'text' }]
    }
    queryArray.push(rawQuery)
  }

  if (dateKey.includes(queryKey)) {
    const localDate = moment(value).format('YYYY-MM-DD')
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: localDate, value: localDate }]
    }
    queryArray.push(rawQuery)
    return
  }

  if (fileSizeKey === queryKey) {
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: getFileSizeLabel(value), value }]
    }
    queryArray.push(rawQuery)
    return
  }

  if (environmentKey === queryKey) {
    const [value]: any = Object.values(query[queryKey])[0]
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: value, value }]
    }
    queryArray.push(rawQuery)
    return
  }

  if ([fileNameKey, urlKey, titleKey, tagKey].includes(queryKey)) {
    const rawQuery = {
      ...sampleQuery,
      operator: [operatorByValue[operator]],
      value: [{ label: value, value }]
    }
    queryArray.push(rawQuery)
    return
  }
}

const assetsFormat = {
  key: [
    {
      label: 'Text',
      value: '_text_assets'
    }
  ],
  operator: [
    {
      name: 'Contains',
      icon: 'Contains',
      value: 'contains'
    }
  ],
  value: [
    {
      label: 'test',
      value: 'test',
      type: 'text'
    }
  ],
  isQueryCompleted: true,
  queryType: 'inAssets',
  nextQuery: 'inAssets'
}

export const queryParserAssets = async (queryObject, search_text, fetchData, cacheResponse?) => {
  try {
    let queryArray = []

    if (search_text) {
      queryArray.push({
        ...assetsFormat,
        value: [{ label: search_text, value: search_text, type: 'text' }]
      })
    }

    const { $and: andArray = [] } = queryObject || {}

    if (!queryObject || !Array.isArray(andArray)) {
      return queryArray
    }

    let userResponse = []
    let typesResponse = []

    if (cacheResponse) {
      userResponse = cacheResponse.userResponse
      typesResponse = cacheResponse.typesResponse
    } else {
      const response = await fetchDataForQuery(andArray, fetchData)
      userResponse = response.userResponse
      typesResponse = response.typesResponse
    }


    andArray.forEach((query) => {
      const queryKey = Object.keys(query)[0]
      parseQueryForObject({
        queryKey,
        query,
        users: userResponse,
        types: typesResponse,
        queryArray
      })
    })

    return queryArray
  } catch (error) {
    console.log('queryParserAssets', error)
    throw error
  }
}


export const advanceQueryAssetParser = async (query, selectedModule, fetchData, cache: any = {}, isRoot = true) => {

  const isQuery = (q) => Object.keys(q).includes('$and') || Object.keys(q).includes('$or')
  const firstKey = Object.keys(query)[0]

  const subQueries = []
  const currentQueryFields = []
  const children = []

  if (isQuery(query)) {
    const curQuery = query[firstKey]
    for (let subQuery of curQuery) {
      if (isQuery(subQuery)) {
        subQueries.push(subQuery)
      } else {
        currentQueryFields.push(subQuery)
      }
    }
  }

  const currentQuery = { $and: currentQueryFields }
  let queryArray = await queryParserAssets(currentQuery, '', fetchData, cache)

  for (let subQuery of subQueries) {
    const queryObject = await advanceQueryAssetParser(subQuery, selectedModule, fetchData, cache, false)

    if (queryObject) {
      children.push(queryObject)
    }
  }

  const data: any = {}

  if (isRoot) data.root = true

  data.children = children
  data.queryArray = queryArray
  data.queryType = firstKey === '$and' ? 'matchAll' : 'matchAny'

  return data
}
