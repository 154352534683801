import React, { useEffect, useState } from 'react';
import { Button, cbModal, DateLabel, EmptyState, Icon, ModalBody, ModalFooter, ModalHeader, Search } from '../../../../../../../../..';

import styles from './styles.module.css'

const PublishDetailsModal = (props: any) => {
  const { data, environments, users, locales } = props;
  const publish_details = data['publish_details'] || [];
  const [searchResults, setSearchResults] = useState([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    const result = Array.from(publish_details).filter(pd => {
      const name = environments?.[pd['environment']]?.['name']?.toLowerCase();
      return name?.includes(searchValue.toLowerCase())
    });
    setSearchResults(result);
  }, [searchValue])

  const handleChange = (evt: any) => {
    setSearchValue(evt);
  }

  return (
    <div className="modal-info-list">
      <ModalHeader
        title={<div className={styles["info-heading"]}>Publish Status <span>{publish_details.length}</span></div>} closeModal={props.closeModal}
      />
      <ModalBody>
        <Search placeholder="Search publish entries" onChange={handleChange} value={searchValue} width="full" type="secondary"></Search>
        {searchResults.length ?
          <div className={styles["info-wrapper"]}>
            <div className={styles["publish-status-list"]}>{
              searchResults.map((Publish_el: any) => {
                return (
                  <div key={Publish_el['environment']} className={styles["publish-wrapper"]} data-testid={"publish-list"}>
                    <Icon icon='EnvironmentColor' fill={environments?.[Publish_el['environment']]?.['color']}></Icon>
                    <div>
                      <div className={styles["env"]}>{environments?.[Publish_el['environment']]?.['name']}</div>
                      <div className={styles["ver"]}><span className={styles['bold']}>Version {Publish_el.version}</span> Published</div>
                      <DateLabel type="tiny" date={Publish_el.time} className="mt-5" />
                      <div className={styles["user"]}>By {users[Publish_el['user']]}</div>
                      <div className={styles["user"]}>In {locales[Publish_el['locale']]}</div>
                    </div>
                  </div>
                )
              })
            }
            </div>
          </div>
          : <div className={styles["info-wrapper"]}>
            <EmptyState heading={'No results found'} displayImage={false} type="tertiary" />
          </div>}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          buttonType="light"
          onClick={props.closeModal}
          size="small"
        >
          <span>Cancel</span>
        </Button>
      </ModalFooter>
    </div>
  )
}

export const handlePublishDetailClick = ({ data, environments, users, locales }) => {
  cbModal({
    component: (props: any) => <PublishDetailsModal users={users} {...props} locales={locales} environments={environments} data={data} />,
    modalProps: {
      size: 'small',
      customClass: 'modal-body-p-0'
    }
  })
}