//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'

import { createCell } from './createCell'
import { createHeadCell } from './createHeadCell'
import { elementCategories } from '../../elementCategories'
import { TableAddColumnBelowIcon } from '../../../utils/Icon'

export const insertColAfter = (event, { editor }) => {
  const [rowHead] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'th'
    },
    mode: 'lowest'
  })
  const [rowBody] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'td'
    },
    mode: 'lowest'
  })
  let row
  if (rowBody && !rowHead) {
    row = rowBody
  } else if (rowHead && !rowBody) {
    row = rowHead
  } else if (rowHead && rowBody) {
    row = rowBody
  }
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  if (row && table) {
    const selection = row[1][row[1].length - 1] + 1
    Array.from(table[0].children).forEach((tableChild, index0) => {
      Array.from(tableChild.children).forEach((child, index1) => {
        const path = [...table[1], index0, index1, selection]
        if (child.children[selection - 1].type === 'th') {
          Transforms.insertNodes(editor, createHeadCell(), { at: path })
        }
        if (child.children[selection - 1].type === 'td') {
          Transforms.insertNodes(editor, createCell(), { at: path })
        }
      })
    })
    let colWidths = table[0].attrs.colWidths.slice()
    colWidths.splice(selection, 0, 250)
    Transforms.setNodes(
      editor,
      {
        attrs: {
          ...table[0].attrs,
          colWidths,
          cols: table[0].attrs.cols + 1
        }
      },
      { at: table[1] }
    )
  }
}
const InsertColAfterButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: insertColAfter,
        isDisable: { func: insertColAfterButtonDisabler, params: { editor } },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}

export const shortcutInsertColAfter = (event, editor, format, registryObj) => {
  insertColAfter(event, { editor })
}

const insertColAfterButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })

  return !table
}
Registry.register(
  {
    IngressComponent: InsertColAfterButton,
    iconName: <TableAddColumnBelowIcon />,
    toolbar: {
      inSlashToolbar: false
    },
    canCustomize: false,
    shortcut: { key: 'ctrl+shift+right', callback: shortcutInsertColAfter },
    category: elementCategories.TABLE_ACTION,
    title: 'Insert column right',
    isContentStackElement: true
  },
  {
    id: 'table-insert-col-after',
    registry: 'v2.element'
  }
)
