
// import {
//   operatorTextByName
// } from './constants';

// import { getQueryType, getPopulatedQueryUids, fetchDataByValue, parseSafeJSON } from './helpers';
import { basicQueryParser } from './common';

// const queryTypeMapping = {
//   matchAny: 'Match Any',
//   matchAll: 'Match All'
// }

export const buildRecentSearches = async ({ recentSearchResponse = [] }) => {
  try {
    const newRecentSearches = recentSearchResponse.filter(recentSearch => recentSearch.search_text)

    for (let idx = 0; idx < newRecentSearches.length; idx++) {
      const recentSearch = newRecentSearches[idx];

      let search_text = recentSearch.search_text;
      let selectedOption = recentSearch.type;

      let queryArray = [];
      if (search_text) {
        queryArray = await basicQueryParser({ search_text, selectedOption });
        newRecentSearches[idx] = { ...newRecentSearches[idx], queryArray, queryType: 'basicQuery' };
      }
    }
    return newRecentSearches;
  } catch (error) {
    console.log("buildRecentSearches error", error)
    throw error
  }
}

// export const buildRecentSearches = async ({ recentSearchResponse = [], fetchData }) => {
//   try {
//     const newRecentSearches = [...recentSearchResponse];

//     let parsedQueryMap = {};

//     let cacheResponse: {
//       userResponse: any,
//       typesResponse: any,
//       ctResponse: any,
//       langResponse: any,
//       workflowStagesResponse: any
//     };

//     //populate workflow stages uid and fetch
//     const users = [];
//     const contentTypes = [];
//     const languages = [];
//     const types = [];
//     const workflowStages = []

//     // collect all values to make api request and cache them
//     for (let idx = 0; idx < recentSearchResponse.length; idx++) {
//       const recentSearch = recentSearchResponse[idx];

//       if (!recentSearch.query) continue;

//       const queryType = getQueryType(recentSearch.query, recentSearch.type);
//       const parsedQuery = parseSafeJSON(recentSearch.query) || {};

//       parsedQueryMap[idx] = parsedQuery;

//       let valuesObject: any = {
//         users: [],
//         contentTypes: [],
//         languages: [],
//         types: [],
//         workflowStages: []
//       }

//       if (queryType === 'basicQuery' || queryType === 'advanceQuery') {
//         const queryObjectArray = parsedQuery['$and'] || parsedQuery['$or'] || [];
//         valuesObject = getPopulatedQueryUids(queryObjectArray);
//       }

//       users.push(...valuesObject.users);
//       contentTypes.push(...valuesObject.contentTypes);
//       languages.push(...valuesObject.languages);
//       types.push(...valuesObject.types);
//       workflowStages.push(...valuesObject.workflowStages);
//     }

//     cacheResponse = await fetchDataByValue({ users, types, contentTypes, languages, workflowStages, fetchData })

//     // parse each query with cache response and build queryArray
//     for (let idx = 0; idx < recentSearchResponse.length; idx++) {
//       const recentSearch = recentSearchResponse[idx];

//       let search_text = recentSearch.search_text;
//       let selectedOption = recentSearch.type;
//       let queryType = getQueryType(recentSearch.query, recentSearch.type);
//       let query = '';

//       if (recentSearch.query) {
//         query = parsedQueryMap[idx];
//       }

//       let queryArray = [];

//       if (queryType === 'basicQuery') {
//         queryArray = await basicQueryParser({ queryObject: query, search_text, fetchData, cacheResponse, selectedOption });
//       }

//       if (queryType === 'advanceQuery') {
//         queryArray = await advanceQueryParser({ query, fetchData, selectedModule: selectedOption, cache: cacheResponse });
//       }

//       newRecentSearches[idx] = { ...newRecentSearches[idx], queryArray, queryType };
//     }
//     return newRecentSearches;
//   } catch (error) {
//     console.log("buildRecentSearches error", error)
//     throw error
//   }
// }


// const basicQueryToString = ({ queryArray = [] }) => {
//   let queryString = '';

//   for (let query of queryArray) {
//     queryString += ' ';
//     queryString += `"${query.key[0].label} ${operatorTextByName[query.operator[0].name]} ${query.value[0].label}"`
//   }

//   return queryString
// }

// const advanceQueryToString = ({ queryArray = [] }) => {
//   let queryString = '';

//   for (let query of queryArray) {
//     let queryType = `"${queryTypeMapping[query.queryType]}"` || '';

//     let basicQueryString = basicQueryToString({ queryArray: query.queryArray });
//     let advanceQueryString = advanceQueryToString({ queryArray: query.children });

//     queryString += `${queryType} ${basicQueryString} ${advanceQueryString}`;
//   }

//   return queryString
// }


// export const queryToString = ({ queryType, queryArray, type }) => {
//   let queryString = '';
//   let prefix = ''

//   if (type === 'assets') prefix = 'In Assets';
//   if (type === 'entries') prefix = 'In Entries';

//   if (queryType === 'basicQuery') {
//     queryString = basicQueryToString({ queryArray });
//   }

//   if (queryType === 'advanceQuery') {
//     queryString = advanceQueryToString({ queryArray })
//   }

//   return { queryString, prefix };
// }