//@ts-nocheck
import { Registry } from '@react-registry'
import { getRegistry } from '../getRegistry';

const callbackMapper = {
  onChange: 'change',
  'normalize': 'normalizeNode'
}

const callbackPropsMapper = {
  change: ({ editor }) => ({ value: editor.children })
}

const withPlugin = (type, _callback, _props = {}, editor) => {
  const callbacks = getRegistry(type);
  let newCb = _callback;

  Object.entries(callbacks).forEach(([key, val]) => {
    const { callback, props } = val['components'][0]['component'];
    let newDefinition = newCb;
    const extraProps = callbackPropsMapper[type] ? callbackPropsMapper[type]({ editor }) : {};
    const event = {
      ...props,
      ..._props,
      ...extraProps,
      preventDefault: () => {
        newDefinition = null;
      }
    }
    newCb = () => {
      callback(event)
      newDefinition && newDefinition();
    };
  });

  return newCb(_props);
}

const displayType = {
  isVoid: 'void',
  isInline: 'inline'
}

type IDisplayType = "block" | "void" | "inline"
type IDynamicType = IDisplayType | IDisplayType[]
const withDisplayType = (type, _callback, node) => {
  if (!node['type']) return _callback(node);
  const extension = Registry.get({ id: node['type'], registry: 'v2.extension' });
  if (!(extension && extension.elementType)) {
    return _callback(node);
  }
  if (typeof extension.elementType === 'function') {
    const dynamicType: IDynamicType = extension.elementType(node)
    let result: boolean = true
    if (Array.isArray(dynamicType)) {
      result = dynamicType.includes(displayType[type])
    } else {
      result = dynamicType === displayType[type]
    }
    return result
  } else {
    return extension.elementType.includes(displayType[type]);
  }
}

export const withRteExtension = (editor) => {
  const { insertBreak, deleteForward, deleteBackward, normalizeNode, insertText, isVoid, isInline, onChange } = editor;
  const allowedEvents = {
    insertBreak,
    deleteBackward,
    deleteForward,
    normalizeNode,
    insertText,
    onChange
  };
  const displayType = {
    isVoid,
    isInline
  }

  Object.entries(allowedEvents).forEach(([key, val]) => {
    const pluginType = callbackMapper[key] || key;
    editor[key] = (args) => {
      withPlugin(pluginType, val, args, editor)
    }
  })

  Object.entries(displayType).forEach(([key, val]) => {
    editor[key] = (args) => {
      return withDisplayType(key, val, args)
    }
  })


  return editor
}

Registry.register(withRteExtension, {
  id: 'withRteExtension',
  registry: 'v2.plugin'
})
