import React from 'react'

export const CopyIcon = ({ handleCopy }) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCopy}>
      <path d="M9 4H1C0.4 4 0 4.4 0 5V15C0 15.6 0.4 16 1 16H9C9.6 16 10 15.6 10 15V5C10 4.4 9.6 4 9 4Z" fill="#647696" />
      <path d="M13 0H3V2H12V13H14V1C14 0.4 13.6 0 13 0Z" fill="#647696" />
    </svg>
  )
}

export const DeleteIcon = ({ handleDelete, editor, element }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(editor, element)}>
      <path d="M2 6V14C2 15.1 2.9 16 4 16H12C13.1 16 14 15.1 14 14V6H2Z" fill="#647696" />
      <path d="M12 3V1C12 0.4 11.6 0 11 0H5C4.4 0 4 0.4 4 1V3H0V5H16V3H12ZM10 3H6V2H10V3Z" fill="#647696" />
    </svg>
  )
}