//@ts-nocheck
import { Editor, Point, Transforms, Node, Text } from "slate";
import { ReactEditor, useFocused, useSelected } from "slate-react";
import { cloneDeep, get, set } from 'lodash';
import { Registry } from "@react-registry";

const ReactEditorHandler = (editor) => {
  return {
    get(target, prop, receiver) {
      return target[prop].bind(null, editor);
    }
  }
}

const handleDecorateAdd = (decorateFunc) => {
  if (decorateFunc) {
    Registry.register(decorateFunc, {
      id: decorateFunc.name,
      registry: 'extended.decorate'
    })
  }
}

const actions = (editor) => {
  const ReactEditorProxy = new Proxy(ReactEditor, ReactEditorHandler(editor));

  // selection
  const selection = {
    get: () => editor.selection,
    set: (_selection) => {
      Transforms.select(editor, _selection)
    },
    isSelected: useSelected,
    isFocused: useFocused,
    getEnd: () => Editor.end(editor, []),
    before: Editor.before.bind(null, editor),
    after: Editor.after.bind(null, editor),
    isPointEqual: Point.equals,
  }

  const generators = {
    elements: Node.elements
  }

  const _adv = {
    editor: editor,
    Transforms,
    Editor,
    ReactEditor: ReactEditorProxy,
    addToDecorate: handleDecorateAdd,
    Text
  }

  // editor
  const getPath = (node) => ReactEditor.findPath(editor, node);
  const setAttrs = (attrs, options) => Transforms.setNodes(editor, attrs, options);
  const isNodeOfType = (type) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === type
    })
    return !!match;
  }
  const getNode = (at, option) => Editor.node(editor, at, option);
  const getNodes = (option) => Editor.nodes(editor, option);
  const string = (at) => Editor.string(editor, at);

  // leaf
  const addMark = (key, val) => Editor.addMark(editor, key, val);
  const removeMark = (key) => Editor.removeMark(editor, key);
  const hasMark = (key) => {
    const marks = Editor.marks(editor)
    return (marks && marks[key]) ? marks[key] : false
  }
  const insertText = (text, location) => location ? Editor.insertText(editor, text) : Transforms.insertText(editor, text, { at: location });
  const getText = () => Editor.string(editor, editor.selection);
  const deleteText = () => Transforms.delete(editor)

  // block level operations
  const updateNode = (type, attrs, options) => Transforms.setNodes(editor, { type, attrs }, options);
  const unsetNode = (options = {}) => {
    Transforms.setNodes(editor, {
      type: 'p'
    }, options);
  }
  const insertNode = (node, options) => Transforms.insertNodes(editor, node, options);
  const deleteNode = (options) => Transforms.delete(editor, options);
  const removeNode = (node) => {
    let path = ReactEditor.findPath(editor, node);
    let pathLength = path.length;
    let siblingPath = [...path.slice(0, pathLength - 1), path[pathLength - 1] + 1];
    Transforms.insertNodes(editor, { type: 'p', children: [{ text: '' }] }, { at: siblingPath })
    Transforms.delete(editor, {
      at: path
    })
  }
  const wrapNode = (node, options) => Transforms.wrapNodes(editor, node, options)
  const unWrapNode = (options) => Transforms.unwrapNodes(editor, options);
  const mergeNodes = (options) => Transforms.mergeNodes(editor, options);

  // storage and fetch content

  const getEmbeddedItems = () => {
    return cloneDeep(get(editor, ['requestProps', 'uidToEntry'], {}))
  }

  const getVariable = (variableName, defaultValue = null) => {
    return get(editor, ['editor_variables', variableName], defaultValue)
  }

  const setVariable = (variableName, value) => {
    set(editor, ['editor_variables', variableName], value)
  }

  return {
    selection,
    generators,
    _adv,

    getNode,
    getNodes,
    getPath,
    setAttrs,
    isNodeOfType,
    string,

    addMark,
    removeMark,
    hasMark,
    insertText,
    getText,
    deleteText,

    getEmbeddedItems,
    getVariable,
    setVariable,

    updateNode,
    unsetNode,
    insertNode,
    deleteNode,
    wrapNode,
    unWrapNode,
    mergeNodes,
    removeNode
  }
};

export default actions;
