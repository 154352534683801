//@ts-nocheck
import React from 'react'
import { Registry } from '@react-registry'

import { StrikethroughIcon } from '../../utils/Icon'
import { leafCategories } from '../leafCategories'

export const StrikeThrough = ({ children }) => <strike>{children}</strike>

export * from './deserializer'

Registry.register(
  {
    Component: StrikeThrough,
    title: 'Strikethrough',
    iconName: <StrikethroughIcon />,
    shortcut: { key: 'mod+-' },
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE_SEE_MORE,
    inBasicToolbar: true,
  },
  { id: 'strikethrough', registry: 'v2.leaf' }
)
