import React from 'react'
import { useSlateStatic } from "slate-react"
import { Registry } from '@react-registry'
import { Style } from "react-style-tag"
import { ExtendedEditor } from '../../../../../../utils/types'

const STYLE = (props) => {
    const editor: ExtendedEditor = useSlateStatic()
    const styleText = props?.attrs?.['style-text'] || ""
    return (
        <span {...props.attributes} contentEditable={false}>
            <Style>{`.scrte-editor-${editor.uniqueId}{
                ${styleText}
            }`}
            </Style>
            {props.children}
        </span>

    )
}

export default STYLE
export * from './withStyle'

Registry.register(
    {
        Component: STYLE,
        isContentStackElement: true,
        dndOptions: {
            DisableDND: true,
            DisableSelectionHalo: true
        }
    },
    { id: 'style', registry: 'element' }
)