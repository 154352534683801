import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { cx, css } from '@emotion/css'
//@ts-ignore
import { HeadingIcon } from '../../../utils/Icon'
import styles from '../style.module.css'
import { H5Icon } from '../../../utils/Icon'
export * from './deserializer'

export const H5 = (props) => {
  const { className } = props.attrs || {}
  return (
    <div {...props.attrs} className={styles['heading']}>
      <h5 {...props.attributes} className={cx(
        className,
        css`padding-top: var(--scrte-padding-top, 0px);`
      )}>
        {props.children}
      </h5>
    </div>
  )
}
Registry.register(
  {
    Component: H5,
    // iconName: 'ri-h-5',
    slashIconName: <H5Icon />,
    slashTitle: 'Heading 5',
    title: <h5 className={styles['h5']}>Heading 5</h5>,
    subtitle: 'Add heading level 5',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+5' },
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['heading five', 'heading 5']
  },
  { id: 'h5', registry: 'v2.element' }
)
