import React, { useEffect, useRef, useState } from 'react';
import styles from '../style.module.css'

const Instagram = ({ url, width }) => {
  const instaRef = useRef(null)
  const [frameHeight, setFrameHeight] = useState('')
  let newUrl;
  if (url.href.indexOf('/embed/captioned') > -1) {
    newUrl = url.href;
  }
  else {
    newUrl = url?.protocol + '//' + url?.hostname + url?.pathname + 'embed/captioned';
  }


  useEffect(() => {
    const handleEvent = (e) => {
      if (e.origin === "https://www.instagram.com" && (JSON.parse(e.data).details.height)) {
        if (instaRef.current.contentWindow === e.source) {
          setFrameHeight(JSON.parse(e.data).details.height)
          window.removeEventListener('message', handleEvent)
        }
      }
    }

    if (instaRef.current) {
      window.addEventListener('message', handleEvent)
    }

    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [width])
  return (
    <>
      <iframe
        ref={instaRef}
        src={newUrl}
        height={frameHeight ? frameHeight : '284'}
        width={width ? `${width}` : '540'}
        className={styles['instagram-default']}
      />
    </>
  );
}

export default Instagram;