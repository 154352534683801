import React from 'react'
import { useSlate } from 'slate-react'

import { getRegistry } from '../../../utils/getRegistry'
import { elementCategories } from '../../../elements/Element/elementCategories'
import { leafCategories } from '../../../elements/Leaf/leafCategories'

import { BlockButton, MarkButton } from '../Button'
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from '../Button/buttonUtils'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'

export const allElements = (registry = null) => {
  if (!registry) {
    return []
  } else {
    return Object.entries(getRegistry(registry)).map(([key, value]) => {
      return [key, value['components'][0]['component']]
    })
  }
}

export const allCategory = (category = {}) => {
  return Object.entries(category)
}

export const toolbarFilter = (
  elements = [],
  toolbarType = null,
  contentStack = false,
  toolbarMode = 'advance',
  customToolbarComponents = {},
  registry = null
) => {
  let outputElements = [...elements]
  switch (toolbarType) {
    case 'mainToolbar':
      outputElements = outputElements.filter(([key, value]) => {
        if (value['toolbar']) {
          const toolbar = value['toolbar']
          if (toolbar['inMainToolbar'] !== undefined) {
            return toolbar['inMainToolbar']
          }
        }
        return true
      })
      break
    case 'hovering':
      outputElements = outputElements.filter(([key, value]) => {
        if (value['toolbar']) {
          const toolbar = value['toolbar']
          if (toolbar['inHoveringToolbar'] !== undefined) {
            return toolbar['inHoveringToolbar']
          }
        }
        return true
      })
      break
    default:
      break
  }

  if (contentStack && registry) {
    outputElements = outputElements.filter(([key, value]) => {
      return value?.isContentStackElement
    })
  }

  if (registry) {
    switch (toolbarMode) {
      case 'basic':
        outputElements = outputElements.filter(([key, value]) => {
          return value?.inBasicToolbar
        })
        break
      case 'advance':
        outputElements = outputElements.filter(([key, value]) => {
          if (value.inAdvToolbar !== undefined) return value.inAdvToolbar
          else return true
        })
        break
      case 'custom':
        outputElements = outputElements.filter(([key, value]) => {
          return customToolbarComponents?.[registry]?.includes(key)
        })
        break
      default:
        break
    }
  }

  return outputElements
}

export const toolbarElements = [
  {
    registry: 'editorButtons',
    categories: [],
    elements: allElements('editorButtons'),
    DefaultComponent: () => {
      // this is intentional
    },
  },
  {
    registry: 'leaf',
    categories: leafCategories,
    DefaultComponent: (props) => {
      const { element, keyData } = props
      const { title, shortcut, iconName } = element;
      return (
        <MarkButton key={keyData} format={keyData} title={title} shortcut={shortcut}>
          {iconName || 'icon-here'}
        </MarkButton>
      )
    },
    DefaultMenuComponent: (props) => {
      const { format, children } = props
      const editor = useSlate()
      return (
        <span
          className={cx(
            { [styles['dropdown__menu__default__list--active']]: isMarkActive(editor, format) },
            { [styles['dropdown__menu__default__list--hoverable']]: !isMarkActive(editor, format) }
          )}
          onMouseDown={(event) => {
            event.preventDefault()
            toggleMark(editor, format)
          }}
        >
          {children}
        </span>
      )
    },
  },
  {
    registry: 'element',
    categories: elementCategories,
    DefaultComponent: (props) => {
      const { element, keyData } = props
      const { title, iconName } = element
      return (
        <BlockButton shortcut={element?.shortcut} key={keyData} format={keyData} title={title}>
          {iconName || 'icon-here'}
        </BlockButton>
      )
    },
    DefaultMenuComponent: (props) => {
      const { format, children } = props
      const editor = useSlate()
      return (
        <span
          className={cx(
            { [styles['dropdown__menu__default__list--active']]: isBlockActive(editor, format) },
            { [styles['dropdown__menu__default__list--hoverable']]: !isBlockActive(editor, format) }
          )}
          onMouseDown={(event) => {
            event.preventDefault()
            toggleBlock(editor, format)
          }}
        >
          {children}
        </span>
      )
    },
  },
]
