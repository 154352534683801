import React, { useState, Fragment, useEffect } from 'react'
import cn from 'classnames'

import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import './SideBarWindow.css'
import is from 'date-fns/esm/locale/is/index.js'

type IArray = {
  id: string
  tabLabel?: string
  tabIcon?: React.ReactNode
  hasNotification?: Boolean
  notifTabIcon?: React.ReactNode
  data?: React.ReactNode
  windowTitle?: string | React.ReactNode
  width?: string
  discussionPanelClassName?: string
  disableSidebar?: boolean
  enableTab?: boolean
  enableInEntryCreatePage?: boolean
  isIndependentlyActive?: boolean
  onClick: () => void
  tabTestId?: string
}

interface SideBarWindowProps {
  /**
   * An array of tab info objects(id, tabLabel, tabIcon, data)
   */
  tabsInfo?: Array<IArray>
  windowData?: {
    tabLabel?: string
    tabIcon?: React.ReactNode
    data?: React.ReactNode
    windowTitle?: string | React.ReactNode
    tabTestId?: string
  }
  isInsideEntryCreatePage?: boolean
  onSelect?: (data?: any) => void
  onClose?: (data?: any) => void
  borderOpen?: boolean
  borderClose?: boolean
  isResizable?: boolean
  defaultSelectedIndex?: number
  onKeyDownCallback?: Function
}
interface WindowContentProps {
  data?: React.ReactNode
  windowTitle?: string | React.ReactNode
  width?: string
  discussionPanelClassName?: string
  isResizable?: boolean
}

export const GetWindowContent = (props: WindowContentProps) => {
  const { data, windowTitle, width, discussionPanelClassName, isResizable } = props

  useEffect(() => {
    if (isResizable) {
      const BORDER_SIZE = 4
      const panel = document.getElementById('resizable__panel')
      panel.style.width = width ? width : '300px'

      let m_pos
      function resize(e) {
        const dx = m_pos - e.x
        m_pos = e.x
        let width = parseInt(getComputedStyle(panel, '').width) + dx
        if (width < 544 && width > 300) {
          panel.style.width = width + 'px'
        }

        if (width <= 400) {
          panel.classList.add('resizable__panel--min')
        } else {
          panel.classList.remove('resizable__panel--min')
        }
      }

      if (panel) {
        panel.addEventListener(
          'mousedown',
          function (e) {
            if (e.offsetX < BORDER_SIZE) {
              m_pos = e.x
              document.addEventListener('mousemove', resize, false)
            }
          },
          false
        )
      }

      document.addEventListener(
        'mouseup',
        function () {
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    }
  }, [])

  if (data) {
    let content_width: any = width ? width : '400px'
    let style: any = { width: isResizable ? 'calc(100% - 4rem)' : content_width }
    if (isResizable) {
      const panel = document.getElementById('resizable__panel')
      if (panel) {
        panel.style.width = width ? width : '400px'
      }
    }
    return (
      <div className={['SidebarWindow__content', discussionPanelClassName].join(' ')} style={style}>
        <div className="SidebarWindow__content__title">{windowTitle}</div>
        <div className="SidebarWindow__content__body">{data}</div>
      </div>
    )
  }

  return <p>No data defined for the tab</p>
}

const SideBarWindow = (props: SideBarWindowProps) => {
  const [isComponentVisible, setIsComponentVisible] = useState(
    Number.isInteger(props.defaultSelectedIndex) && props.defaultSelectedIndex < props.tabsInfo.length
  )
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    Number.isInteger(props.defaultSelectedIndex) && props.defaultSelectedIndex < props.tabsInfo.length
      ? props.defaultSelectedIndex
      : null
  )
  const { tabsInfo, windowData, isInsideEntryCreatePage = false, onSelect, onClose, isResizable } = props
  const [refresh, setRefresh] = useState(false) // this is an arbitary value just to reload component

  const selectTab = (index: number) => {
    if (!tabsInfo[index].hasOwnProperty('disableSidebar') && !tabsInfo[index].disableSidebar) {
      if (tabsInfo[index].hasOwnProperty('enableTab') && tabsInfo[index].enableTab === true) {
        if (tabsInfo[index].hasOwnProperty('onClick')) {
          tabsInfo[index].onClick()
          tabsInfo[index].isIndependentlyActive = !tabsInfo[index].isIndependentlyActive
          setRefresh((prevValue) => !prevValue)
        }
      } else if (!isInsideEntryCreatePage) {
        if (tabsInfo[index].hasOwnProperty('onClick')) {
          tabsInfo[index].onClick()
        }
        if (index === selectedTabIndex && isComponentVisible) {
          setIsComponentVisible(false)
          onClose()
          return
        }
        setIsComponentVisible(true)
        setSelectedTabIndex(index)
        if (onSelect) {
          onSelect()
        }
      }
    }
  }

  const onCloseWindow = () => {
    if (!isInsideEntryCreatePage) {
      setIsComponentVisible(false)
      setSelectedTabIndex(null)
      if (onClose) onClose()
    }
  }

  const onKeyPressTabs = (e: any, index: number)=>{
    if(e.key === "Enter" || e.key === " "){
      selectTab(index)
    }
    props.onKeyDownCallback && props.onKeyDownCallback(e , (index === tabsInfo.length-1))
  }

  return (
    <div className={`SidebarWindow flex ${isComponentVisible ? 'SidebarWindow--active' : ''}`} 
    // onKeyDown={(e)=>{ console.log("check bool", e.target) ;props.onKeyDownCallback && props.onKeyDownCallback({...e,  boolcheck: true})}}
    >
      {props.hasOwnProperty('windowData') ? (
        <>
          {isComponentVisible && (
            <div>
              <div onClick={() => onCloseWindow()} className="RightSideHide SidebarWindow__hide-icon-container">
                <Icon icon="ChevronRight" />
              </div>
              <GetWindowContent data={windowData.data} windowTitle={windowData.windowTitle} />
            </div>
          )}
        </>
      ) : (
        <>
          {isComponentVisible && (
            <div id={isResizable ? 'resizable__panel' : ''}>
              <div className="resizable__panel__stroke"></div>
              <div onClick={() => onCloseWindow()} className="RightSideHide SidebarWindow__hide-icon-container">
                <Icon icon="ChevronRight" />
              </div>
              <GetWindowContent
                data={tabsInfo[selectedTabIndex].data}
                windowTitle={tabsInfo[selectedTabIndex].windowTitle}
                width={tabsInfo[selectedTabIndex].width}
                discussionPanelClassName={tabsInfo[selectedTabIndex].discussionPanelClassName}
                isResizable={isResizable}
              />
            </div>
          )}
        </>
      )}

      <div
        className={`SidebarWindow__tabs-container 
      ${!isComponentVisible && props.borderClose ? 'SidebarWindow__tabs-container--border' : ''} 
      ${isComponentVisible && props.borderOpen ? 'SidebarWindow__tabs-container--border' : ''}`}>
        {props.hasOwnProperty('windowData') ? (
          <>
            {windowData && (
              <Tooltip
                testId={windowData.tabTestId}
                content={windowData.tabLabel}
                position="left"
                showArrow={false}
                interactive={false}>
                <div tabIndex={0} onKeyDown={(e)=>{
                  if(e.key === "Enter"){
                    setIsComponentVisible(!isComponentVisible) 
                  }
                }} className="SidebarWindowSingle__tab-item">
                  <div className="SidebarWindow__tab-item" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                    {windowData.tabIcon}
                  </div>
                </div>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {tabsInfo &&
              tabsInfo.map((tab, index) => {
                const tabClassName = cn('SidebarWindow__tab-item', {
                  'SidebarWindow__tab-item--selected': selectedTabIndex === index,
                  'SidebarWindow__tab-item--disabled':
                    (!tab.enableInEntryCreatePage && isInsideEntryCreatePage) ||
                    (tabsInfo[index].hasOwnProperty('disableSidebar') && tabsInfo[index].disableSidebar),
                  'SidebarWindow__tab-item--independently-active': tab.isIndependentlyActive,
                  '': refresh && false
                })

                return (
                  <Tooltip
                    testId={tab.tabTestId}
                    content={tab.tabLabel}
                    key={index}
                    position="left"
                    showArrow={false}
                    interactive={false}>
                    {tab.hasNotification ? (
                      selectedTabIndex === index && isComponentVisible ? (
                        <div tabIndex={0} onKeyDown={(e: any)=>onKeyPressTabs(e, index)} className={tabClassName} onClick={() => selectTab(index)}>
                          {tab.tabIcon}
                        </div>
                      ) : (
                        <div tabIndex={0} onKeyDown={(e: any)=>onKeyPressTabs(e, index)}  className={tabClassName} onClick={() => selectTab(index)}>
                          {tab.notifTabIcon}
                        </div>
                      )
                    ) : (
                      <div tabIndex={0} onKeyDown={(e: any)=>onKeyPressTabs(e, index)}  className={tabClassName} onClick={() => selectTab(index)}>
                        {tab.tabIcon}
                      </div>
                    )}
                  </Tooltip>
                )
              })}
          </>
        )}
      </div>
    </div>
  )
}

SideBarWindow.defaultProps = {
  tabsInfo: [],
  onKeyDownCallback: ()=>{}
} as Partial<SideBarWindowProps>

export default SideBarWindow
