import React, { useRef } from "react"
import Tippy from '@tippyjs/react'
import { useSlate } from 'slate-react'
import { Transforms } from 'slate'

import { formatShortcut } from '../../../../utils/shortcuts/formatShortcut'

import { TableDeleteRowIcon, TableAddRowBeforeIcon, TableAddRowAfterIcon } from '../../../utils/Icon'
import { deleteRow, insertRowBefore, insertRowAfter } from '../utils/index'

import styles from '../style.module.css'

function SvgComponent(props) {
    const { path } = props
    const editor = useSlate()
    const tooltipRef = useRef(null)
    const closeTooltip = (dom) => {
        if (dom) {
            if (dom.getAttribute('elementtype') === 'tr') {
                return
            }
            if (dom?._tippy) {
                dom._tippy.hide()
                return
            }
            closeTooltip(dom.parentElement)
        } else {
            return
        }
    }
    return (
        <Tippy placement="right" content={
            <div data-testid="dnd-row-icon-toolbar" style={{ display: "flex", alignItems: "center", padding: "2px 8px" }} ref={tooltipRef}>
                <span data-testid="delete-row-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
                    e.preventDefault()
                    const firstCell = [...path, 0]
                    Transforms.select(editor, firstCell)
                    deleteRow(e, { editor })
                    closeTooltip(tooltipRef.current)

                }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="delete-row-text" className={styles['scrte-table-tooltip-text']} >Delete Row</span>} ><TableDeleteRowIcon /></Tippy></span>
                <span data-testid="insert-row-before-icon" className={styles['table-column-toolbarIcon']} style={{ margin: '0px 10px' }} onMouseDown={(e) => {
                    e.preventDefault()
                    const firstCell = [...path, 0]
                    closeTooltip(tooltipRef.current)
                    Transforms.select(editor, firstCell)
                    insertRowBefore(e, { editor })
                    Transforms.select(editor, firstCell)

                }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="add-row-up-text" className={styles['scrte-table-tooltip-text']} >Add Row to the top  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+up')}</span></span>} ><TableAddRowBeforeIcon /></Tippy></span>
                <span data-testid="insert-row-after-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
                    e.preventDefault()
                    const firstCell = [...path, 0]

                    Transforms.select(editor, firstCell)
                    insertRowAfter(e, { editor })
                    closeTooltip(tooltipRef.current)
                    const nextCell = [...path.slice(0, path.length - 1), path[path.length - 1] + 1, 0]
                    Transforms.select(editor, nextCell)

                }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="add-row-down-text" className={styles['scrte-table-tooltip-text']} >Add Row to the bottom  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+down')}</span></span>} ><TableAddRowAfterIcon /></Tippy></span>

            </div>
        }
            trigger="click"
            interactive={true}
            className="tippy-box-light tippy-box-light--primary"
        >
            <span style={{ height: "100%" }} data-testid="row-dnd-icon">
                <svg
                    width={12}
                    height="100%"
                    viewBox="0 0 12 12"
                    fill="none"
                    style={{ opacity: "0.5" }}
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                >
                    <rect x={2.052} width={3.002} height={3.002} rx={1.501} fill="#647696" />
                    <rect
                        x={2.052}
                        y={4.499}
                        width={3.002}
                        height={3.002}
                        rx={1.501}
                        fill="#647696"
                    />
                    <rect
                        x={2.052}
                        y={8.998}
                        width={3.002}
                        height={3.002}
                        rx={1.501}
                        fill="#647696"
                    />
                    <rect x={6.946} width={3.002} height={3.002} rx={1.501} fill="#647696" />
                    <rect
                        x={6.946}
                        y={4.499}
                        width={3.002}
                        height={3.002}
                        rx={1.501}
                        fill="#647696"
                    />
                    <rect
                        x={6.946}
                        y={8.998}
                        width={3.002}
                        height={3.002}
                        rx={1.501}
                        fill="#647696"
                    />
                </svg>
            </span>
        </Tippy>

    )
}

export default SvgComponent
