//@ts-nocheck
import { Transforms, Editor, Node } from 'slate';

export const makeColumn = (editor, path, width) => {
    Transforms.wrapNodes(editor, {
        type: 'column',
        meta: {
            width
        },
        children: []
    }, {
        at: path
    })
}

export const makeRow = (editor, path) => {
    Transforms.wrapNodes(editor, {
        type: 'row',
        children: []
    }, {
        at: path
    })
}


export const removeRow = (editor, path) => {
    let rowPath = path.slice(0, path.length - 1);
    Transforms.unwrapNodes(editor, { at: rowPath })
    Transforms.unwrapNodes(editor, { at: rowPath })
}


export const isInsideRow = (editor, path) => {
    const [match] = Editor.nodes(editor, {
        at: path,
        match: n => n.type === 'row'
    });
    return !!match;
}


export const createNewColumn = (editor, sourcePath, destinationPath, side = 'right') => {
    const destinationPathLen = destinationPath.length;
    const newDestinationColPath = destinationPath.splice(0, destinationPathLen - 1);
    const newDestinationColPathLength = newDestinationColPath.length;
    if (side === 'right')
        newDestinationColPath[newDestinationColPathLength - 1]++;
    const rowPath = newDestinationColPath.slice(0, newDestinationColPathLength - 1)
    const row: any = Node.get(editor, rowPath)
    const columnCount = row.children.length + 1;
    const newColWidthRatio = 1 / columnCount;

    for (let i = 0; i < columnCount - 1; i++) {
        const currentColWidth = row.children[i].meta.width;
        Transforms.setNodes(editor, {
            meta: {
                width: currentColWidth * newColWidthRatio * (columnCount - 1)
            }
        }, {
            at: [...rowPath, i]
        })
    }
    Transforms.insertNodes(editor, {
        type: 'column',
        meta: {
            width: newColWidthRatio,
        },
        children: [{ text: '' }]
    }, {
        at: newDestinationColPath
    });
    Transforms.moveNodes(editor, {
        to: newDestinationColPath.concat(0),
        at: sourcePath
    });
}
