import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'


import { isInlineElement } from "../../../../utils/queries/index"
import Dndstyles from './customDndStyle.module.css'

export const ListItem = (props) => {
  return <li {...props.attributes} {...props.attrs}>{props.children}</li>
}

const beforeChildrenRender = (props) => {
  let { path, element } = props
  // Check if current element if first child
  if (path[path.length - 1] === 0) {
    //check if element is of type block
    if (!isInlineElement(element)) {
      props.DisableDND = true;
      props.DisableSelectionHalo = true;
    }
  }
}
Registry.register(
  {
    Component: ListItem,
    iconName: 'ri-list-check',
    title: 'List Item',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
    },

    category: elementCategories.LIST_ITEMS,
    subcategory: '',
    dndOptions: {
      DisableDND: false,
      DisableSelectionHalo: false,
      DisableAdd: true,
      dndStyles: Dndstyles,
    },
    beforeChildrenRender
  },
  { id: 'li', registry: 'element' }
)
export * from './deserializer'
export * from './withListItem'
