import { Editor } from 'slate'
import { getRegistry } from '../../../utils'

export const REMOVE_FORMATTING_ATTRS = {
  type: 'removeFormatting'
}

export function removeFormatting(event, editor) {
  event?.preventDefault()

  const selection = editor.selection || editor.savedSelection
  if (selection) {
    Object.entries(getRegistry('v2.leaf')).forEach(([format]) => {
      Editor.removeMark(editor, format)
    })
  }
}
