import { Transforms, Node } from 'slate'
import { Registry } from '@react-registry'

import { removeRow } from '../utils/gridActions'
import { ElementWithType } from '../../../../../../../utils/types';

export const withGrid = (editor) => {
    const { normalizeNode, apply } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;
        if (node.type === 'column' && node.meta.width === 1) {
            removeRow(editor, path);
            return;
        }

        if (node.type === 'column' && node.children.length === 0) {
            let rowPath = path.slice(0, path.length - 1);


            Transforms.removeNodes(editor, {
                at: path
            })

            let updatedRow: any = Node.get(editor, rowPath);


            // first case: only single element left
            if (updatedRow.children.length === 1) {
                removeRow(editor, path);
            }
            return;
        }

        // row with only text gets deleted
        if (node.type === 'row' && node.children.length === 1 && node.children[0].text === '' && node.children[0].type === undefined) {
            Transforms.removeNodes(editor, {
                at: path
            })
        }

        return normalizeNode(entry)
    }

    editor.apply = operation => {
        if (operation.type === 'remove_node' && operation.node.type === 'column') {
            let { path } = operation;
            let rowPath = path.slice(0, path.length - 1);
            let row: any = Node.get(editor, rowPath);
            if (row.type === 'row') {
                let removingCol: any = Node.get(editor, path);
                let removingColWidth = removingCol?.meta?.width;
                let newColCount = row.children.length - 1;
                let widthToBeAdded = removingColWidth / (newColCount);

                row.children.forEach((child, index) => {
                    if (child.children.length !== 0) {

                        Transforms.setNodes(editor, {
                            meta: {
                                width: child.meta.width + widthToBeAdded
                            }
                        } as ElementWithType, {
                            at: rowPath.concat(index)
                        })
                    }
                })
            }
        }
        return apply(operation);
    }

    return editor;
}


Registry.register(withGrid, {
    id: 'withGrid',
    registry: 'plugin'
})
