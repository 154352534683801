import { Registry } from '@react-registry'
import { deserialize as defaultDeserialize } from './deserializer/defaultDeserializer'
import { deserialize as googleDeserialize } from './deserializer/googleDocsSerializer'
import { deserialize as csDeserialize } from './deserializer/superchargedRTEDeserializer'
import { deserialize as wordDeserialize } from './deserializer/wordDeserializer'
import collapse from 'collapse-whitespace'
import { preInsert } from './utils'
import {detect} from 'detect-browser'

import { Transforms } from 'slate'
const pattern = /^docs-internal-guid/

const getUserAgentDetails = () => {
  const systemDetails = detect()
  return {
    platform: systemDetails.os,
    browser: systemDetails.name,
    browserVersion: systemDetails.version
  }
}

const getFragmentFromCopyPasteScript = (copyPasteConfig, docTypeFromScript, parsedHtml, wordPasteOptions, googlePasteOptions) => {
  try{
    const userAgentDetails = getUserAgentDetails()
    const docCopyPasteConfig = copyPasteConfig?.docCopyPasteConfig
    let configMatch = Array.from(docCopyPasteConfig.config).find((config: any) => {
      return  config.type === docTypeFromScript.docType && 
      config?.browser === userAgentDetails.browser && 
      userAgentDetails.platform.includes(config?.platform) && 
      config?.mode === docTypeFromScript.mode &&
      config.shouldVisit(parsedHtml)
    })
    if(configMatch){
     //@ts-ignore
     return configMatch.parser(parsedHtml, wordPasteOptions, googlePasteOptions)
    }
    return null
   } catch(err) {
    console.log("Error while copy pasting data using external script", err)
   }
}

const withHtml = (editor) => {
  const { insertData } = editor

  editor.insertData = (data) => {
    const customPasteOptions = editor.customPasteOptions || {}
    const copyPasteConfig = editor.copyPasteConfig || {}
    const googlePasteOptions = customPasteOptions && customPasteOptions.googleDoc || {}
    const wordPasteOptions = customPasteOptions && customPasteOptions.wordDoc || {}
    const html = data.getData('text/html')
    if (html) {
      let parsedHtml: any = new DOMParser().parseFromString(html, 'text/html').body
      collapse(parsedHtml)
      let docTypeFromScript = copyPasteConfig?.findDocType?.(parsedHtml)

      let fragment
      let googlePlainHtml = "";

      let isGoogleDoc = Array.from(parsedHtml.childNodes).some((node: any) => {
        if (node.nodeName === 'B' && pattern.test(node['id'])) {
          googlePlainHtml = node.innerHTML
          return true
        }
        return false
      })
      const isCSContent = parsedHtml.querySelector('[data-editor-type*="scrte"]')
      
      const isWord = parsedHtml.getElementsByTagName('O:P').length || (parsedHtml.querySelector('[class*="Mso"]' || parsedHtml.querySelector('[style*="mso"]')))

      const fragmentFromCopyPasteScript = getFragmentFromCopyPasteScript(copyPasteConfig, docTypeFromScript, parsedHtml, wordPasteOptions, googlePasteOptions)

      if(fragmentFromCopyPasteScript) {
        fragment = fragmentFromCopyPasteScript
     } else if (isGoogleDoc) {
        parsedHtml = new DOMParser().parseFromString(googlePlainHtml, 'text/html').body
        fragment = googleDeserialize(parsedHtml, googlePasteOptions)
      } else if (isCSContent) {
        fragment = csDeserialize(parsedHtml)
      } else if (isWord) {
        console.error("Error while loading word copy paste script. Running deserilizer from venus-components.")
        fragment = wordDeserialize(parsedHtml, wordPasteOptions)
      } else {
        fragment = defaultDeserialize(parsedHtml)
      }

      fragment = preInsert(editor, fragment)
      Transforms.insertFragment(editor, fragment)
      return
    }
    insertData(data)
  }
  return editor
}

Registry.register(withHtml, { id: 'withHtml', registry: 'plugin' })
