import React from 'react'
import { Youtube, Vimeo } from 'reactjs-social-embed'
import Facebook from './EmbedComponents/Facebook'
import { TikTok } from 'react-tiktok'
import Instagram from './EmbedComponents/Instagram'
import Twitter from './EmbedComponents/Twitter'
var VIMEO_MATCH_RE = /^(?:\/video|\/channels\/[\w-]+|\/groups\/[\w-]+\/videos)?\/(\d+)/

export const Embeds = (props) => {
  const { url, width, height } = props;
  let parsedURL = new URL(url)
  let result;
  if (parsedURL.hostname) {
    result = findEmbedType(parsedURL);
  }
  if (result) {
    switch (result.embed) {
      case 'facebook':
        return <Facebook type={result.type} show_text={true} url={result.url} width={width} height={height} />
      case 'instagram':
        return <Instagram url={result.url} width={width} />
      case 'twitter':
        return width ? <Twitter id={result.id} width={`${width}px`} /> : <Twitter id={result.id} />
      case 'youtube':
        return width ? <Youtube id={result.id} width={`${width}px`} /> : <Youtube parentClass="p-container" iframeClass="v-container" id={result.id} />
      case 'tiktok':
        return <TikTok url={result.url} />
      case 'vimeo':
        return width ? <Vimeo id={result.id} width={`${width}px`} height={`${height}px`} /> : <Vimeo id={result.id} />
      default:
        return null;
    }
  }
}

export const findEmbedType = (url: URL) => {
  if (url.hostname?.indexOf('facebook.com') > -1) {
    if (url.searchParams.has('href')) {
      return {
        embed: 'facebook',
        type: url.searchParams.get('href').includes('videos') ? 'video' : 'post',
        url: url.searchParams.get('href')
      }
    }
    if (url.pathname.split('/')[2] === 'posts') {
      return {
        embed: 'facebook',
        type: 'post',
        url: url.href
      }
    }
    else if (url.pathname.split('/')[2] === 'videos') {
      return {
        embed: 'facebook',
        type: 'video',
        url: url.href
      }
    }

  }
  else if (url.hostname?.indexOf('youtube.com') > -1) {
    if (url.pathname.indexOf('/watch') > -1) {
      return {
        embed: 'youtube',
        id: url.searchParams.get('v')
      }
    }
    return {
      embed: 'youtube',
      id: url.pathname.split('/')[2]
    }
  }
  else if (url.hostname?.indexOf('youtu.be') > -1) {
    return {
      embed: 'youtube',
      id: url.pathname.split('/')[1]
    }
  }
  else if (url.hostname?.indexOf('vimeo.com') > -1) {
    var match;
    return {
      embed: 'vimeo',
      id: (match = VIMEO_MATCH_RE.exec(url.pathname)) ? match[1] : null
    }
  }
  else if (url.hostname?.indexOf('instagram.com') > -1) {
    return {
      embed: 'instagram',
      url: url
    }
  }
  else if (url.hostname?.indexOf('twitter.com') > -1) {
    return {
      embed: 'twitter',
      id: url.searchParams.has('id') ? url.searchParams.get('id') : url.pathname.split('/')[3]
    }
  }
  else if (url.hostname?.indexOf('tiktok.com') > -1) {
    return {
      embed: 'tiktok',
      url: url.href
    }
  }
  else {
    return null;
  }
}






