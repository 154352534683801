//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'

import { calculateColSize } from './calculateColSize'
import { deleteTable } from './deleteTable'
import { elementCategories } from '../../elementCategories'
import { useSlate } from 'slate-react'
import { TableDeleteColumnIcon } from '../../../utils/Icon'


export const deleteCol = (event, { editor }) => {
  const [rowHead] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'th'
    },
    mode: 'lowest'
  })
  const [rowBody] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'td'
    },
    mode: 'lowest'
  })
  let row
  if (rowBody && !rowHead) {
    row = rowBody
  } else if (rowHead && !rowBody) {
    row = rowHead
  } else if (rowHead && rowBody) {
    row = rowBody
  }
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  if (row && table) {
    const selection = row[1][row[1].length - 1]
    const tableCol = calculateColSize(event, editor)
    if (tableCol > 1) {
      Array.from(table[0].children).forEach((tableChild, index0) => {
        Array.from(tableChild.children).forEach((child, index1) => {
          const path = [...table[1], index0, index1, selection]
          Transforms.removeNodes(editor, { at: path })
        })
      })
      let colWidths = table[0].attrs.colWidths.slice()
      colWidths.splice(selection, 1)
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table[0].attrs,
            colWidths,
            cols: tableCol - 1
          }
        },
        { at: table[1] }
      )
    } else {
      deleteTable(event, { editor })
    }
  }
}
const DeleteColButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: deleteCol,
        isDisable: { func: deleteColButtonDisabler, params: { editor } },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}
const deleteColButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  return !table
}
Registry.register(
  {
    iconName: <TableDeleteColumnIcon />,
    IngressComponent: DeleteColButton,
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
    },
    canCustomize: false,
    title: 'Delete column',
    isContentStackElement: true
  },
  {
    id: 'table-create-header',
    registry: 'v2.element'
  }
)
