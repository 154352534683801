import './Timepicker.css'

import React, {
  useEffect,
  useState
} from 'react'

import className from 'classnames'

import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import { defaultTimezone } from '../DateTime/helper'
import {
  ITimePicker,
  ITimeSlider
} from '../DateTime/interfaces'
import DSTTimeZoneList from '../DateTime/TimeZoneList'
import Icon from '../Icon2/Icon'
import TextInput from '../TextInput/TextInput'
// import { format } from 'date-fns'
import TimeZoneStandard from './TimeZoneStandard'

// import { min } from 'lodash'

const TimeSlider: React.FunctionComponent<ITimeSlider> = props => {
	const { name, value, onClick, min, max }: any = props
	return (
		<div className="TimePicker__time">
			<div className="TimePicker__time__title">{name}</div>
			<div className="TimePicker__time__box">
				<TextInput
					type='number'
					placeholder={'00'}
					value={props.value}
					className="TimePicker__time__value"
					min={min}
					max={max}
					onFocus={event => {
						event.preventDefault();
						event.stopPropagation();

						// let elems = document.querySelectorAll(".TimePicker__time--focus");
						// [].forEach.call(elems, function (el) {
						// 	el.classList.remove("TimePicker__time--focus");
						// });
						// event.target.parentElement.parentElement.classList.add("TimePicker__time--focus")
					}}
					onKeyDown={(e: any)=>{
						console.log((e.shiftKey && e.key === "Tab" && name === "Hour"),"yesppp")
						if(e.shiftKey && e.key === "Tab" && name === "Hour"){
							props.cancelHandler && props.cancelHandler()
						}
					}}
					// onBlur={event => {
					//   event.target.parentElement.classList.remove("TimePicker__time--focus")
					// }}
					onChange={event => {
						event.preventDefault()
						props.onClick(event.target.value)
					}}
				/>
				{/* <div className="TimePicker__time__actions flex">
					<div className="TimePicker__time__action flex-center" onClick={event => { event.preventDefault(); event.stopPropagation(); props.onClick(parseInt(value) + 1) }}><Icon icon="PlusBold" /></div>
					<div className="TimePicker__time__action flex-center" onClick={event => { event.preventDefault(); event.stopPropagation(); props.onClick(parseInt(value) - 1) }}><Icon icon="Minus" /></div>
				</div> */}
			</div>
		</div>
	)
}

const TimePicker2: React.FunctionComponent<ITimePicker> = props => {
	const {
		initialDate,
		onChange,
		viewType,
		onDone,
		onCancel,
		closeModal,
		useVisitorTimezone,
		useVisitorTimezoneOnChange,
		hasVisitorTimezoneCheckBox,
	} = props


	console.log('initialDate2 init', initialDate);

	const initialDate2 = initialDate ? initialDate : ''; //'00:00:00+0000';
	const getTimeZone = initialDate2.includes('+') ? initialDate2.split('+') : initialDate2.split('-');
	const getTimeArray = getTimeZone[0].split(':');
	const [time, setTime] = useState(initialDate2);
	const [hours, setHours] = useState(getTimeArray[0] ? parseInt(getTimeArray[0]) : '');
	const [minutes, setMinutes] = useState(getTimeArray[1] ? parseInt(getTimeArray[1]) : '');
	const [seconds, setSeconds] = useState(getTimeArray[2] ? parseInt(getTimeArray[2]) : '');

	const [zone, setZone] = useState(initialDate2 && initialDate2[0] ? (initialDate2.includes('+') ? `+${getTimeZone[1]}` : `-${getTimeZone[1]}`) : '');

	const [dstZone, setDstZone] = useState(zone ? defaultTimezone(zone) : '');
	const [useUserTimezone, setUseUserTimezone] = useState(Boolean(useVisitorTimezone));

	useEffect(() => {

		const selectedTime = `${(hours > 0 && hours < 10) ? '0' + hours : hours || '00'}:${(minutes > 0 && minutes < 10) ? '0' + minutes : minutes || '00'}:${(seconds > 0 && seconds < 10) ? '0' + seconds : seconds || '00'}${(zone) ? zone : ''}`;
		//`${(hours < 10) ? '0' + hours : hours}:${(minutes < 10) ? '0' + minutes : minutes}:${(seconds < 10) ? '0' + seconds : seconds}${(zone) ? zone : ''}`;
		//const selectedTime = `${(hours < 10) ? '0' + hours : hours}:${(minutes < 10) ? '0' + minutes : minutes}:${(seconds < 10) ? '0' + seconds : seconds}${(zone) ? zone : ''}`;

		if (onChange) {
			console.log('selectedTime set', selectedTime);
			onChange(selectedTime);
		}


	}, [hours, minutes, seconds, zone]);

	// const initDate = initialDate;
	// const currDate = new Date(initialDate).getTime() ? new Date(initialDate) : new Date(new Date().setHours(0, 0, 0, 0));

	// const gmt = new Date().toTimeString().split('GMT')[1].substr(0, 5)
	// const [zone, setZone] = useState(gmt)
	const currZoneObj = (zone) ? DSTTimeZoneList().find(singleZoneObj => zone === singleZoneObj.offsetString) : { label: '(GMT +5:30) India Standard Time - Kolkata', value: '+0530' };
	const [timeObj, setTimeObj] = useState(currZoneObj)

	const hourChangeHandler = (value: any) => {

		const hour = parseInt(value);

		if (hour >= 0 && hour < 24) {
			setHours(parseInt(value))
		}

		// console.log('hourChange handle viewType', viewType);
		// if (viewType == 'datetime') {
		// 	updateTimeOnChange();
		// }


		// const hour = parseInt(value)
		// let t = ''
		// if (hour >= 0 && hour < 24) {
		//   const formattedNumber = ('0' + hour).slice(-2)
		//   setHour(formattedNumber)
		//   t = `${formattedNumber}:${minute}:${second}${zone}`
		//   setTime(t)
		// } else if (hour > 23) {
		//   t = time
		// } else {
		//   setHour('00')
		//   t = `00:${minute}:${second}${zone}`
		//   setTime(t)
		// }
		// if (onChange) {
		//   onChange(t, timeObj)
		// }
	}
	const minuteChangeHandler = (value: any) => {

		const minute = parseInt(value);

		if (minute >= 0 && minute < 60) {
			setMinutes(parseInt(value))
		}

		// if (viewType == 'datetime') {

		// 	updateTimeOnChange();
		// }

		// const minute = parseInt(value)
		// let t = ''
		// if (minute >= 0 && minute < 60) {
		//   const formattedNumber = ('0' + minute).slice(-2)
		//   t = `${hour}:${formattedNumber}:${second}${zone}`
		//   setMinute(formattedNumber)
		//   setTime(t)
		// } else if (minute > 59) {
		//   t = time
		// } else {
		//   setMinute('00')
		//   t = `${hour}:00:${second}${zone}`
		//   setTime(t)
		// }
		// if (onChange) {
		//   onChange(t, timeObj)
		// }
	}
	const secondChangeHandler = (value: any) => {

		const second = parseInt(value);

		if (second >= 0 && second < 60) {
			setSeconds(parseInt(value))
		}

		// if (viewType == 'datetime') {
		// 	updateTimeOnChange();
		// }


		// const second = parseInt(value)
		// let t = ''
		// if (second >= 0 && second < 60) {
		//   const formattedNumber = ('0' + second).slice(-2)
		//   setSecond(formattedNumber)
		//   t = `${hour}:${minute}:${formattedNumber}${zone}`
		//   setTime(t)
		// } else if (second > 59) {
		//   t = time
		// } else {
		//   t = `${hour}:${minute}:00${zone}`
		//   setSecond('00')
		//   setTime(t)
		// }
		// if (onChange) {
		//   onChange(t, timeObj)
		// }
	}
	const zoneHandler = (zone: any, obj: any) => {

		console.log('hours CHange Handleer zoneHandler', zone, obj);

		setZone(obj.offsetString)
		setDstZone(zone)
		setTimeObj(obj);
		// if (viewType == 'datetime') {
		// 	updateTimeOnChange();
		// }

		// const timeValue = time.includes('+') ? time.split('+')[0] : time.split('-')[0]
		// console.log("Zone changed ", zone);
		// const t = timeValue + zone
		// setTime(t)
		// setZone(zone)
		// setTimeObj(obj)
		// if (onChange) {
		//   onChange(t, obj)
		// }
	}

	const doneHandler = (event: any) => {


		console.log('hours CHange Handleer doneEvent', hours, minutes, seconds);

		const selectedTime = `${(hours > 0 && hours < 10) ? '0' + hours : hours || '00'}:${(minutes > 0 && minutes < 10) ? '0' + minutes : minutes || '00'}:${(seconds > 0 && seconds < 10) ? '0' + seconds : seconds || '00'}${(zone) ? zone : ''}`;
		//`${(hours < 10) ? '0' + hours : hours}:${(minutes < 10) ? '0' + minutes : minutes}:${(seconds < 10) ? '0' + seconds : seconds}${zone}`;
		//const selectedTime = `${(hours < 10) ? '0' + hours : hours}:${(minutes < 10) ? '0' + minutes : minutes}:${(seconds < 10) ? '0' + seconds : seconds}${zone}`;
		setTime(selectedTime);

		console.log('hours CHange Handleer final time', selectedTime);

		onDone(selectedTime, timeObj);
		if (closeModal) {
			closeModal();
		}
	};

	const cancelHandler = (event: any) => {
		if (onCancel) {
			onCancel(time);
		}
		if (closeModal) {
			closeModal();
		}
	};

	const handlePickerClick = (event: any) => {
		event.stopPropagation()
		if (!event.target.parentElement.classList.contains('TimePicker__time__value') && !event.target.classList.contains('TimePicker__action') && !event.target.parentElement.classList.contains('TimePicker__action') && !event.target.parentElement.parentElement.classList.contains('TimePicker__action')) {
			let elems = document.querySelectorAll(".TimePicker__time--focus");
			[].forEach.call(elems, function (el) {
				el.classList.remove("TimePicker__time--focus");
			});
		}
	}

	const useVisitorTimezoneChangedHandler = () => {
		useVisitorTimezoneOnChange(!useUserTimezone);
		setUseUserTimezone(!useUserTimezone);
	}

	return (
		<>

			<div className={className('TimePicker', {
				'TimePicker__date-time': viewType === 'datetime'
			})} onClick={handlePickerClick}>
				<div className="TimePicker--time">
					<div className="TimePicker__title">Choose Time</div>
					<div className="flex TimePicker__container ">
						<TimeSlider
							name="Hour"
							value={hours}
							onClick={hourChangeHandler}
							min={0}
							max={23}
							cancelHandler={cancelHandler}
						/>
						<TimeSlider
							name="Minute"
							value={minutes}
							onClick={minuteChangeHandler}
							min={0}
							max={59}
						/>
						<TimeSlider
							name="Second"
							value={seconds}
							onClick={secondChangeHandler}
							min={0}
							max={59}
						/>
					</div>
				</div>

				<div className="TimePicker--timezone">
					<div className="TimePicker__title">Choose Time Zone</div>
					{<TimeZoneStandard dstZone={dstZone} zone={zone} onChange={zoneHandler} disableTzDropdown={useUserTimezone} />}
				</div>

				{hasVisitorTimezoneCheckBox &&
					<div className='TimePicker--useUserTimezone'>
						<div className="Checkbox-wrapper">
							<Checkbox
								onChange={useVisitorTimezoneChangedHandler}
								label={'User\'s timezone'}
								checked={useUserTimezone}
							/>
							<Icon className='pl-10 TimePicker__icon' icon='InformationOutlineSmall' />
						</div>
					</div>
				}

				{viewType !== 'datetime' &&
					<div className="TimePicker__actions flex-right">
						<Button buttonType="primary" onClick={doneHandler} icon="CheckedWhite" >Done</Button>
						<Button buttonType="light" onClick={cancelHandler}>Cancel</Button>
					</div>
				}
			</div>
		</>
	)
}
export default TimePicker2
