import moment from 'moment'
import groupBy from 'lodash/groupBy'

import {
  operatorByValue,
  usersKey,
  dateKey,
  languageKey,
  tagKey,
  environmentKey,
  contentTypeKey,
  entriesQueryFormat,
  uidKey,
  assetTypeKey,
  workflowStageKey,
  notPublishedEnvObj
} from './constants'


export const searchInStaticData = (allQueryData, inputValue) => {
  const searchedData = allQueryData.map((data) => {
    const options = data.options.filter((opt) => opt.label.toLowerCase().includes(inputValue.toLowerCase()))
    return { ...data, options }
  })

  const count = searchedData.reduce((sum, data) => sum + data.options.length, 0)
  return { data: searchedData, count }
}

export const getFileSizes = ({ inputValue }) => {
  if (inputValue) {
    const num = +inputValue
    const options = [
      { label: `${num} KB`, value: num * 1024, enteredValue: inputValue },
      { label: `${num} MB`, value: num * Math.pow(1024, 2), enteredValue: inputValue },
    ]

    return options
  }
  // const regexNum = /^\d+$/
  // if (regexNum.test(inputValue)) {
  //   const num = parseInt(inputValue)
  // }

  return []
}

export const getFileSizeLabel = (value) => {
  const kbValue = value / 1024
  const mbValue = value / Math.pow(1024, 2)

  const isFloat = (value) => {
    return value % 1 !== 0
  }

  if (mbValue >= 1) {
    return `${isFloat(mbValue) ? mbValue.toFixed(1) : mbValue} MB`
  }

  return `${isFloat(kbValue) ? kbValue.toFixed(1) : kbValue} KB`
}

const buildQueryForContentType = (value, operator, queryObj) => {
  if (value[0].type === 'text') {
    if (operator[0].value === '$ne') {
      queryObj.$and.push({ _content_type_uid: { $ne: value[0].value } })
    } else {
      queryObj.$and.push({ _content_type_uid: { $regex: value[0].value } })
    }
  } else {
    if (operator[0].value === 'contains') {
      queryObj.$and.push({ _content_type_uid: { $regex: value[0].value } })
    } else {
      queryObj.$and.push({ _content_type_uid: { [operator[0].value]: value[0].value } })
    }
  }
}

const buildInQueryForCt = (ctQueryArray, queryObj) => {
  let valuesArray = []
  ctQueryArray.forEach((query) => {
    const { value, key, operator } = query
    if (Array.isArray(value) && value.length) {
      valuesArray.push(value[0].value)
    }
  })
  if (valuesArray.length) {
    queryObj['_content_type_uid'] = { $in: valuesArray }
  }
}


/*
 **this method is being used in adv querycase also ,
 ** when do chaneg in resposne format handle there also
 */
export const queryBuilderEntries = ({ queryArray, action = '', queryCase = '' }) => {
  console.log('queryBuilderEntries -> queryArray', queryArray, action)

  let queryObj: any = { $and: [] }
  let search = ''

  //filter out query with eq operator to create Query with $in operator
  let [ctQueryArray, publishedEnvEqualQuery, tagsEqualQuery, workflowStageQuery, filteredQueryArray] = queryArray.reduce(
    ([ctQueryArray, publishedEnvEqualQuery, tagsEqualQuery, workflowStageQuery, filteredQueryArray], query) => {
      if (query.key[0].value === '_content_type_uid') {
        return [[...ctQueryArray, query], publishedEnvEqualQuery, tagsEqualQuery, workflowStageQuery, filteredQueryArray]
      }
      if (query.key[0].value === "_publish_environment" && query.operator[0].value === "$eq") {
        return [ctQueryArray, [...publishedEnvEqualQuery, query], tagsEqualQuery, workflowStageQuery, filteredQueryArray]
      }

      if (query.key[0].value === "tags" && query.operator[0].value === "$eq") {
        return [ctQueryArray, publishedEnvEqualQuery, [...tagsEqualQuery, query], workflowStageQuery, filteredQueryArray]
      }

      if (query.key[0].value === "_workflow" && query.operator[0].value === "$eq") {
        return [ctQueryArray, publishedEnvEqualQuery, tagsEqualQuery, [...workflowStageQuery, query], filteredQueryArray]
      }

      return [ctQueryArray, publishedEnvEqualQuery, tagsEqualQuery, workflowStageQuery, [...filteredQueryArray, query]]
    },
    [[], [], [], [], []]
  )

  console.log('==> After reduce query test', { ctQueryArray, publishedEnvEqualQuery, tagsEqualQuery, workflowStageQuery, filteredQueryArray })

  //handling table filter contentType case with $in
  if (action === 'tableFilterAppendAll' || action === 'tableFilterClearAll') {
    buildInQueryForCt(ctQueryArray, queryObj)
  } else {
    //from Global search basic query, handling ct query with eq case using $in
    if (queryCase === 'basicQuery') {
      let [ctEqualQueryArray, remainingCtQueryArray] = ctQueryArray.reduce(
        ([ctEqualQueryArray, remainingCtQueryArray], query) =>
          query.key[0].value === '_content_type_uid' && query.operator[0].value === "$eq"
            ? [[...ctEqualQueryArray, query], remainingCtQueryArray]
            : [ctEqualQueryArray, [...remainingCtQueryArray, query]],
        [[], []]
      )
      if (ctEqualQueryArray.length) {
        buildInQueryForCt(ctEqualQueryArray, queryObj)
      }
      //Assigning all non equal ct query so that can created using buildQueryForContentType
      ctQueryArray = remainingCtQueryArray
    }

    //creating query in advance query view 
    ctQueryArray.forEach((query) => {
      if (query.isQueryCompleted) {
        const { key, operator, value } = query
        buildQueryForContentType(value, operator, queryObj)
      }
    })
  }

  //creating all $in queries
  if (publishedEnvEqualQuery.length) {
    let notPubEnvCaseCheck = false

    publishedEnvEqualQuery = publishedEnvEqualQuery.filter(query => {
      const { value = [] } = query
      if (value.length && value[0].value === notPublishedEnvObj.value) {
        notPubEnvCaseCheck = true
        return false
      }
      return true
    })

    const allEnvs = publishedEnvEqualQuery.map(query => query.value[0].value)

    if (notPubEnvCaseCheck) {
      const notPubQuery: any = { $or: [{ _publish_details: { $exists: false } }] }

      if (allEnvs.length) {
        notPubQuery.$or.push({ _publish_environment: { $in: allEnvs } })
      }
      queryObj.$and.push(notPubQuery)
    }
    else if (allEnvs.length) {
      queryObj.$and.push({ _publish_environment: { $in: allEnvs } })
    }
  }

  if (tagsEqualQuery.length) {
    const allTags = tagsEqualQuery.map(query => query.value[0].value)
    queryObj.$and.push({ tags: { $in: allTags } })
  }

  if (workflowStageQuery.length) {
    const allStages = workflowStageQuery.map(query => query.value[0].value)
    queryObj.$and.push({ _workflow: { $in: allStages } })
  }


  //creating query for all remaining query object
  filteredQueryArray.forEach((query, index) => {
    if (query.isQueryCompleted && query.queryType !== 'localizedInLabel') {
      const { key, operator, value, queryType } = query

      if (queryType === 'inEntries' || queryType === 'textLabel') {
        search = value[0].value
      } else if (queryType === 'numberLabel') {
        let query = { [key[0].value]: { [operator[0].value]: parseInt(value[0].value) } }
        queryObj.$and.push(query)
      } else if (queryType === 'environmentLabel') {
        if (!queryObj._content_type_uid) {
          queryObj._content_type_uid = '$all'
        }
        const envOpsMapping = {
          $eq: '$in',
          $ne: '$nin',
        }
        const env_operator = envOpsMapping[operator[0].value]
        queryObj.$and.push({ [key[0].value]: { [env_operator]: [value[0].value] } })
      } else if (queryType === 'date') {
        if (operator[0].value === '$eq') {
          let startTime = moment(value[0].value).startOf('day').toISOString()
          let endTime = moment(value[0].value).endOf('day').toISOString()
          let startTimeQuery = { [key[0].value]: { $gte: startTime } }
          let endTimeQuery = { [key[0].value]: { $lte: endTime } }
          queryObj.$and.push(startTimeQuery)
          queryObj.$and.push(endTimeQuery)
        } else if (operator[0].value === '$lte') {
          let startTime = moment(value[0].value).endOf('day').toISOString()

          let query = { [key[0].value]: { [operator[0].value]: startTime } }
          queryObj.$and.push(query)
        } else {
          let startTime = moment(value[0].value).startOf('day').toISOString()

          let query = { [key[0].value]: { [operator[0].value]: startTime } }
          queryObj.$and.push(query)
        }
      } else if (queryType === 'languageLabel') {
        let query = { [key[0].value]: { [operator[0].value]: value[0].value } }

        const localizeQuery = filteredQueryArray[index + 1]
        if (localizeQuery && localizeQuery.queryType === 'localizedInLabel') {
          const localizeKey = localizeQuery.key[0]
          const value = (localizeQuery.value[0] && localizeQuery.value[0].value) || []
          const localizedIds = value.map((v) => v.value)
          if (!localizedIds.includes('$any')) {
            const operatorValue = localizeQuery.operator[0].value === '$eq' ? '$in' : '$nin'
            query[key[0].value][localizeKey.value] = { [operatorValue]: localizedIds }
          }
        }
        queryObj.$and.push(query)
      } else if (queryType === 'referenceLabel') {
        let query: any = { [key[0].value]: { [operator[0].value]: value[0].value } }
        query.contentTypeUid = value[0].ctUid
        queryObj.$and.push(query)
      } else if (operator[0].value !== 'contains') {
        let query = { [key[0].value]: { [operator[0].value]: value[0].value } }
        queryObj.$and.push(query)
      } else if (operator[0].value === 'contains') {
        let query = { [key[0].value]: { $regex: value[0].value } }
        queryObj.$and.push(query)
      }

      // else if (queryType === 'workflowStageLabel' || queryType === 'workflowLabel') {
      //   let query: any = { [key[0].value]: { [operator[0].value]: value[0].value } }
      //   query.workflowUid = value[0].workflowUid
      //   queryObj.$and.push(query)
      // }
    }
  })

  if (!queryObj.$and.length) {
    const { $and, ...rest } = queryObj
    queryObj = rest
  }

  return { queryObject: !queryObj.$and && !queryObj._content_type_uid ? '' : queryObj, search }
}

export const getLocalizedOptions = ({ languages, selectedLanguageValue }) => {
  let itrCount = 0

  const languagesDict = languages.reduce((acc, current) => {
    acc[current.value] = current
    return acc
  }, {})

  const options = [{ label: 'Any Locales', value: '$any' }]

  let currentLocale = selectedLanguageValue

  while (currentLocale && itrCount < languages.length) {
    options.push(languagesDict[currentLocale])
    currentLocale = languagesDict[currentLocale].fallback_locale
    itrCount++
  }

  return options
}

const entriesFormat = {
  key: [
    {
      label: 'Text',
      value: '_text_entries',
    },
  ],
  operator: [
    {
      name: 'Contains',
      icon: 'Contains',
      value: 'contains',
    },
  ],
  value: [
    {
      label: 'test',
      value: 'test',
      type: 'text',
    },
  ],
  isQueryCompleted: true,
  queryType: 'inEntries',
  nextQuery: 'inEntries',
}

//in case of tags name and values are same
//in case of env name and values are same

const parseQueryForObject = ({ queryKey, query, users, languages, queryArray, contentTypes, workflowStages }) => {
  try {

    if (queryKey === '$or' && Array.isArray(query['$or'])) {
      let notPubEnvCaseCheck = false;
      let allEnvArray = []

      const notPublishedArray = query['$or']
      notPublishedArray.forEach((query = {}) => {
        const pubQueryKey = (Object.keys(query) || [])[0]
        if (pubQueryKey === '_publish_details') {
          notPubEnvCaseCheck = true
        }
        else if (pubQueryKey === '_publish_environment') {
          const valueArray = query[pubQueryKey]?.$in || []
          allEnvArray = [...allEnvArray, ...valueArray]
        }
      })

      if (notPubEnvCaseCheck) {
        const sampleQuery = entriesQueryFormat['_publish_environment']
        const rawQuery = {
          ...sampleQuery,
          operator: [operatorByValue['$eq']],
          value: [notPublishedEnvObj],
        }
        queryArray.push(rawQuery)
      }

      if (Array.isArray(allEnvArray)) {
        allEnvArray.forEach(envValue => {
          const sampleQuery = entriesQueryFormat['_publish_environment']
          const rawQuery = {
            ...sampleQuery,
            operator: [operatorByValue['$eq']],
            value: [{ label: envValue, envValue }],
          }
          queryArray.push(rawQuery)
        })
      }
    }

    if (usersKey.includes(queryKey)) {
      const value = Object.values(query[queryKey])[0]
      const operator = Object.keys(query[queryKey])[0]

      const foundUser = users.find((user) => user.value === value)
      const sampleQuery = entriesQueryFormat[queryKey]

      const rawQuery = {
        ...sampleQuery,
        operator: [operatorByValue[operator]],
        value: [{ label: foundUser ? foundUser.label : value, value }],
      }
      queryArray.push(rawQuery)
    }

    if (languageKey === queryKey) {
      const value = Object.values(query[queryKey])[0]
      const operator = Object.keys(query[queryKey])[0]

      const foundLanguage = languages.find((lang) => lang.value === value)
      const sampleQuery = entriesQueryFormat[queryKey]
      const rawQuery = {
        ...sampleQuery,
        operator: [operatorByValue[operator]],
        value: [{ label: foundLanguage ? foundLanguage.label : value, value }],
      }
      queryArray.push(rawQuery)

      const selectedLanguage = value
      if (query.locale['$localization'] && typeof query.locale['$localization'] === 'object') {
        const localizeQuery = query.locale['$localization']
        const operator = Object.keys(localizeQuery)[0]
        const value: any = Object.values(localizeQuery)[0]

        let localizedValue = []

        value.forEach((localize) => {
          const foundLanguage = languages.find((lang) => lang.value === localize) || { label: localize, value: localize }
          localizedValue.push(foundLanguage)
        })

        const label = localizedValue.length === 1 ? localizedValue[0].label : `${localizedValue.length} Selected`

        const sampleLocalizeQuery = entriesQueryFormat['$localization']
        const rawQuery = {
          ...sampleLocalizeQuery,
          operator: [operatorByValue[operator]],
          value: [{ label, value: localizedValue }],
          selectedLanguage,
        }
        queryArray.push(rawQuery)
      } else {
        const sampleLocalizeQuery = entriesQueryFormat['$localization']
        queryArray.push({ ...sampleLocalizeQuery, selectedLanguage })
      }
    }

    if (tagKey === queryKey) {
      const value = Object.values(query[queryKey])[0]
      if (Array.isArray(value) && value.length) {
        value.forEach(singleTagValue => {
          const operator = Object.keys(query[queryKey])[0]
          const sampleQuery = entriesQueryFormat[queryKey]
          const rawQuery = {
            ...sampleQuery,
            operator: [operatorByValue[operator]],
            value: [{ label: singleTagValue, value: singleTagValue }],
          }
          queryArray.push(rawQuery)
        })
      } else {
        const operator = Object.keys(query[queryKey])[0]
        const sampleQuery = entriesQueryFormat[queryKey]
        const rawQuery = {
          ...sampleQuery,
          operator: [operatorByValue[operator]],
          value: [{ label: value, value }],
        }
        queryArray.push(rawQuery)
      }
    }

    if (workflowStageKey === queryKey) {
      const value = Object.values(query[queryKey])[0]
      if (Array.isArray(value) && value.length) {
        value.forEach(singleStageValue => {
          const operator = Object.keys(query[queryKey])[0]
          const sampleQuery = entriesQueryFormat[queryKey]

          let workflowStageLabel = singleStageValue
          const foundStages = workflowStages.find(stage => stage.value === singleStageValue)
          workflowStageLabel = foundStages ? foundStages.label : singleStageValue

          const rawQuery = {
            ...sampleQuery,
            operator: [operatorByValue[operator]],
            value: [{ label: workflowStageLabel, value: singleStageValue }],
          }
          queryArray.push(rawQuery)
        })
      } else {
        const operator = Object.keys(query[queryKey])[0]
        const sampleQuery = entriesQueryFormat[queryKey]

        let workflowStageLabel = value
        const foundStages = workflowStages.find(stage => stage.value === value)
        workflowStageLabel = foundStages ? foundStages.label : value

        const rawQuery = {
          ...sampleQuery,
          operator: [operatorByValue[operator]],
          value: [{ label: workflowStageLabel, value }],
        }
        queryArray.push(rawQuery)
      }
    }

    if (uidKey === queryKey) {
      const value = Object.values(query[queryKey])[0]
      const operator = Object.keys(query[queryKey])[0]
      const sampleQuery = entriesQueryFormat[queryKey]
      const rawQuery = {
        ...sampleQuery,
        operator: [operatorByValue[operator]],
        value: [{ label: value, value, type: 'text' }],
      }
      queryArray.push(rawQuery)
    }
    //todo handle equal case
    if (dateKey.includes(queryKey)) {
      const value = Object.values(query[queryKey])[0]
      const operator = Object.keys(query[queryKey])[0]
      const sampleQuery = entriesQueryFormat[queryKey]
      const localDate = moment(value).format('YYYY-MM-DD')
      const rawQuery = {
        ...sampleQuery,
        operator: [operatorByValue[operator]],
        value: [{ label: localDate, value: localDate }],
      }
      queryArray.push(rawQuery)
    }

    if (contentTypeKey === queryKey) {
      const value: any = Object.values(query[queryKey])[0] || ' '
      const operator = Object.keys(query[queryKey])[0]
      // console.log('contentTypeKey -> value,  operator', value, operator)
      const sampleQuery = entriesQueryFormat[queryKey]

      let foundContentType = contentTypes.find((ct) => ct.value === value)

      let parsedValue: any
      if (foundContentType) {
        parsedValue = [{ label: foundContentType.label, value }]
      } else {
        parsedValue = [{ label: value, value, type: 'text' }]
      }
      const rawQuery = {
        ...sampleQuery,
        operator: [operatorByValue[operator]],
        value: parsedValue,
      }
      queryArray.push(rawQuery)

      // foundContentType = foundContentType || {}
      // if (operator === '$regex') {
      //   const actualValue = value.split('.*')[1]
      //   const rawQuery = {
      //     ...sampleQuery,
      //     operator: [operatorByValue.contains],
      //     value: [{ label: actualValue, value: actualValue, type: 'text' }]
      //   }
      //   queryArray.push(rawQuery)
      // } else {

      // }
    }
    if (environmentKey.includes(queryKey)) {
      const valueArray: any = Object.values(query[queryKey])[0]

      if (Array.isArray(valueArray)) {
        valueArray.forEach(envValue => {
          const operator = Object.keys(query[queryKey])[0]
          const sampleQuery = entriesQueryFormat[queryKey]
          const rawQuery = {
            ...sampleQuery,
            operator: [operatorByValue[operator]],
            value: [{ label: envValue, envValue }],
          }
          queryArray.push(rawQuery)
        })
      }
    }
  } catch (error) {
    console.log("parseQueryForObject error", error)
  }
}

//handle regex another patern regex:value
//hanlde operator equeal case
export const queryParserEntries = async (queryObject, search_text, fetchData, cacheResponse?) => {
  try {
    let queryArray = []

    const { $and } = queryObject || {}
    const andArray = $and

    if (search_text) {
      queryArray.push({
        ...entriesFormat,
        value: [{ label: search_text, value: search_text, type: 'text' }],
      })
    }

    if (!queryObject || !Array.isArray(andArray)) {
      return queryArray
    }

    let userResponse = []
    let langResponse = []
    let ctResponse = []
    let workflowStagesResponse = []

    if (cacheResponse) {
      userResponse = cacheResponse.userResponse
      langResponse = cacheResponse.langResponse
      ctResponse = cacheResponse.ctResponse
      workflowStagesResponse = cacheResponse.workflowStagesResponse
    } else {
      const response = await fetchDataForQuery(andArray, fetchData)
      userResponse = response.userResponse
      langResponse = response.langResponse
      ctResponse = response.ctResponse
      workflowStagesResponse = response.workflowStagesResponse
    }

    for (let query of andArray) {
      const queryKey = Object.keys(query)[0]
      parseQueryForObject({
        queryKey,
        query,
        users: userResponse,
        languages: langResponse,
        queryArray,
        contentTypes: ctResponse,
        workflowStages: workflowStagesResponse,
      })
    }

    return queryArray
  } catch (error) {
    console.log('queryParserEntries', error)
    throw error
  }
}

export const setCounterForAdvanceQuery = (queryArray, counter) => {
  queryArray.map((obj) => {
    counter = counter + 1
    obj.cursorY = counter
    if (obj.queryArray.length) {
      obj.queryCursorY = counter + 1
      counter = counter + 1
    }
    if (obj.children.length) {
      let updatedCounter = setCounterForAdvanceQuery(obj.children, counter)
      counter = updatedCounter
    }
  })
  return counter
}

// when no querystring defaultQueryType returned to handle search_text
export const getQueryType = (queryString, selectedModule) => {
  let defaultQueryType = 'basicQuery'
  if (!queryString) return defaultQueryType

  const andRegex = /\"\$and\":/g
  const orRegex = /\"\$or\":/g

  let ands = queryString.match(andRegex) || []
  let ors = queryString.match(orRegex) || []

  let notPublishMatch = queryString.match('_publish_details') || []

  if (ors.length === 1 && notPublishMatch.length >= 1 && ands.length <= 1) {
    return 'basicQuery'
  }

  if (ands.length <= 1 && !ors.length) {
    return 'basicQuery'
  }

  if (ands.length > 1 || ors.length) {
    return 'advanceQuery'
  }

  //checking for old query 
  const parsedQuery = parseSafeJSON(queryString)
  const queryObjectArray = parsedQuery.$and || parsedQuery.$or
  const contentTypeQuery = parsedQuery._content_type_uid

  if (selectedModule === 'entries' && contentTypeQuery && Array.isArray(queryObjectArray)) {
    return 'advanceQuery'
  }


  return defaultQueryType
}

export const validateBasicQuery = (queryArray, selectedModule) => {
  let isValid = true
  let errorMessage = ''

  queryArray.forEach((query) => {
    if (query.queryType === 'localizedInLabel' && !query.value.length) {
      isValid = false
      errorMessage = 'Please select Localized In'
    }
  })

  return {
    isValid,
    errorMessage,
  }
}

export const isEmpty = (value) => {
  let empty = true

  if (Array.isArray(value)) {
    empty = value.length === 0
  } else if (typeof value === 'object' && value !== null) {
    empty = Object.values(value).length === 0
  } else {
    empty = !value
  }
  return empty
}

export const isEmptyFilters = (filters: any) => {
  return filters.every((filter) => isEmpty(filter.value))
}

export const truncate = (input = '', maxLen = 24) => {
  const truncatedText = input.length > maxLen ? `${input.substring(0, maxLen)}...` : input
  const isOverflow = input.length > maxLen

  return {
    truncatedText,
    isOverflow,
  }
}

export const getPopulatedQueryUids = (queryObjectArray: any[]) => {
  const users = []
  const contentTypes = []
  const languages = []
  const types = []
  const workflowStages = []

  if (!Array.isArray(queryObjectArray)) {
    return { users, contentTypes, languages, types }
  }

  for (let query of queryObjectArray) {
    const queryKey = Object.keys(query)[0]

    if (usersKey.includes(queryKey)) {
      const value = Object.values(query[queryKey])[0]
      if (!users.includes(value)) {
        users.push(value)
      }
    }

    if (assetTypeKey.includes(queryKey)) {
      const value = Object.values(query[queryKey])[0]
      if (!types.includes(value)) {
        types.push(value)
      }
    }

    if (workflowStageKey === queryKey) {
      const value = Object.values(query[queryKey])[0]
      if (!workflowStages.includes(value)) {
        workflowStages.push(value)
      }
    }

    if (languageKey === queryKey) {
      const value = Object.values(query[queryKey])[0]
      if (!languages.includes(value)) {
        languages.push(value)
      }
    }

    if (contentTypeKey === queryKey && typeof query[queryKey] === 'object') {
      const value = Object.values(query[queryKey])[0]
      if (!contentTypes.includes(value)) {
        contentTypes.push(value)
      }
    }

    if (queryKey === '$or' || queryKey === '$and') {
      const queryArray = query[queryKey]
      const valuesObject = getPopulatedQueryUids(queryArray)

      users.push(...valuesObject.users)
      contentTypes.push(...valuesObject.contentTypes)
      languages.push(...valuesObject.languages)
      types.push(...valuesObject.types)
      workflowStages.push(...valuesObject.workflowStages)
    }
  }

  return { users, contentTypes, languages, types, workflowStages }
}

export const fetchDataByValue = async ({ users = [], types = [], contentTypes = [], languages = [], workflowStages = [], fetchData }) => {
  try {
    let uniqCts = Array.from(new Set(contentTypes))
    let uniqWorkflowStages = Array.from(new Set(workflowStages))

    const [userResponse, typesResponse, ctResponse, langResponse, workflowStagesResponse] = await Promise.all([
      users.length && fetchData.fetchUsers({}),
      types.length && fetchData.fetchAssetTypes({}),
      contentTypes.length && fetchData.fetchContentTypes({ query: { uid: { $in: uniqCts } } }),
      languages.length && fetchData.fetchLanguages({}),
      workflowStages.length && fetchData.fetchWorkflowStage({ query: { uid: { $in: uniqWorkflowStages } } })
    ])

    const response = {
      userResponse: (userResponse && userResponse.data) || [],
      typesResponse: (typesResponse && typesResponse.data) || [],
      ctResponse: (ctResponse && ctResponse.data) || [],
      langResponse: (langResponse && langResponse.data) || [],
      workflowStagesResponse: (workflowStagesResponse && workflowStagesResponse.data) || [],
    }
    return response
  } catch (error) {
    throw error
  }
}

export const fetchDataForQuery = async (rawQueryObjectArray, fetchData) => {
  try {
    const { users, types, contentTypes, languages, workflowStages } = await getPopulatedQueryUids(rawQueryObjectArray)
    const response = await fetchDataByValue({ users, types, contentTypes, languages, workflowStages, fetchData })
    return response
  } catch (error) {
    console.log('fetchDataForQuery error', error)
    throw error
  }
}

export const parseSafeJSON = (json) => {
  let parsed
  try {
    parsed = JSON.parse(json)
  } catch (e) { }

  return parsed
}

export const groupEntriesByCt = (response) => {
  const inReference = {
    group: '',
    queryType: 'inReference',
    type: 'value',
    options: []
  }

  let data = []
  if (response.count) {
    let groupedObj = groupBy(response.data, (data) => {
      return [data.ctUid]
    })

    Object.keys(groupedObj).forEach((k) => {
      const valueArray = groupedObj[k]
      data.push({ ...inReference, group: valueArray[0].ctTitle, options: valueArray })
    })
    return data
  }
  return data
}

export const getOS = () => {

  let windowNavigator: any = window.navigator

  let userAgent = windowNavigator.userAgent
  let platform = windowNavigator.platform || windowNavigator?.userAgentData?.platform
  let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  let iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}





// const buildInQueryForLanguage = (langEqualQueryArray, queryObj) => {
//   let valuesArray = []
//   langEqualQueryArray.forEach((query) => {
//     if (query.isQueryCompleted && query.queryType !== 'localizedInLabel') {
//       const { value } = query
//       if (Array.isArray(value) && value.length) {
//         valuesArray.push(value[0].value)
//       }
//     }
//   })
//   if (valuesArray.length) {
//     queryObj.$and.push({ locale: { $in: valuesArray } })
//   }
// }

// let [langEqualQueryArray, remainingFilteredQueryArray] = filteredQueryArray.reduce(
//   ([langEqualQueryArray, remainingFilteredQueryArray], query) =>
//     query.key[0].value === 'locale' && query.operator[0].value === "$eq"
//       ? [[...langEqualQueryArray, query], remainingFilteredQueryArray]
//       : [langEqualQueryArray, [...remainingFilteredQueryArray, query]],
//   [[], []]
// )
// if (langEqualQueryArray.length) {
//   buildInQueryForLanguage(langEqualQueryArray, queryObj)
// }

// filteredQueryArray = remainingFilteredQueryArray