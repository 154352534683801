import { IEditorRequestProps } from "../../../utils/types";

const offset = 7

export const handlePopupPosition = (sourceDOM: HTMLDivElement, targetDOM: Element | Range, editorMetadata: IEditorRequestProps, isNewDiscussion: boolean, isRangeComment: boolean) => {
  const targetDiscussionPosition = targetDOM.getBoundingClientRect()

  let windowContainerPosition = document.body.getBoundingClientRect();
  let overlayHeight = sourceDOM.clientHeight
  let viewportBottom = windowContainerPosition.bottom
  let viewportRight = windowContainerPosition.right

  if (isNewDiscussion) {
    overlayHeight = 225
  }

  let [topPosition, rightPosition] = [targetDiscussionPosition.top, viewportRight - targetDiscussionPosition.right - offset];
  let bottomPosition
  if (editorMetadata.isPlugin && editorMetadata.isFullScreen) { // for plugin fullscreen get bottom from innerHeight
    viewportBottom = window.innerHeight
  }

  if (viewportBottom - topPosition - offset < overlayHeight) {
    bottomPosition = offset
  }

  if (isRangeComment) {
    const rteBox = document.querySelector('#scrte-doc').getBoundingClientRect()
    rightPosition = rteBox.left
  }
  sourceDOM.style.top = `${topPosition}px`

  if(bottomPosition){
    sourceDOM.style.top = 'auto'
    sourceDOM.style.bottom = `${bottomPosition}px`
  }
  else{
    sourceDOM.style.bottom = 'auto'

  }
  sourceDOM.style.right = `${rightPosition}px`
}