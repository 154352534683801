//@ts-nocheck
import React, { memo, createContext, useContext } from 'react'
import { Registry } from '@react-registry'
import { TriggerButton } from './Button/TriggerButton'
import { Buttons } from './toolbarComponents'
import styles from './style.module.css'

const toolbarContext = createContext({})
export const useToolbarProps = () => useContext(toolbarContext)

const types = ['v2.leaf', 'v2.element', 'v2.editorButtons']

const Toolbar = memo(
  (props: any) => {
    const {
      toolbarType = 'mainToolbar',
      contentStack = false,
      toolbarMode = 'advance',
      customToolbarComponents = {},
      fieldName,
      editorRef,
      pluginslen,
      closeHoveringToolbar = () => {}
    } = props

    return (
      <div className="toolbar-parent">
        {toolbarType !== 'hoveringToolbar' && <div className={'rte-field-name'}>{fieldName}</div>}
        <div className={styles['toolbar-child-inline']}>
          <toolbarContext.Provider
            value={{ toolbarType, contentStack, toolbarMode, customToolbarComponents, editorRef }}>
            {types.map((type) => (
              <Buttons pluginslen={pluginslen} type={type} key={type} closeHoveringToolbar={closeHoveringToolbar} />
            ))}
          </toolbarContext.Provider>
        </div>
        {toolbarType !== 'hoveringToolbar' && (
          <div className={styles['toolbar-child-inline']}>
            <TriggerButton
              key={'fullscreen'}
              value={Registry.get({
                id: 'fullscreen',
                registry: 'v2.element',
                conditions: {
                  overide: true
                }
              })}
              format={'fullscreen'}
              toolbarType={toolbarType}
              title={'Fullscreen'}
            />
          </div>
        )}
      </div>
    )
  },
  (prev, next) => prev.pluginslen === next.pluginslen
)

export default Toolbar
