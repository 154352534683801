import { Registry } from '@react-registry'

export const withDivider = (editor) => {
  const { isVoid } = editor

  editor.isVoid = (el) => (el.type === 'hr' ? true : isVoid(el))

  return editor
}

Registry.register(withDivider, {
  id: 'withDivider',
  registry: 'plugin'
})
