import React, { useState } from 'react'

import Icon from '../Icon2'
import { MatchType } from './types'
import { className } from './utils'

export const MatchNode = ({
  matchType,
  onMatchNodeSelection,
  matchAllLabel,
  matchAnyLabel,
}: {
  matchType?: MatchType,
  onMatchNodeSelection: (matchType?: MatchType) => void,
  matchAllLabel: string,
  matchAnyLabel: string,
}) => {
  const [showOptionsPanel, setShowOptionsPanel] = useState(false);

  const onClickMatchNode = () => {
    setShowOptionsPanel(!showOptionsPanel);
  }
  const onClickMatchNodeSelection = (selection?: MatchType) => () => {
    onMatchNodeSelection(selection);
    onClickMatchNode();
  }

  return (
    <>
      <button type="button" autoFocus={true} className={className('TagView')} onClick={onClickMatchNode}>
        Match: {
          matchType === MatchType.ANY ?
            matchAnyLabel :
            matchType === MatchType.ALL ?
              matchAllLabel : ''
        }
        <Icon icon='DropdownDark' className={className('MatchNodeDropDown')} />
      </button>
      {
        showOptionsPanel &&
        <div className={className('MatchNodeOptionsPanel')}>
          <div className={className('MatchNodeOptionsPanel', 'Header')}>OPTIONS</div>
          <div className={className('MatchNodeOptionsPanel', 'Tags')}>
            <button
              type="button"
              autoFocus={true}
              className={`${className('TagView')} ${className('TagViewHoverable')}`}
              onClick={onClickMatchNodeSelection(MatchType.ALL)}
              onKeyDown={(event) => {
                if (event.key === 'Escape') {
                  setShowOptionsPanel(false);
                }
              }}>
              Match: {matchAllLabel}
            </button>
            <button
              type="button"
              className={`${className('TagView')} ${className('TagViewHoverable')}`}
              onClick={onClickMatchNodeSelection(MatchType.ANY)}
              onKeyDown={(event) => {
                if (event.key === 'Escape') {
                  setShowOptionsPanel(false);
                }
              }}>
              Match: {matchAnyLabel}
            </button>
          </div>
        </div>
      }
      {
        matchType !== undefined &&
        <button type="button" className={className('Cancel')} onClick={onClickMatchNodeSelection(undefined)}>
          <Icon icon='Cancel'/>
        </button>
      }
    </>
  )
}