/* istanbul ignore file */
import React, { useRef, useEffect, Fragment } from 'react'
import { Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import styles from './style.module.css'
import Popover from '../Popover'
import { handleSlashRect } from './utils'

const noAction = () => {
  // does nothing
}

export const SlashComponent = React.memo((props: any) => {
  const ref = useRef()
  let listRef = useRef()
  const { searchElements, index, handleSet, target, editor, setTarget, choice, setChoice, scrollRef, dispatch } = props

  let el = null
  let choiceSet = choice;
  let selectedOption = searchElements[choice]

  const handlePosition = () => {
    el = ref.current
    if (target && searchElements.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      const isPlugin = editor?.['requestProps']?.['isPlugin']
      if (isPlugin) {
        handleSlashRect(rect, el, scrollRef.current)
      } else {
        el.style.top = `${rect.bottom + 16}px`
        el.style.left = `${rect.left + window.scrollX}px`
      }
    } else {
      el.style = ''
    }
  }

  useEffect(() => {
    handlePosition()
  }, [searchElements.length, index, target])

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', handlePosition, false)
    return () => {
      scrollRef?.current?.removeEventListener('scroll', handlePosition, false)
    }
  }, [scrollRef?.current])

  const menu = {}
  searchElements.forEach((char) => {
    if (char.category) {
      if (!menu[char?.category.slashTitle]?.length) menu[char?.category.slashTitle] = []
      menu[char?.category.slashTitle].push(char)
    }
  })

  return (
    <Popover ref={ref} data-testid="slash-command" className={styles['slash-popover']}>
      <ul ref={listRef} className={styles['slash-ul']}>
        {searchElements.length === 0 && <></>}
        {Object.keys(menu).map((category) => {
          return (
            <Fragment key={category}>
              <li>
                <p className={styles['category']}>{category}</p>
              </li>
              {menu[category].map((item) => {
                if (item.toolbar?.inSlashToolbar === false) return
                if (item.IngressComponent) {
                  return (
                    <item.IngressComponent key={item.format}>
                      {({ handleMouseDown }) => {
                        if (choiceSet != null && selectedOption.format === item.format) {
                          choiceSet = null
                          setChoice(null)
                          setTimeout(() => {
                            handleMouseDown({
                              preventDefault: noAction
                            }, {editor})
                          }, 0)
                          return <React.Fragment key={category}></React.Fragment>
                        }
                        return (
                          <>
                            <li
                              key={item.format}
                              data-testid={item.format}
                              onMouseDown={(event) => {
                                event.preventDefault()
                                setTarget(null)
                                Transforms.delete(editor, {
                                  at: target
                                })
                                setTimeout(
                                  () =>
                                    handleMouseDown(
                                      {
                                        preventDefault: noAction
                                      },
                                      { editor }
                                    ),
                                  0
                                )
                              }}>
                              {
                                <Item
                                  type="item"
                                  listRef={listRef}
                                  item={item}
                                  index={searchElements.findIndex((charItem) => charItem.slashTitle == item.slashTitle)}
                                  currentIndex={index}
                                />
                              }
                            </li>
                          </>
                        )
                      }}
                    </item.IngressComponent>
                  )
                } else {
                  return (
                    <li
                      key={item.format}
                      data-testid={item.format}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        Transforms.delete(editor, { at: target })
                        if (item.handleMouseDown) {
                          item.handleMouseDown({ event, editor, dispatch })
                        } else handleSet(item.format)
                      }}>
                      {
                        <Item
                          type="item"
                          listRef={listRef}
                          item={item}
                          index={searchElements.findIndex((charItem) => charItem.slashTitle == item.slashTitle)}
                          currentIndex={index}
                        />
                      }
                    </li>
                  )
                }
              })}
            </Fragment>
          )
        })}
      </ul>
    </Popover>
  )
})

const Item = ({ item, index, currentIndex, listRef, type }) => {
  const itemRef = useRef<HTMLDivElement>()

  if (item.title) {
    if (document.querySelector('.category') && itemRef && itemRef.current) {
      let elHeight = itemRef.current.clientHeight
      let scrollTop = listRef.current.offsetTop
      let viewport = scrollTop + listRef.current.clientHeight
      let elOffset = elHeight * 2
      listRef.current.scrollTop = elOffset - viewport + elHeight
    } else if (listRef && listRef.current && itemRef && itemRef.current && currentIndex === index) {
      let elHeight = itemRef.current.clientHeight + 25
      let scrollTop = listRef.current.offsetTop
      let viewport = scrollTop + listRef.current.clientHeight
      let elOffset = elHeight * index
      listRef.current.scrollTop = elOffset - viewport + elHeight
    }
    return (
      <div ref={itemRef} className={`${styles['slash-item']} ${currentIndex == index && styles['active']}`}>
        {type !== 'category-heading' && <div className={styles['slash-item-icon']}>{item.slashIconName}</div>}
        <div className={styles['slash-item-text']}>
          <span style={{ fontSize: type === 'category-heading' ? '20px' : '14px' }}>{item.slashTitle}</span>
        </div>
      </div>
    )
  } else return null
}
