import React, { Component } from 'react'
import Icon from '../../Icon2/Icon'

import FilterDropdown from './FilterDropdown'
import Button from '../../Button/Button'
import Tooltip from '../../Tooltip/Tooltip'
// import { entriesfilters, filterQueryObjectBuilder } from './utils'
import { isEmpty } from '../util/helpers'
import { getOperatorTextContent } from '../util/common'
import SearchPortal from '../SearchPortal'
import SearchTooltip from '../SearchTooltip'
import { portalId, inputTextShadow } from '../util/constants'

const renderValue = ({ isMulti, value, inputValue, isText, isNumeric, currentQuery, isFilterDropdownOpen }: any) => {

  if (inputValue) {
    return inputValue;
  }

  let labels = []

  if (isMulti) {
    labels = value.map((v) => v.label)
  } else if (value.value) {
    labels.push(value.label)
  }

  if (!labels.length && (isText || isNumeric)) {
    return <span className="AdvancedSearch__query-autocomplete__placeholder">Enter a value</span>
  }

  if (!labels.length) {
    if (currentQuery === 'fetchEntriesTags' || currentQuery === 'fetchAssetTags') {
      return isFilterDropdownOpen ? '' : <span className="AdvancedSearch__query-autocomplete__placeholder">Select</span>
    }
    return <span className="AdvancedSearch__query-autocomplete__placeholder">{isFilterDropdownOpen && currentQuery !== 'listDate' ? `Type something to search` : `Select`}</span>
  }

  if (labels.length >= 2) {
    return <div className="AdvancedSearch__query__selected">{labels.length} Selected</div>
  }

  return <div className="AdvancedSearch__query__selected">{labels.map((label) => `${label} `)}</div>
}



class FilterInput extends Component<any, any> {
  inputRef: any;

  state = {
    inputValue: '',
    activeInputIndex: -1,
    type: 'text',
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }


  setInputValue = (value) => {
    this.setState({ inputValue: value });
  }

  setInputType = (type) => {
    this.setState({ type });
  }

  updateInputValue = (event) => {
    const value = event.target.value
    this.setState({ inputValue: value })
    this.props.clearErrorMessage();
    this.openAutoComplete();
  }

  resetInputValue = () => {
    this.setState({ inputValue: '' })
  }

  openAutoComplete = () => {
    if (this.state.activeInputIndex !== this.props.index) {
      const { isNumeric } = this.props.filters[this.props.index]
      this.setState({ activeInputIndex: this.props.index, type: isNumeric ? 'number' : 'text' }, () => {
        this.props.clearErrorMessage();
        this.props.setDropdownState(this.props.index, true);
      })
    }
  }

  closeAutoComplete = () => {
    this.setState({ activeInputIndex: -1, inputValue: '' });
    this.props.setDropdownState(this.props.index, false);
  }

  updateFilter = (filterData) => {
    if (!filterData.isMulti && filterData.type === 'value') {
      setTimeout(this.closeAutoComplete)
    }
    this.props.updateFilter(filterData)
  }

  render() {
    const { textContent } = this.props;
    const isFilterDropdownOpen = this.state.activeInputIndex === this.props.index
    const inputName = `query-input-${this.props.index}`
    const { isMulti, value, isText, isNumeric, currentQuery } = this.props.filterObject
    const inputValue = this.state.inputValue

    const mirrorPlaceholderCheck = inputValue ? inputValue.length <= 20 : true
    return (
      <div className="AdvancedSearch__current-query">
        <div className="AdvancedSearch__input-wrapper">
          <input
            style={{ textShadow: inputValue.length > 20 ? inputTextShadow : '' }}
            disabled={this.state.type === 'tagLabelCase'}
            type={this.state.type}
            value={this.state.inputValue}
            className="AdvancedSearch__query-input"
            aria-label="input-search-query"
            name={inputName}
            onChange={this.updateInputValue}
            onClick={this.openAutoComplete}
            onFocus={this.openAutoComplete}
            autoComplete="off"
            ref={this.inputRef}
          // ref={props.filterInputRef}
          // onKeyDown={props.handleKeyDown}
          />
          {mirrorPlaceholderCheck && (
            <span className={`AdvancedSearch__query-autocomplete ${inputValue ? 'AdvancedSearch__query-autocomplete-mirror' : ''}`}>
              {renderValue({ isText, isMulti, isNumeric, value, inputValue: this.state.inputValue, currentQuery, isFilterDropdownOpen })}
            </span>

          )}
        </div>

        {isFilterDropdownOpen && (
          <SearchPortal domId={portalId}>
            <FilterDropdown
              textContent={textContent}
              resetInputValue={this.resetInputValue}
              setInputValue={this.setInputValue}
              inputValue={this.state.inputValue}
              closeAutoComplete={this.closeAutoComplete}
              fetchData={this.props.fetchData}
              isAutoCompleteOpen={isFilterDropdownOpen}
              filterObject={this.props.filterObject}
              inputName={inputName}
              updateFilter={this.updateFilter}
              filters={this.props.filters}
              languages={this.props.languages}
              inputRef={this.inputRef}
              setInputType={this.setInputType}
              inputType={this.state.type}
            // selectedOption={this.props.selectedOption.value}
            // setPlaceholder={this.setPlaceholder}
            />
          </SearchPortal>
        )}
        {!(isText || isNumeric) && <Icon icon="DropdownChevronDark" className="AdvancedSearch__search-indicator" onClick={() => this.openAutoComplete()} />}
      </div>
    )
  }
}

class SearchFilter extends Component<any, any> {
  state = {
    errorMessage: '',
    allLanguages: [],
    isScrollDisabled: false,
    dropdownOpenState: []
  }

  setDropdownState = (index, value) => {
    let newDropDownState = [...this.state.dropdownOpenState];
    newDropDownState[index] = value;
    this.setState({ dropdownOpenState: newDropDownState })
  }

  validateFilters = (filters) => {
    let isValid = false;
    let errorMessage = '';

    let hasLanguageValue = false;
    let hasLocalizedValue = false;
    let hasNonEmptyValue = false;

    for (const filter of filters) {
      const { value, key } = filter;

      const hasValue = !isEmpty(value);

      if (key.value === 'locale') {
        hasLanguageValue = hasValue;
      }

      if (key.value === 'localization') {
        hasLocalizedValue = hasValue;
      }

      if (hasValue) {
        hasNonEmptyValue = true;
      }
    }

    if (hasLanguageValue && !hasLocalizedValue) {
      errorMessage = this.props.textContent.validations.filters.no_localization
      isValid = false;
      return { isValid, errorMessage }
    }

    if (!hasNonEmptyValue) {
      isValid = false;
      errorMessage = this.props.textContent.validations.filters.no_filters
      return { isValid, errorMessage };
    }

    return { isValid: true, errorMessage: '' }
  }

  clearErrorMessage = () => {
    this.setState({
      errorMessage: '',
    })
  }

  handleResultClick = () => {
    const { isValid, errorMessage = '' } = this.validateFilters(this.props.filters);
    if (!isValid) {
      this.setState({ errorMessage })
      return;
    }
    this.props.onClickFilterResult()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.searchCase !== prevProps.searchCase && this.props.searchCase === 'searchFilter') {
      this.clearErrorMessage();
      try {
        const response = await this.props.fetchData.fetchLanguages({});
        this.setState({
          allLanguages: response.data,
          dropdownOpenState: this.props.filters.map(() => false)
        })
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    const { textContent } = this.props;
    const { filters } = this.props;
    const isDropdownOpen = this.state.dropdownOpenState.some((v) => v);

    return (
      <div className={`AdvancedSearch__searchFilter${this.props.searchCase === 'searchFilter' ? ` AdvancedSearch__searchFilter--show` : ''}`}>
        <div
          className={`AdvancedSearch__searchFilterBody${filters.length > 9 ? ` AdvancedSearch__searchFilterBody--limit` : ''
            }`}
          style={{ overflowY: isDropdownOpen ? 'hidden' : 'unset' }}
        >
          {/* {this.state.errorMessage && <p style={{ color: '#F77D7A', textAlign: 'center' }}>{this.state.errorMessage}</p>} */}
          {filters.map((filter, index) => {
            const { key, operator, queryType } = filter;

            let tooltipContent = getOperatorTextContent({
              operatorKey: operator,
              queryType,
              textContent
            })

            const isLocalizedFilter = key.value === 'localization';
            const languageFilter = filters.find((f) => f.key.value === 'locale') || {};
            const languageHasValue = languageFilter.value && languageFilter.value.label

            if (isLocalizedFilter && !languageHasValue) {
              return null;
            }

            return (
              <div className={`AdvancedSearch__searchFiltersItems ${isLocalizedFilter ? 'AdvancedSearch__searchFiltersItems--locales' : ''}`} key={index}>
                <span className="locales__vertical-connector"></span>
                <span className="locales__horizontal-connector"></span>
                <span className="locales__separator"></span>
                <div className="AdvancedSearch__filterItem-title">{key.label}</div>
                <SearchTooltip content={tooltipContent} position="top">
                  <div className="AdvancedSearch__matched-operator">
                    <Icon icon={operator.icon}></Icon>
                  </div>
                </SearchTooltip>
                <div className="AdvancedSearch__filterItem-value">
                  <FilterInput
                    setDropdownState={this.setDropdownState}
                    textContent={this.props.textContent}
                    filterObject={filter}
                    index={index}
                    fetchData={this.props.fetchData}
                    updateFilter={this.props.updateFilter}
                    filters={filters}
                    languages={this.state.allLanguages}
                    clearErrorMessage={this.clearErrorMessage}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className="AdvancedSearch__searchFilterFooter">
          <span className="AdvancedSearch__error-message">
            {this.state.errorMessage}
          </span>
          <div className="AdvancedSearch__see-results">
            <Button aria-label="Apply" icon="RightArrow" iconAlignment="right" onClick={this.handleResultClick}>Apply</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchFilter

// state = {
  //   filters: [],
  // }

  // componentDidMount() {
  //   const selectedOption = this.props.selectedOption
  //   const filters = getFiltersBySelectedOption(selectedOption)
  //   this.setState({ filters })
  // }

  // updateFilter = (filterData) => {
  //   // console.log("SearchFilter -> filterData", filterData)
  //   // const {type, isMulti, value, uid} =

  //   const { filters } = this.state
  //   const { type, value, uid, isMulti, isChecked } = filterData

  //   const foundFilter = filters.find((filter) => filter.uid === uid)
  //   // console.log("SearchFilter -> foundFilter", foundFilter)
  //   if (type === 'value' && isMulti) {
  //     if (!foundFilter.value.length && isChecked) {
  //       foundFilter.value.push(value)
  //       // console.log("SearchFilter -> foundFilter", foundFilter)
  //     } else if (isChecked) {
  //       foundFilter.value = [...foundFilter.value, value]
  //     } else if (!isChecked) {
  //       foundFilter.value = foundFilter.value.filter((v) => v.value !== value.value)
  //     }
  //     // foundFilter.value = value;
  //     this.setState({ filters: [...filters] })
  //     return
  //   }
  //   if (type === 'value' && !isMulti) {
  //     if (foundFilter.value.value === value.value) {
  //       foundFilter.value = {}
  //     } else {
  //       foundFilter.value = value
  //     }
  //     this.setState({ filters: [...filters] })
  //     return
  //   }

  //   if (type === 'operator') {
  //     foundFilter.operator = value
  //     this.setState({ filters: [...filters] })
  //     return
  //   }
  // }

  // onClickResult = () => {
  //   const queryObject = queryBuilder({
  //     filters: this.state.filters,
  //     selectedModuleVal: this.props.selectedOption.value,
  //   })

  //   if (this.props.onSearchSubmit) {
  //     this.props.onSearchSubmit({
  //       type: 'searchFilter',
  //       selectedOption: this.props.selectedOption,
  //       queryObject,
  //     })
  //   }
  //   console.log('queryObject', queryObject)
  //   this.props.setSearchVisibility(false)
  // }