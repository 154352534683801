export * from './InfoIcon'

export * from './EmbedBtn'

export * from './EmbedBtnGroup'

export const getEmbedBtnTitle = (type) => {
  switch (type) {
    case 'asset': return 'View Asset';
    case 'assetLink': return 'View Asset'
    default: return 'Edit Entry'
  }
}

export * from './ReferenceComponent'