import React, { CSSProperties, useRef } from 'react'
import './Info.css'
import cn from 'classnames'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'

type InfoType = 'light' | 'dark' | 'success' | 'warning' | 'attention'

interface InfoProps {
  className?: string
  icon?: React.ReactNode
  content: string | React.ReactNode
  type?: InfoType
  borderColor?: string
  backgroundColor?: string
  dismissable?: boolean,
  width?: number | string | undefined,
  leftSpacing?,
  rightSpacing?,
  bottomSpacing?
  topSpacing?
  enableHover?: boolean | undefined,
  style?: CSSProperties,
  contentStyles?: CSSProperties,
  iconStyles?: CSSProperties,
  dismissStyles?: CSSProperties,
  contentClassName?: string,
  iconClassName?: string,
  dismissClassName?: string,
  testId?: string,
}

const Info = (props: InfoProps) => {
  const {
    icon,
    content,
    className,
    type,
    backgroundColor,
    borderColor,
    dismissable,
    width,
    leftSpacing,
    rightSpacing,
    bottomSpacing,
    topSpacing,
    enableHover,
    style = {},
    contentStyles = {},
    iconStyles = {},
    dismissStyles = {},
    contentClassName,
    iconClassName,
    dismissClassName,
    testId,
  } = props

  let customStyle: CSSProperties = {}
  const infoEl = useRef(null);

  if (backgroundColor) customStyle.backgroundColor = backgroundColor;
  if (borderColor) customStyle.borderColor = borderColor;
  if (width) customStyle.width = width;
  if (leftSpacing) customStyle.marginLeft = leftSpacing;
  if (rightSpacing) customStyle.marginRight = rightSpacing;
  if (bottomSpacing) customStyle.marginBottom = bottomSpacing;
  if (topSpacing) customStyle.marginTop = topSpacing;

  customStyle = Object.assign(customStyle, style);

  const onDismissClick = () => {
    infoEl.current.remove();
  };

  const infoClass = cn('Info', [className], { [`Info--${type}`]: type, })
  const dismissClass = cn('Info__dismiss', [type], { 'hoverable__dismiss': enableHover, }, [dismissClassName])
  const contentClass = cn('Info__content', [type], [contentClassName])
  const iconClass = cn('Info__icon', [iconClassName], { [`Info__icon--${type}`]: type, }, 'flex-h-center', 'flex-v-center')

  return (
    <div
      className={infoClass}
      style={customStyle}
      ref={infoEl}
      data-test-id={testId}
    >
      {icon && <div className={iconClass} style={iconStyles}>{icon}</div>}
      <div className={contentClass} style={contentStyles}>{content}</div>

      {dismissable && <div className={dismissClass} style={dismissStyles} onClick={onDismissClick}>
        <Tooltip content="Dismiss" position="top" showArrow={false} interactive={false}>
          <Icon icon="Cancel" />
        </Tooltip>
      </div>}
    </div>
  )
}

Info.defaultProps = {
  testId: 'cs-Info'
} as Partial<InfoProps>

export default Info
