import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { HeadingIcon } from '../../../utils/Icon'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from '../style.module.css'
import { H4Icon } from '../../../utils/Icon'
export * from './deserializer'
export const H4 = (props) => {
  const { className } = props.attrs || {}
  return (
    <div {...props.attrs} className={styles['heading']}>
      <h4 {...props.attributes} className={cx(
        className,
        css`padding-top: var(--scrte-padding-top, 0px);`
      )}>
        {props.children}
      </h4>
    </div>
  )
}
Registry.register(
  {
    Component: H4,
    // iconName: 'ri-h-4',
    slashIconName: <H4Icon />,
    slashTitle: 'Heading 4',
    title: <h4 className={styles['h4']}>Heading 4</h4>,
    subtitle: 'Add heading level 4',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+4' },
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['heading four', 'heading 4']
  },
  { id: 'h4', registry: 'v2.element' }
)
