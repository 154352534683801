import { Node, Transforms, Editor, Element } from 'slate'
import { ElementWithType } from '../../../../../../utils/types';

import { JsonNode, INodeAttrs } from '../../../utils/types'

export const alignNode = (editor, alignment) => {
    const { selection } = editor;
    if (selection === null) {
        return
    }

    for (const [n, p] of Editor.nodes(editor, { at: selection })) {
        const node: JsonNode = n
        const path = p
        if (Element.isElement(node) && node.type !== 'doc') {
            const oldAttributes: any = node.attrs

            let redactorAttributes: Partial<INodeAttrs> = {}

            if (oldAttributes) {
                redactorAttributes = {
                    ...redactorAttributes,
                    ...oldAttributes["redactor-attributes"]
                }
            }
            if (node.type === 'img' || node.type === 'reference') {
                redactorAttributes.position = alignment
            }

            const elementAttribute = {
                attrs: {
                    ...oldAttributes,
                    style: {
                        ...oldAttributes?.style,
                        "text-align": alignment
                    },
                    "redactor-attributes": redactorAttributes
                }
            }

            Transforms.setNodes(editor, elementAttribute as ElementWithType, { at: path })
        }
        Transforms.select(editor, selection)
    }
}


export const isNodeAligned = (editor, style) => {
    const selection = editor.selection;

    if (selection === null) {
        return false
    }

    const [node] = Editor.nodes(editor, { match: (n: any) => n?.attrs?.style?.["text-align"] })
    //@ts-ignore
    if (node) return node[0].attrs.style["text-align"] === style
    else return style === 'left'
}
