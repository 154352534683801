import React, { useState, useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Icon from '../Icon2'
import { iconName } from '../Icon2/constants'
import './Carousel.css'

const defaultProps = {
  spacing: 0,
  items: [],
  disableDragDrop: false,
  testId: 'cs-carousel'
}

type DefaultProps = Readonly<typeof defaultProps>

export type CarouselProps = {
  spacing?: number
  items: Array<any>
  itemCount: number
  type?: string
  showDragIcon?: boolean
  disableDragDrop?: boolean
  dragEndCallback?: Function
  addBlockFlag?: boolean
  addCallback?: Function
  deleteBlockFlag?: boolean
  deleteCallBack?: Function
  uploadFileFlag?: boolean
  uploadFileCallback?: Function
  itemWidth?: number
  maxWidth?: number
  left?: number
  /**
   * An ID used for testing purposes applied as a data attribute (data-test-id)
   */
  testId?: string
} & Partial<DefaultProps>

const Carousel = (props: CarouselProps) => {
  let carouselContainer: any = useRef(null)
  let carouselItemContainer: any = useRef(null)
  let outerContainer: any = useRef(null)
  const [carouselItems, setCarouselItems] = useState(props.items || [])
  const [maxWidth, setMaxWidth] = useState(props.maxWidth || 0)
  const [singleItemWidth, setSingleItemWidth] = useState(0)
  const [itemWidth, setItemWidth] = useState(props.itemWidth || 0)
  const [itemLeft, setLeftValue] = useState(props.left || 0)

  let tempWidth
  let CarouselMinOffset = 'Carousel--min-offset'
  let CarouselMaxOffset = 'Carousel--max-offset'

  /**
   * This UseEffect is used for Setting Item Width, MaxWidth and Left (Scroll from left) value
   */

  useEffect(() => {
    setCarouselItems(props.items)
    let CarouselBlockItemList =
      carouselItemContainer &&
      carouselItemContainer.current &&
      carouselItemContainer.current.getElementsByClassName('Carousel__item')
    tempWidth = CarouselBlockItemList && CarouselBlockItemList[CarouselBlockItemList.length - 1].offsetWidth
    if (carouselItemContainer && carouselItemContainer.current) {
      carouselItemContainer.current.style.left = carouselItemContainer.current.style.left
    }
    setSingleItemWidth(tempWidth)
    setItemWidth(singleItemWidth + props.spacing)
    if (props.maxWidth || props.itemWidth || props.left) {
      setMaxWidth(props.maxWidth)
      setLeftValue(props.left)
      setItemWidth(props.itemWidth)
    } else {
      setMaxWidth(calculateMaxIndex())
    }
  }, [props.items, singleItemWidth, itemWidth, carouselItems.length, props.left])

  /**
   * This UseEffect is used for Add Modular blocks dynamically
   */
  useEffect(() => {
    if (props.addBlockFlag && carouselItemContainer.current) {
      const property = carouselItemContainer.current
      const left = (property && property.offsetLeft) || itemLeft
      const main = outerContainer.current
      const totalItemWidth = props.itemCount * itemWidth + itemWidth

      AddBlockCalc(left, totalItemWidth, main)
    }
  }, [props.addBlockFlag])
  /**
   * This UseEffect is used for Upload Files(Images) dynamically
   */
  useEffect(() => {
    if (props.uploadFileFlag) {
      const property = carouselItemContainer.current
      const left = property.offsetLeft || itemLeft
      const main = outerContainer.current
      const totalItemWidth = props.itemCount * itemWidth
      const maxIndex = carouselContainer.current.offsetWidth - totalItemWidth
      UploadFileCalc(left, maxIndex)
    }
  }, [props.uploadFileFlag])
  /**
   * This UseEffect is used for Delete Modular Block
   */
  useEffect(() => {
    if (props.deleteBlockFlag) {
      const property = carouselItemContainer && carouselItemContainer.current
      const left = property.offsetLeft || itemLeft
      const main = outerContainer.current
      DeleteBlogCalc(left, main)
    }
  }, [props.deleteBlockFlag])

  const AddBlockCalc = (left, totalItemWidth, main) => {
    if (left === 0 && maxWidth < 0) {
      outerContainer.current.classList.add(CarouselMinOffset)
      carouselItemContainer.current.style.left = `${maxWidth}px`
    }
    if (Math.sign(maxWidth + itemWidth) !== 1 && Math.sign(maxWidth + itemWidth) !== 0) {
      outerContainer.current.classList.add(CarouselMinOffset)
      if (left !== 'auto') {
        if (left - maxWidth > itemWidth) {
          if (left - maxWidth > itemWidth + itemWidth) {
            if (left === 0) {
              if (itemWidth <= 192 && itemWidth >= 112) {
                carouselItemContainer.current.style.left = `${maxWidth}px` // `${left - (left - maxWidth - itemWidth) - itemWidth}px`
              } else {
                if (Math.sign(maxWidth) !== 1) {
                  carouselItemContainer.current.style.left = `${maxWidth + itemWidth}px`
                }
              }
            } else {
              if (itemWidth < 192 && itemWidth > 112) {
                if (Math.sign(maxWidth) !== 1) {
                  carouselItemContainer.current.style.left = `${maxWidth}px`
                }
              }
            }
          }
        }
        if (!(left - itemWidth - maxWidth > itemWidth) && itemWidth <= 192 && itemWidth >= 112) {
          if (left === 0) {
            if (itemWidth <= 192 && itemWidth >= 112) {
              carouselItemContainer.current.style.left = `${maxWidth}px` // `${left - (left - maxWidth - itemWidth) - itemWidth}px`
            } else {
              if (Math.sign(maxWidth) !== 1) {
                carouselItemContainer.current.style.left = `${maxWidth + itemWidth}px`
              }
            }
          } else {
            if (Math.sign(maxWidth) !== 1 && left > 1000) {
              carouselItemContainer.current.style.left = `${maxWidth - itemWidth}px` // ${left - (left - maxWidth - itemWidth) - itemWidth}
            } else {
              carouselItemContainer.current.style.left = `${maxWidth}px`
            }
          }
        }
      }
    }
    props.addCallback()
  }

  const UploadFileCalc = (left, maxIndex) => {
    if (left === 0 && maxWidth < 0) {
      outerContainer.current.classList.add(CarouselMinOffset)
      //carouselItemContainer.current.style.left = `${maxWidth}px`
      carouselItemContainer.current.style.left = `${maxIndex}px`
    }
    if (Math.sign(maxWidth + itemWidth) !== 1 && Math.sign(maxWidth + itemWidth) !== 0) {
      outerContainer.current.classList.add(CarouselMinOffset)
      if (left !== 'auto') {
        if (left - maxWidth > itemWidth) {
          if (left - maxWidth > itemWidth + itemWidth) {
            if (left === 0) {
              if (Math.sign(maxWidth) !== 1) {
                carouselItemContainer.current.style.left = `${maxWidth - itemWidth}px`
              }
            } else {
              carouselItemContainer.current.style.left = `${maxWidth}px`
            }
          }
        }
        if (!(left - itemWidth - maxWidth > itemWidth)) {
          carouselItemContainer.current.style.left = `${maxIndex}px`
        }
      }
    }
    props.uploadFileCallback()
  }

  const DeleteBlogCalc = (left, main) => {
    if (left !== 'auto') {
      if (left !== 'auto' && left !== 0) {
        //main.classList.add(CarouselMaxOffset);
        if (left + itemWidth > 0) {
          carouselItemContainer.current.style.left = 0
          main.classList.remove(CarouselMinOffset)
        } else {
          carouselItemContainer.current.style.left = `${left + itemWidth}px`
        }
      }
      if (left + itemWidth === 0) {
        main.classList.remove(CarouselMinOffset)
      }
    }
    // else {
    //   if (left !== 'auto' && left !== 0) {
    //     main.classList.add(CarouselMaxOffset);
    //     carouselItemContainer.current.style.left = `${itemWidth}px`;
    //   };
    // };
    props.deleteCallBack()
  }

  const calculateMaxIndex = () => {
    const property = carouselItemContainer && carouselItemContainer.current
    const left = property.offsetLeft || itemLeft
    const main = outerContainer.current
    const totalItemWidth = props.itemCount * itemWidth + itemWidth
    const maxIndex = carouselContainer.current.offsetWidth - totalItemWidth
    if (maxIndex < 0) {
      outerContainer.current.classList.add(CarouselMaxOffset)
    }
    return maxIndex
  }

  const handlePrev = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const property = carouselItemContainer.current
    const left = property.offsetLeft || itemLeft
    const main = outerContainer.current

    if (left !== 'auto') {
      if (left !== 'auto' && left !== 0) {
        main.classList.add(CarouselMaxOffset)
        if (left + itemWidth > 0) {
          carouselItemContainer.current.style.left = 0
          main.classList.remove(CarouselMinOffset)
        } else {
          carouselItemContainer.current.style.left = `${left + itemWidth}px`
        }
      }
      if (left + itemWidth === 0) {
        main.classList.remove(CarouselMinOffset)
      }
    }
    // else {
    //   if (left !== 'auto' && left !== 0) {
    //     main.classList.add(CarouselMaxOffset);
    //     carouselItemContainer.current.style.left = `${itemWidth}px`;
    //   };
    // };
  }

  const handleNext = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const property = carouselItemContainer.current
    const left = property.offsetLeft || itemLeft
    const totalItemWidth = props.itemCount * itemWidth + itemWidth
    const maxIndex = carouselContainer.current.offsetWidth - totalItemWidth
    if (Math.sign(maxWidth + itemWidth) !== 1 && Math.sign(maxWidth + itemWidth) !== 0) {
      const main = outerContainer.current
      main.classList.add(CarouselMinOffset)
      if (left !== 'auto') {
        if (left - maxWidth > itemWidth) {
          if (left - maxWidth > itemWidth + itemWidth) {
            if (itemWidth < 192 && itemWidth > 112) {
              if (Math.sign(maxWidth) !== 1) {
                carouselItemContainer.current.style.left = `${left - itemWidth}px`
              }
            }
            // after clicking prev button then again next button click in uploadFileComponent
            else {
              carouselItemContainer.current.style.left = `${left - itemWidth}px`
            }
          }
          // added for upload file moving next
          else {
            carouselItemContainer.current.style.left = `${left - (left - maxWidth - itemWidth)}px`
          }
        }
        if (!(left - itemWidth - maxWidth > itemWidth) && itemWidth <= 192 && itemWidth >= 112) {
          carouselItemContainer.current.style.left = `${left - itemWidth - 30}px`
          if (Math.abs(left) > Math.abs(maxIndex)) {
            main.classList.remove(CarouselMaxOffset)
          }
        }
      }
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = ({ destination, source }) => {
    if (!destination) {
      return
    } else if (destination.index !== source.index) {
      setCarouselItems(reorder([...carouselItems], source.index, destination.index))
      if (props.dragEndCallback) {
        props.dragEndCallback(source.index, destination.index)
      }
    }
  }

  return (
    <div
      data-test-id={props.testId}
      ref={outerContainer}
      className={`Carousel ${props.type === 'card' ? 'Carousel--card' : ''}`}>
      <div className="" ref={carouselContainer}>
        {Math.sign(maxWidth + itemWidth) !== 1 && Math.sign(maxWidth + itemWidth) !== 0 ? (
          <>
            <button
              id="button-prev"
              aria-label="Previous Button"
              className={`Carousel__slide-button Carousel__slide-button--prev`}
              onClick={handlePrev}
              type="button">
              <Icon icon={iconName.LeftArrow} className="Carousel__slide-icon" />
            </button>
            <button
              id="button-next"
              aria-label="Next Button"
              className={`Carousel__slide-button Carousel__slide-button--next`}
              onClick={handleNext}
              type="button">
              <Icon icon={iconName.RightArrow} className="Carousel__slide-icon" />
            </button>
          </>
        ) : null}
        <DragDropContext onDragEnd={onDragEnd}>
          <div ref={carouselItemContainer} className="Carousel__wrapper">
            <Droppable isDropDisabled={props.disableDragDrop} droppableId="carousel-droppable" direction="horizontal">
              {(provided: any, snapshot: any) => (
                <div {...provided.placeholder} {...provided.droppableProps} ref={provided.innerRef}>
                  {carouselItems.map((item: any, index: any) => {
                    return (
                      <div className="Carousel__item" key={index}>
                        <Draggable
                          isDragDisabled={props.disableDragDrop}
                          key={item.id}
                          draggableId={item.id}
                          index={index}>
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...(props.showDragIcon ? null : provided.dragHandleProps)}>
                              {props.showDragIcon ? (
                                <>
                                  {item.component}
                                  <div className="Carousel__item--drag" {...provided.dragHandleProps}>
                                    <Icon icon="MoveIcon" />
                                  </div>
                                </>
                              ) : (
                                item.component
                              )}
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )
                  })}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}

Carousel.defaultProps = defaultProps

export default Carousel
