import React from 'react'
import { Registry } from '@react-registry'
import { useSlate } from 'slate-react'

import { isActionDisable, performHistoryAction } from '../utils'
import { Icon } from '../../../../../../../index';

export const RedoButton = ({ format = 'redo', children }) => {
  const editor = useSlate()
  const handleMouseDown = (event) => {
    event.preventDefault()
    performHistoryAction(editor, format)
  }
  return (
    <React.Fragment>
      {children({ handleMouseDown, isDisable: { func: isActionDisable, params: { editor, format } } })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <Icon style={{ width: '14px', height: '14px' }} icon='Redo' />,
    title: 'Redo',
    IngressComponent: RedoButton,
    isContentStackElement: true,
    inBasicToolbar: true,
    shortcut: { key: 'mod+shift+z', callback: () => { } },
    toolbar: {
      inHoveringToolbar: false
    }
  },
  { id: 'redo', registry: 'editorButtons' }
)
