import React from 'react'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import { toggleMark, isMarkActive } from '../../../components/Toolbar/Button/buttonUtils'

export const toggleSubscript = (event, editor) => {
  event.preventDefault()
  Editor.removeMark(editor, 'superscript')
  toggleMark(editor, 'subscript')
}

export const shortcutToggleSubScript = (event, editor, format, registryObj) => {
  toggleSubscript(event, editor)
}

export const ToggleSubscriptButton = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    toggleSubscript(event, editor)
  }

  return (
    <React.Fragment>{props.children({ handleMouseDown, active: isMarkActive(editor, 'subscript') })}</React.Fragment>
  )
}
