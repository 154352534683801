import React from 'react';
import moment from 'moment';
import cn from 'classnames';

import './DateLabel.css';

export type dateLabelProps = {
  date: any
  className?: string
  type?: 'tiny' | 'small' | 'medium',
  dateVal?: any
};

const DateLabel = (props: dateLabelProps) => {

  let { date, className, type, dateVal } = props;

  if (typeof (date) === 'number') {
    date = date.toString();
  };

  const typeClass = `DateLabel--${type}`;
  const classNames = cn('DateLabel', typeClass, className);

  // console.log('dateTime publishDetails datelabel', dateVal, moment(dateVal).format('MMM DD, YYYY [ ]hh:mm A'), '==>', date, moment(date).format('MMM DD, YYYY [ ]hh:mm A'));

  return (
    <div className={classNames}>
      {moment(date).format('MMM DD, YYYY [ ]hh:mm A')}
    </div>
  );
};

DateLabel.defaultProps = {
  type: 'tiny'
} as Partial<dateLabelProps>;

export default DateLabel;