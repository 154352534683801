import './OAuthCard.css'
import React from 'react'

export interface OAuthCardProps {
  rootClassName?: string;
  className?: string;
}

const OAuthCard: React.FC<OAuthCardProps> = ({
  className = '',
  children,
  rootClassName = ''
}: React.PropsWithChildren<OAuthCardProps>) => {
  return (
    <div className={`OAuth_Background flex-center ${rootClassName}`}>
      <div className={`OAuth_Consent_Card flex-column ${className}`}>
        {children}
      </div>
    </div>
  )
}

export default OAuthCard
