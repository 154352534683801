import React from 'react'
import { AssetCardVertical } from '../../../../../../../../../index'

const getTypeFromContentType = (contentType) => {
  if (contentType.startsWith('image/')) return 'image'
  else if (contentType.startsWith('application/')) return 'folder'
  else return 'text/plain'
}

export declare interface AssetImageProps {
  handleClick: any
  asset: any,
  checked: boolean
}

export default React.memo(
  (props: AssetImageProps) => {
    const { handleClick, asset, checked } = props
    const { url, title, file_size, uid, content_type, name, file_name } = asset

    return (
      <AssetCardVertical
        onClick={() => handleClick(asset)}
        url={url}
        title={title ?? name ?? file_name}
        size={file_size}
        type={getTypeFromContentType(content_type)}
        imageWidth={'100%'}
        checked={checked}
      />
    )
  },
  (prev, curr) => {
    return prev.checked === curr.checked
  }
)
