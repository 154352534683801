import { Registry } from '@react-registry'

export const IFRAME = (el) => {
  return {
    type: 'embed',
    attrs: {
      url: el.getAttribute('src')
    },
    children: [
      {
        text: ''
      }
    ]
  }
}

Registry.register(IFRAME, { id: 'IFRAME', registry: 'deserializer' })
