import React from 'react'
import { AvatarProps } from '../Avatar/Avatar'
import './MainHeader.css'

interface MainHeaderProps {
  mainLogo: React.ReactNode;
  headerElements?: React.ReactNode[];
  secondaryLogo: React.ReactNode;
  avatar: React.ReactElement<AvatarProps>;
}

const MainHeader: React.FC<MainHeaderProps> = (props) => {
  const { mainLogo, headerElements, secondaryLogo, avatar } = props

  return (
    <div className="MainHeader flex-justify flex-v-center">
      <div className="MainHeader__main-logo-wrapper">
        {mainLogo && mainLogo}
      </div>
      <div className="MainHeader__header-elements-wrapper flex-v-center flex-h-center">
        <div className="MainHeader__header-elements-quicklinks flex-v-center flex-h-center" >
          {headerElements && headerElements}
        </div>
        <div className="MainHeader__header-elements-cs-Logo flex-v-center flex-h-center">
          {secondaryLogo && secondaryLogo}
          {avatar && avatar}
        </div>
      </div>
    </div>
  )
}

export default MainHeader
