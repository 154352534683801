import React from 'react'

import Icon from '../Icon2'
import { MatchNode } from './match-node'
import { QueryFragmentBuilder } from './query-fragment-builder'
import {
  Condition,
  MatchType,
  Property,
  PropertyConditionConstraint,
  queryCombination,
  QueryCombination,
  QueryFragment,
  Translations
} from './types'
import { className } from './utils'

export const QueryCombinationBuilder = ({
  query,
  onUpdateQuery,
  properties,
  conditions,
  translations,
  propertyConditionConstraints,
}: {
  query: QueryCombination | undefined,
  onUpdateQuery: (query: QueryCombination) => void,
  properties: Property[],
  conditions: Condition[],
  translations: Translations,
  propertyConditionConstraints: PropertyConditionConstraint[]
}) => {
  const onUpdateMatchType = (matchType: MatchType | undefined) =>
      onUpdateQuery(matchType ? queryCombination(matchType, query?.queries || []) : undefined);
  
  if (!query) {
    return (
      <div className={className('QueryWrapper')}>
        <MatchNode
          matchType={undefined}
          onMatchNodeSelection={onUpdateMatchType}
          matchAllLabel={translations.matchAll}
          matchAnyLabel={translations.matchAny} />
      </div>
    );
  }

  return (
    <div className={className('QueryWrapper')}>
      <MatchNode
        matchType={query.matchType}
        onMatchNodeSelection={onUpdateMatchType}
        matchAllLabel={translations.matchAll}
        matchAnyLabel={translations.matchAny} />
      <QueryCombinationContainer />
      <QueriesGuideLine />
      <div className={className('QueryListWrapper')}>
        {
          query.queries.map(q =>
            <QueryFragmentBuilder
              query={q as QueryFragment}
              onUpdateQuery={queryFragment => {
                const queriesList = [...query.queries];
                const index = queriesList.indexOf(q);
                if (queryFragment === undefined) {
                  queriesList.splice(index, 1);
                } else {
                  queriesList[index] = queryFragment; 
                }
                onUpdateQuery({...query, queries: queriesList});
              }}
              properties={properties}
              conditions={conditions}
              propertyLabel={translations.propertyLabel}
              conditionLabel={translations.conditionLabel}
              propertyConditionConstraints={propertyConditionConstraints} />
          )
        }
        <div className={className('FirstQueryFragmentBuilder')}>
          <QueryFragmentBuilder
              query={undefined}
              onUpdateQuery={queryFragment => onUpdateQuery({...query, queries: [...query.queries, queryFragment]})}
              properties={properties}
              conditions={conditions}
              propertyLabel={translations.propertyLabel}
              conditionLabel={translations.conditionLabel}
              propertyConditionConstraints={propertyConditionConstraints} />
        </div>
      </div>
    </div>
  );
}

const QueryCombinationContainer = () => {
  return (
    <div className={className('QueryCombinationContainer')} />
  );
}

const QueriesGuideLine = () => {
  return (
    <div className={className('QueriesGuideline')}>
      <Icon icon='PlusBold' className={className('QueriesGuideline', 'PlusSign')} />
    </div>
  );
}
