import React from 'react'
import { Registry } from '@react-registry'

import { shortcutToggleSuperscript, ToggleSuperscriptButton } from './superscriptButton'
import { SuperscriptIcon } from '../../utils/Icon'
//@ts-ignore
import styles from './style.module.css'
import { leafCategories } from '../leafCategories'

export const Superscript = ({ children }) => <sup className={styles['super']}>{children}</sup>

export * from './deserializer'

Registry.register(
  {
    Component: Superscript,
    title: 'Superscript',
    iconName: <SuperscriptIcon />,
    shortcut: { key: 'mod+shift+^', byKey: true, callback: shortcutToggleSuperscript },
    IngressComponent: ToggleSuperscriptButton,
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE_SEE_MORE
  },
  { id: 'superscript', registry: 'leaf' }
)
