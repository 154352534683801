import React from 'react'
import { Registry } from '@react-registry'

export const Mark = ({ children }) => <span style={{ background: 'var(--mark-background,#ffe58f)' }}>{children}</span>

export * from './deserializer'

Registry.register(
  {
    Component: Mark,
    title: 'Mark',
    iconName: 'ri-mark-pen-line',
    shortcut: { key: 'mod+m' },
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
    },
  },
  { id: 'mark', registry: 'leaf' }
)
