import React, { Fragment, useState } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../Modal/Modal'
import Button from "../../Button/Button";
import ButtonGroup from "../../Button/ButtonGroup/ButtonGroup";
import SelectImage from './SelectImage/SelectImage'
import '../style.css'
import { Asset } from '../utils/types';

export const getImageMarkdown = (href, text = 'Enter your image caption here') => {
  text = text === '' ? href : text
  return `![${text}](${href})`
}

declare global {
  interface Window {
    extension_uid: any;
  }
}
interface IModalComponentState {
  assets: Asset[]
}

const ModalComponent = (props) => {
  const isMultiple = props?.multiple
  const [state, setState] = useState<IModalComponentState>({
    assets: [],
  });

  const setAssets = (assets) => {
    setState(state => ({
      ...state,
      assets: assets.map(ele => ({
        url: ele.url,
        fileName: ele.filename,
        uid: ele.uid,
        title: ele.title,
        fileSize: ele.file_size,
        contentType: ele.content_type,
      }))
    }))
  }

  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(state.assets)
    }
    handleReset()
  }

  const handleReset = () => {
    props.closeModal()
  }

  const isAssetSelected = !state.assets.length;

  return (
    <div className="asset-picker">
      <div id="scrte-image-modal">
        <ModalHeader title={'Select Asset'} closeModal={props.closeModal} />

        <ModalBody className="modalBodyCustomClass">
          <SelectImage
            setImages={setAssets}
            type={props?.only}
            fileTypes={props.fileTypes}
            minSize={props?.size?.min}
            maxSize={props?.size?.max}
            excludeAssetUids={props?.excludeAssetUids}
            {...(isMultiple && { multiple: props.multiple })} />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={handleReset} buttonType="light">
              Cancel
            </Button>
            <Button data-testid="add-asset" onClick={handleSubmit}
              disabled={isAssetSelected}
              icon="CheckedWhite">
              Add Selected Asset
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    </div>
  )
}
export default ModalComponent