import React from 'react'

export const InsertTable = (props) => {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 -200 1700 1500"
            preserveAspectRatio="none"
            fill='currentColor'
            {...props}
        >
            <path
                d="M91.506 1261.1V422.602c0-18.06-19.8-32.25-45-32.25s-45 14.19-45 32.25V1261.1c0 18.06 19.8 32.25 45 32.25s45-14.19 45-32.25zM1265.5 1261.1V422.602c0-18.06-19.8-32.25-45-32.25s-45 14.19-45 32.25V1261.1c0 18.06 19.8 32.25 45 32.25s45-14.19 45-32.25zM678.502 1261.1V422.602c0-18.06-19.8-32.25-45-32.25s-45 14.19-45 32.25V1261.1c0 18.06 19.8 32.25 45 32.25s45-14.19 45-32.25z"
            />
            <path
                d="M54.173 751.352H1212.84c29.49 0 52.67-19.8 52.67-45s-23.18-45-52.67-45H54.173c-29.494 0-52.667 19.8-52.667 45s23.173 45 52.667 45zM1220.06 932.152H46.156c-25.284 0-45.15 19.871-45.15 45.16 0 25.288 19.866 45.158 45.15 45.158H1220.06c25.28 0 45.15-19.87 45.15-45.158 0-25.289-19.87-45.16-45.15-45.16zM1212.84 1203.35H54.173c-29.494 0-52.667 19.8-52.667 45s23.173 45 52.667 45H1212.84c29.49 0 52.67-19.8 52.67-45s-23.18-45-52.67-45zM1220.05 0H46.149C20.864 0 .999 19.87.999 45.16v361.28c0 25.29 19.866 45.16 45.15 45.16H1220.05c25.28 0 45.15-19.87 45.15-45.16V45.16c0-25.29-19.87-45.16-45.15-45.16zm-45.15 361.28H91.299V90.32H1174.9v270.96z"
            />
        </svg>
    )
}