import { Editor, Transforms, Range, Point } from 'slate'
import { Registry } from '@react-registry'
import { ElementWithType } from '../../../../../../../utils/types'



export const withChecklists = editor => {
    const { deleteBackward, insertBreak } = editor

    editor.deleteBackward = (...args) => {
        const { selection } = editor
        if (selection && Range.isCollapsed(selection)) {
            const [match] = Editor.nodes(editor, {
                match: (n: ElementWithType) => n.type === 'check-list',
            })

            if (match) {
                const [, path] = match
                const start = Editor.start(editor, path)

                if (Point.equals(selection.anchor, start)) {
                    Transforms.setNodes(
                        editor,
                        { type: 'p' } as ElementWithType,
                        { match: (n: ElementWithType) => n.type === 'check-list' }
                    )
                    return
                }
            }
        }

        deleteBackward(...args)
    }
    editor.insertBreak = () => {
        insertBreak()
        if (editor.selection) {
            Transforms.setNodes(editor, { checked: false } as ElementWithType, { match: (n: ElementWithType) => n.type === 'check-list' })
        }
    }

    return editor
}
Registry.register(withChecklists, { id: 'withChecklists', registry: 'plugin' })