import React from 'react'
import { Registry } from '@react-registry'
import styles from './styles.module.css'

const handleEvent = (e) => e.stopPropagation();

export const docs = (props) => {
    return (
        <div id='scrte-doc' className={styles['doc']} onMouseDown={handleEvent} {...props.attributes}>
            {props.children}
        </div>
    )
}


Registry.register({
    Component: docs,
    dndOptions: {
        DisableDND: true,
        DisableSelectionHalo: true
    }

}, {
    id: 'docs',
    registry: 'element'
})