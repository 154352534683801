import { Transforms, Editor, Node } from 'slate'
import { Registry } from '@react-registry'
import { ElementWithType } from '../../../../../../utils/types'

export const withBlockquote = (editor) => {
  const { insertBreak } = editor

  editor.insertBreak = () => {
    if (editor.selection) {
      const [blockquote] = Editor.nodes(editor, {
        match: (n: ElementWithType) => {
          return n.type === 'blockquote'
        }
      })

      if (blockquote) {

        Transforms.splitNodes(editor)
        const nextPath = Editor.next(editor, {
          at: blockquote[1], match: (n: ElementWithType) => {
            return n.type === 'blockquote'
          }
        })
        if (nextPath) {
          //@ts-ignore
          Transforms.setNodes(editor, { type: 'p' }, { at: nextPath[1] })
        }
        return
      }
    }
    insertBreak()
  }

  return editor
}
Registry.register(withBlockquote, { id: 'withBlockquote', registry: 'v2.plugin' })
