let previewElement = null;
let previewRef = null;


const handleMousemove = (event) => {
    // previewElement = document.getElementById('table-column-preview')
    previewElement = previewRef.current
    if (previewElement) {
        previewElement.style['display'] = 'block'
        previewElement.style['transform'] = `translate(${event.x}px, ${event.y}px)`
    }
}


const addEventListenerForPreview = (CustomRef) => {
    previewRef = CustomRef
    window.addEventListener("dragover", handleMousemove)
}
const removeEventListenerForPreview = () => {
    window.removeEventListener("dragover", handleMousemove)
}
export default {
    addEventListenerForPreview,
    removeEventListenerForPreview
};
