import React, { forwardRef, RefObject, useEffect, useRef, useState } from 'react'
import { truncate } from '../AdvanceSearch/util/helpers'
import Tooltip from '../Tooltip/Tooltip'
import './Truncate.css'
import { useResizeObserver } from './use-resize-observer'

export type TruncateProps = {
  children: string
  maxChar?: number
  tooltip?: TruncateTooltipProps
  isResponsive?: boolean
  testId?: string
}

export type TruncateTooltipProps = {
  position:
    | 'bottom'
    | 'top'
    | 'left'
    | 'right'
    | 'bottom-start'
    | 'bottom-end'
    | 'top-start'
    | 'top-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end'
  showArrow?: boolean
  disabled?: boolean
  variantType?: 'dark' | 'light' | 'menuIcon'
  type?: 'primary' | 'secondary' | 'dynamic'
  maxWidth?: any
  [propsKey: string]: any
}

export const Truncate = forwardRef(({ children, maxChar, tooltip, isResponsive, testId }: TruncateProps, nodeRef) => {
  const refDefault = useRef(null)
  const truncateRef = useRef(null)
  const wrapperRef = (nodeRef as RefObject<HTMLDivElement>) || refDefault

  const dimensions = useResizeObserver(wrapperRef)
  const [isToolTipDisabled, setIsToolTipDisabled] = useState(null)
  const { truncatedText } = truncate(children, maxChar)

  const isEllipsisActive = () => {
    return wrapperRef?.current?.offsetWidth < truncateRef?.current?.scrollWidth
  }

  const setEllipses = () => {
    const isTruncated = isEllipsisActive()
    if (!isTruncated) {
      setIsToolTipDisabled(true)
    } else {
      setIsToolTipDisabled(false)
    }
  }

  const isMaxChar = () => {
    if (isResponsive) {
      setEllipses()
    }

    if (maxChar && !isResponsive) {
      children?.length <= maxChar ? setIsToolTipDisabled(true) : setIsToolTipDisabled(false)
    }
  }

  useEffect(() => {
    isMaxChar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions])

  return (
    <div ref={wrapperRef}>
      <Tooltip content={children} position={tooltip.position as string} disabled={isToolTipDisabled} {...tooltip}>
        <div
          ref={truncateRef}
          className="truncate-text"
          style={isResponsive ? { width: `${dimensions}px` } : null}
          data-test-id={testId}>
          {isResponsive ? children : truncatedText}
        </div>
      </Tooltip>
    </div>
  )
})

Truncate.defaultProps = {
  testId: 'cs-truncate',
  maxChar: 24,
  tooltip: { position: 'bottom' },
  isResponsive: false
}
