import { Registry } from '@react-registry'
import { v4 } from 'uuid'
import { Node, Element } from 'slate'

export const generateId = () => v4().split('-').join('')

export const withId = (editor) => {
  const { apply } = editor

  editor.apply = (operation) => {

    let id = generateId()
    if (operation.type === 'split_node') {
      const node = Node.get(editor, operation['path'])
      if (Element.isElement(node)) {
        if (operation.properties.type === 'li' || operation.properties.type === 'reference' || operation.properties.type === 'a') {
          return apply({
            ...operation,
            properties: {
              ...operation.properties,
              id
            }
          })
        }
        return apply({
          ...operation,
          properties: {
            ...operation.properties,
            type: 'p',
            id
          }
        })
      }
    }

    if (operation.type === 'insert_node' && Element.isElement(operation['node'])) {
      return apply({
        ...operation,
        node: {
          id,
          ...operation['node']
        }
      })
    }
    return apply(operation)
  }
  return editor
}

Registry.register(withId, {
  id: 'withId',
  registry: 'plugin'
})
