import classnames from 'classnames'

export const defaultValue = {
  columnSelector: true,
  name: { singular: '', plural: '' },
  controlledSelectedRowIds: {},
  minBatchSizeToFetch: 30,
  loading: false,
  viewSelector: false,
  fetchTableDataArg: { skip: 0, limit: 30, startIndex: 0, stopIndex: 29 },
  canSearch: false,
  canRefresh: false,
  tableHeight: 650,
  toolTipOffset: 30,
  baseColumnWidth: 140,
  isResizable: false,
  resizedColumnWidths: {},
  canOrderColumn: false,
  selectColumnWidth: 68,
  columnMinWidth: 200,
  columnMaxWidth: 700,
  columnMaxWidthPerc: 0.8,
  noDataColumnWidth: 180,
  withExportCta: {
    showExportCta: false
  },
  tableTestId: "cs-table",
  /* 
  changing this won't change table head height
  Table height is set using css, using just for calculation 
   */
  TABLE_HEAD_HEIGHT: 40,
  freezeColumnBorderWidth: 2,
  v2Features: { tableRowAction: false }
}
export const MAX_FREEZE_COUNT = 2;
export const defaultProps = {
  columnSelector: true,
  name: { singular: '', plural: '' },
  controlledSelectedRowIds: {},
  minBatchSizeToFetch: 30,
  loading: false,
  viewSelector: false,
  canSearch: true,
  canRefresh: true,
  tableHeight: 650,
  isResizable: false,
  resizedColumnWidths: {},
}


export const constants = {
  compact_view: 'Compact',
  comfort_view: 'Comfortable',
  asc: 'asc',
  desc: 'desc',
}

export const viewByName = {
  compact_view: constants.compact_view,
  comfort_view: constants.comfort_view,
}


export const getTableRowClassString = (row, isRowDisabled, rowSelectCheckboxProp, viewBy, isSingleRowSelected, equalWidthColumns, rowDisableProp) => {
  let classname: string = 'Table__body__row ';
  if (isRowDisabled) {
    classname = classname + 'Table__body__row--disabled ';
  };
  if (equalWidthColumns) {
    classname = classname + 'Table__body__row--equal-width-columns ';
  }
  if (row.isSelected) {
    if (rowSelectCheckboxProp && row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
      if (!(rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value)) {
        classname = classname + 'Table__body__row--selected ';
      }
    }
    if (!rowSelectCheckboxProp) {
      if (!(rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value)) {
        classname = classname + 'Table__body__row--selected ';
      }
    };
  };
  if (viewBy === 'Compact') {
    classname = classname + 'Table__body__row--compact ';
  };
  if (isSingleRowSelected) {
    classname = classname + 'Table__body__row--selected ';
  };
  if (rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value) {
    classname = classname + 'Table__body__row--complete-disabled '
  }
  return classname;
};

export const getRowsLength = (rows, rowSelectCheckboxProp) => {
  let length = rows.length
  if (rowSelectCheckboxProp) {
    let count = 0
    rows.forEach((row) => {
      if (row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
        count = count + 1
      }
    })
    length = count
  }
  return length
}

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const filterColumnData = (allColumns) => {
  let columnObject = {}
  allColumns.forEach(column => {
    if (column.addToColumnSelector) {
      columnObject[column.id] = column.isVisible
    }
  })
  return columnObject
}

export const getMinMaxStyle = (tableWidth) => {
  return {
    minWidth: defaultValue.columnMinWidth,
    maxWidth: tableWidth ? tableWidth * defaultValue.columnMaxWidthPerc : defaultValue.columnMaxWidth
  }
}

export const setWidth = (currentWidth, tableWidth) => {
  let maxWidth = tableWidth ? tableWidth * defaultValue.columnMaxWidthPerc : defaultValue.columnMaxWidth
  if (currentWidth >= defaultValue.columnMinWidth && currentWidth <= maxWidth) {
    return currentWidth;
  }
  if (currentWidth >= maxWidth) {
    return maxWidth;
  }
  if (currentWidth <= defaultValue.columnMinWidth) {
    return defaultValue.columnMinWidth;
  }

}

//! todo ---> can we improve this
export const compareColumn = (allColumns, columns) => {
  return allColumns.filter((e) => {
    return columns.findIndex((column) => column.id === e.id) > -1;
  });
}
export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const getActiveStateIcon = (actionSelected, selectedTile) => actionSelected === selectedTile;

export const appendRule = (sheet) => {
  const len = sheet.cssRules.length;
  sheet.insertRule('*{}', len);
  return sheet.cssRules[len];
}

export const freezeColumnOptions = [
  {
    label: 'Unfreeze all columns',
    value: 0
  },
  {
    label: 'Freeze 1 Column',
    value: 1,
  },
  {
    label: 'Freeze 2 Columns',
    value: 2,
  }
];

export const getFreezeColumnCount = (listData, isRowSelect) => {
  let [freezed = [],] = listData;
  let totalFreezedColumn = freezed.filter((column) => column.isVisible).length;
  return totalFreezedColumn > 0 && isRowSelect ? totalFreezedColumn + 1 : totalFreezedColumn;
}


export const getTableCount = ({ totalCounts, name, staticRowCount }) => {
  let totalString: any = "";

  if (!totalCounts) {
    return `No ${name.plural}`;
  }
  totalString = `${totalCounts} ${name.plural}`;
  // check if the totalCounts is only 1
  if (totalCounts === 1) {
    totalString = `${totalCounts} ${name.singular}`;
    // check if there is static row and the total count is only 1 so basically we need to show that there is no data here
    if (staticRowCount) {
      totalString = `No ${name.plural}`;
    }
  } else {
    //where totalCount is not one and staticRowCount is present
    if (staticRowCount) {
      totalString = `${totalCounts - staticRowCount} ${name.plural}`;
      //to handle the case where the staticRowCount is 1 and totalCount is 2 so the text should be singular
      if (totalCounts - staticRowCount === 1) {
        totalString = `${totalCounts - staticRowCount} ${name.singular}`;
      }
    }
  };
  return totalString;
}

export const getTableHeadWidth = (headerGroups) => {
  let totalWidth = 0
  headerGroups.map((headerGroup) => {
    headerGroup.headers.map((header, index) => {
      totalWidth += headerGroup.headers[index].width
    })
  })
  return totalWidth
}

export const handleDataOnRowSelect = ({ selectedRowIds, rowSelectCheckboxProp, data, rowDisableProp, uniqueKey, selectedData }) => {
  let selectedRowIdsArr = Object.keys(selectedRowIds)

  if (rowSelectCheckboxProp) {
    selectedRowIdsArr = selectedRowIdsArr.filter((selectedRowId) => {
      const foundData = data.find((dataObj) => selectedRowId === dataObj.uid)
      //if not found means user is on different page or in case of asset user cliked on any folder
      if (!foundData) {
        return true
      }
      //props conditional select check
      if (foundData && foundData[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
        if (rowDisableProp) {
          if (foundData[rowDisableProp.key] === rowDisableProp.value) {
            return false
          }
        }
        return true
      }
      // if condition not match return false
      return false
    })
  } else if (rowDisableProp) {
    selectedRowIdsArr = selectedRowIdsArr.filter((selectedRowId) => {
      const foundData = data.find((dataObj) => selectedRowId === dataObj.uid)
      //if not found means user is on different page or in case of asset user cliked on any folder
      if (!foundData) {
        return true
      }
      //props conditional disable check
      if (foundData[rowDisableProp.key] === rowDisableProp.value) {
        return false
      }
      return true
    })
  }

  selectedRowIdsArr.map((id) => {
    const foundData = data.find((data) => data[uniqueKey] === id)
    if (foundData) {
      selectedData.push(foundData)
    }
  })

  //remove duplicate
  selectedData = selectedData.filter(
    (data, index, arr) => index === arr.findIndex((arrData) => arrData[uniqueKey] === data[uniqueKey])
  )
  //filter out unselected data
  selectedData = selectedData.filter((data) => selectedRowIdsArr.includes(data[uniqueKey]))

  return { updatedSelected: selectedData, selectedRowIdsArr }
}

export const isMacOs = () => {
  if (navigator.userAgent.indexOf('Mac OS X') != -1) {
    return true;
  }
  return false;
}

export const getControlledFreezedColumns = (freezedColumnProps = [], allColumns = []) => {
  if (!freezedColumnProps.length) {
    return []
  }

  let allColumnIds = allColumns.map(column => column.id)

  let freezedColumnPropsObj = {}
  freezedColumnProps.forEach(columnId => {
    freezedColumnPropsObj[columnId] = true
  })
  let allUniqColumnIds = allColumnIds.filter(columnId => !freezedColumnPropsObj[columnId])

  let freezeCount = freezedColumnProps.length >= MAX_FREEZE_COUNT ? MAX_FREEZE_COUNT : freezedColumnProps.length

  let allColumnIdsInOrder = [...freezedColumnProps, ...allUniqColumnIds]

  return allColumnIdsInOrder.slice(0, freezeCount)

}

export const getControlledListProps = ({ canFreezeColumn, v2Features, height, innerElementType, isTableWidthGreater, isItemLoaded }) => {
  return canFreezeColumn || v2Features.tableRowAction
    ? {
      height,
      innerElementType,
      className: classnames(
        'Table__body',
        isTableWidthGreater && 'Table__body--scroll',
        !isItemLoaded && 'Table__body--loading',
        !isMacOs() && 'win'
      )
    }
    : {
      height: height - defaultValue.TABLE_HEAD_HEIGHT,
      className: classnames(
        'Table__body',
        isTableWidthGreater && 'Table__body--scroll',
        !isItemLoaded && 'Table__body--loading'
      )
    }
}