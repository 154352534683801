import React from 'react'

import ReferenceButton from './referenceButton'

export default (props) => {
  return (
    <ReferenceButton {...props} title="Choose Entry">
      {({ handleClick }) => {
        const handleMouseDown = handleClick
        return props.children({ handleMouseDown })
      }}
    </ReferenceButton>
  )
}
