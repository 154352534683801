import Prism from 'prismjs'
import { getRegistry } from '../getRegistry';
import { useCallback, useEffect, useState } from 'react'
import { Editor, Range, Text } from 'slate'
import '../../elements/utils/prism'
import _ from 'lodash';

const getLength = (token) => {
  if (typeof token === 'string') {
    return token.length
  } else if (typeof token.content === 'string') {
    return token.content.length
  } else {
    return token.content.reduce((l, t) => l + getLength(t), 0)
  }
}

export const useDecorate = (editor, deps) => useCallback(([node, path]) => {

  const ranges = []
  const currentSelection = editor.selection
  const isCreateRangeComment = _.isEqual(currentSelection, editor.createRangeCommentSelection)

  // decorate for rte plugins
  const callbacks = getRegistry('extended.decorate');
  if (callbacks) {
    Object.entries(callbacks).forEach(([key, val]) => {
      const decorateFunc = val['components'][0]['component'];
      ranges.push(...decorateFunc([node, path]));
    });
  }

  // for create range comment selection highlight
  if (
    Text.isText(node) &&
    currentSelection !== null &&
    isCreateRangeComment
  ) {
    const intersection = Range.intersection(currentSelection, Editor.range(editor, path))
    if (intersection !== null) {
      ranges.push({
        createCommentHighlight: true,
        ...intersection
      })
    }
  }

  if (node === editor) return ranges

  const [parentNode]: any = Editor.parent(editor, path);

  if (!parentNode || parentNode.type !== 'code') {
    return ranges
  }

  if (!Text.isText(node)) {
    return ranges
  }


  let selectedLanguage = parentNode?.attrs?.language ? parentNode.attrs.language : 'html'
  let start = 0
  const tokens = Prism.tokenize(node.text, Prism.languages[selectedLanguage])
  for (const token of tokens) {
    const length = getLength(token)
    const end = start + length

    if (typeof token !== 'string') {
      ranges.push({
        [token.type]: true,
        anchor: { path, offset: start },
        focus: { path, offset: end }
      })
    }

    start = end
  }
  return ranges
}, deps);