//@ts-nocheck
import React from 'react'

import HoveringToolbarWrapper from './HoveringToolbarWrapper'
import BaseToolbar from '../BaseToolbar';

export declare interface HoveringToolbarProps extends React.PropsWithChildren<object> {
  customToolbarComponents: any,
  contentStack: boolean,
  toolbarMode: any,
  scrollRef: any
}

export const HoveringToolbar = React.memo((props: HoveringToolbarProps) => {
  const {
    contentStack,
    customToolbarComponents,
    toolbarMode = 'advance'
  } = props;

  return (
    <HoveringToolbarWrapper {...props}>
      <BaseToolbar
        toolbarType={'hoveringToolbar'}
        contentStack={contentStack}
        toolbarMode={toolbarMode}
        customToolbarComponents={customToolbarComponents}
      />
    </HoveringToolbarWrapper>
  )
})
