import React from 'react';
import './index.css';


let loaders = [1, 2, 3, 4, 5, 6, 7, 8];
const Loader = () => {
  return (
    <React.Fragment>
      {
        loaders.map(loader => (
          <div key={loader} id='create-folder-grid-card' className={`create-folder-asset-card`}>
            <div className={`flex-v-center flex-h-center create-folder-asset-body loading-folder`}>
            </div>
            <div className='create-folder-asset-description'>
            </div>
          </div>
        ))
      }
    </React.Fragment>
  )
};


export default Loader;