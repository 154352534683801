//@ts-nocheck
import React from 'react';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};
export const ColumnDndPreview = (props) => {
    const { height, width, index, previewRef } = props
    return (<div contentEditable={false} style={layerStyles}>
        <div data-testid="table-column-preview" id='table-column-preview' ref={previewRef} style={{ display: 'none', maxWidth: '840px', border: `1px solid rgba(55, 53, 47, 0.4)`, width: `${width}px`, opacity: '0.7' }}>
            <div style={{ background: '#fafafa', borderBottom: `1px solid rgba(55, 53, 47, 0.4)`, fontWeight: '700' }}>
                Column {index + 1}
            </div>
            <div style={{ height: `${height}px`, background: 'white' }}>
            </div>
        </div>
    </div>);
};
