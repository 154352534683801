export const formatTime = (date) => {
  date = new Date(date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours < 10 ? `0${hours}` : hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return (
    new Date(date).toString().split(" ").slice(1, 4).join(" ") +
    ", " +
    strTime
  );
};

export const getAssetType = (content_type: any) => {
  let assetType = 'Document'
  if (content_type) {
    switch (true) {
      case content_type.indexOf('image') > -1:
        assetType = null;
        break
      case content_type.indexOf('folder') > -1:
        assetType = 'Group'
        break
      case content_type.indexOf('pdf') > -1:
        assetType = 'Pdf'
        break
      case content_type.indexOf('excel') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('presentation') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('json') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('document') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('text/plain') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('text/html') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('zip') > -1:
        assetType = 'Archive'
        break
      case content_type.indexOf('video') > -1:
        assetType = 'Video'
        break
      case content_type.indexOf('audio') > -1:
        assetType = 'Audio'
        break
    }
    if (content_type.indexOf('image/tiff') > -1) {
      assetType = 'Document'
    }

    return assetType
  }

  return assetType
}