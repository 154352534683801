import { useRef, useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill'

export const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  const animationFrameRef = useRef(null);

  const resize = (width: number) => {
    setDimensions(width);
  }

  useLayoutEffect(() => {
    const observeTarget = ref.current;

    const resizeObserver = new ResizeObserver(entries => {
      animationFrameRef.current = window?.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const entry = entries[0];
        const width = Math.round(entry.contentRect.width);
        // Wait at most one seconds before processing events.
        // @ts-ignore
        window?.requestIdleCallback(() =>
          resize(width), { timeout: 1000 });
      })
    });
    resizeObserver.observe(observeTarget);
    return () => {
      window?.cancelAnimationFrame(animationFrameRef.current);
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return dimensions;
};