import React, { useState } from 'react'
import { Transforms, Range, Point, Editor } from 'slate'
import { useSlateStatic, useSlate } from 'slate-react'
import isUrl from 'is-url'
import {
  cbModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  ButtonGroup,
  MultilineField,
  Field
} from '../../../../../../index'

import { disableOnCodeChild } from '../code/utils'
import { Editor as RteEditor } from '../../../utils/types'
import { insertImage, isImageUrl } from '../image'
import { setOldSelection } from '../../../utils/setOldSelection'
import getEmbedUrl from './getEmbedUrl'

export const isIframe = (data) => {
  return (data.match(/<iframe|<video/gi) !== null);
}

export const getUrlFromIframe = (url, withAttrs) => {
  let attrs;
  const parser = new DOMParser();
  const htmlElement = parser.parseFromString(url, "text/html");

  if (htmlElement.getElementsByTagName('iframe')) {
    attrs = {
      url: htmlElement.getElementsByTagName('iframe')[0]?.getAttribute('src'),
    }
    if (withAttrs) {
      htmlElement.getElementsByTagName('iframe')[0]?.getAttributeNames()?.forEach((attribute) => {
        attrs[attribute] = htmlElement.getElementsByTagName('iframe')[0]?.getAttribute(attribute)
      })
    }
  }
  return attrs
}

export const addEmbed = (editor, attrs) => {
  let url;
  if (typeof attrs === 'string') {
    url = attrs;
  }
  else {
    url = attrs?.url
  }
  if (!url || !isUrl(url)) return false
  if (isImageUrl(url)) {
    insertImage(editor, url)
    return
  }
  url = getEmbedUrl(url) || url;
  let embeded = {
    type: 'embed',
    attrs: typeof attrs === 'string' ? { url } : { ...attrs },
    children: [{ text: '' }]
  }
  // add frameborder=0 allowfullscreen="" to embeded iframe
  if (embeded.attrs) {
    embeded.attrs.allowfullscreen = ""
    embeded.attrs.frameborder = "0"
  }
  editor.insertNode(embeded)
}
export default (props) => {
  const editor: RteEditor = useSlateStatic()
  const useSlateEditor = useSlate()

  const handleSubmitForm = React.useCallback((values, selection) => {
    if (selection) Transforms.select(editor, selection)
    if (isIframe(values['embeded_url'])) {
      let data = values['embeded_url'];
      var allowed = ['iframe', 'video', 'source'];
      var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

      data = data?.replace(/<p(.*?[^>]?)>([\w\W]*?)<\/p>/gi, '');
      data = data?.replace(tags, function ($0, $1) {
        return (allowed.indexOf($1.toLowerCase()) === -1) ? '' : $0;
      });

      const parser = new DOMParser();
      const htmlElement = parser.parseFromString(data, "text/html");

      if (htmlElement.getElementsByTagName('iframe')) {
        let attrs
        attrs = {
          url: htmlElement.getElementsByTagName('iframe')[0]?.getAttribute('src'),
        }
        htmlElement.getElementsByTagName('iframe')[0]?.getAttributeNames()?.forEach((attribute) => {
          attrs[attribute] = htmlElement.getElementsByTagName('iframe')[0]?.getAttribute(attribute)
        })
        addEmbed(editor, attrs)
      }
      return;
    }
    addEmbed(editor, values['embeded_url'])
  }, [])

  const openModal = () => {
    const selection: Range | Point = editor.selection || editor.savedSelection || Editor.end(editor, []);
    if (!selection) return
    cbModal({
      modalProps: {
        //@ts-ignore
        shouldReturnFocusAfterClose: false,
        onClose: () => setOldSelection(editor)
      },
      component: (props) => <ModalComponent handleSubmitForm={handleSubmitForm} selection={selection} {...props} />
    })
  }



  const ModalComponent = (props) => {
    const [formData, setFormData] = useState({
      embeded_url: ''
    })

    const handleChange = (e) => {
      e.persist()
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleSubmit = (e) => {
      props.handleSubmitForm(formData, props.selection)
      props.closeModal()
    }

    return (
      <div>
        <ModalHeader title="Video" closeModal={props.closeModal} />

        <ModalBody>
          <Field labelText="Video Embed Code or Youtube/Vimeo Link">
            <MultilineField
              rows={3}
              autoFocus
              name="embeded_url"
              placeholder="Enter Something"
              value={formData.embeded_url}
              onChange={handleChange}
            />
          </Field>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button key="cancel" buttonType="light" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button key="add" icon="CheckedWhite" onClick={handleSubmit}>
              Add
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: openModal,
        isDisable: {
          func: disableOnCodeChild,
          params: { editor: useSlateEditor }
        }
      })}
    </React.Fragment>
  )
}
