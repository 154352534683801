import React, { useState, memo } from 'react'
import { ResponsiveContainer, RadialBarChart, RadialBar, Legend, PolarAngleAxis } from 'recharts'

import './Graph.css'

interface BarItem {
  name: string
  value: number
  fill: string
}

export interface RadialBarChartProps {
  data: BarItem[]
  name: string
  usage: string
  plan: string
}

const RadialBarChartComponent = (props: RadialBarChartProps) => {
  const { name, data, plan, usage } = props
  return (
    <ResponsiveContainer className="RadialBar">
      <RadialBarChart innerRadius={63} outerRadius={75} barSize={12} data={data} startAngle={90} endAngle={-270}>
        <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
        <RadialBar background dataKey="value" cornerRadius={5} />
        <foreignObject x={'16%'} y={'35%'} width="100" height="100">
          <div className="center">
            <span className="RadialBar--usage">{usage}</span>
            <span className="RadialBar--plan">/{plan}</span>
          </div>
        </foreignObject>
        <foreignObject x={'16%'} y={'48%'} width="100" height="100">
          <p className="RadialBar--name center">{name}</p>
        </foreignObject>
      </RadialBarChart>
    </ResponsiveContainer>
  )
}

export default memo(RadialBarChartComponent)
