//@ts-nocheck
import { Transforms, Editor, Element } from 'slate'

import { JsonNode } from '../../../utils/types'

export const alignNode = (editor, alignment) => {
    Editor.normalize(editor, {force: true})
    const { selection } = editor;
    if (selection === null) {
        return
    }

    for (const [n, p] of Editor.nodes(editor, { at: selection })) {
        const node: JsonNode = n
        const path = p
        if (Element.isElement(node) && node.type !== 'docs') {
            const oldAttributes: any = node.attrs

            let redactorAttributes: any = {}

            if (oldAttributes) {
                redactorAttributes = {
                    ...redactorAttributes,
                    ...oldAttributes['redactor-attributes']
                }
            }
            if (node.type === 'img') {
                redactorAttributes.position = alignment
            }

            const elementAttribute = {
                attrs: {
                    ...oldAttributes,
                    style: {
                        ...oldAttributes?.style,
                        "text-align": alignment
                    },
                    "redactor-attributes": redactorAttributes
                }
            }

            Transforms.setNodes(editor, elementAttribute, { at: path })
        }
        // in case if normalizing is called, editor selection changes and hence this change
        if(editor.selection) Transforms.select(editor, editor.selection)
    }
}


export const isNodeAligned = (editor, style) => {
    const selection = editor.selection;

    if (selection === null) {
        return false
    }

    const [node] = Editor.nodes(editor, { match: (n: any) => n?.attrs?.style?.["text-align"] })
    //@ts-ignore
    if (node) return node[0].attrs.style["text-align"] === style
    else return style === 'left'
}
