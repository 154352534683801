//@ts-nocheck
import React from 'react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../style.module.css'

export * from './deserializer'

const TH = (props) => {
  const { attributes, children } = props

  return (
    <div
      data-testid="th"
      data-type="th"
      className={styles['div-table-cell-heading']}
    >
      <div {...attributes} style={{ overflow: 'hidden', flexWrap: 'wrap', width: '100%' }}>
        {children}
      </div>
    </div>
  )
}

export default TH

Registry.register(
  {
    Component: TH,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
    toolbar: {
      inHoveringToolbar: false
    },
  },
  { id: 'th', registry: 'v2.element' }
)
