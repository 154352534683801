import React from 'react'
import { Transforms } from 'slate'
import { Registry } from '@react-registry'

import linkReferenceButton, { ShortcutOpenEntryAsLinkOverlay } from './linkReferenceButton'
import { elementCategories } from '../../../elementCategories'
import { cx } from '@emotion/css'

//@ts-nocheck
import styles from './style.module.css'
import { EmbedBtnGrp } from '../inlineReference'
import Tooltip from '../../../../../../../Tooltip/Tooltip'

export const EmbedLinkReference = ({ entry, editor, element, ...props }) => {
  const url = entry['url'] || element?.attrs?.['href']
  const isError = entry['error']
  const { history, apiKey } = editor?.requestProps || {}
  const contentTypeUid = element?.attrs?.['content-type-uid'],
    entryUid = element?.attrs?.['entry-uid'],
    locale = element?.attrs?.['locale']
  const isDisabled = entry['disable']
  const handleEdit = ({ apiKey, contentTypeUid, entryUid }) => {
    history?.push(`/stack/${apiKey}/content-type/${contentTypeUid}/${locale}/entry/${entryUid}/edit`)
  }

  const handleUnlink = () => {
    const selection = editor.selection || editor.savedSelection
    if (!selection) return
    Transforms.unwrapNodes(editor, {
      at: selection,
      //@ts-ignore
      match: (node) => node.type === 'reference' && node?.attrs?.['display-type'] === 'link'
    })
  }

  return (
    <span className="tippy-inline">
      <Tooltip
        appendTo={document.body}
        zIndex={909}
        className="p-0"
        position="bottom"
        trigger="click"
        variantType="light"
        content={
          <div contentEditable={false} className={styles['embed--btn-group']}>
            <a href={url} target="_blank" className={styles['embed--url']}>
              {url}
            </a>
            <EmbedBtnGrp
              isDisabled={isDisabled}
              isError={isError}
              handleCaption={undefined}
              handleEdit={() => handleEdit({ apiKey, contentTypeUid, entryUid })}
              handleDelete={undefined}
              handleUnlink={handleUnlink}
              editor={editor}
              type="link"
              attributes={{ ...props.attributes, ...props.attrs, element }}
            />
          </div>
        }>
        <Tooltip
          appendTo={document.body}
          disabled={!Boolean(entry['error'])}
          zIndex={909}
          className={styles['embed--link-error']}
          position="top"
          variantType="light"
          content={entry['error']}>
          <a
            href={url}
            {...props.attributes}
            {...props.attrs}
            data-type="link"
            data-testid={'embed-hyperlink'}
            className={cx(styles['linkRef'], entry['error'] && styles['linkRefError'])}>
            {props.children}
          </a>
        </Tooltip>
      </Tooltip>
    </span>
  )
}

Registry.register(
  {
    title: 'Embed entry as link',
    category: elementCategories.LINK_DROPDOWN,
    IngressComponent: linkReferenceButton,
    shortcut: { key: 'mod+shift+k', callback: ShortcutOpenEntryAsLinkOverlay },
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true,
      inSlashToolbar: false
    },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'linkReference', registry: 'element' }
)

export * from './deserializer'
