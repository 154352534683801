import './GenericCard.css'

import React, {
  useMemo,
  useState
} from 'react'

import { iconName } from '../Icon2/constants'
import Icon from '../Icon2/Icon'
import { Truncate } from '../Truncate/Truncate';

const defaultProps = {
  active: false,
  data: '',
  onActiveToggle: state => { },
  disableActiveFn: false,
  width: 20,
  testId: 'cs-generic-card'
}

type DefaultProps = Readonly<typeof defaultProps>

export type GenericCardProps = {
  title?: string
  active?: boolean
  mainContent?: JSX.Element
  footerContent?: JSX.Element
  data?: any
  onActiveToggle: (state) => void,
  disableActiveFn?: boolean,
  width?: number,
  testId?: string
} & Partial<DefaultProps>

const GenericCardFooter = ({ content }) => {
  return useMemo(() => {
    return (
      <div className="StackCard__footer GenericCardfooter">
        {content}
      </div>
    )
  }, [content])
}

const GenericCard = (props: GenericCardProps) => {
  let { title, active, onActiveToggle, mainContent, footerContent, data, disableActiveFn, width, testId } = props;

  let [isActive, setIsActive] = useState(active);

  const handleStarClick = (event: any) => {
    event.stopPropagation();
    setIsActive(!isActive);
    onActiveToggle({ isActive: !isActive, data });
  };

  return (
    <div className="GenericCard" style={{ width: `${width}rem` }} data-test-id={testId}>
      <div className="GenericCard__heading">
        <h4 className="GenericCard__title"
          aria-label='title'>
          {title?.length > 22 ? (
            <Truncate maxChar={22}>{title}</Truncate>
          ) : title}
          {!disableActiveFn && <button
            aria-label='star-button'
            className="GenericCard__star-button"
            onClick={handleStarClick}
          >
            <Icon
              icon={iconName.Star}
              className={`StackCard__star-icon${isActive ? ' StackCard__star-icon--active' : ''}`}
            ></Icon>
          </button>}
        </h4>
      </div>
      <div className="StackCard__content">
        {mainContent}
      </div>
      <GenericCardFooter content={footerContent} />
    </div>
  )
}

GenericCard.defaultProps = defaultProps

export default GenericCard
