import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { cx, css } from '@emotion/css'

//@ts-ignore
import styles from '../style.module.css'
export * from './deserializer'

export const H2 = (props) => {
  const { className } = props.attrs || {}
  return (
    <div {...props.attrs} className={styles['heading']}>
      <h2 {...props.attributes} className={cx(
        className,
        css`padding-top: var(--scrte-padding-top, 0);`
      )}>
        {props.children}
      </h2>
    </div>
  )
}

Registry.register(
  {
    Component: H2,
    // iconName: 'ri-h-2',
    title: <h2 className={styles['h2']}>Heading 2</h2>,
    subtitle: 'Subtitle for your site',
    shortcut: { key: 'mod+alt+2' },
    category: elementCategories.TEXT_FORMAT,
    isContentStackElement: true,
    inBasicToolbar: true
  },
  { id: 'h2', registry: 'element' }
)
