import React from 'react'

import { ModalBody, ModalHeader } from '../../../../../../index'
//@ts-ignore
import styles from './style.module.css'
import { handleImageError } from './withImage'

export const ImageFullScreen = (props) => {
  const { url, closeModal } = props
  return (
    <React.Fragment>
      <ModalHeader title="View Image" closeModal={closeModal} />

      <ModalBody>
        <img
          style={{ width: '100%', height: 'auto' }}
          src={url}
          onError={handleImageError}
          className={styles['modal']}
          data-testid={"scrte-image-modal"}
        />
      </ModalBody>
    </React.Fragment>
  )
}
