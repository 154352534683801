export default `
<div
    {...props.attributes}
    {...props.attrs}
    className={props.className}
    data-type='check-list'
    data-checked={props.checked}
>
    <span
    contentEditable='false'
    className={props.styles['scrte--checklist-checkbox']}
    >
    <Checkbox
        type="checkbox"
        checked={props.checked}
        onChange={props.onToggle}
    />
    </span>
    <span
    className={props.cross}
    >
    {props.children}
    </span>
</div >
`