import React, { useState, useRef, useMemo, MouseEventHandler } from 'react'
import cn from 'classnames'

import Icon from '../Icon2/Icon'

import './PageLayout.css'

export type headerProps = {
  backNavigation?: Function
  Breadcrumb?: React.ReactNode
  component?: React.ReactNode
  title?: string
  headerLogoOnClick?: MouseEventHandler
}

export type mainContentProps = {
  component?: React.ReactNode
  livePreview?: React.ReactNode
}

export type sidebarProps = {
  component?: React.ReactNode
}

export type topNavBarProps = {
  component?: React.ReactNode
}

export type stickyFooterProps = {
  component?: React.ReactNode
}

export type layoutProps = {
  footer?: stickyFooterProps
  content?: mainContentProps | emptyPageContentProps
  header?: headerProps
  headerType?: string
  leftSidebar?: sidebarProps
  rightSidebar?: sidebarProps
  layoutBackgroundColor?: boolean
  leftFooter?: React.ReactNode
  rightFooter?: React.ReactNode
  type: string
  className?: string
  mode?: 'compact' | 'fullscreen'
  topNavBar?: topNavBarProps
  topNavMode?: boolean
  version?: 'v2'
  testId?: string
  onToggleLeftSidebar?: Function
}

export type contentProps = {
  content: mainContentProps
  type: string
  toggle?: Function
  toggleStat?: boolean
  headerType?: string
  mode?: 'compact' | 'fullscreen'
  layoutBackgroundColor?: boolean
}

export type layoutHeaderProps = {
  header: headerProps
  toggle?: Function
  toggleStat?: boolean
  type: string
  headerType?: string
  mode?: 'compact' | 'fullscreen'
  layoutBackgroundColor?: boolean
}

export type leftSidebarProps = {
  leftSidebar: sidebarProps
}

export type rightSidebarProps = {
  rightSidebar: sidebarProps
}

export type footerProps = {
  footer?: stickyFooterProps
}

export type rightFooterProps = {
  rightFooter?: React.ReactNode
}

export type leftFooterProps = {
  leftFooter?: React.ReactNode
}

export type secondaryLayoutProps = {
  content: mainContentProps
  footer?: stickyFooterProps
  header: headerProps
  leftSidebar?: sidebarProps
  rightSidebar?: sidebarProps
  className?: string
  mode?: 'compact' | 'fullscreen'
  layoutBackgroundColor?: boolean
  leftFooter?: React.ReactNode
  rightFooter?: React.ReactNode
  topNavBar?: topNavBarProps
  version?: 'v2'
  testId?: string
}

export type primaryLayoutProps = {
  content: mainContentProps
  header: headerProps
  leftSidebar?: sidebarProps
  type?: string
  headerType?: string
  className?: string
  testId?: string
  onToggleLeftSidebar?: Function
}

export type emptyPageContentProps = {
  component?: React.ReactNode
  alignContent?: 'center'
  backgroundColor?: 'primary' | 'secondary'
}

export type emptyPageProps = {
  content: emptyPageContentProps
  header?: headerProps
  testId?: string
}

const TopNavbar = ({ topNavBar }: { topNavBar: topNavBarProps }) => {
  return <>{topNavBar.component}</>
}

const LayoutHeader = (props: layoutHeaderProps) => {
  let headClassname = ''
  if (props.type === 'card') {
    headClassname = props.headerType === 'custom' ? 'PageLayout__head--card--custom-header' : 'PageLayout__head--card'
  }
  return useMemo(() => {
    if (props.header !== undefined) {
      return (
        <div className={`PageLayout__head ${headClassname}`}>
          {props.toggleStat !== undefined ? (
            <div className="PageLayout__head__hamburger" onClick={() => props.toggle()}>
              <Icon icon="Hamburger" size="original" />
            </div>
          ) : (
            <div
              className="PageLayout__head__backArrow"
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  e.stopPropagation()
                  props.header.backNavigation(e)
                }
              }}
              onClick={(event: any) => props.header.backNavigation(event)}>
              <Icon icon="BackArrow" size="original" />
            </div>
          )}
          {props.header.component !== undefined ? props.header.component : ''}
        </div>
      )
    } else {
      return null
    }
  }, [props.header && props.header.title, props.toggleStat, props.header && props.header.component])
}

const LeftSidebar = (props: leftSidebarProps) => {
  return <>{props.leftSidebar.component}</>
}

const RightSidebar = (props: rightSidebarProps) => {
  return <>{props.rightSidebar.component}</>
}

const Footer = (props: footerProps) => {
  return <>{props.footer.component}</>
}

const Content = (props: contentProps) => {
  let cardClassname = ''
  if (props.type === 'card') {
    cardClassname = props.headerType === 'custom' ? 'PageLayout__body--card--custom-header' : 'PageLayout__body--card'
  }
  return useMemo(() => {
    if (props.content !== undefined) {
      return (
        <>
          {props.type === 'edit' ? (
            <div className={`PageLayout__body`} id="PageLayout__body">
              {props.content.livePreview}
              <div className="PageLayout__body__container">{props.content.component}</div>
            </div>
          ) : (
            <div className={`PageLayout__body ${cardClassname}`}>{props.content.component}</div>
          )}
        </>
      )
    } else {
      return null
    }
  }, [props.content, props.content.component, props.toggleStat])
}

const ListLayout = (props: primaryLayoutProps) => {
  const [toggleLeftNav, setLeftNav] = useState(true)
  const sidebar = useRef(null)
  const [toggleLayout, setToggleLayout] = useState(false)

  const handleToggle = () => {
    setLeftNav(!toggleLeftNav)
    let leftSidebarElem: any = sidebar.current
    if (sidebar.current.classList.contains('PageLayout__leftSidebar--show')) {
      setTimeout(function () {
        leftSidebarElem.style.zIndex = '8'
      }, 300)
    } else {
      leftSidebarElem.style.zIndex = '2'
    }
    setToggleLayout(!toggleLayout)
    if (props.onToggleLeftSidebar) {
      setTimeout(() => props.onToggleLeftSidebar(toggleLeftNav), 300)
    }
  }
  let contentCardClassname = ''
  if (props.type === 'card') {
    contentCardClassname =
      props.headerType === 'custom' ? 'PageLayout__content--card--custom-header' : 'PageLayout__content--card'
  }
  const leftSidebarClassName = !!toggleLeftNav ? 'PageLayout__leftSidebar--show' : 'PageLayout__leftSidebar--hide'
  return (
    <div
      data-test-id={props.testId}
      className={`PageLayout PageLayout--primary ${props.className ? props.className : ''}`}>
      {props.leftSidebar !== undefined ? (
        <div ref={sidebar} className={`PageLayout__leftSidebar ${leftSidebarClassName}`}>
          <LeftSidebar leftSidebar={props.leftSidebar} />
        </div>
      ) : null}
      <div
        className={`PageLayout__content ${
          props.leftSidebar === undefined ? 'PageLayout__content--full' : ''
        } ${contentCardClassname} ${toggleLayout ? 'PageLayout__content--full' : ''}`}>
        {props.leftSidebar !== undefined ? (
          <LayoutHeader
            header={props.header}
            type={props.type}
            headerType={props.headerType}
            toggleStat={toggleLeftNav}
            toggle={handleToggle}
          />
        ) : (
          <LayoutHeader header={props.header} headerType={props.headerType} toggle={handleToggle} type={props.type} />
        )}
        <Content
          content={props.content}
          type={props.type}
          headerType={props.headerType}
          toggle={handleToggle}
          toggleStat={toggleLeftNav}
        />
      </div>
    </div>
  )
}
ListLayout.defaultProps = {
  testId: 'cs-list-layout'
}

const RightFooter = (props: rightFooterProps) => {
  const { rightFooter } = props
  return <div className="PageLayout__right-footer">{rightFooter}</div>
}

const LeftFooter = (props: leftFooterProps) => {
  const { leftFooter } = props
  return <div className="PageLayout__left-footer">{leftFooter}</div>
}

const FullLayout = (props: secondaryLayoutProps) => {
  const {
    className,
    header,
    mode,
    leftSidebar,
    rightSidebar,
    content,
    footer,
    layoutBackgroundColor,
    rightFooter,
    leftFooter,
    topNavBar,
    version,
    testId
  } = props

  const fullPageClassName = !leftFooter ? `${footer ? '' : 'PageLayout--content-fullpage'}` : ''

  const secondaryLayoutClassnames = cn('PageLayout PageLayout--secondary', className, {
    'PageLayout--full-screen': mode === 'fullscreen',
    'PageLayout--content-fullpage': fullPageClassName,
    'PageLayout--secondary-edit': layoutBackgroundColor === true,
    'PageLayout--secondary-edit--v2': version
  })

  return (
    <div data-test-id={testId} className={secondaryLayoutClassnames}>
      <LayoutHeader header={header} type={'edit'} mode={mode} />
      <div className={`PageLayout__content`}>
        {leftSidebar && (
          <div className={`PageLayout__leftSidebar`}>
            <LeftSidebar leftSidebar={leftSidebar} />
          </div>
        )}
        {rightSidebar && (
          <div className={`PageLayout__rightSidebar`}>
            <RightSidebar rightSidebar={rightSidebar} />
          </div>
        )}
        {topNavBar && (
          <div className={`PageLayout__topNavbar`}>
            <TopNavbar topNavBar={topNavBar} />
          </div>
        )}
        <Content content={content} type={'edit'} mode={mode} />
      </div>
      {footer && (
        <div className={`PageLayout__footer`}>
          <Footer footer={footer} />
        </div>
      )}
      {rightFooter && <RightFooter rightFooter={rightFooter} />}
      {leftFooter && <LeftFooter leftFooter={leftFooter} />}
    </div>
  )
}
FullLayout.defaultProps = {
  testId: 'cs-full-layout'
}

const EmptyPage = (props: emptyPageProps) => {
  const PageHeaderWithCSLogo = () => (
    <div className="PageLayout__head--emptyLayout" data-test-id={props.testId}>
      <div
        className={`PageLayout__head--CSLogo ${props.header?.headerLogoOnClick ? 'clickable' : ''}`}
        onClick={props.header?.headerLogoOnClick}>
        <Icon icon="ContentstackLogoGrey" />
      </div>
    </div>
  )

  return (
    <div
      data-test-id={props.testId}
      className={`PageLayout--emptyLayout
        ${props.content.backgroundColor === 'secondary' ? 'secondaryBackground' : 'primaryBackground'}`}>
      <PageHeaderWithCSLogo />
      <div
        className={`${
          props.content.alignContent === 'center' ? 'PageLayout__content--center' : 'PageLayout__content'
        }`}>
        {props.content.component}
      </div>
    </div>
  )
}
EmptyPage.defaultProps = {
  testId: 'cs-empty-page'
}

const PageLayout = (props: layoutProps) => {
  const {
    header,
    leftSidebar,
    content,
    type,
    rightSidebar,
    footer,
    headerType,
    className,
    mode,
    layoutBackgroundColor,
    rightFooter,
    leftFooter,
    topNavBar,
    version,
    testId,
    onToggleLeftSidebar
  } = props

  switch (type) {
    case 'list':
      return (
        <ListLayout
          header={header}
          leftSidebar={leftSidebar}
          content={content}
          className={className}
          testId={testId}
          onToggleLeftSidebar={onToggleLeftSidebar}
        />
      )
    case 'card':
      return (
        <ListLayout
          header={header}
          headerType={headerType}
          leftSidebar={leftSidebar}
          content={content}
          type={type}
          className={className}
          testId={testId}
          onToggleLeftSidebar={onToggleLeftSidebar}
        />
      )
    case 'edit':
      return (
        <FullLayout
          header={header}
          leftSidebar={leftSidebar}
          content={content}
          rightSidebar={rightSidebar}
          footer={footer}
          className={className}
          mode={mode}
          layoutBackgroundColor={layoutBackgroundColor}
          rightFooter={rightFooter}
          leftFooter={leftFooter}
          topNavBar={topNavBar}
          version={version}
          testId={testId}
        />
      )
    case 'empty':
      return <EmptyPage content={content} header={header} testId={testId} />
    default:
      return null
  }
}

export default PageLayout

PageLayout.defaultProps = {
  testId: 'cs-page-layout'
}
