import React from 'react'
import cn from 'classnames'
import _range from 'lodash/range'
import _isEmpty from 'lodash/isEmpty'
import Icon from '../Icon2/Icon'
import Dropdown from '../DropDown/Dropdown'
import { isNextMonthDisabled, isPrevMonthDisabled } from './helpers'

const Header = props => {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    minDate,
    maxDate,
    maxAllowedDate,
    minAllowedDate
  } = props
  const years = _range(minDate.getFullYear(), maxDate.getFullYear(), 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const yearList = [
    ...years.map(year => ({
      key: year,
      label: year,
      action: () => {
        changeYear(year)
      },
      default: year === date.getFullYear(),
    })),
  ]

  const monthList = [
    ...months.map(month => ({
      key: month,
      label: month,
      action: () => {
        changeMonth(months.indexOf(month))
      },
      default: month === months[date.getMonth()],
    })),
  ]

  const disablePrevMonth =
    prevMonthButtonDisabled || isPrevMonthDisabled(date, minAllowedDate ? minAllowedDate : minDate)
  const disabledNextMonth =
    nextMonthButtonDisabled || isNextMonthDisabled(date, maxAllowedDate ? maxAllowedDate : maxDate)

  return (
    <div className="Datepicker__custom-header">
      <button
        className={cn('Datepicker__header-controls', { disabled: disablePrevMonth })}
        onKeyDown={(e: any)=>{
          if(e.key==="Enter"){
            e.preventDefault()
            e.stopPropagation()
            decreaseMonth()
          }
        }}
        onClick={decreaseMonth}
        disabled={disablePrevMonth}
        type="button"
        aria-label='Previous Month'
      >
        <Icon icon="LeftArrowGray" />
      </button>
      <Dropdown type="select" list={monthList} closeAfterSelect={true} />
      {!_isEmpty(yearList) && <Dropdown type="select" list={yearList} closeAfterSelect={true} />}

      <button
        className={cn('Datepicker__header-controls', { disabled: disabledNextMonth })}
        onKeyDown={(e: any)=>{
          if(e.key==="Enter"){
            e.preventDefault()
            e.stopPropagation()
            increaseMonth()
          }
        }}
        onClick={increaseMonth}
        disabled={disabledNextMonth}
        type="button"
        aria-label='Next Month'
      >
        <Icon icon="RightArrowGray" />
      </button>
    </div>
  )
}

export default Header
