import { Registry } from '@react-registry'
import { v4 } from 'uuid'
import { Node, Element } from 'slate'
import { ElementWithType } from '../../../../../utils/types'

export const generateId = () => v4().split('-').join('')


export const withId = editor => {
    const { apply } = editor;

    editor.apply = operation => {
        let uid = generateId();

        if (operation.type === "split_node") {
            const node = Node.get(editor, operation["path"]);
            if (Element.isElement(node)) {
                return apply({
                    ...operation,
                    properties: {
                        ...operation.properties,
                        uid
                    }
                });
            }
        }

        if (operation.type === "insert_node" && Element.isElement(operation['node'])) {
            const node = operation['node'] as ElementWithType;
            if (!node['attrs']) node['attrs'] = {};
            //removing the below code as for v2, we do not need to set 'dirty' attribute

            return apply({
                ...operation,
                node: {
                    uid,
                    ...operation["node"]
                }
            });
        }

        return apply({ ...operation });
    }

    return editor;
}

Registry.register(withId, {
    id: 'withId',
    registry: 'v2.plugin'
})