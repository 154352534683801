//@ts-nocheck
import axios from "axios";
import Q, { handleUpload } from '../../../../../../lib/requestModule'
import { only_query, isBlt } from "../../../../../../../../utils/helpers/query";

function ClearPrevRequest() {
  let prevRequests = {};
  return {
    addReq: function (reqName) {
      prevRequests[reqName] = axios.CancelToken.source();
      return prevRequests[reqName];
    },
    cancelAll: function () {
      Object.keys(prevRequests).forEach(key => {
        const cancelToken = prevRequests[key];
        cancelToken && cancelToken.cancel();
      });
      prevRequests = {}
    }
  }
}

const { addReq, cancelAll } = ClearPrevRequest();

export const fetchEntries = async (props) => {
  let { contentTypeUid, editor, limitPerPage, query, skipEntries } = props;
  const requestProps = editor['requestProps'] || {};
  let { apiKey, authToken, assetUrl, locale, branch, isPlugin, customizedPagination } = requestProps
  const queryObj = {};
  if (!query) {
    query = ''
  } else {
    if (!customizedPagination) {
      queryObj['typeahead'] = query;
    } else {
      if (isBlt(query)) {
        queryObj['query'] = `{ "uid": "${query}" }`;
      } else {
        queryObj['query'] = `{ "title": { "$regex": "${query}", "$options": "i" } }`;
      }
    }
    query = `&${new URLSearchParams(queryObj).toString()}`;
    cancelAll();
  }
  let onlyFields = only_query(['_version', 'locale', 'uid', 'ACL', 'created_at', 'created_by', 'title', 'updated_at', 'updated_by', 'publish_details', 'url'])
  let url = `${assetUrl}/content_types/${contentTypeUid}/entries?desc=updated_at${query}&include_publish_details=true&locale=${locale}&limit=${limitPerPage}&skip=${skipEntries}&${onlyFields}`
  let countUrl = `${assetUrl}/content_types/${contentTypeUid}/entries?locale=${locale}${query}&count=true`
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authtoken'] = authToken);
  branch && (headers['branch'] = branch);
  let res = {};
  if (isPlugin) {
    res = await Q({
      action: 'getEntries',
      content_type_uid: contentTypeUid,
      params: {
        desc: 'updated_at',
        include_publish_details: true,
        locale: locale,
        include_count: true,
        limit: limitPerPage,
        skip: skipEntries,
        ...queryObj
      }
    })
  } else {
    const fetchEntriesCall = await axios({
      method: 'GET',
      url,
      headers,
      cancelToken: addReq(`${contentTypeUid}_${skipEntries}`).token
    }).then(res => {
      const { entries } = res['data'];
      if (customizedPagination) {
        const newCount = skipEntries + entries.length;
        res['data']['count'] = entries.length >= limitPerPage ? newCount + limitPerPage : newCount;
      }
      return res
    });
    const countCall = async () => {
      if (customizedPagination) {
        return {
          data: { entries: 0 }
        };
      } else {
        return await axios({
          method: 'GET',
          url: countUrl,
          headers
        })
      }
    };
    res = await Promise.all([
      fetchEntriesCall,
      countCall()
    ]).then(_res => {
      res = _res[0].data;
      if (!customizedPagination) {
        res['count'] = _res[1].data.entries;
      }
      return {
        data: res
      };
    }).catch(err => {
      console.log(err, 'ERROR');
    })
  }

  // @ts-ignore
  let resJson = await res.data

  const modifiedEntries = resJson.entries.map((entry) => {
    return {
      ...entry,
      key: entry['uid'],
    }
  })
  resJson.entries = modifiedEntries
  return resJson
}
