import { elementCategories } from '../../../elements/Element/elementCategories';
import { leafCategories } from '../../../elements/Leaf/leafCategories';


export const getCleanRegistry = (registry) => {
  return Object.entries(registry).map(([key, val]) => ({ id: key, value: val['components'][0]['component'] }));
}

export const filterIsContentStack = (elements) => {
  return elements.filter(element => element.value.isContentStackElement);
}

const conditionFromType = {
  'mainToolbar': 'inMainToolbar',
  'hoveringToolbar': 'inHoveringToolbar'
}

const toolbarFromType = {
  'basic': 'inBasicToolbar',
  'advance': 'inAdvToolbar',
}

export const organize = (elements, toolbarType = 'mainToolbar', toolbarMode = null, customToolbarComponents = [], type = '') => {
  // filter root categories
  let subcategories = [];
  let remainingElements = [];
  let subcategoryId = {};
  toolbarType = conditionFromType[toolbarType];
  elements.forEach(({ id, value }) => {

    switch (toolbarMode) {
      case 'basic': {
        if (!value?.inBasicToolbar) return;
        break;
      }
      case 'advance': {
        let instance = value?.[toolbarFromType[toolbarMode]];
        if (typeof (instance) === 'boolean' && instance === false) return;
        break;
      }
      case 'custom': {
        if (!(customToolbarComponents[type]?.includes(id))) return;
        break;
      }
      default:
        break
    }

    if (value?.toolbar && value?.toolbar[toolbarType] === false) {
      // intentionally left blank
    }
    else if (value.category) {
      let index = subcategoryId[value.category['id']];
      if (index) {
        subcategories[index - 1].push({ id, value });
      } else {
        subcategories.push([]);
        subcategoryId[value.category['id']] = subcategories.length;
        subcategories[subcategories.length - 1].push({ id, value });
      }
    } else if (value.iconName) {
      remainingElements.push({ id, value });
    }
  });

  let rootCategories = [];
  let seemore = [];
  let groupCategories = type === 'leaf' ? leafCategories : elementCategories;

  Object.entries(groupCategories)
    .filter(([key, value]) => value.rootCategory)
    .forEach(([key, value]) => {

      let instance = [];

      if (value.seeMore) {

        let id = groupCategories[value.seeMore]['id'];
        let idx = subcategoryId[id] - 1;
        if (subcategories[idx]) {
          if (value?.toolbar?.[toolbarType] !== false) {
            seemore.push([]);
            seemore[seemore.length - 1].push({
              details: groupCategories[value.seeMore],
              childrens: subcategories[idx]
            });
            if (value?.spread?.[toolbarType] === true) {
              remainingElements.push(...seemore[0][0].childrens)
              seemore.pop();
            }
          }
          delete subcategories[idx];
        }
      }

      value.subCategories.forEach(el => {
        let id = groupCategories[el]['id'];
        let idx = subcategoryId[id] - 1;
        if (subcategories[idx]) {
          let details = groupCategories[el];
          let shouldInclude = typeof (details?.toolbar?.[toolbarType]) !== 'boolean' || details.toolbar?.[toolbarType] !== false
          if (shouldInclude) {
            instance.push({ details: details, childrens: subcategories[idx] });
          }
          delete subcategories[idx];
        }
      });


      if (instance.length) {
        rootCategories.push(instance);
      }

    });

  subcategories.forEach(el => rootCategories.push(el));
  return { rootCategories, remainingElements, seemore };
}