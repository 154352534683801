import { createRowWithColSize } from "./createRowWithColSize"

export const defaultTable = (rows, cols) => {
    let colWidths = Array.from({ length: cols }).fill(250)
    let table = {
        type: 'table',
        attrs: {
            rows: rows,
            cols: cols,
            colWidths: colWidths
        },
        children: []
    }
    let tableBody = {
        type: 'tbody',
        attrs: {

        },
        children: []
    }
    let tableRow = Array.from({ length: rows }).fill(createRowWithColSize(cols))
    tableBody.children = tableRow.slice()
    table.children = [JSON.parse(JSON.stringify(tableBody))]
    return JSON.parse(JSON.stringify(table))

}