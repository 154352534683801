//@ts-nocheck
import React from 'react'
import {
  Field,
  TextInput,
  Checkbox
} from '../../../../../../../../../index'

//@ts-ignore
import '../style.css';


export const EmbedLinkBody = ({ singleSelectedRowId, setState, state }) => {
  const { linkDetails } = state;
  const url = singleSelectedRowId.key !== '' ? (singleSelectedRowId?.data?.url?.length ? singleSelectedRowId.data.url : 'undefined') : (linkDetails?.['href'] ? linkDetails?.['href'] : '/');

  return (
    <div style={{ padding: '20px' }}>
      <Field labelText="URL">
        <TextInput
          name="text"
          value={url}
          disabled={true}
          type="text"
          placeholder="Entry URL Here"
          autoComplete="off"
        />
      </Field>

      <Field labelText="Display text">
        <TextInput
          name="text"
          value={state.linkDetails.text}
          onChange={(e) => {
            e.persist();
            const text = e.target.value
            setState(prevState => ({
              ...prevState,
              linkDetails: {
                ...prevState.linkDetails,
                text: text
              }
            }))
          }}
          type="text"
          placeholder="Enter Link Text"
          autoComplete="off"
        />
      </Field>

      <div style={{ paddingLeft: '.55rem' }}>
        <Checkbox
          checked={state.linkDetails.inNewTab}
          text="Open link in new Tab"
          onChange={(e) => {
            e.persist();
            const val = e.target.checked
            setState(prevState => ({
              ...prevState,
              linkDetails: {
                ...prevState.linkDetails,
                inNewTab: val
              }
            }))
          }} />
      </div>
    </div>
  )
}