//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'

import { createRowWithColSize } from './createRowWithColSize'
import { elementCategories } from '../../elementCategories'
import { TableAddRowBeforeIcon } from '../../../utils/Icon'

export const insertRowBefore = (event, { editor }) => {
  const [row] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'tr'
    },
    mode: 'lowest',
  })
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest',
  })
  if (row && table) {
    if (table[0].attrs && table[0].attrs.cols) {
      const newRow = createRowWithColSize(table[0].attrs.cols)
      Transforms.insertNodes(editor, newRow, { at: row[1] })

      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table[0].attrs,
            rows: table[0].attrs.rows + 1,
          },
        },
        { at: table[1] }
      )
    }
  }
}
const InsertRowTopButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: insertRowBefore,
        isDisable: {
          func: insertRowBeforeButtonDisabler,
          params: { editor },
        },
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}

const shortcutInsertRowBefore = (event, editor, format, registryObj) => {
  insertRowBefore(event, { editor })
}
const insertRowBeforeButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest',
  })
  return !table
}
Registry.register(
  {
    iconName: <TableAddRowBeforeIcon />,
    IngressComponent: InsertRowTopButton,
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
    },
    shortcut: { key: 'ctrl+shift+up', callback: shortcutInsertRowBefore },
    canCustomize: false,
    title: 'Insert row above',
    isContentStackElement: true,
  },
  {
    id: 'table-insert-row-top',
    registry: 'v2.element',
  }
)
