import React from 'react'
import { Portal } from '../Toolbar/ToolbarWrapper'
import { cx } from '@emotion/css'

import styles from './style.module.css'
import { useOutsideClick } from './useOutsideClick'

type DivProps = React.HTMLProps<HTMLDivElement> & { handleOutsideClick?: (event: any) => any }

const Popover = React.forwardRef<HTMLDivElement, DivProps>(({ handleOutsideClick, ...props }, ref) => {
  useOutsideClick(ref, handleOutsideClick)
  return (
    <Portal>
      <div ref={ref} {...props} className={cx(styles['popover'], props.className)}>
        {props.children}
      </div>
    </Portal>
  )
})

export default Popover
