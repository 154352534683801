import React from 'react'

export const EditorProvider = React.createContext(null);

export const EditorPropsProvider = (Component) => props => {
    return (
        <EditorProvider.Consumer>
            {consumerProps => <Component {...consumerProps} {...props} />}
        </EditorProvider.Consumer>
    )

}
