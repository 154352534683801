import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="table-column-dnd-icon"
      {...props}
    >
      <rect y={2.002} width={3} height={3} rx={1.5} fill="#647696" />
      <rect x={4.5} y={2.002} width={3} height={3} rx={1.5} fill="#647696" />
      <rect x={9} y={2.002} width={3} height={3} rx={1.5} fill="#647696" />
      <rect y={7} width={3} height={3} rx={1.5} fill="#647696" />
      <rect x={4.5} y={7} width={3} height={3} rx={1.5} fill="#647696" />
      <rect x={9} y={7} width={3} height={3} rx={1.5} fill="#647696" />
    </svg>
  )
}

export default SvgComponent
