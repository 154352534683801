import React from 'react'
import Icon from '../Icon2'

interface Props {
  icon: string
  title: string | React.ReactNode
  description: string | React.ReactNode
}

export type Access = {
  title: string
  icon: string
  description: string
}

const OAuthAccessItem: React.FC<Props> = ({ icon, description, title }: Props) => {
  return (
    <div className="OAuth_Access_List flex-center">
      <div className="OAuth_Access_List_Icon">
        <Icon icon={icon} />
      </div>
      <div>
        <div className="OAuth_Access_List_Title">{title}</div>
        <div className="OAuth_Access_List_Desc">{description}</div>
      </div>
    </div>
  )
}

export default React.memo(OAuthAccessItem)
