//@ts-nocheck
import { Node, Editor } from 'slate'

export const setClass = (editor, classText, selection) => {
  if (selection) {
    Editor.addMark(editor, "classname", classText)
  }
}

export const setId = (editor, idText, selection) => {
  if (selection) {
    Editor.addMark(editor, "id", idText)
  }
}

export const getClass = (editor, selection) => {
  if (selection.focus.path) {
    const className = Node.leaf(editor, selection.focus.path)["classname"]
    if (className) {
      return className
    }
  }
}

export const getId = (editor, selection) => {
  if (selection.focus.path) {
    const id = Node.leaf(editor, selection.focus.path)["id"]
    if (id) {
      return id
    }
  }
}
