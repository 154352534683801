import React from 'react'
import { Registry } from '@react-registry'
import classNames from 'classnames'

const DIV = ({ attributes, children, attrs }) => {

    return (
        <div {...attributes} className={classNames(attrs['class-name'])} style={{ ...attrs.style }}>{children}</div>
    )
}

export default DIV

Registry.register(
    {
        Component: DIV,
        isContentStackElement: true,
        dndOptions: {
            DisableDND: true,
            DisableSelectionHalo: true
        }
    },
    { id: 'div', registry: 'element' }
)