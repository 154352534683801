import { Registry } from '@react-registry'
import { Text, Editor } from 'slate'

export function withRemoveFormatting(editor) {
  const { isInline, normalizeNode } = editor

  editor.isInline = (element) => {
    return element.type === 'removeFormatting' ? true : isInline(element)
  }

  editor.normalizeNode = (entry) => {
    //@ts-ignore
    if (Text.isText(entry[0]) && entry[0]?.removeFormatting) {
      Object.entries(entry[0]).forEach(([key]) => {
        if (key !== 'text') {
          Editor.removeMark(editor, key)
        }
      })
    }
    normalizeNode(entry)
  }
  return editor
}

Registry.register(withRemoveFormatting, { id: 'withRemoveFormatting', registry: 'plugin' })
