//@ts-nocheck
import { Editor, Transforms } from 'slate'

export const customCodeBlockKeydown = (event, editor) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    const { selection } = editor;
    if (selection) {
      const [code] = Editor.nodes(editor, {
        match: n => n.type === 'code'
      })
      if (code) {
        if (code[0].children[0].text === '') {
          return;
        }
        let textLength = code[0].children[0].text.length
        if (code[0].children[0].text[textLength - 1] === '\n' && code[0].children[0].text[textLength - 2] === '\n') {
          Editor.deleteBackward(editor)
          Editor.deleteBackward(editor)
          editor.insertBreak()
          Transforms.setNodes(editor, { type: 'p', children: [{ text: '' }] }, { at: editor.selection })
        }
        else {
          editor.insertText('\n')
        }
      }
      else {
        editor.insertBreak()
      }
    }
  }
}

