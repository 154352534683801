// @ts-nocheck
import { JsonNode } from 'components/JsonRTE/SuperChargedRte/utils/types'
import { Transforms, Node, Editor, Element, Path } from 'slate'
import { ElementWithType } from '../../../../../../../utils/types'

import { normalTextElement } from '../../paragraph/utils'
import { LIST_TYPES } from '../utils'
import { cloneDeep, isEqual } from 'lodash'

export const outdentListItem = (editor, li) => {
  const liPath = li[1]
  const liPathLastIndex = liPath.length - 1
  const liIndex = liPath[liPathLastIndex]

  const parentListPath = liPath.slice(0, liPathLastIndex)
  const parentList: any = Node.get(editor, parentListPath)
  const [parentLi] = Editor.nodes(editor, {
    at: liPath,
    match: (node, path) => Element.isElement(node) && node.type === 'li' && Path.isAncestor(path, liPath)
  })
  const parentLiPath = parentLi[1]
  const prevSiblings = parentList.children.slice(0, liIndex)
  const nextSiblings = parentList.children.slice(liIndex + 1, parentList.children.length)

  const previousSiblingList = cloneDeep(parentList)
  previousSiblingList.children = prevSiblings

  const nextSiblingList = {
    type: parentList.type,
    attrs: {},
    children: nextSiblings
  }

  const newCurrentLiPath = [...parentLiPath.slice(0, -1), parentLiPath.slice(-1)[0] + 1]

  let newCurrentLi = cloneDeep(li[0])
  
  const [listInsideCurrentLiEntry] = Editor.nodes(editor, {
    at: liPath,
    match: (node, path) => LIST_TYPES.includes(node.type) && Path.isAncestor(liPath, path)
  })

  if (nextSiblings) {
    if (listInsideCurrentLiEntry) {
      // If list already exists inside li
      const [listInsideCurrentLi, listInsideCurrentLiPath] = listInsideCurrentLiEntry

      // Add the nextSiblings to the pre existing list
      const newListInsideCurrentLi = cloneDeep(listInsideCurrentLi)
      newListInsideCurrentLi.children = [...newListInsideCurrentLi.children, ...nextSiblings]

      Editor.withoutNormalizing(editor, () => {
        Transforms.removeNodes(editor, { at: listInsideCurrentLiPath })
        Transforms.insertNodes(editor, newListInsideCurrentLi, { at: listInsideCurrentLiPath })
      })

      // Get the updated list-item
      newCurrentLi = Node.get(editor, liPath)
    } else {
      newCurrentLi.children = [{ type: 'fragment', children: [...newCurrentLi.children, nextSiblingList] }]
    }
  }

  Editor.withoutNormalizing(editor, () => {
    // replace the list with previousSiblingList if previous are available
    Transforms.removeNodes(editor, { at: parentListPath })
    if (prevSiblings.length) {
      Transforms.insertNodes(editor, previousSiblingList, { at: parentListPath })
    }
    Transforms.insertNodes(editor, newCurrentLi, { at: newCurrentLiPath })
  })

  Transforms.select(editor, newCurrentLiPath)
  Transforms.collapse(editor, { edge: 'start' })
}

// TODO: Refractor indentListItem
export const indentListItem = (editor, li) => {
  const liItem = li[0]
  const liPath = li[1]
  const liPathLastIndex = liPath.length - 1
  const parent: any = Node.get(editor, liPath.slice(0, liPathLastIndex))

  if (liPath[liPathLastIndex] == 0) {
    return null
  }

  const [previousLi, previousLiPath] = Editor.previous(editor, { at: liPath })

  const getPreviousList = (liPath) => {
    const liPathLastIndex = liPath.length - 1
    const previousLiPath = [...liPath.slice(0, liPathLastIndex), liPath[liPathLastIndex] - 1]
    const [previousListEntry] = Editor.nodes<Element>(editor, {
      at: previousLiPath,
      match: (node: JsonNode, path) =>
        Element.isElement(node) && LIST_TYPES.includes(node.type) && Path.isAncestor(previousLiPath, path)
    })

    return previousListEntry
  }

  const getNextList = (liPath) => {
    const [currentListEntry] = Editor.nodes<Element>(editor, {
      at: liPath,
      match: (node: JsonNode, path) =>
        Element.isElement(node) && LIST_TYPES.includes(node.type) && Path.isAncestor(liPath, path)
    })
    return currentListEntry
  }

  const prevListEntry = getPreviousList(liPath)
  const nextListEntry = getNextList(liPath)

  if (prevListEntry && nextListEntry) {
    const [nextList, nextListPath] = nextListEntry
    const [previousList, previousListPath] = prevListEntry

    const newPreviousList = cloneDeep(previousList)

    // Delete nextList
    Transforms.removeNodes(editor, { at: nextListPath })

    // Get the updated liItem
    const newCurrentLi = Node.get(editor, liPath)

    // Delete liItem
    Transforms.removeNodes(editor, { at: liPath })

    newPreviousList.children = [...newPreviousList.children, newCurrentLi, ...nextList.children]

    Editor.withoutNormalizing(editor, () => {
      // Replace the previousList with newPreviousList
      Transforms.removeNodes(editor, { at: previousListPath })
      Transforms.insertNodes(editor, newPreviousList, { at: previousListPath })
    })

    Transforms.select(editor, [...previousListPath, previousList.children.length])
  } else if (prevListEntry) {
    const [previousList, previousListPath] = prevListEntry

    const newPreviousList = cloneDeep(previousList)

    const newCurrentLi = liItem
    // Delete liItem
    Transforms.removeNodes(editor, { at: liPath })

    newPreviousList.children = [...newPreviousList.children, newCurrentLi]

    Editor.withoutNormalizing(editor, () => {
      // Replace the previousList with newPreviousList
      Transforms.removeNodes(editor, { at: previousListPath })
      Transforms.insertNodes(editor, newPreviousList, { at: previousListPath })
    })
    Transforms.select(editor, [...previousListPath, previousList.children.length])
  } else if (nextListEntry) {
    if (!Element.isElement(previousLi)) return

    const [nextList, nextListPath] = nextListEntry

    const previousListPath = [...previousLiPath, previousLi.children.length]

    // Delete nextList
    Transforms.removeNodes(editor, { at: nextListPath })

    // Get the updated liItem
    const newCurrentLi = Node.get(editor, liPath)

    // Delete liItem
    Transforms.removeNodes(editor, { at: liPath })

    const newPreviousList = {
      type: parent.type,
      attrs: {},
      children: [newCurrentLi, ...nextList.children]
    }

    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(editor, newPreviousList, { at: previousListPath })
      Transforms.select(editor, [...previousListPath, 0])
      const span = [
        [...previousLiPath, 0],
        [...previousLiPath, previousLi.children.length]
      ]
      // @ts-ignore
      Transforms.wrapNodes(editor, { type: 'fragment' }, { at: span })

    })
  } else {
    if (!Element.isElement(previousLi)) return

    const previousListPath = [...previousLiPath, previousLi.children.length]

    // Get the updated liItem
    const newCurrentLi = liItem

    // Delete liItem
    Transforms.removeNodes(editor, { at: liPath })

    const newPreviousList = {
      type: parent.type,
      attrs: {},
      children: [newCurrentLi]
    }

    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(editor, newPreviousList, { at: previousListPath })
      Transforms.select(editor, [...previousListPath, 0])
      const span = [
        [...previousLiPath, 0],
        [...previousLiPath, previousLi.children.length]
      ]
      // @ts-ignore
      Transforms.wrapNodes(editor, { type: 'fragment' }, { at: span })
    })
  }
  Transforms.collapse(editor, { edge: 'start' })
}

export const exitList = (editor) => {
  if (editor.selection) {
    const [listItemObjPath] = Editor.nodes(editor, {
      match: (n: ElementWithType) => {
        return n.type === 'li'
      },
      mode: 'lowest'
    })

    if (listItemObjPath) {
      const [listItem, listItemPath] = listItemObjPath
      // check if text exist
      if (Node.string(listItem) === '') {
        const [listParentObjPath] = Editor.nodes(editor, {
          match: (n: any) => {
            return LIST_TYPES.includes(n.type)
          }
        })

        if (listParentObjPath) {
          const listParentPath = listParentObjPath[1]

          const nextPath = listParentPath.slice()
          nextPath[nextPath.length - 1] += 1

          Transforms.insertNodes(editor, normalTextElement, { at: nextPath })
          Transforms.removeNodes(editor, { at: listItemPath })

          const [para] = Editor.nodes(editor, {
            at: listParentPath
          })
          try {
            Transforms.select(editor, nextPath)
          } catch (e) {
            Transforms.select(editor, listParentPath)
          }
          return true
        }
      } else {
        let newLiNode = { type: 'li', children: [{ type: 'fragment', children: [{ text: '' }] }] }

        const [listInsideLi] = Editor.nodes(editor, {
          at: listItemPath,
          match: (node, path) => LIST_TYPES.includes(node.type) && Path.isAncestor(listItemPath, path)
        })

        if (listInsideLi) {
          newLiNode = {
            type: 'li',
            children: [
              { type: 'fragment', children: [{ type: 'fragment', children: [{ text: '' }] }, listInsideLi[0]] }
            ]
          }
          Transforms.removeNodes(editor, { at: listInsideLi[1] })
        }

        const newPath = [...listItemPath.slice(0, -1), listItemPath.slice(-1)[0] + 1]
        Transforms.insertNodes(
          editor,
          // @ts-ignore
          newLiNode,
          { at: newPath }
        )
        Transforms.select(editor, newPath)
        Transforms.collapse(editor, { edge: 'start' })
        return true
      }
    }
  }
}