const Q = (body) => {
  //@ts-ignore
  const postRobot = window.postRobot;
  return postRobot.sendToParent('stackQuery', body);
}

export const getConfigForApp = (body) => {
  //@ts-ignore
  const postRobot = window.postRobot;
  return postRobot.sendToParent('getConfig', body);
}

export const handleUpload = async (files: FileList, folderUid = "") => {
  const _files: File[] = [];
  Array.from(files).forEach(_ => {
    const file = new File([_], _.name, { type: _.type });
    _files.push(file);
  })
  const uid = new Date().getUTCMilliseconds();
  //@ts-ignore
  const postRobot = window.postRobot;
  const uploadReadyListener = postRobot.on(`uploadReady_${uid}`, function () {
    window.parent.postMessage({
      type: `upload_${uid}`,
      files: _files,
      parentFolderUid: folderUid
    }, '*');

    uploadReadyListener.cancel();
    return Promise.resolve({});
  });
  await postRobot.sendToParent('stackOptionsQuery', {
    action: `upload_${uid}`,
    uid
  });
  return postRobot.sendToParent(`upload_${uid}`, {})
}

export default Q