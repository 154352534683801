import kebbab from 'lodash/kebabCase'

const ELEMENT_TYPES: { [key: string]: Function } = {
  blockquote: (attrs: string, child: string) => {
    return `<blockquote${attrs}>${child}</blockquote>`
  },
  h1: (attrs: any, child: any) => {
    return `<h1${attrs}>${child}</h1>`
  },
  h2: (attrs: any, child: any) => {
    return `<h2${attrs}>${child}</h2>`
  },
  h3: (attrs: any, child: any) => {
    return `<h3${attrs}>${child}</h3>`
  },
  h4: (attrs: any, child: any) => {
    return `<h4${attrs}>${child}</h4>`
  },
  h5: (attrs: any, child: any) => {
    return `<h5${attrs}>${child}</h5>`
  },
  h6: (attrs: any, child: any) => {
    return `<h6${attrs}>${child}</h6>`
  },
  img: (attrs: any, child: any, figureStyles: any) => {
    if (figureStyles.fieldsEdited.length === 0) {
      return `<img${attrs}/>`
    }
    let img = figureStyles.anchorLink ? `<a ${figureStyles.anchorLink}><img${attrs}/></a>${child}` : `<img${attrs} />`
    let caption = figureStyles.caption
      ? figureStyles.alignment === 'center'
        ? `<figcaption  style = "text-align: center;" ${figureStyles?.captionAttrs ? figureStyles.captionAttrs : ""}>${figureStyles.caption}</figcaption>`
        : `<figcaption ${figureStyles?.captionAttrs ? figureStyles.captionAttrs : ""}>${figureStyles.caption}</figcaption>`
      : ''
    let align = figureStyles.position
      ? `<figure ${figureStyles.position}>${img}${caption}</figure>`
      : figureStyles.caption
        ? `<figure>${img}${caption}</figure>`
        : `${img}`
    return `${align}`
  },
  figure: (attrs: any, child: any, figureStyles: any) => {    
    return `<figure${attrs}>${child}</figure>`
  },

  embed: (attrs: any, child: any) => {
    return `<iframe${attrs}>${child}</iframe>`
  },
  'social-embeds': (attrs: any, child: any) => {
    return `<iframe${attrs} data-type="social-embeds"></iframe>`
  },
  p: (attrs: any, child: any) => {
    return `<p${attrs}>${child}</p>`
  },
  ol: (attrs: any, child: any) => {
    return `<ol${attrs}>${child}</ol>`
  },
  ul: (attrs: any, child: any) => {
    return `<ul${attrs}>${child}</ul>`
  },
  code: (attrs: any, child: any) => {
    return `<pre${attrs}>${child}</pre>`
  },
  li: (attrs: any, child: any) => {
    return `<li${attrs}>${child}</li>`
  },
  a: (attrs: any, child: any) => {
    return `<a${attrs}>${child}</a>`
  },
  table: (attrs: any, child: any) => {
    return `<table${attrs}>${child}</table>`
  },
  tbody: (attrs: any, child: any) => {
    return `<tbody${attrs}>${child}</tbody>`
  },
  thead: (attrs: any, child: any) => {
    return `<thead${attrs}>${child}</thead>`
  },
  tr: (attrs: any, child: any) => {
    return `<tr${attrs}>${child}</tr>`
  },
  td: (attrs: any, child: any) => {
    return `<td${attrs}>${child}</td>`
  },
  th: (attrs: any, child: any) => {
    return `<th${attrs}>${child}</th>`
  },
  'check-list': (attrs: any, child: any) => {
    return `<p${attrs}>${child}</p>`
  },
  row: (attrs: any, child: any) => {
    return `<div${attrs}>${child}</div>`
  },
  trgrp: (attrs: any, child: any) => {
    return child
  },
  column: (attrs: any, child: any) => {
    return `<div${attrs}>${child}</div>`
  },
  'grid-container': (attrs: any, child: any) => {
    return `<div${attrs}>${child}</div>`
  },
  'grid-child': (attrs: any, child: any) => {
    return `<div${attrs}>${child}</div>`
  },
  hr: (attrs: any, child: any) => {
    return `<hr/>`
  },
  span: (attrs: any, child: any) => {
    return `<span${attrs}>${child}</span>`
  },
  div: (attrs: any, child: any) => {
    return `<div${attrs}>${child}</div>`
  },
  reference: (attrs: any, child: any, extraAttrs: any) => {
    if (extraAttrs?.displayType === 'inline') {
      return `<span${attrs}>${child}</span>`
    } else if (extraAttrs?.displayType === 'block') {
      return `<div${attrs}>${child}</div>`
    } else if (extraAttrs?.displayType === 'link') {
      return `<a${attrs}>${child}</a>`
    } else if (extraAttrs?.displayType === 'asset' || extraAttrs?.displayType === 'display') {
      //added displayType = 'display' as in JSON RTE, we set display type for image assets as 'display'
      return `<figure${attrs}>${child}</figure>`
    }
    return `<span${attrs}>${child}</span>`
  },
  inlineCode: (attrs: any, child: any) => {
    return
  },
  fragment: (attrs: any, child: any) => {
    return child
  },
  style: (attrs: any, child: any, extraAttrs: any) => {
    return `<style ${attrs}>${extraAttrs['style-text']}</style>`
  },
  script: (attrs: any, child: any) => {
    return `<script ${attrs}>${child}</script>`
  },
  header: (attrs: any, child: any) => {
    return `<header ${attrs}>${child}</header>`
  }
}

export const toRedactor = (jsonValue: any, parent: string = '') => {
  if (jsonValue.hasOwnProperty('text')) {
    let text = jsonValue['text']
    if (parent !== 'embed') { // preserve iframe descendants
      text = text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
    }
    if (text.includes(String.fromCharCode(160))) {
      text = text.replace(/[\u00a0]/g, '&nbsp;')
    }
    if (jsonValue['break']) {
      text += `<br/>`
    }
    if(text.includes('\u200b')){
      text = text.replace(/[\u200b]/g, '<wbr>')
    }
    if(text.includes('\u00AD')){
      text = text.replace(/[\u00AD]/g, '&shy;')
    }
    if (jsonValue.text.includes('\n')) {
      text = text.replace(/\n/g, '<br/>')
    }
    if (jsonValue['bold']) {
      text = `<strong>${text}</strong>`
    }
    if (jsonValue['italic']) {
      text = `<em>${text}</em>`
    }
    if (jsonValue['underline']) {
      text = `<u>${text}</u>`
    }
    if (jsonValue['strikethrough']) {
      text = `<del>${text}</del>`
    }
    if (jsonValue['subscript']) {
      text = `<sub>${text}</sub>`
    }
    if (jsonValue['superscript']) {
      text = `<sup>${text}</sup>`
    }
    if (jsonValue['inlineCode']) {
      text = `<span data-type='inlineCode'>${text}</span>`
    }
    if (jsonValue['attrs']) {
      const { style } = jsonValue['attrs']
      if (style) {
        let attrsStyle = ''
        if (style.color) {
          attrsStyle = `color:${style.color};`
        }
        if (style['font-family']) {
          attrsStyle += `font-family:"${style['font-family']}";`
        }
        if (style['font-size']) {
          attrsStyle += `font-size: ${style['font-size']};`
        }
        if (attrsStyle !== '') {
          text = `<span style='${attrsStyle}'>${text}</span>`
        }
      }
    }
    return text
  }
  let children: any = ''
  if (jsonValue.children) {
    children = Array.from(jsonValue.children).map(child => toRedactor(child, jsonValue['type']))
    if (jsonValue['type'] === 'blockquote') {
      children = children.map((child: any) => {
        if (child === '\n') {
          return '<br/>'
        }
        return child
      })
    }
    children = children.join('')
  }

  if (!Object.keys(ELEMENT_TYPES).includes(jsonValue['type']) && jsonValue['type'] !== 'docs') {
    let attrs = ''
    Object.entries(jsonValue?.attrs?.['redactor-attributes'] || {}).forEach(([key, val]) => {
      attrs += val ? ` ${key}="${val}"` : ` ${key}`;
    })
    attrs = (attrs.trim() ? ' ' : '') + attrs.trim()
    return `<${jsonValue['type'].toLowerCase()}${attrs}>${children}</${jsonValue['type'].toLowerCase()}>`
  }

  if (ELEMENT_TYPES[jsonValue['type']]) {
    let attrs = ''
    let orgType
    let colWidths
    let figureStyles: any = {
      fieldsEdited: []
    }
    if (jsonValue.attrs) {
      let attrsJson: { [key: string]: any } = {}
      let allattrs = JSON.parse(JSON.stringify(jsonValue.attrs))
      let style = ''
      if (jsonValue.attrs['redactor-attributes']) {
        attrsJson = { ...allattrs['redactor-attributes'] }
      }
      if (jsonValue['type'] === 'img' || jsonValue['type'] === 'embed' || jsonValue['type'] === 'social-embeds') {
        attrsJson['src'] = allattrs['url']
      }
      if (jsonValue['type'] === 'img') {
        let inline = ''

        if (attrsJson['inline']) {
          inline = `display: flow-root;margin:0`
          delete attrsJson['width']
          delete attrsJson['style']
        }
        if (attrsJson['position']) {
          figureStyles.position =
            attrsJson['position'] === 'center'
              ? `style = "margin: auto; text-align: center;width: ${allattrs['width'] ? allattrs['width'] + 'px' : 'auto'
              };"`
              : `style = "float: ${attrsJson['position']}${inline ? `;${inline}` : ''};width: ${allattrs['width'] ? allattrs['width'] + 'px' : 'auto'
              };max-width:${allattrs['max-width'] ? allattrs['max-width'] + 'px' : 'auto'};"`
          figureStyles.alignment = attrsJson['position']
          figureStyles.fieldsEdited.push(figureStyles.position)
          delete attrsJson['position']
          attrsJson['width'] && delete attrsJson['width']
          attrsJson['style'] && delete attrsJson['style']
          attrsJson['height'] && delete attrsJson['height']
          attrsJson['max-width'] && delete attrsJson['max-width']
          allattrs['max-width'] && delete allattrs['max-width']
          allattrs['width'] && delete allattrs['width']
          if (allattrs['redactor-attributes']) {
            allattrs['redactor-attributes']['width'] && delete allattrs['redactor-attributes']['width']
            allattrs?.['redactor-attributes']?.['style'] && delete allattrs['redactor-attributes']['style']
            allattrs?.['redactor-attributes']?.['max-width'] && delete allattrs['redactor-attributes']['max-width']
          }
        }
        if (attrsJson['caption']) {
          figureStyles.caption = attrsJson['caption']
          figureStyles.fieldsEdited.push(figureStyles.caption)
          delete attrsJson['caption']
          delete allattrs['caption']
        }
        if (attrsJson['captionAttrs']) {
          const styleString = Object.entries(attrsJson['captionAttrs']).map((key) => {
            return `${key[0]}="${key[1]}"`
          }).join(' ')
          figureStyles.captionAttrs = styleString
          figureStyles.fieldsEdited.push(figureStyles.captionAttrs)
          delete attrsJson['captionAttrs']
          delete allattrs['captionAttrs']
        }
        if (attrsJson['anchorLink']) {
          let anchor = ''
          anchor = `href="${attrsJson['anchorLink']}"`
          if (attrsJson['target']) {
            anchor += ' target="_blank"'
          }
          figureStyles.anchorLink = `${anchor}`
          figureStyles.fieldsEdited.push(figureStyles.anchorLink)
          delete attrsJson['anchorLink']
          delete allattrs['anchorLink']
        }
        delete attrsJson['target']
      }
      if (jsonValue['type'] === 'a') {
        attrsJson['href'] = allattrs['url']
        delete allattrs['isBlock']
      }
      if (allattrs['orgType']) {
        orgType = allattrs['orgType']
        delete allattrs['orgType']
      }
      if (allattrs['class-name']) {
        attrsJson['class'] = allattrs['class-name']
        delete allattrs['class-name']
      }
      if (attrsJson['width']) {
        let width = attrsJson['width']
        let height = attrsJson['height']
        if(!attrsJson['style']){
          if (width[width.length - 1] === '%') {
            style = `width: ${allattrs['width']}; height: ${attrsJson['height']
              ? Number(height)
                ? attrsJson['height'] + 'px'
                : attrsJson['height']
              : 'auto'
              };`
          } else {
            if (width && height) {
              style = `width: ${allattrs['width'] + 'px'}; height: ${attrsJson['height']
                ? Number(height)
                  ? attrsJson['height'] + 'px'
                  : attrsJson['height']
                : 'auto'
                };`
            }
          }
        }
      }
      if (jsonValue['type'] === 'embed') {
        let isIframe = jsonValue?.['attrs']?.['redactor-attributes']?.['allow']
        style = ''
        if (allattrs['style'])
          Object.keys(allattrs['style']).forEach((key) => {
            style += `${kebbab(key)}: ${allattrs.style[key]};`
          })
      }
      else {
        if (allattrs['width']) {
          let width = String(allattrs['width'])
          if (width.slice(width.length - 1) !== '%') {
            allattrs['width'] = String(allattrs['width'])
          }
        }
      }
      if (
        allattrs['style'] &&
        // jsonValue['type'] !== 'img' &&
        jsonValue['type'] !== 'social-embeds' &&
        jsonValue['type'] !== 'embed'
      ) {
        Object.keys(allattrs['style']).forEach((key) => {
          style += `${kebbab(key)}: ${allattrs.style[key]};`
        })
        delete allattrs['style']
      }

      if (allattrs['rows'] && allattrs['cols'] && allattrs['colWidths']) {
        delete allattrs['rows']
        delete allattrs['cols']
        colWidths = allattrs['colWidths']
        delete allattrs['colWidths']
      }
      if (allattrs['disabledCols']) {
        delete allattrs['disabledCols']
      }
      if (allattrs['colSpan']) {
        delete allattrs['colSpan']
      }
      if (allattrs['rowSpan']) {
        delete allattrs['rowSpan']
      }

      attrsJson = { ...attrsJson, ...allattrs, style: style }

      if (jsonValue['type'] === 'reference') {
        if (attrsJson['type'] === 'entry') {
          attrsJson['data-sys-entry-uid'] = allattrs['entry-uid']
          delete attrsJson['entry-uid']
          attrsJson['data-sys-entry-locale'] = allattrs['locale']
          delete attrsJson['locale']
          attrsJson['data-sys-content-type-uid'] = allattrs['content-type-uid']
          delete attrsJson['content-type-uid']
          attrsJson['sys-style-type'] = allattrs['display-type']
          delete attrsJson['display-type']
        } else if (attrsJson['type'] === 'asset') {
          attrsJson['data-sys-asset-filelink'] = allattrs['asset-link']
          delete attrsJson['asset-link']
          attrsJson['data-sys-asset-uid'] = allattrs['asset-uid']
          delete attrsJson['asset-uid']
          attrsJson['data-sys-asset-filename'] = allattrs['asset-name']
          delete attrsJson['asset-name']
          attrsJson['data-sys-asset-contenttype'] = allattrs['asset-type']
          delete attrsJson['asset-type']
          //
          if (allattrs['asset-caption']) {
            attrsJson['data-sys-asset-caption'] = allattrs['asset-caption']
            delete attrsJson['asset-caption']
          }

          if (allattrs['asset-alt']) {
            attrsJson['data-sys-asset-alt'] = allattrs['asset-alt']
            delete attrsJson['aasset-alt']
          }

          if (allattrs['link']) {
            attrsJson['data-sys-asset-link'] = allattrs['link']
            delete attrsJson['link']
          }

          if (allattrs['position']) {
            attrsJson['data-sys-asset-position'] = allattrs['position']
            delete attrsJson['position']
          }

          if (allattrs['target']) {
            attrsJson['data-sys-asset-isnewtab'] = allattrs['target'] === '_blank'
            delete attrsJson['target']
          }
          if (!attrsJson['sys-style-type']) {
            attrsJson['sys-style-type'] = String(allattrs['asset-type']).indexOf('image') > -1 ? 'display' : 'download'
          }

          delete attrsJson['display-type']
        }
      }
      if (jsonValue['type'] === 'style') {
        figureStyles['style-text'] = attrsJson['style-text']
        delete attrsJson['style-text']
      }
      delete attrsJson['redactor-attributes']
      delete attrsJson['url']
      Object.entries(attrsJson).forEach((key) => {
        const isEmbedFullScrAttr = key[0] === 'allowfullscreen'
        if (key[0] === 'data-type' && jsonValue['type'] === 'social-embeds') {
          return
        }
        // hack to solve removal of async from script
        if (key[0] === 'async') {
          attrs += `async`
          return
        }
        return isEmbedFullScrAttr || key[1] ? (isEmbedFullScrAttr || key[1] !== '' ? (attrs += `${key[0]}="${key[1]}" `) : '') : ''
      })

      attrs = (attrs.trim() ? ' ' : '') + attrs.trim()
    }
    if (jsonValue['type'] === 'table') {
      let colWidths = jsonValue.attrs.colWidths
      let totalWidth = colWidths.reduce((a: any, b: any) => a + b, 0)
      var setCol = new Set(colWidths)
      if (!(setCol.size === 1 && jsonValue.attrs.cols * setCol.values().next().value === totalWidth)) {
        let col = ''
        Array.from(colWidths).forEach(
          (colWidth, index) => {
            const width = (colWidth as number / totalWidth) * 100
            col += `<col style="width:${width}%"/>`
          }
        )
        let colgroup = `<colgroup data-width='${totalWidth}'>${col}</colgroup>`
        children = colgroup + children

      }
    }
    if (jsonValue['type'] === 'check-list') {
      attrs = `data-checked='${jsonValue.checked}' data-type='checked'`
    }

    if (jsonValue['type'] === 'row') {
      attrs = `data-type='row' style="max-width:100%;display:flex;"`
    }

    if (jsonValue['type'] === 'column') {
      const { width } = jsonValue?.meta || {}
      attrs = `data-type='column' width="${width}" style="flex-grow: 0;flex-shrink: 0;position: relative;width:${width * 100
        }%; margin: 0 0.25rem;"`
    }

    if (jsonValue['type'] === 'grid-container') {
      const { gutter } = jsonValue.attrs
      attrs = `data-type='grid-container' gutter="${gutter}" style="display: flex; width: 100%; gap: ${gutter}px"`
    }

    if (jsonValue['type'] === 'grid-child') {
      const { gridRatio } = jsonValue.attrs
      attrs = `data-type='grid-child' grid-ratio="${gridRatio}" style="flex: ${gridRatio}"`
    }

    if (jsonValue['type'] === 'reference') {
      figureStyles.displayType = jsonValue?.attrs?.['display-type']
    }
    if (jsonValue['type'] === 'script' && jsonValue?.children?.[0]?.text) {
      children = jsonValue.children[0].text
    }
    if (jsonValue['type'] === 'span' && jsonValue.children.length === 1 && jsonValue.children[0].type === 'span') {
      if (Object.keys(jsonValue.attrs).length === 0) {
        return children
      }
    }

    if(['td','th'].includes(jsonValue['type'])){
      if(jsonValue?.['attrs']?.['void']) return ''
    }
    attrs = (attrs.trim() ? ' ' : '') + attrs.trim()
    return ELEMENT_TYPES[orgType || jsonValue['type']](attrs, children, figureStyles)
  }
  return children === '<p></p>' ? '' : children
}
