import React from 'react'
import { Transforms } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'

export const insertDivider = ({ event, editor }) => {
  event.preventDefault()
  Transforms.insertNodes(editor, {
    type: 'hr',
    children: [{ text: '' }]
  } as ElementWithType)
}

export const ShortcutAddDivider = (event, editor, format, registryObj) => {
  insertDivider({ event, editor })
}
