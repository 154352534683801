//@ts-nocheck

import React from 'react'
import AssetImage from './AssetImage/AssetImage';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from './Loader';
import './index.css';

export function GridComponent({ state, handleClick, updateSelection, selectedAssets, isMultiple, readOnly }: any) {
    return state.assets.map(asset => {
        return <AssetImage
            key={asset.uid}
            asset={asset}
            handleClick={handleClick}
            updateSelection={updateSelection}
            isMultiple={isMultiple}
            readOnly={readOnly}
            {...(selectedAssets && { checked: selectedAssets?.find(ele => ele.uid === asset.uid) })}
        ></AssetImage>
    })
}

export function ThumbnailView({ state, handleClick, loadMoreGrid, multiple, updateSelection: updateSelectionData, selectedAssets, readOnly }) {
    let [selectedData, setSelectedData] = React.useState(selectedAssets)

    const isMultiple = multiple
    const isBelowMaxSelectCondition = (selectedData) => isMultiple && multiple?.max && selectedData.length < multiple.max
    const hasNoLimit = isMultiple && typeof multiple === 'boolean'

    const updateSelection = (currentCheckStatus, asset) => {
        if (asset && asset.is_dir) handleClick(asset);
        else
            if (!currentCheckStatus) {
                setSelectedData(selectedData => {
                    const newSelectedAssets = [
                        ...(isMultiple ? selectedData : []),
                        ...(!isMultiple || (isMultiple && (hasNoLimit || isBelowMaxSelectCondition(selectedData)))
                            ? [asset]
                            : [])
                    ]
                    updateSelectionData(newSelectedAssets)
                    return newSelectedAssets
                })
            } else {
                setSelectedData(selectedData => {
                    const newSelectedAssets = selectedData.filter(ele => ele.uid !== asset.uid)
                    updateSelectionData(newSelectedAssets)
                    return newSelectedAssets
                })
            }
    }

    const getOnlySelectedRows = () => {
        if (readOnly) {
            return (
                <InfiniteScroll
                    dataLength={selectedData}
                    style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
                    scrollableTarget='scrollableDiv'
                >
                    {state.loading && <Loader />}
                    <GridComponent isMultiple={isMultiple} state={{ assets: selectedData }} readOnly={true} />
                </InfiniteScroll>
            )
        }
        else return null
    }

    return (
        <div id='scrollableDiv' data-testid="grid-view" style={{ height: 265, overflow: 'auto', fontFamily: 'Inter' }}>
            {!readOnly && <InfiniteScroll
                loader={<Loader />}
                dataLength={state.assets.length}
                hasMore={state.assets.length < state.count}
                next={loadMoreGrid}
                style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
                scrollableTarget='scrollableDiv'
            >
                {state.loading && <Loader />}
                <GridComponent isMultiple={isMultiple} state={state} selectedAssets={selectedData} handleClick={handleClick} updateSelection={updateSelection} />
            </InfiniteScroll>}
            {getOnlySelectedRows()}
        </div>
    )
}
