import clone from 'lodash/clone'
import indexOf from 'lodash/indexOf'

export const formatTime = (date) => {
  date = new Date(date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours < 10 ? `0${hours}` : hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return (
    new Date(date).toString().split(" ").slice(1, 4).join(" ") +
    ", " +
    strTime
  );
};

export const getAssetType = (content_type) => {
  let assetType = 'Document'
  if (content_type) {
    switch (true) {
      case content_type.indexOf('image') > -1:
        assetType = null;
        break
      case content_type.indexOf('folder') > -1:
        assetType = 'Group'
        break
      case content_type.indexOf('pdf') > -1:
        assetType = 'Pdf'
        break
      case content_type.indexOf('excel') > -1:
      case content_type.indexOf('presentation') > -1:
      case content_type.indexOf('document') > -1:
      case content_type.indexOf('json') > -1:
      case content_type.indexOf('text/plain') > -1:
      case content_type.indexOf('text/html') > -1:
        assetType = 'Document'
        break
      case content_type.indexOf('zip') > -1:
        assetType = 'Archive'
        break
      case content_type.indexOf('video') > -1:
        assetType = 'Video'
        break
      case content_type.indexOf('audio') > -1:
        assetType = 'Audio'
        break
    }
    if (content_type.indexOf('image/tiff') > -1) {
      assetType = 'Document'
    }

    return assetType
  }

  return assetType
}

const convertFiletype = function (fileArray, onlyImage) {
  if (fileArray && fileArray.length && typeof fileArray === 'object' && !onlyImage) {
    for (var i = 0; i < fileArray.length; i++) {
      fileArray[i] = fileArray[i].trim();
    }
  } else if (fileArray && fileArray.length && !onlyImage) {
    fileArray = fileArray.split(/[ ,]+/);
  } else if (onlyImage) {
    return "image/*";
  } else {
    return;
  }

  var temparray = clone(fileArray);
  if (temparray) {
    for (var j = 0; j <= temparray.length - 1; j++) {
      temparray[j] = temparray[j].trim();
      if (j === 0) {
        temparray[j] = "." + temparray[j];
      } else {
        temparray[j] = " ." + temparray[j];
      }
    }
  }
  return temparray.toString();
};

const checkFileTypeValidation = function (asset, extensions) {
  var acceptFileTypes = extensions.split(/[ ,]+/);
  var getExtension = Array.isArray(asset.filename) ? asset.filename[0].split(".") : asset.filename.split(".");
  var sExtension = getExtension[getExtension.length - 1];

  if (getExtension.length > 1 && acceptFileTypes.length) {
    if (indexOf(acceptFileTypes, sExtension) >= 0) {
      return false;
    } else {
      asset.fileTypeError = true;
      var filetypes = convertFiletype(acceptFileTypes, false);
      // asset.errorMessage += "Unable to upload file. Permitted file types are " + filetypes + ".";
      asset.errorMessage += "Permitted file types are " + filetypes + ".";
      return true;
    }
  }
}

const checkFileSizeValidation = function (asset, minSizeProp, maxSizeProp) {
  minSizeProp = minSizeProp * 1024 * 1024
  maxSizeProp = maxSizeProp * 1024 * 1024
  var expectedFileMinSize = (minSizeProp === "" || !minSizeProp) ? 0 : minSizeProp;
  var expectedFileMaxSize = (maxSizeProp === "" || !maxSizeProp) ? 734003200 : maxSizeProp;
  var OgFilesizeinMb = asset.file_size;

  if (!OgFilesizeinMb || (OgFilesizeinMb <= expectedFileMaxSize) && (OgFilesizeinMb >= expectedFileMinSize)) {
    return false;
  } else {
    var minSize = (minSizeProp / (1024 * 1024)) || 0,
      maxSize = (maxSizeProp === null || !maxSizeProp) ? 700 : (maxSizeProp / (1024 * 1024));
    asset.errorMessage += "The file size should be within " + minSize + " MB and " + maxSize + " MB.";
    return true;
  }
};

export const checkFileValidation = (asset, filter) => {
  asset.errorMessage = "";
  var fileTypeError: any = false, fileSizeError = false;
  if (!asset.is_dir) {

    if (filter.extensions)
      fileTypeError = checkFileTypeValidation(asset, filter.extensions);

    if (filter.minSize || filter.maxSize)
      fileSizeError = checkFileSizeValidation(asset, filter.minSize, filter.maxSize);

    asset.err = (fileSizeError || fileTypeError);
    if (asset.err) {
      asset.errorMessage = "Unable to select file." + asset.errorMessage;
    }
  }
  if (asset.errorMessage) {
    asset.invalid_asset = true
  } else {
    asset.invalid_asset = false
  }
}