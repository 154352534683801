import React, {
  Component,
  RefObject,
  FocusEvent,
  KeyboardEvent,
  memo,
  ChangeEventHandler,
  FocusEventHandler
} from 'react'
import cn from 'classnames'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import Icon from '../Icon2'
import Tooltip from '../Tooltip/Tooltip'
import './TextInput.css'

export type TextInputProps = {
  /**
   * Lets you set the width of the input field
   */
  width?: 'small' | 'medium' | 'large' | 'x-large' | 'full'
  /**
   * Makes a field read-only
   */
  isReadOnly?: boolean
  /**
   * Define the type of input element
   */
  type?: 'text' | 'password' | 'email' | 'number' | 'search' | 'url' | 'date' | 'time' | string
  /**
   * Define the name of the input element
   */
  name?: string
  /**
   * Define the ID of the input element
   */
  id?: string
  /**
   * Provide the classnames of input element wrapper
   */
  className?: string
  /**
   * Provide the classnames of input element
   */
  inputClassName?: string
  /**
   * Pass the callback function on a copy event
   */
  onCopy?: (value: string) => void
  /**
   * Pass the DOM reference to input element
   */
  inputRef?: RefObject<HTMLInputElement>
  /**
   * Defines whether input has an error or not
   */
  error?: boolean
  /**
   * Triggers a blur event on the click of ESC button
   */
  willBlurOnEsc?: boolean
  /**
   * Provides text suggestions while typing
   */
  autoComplete?: 'on' | 'off' | 'new-password'
  /**
   * Lets you autofocus input element
   */
  autoFocus?: boolean
  /**
   * Define value of input element
   */
  value?: any
  /**
   * Show eye suffix tooltip for input element on hover
   */
  suffix?: React.ReactNode
  /**
   * Provides an option to switch between types: text and password
   */
  canShowPassword?: boolean
  /**
   * Pass the maximum length of characters to be allowed
   */
  maxLength?: number
  /**
   * Shows character length of input element value
   */
  showCharacterCount?: boolean
  /**
   * Pass an ID that you can use for testing purposes. It is applied as a data attribute (data-test-id).
   */
  testId?: string
  /**
   * Disables text input
   */
  disabled?: boolean
  /**
   * Define an input field as required
   */
  required?: boolean
  /**
   * Pass the callback function on a change event
   */
  onChange?: ChangeEventHandler<HTMLInputElement>
  /**
   * Pass the callback function on a blur event
   */
  onBlur?: FocusEventHandler<HTMLInputElement>
  /**
   * Pass the placeholder value
   */
  placeholder?: string
  /**
   * Pass the callback function on a keypress event
   */
  onKeyDown?: (value: any) => void
  /**
   * Provides the directionality of the text.
   */
  textDirection?: 'ltr' | 'rtl' | 'auto'
  /**
   * A new version of TextInput.
   */
  version?: 'v2'
  /**
   * Show heading icon on input element
   */
  prefix?: React.ReactNode
  /**
   * Provides an option to hide the character count error message.
   */
  hideCharCountError?: boolean
} & JSX.IntrinsicElements['input'] &
  typeof defaultProps

export type TextInputState = {
  value?: string
  visiblePassword?: boolean
}

interface RenderVisiblePasswordProps {
  togglePassworVisible: () => void
  visiblePassword: boolean
  type: 'text' | 'password' | 'email' | 'number' | 'search' | 'url' | 'date' | 'time' | string
  canShowPassword: boolean
}

const defaultProps = {
  disabled: false,
  isReadOnly: false,
  required: false,
  autoComplete: 'off',
  autoFocus: false,
  width: 'full',
  willBlurOnEsc: true,
  placeholder: 'Type something..',
  testId: 'cs-text-input',
  textDirection: 'auto',
  hideCharCountError: true
}

export const RenderVisiblePassword = memo(
  ({ togglePassworVisible, visiblePassword, type, canShowPassword }: RenderVisiblePasswordProps) => {
    if (type === 'password' && canShowPassword) {
      return (
        <span onClick={togglePassworVisible} className="TextInput__suffix__wrapper TextInput__password">
          {!visiblePassword ? (
            <Tooltip content={'View Password'} position="top">
              <Icon icon="Eye" />
            </Tooltip>
          ) : (
            <Tooltip content={'Hide Password'} position="top">
              <Icon icon="EyeClose" />
            </Tooltip>
          )}
        </span>
      )
    }
    return null
  }
)

const CharacterCount = ({ version, value, maxLength, showCharacterCount, hideCharCountError }) => {
  if (!showCharacterCount || !maxLength) {
    return null
  }
  const length = value?.length || 0

  if (version === 'v2') {
    if (length <= maxLength) {
      return <span className="TextInput__char-count">{`${length} / ${maxLength}`}</span>
    }

    if (!hideCharCountError) {
      return (
        <span className="TextInput__char-count TextInput__char-length--reached-text">
          Maximum character limit exceeded
        </span>
      )
    }
  } else {
    return (
      <span
        className={`TextInput__char-length${
          length === maxLength ? ' TextInput__char-length--reached' : ''
        }`}>{`${length} / ${maxLength}`}</span>
    )
  }
  return null
}

export class TextInput extends Component<TextInputProps, TextInputState> {
  static defaultProps = defaultProps

  state = {
    value: this.props.value,
    visiblePassword: false
  }

  UNSAFE_componentWillReceiveProps(nextProps: TextInputProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  handleFocus = (e: FocusEvent) => {
    if (this.props.disabled) {
      ;(e.target as HTMLInputElement).select()
    }
  }

  handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const ESC = 'Escape'
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
    if (e.key === ESC && this.props.willBlurOnEsc) {
      e.currentTarget.blur()
    }
  }

  handleChange = (e) => {
    e.persist()
    const value = e.target.value
    this.setState({ value })

    if (this.props.disabled || this.props.isReadOnly) return
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  renderSuffix = () => {
    if (this.props.suffix && !this.props.isReadOnly) {
      return <span className="TextInput__suffix__wrapper">{this.props.suffix}</span>
    }
    return null
  }

  renderPrefix = () => {
    if (this.props.prefix) {
      return <span className="TextInput__prefix__wrapper">{this.props.prefix}</span>
    }
    return null
  }

  renderIsReadOnly = () => {
    if (this.props.isReadOnly) {
      return (
        <span className="TextInput__read-only__wrapper">
          <Icon icon="LockThin" />
        </span>
      )
    }
    return null
  }

  togglePassworVisible = () => {
    this.setState({ visiblePassword: !this.state.visiblePassword })
  }

  handleClearSearch = (e: any) => {
    let { disabled, isReadOnly, onChange } = this.props
    if (disabled || isReadOnly) return
    let event: any = { target: { value: '' } }
    if (onChange) {
      onChange(event)
    }
    this.setState({ value: '' })
  }

  render() {
    const {
      className,
      inputClassName,
      placeholder,
      maxLength,
      disabled,
      required,
      isReadOnly,
      onChange,
      onBlur,
      onCopy,
      error,
      width,
      value,
      type,
      name,
      testId,
      id,
      inputRef,
      willBlurOnEsc,
      autoComplete,
      autoFocus,
      suffix,
      prefix,
      canShowPassword,
      showCharacterCount,
      textDirection,
      hideCharCountError,
      ...otherProps
    } = this.props

    const widthClass = `TextInput--${width}`
    const versionClass = `${otherProps?.version === 'v2' ? 'TextInput__v2' : ''}`
    const classNames = cn(['TextInput'], className, [widthClass], {
      'TextInput--disabled': disabled,
      'TextInput--negative': error || (maxLength > 0 && this.state.value?.length > maxLength),
      'TextInput--with-count': showCharacterCount,
      'TextInput--suffix': !!suffix || type === 'password',
      TextInput__prefix: prefix,
      'TextInput__read-only': isReadOnly
    })

    const suffixNode = this.renderSuffix()
    const prefixNode = this.renderPrefix()
    const isReadOnlyNode = this.renderIsReadOnly()

    return (
      <div data-test-id={testId} className={classNames}>
        {prefixNode}
        {suffixNode}
        {isReadOnlyNode}
        <RenderVisiblePassword
          canShowPassword={canShowPassword}
          type={type}
          visiblePassword={this.state.visiblePassword}
          togglePassworVisible={this.togglePassworVisible}
        />
        <input
          onKeyDown={this.handleKeyDown}
          aria-label={name}
          className={`TextInput__input${inputClassName ? ` ${inputClassName}` : ''}${
            versionClass ? ` ${versionClass}` : ''
          }`}
          id={id}
          name={name}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onFocus={this.handleFocus}
          dir={textDirection}
          onChange={this.handleChange}
          onWheel={(e) => e.currentTarget.blur()}
          value={this.state.value}
          type={!this.state.visiblePassword ? type : 'text'}
          ref={inputRef}
          {...otherProps}
        />
        {type == 'search' && (
          <div
            data-test-id="textInput-search-clear"
            onClick={this.handleClearSearch}
            className="TextInput__cancel-icon">
            {this.state.value && (
              <Tooltip content="Cancel Search" position="top">
                <Icon icon="Cancel" />
              </Tooltip>
            )}
          </div>
        )}
        <CharacterCount
          version={otherProps.version}
          value={this.state.value}
          maxLength={maxLength}
          showCharacterCount={showCharacterCount}
          hideCharCountError={hideCharCountError}
        />
      </div>
    )
  }
}

export default withDeprecatedProp(TextInput, {
  showCharCount: 'showCharacterCount',
  toggleVisiblePassword: 'canShowPassword'
})
