export const srcDoc = ([message]) => `
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
</head>

<body>
    <div class="wrapper" data-testid="src-doc">

        <img class="icon"
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA0NSA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNTk2NyAzNy4xNzAyTDIyLjczMjMgNDQuNDc0M0wzNS43MDc4IDM3LjEzMTNDMzYuMzMyMSAzNi43NjAyIDM2LjczODYgMzYuMjc5NyAzNy4wNTQzIDM1LjUxODJDMzcuNDIxMSAzNC42MzM4IDM3LjY2NjcgMzMuMzMzIDM3LjY2NjcgMzEuNTMzMlYxNi42Njg3TDIyLjYxMTIgNy41ODAyNkw3LjMzMzM0IDE2LjY5MTdWMzEuNTMzMkM3LjMzMzM0IDMzLjg0NSA4LjU2MTI3IDM1Ljk4MjkgMTAuNTU4MiAzNy4xNDc4TDEwLjU5NjcgMzcuMTcwMlpNMC44MzMzNDQgMTNMMjIuNjMxNCAwTDQ0LjE2NjcgMTNWMzEuNTMzMkM0NC4xNjY3IDM2LjE1NjkgNDIuOTQ4OSA0MC40MzI2IDM4Ljk1NTEgNDIuNzYyM0wyMi42MzE0IDUyTDcuMjgzMDIgNDIuNzYyM0MzLjI4OTE5IDQwLjQzMjYgMC44MzMzNDQgMzYuMTU2OSAwLjgzMzM0NCAzMS41MzMyVjEzWiIgZmlsbD0iI0RERTNFRSIvPg0KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi45Nzk3IDE2LjQ3OTNDMTQuMjQ4OSAxNS4yMTAxIDE2LjMwNjcgMTUuMjEwMSAxNy41NzU5IDE2LjQ3OTNMMjIuNTAwMSAyMS40MDM1TDI3LjQyNDIgMTYuNDc5M0MyOC42OTM0IDE1LjIxMDEgMzAuNzUxMiAxNS4yMTAxIDMyLjAyMDQgMTYuNDc5M0MzMy4yODk2IDE3Ljc0ODUgMzMuMjg5NiAxOS44MDYzIDMyLjAyMDQgMjEuMDc1NUwyNy4wOTYzIDI1Ljk5OTdMMzIuMDIwNCAzMC45MjM4QzMzLjI4OTYgMzIuMTkzIDMzLjI4OTYgMzQuMjUwOCAzMi4wMjA0IDM1LjUyQzMwLjc1MTIgMzYuNzg5MiAyOC42OTM0IDM2Ljc4OTIgMjcuNDI0MiAzNS41MkwyMi41MDAxIDMwLjU5NTlMMTcuNTc1OSAzNS41MkMxNi4zMDY3IDM2Ljc4OTIgMTQuMjQ4OSAzNi43ODkyIDEyLjk3OTcgMzUuNTJDMTEuNzEwNSAzNC4yNTA4IDExLjcxMDUgMzIuMTkzIDEyLjk3OTcgMzAuOTIzOEwxNy45MDM5IDI1Ljk5OTdMMTIuOTc5NyAyMS4wNzU1QzExLjcxMDUgMTkuODA2MyAxMS43MTA1IDE3Ljc0ODUgMTIuOTc5NyAxNi40NzkzWiIgZmlsbD0iI0RERTNFRSIvPg0KPC9zdmc+DQo=' />
        <h1 class="message">${message}</h1>
    </div>

</body>
<style>
    *{
        margin: 0;
        bottom: 0;
        box-sizing: border-box;
    }
    html {
        font-size: 62.5%;
    }

    body {
        font-family: Inter;
        color: #475161;
        display: grid;
        place-content: center;
        min-height: 100vh;
        border: 1px solid #A9B6CB;
        border-radius: .8em;
    }
    .wrapper{
        display: grid;
        gap: 1em;
        justify-content: center;
        justify-items: center;
    }
    .icon {
        height: 5.2em;
    }

    .message {
        font-size: 1.4em;
        line-height: 2.6em;
        font-weight: 200;
    }
</style>

</html>
`