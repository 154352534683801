import React from 'react'
import { SeeMoreIcon } from '../utils/Icon'

export const leafCategories = {
  // see more
  TEXT_STYLE_SEE_MORE: {
    id: 'text_style_see_more',
    title: 'More Text Styles',
    rootCategory: false,
    openOnFullScreen: true,
    toolbar: {
      inHoveringToolbar: false,
    },
    icon: <SeeMoreIcon />,
  },
  // rootCategory
  TEXT_STYLE: {
    id: 'text_style',
    title: 'Text style',
    rootCategory: true,
    subCategories: [],
    seeMore: 'TEXT_STYLE_SEE_MORE',
    spread: {
      inHoveringToolbar: true
    }
  },
}
