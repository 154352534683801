import React, { useState } from 'react'
import { cx } from '@emotion/css'

import { Button, ButtonGroup } from '../../../../../index'
import styles from './style.module.css'
import { useSlateStatic } from 'slate-react'
import { Transforms } from 'slate'


interface IDiscussionFooter {
  isDisabled: boolean,
  editComment: string,
  handleOnSave: React.MutableRefObject<any>,
  setActiveDiscussion: Function
}
const DiscussionFooter: React.FC<IDiscussionFooter> = ({ isDisabled, handleOnSave, editComment, setActiveDiscussion }) => {
  const [loading, setLoading] = useState(false)
  const onClick = async (event) => {
    if (handleOnSave.current) {
      setLoading(true)
      await handleOnSave.current(event)
      // to wait for the set state after res received
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }
  const closePopup = () => {
    setActiveDiscussion(null)
    // @ts-ignore
  }
  return (
    <div className={cx(styles['scrte-discussion-footer--wrapper'], 'flex-v-center')}>
      <ButtonGroup>
        <Button type="button" buttonType="light" onClick={closePopup}>
          Cancel
        </Button>
        <Button
          type="button"
          buttonType="primary"
          disabled={isDisabled}
          onClick={onClick}
          isLoading={loading}
          testId={'discussion-save-btn'}>
          {editComment === '' ? 'Post' : 'Update'}
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default DiscussionFooter