import React, { useCallback } from 'react'
import { Registry } from '@react-registry';
import styles from './styles.module.css'

const handleEvent = (e) => e.stopPropagation();

export const DOCS = (props) => {
  return (
    <div id='scrte-doc' className={styles['doc']} onMouseDown={handleEvent} {...props.attributes}>
      {props.children}
    </div>
  )
}

Registry.register(
  {
    Component: DOCS,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    },
    toolbar: {
      inSlashToolbar: false
    }
  },
  {
    id: 'doc',
    registry: 'v2.element'
  }
)
