import React from 'react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../style.module.css'

import RowDNDIcon from './DndRowIcon'
//@ts-ignoreDndstyles
import Dndstyles from './style.module.css'
//@ts-ignoreDndstyles
import PreviewStyleClass from './preview.module.css'

export * from './deserializer'
let classNames = {
  divTableRow: styles['div-table-row'],
  tableRowExtraCell: styles['table-add-column-extra-cell'],
}
const TableRow = (props) => {
  //props['styles'] = classNames
  props = { ...props, styles: { ...classNames } }
  return (
    <div {...props.attributes} className={'scrte-table-row'}>
      <div data-type='tr' data-testid='tr' className={props.styles.divTableRow}>
        {props.children}
      </div>
    </div>
  )
}
const getDroppableContainer = (element, path) => {
  return path.slice(0, path.length - 1).join('')
}
export default TableRow

Registry.register(
  {
    Component: TableRow,
    title: 'Table Row',
    iconName: 'ri-layout-row-line',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
      inSlashToolbar: false,
    },
    isContentStackElement: true,
    dndOptions: {
      DisableSelectionHalo: true,
      CustomDndIcon: RowDNDIcon,
      ContainerClassName: "scrte-table-row-dnd-container",
      DisableAdd: true,
      dndStyles: Dndstyles,
      getDroppableContainer: getDroppableContainer,
      PreviewStyleClass: PreviewStyleClass,
      DisableGridDnd: true,
    },
  },
  { id: 'tr', registry: 'v2.element' }
)
