import React, { useState, useRef } from 'react'
import { Registry } from '@react-registry'
import { Transforms } from 'slate'
import { useSlateStatic, useFocused, useSelected, ReactEditor } from 'slate-react'
import { Resizable } from 're-resizable'
import isUrl from 'is-url'
import {
  cbModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonGroup,
  TextInput,
  Icon,
  Field,
  ActionTooltip
} from '../../../../../../index'

import EmbededOverlay from './EmbededOverlay'
import { EmbedIcon } from '../../utils/Icon'
import { handleDelete } from '../../utils/actions'
//@ts-ignore
import styles from './style.module.css'

import { cx } from '@emotion/css'
import { elementCategories } from '../elementCategories'
import getEmbedUrl from './getEmbedUrl'
import { ElementWithType } from '../../../../../../utils/types'
import { getUrlFromIframe, isIframe } from './EmbededOverlay'

export const EmbededElement = ({ attributes, children, element, attrs }) => {
  let { src, width, height } = element.attrs
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  const embedRef = useRef()

  if (!src || !isUrl(src)) return null

  const onResizeStop = (event, dir, el, size) => {
    let width = el.style.width
    let widthLen = width.length
    width = Number(width.slice(0, widthLen - 1))
    let height = el.style.height
    let heightLen = height.length
    height = Number(height.slice(0, heightLen - 2))
    let path = ReactEditor.findPath(editor as ReactEditor, element)
    let { attrs } = element
    Transforms.setNodes(
      editor,
      { attrs: { ...attrs, width, height } } as ElementWithType,
      {
        at: path
      }
    )
  }

  const marginAlignment = {
    center: { margin: 'auto' },
    left: { marginRight: 'auto' },
    right: { marginLeft: 'auto' }
  }
  const margin = attrs?.style?.['text-align'] ? marginAlignment[attrs?.style?.['text-align']] : marginAlignment['left']
  const style = {
    ...(attrs.style ? attrs.style : {}),
    ...margin
  }

  const ModalComponent = (props) => {
    const [formData, setFormData] = useState({
      embeded_url: props.initialUrl || ''
    })

    const updateFormData = (e) => {
      e.persist()
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleOnOk = () => {
      props.closeModal()
      let path = ReactEditor.findPath(editor as ReactEditor, element)
      let attrs = {}
      if (isIframe(formData['embeded_url'])) {
        attrs = getUrlFromIframe(formData['embeded_url'], true)
      }
      else {
        attrs = { ...element.attrs, src: getEmbedUrl(formData['embeded_url']) }
      }
      Transforms.setNodes(editor, { attrs } as ElementWithType, { at: path })
    }
    return (
      <div>
        <ModalHeader title="Edit Embed Url" closeModal={props.closeModal} />

        <ModalBody>
          <Field labelText="URL">
            <TextInput
              name="embeded_url"
              onChange={updateFormData}
              value={formData.embeded_url}
              type="url"
              placeholder="Enter Embeded URL"
              data-testid='embeded_url'
            />
          </Field>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button key="cancel" buttonType="light" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button key="add" icon="CheckedWhite" onClick={handleOnOk} data-testid="updateEmbedBtn">
              Update
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }

  const toolList = [
    {
      label: <Icon icon="Edit" size="small" />,
      title: 'Edit',
      action: () => {
        //@ts-ignore
        editor.setFullScreen();
        cbModal({
          component: (props) => <ModalComponent initialUrl={src} {...props} />
        })
      }
    },
    {
      label: <Icon icon="Trash" size="small" />,
      title: 'Delete',
      action: () => {
        handleDelete(editor, element)
      }
    }
  ]

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} className={styles['embed-wrapper']}>
        <Resizable
          onResizeStop={onResizeStop}
          maxWidth={'100%'}
          minHeight={150}
          minWidth={150}
          defaultSize={{
            width: `${width || 100}%`,
            height: `${height || 300}px`
          }}
          //@ts-ignore
          contentEditable={false}
          style={style}>
          <div className={styles['scrte--iframe-wrapper']}>
            <ActionTooltip list={toolList} className={styles['scrte--action-tooltip']}>
              <iframe
                ref={embedRef}
                src={`${src}?title=0&byline=0&portrait=0`}
                frameBorder="0"
                className={cx(styles['scrte--iframe'], {
                  [styles['scrte--embed-selected']]: selected && focused
                })}
                sandbox="allow-scripts allow-popups allow-top-navigation-by-user-activation allow-forms allow-same-origin allow-presentation"
                allowFullScreen={true}
                loading="lazy"
              />
            </ActionTooltip>
          </div>
        </Resizable>
      </div>
      {children}
    </div>
  )
}

Registry.register(
  {
    Component: React.memo(EmbededElement),
    iconName: <EmbedIcon />,
    title: 'Video',
    slashTitle: 'Video',
    slashIconName: <EmbedIcon />,
    keywords: ['video'],
    subtitle: 'Embed Youtube, Vimeo or Sites',
    IngressComponent: EmbededOverlay,
    isContentStackElement: true,
    category: elementCategories.VIDEO,
    toolbar: {
      inSlashToolbar: true
    }
  },
  { id: 'embed', registry: 'v2.element' }
)
