import React from 'react'

export const ScrollRefProvider = React.createContext(null);

export const ScrollRefHOC = (Component) => props => {
    return (
        <ScrollRefProvider.Consumer>
            {consumerProps => <Component {...consumerProps} {...props}/>}
        </ScrollRefProvider.Consumer>
    )
    
}