import React, { useState, useEffect, useRef } from 'react'
import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import Dropdown from '../DropDown/Dropdown'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import Search from '../Search/Search'
import Tooltip from '../Tooltip/Tooltip'
import {
  reorderList,
  filterColumnData,
  constants,
  compareColumn,
  move,
  getActiveStateIcon,
  freezeColumnOptions,
  MAX_FREEZE_COUNT,
  getTableCount
} from './util'

const InfoComponent = () => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation()
      }}
      className="TablePanel__info_container">
      <div className="mr-10">
        <Icon icon="Warning" size="small" />
      </div>
      <div className="TablePanel__info_text">At least two columns must be visible</div>
    </div>
  )
}

const TablePanel = (props) => {
  const {
    columnSelector,
    allColumns,
    totalCounts,
    name,
    viewSelector,
    dropDownList,
    viewBy,
    loading,
    searchPlaceholder,
    onChangeSearch,
    onRefresh,
    searchValue,
    canSearch,
    canRefresh,
    staticRowCount,
    onToggleColumnSelector,
    withExportCta,
    setTableColumnOrder,
    canOrderColumn,
    freezedUnfreezeColumn,
    canFreezeColumn,
    listData,
    setListData,
    setDragListData,
    // updateFreezeColumnCount,
    setIsOpenAddColumn,
    isOpenAddColumn,
    hasFreezedColumn
  } = props

  const [actionSelected, setActionSelected] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [isCursorInside, setIsCursorInside] = useState(false)

  const allVisibleColumn = allColumns
    .filter((column) => column.isVisible && column.addToColumnSelector)
    .map((column) => column.id)

  let totalString = getTableCount({ totalCounts, name, staticRowCount })

  let tablePanelValueRef: any = useRef({})
  tablePanelValueRef.current.isCursorInside = isCursorInside
  tablePanelValueRef.current.showDropdown = showDropdown

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', handleClickOutside)
      document.addEventListener('closeTableSettingDropdown', closeDropdown)
      return () => {
        document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('closeTableSettingDropdown', closeDropdown)
      }
    }
  }, [showDropdown])

  const closeDropdown = () => {
    setShowDropdown(false)
    setActionSelected(undefined)
  }
  useEffect(() => {
    if (isOpenAddColumn) {
      setIsOpenAddColumn(false)
      setShowDropdown(true)
      setActionSelected('manage_columns')
    }
  }, [isOpenAddColumn])

  const handleClickOutside = () => {
    if (tablePanelValueRef.current.showDropdown && !tablePanelValueRef.current.isCursorInside) {
      setShowDropdown(false)
      setActionSelected('')
    }
  }

  const onChangeColumnSelector = (allColumnsData) => {
    let [freezed = [], unfreezed = []] = listData
    freezed = compareColumn(allColumnsData, freezed)
    unfreezed = compareColumn(allColumnsData, unfreezed)
    setListData([freezed, unfreezed])
    if (onToggleColumnSelector) {
      const columnSelectorData = filterColumnData(allColumnsData)
      onToggleColumnSelector(columnSelectorData)
    }
  }
  const getSettingsDropdownList = (viewSelector, columnSelector, canFreezeColumn) => {
    let lists = []
    if (props.dropdownCustomOptions && props.dropdownCustomOptions.length > 0) {
      lists = [...lists, ...props.dropdownCustomOptions]
    }
    if (viewSelector) {
      lists.push({
        label: 'Change table view',
        value: 'table_view',
        icon: 'ComfortableView',
        className: 'view-selector',
        list: dropDownList,
        activeIcon: 'ComfortableViewActive'
      })
    }
    if (columnSelector) {
      lists.push({
        label: 'Add or remove Columns',
        value: 'manage_columns',
        icon: 'ManageColumn',
        activeIcon: 'ColumnSelectorActive',
        list: canFreezeColumn ? [] : list,
        lists: canFreezeColumn ? getDropdownList() : [],
        dragDropProps: {
          canDragAndDrop: canOrderColumn,
          onDragEnd: onDragEnd,
          labels: canFreezeColumn ? ['FREEZED COLUMNS', 'UNFREEZED COLUMNS'] : ''
        },
        isMultiCheck: true,
        infoComponent: allVisibleColumn.length <= MAX_FREEZE_COUNT && InfoComponent
      })
    }

    return lists
  }

  const Selector = ({ column, isLock = false }) => {
    const toggleHiddenProps = { ...column.getToggleHiddenProps() }

    return (
      <div className="selector">
        <Checkbox
          text={typeof column.Header === 'function' ? <column.Header /> : column.Header}
          {...toggleHiddenProps}
          disabled={column.default || isLock || (allVisibleColumn.length <= 2 && allVisibleColumn.includes(column.id))}
        />
        {canFreezeColumn && (
          <span
            className={`selector-icon ${column.isVisible ? '' : 'disabled'}`}
            onClick={() => freezedUnfreezeColumn(column)}>
            <Icon
              withTooltip={true}
              tooltipContent={isLock ? 'Unfreeze' : 'Freeze'}
              tooltipPosition="top"
              icon={isLock ? 'UnLock' : 'LockWhite'}
            />
          </span>
        )}
      </div>
    )
  }

  const getListItem = (columns, isLock = false) => {
    let list = []
    columns.forEach((column) => {
      if (column.addToColumnSelector) {
        const action = () => {
          setTimeout(() => {
            onChangeColumnSelector(allColumns)
          })
        }
        list.push({
          label: <Selector column={column} isLock={isLock} />,
          action,
          id: column.id,
          canDragDrop: column.canDragDrop,
          isDropDisabled: hasFreezedColumn && !column.isVisible
        })
      }
    })
    return list
  }

  let list = getListItem(allColumns) || []

  const getDropdownList = () => {
    let [freezed = [], unfreezed = []] = listData
    let unfreezedColumn = []
    let freezedColumn = []
    if (freezed?.length > 0) {
      freezedColumn = getListItem(compareColumn(allColumns, freezed), true)
    }
    if (unfreezed.length > 0) {
      unfreezedColumn = getListItem(compareColumn(allColumns, unfreezed), false)
    }
    return [freezedColumn, unfreezedColumn]
  }

  const onDragEnd = (result, draggableId) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (canFreezeColumn) {
      const sInd = source.droppableId
      const dInd = destination.droppableId
      if (sInd === dInd) {
        const items = reorderList(listData[sInd], source.index, destination.index)
        const newState = [...listData]
        newState[sInd] = items
        setDragListData(newState, 'setColumnFreeze')
      } else {
        const items = move(listData[sInd], listData[dInd], source, destination)
        const newState = [...listData]
        newState[sInd] = items[sInd]
        newState[dInd] = items[dInd]
        freezedUnfreezeColumn({ id: result.draggableId })
      }
    } else {
      const updatedList: any = reorderList(list, result.source.index, result.destination.index)
      const updatedOrderedColumnIds = updatedList.map((list) => list.id)
      setTableColumnOrder(updatedOrderedColumnIds)
    }
  }

  const primaryDropdownList = getSettingsDropdownList(viewSelector, columnSelector, canFreezeColumn)

  return (
    <div className="TablePanel flex-v-center">
      {canSearch && (
        <Search
          width="full"
          corners="regular"
          placeholder={searchPlaceholder}
          onChange={onChangeSearch}
          debounceSearch={true}
          value={searchValue}
        />
      )}
      <div className={`flex-v-center ${canSearch ? '' : 'w-100 flex-justify'}`}>
        <label className="TablePanel__list-count flex-v-center">
          <div>
            {loading ? (
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={10}
                tileWidth={80}
                tileBottomSpace={7}
                tileTopSpace={5}
                tileleftSpace={5}
              />
            ) : (
              totalString
            )}
          </div>
          {canRefresh && (
            <Tooltip content={'Refresh'} position="top">
              <div className="ml-10 refresh-wrapper" onClick={onRefresh}>
                <Icon icon="Refresh" className="Refresh__icon" />
              </div>
            </Tooltip>
          )}
        </label>
        {columnSelector || viewSelector || (withExportCta && withExportCta.showExportCta) ? (
          <div className="TablePanel__actions ml-20">
            <div
              className={
                'TablePanel__actions__items ' +
                ((viewSelector && !columnSelector) || (!viewSelector && columnSelector)
                  ? 'TablePanel__actions__items--single'
                  : '')
              }>
              {withExportCta && withExportCta.component && <div className="import-cta">{withExportCta.component}</div>}

              <div
                onMouseEnter={() => {
                  setIsCursorInside(true)
                }}
                onMouseLeave={() => {
                  setIsCursorInside(false)
                }}>
                {primaryDropdownList.length > 0 && (
                  <div
                    className={`settings-wrapper ${totalCounts === 0 ? 'TablePanel__actions--disabled' : ''}`}
                    data-testid="settings-wrapper"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      setShowDropdown(!showDropdown)
                    }}>
                    <Icon
                      withTooltip
                      tooltipContent="Table Settings"
                      tooltipPosition="bottom"
                      icon="SettingsOutlineMedium"
                      activeIcon="SettingsMedium"
                      className="action-icon"
                      hover
                      active={showDropdown}
                      hoverType="secondary"
                      width="16px"
                      height="16px"
                    />
                  </div>
                )}

                {showDropdown && (
                  <div className="TablePanel__actions_list">
                    <ul>
                      {primaryDropdownList.map((option) => {
                        return (
                          <li
                            className={`TablePanel__actions_list_item ${
                              option.value === actionSelected ? 'selected' : ''
                            }`}
                            key={option.value}
                            onMouseEnter={(e) => setActionSelected(option.value)}>
                            <div className="dropdown-primary">
                              <span className="dropdown-primary-item">
                                <Icon
                                  icon={option.icon}
                                  active={getActiveStateIcon(actionSelected, option.value)}
                                  activeIcon={option.activeIcon}
                                  className="dropdown-list"
                                />{' '}
                                {option.label}
                              </span>
                              <Icon
                                active={getActiveStateIcon(actionSelected, option.value)}
                                icon="ChevronExtraSmall"
                                className="arrow-icon"
                              />{' '}
                            </div>
                            {option.value === actionSelected && (
                              <div className={`TablePanel__actions__subitems flex-v-center `}>
                                <Dropdown
                                  type="click"
                                  closeAfterSelect={false}
                                  dropDownPosition="bottom"
                                  className={`flex-v-center ${option.className}`}
                                  isOpen={true}
                                  list={option.list}
                                  lists={option.lists}
                                  dragDropProps={option.dragDropProps}
                                  isMultiCheck={option.isMultiCheck}
                                  infoComponent={option.infoComponent}
                                  canCloseOnClickOutside={false}
                                />
                              </div>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TablePanel

// if (canFreezeColumn) {
//   lists.push({
//     label: 'Freeze columns',
//     value: 'freeze_columns',
//     icon: 'Freeze',
//     list: freezeCoulumnList(),
//     activeIcon: 'FreezeActive'
//   })
// }

// const freezeCoulumnList = () => {
//   let [freezed = []] = listData
//   return freezeColumnOptions.map((option) => ({
//     label: <div className={`${freezed.length === option.value ? 'selected' : ''}`}>{option.label}</div>,
//     action: () => updateFreezeColumnCount(option.value),
//     default: true
//   }))
// }
