import React from 'react';
import { useDropzone } from 'react-dropzone';

const DropableComponent = ({ children, config, nativeConfig }) => {
  const { getRootProps, getInputProps } = useDropzone(config);

  return (
    <span {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} {...nativeConfig} />
      {children}
    </span>
  )
}

export default DropableComponent;