import { cloneDeep } from 'lodash';
import { useState } from 'react';

export const useCustomToolbar = (toolbarConfig = {}, editor) => {
  const [json, _] = useState(getCustomToolbarHelper(toolbarConfig, editor));
  return json;
}

const tableChilds = [
  'table-create-table',
  'table-delete-table',
  'table-insert-row-top',
  'table-insert-row-bottom',
  'table-delete-row',
  'table-insert-col-before',
  'table-insert-col-after',
  'table-create-header',
  'thead',
  'tableHeaderBtn',
  'tbody',
  'td',
  'tr',
  'th'
];

const alignments = [
  'left-align',
  'center-align',
  'right-align',
  'justify-align'
];

const lists = ['ol', 'ul'];

const img = ['uploadImage', 'chooseImage'];

const getCustomToolbarHelper = (toolbarConfig, editor) => {
  toolbarConfig = cloneDeep(toolbarConfig);
  const json = {
    'v2.element': (toolbarConfig['element'] || []),
    'v2.leaf': (toolbarConfig['leaf'] || []),
    'v2.editorButtons': (toolbarConfig['editorButtons'] || [])
  };

  const elements = json['v2.element'];
  const parentChilds = {
    'table': tableChilds,
    'alignment': alignments,
    'lists': lists,
    'img': img
  };

  for (const element of json['v2.element']) {
    if (parentChilds[element]) {
      elements.push(...parentChilds[element]);
    }
  }
  json['v2.element'] = elements;

  editor['customToolbarComponents'] = json;
  return json;
}