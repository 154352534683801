import React from 'react'

export const Youtube = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.24451 9.94111C2.37304 7.96233 3.96395 6.41157 5.94447 6.31345C8.81239 6.17136 12.9115 6 16 6C19.0885 6 23.1876 6.17136 26.0555 6.31345C28.0361 6.41157 29.627 7.96233 29.7555 9.94111C29.8786 11.8369 30 14.1697 30 16C30 17.8303 29.8786 20.1631 29.7555 22.0589C29.627 24.0377 28.0361 25.5884 26.0555 25.6866C23.1876 25.8286 19.0885 26 16 26C12.9115 26 8.81239 25.8286 5.94447 25.6866C3.96395 25.5884 2.37304 24.0377 2.24451 22.0589C2.12136 20.1631 2 17.8303 2 16C2 14.1697 2.12136 11.8369 2.24451 9.94111Z" fill="#FC0D1B" />
      <path d="M13 12V20L21 16L13 12Z" fill="white" />
    </svg>
  )
}

export const Vimeo = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.79742 6.99579L2.04553 10.3966L3.34372 12.0312C3.92184 11.6719 5.07809 10.7344 5.59372 10.7344C6.74997 10.7344 7.7894 13.8672 8.78122 17.3125C9.67184 20.4062 10.5937 28.0937 14.8437 28.0937C19.7343 28.0937 30.0156 14.8906 30.0156 9.01434C30.0156 6.88806 29.5937 3.82812 25.3593 3.82812C20.8218 3.82812 18.5895 8.20713 18.0405 10.3966C18.4354 10.1333 19.5105 9.9578 20.1029 9.9578C22.5164 9.9578 22.001 12.4595 21.6718 13.4687C21.3427 14.478 18.8125 19.3924 17.4481 19.3924C15.7806 19.3924 14.7932 12.1519 14.3105 9.01434C13.8278 5.87679 12.5772 4.1654 10.6683 4.1654C9.14123 4.1654 6.78477 6.05233 5.79742 6.99579Z" fill="#3CB9E6" />
    </svg>
  )
}

export const Facebook = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="14" fill="url(#paint0_linear)" />
      <path d="M21.2137 20.2816L21.8356 16.3301H17.9452V13.767C17.9452 12.6857 18.4877 11.6311 20.2302 11.6311H22V8.26699C22 8.26699 20.3945 8 18.8603 8C15.6548 8 13.5617 9.89294 13.5617 13.3184V16.3301H10V20.2816H13.5617V29.8345C14.2767 29.944 15.0082 30 15.7534 30C16.4986 30 17.2302 29.944 17.9452 29.8345V20.2816H21.2137Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear" x1="16" y1="2" x2="16" y2="29.917" gradientUnits="userSpaceOnUse">
          <stop stop-color="#18ACFE" />
          <stop offset="1" stop-color="#0163E0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const Twitter = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7887 28C8.55374 28 5.53817 27.0591 3 25.4356C5.15499 25.5751 8.95807 25.2411 11.3236 22.9848C7.76508 22.8215 6.16026 20.0923 5.95094 18.926C6.25329 19.0426 7.6953 19.1826 8.50934 18.856C4.4159 17.8296 3.78793 14.2373 3.92748 13.141C4.695 13.6775 5.99745 13.8641 6.50913 13.8174C2.69479 11.0882 4.06703 6.98276 4.74151 6.09635C7.47882 9.88867 11.5812 12.0186 16.6564 12.137C16.5607 11.7174 16.5102 11.2804 16.5102 10.8316C16.5102 7.61092 19.1134 5 22.3247 5C24.0025 5 25.5144 5.71275 26.5757 6.85284C27.6969 6.59011 29.3843 5.97507 30.2092 5.4432C29.7934 6.93611 28.4989 8.18149 27.7159 8.64308C27.7224 8.65878 27.7095 8.62731 27.7159 8.64308C28.4037 8.53904 30.2648 8.18137 31 7.68256C30.6364 8.52125 29.264 9.91573 28.1377 10.6964C28.3473 19.9381 21.2765 28 11.7887 28Z" fill="#47ACDF" />
    </svg>
  )
}

export const Instagram = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint0_radial)" />
      <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint1_radial)" />
      <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint2_radial)" />
      <path d="M23 10.5C23 11.3284 22.3284 12 21.5 12C20.6716 12 20 11.3284 20 10.5C20 9.67157 20.6716 9 21.5 9C22.3284 9 23 9.67157 23 10.5Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H16.4C19.7603 6 21.4405 6 22.7239 6.65396C23.8529 7.2292 24.7708 8.14708 25.346 9.27606C26 10.5595 26 12.2397 26 15.6V16.4C26 19.7603 26 21.4405 25.346 22.7239C24.7708 23.8529 23.8529 24.7708 22.7239 25.346C21.4405 26 19.7603 26 16.4 26H15.6C12.2397 26 10.5595 26 9.27606 25.346C8.14708 24.7708 7.2292 23.8529 6.65396 22.7239C6 21.4405 6 19.7603 6 16.4V15.6ZM15.6 8H16.4C18.1132 8 19.2777 8.00156 20.1779 8.0751C21.0548 8.14674 21.5032 8.27659 21.816 8.43597C22.5686 8.81947 23.1805 9.43139 23.564 10.184C23.7234 10.4968 23.8533 10.9452 23.9249 11.8221C23.9984 12.7223 24 13.8868 24 15.6V16.4C24 18.1132 23.9984 19.2777 23.9249 20.1779C23.8533 21.0548 23.7234 21.5032 23.564 21.816C23.1805 22.5686 22.5686 23.1805 21.816 23.564C21.5032 23.7234 21.0548 23.8533 20.1779 23.9249C19.2777 23.9984 18.1132 24 16.4 24H15.6C13.8868 24 12.7223 23.9984 11.8221 23.9249C10.9452 23.8533 10.4968 23.7234 10.184 23.564C9.43139 23.1805 8.81947 22.5686 8.43597 21.816C8.27659 21.5032 8.14674 21.0548 8.0751 20.1779C8.00156 19.2777 8 18.1132 8 16.4V15.6C8 13.8868 8.00156 12.7223 8.0751 11.8221C8.14674 10.9452 8.27659 10.4968 8.43597 10.184C8.81947 9.43139 9.43139 8.81947 10.184 8.43597C10.4968 8.27659 10.9452 8.14674 11.8221 8.0751C12.7223 8.00156 13.8868 8 15.6 8Z" fill="white" />
      <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 23) rotate(-55.3758) scale(25.5196)">
          <stop stop-color="#B13589" />
          <stop offset="0.79309" stop-color="#C62F94" />
          <stop offset="1" stop-color="#8A3AC8" />
        </radialGradient>
        <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11 31) rotate(-65.1363) scale(22.5942)">
          <stop stop-color="#E0E8B7" />
          <stop offset="0.444662" stop-color="#FB8A2E" />
          <stop offset="0.71474" stop-color="#E2425C" />
          <stop offset="1" stop-color="#E2425C" stop-opacity="0" />
        </radialGradient>
        <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.500002 3) rotate(-8.1301) scale(38.8909 8.31836)">
          <stop offset="0.156701" stop-color="#406ADC" />
          <stop offset="0.467799" stop-color="#6A45BE" />
          <stop offset="1" stop-color="#6A45BE" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const Tiktok = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.45083 19.7926C8.6071 18.4987 9.13777 17.7742 10.1378 17.0316C11.5687 16.0258 13.356 16.5948 13.356 16.5948V13.2196C13.7905 13.2085 14.2252 13.2342 14.655 13.2966V17.6401C14.655 17.6401 12.8682 17.0711 11.4373 18.0774C10.4379 18.8196 9.90611 19.5445 9.75038 20.8384C9.7455 21.541 9.87735 22.4594 10.4845 23.2536C10.3344 23.1766 10.1814 23.0889 10.0255 22.9904C8.68795 22.0923 8.44432 20.7448 8.45083 19.7926ZM22.035 6.97892C21.0507 5.90033 20.6785 4.81133 20.5439 4.04633H21.7822C21.7822 4.04633 21.5353 6.05218 23.3346 8.02475L23.3595 8.05128C22.8746 7.74624 22.4299 7.38618 22.035 6.97892ZM27.9999 10.0369V14.293C27.9999 14.293 26.4199 14.2311 25.2505 13.9336C23.6178 13.5176 22.5684 12.8795 22.5684 12.8795C22.5684 12.8795 21.8435 12.4244 21.7849 12.3927V21.1817C21.7849 21.671 21.6509 22.8932 21.2423 23.9125C20.7089 25.2459 19.8858 26.1211 19.7344 26.3C19.7344 26.3 18.7333 27.4832 16.9671 28.2799C15.3751 28.9986 13.9773 28.9804 13.5595 28.9986C13.5595 28.9986 11.1433 29.0943 8.96902 27.6813C8.49886 27.3699 8.05998 27.0171 7.65808 26.6277L7.66893 26.6355C9.84371 28.0485 12.2594 27.9528 12.2594 27.9528C12.6778 27.9346 14.0755 27.9528 15.667 27.2341C17.4315 26.4373 18.4343 25.2542 18.4343 25.2542C18.584 25.0753 19.411 24.2001 19.9422 22.8661C20.3497 21.8473 20.4848 20.6247 20.4848 20.1353V11.3474C20.5434 11.3796 21.2678 11.8347 21.2678 11.8347C21.2678 11.8347 22.3177 12.4733 23.9504 12.8888C25.1203 13.1863 26.6998 13.2482 26.6998 13.2482V9.91308C27.2403 10.0343 27.7009 10.067 27.9999 10.0369Z" fill="#EE1D52" />
      <path d="M26.7004 9.91308V13.2472C26.7004 13.2472 25.1209 13.1853 23.951 12.8878C22.3183 12.4718 21.2684 11.8336 21.2684 11.8336C21.2684 11.8336 20.544 11.3786 20.4854 11.3464V20.1363C20.4854 20.6257 20.3514 21.8484 19.9428 22.8672C19.4094 24.2011 18.5863 25.0763 18.4349 25.2552C18.4349 25.2552 17.4332 26.4384 15.6676 27.2351C14.0761 27.9538 12.6783 27.9356 12.26 27.9538C12.26 27.9538 9.8443 28.0495 7.66953 26.6365L7.65867 26.6287C7.42906 26.4064 7.21293 26.1716 7.01134 25.9256C6.31734 25.0795 5.89194 24.0789 5.78505 23.7934C5.78486 23.7922 5.78486 23.7909 5.78505 23.7897C5.61304 23.2936 5.25166 22.1021 5.30104 20.9481C5.3884 18.9121 6.10464 17.6624 6.29401 17.3494C6.79555 16.4956 7.44785 15.7318 8.2219 15.0918C8.90495 14.5396 9.67917 14.1002 10.5128 13.7917C11.414 13.4294 12.379 13.2353 13.3561 13.2196V16.5948C13.3561 16.5948 11.5687 16.0279 10.1384 17.0316C9.13837 17.7742 8.6077 18.4987 8.45142 19.7926C8.44491 20.7448 8.68854 22.0923 10.025 22.9909C10.1809 23.0897 10.3339 23.1775 10.484 23.2541C10.7175 23.5576 11.0017 23.822 11.3251 24.0368C12.6306 24.8631 13.7245 24.9209 15.1233 24.3842C16.0561 24.0253 16.7582 23.2166 17.0838 22.3206C17.2883 21.761 17.2856 21.1978 17.2856 20.6153V4.04633H20.5413C20.6759 4.81133 21.0481 5.90033 22.0324 6.97892C22.4272 7.38618 22.8719 7.74624 23.3569 8.05128C23.5001 8.19949 24.2327 8.93225 25.173 9.3821C25.6592 9.61463 26.1717 9.79279 26.7004 9.91308Z" fill="black" />
      <path d="M4.48926 22.757V22.7596L4.57004 22.9785C4.56076 22.953 4.53074 22.8755 4.48926 22.757Z" fill="#69C9D0" />
      <path d="M10.5128 13.7917C9.67919 14.1002 8.90498 14.5396 8.22192 15.0918C7.44763 15.7333 6.79548 16.4987 6.29458 17.3541C6.10521 17.6661 5.38897 18.9169 5.30161 20.9529C5.25223 22.1069 5.61361 23.2983 5.78561 23.7945C5.78543 23.7957 5.78543 23.7969 5.78561 23.7981C5.89413 24.081 6.31791 25.0816 7.01191 25.9303C7.2135 26.1764 7.42963 26.4111 7.65924 26.6334C6.92357 26.1458 6.26746 25.5562 5.71236 24.884C5.02433 24.0451 4.60001 23.0549 4.48932 22.7627C4.48919 22.7606 4.48919 22.7585 4.48932 22.7564V22.7528C4.31677 22.2572 3.95431 21.0652 4.00477 19.9096C4.09213 17.8736 4.80838 16.6239 4.99775 16.3109C5.4985 15.4554 6.15067 14.6899 6.92509 14.0486C7.608 13.4962 8.38225 13.0568 9.21598 12.7485C9.73602 12.5417 10.2778 12.3891 10.8319 12.2934C11.6669 12.1537 12.5198 12.1416 13.3588 12.2575V13.2196C12.3808 13.235 11.4148 13.4291 10.5128 13.7917Z" fill="#69C9D0" />
      <path d="M20.544 4.04635H17.2883V20.6159C17.2883 21.1983 17.2883 21.76 17.0865 22.3211C16.7577 23.2167 16.0582 24.0253 15.126 24.3842C13.7266 24.923 12.6327 24.8632 11.3278 24.0368C11.0038 23.823 10.7189 23.5594 10.4846 23.2567C11.5964 23.8251 12.5915 23.8152 13.8243 23.341C14.756 22.9821 15.4565 22.1734 15.7842 21.2774C15.9893 20.7178 15.9866 20.1546 15.9866 19.5726V3H20.4821C20.4821 3 20.4317 3.41188 20.544 4.04635ZM26.7004 8.99104V9.9131C26.1726 9.79263 25.661 9.61447 25.1757 9.38213C24.2353 8.93228 23.5028 8.19952 23.3596 8.0513C23.5258 8.1559 23.6983 8.25106 23.8761 8.33629C25.0194 8.88339 26.1453 9.04669 26.7004 8.99104Z" fill="#69C9D0" />
    </svg>
  )
}