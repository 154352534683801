import React from 'react';
import Tooltip from '../../../../../Tooltip/Tooltip';

export const InfoButton = ({ content, onClick, children, title }) => {
  return (
    <Tooltip position='bottom' content={content}>
      <button id={title} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}>
        {children}
      </button>
    </Tooltip>
  )
}
