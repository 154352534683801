import React, { useState, memo } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
} from 'recharts'

const StackedBarGraph = (props) => {
  const {
    data,
    label,
    value,
    domainY,
    domainX,
    scaleX = 'auto',
    typeX = 'category',
    tickFormatterX,
    tickFormatterY,
    CustomTooltip,
    dataKeys,
    barSize = 25,
    AxisXTick,
    gridStroke = '0.7',
    gridLinesVertical = false,
    barChartMargin = { bottom: 20 }
  } = props

  const styleXAxis = {
    fill: '#718096',
    strokeWidth: 0.08,
    fontSize: '10px',
  }

  const styleYAxis = {
    fill: '#A0AEC0',
    strokeWidth: 0.05,
    fontSize: '11px',
  }

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart data={data} margin={barChartMargin}>
        <CartesianGrid stroke='#E9ECF3' strokeWidth={gridStroke} vertical={gridLinesVertical} />
        <XAxis
          dataKey={label}
          style={styleXAxis}
          padding={{ left: 30, right: 30 }}
          tickFormatter={tickFormatterX || null}
          scale={scaleX}
          type={typeX}
          domain={domainX}
          allowDataOverflow={true}
          tick={AxisXTick && <AxisXTick />}
        />
        <YAxis
          interval='preserveStartEnd'
          style={styleYAxis}
          domain={domainY}
          tickFormatter={tickFormatterY || null}
        />
        {CustomTooltip ? (
          <Tooltip content={<CustomTooltip />} cursor={false} />
        ) : (
          <Tooltip cursor={false} />
        )}

        {dataKeys.map((data, index) => {
          if (data.background) {
            return (
              <Bar
                dataKey={data.key}
                fill={data.barColor}
                isAnimationActive={false}
                barSize={barSize}
                background={{ fill: data.background }}
                stackId={data.stackId}
                key={index}
              />
            )
          }
          return (
            <Bar
              dataKey={data.key}
              fill={data.barColor}
              isAnimationActive={false}
              barSize={barSize}
              stackId={data.stackId}
              key={index}
            />
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default memo(StackedBarGraph)
