import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as ContentStackVenusComponent from '../../../../../index';
import { Registry } from '@react-registry';
import withRTE from '../../../lib/rteSDK';

const system = eval("System");
const exposePackages = {
  react: React,
  'react-dom': ReactDOM,
  '@contentstack/venus-components': ContentStackVenusComponent
};

Object.keys(exposePackages).forEach((packageName) => {
  system.register(packageName, [], (_export) => {
    return {
      execute: () => {
        const content = exposePackages[packageName];
        _export({
          ...content,
          default: content,
        });
      },
    };
  });
});

export function setupSystemRegister() {
  return system;
}

const isLeafCategory = (type) => {
  if (!type) return false;
  if (Array.isArray(type)) return type.includes('text');
  return type === 'text';
}

export async function loadDropdownPlugin(args) {
  const { key, value, rte } = args
  const dropdown = await value.get(rte);
  const category = dropdown.registry;
  const plugins = dropdown.meta?.dependentPlugins || [];
  const isLeafDropdown = isLeafCategory(value?.['pluginMetaData']?.['meta']?.['elementType']);
  category['icon'] = dropdown.registry.iconName;
  category['isLeaf'] = isLeafDropdown;
  // REGISTER UNDER CATEGORY TO DISPLAY IN DROPDOWN
  await Registry.register(category, {
    id: key,
    registry: 'category'
  });
  // REGISTER PLUGINS
  await Promise.all(plugins.map(async _ => {
    const plugin = await _.get(rte);
    plugin['registry']['category'] = category;
    withRTE({ plugin, rte, key: plugin.registry.title });
  }));
}