import React, { useEffect } from 'react'

import Icon from '../Icon2/Icon'
import SectionHeader from '../SectionHeader/SectionHeader'
import PageLayout from '../PageLayout/PageLayout'
import PageHeader from '../PageHeader/PageHeader'
import ListRow from '../ListRow'

import './LeftNavigation.css'

interface ActionProps {
  label: string
  onClick: Function
  type: string
}

interface HeaderDataProps {
  title: string | React.ReactNode
  actions: Array<ActionProps>
  pageHeaderContent?: string | React.ReactNode
}
interface NavArrayProp {
  title: React.ReactNode
  onclick: Function
  id: string
  headerData?: HeaderDataProps
  component: React.ReactNode
  externalLink?: string
  default?: boolean
  link?: string
}

interface LeftNavigation {
  navigationData: Array<NavArrayProp>
  navigationTitle: string | React.ReactNode
  customDefault?: NavArrayProp
}

interface NavigationProps {
  navigationProps: LeftNavigation
  headerContent?: boolean
  testId?: string
}

interface HeaderProps {
  headerProps: NavArrayProp
}

interface LeftNavProps {
  setActiveNav: Function
  navigationProps: LeftNavigation
  icon?: React.ReactNode
  activeNav: NavArrayProp
}

interface TitleProps {
  label: string
  info?: string
  component?: React.ReactNode
}

interface LeftHeaderProps {
  title: TitleProps
  actions?: Array<ActionProps>
  component?: React.ReactNode
}

export const LeftNavHeader: React.FunctionComponent<LeftHeaderProps> = (props) => {
  const { title, actions, component }: any = props

  const handleClick = () => {
    const ele: any = document.getElementsByClassName('PageLayout__content')
    document.querySelector('.PageLayout__leftSidebar').classList.toggle('PageLayout__leftSidebar--hide')
    ele[0]?.classList.toggle('PageLayout__content--full')
  }

  return (
    <div className="PageLayout__head">
      <div className="PageLayout__head__hamburger" onClick={handleClick}>
        <Icon icon="Hamburger" size="original" />
      </div>
      <PageHeader title={title} actions={actions} component={component} />
    </div>
  )
}

const LeftNav: React.FunctionComponent<LeftNavProps> = (props) => {
  const { navigationProps } = props
  const handleClick = (event: any) => {
    props.setActiveNav(event.currentTarget.id)
  }

  return (
    <>
      <SectionHeader content={<>{navigationProps.navigationTitle}</>} />
      <div className="Navigation__list">
        {navigationProps?.navigationData.map((navElement: any, index: any) => {
          return (
            <ListRow
              id={navElement.id}
              active={props.activeNav.id === navElement.id}
              onClick={handleClick}
              linkToRow={navElement.link}
              key={index}
              rightArrow={true}
              content={navElement.title}
              leftIcon={navElement.icon}
            />
          )
        })}
      </div>
    </>
  )
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const headerActions: any = props.headerProps.headerData.actions

  return (
    <>
      <PageHeader
        title={{ label: props.headerProps.headerData.title }}
        actions={headerActions}
        content={props.headerProps.headerData.pageHeaderContent}
      />
    </>
  )
}

const LeftNavigation: React.FunctionComponent<NavigationProps> = (props) => {
  const { navigationProps, testId } = props

  const checkDefault = () => {
    const defaultData = navigationProps.customDefault
      ? navigationProps.customDefault
      : navigationProps.navigationData[0]
    let active = navigationProps.navigationData.filter((data) => data.default !== undefined && data.default)
    return active.length > 0 ? active[0] : defaultData
  }

  const [activeNav, setActiveNav] = React.useState(checkDefault())

  useEffect(() => {
    const updatedActiveNavObject = checkDefault()

    const updatedActiveNav = navigationProps.navigationData.filter((data) => {
      return data.id === updatedActiveNavObject.id
    })[0]
    setActiveNav(updatedActiveNav || navigationProps?.customDefault || navigationProps.navigationData[0])
  }, [navigationProps])

  const newActiveNav = (id: any) => {
    let index: any
    navigationProps.navigationData.forEach((value: any) => {
      if (value.id === id) {
        index = navigationProps.navigationData.indexOf(value)
      }
    })
    if (navigationProps.navigationData[index].onclick !== undefined) {
      navigationProps.navigationData[index].onclick()
    }
    setActiveNav(navigationProps.navigationData[index])
  }

  const contentLeftNav = {
    component: <LeftNav setActiveNav={newActiveNav} activeNav={activeNav} navigationProps={navigationProps} />
  }

  const contentHeader = {
    component: <Header headerProps={activeNav} />
  }

  const mainContent = {
    component: activeNav.component
  }

  return (
    <>
      {props.headerContent === undefined ? (
        <PageLayout
          header={contentHeader}
          testId={testId}
          content={mainContent}
          leftSidebar={contentLeftNav}
          type={'list'}
        />
      ) : (
        <PageLayout testId={testId} leftSidebar={contentLeftNav} content={mainContent} type={'list'} />
      )}
    </>
  )
}

export default LeftNavigation

// const NavIndexSetter = (Navs: Array<navArrayProp>, activeNav: string | undefined) => {
//   let index: any;
//   if (activeNav === undefined) {
//     index = 0;
//   }
//   else {
//     Navs.map((indiNavs: any) => {
//       if (indiNavs.id === activeNav) {
//         index = Navs.indexOf(indiNavs);
//       };
//     });
//   };
//   return index;
// };
