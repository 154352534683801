//@ts-nocheck

import { Node, Transforms } from 'slate'

import { createHeadCell } from './createHeadCell'
import { createCell } from './createCell'
export const insertColAtEnd = (editor, tablePath) => {
    const table = Node.get(editor, tablePath)
    const selection = table.attrs.cols
    Array.from(table.children).forEach(
        (tableChild, index0) => {
            Array.from(tableChild.children).forEach((child, index1) => {
                const path = [...tablePath, index0, index1, selection]
                if (child.children[selection - 1].type === 'th') {
                    Transforms.insertNodes(editor, createHeadCell(), { at: path })
                }
                if (child.children[selection - 1].type === 'td') {
                    Transforms.insertNodes(editor, createCell(), { at: path })
                }

            })
        }
    )
    let colWidths = table.attrs.colWidths.slice()
    colWidths.splice(selection, 0, 250)
    Transforms.setNodes(editor, {
        attrs: {
            ...table.attrs,
            colWidths,
            cols: table.attrs.cols + 1
        }
    }, { at: tablePath })
}
