import './MiniScrollableTable.css'

import React from 'react'

import withDeprecatedProp from 'utils/hooks/depricatedPropsHoc'

import { Field } from '../Field'

export const MiniScrollableTable = (
  {
    headerComponent,
    rowComponent,
    footerComponent,
    width = '500px',
    maxContentHeight = '250px',
    type = 'Primary',
    testId = 'cs-mini-scrollable-table',
  }: {
    headerComponent: React.ReactNode
    rowComponent: React.ReactNode,
    footerComponent?: React.ReactNode
    width?: string,
    maxContentHeight?: string
    type?: 'Primary' | 'Secondary',
    testId?: string,
  }
) => {

  return (
    <div data-test-id={testId} className={`table-content-section ${type === 'Primary' ? 'table--primary' : ''}`}>
      <Field>
        <div className='field-content' style={{ width }}>
          <div className='flex-v-center sticky_table_header'>
            {headerComponent}
          </div>
          <div className="field-content__content" style={{ maxHeight: maxContentHeight }}>
            {rowComponent}
          </div>
          {footerComponent &&
            <div>
              {footerComponent}
            </div>}
        </div>
      </Field >
    </div >
  )
}

export default withDeprecatedProp(MiniScrollableTable, { 'headerComponet': 'headerComponent' })