import { Editor, Range, Node } from 'slate'

export const getText = (editor, range) => {
  return Editor.string(editor, range)
}

export const isSelectionCollapsed = (range) => {
  return Range.isCollapsed(range)
}
const isInline = ['span', 'a', 'inlineCode', 'reference']
export const isInlineElement = (element) => {
  if (typeof element === 'string') return true
  if (element.hasOwnProperty('text')) {
    return true
  }
  if (isInline.includes(element.type)) {
    if (element.type === 'reference') {
      if (!['link', 'inline'].includes(element?.attrs?.['display-type'])) {
        return false
      }
    }
    return true
  }
  return false
}

export const isAncestorEmpty = (editor, block) => {
  return !Node.string(block) && !block.children.some((n) => Editor.isInline(editor, n))
}

export const isBlockAboveEmpty = (editor) => {
  const block = Editor.above(editor)?.[0]
  if (!block) return false;
  return isAncestorEmpty(editor, block)
}