import React from 'react'
import { Registry } from '@react-registry'

//@ts-ignore
import styles from './style.module.css'
import { elementCategories } from '../elementCategories'


const Paragraph = (props) => {
  return (
    <div {...props.attributes} {...props.attrs} className={styles['paragraph']}>
      <p data-testid='p'>
        {props.children}
      </p>
    </div>
  )
}

export default Paragraph

export * from './deserializer'
export * from './withParagraph'

Registry.register(
  {
    Component: Paragraph,

    // iconName: 'ri-text',
    title: 'Normal',
    subtitle: 'Just a simple text',
    shortcut: { key: 'mod+alt+0' },
    category: elementCategories.TEXT_FORMAT,
    isContentStackElement: true,
    inBasicToolbar: true
  },
  { id: 'p', registry: 'element' }
)
