import { Registry } from '@react-registry'

export const IMG = (el) => {
  return {
    type: 'img',
    attrs: {
      url: el.getAttribute('src'),
    },
    children: [{ text: '' }],
  }
}
export const Image = (el) => {
  return {
    type: 'img',
    attrs: {
      url: el.getAttribute('url'),
    },
  }
}

Registry.register(IMG, { id: 'IMG', registry: 'v2.deserializer' })
Registry.register(Image, { id: 'image', registry: 'v2.deserializer' })
