import isUrl from 'is-url'
import { Registry } from '@react-registry'

export const withSocialEmbeds = editor => {
  const { isVoid } = editor
  editor.isVoid = element => {
    return (element.type === 'social-embeds' ? true : isVoid(element))
  }

  editor.insertSocialEmbed = src => {
    if (!src || !isUrl(src)) return false;
    let embed = {
      type: 'social-embeds',
      attrs: {
        src
      },
      children: [{ text: '' }]
    }
    editor.insertNode(embed);
  }

  return editor
}

Registry.register(withSocialEmbeds, { id: 'withSocialEmbed', registry: 'plugin' })