import React, { CSSProperties, ReactNode } from 'react'
import './Box.css'

type PropertyTypes = {
  top?: string
  bottom?: string
  left?: string
  right?: string
}

export type BoxProps = {
  children: ReactNode,
  component?: keyof JSX.IntrinsicElements,
  className?: string,
  margin?: PropertyTypes,
  padding?: PropertyTypes,
  width?: string
}

const Box = (props: BoxProps) => {
  const { component: Component = 'div', children, className, margin, padding, width } = props;
  const style: CSSProperties = {
    margin: margin && getPropertyString(margin),
    padding: padding && getPropertyString(padding),
    width: width
  }

  return (
    <Component style={style} className={className}>
      {children}
    </Component>
  )
}

const getPropertyString = (property: PropertyTypes) => {
  return `${property.top ?? 0} ${property.right ?? 0} ${property.bottom ?? 0} ${property.left ?? 0}`
}

export default Box;