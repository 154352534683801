//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { useSlate } from 'slate-react'
import { Registry } from '@react-registry'
import React from 'react'

import { elementCategories } from '../../elementCategories'
import { DeleteHeaderIcon } from './CustomTableIcon/deleteHeader'
export const deleteHead = (event, { editor }) => {
  const [row] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'thead'
    },
    mode: 'lowest',
  })
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest',
  })
  if (row && table) {
    Transforms.removeNodes(editor, { at: row[1] })
    let headerChild = row[0].children.length
    Transforms.setNodes(
      editor,
      {
        attrs: {
          ...table[0].attrs,
          rows: table[0].attrs.rows - headerChild,
        },
      },
      { at: table[1] }
    )
  }
}
const DeleteHeadButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: deleteHead,
        isDisable: { func: deleteHeadButtonDisabler, params: { editor } },
        CustomIcon: DeleteHeaderIcon,
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}
const deleteHeadButtonDisabler = ({ editor }) => {
  const [tableHeader] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'thead'
    },
    mode: 'lowest',
  })
  return !tableHeader
}
Registry.register(
  {
    IngressComponent: DeleteHeadButton,
    //category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
      inMainToolbar: false,
      inHoveringToolbar: false,
    },
    canCustomize: false,
    title: 'Delete head',
    isContentStackElement: true,
  },
  {
    id: 'table-delete-header',
    registry: 'element',
  }
)
