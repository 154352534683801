export const performHistoryAction = (editor, format) => {
    const { redos, undos } = editor.history
    if (format === 'redo') {
        if (redos.length > 0) {
            editor.redo()
        }
        return
    }
    else {
        if (undos.length > 0) {
            editor.undo()
        }
        return
    }
}

export const isActionDisable = ({ editor, format }) => {
    const { redos, undos } = editor.history
    if (format === 'undo') {
        if (undos.length === 1 && undos[0][0].type === 'set_selection') {
            return true
        }
        return !(undos.length > 0)
    }
    else if (format === 'redo') {
        return !(redos.length > 0)
    }
    return true
}
