import { Registry } from '@react-registry'

export const withFullScreen = (editor) => {

  editor.setFullScreen = () => {
  }

  return editor
}
Registry.register(withFullScreen, { id: 'withFullScreen', registry: 'v2.plugin' })
