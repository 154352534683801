import React from 'react'
import { useSlate } from 'slate-react';
import { isEmbedAssetLinkActive, isEmbedLinkActive } from '../../utils';
import { ReferenceButton } from '../utils'
import { ShortcutOpenReferenceOverlay } from '../utils/referenceButton';


export const ShortcutOpenEntryAsLinkOverlay = (event, editor, format, registryObj) => {
  const props: any = { title: "Embed Link", type: "link" }
  ShortcutOpenReferenceOverlay(event, editor, format, registryObj, props)

}

export default (props) => {
  const editor = useSlate();

  return (
    <ReferenceButton {...props} title="Embed Link" type='link'>
      {({ handleClick }) => {
        return props.children({
          handleMouseDown: handleClick,
          active: isEmbedLinkActive(editor) && !isEmbedAssetLinkActive(editor),
          isDisable: {
            func: ({ editor }) => {
              return isEmbedAssetLinkActive(editor);
            }, params: { editor }
          }
        })
      }}
    </ReferenceButton>
  )
}
