import { Registry } from '@react-registry'
import { getAttributesObject } from '../../../../utils'

export const rowReferenceDeserializer = (el) => {
  return {
    type: 'row',
    attrs: getAttributesObject(el)
  }
}

Registry.register(rowReferenceDeserializer, {
  id: 'row',
  registry: 'deserializer'
})
