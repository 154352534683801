import React from 'react'

export const InsertHeaderIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 16V0H16V16H0ZM2 2H7V7H2V2ZM14 7V2H9V7H14Z" fill="#647696" />
      <path d="M2 2H7V7H2V2Z" fill="#A9B6CB" />
      <path d="M14 7V2H9V7H14Z" fill="#A9B6CB" />
      <path d="M9 14V9H14V14H9Z" fill="white" />
      <path d="M2 9V14H7V9H2Z" fill="white" />
    </svg>
  )


}
