export const list: any = [
  {
    label: 'HTML',
    value: 'html',
    default: true,
  },
  {
    label: 'ABAP',
    value: 'abap'
  },
  {
    label: 'Bash',
    value: 'bash'
  },
  {
    label: 'BASIC',
    value: 'basic'
  },
  {
    label: 'C',
    value: 'c'
  },
  {
    label: 'Clojure',
    value: 'clojure'
  },
  {
    label: 'CoffeeScript',
    value: 'coffeescript'
  },
  {
    label: 'C++',
    value: 'cpp'
  },
  {
    label: 'C#',
    value: 'csharp'
  },
  {
    label: 'CSS',
    value: 'css'
  },
  {
    label: 'Dart',
    value: 'dart'
  },
  {
    label: 'Diff',
    value: 'diff'
  },
  {
    label: 'Docker',
    value: 'docker'
  },
  {
    label: 'Elixir',
    value: 'elixir'
  },
  {
    label: 'Elm',
    value: 'elm'
  },
  {
    label: 'Erlang',
    value: 'erlang'
  },
  {
    label: 'Flow',
    value: 'flow'
  },
  {
    label: 'Fortran',
    value: 'fortran'
  },
  {
    label: 'F#',
    value: 'fsharp'
  },
  {
    label: 'Gherkin',
    value: 'gherkin'
  },
  {
    label: 'GLSL',
    value: 'glsl'
  },
  {
    label: 'Go',
    value: 'go'
  },
  {
    label: 'GraphQL',
    value: 'graphql'
  },
  {
    label: 'Groovy',
    value: 'groovy'
  },
  {
    label: 'Haskell',
    value: 'haskell'
  },
  {
    label: 'Java',
    value: 'java'
  },
  {
    label: 'JavaScript',
    value: 'javascript'
  },
  {
    label: 'JSON',
    value: 'json'
  },
  {
    label: 'Julia',
    value: 'julia'
  },
  {
    label: 'Kotlin',
    value: 'kotlin'
  },
  {
    label: 'LaTeX',
    value: 'latex'
  },
  {
    label: 'Less',
    value: 'less'
  },
  {
    label: 'Lisp',
    value: 'lisp'
  },
  {
    label: 'LiveScript',
    value: 'livescript'
  },
  {
    label: 'Lua',
    value: 'lua'
  },
  {
    label: 'Makefile',
    value: 'makefile'
  },
  {
    label: 'Markdown',
    value: 'markdown'
  },
  {
    label: 'Markup',
    value: 'markup'
  },
  {
    label: 'MATLAB',
    value: 'matlab'
  },
  {
    label: 'Nix',
    value: 'nix'
  },
  {
    label: 'Objective-C',
    value: 'objectivec'
  },
  {
    label: 'OCaml',
    value: 'ocaml'
  },
  {
    label: 'Pascal',
    value: 'pascal'
  },
  {
    label: 'Perl',
    value: 'perl'
  },
  {
    label: 'PHP',
    value: 'php'
  },
  {
    label: 'PowerShell',
    value: 'powershell'
  },
  {
    label: 'Prolog',
    value: 'prolog'
  },
  {
    label: 'Protobuf',
    value: 'protobuf'
  },
  {
    label: 'Python',
    value: 'python'
  },
  {
    label: 'R',
    value: 'r'
  },
  {
    label: 'Reason',
    value: 'reason'
  },
  {
    label: 'Ruby',
    value: 'ruby'
  },
  {
    label: 'Rust',
    value: 'rust'
  },
  {
    label: 'Sass',
    value: 'sass'
  },
  {
    label: 'Scala',
    value: 'scala'
  },
  {
    label: 'Scheme',
    value: 'scheme'
  },
  {
    label: 'Scss',
    value: 'scss'
  },
  {
    label: 'Shell',
    value: 'shell-session'
  },
  {
    label: 'SQL',
    value: 'sql'
  },
  {
    label: 'Swift',
    value: 'swift'
  },
  {
    label: 'TypeScript',
    value: 'typescript'
  },
  {
    label: 'VB.Net',
    value: 'vbnet'
  },
  {
    label: 'Verilog',
    value: 'verilog'
  },
  {
    label: 'VHDL',
    value: 'vhdl'
  },
  {
    label: 'Visual Basic',
    value: 'visual-basic'
  },
  {
    label: 'WebAssembly',
    value: 'wasm'
  },
  {
    label: 'XML',
    value: 'xml'
  },
  {
    label: 'YAML',
    value: 'yaml'
  }
]
