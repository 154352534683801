//@ts-nocheck
import { Registry } from '@react-registry'
import isUrl from 'is-url'

import { wrapLink } from './utils'
import { Editor, Transforms, Range, Path, Element, Node } from 'slate'

export const withHyperlinks = (editor) => {
  const { insertData, insertText, isInline, insertBreak, normalizeNode } = editor
  editor.isInline = (element) => {
    return element.type === 'a' ? !element?.attrs?.isBlock : isInline(element)
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      if (/\s/g.test(text)) { // space break text after adding space to hyperlinks
        const [selectedElement, path] = Editor.parent(editor, editor.selection);
        if (selectedElement.type === 'a' || selectedElement?.attrs?.['display-type'] === 'link') {
          const nextNode = Editor.node(editor, Path.next(path))
          if (nextNode && nextNode?.text === '') {
            Transforms.select(editor, Path.next(path)); // move the selection to next node before text insertion
          } else if (nextNode && nextNode?.text !== '') { // when there's already some text in next node
            const newSelection = {
              "anchor": { "path": Path.next(path), "offset": 0 },
              "focus": { "path": Path.next(path), "offset": 0 }
            }
            Transforms.select(editor, newSelection);

          }
        }
      }
      insertText(text)
    }
  }

  editor.normalizeNode = (entry) => {
    if (Element.isElement(entry[0])) {
      const [element, path] = entry;
      if ((element['type'] === 'a' || element['attrs']?.['display-type'] === 'link') && Node.string(element) === '') {
        Transforms.unwrapNodes(editor, { at: path });
      }
    }
    normalizeNode(entry);
  }

  editor.insertBreak = () => {
    const [selectedElement, path] = Editor.parent(editor, editor.selection);

    if (selectedElement.type === 'a' || selectedElement?.attrs?.['display-type'] === 'link') {
      const endPoint = Range.end(editor.selection);
      const [selectedLeaf] = Editor.node(editor, endPoint);
      //@ts-ignore
      if (selectedLeaf.text.length === endPoint.offset) {
        if (Range.isExpanded(editor.selection)) {
          Transforms.delete(editor);
        }
        // Transforms.select(editor, Path.next(path));
      }
    }
    insertBreak();
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

Registry.register(withHyperlinks, { id: 'withHyperlinks', registry: 'plugin' })
