//@ts-nocheck
import React, { useState, useEffect } from 'react'
import { Registry } from '@react-registry'
import { useSlateStatic, ReactEditor } from 'slate-react'
import { Transforms } from 'slate'
import { Icon } from '../../../../../../../index'
import { elementCategories } from '../../elementCategories'
import ingressReferenceButton from './utils/ingressReferenceButton'
//@ts-ignore

import { BlockReference } from './blockReference'
import { EmbedLinkReference } from './linkReference'
import { InlineReference } from './inlineReference'
import Asset from './assetReference/Asset'


export const ReferenceComponent = (props) => {
  const [state, setState] = useState({
    entry: {
      name: ''
    }
  })
  const { element } = props

  const editor = useSlateStatic()
  useEffect(() => {
    if (editor.requestProps && !editor.requestProps.enableEmbedObj) {
      // if disabled embed feature
      setState({
        entry: {
          disable: true,
          title: 'Entry Title',
          url: ''
        }
      })
      return
    }
    const loadState = async () => {
      if (element?.attrs?.default) {
        const attrs = element.attrs
        return setState((currState) => ({
          ...currState,
          entry: {
            url: attrs['asset-link']
          }
        }))
      }

      let contentTypeUid = element?.attrs?.['content-type-uid'],
        entryUid = element?.attrs?.['entry-uid'],
        assetUid = element?.attrs?.['asset-uid']
      const { uidToEntry } = editor.requestProps || {}

      if (contentTypeUid === 'sys_assets') {
        if (!uidToEntry?.[assetUid]) {
          return setState(prevState => ({
            ...prevState, entry: {
              title: assetUid, error: 'The asset is either deleted or you do not have access to it.'
            }
          }))
        } else if (uidToEntry?.[assetUid]) {
          const asset = uidToEntry[assetUid]
          const assetPermission = asset['_embed_permission'] || {}
          const isAssetEditable = assetPermission['update']
          const isReadable = assetPermission['read']
          if ((!isReadable && !isAssetEditable) || !asset['url']) {
            asset['error'] = 'The asset is either deleted or you do not have access to it.'
            return setState(prevState => ({
              ...prevState, entry: asset
            }))
          } else {
            setState(prevState => ({ ...prevState, entry: asset }));
          }
        }
      } else {
        if (uidToEntry?.[entryUid]) {
          const entry = uidToEntry[entryUid]
          const entryPermission = entry['_embed_permission'] || {}
          const isEntryEditable = entryPermission['update'] && entryPermission['read']
          if (!isEntryEditable || !entry['title']) {
            return setState(prevState => ({
              ...prevState, entry: {
                ...entry,
                title: entry['title'] || entry['uid'] || entryUid,
                error: `The entry is either invalid or you do not have access to it.`
              }
            }))
          } else {
            setState(prevState => ({ ...prevState, entry }));
          }
        } else {
          return setState(prevState => ({
            ...prevState, entry: {
              title: entryUid, error: `The entry is either invalid or you do not have access to it.`
            }
          }))
        }
      }
    }
    loadState()
  }, [element?.attrs?.['entry-uid'], element?.attrs?.['asset-uid'], editor.requestProps])

  const { loading, entry } = state

  switch (element?.attrs?.['display-type']) {
    case 'block':
      return (
        <BlockReference editor={editor} loading1={loading} entry={entry} {...props} />
      )
    case 'link':
      return <EmbedLinkReference editor={editor} entry={entry} {...props} />
    case 'display':
    case 'download':
      return <Asset loading={loading} entry={entry} editor={editor} {...props} />
    default:
      return (
        <InlineReference editor={editor} loading={loading} entry={entry} {...props} />
      )
  }
}
const beforeElementRender = (props) => {
  let { element } = props
  if (element['type'] === 'reference' && !['link', 'inline'].includes(element?.['attrs']?.['display-type'])) {
    props.DisableDND = false
  }
  if (element['type'] === "reference" && element?.attrs?.inline) {
    props.DisableDND = true;
  }
}

Registry.register(
  {
    Component: ReferenceComponent,
    title: 'Embed Entry',
    slashTitle: 'Embed Entry',
    slashIconName: <Icon icon="Embed" />,
    keywords: ['embed entry'],
    iconName: <Icon icon="Embed" />,
    IngressComponent: ingressReferenceButton,
    category: elementCategories.CONTENTSTACK_REFERENCE,
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true,
      inSlashToolbar: true
    },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    },
    beforeElementRender
  },
  { id: 'reference', registry: 'v2.element' }
)

export * from './assetReference'

export * from './inlineReference'

export * from './linkReference'
