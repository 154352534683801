import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'
import { DeleteTable as DeleteTableIcon } from './CustomTableIcon/deleteTable'
import { elementCategories } from '../../elementCategories'
import { TableDeleteIcon } from '../../../utils/Icon'
import { ElementWithType } from '../../../../../../../utils/types'

export const deleteTable = (event, { editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n: ElementWithType) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  if (table) {
    Transforms.removeNodes(editor, { at: table[1] })
    Transforms.insertNodes(
      editor,
      {
        type: 'p',
        attrs: {},
        children: [{ text: '' }]
      } as ElementWithType,
      { at: table[1] }
    )
  }
}
const deleteTableButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n: ElementWithType) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  return !table
}
const DeleteTable = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: deleteTable,
        isDisable: { func: deleteTableButtonDisabler, params: { editor } },
        CustomIcon: DeleteTableIcon,
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}
Registry.register(
  {
    IngressComponent: DeleteTable,
    category: elementCategories.TABLE_ACTION,
    iconName: <TableDeleteIcon />,
    title: 'Delete table',
    isContentStackElement: true,
    toolbar: {
      inSlashToolbar: false
    },
    canCustomize: false
  },
  {
    id: 'table-delete-table',
    registry: 'v2.element'
  }
)
