import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const DropableComponent = ({ children, config, ...props }) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone(config);

  return (
    <span {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} data-testid="drop-input" />
      {children}
    </span>
  )
}

export default DropableComponent;