import axios from "axios";

export const fetchEnvironment = async (props) => {
  const { assetUrl, authToken, apiKey, branch } = props;
  const url = `${assetUrl}/environments?include_color=true`;
  try {
    const headers = {
      api_key: apiKey,
      'Content-Type': 'application/json'
    }
    authToken && (headers['authToken'] = authToken);
    branch && (headers['branch'] = branch);
    const res = await axios({
      method: 'GET',
      url,
      headers
    });
    const json = await res.data;
    const environments = {};
    json['environments'].forEach(env => environments[env['uid']] = env);
    return environments;
  } catch (err) {
    // console.error(err);
    return {};
  }
}
