import React, { useState } from "react"
import SkeletonTile from '../SkeletonTile/SkeletonTile'

export const DefaultToggle = () => <span></span>


/*
 ** current node: node.active
 ** all toggled node: node.toggled
 ** use node.active and node.toggled to style accordingly
 **/
export const DefaultHeader = ({ style, node }) => {

  const [loading, setLoader] = useState(false)

  const onClickButton = () => {
    setLoader(true)
    node?.onClick()
  }

  if (node && node.name === 'rootNode') {
    return null
  }
  if (node && node.id === 'loadmore') {
    return loading ? Spinner() : (
      <div
        data-testid="defaultHeader-loadMore"
        onClick={onClickButton}
        style={{ color: '#6c5ce7', fontSize: '0.875rem', fontWeight: 400, padding: '5px 3px' }}
      >
        Load more
      </div>
    )
  }
  return (
    <div
      data-testid="defaultHeader-main"
      style={style.base}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          node.onToggle()
        }
      }}
    >
      <div style={node.active ? { ...style.title, color: 'blue' } : style.title}>
        {`${node.name} ${node.toggled ? '>' : ''}`}
      </div>
    </div>
  )
}

export const DefaultLoading = props => {
  return <div style={props.style}>Loading...</div>
}

export function InfiniteScrollLoader() {
  return (
    <SkeletonTile
      numberOfTiles={4}
      tileHeight={12}
      tileWidth={120}
      tileBottomSpace={10}
      tileTopSpace={10}
      tileleftSpace={25}
      tileRadius={6}
    />
  )
}

export function InitialLoader() {
  return (
    <SkeletonTile
      numberOfTiles={30}
      tileHeight={12}
      tileWidth={180}
      tileBottomSpace={18}
      tileTopSpace={18}
      tileleftSpace={8}
      tileRadius={6}
    />
  )
}

export function Spinner() {
  return (
    <SkeletonTile
      numberOfTiles={2}
      tileHeight={12}
      tileWidth={140}
      tileBottomSpace={10}
      tileTopSpace={10}
      tileleftSpace={25}
      tileRadius={6}
    />
  )
}
