//@ts-nocheck

import { Node, Transforms } from 'slate'

import { createHeadCell } from './createHeadCell'
import { createCell } from './createCell'

const handleAddCell = (editor, row, rowPath) => {
    const newCellPos = row.children.length
    const lastCell = row.children[newCellPos - 1]
    const path = [...rowPath, newCellPos]
    if (lastCell.type === 'th') {
        Transforms.insertNodes(editor, createHeadCell(), { at: path })
    }
    if (lastCell.type === 'td') {
        Transforms.insertNodes(editor, createCell(), { at: path })
    }
}

export const insertColAtEnd = (editor, tablePath) => {
    const table = Node.get(editor, tablePath)
    const selection = table.attrs.cols

    Array.from(table.children).forEach(
        (tableChild, tableChildPos) => {
            Array.from(tableChild.children).forEach((row, rowPos) => {
                if (row.type === "trgrp") {
                    const rowgrp = row
                    const rowGrpPos = rowPos
                    rowgrp.children.forEach((row, rowPos) => {
                        const rowPath = [...tablePath, tableChildPos, rowGrpPos, rowPos]
                        handleAddCell(editor, row, rowPath)
                    })
                    return
                }
                const rowPath = [...tablePath, tableChildPos, rowPos]
                handleAddCell(editor, row, rowPath)


            })
        }
    )
    let colWidths = table.attrs.colWidths.slice()
    colWidths.splice(selection, 0, 250)
    Transforms.setNodes(editor, {
        attrs: {
            ...table.attrs,
            colWidths,
            cols: table.attrs.cols + 1
        }
    }, { at: tablePath })
}
