import React from 'react';
import cn from 'classnames';

import './Grid.css';

export type gridProp = {
  columns: number,
  rows: number,
  columnGap?: number,
  rowGap?: number,
  justifyContent?:
  'center' |
  'end' |
  'flex-end' |
  'flex-start' |
  'initial' |
  'left' |
  'normal' |
  'right' |
  'space-around' |
  'space-between' |
  'space-evenly' |
  'start' |
  'stretch' |
  'unset',
  flexDirection?:
  'row' |
  'row-reverse' |
  'column' |
  'column-reverse',
  flexWrap?:
  'wrap-reverse' |
  'wrap' |
  'nowrap',
  alignContent?:
  'flex-end' |
  'flex-start' |
  'stretch' |
  'center' |
  'baseline' |
  'first baseline' |
  'last baseline' |
  'end' |
  'start' |
  'self-start' |
  'self-end',
  alignItems?:
  'flex-end' |
  'flex-start' |
  'stretch' |
  'center' |
  'baseline' |
  'first baseline' |
  'last baseline' |
  'end' |
  'start' |
  'self-start' |
  'self-end',
  className?: string,
  children: any
};

type divProps = {
  col: number,
  row: number
};

const Grid = (props: gridProp) => {

  const {
    columns,
    rows,
    // children,
    columnGap,
    rowGap,
    justifyContent,
    flexDirection,
    flexWrap,
    alignContent,
    alignItems,
    className
  } = props;

  // let gridValue = columns * rows;
  const classNames = cn('flex', className);

  const children = props.children.length ? props.children : Array.from(Array(props.children))

  const renderChildren = (row: number, col: number) => {
    return children[row + (row * (rows - 1)) + col]
  }

  /* {children[row + (row * (rows - 1)) + col]} */
  /* {children[(col * col) + row]} */

  const Div = (prop: divProps) => {
    const {
      col,
      row
    } = prop;

    const child = renderChildren(row, col);

    return (
      child ? child : null
    );
  };

  return (
    <div className={classNames} style={{ justifyContent: justifyContent, flexDirection: flexDirection, flexWrap: flexWrap, alignContent: alignContent, alignItems: alignItems }}>
      {
        Array.from(Array(columns)).map((a, key1) =>
          <React.Fragment key={key1}>
            {Array.from(Array(rows)).map((b, key2) =>
              <React.Fragment key={key2}>
                <Div row={key1} col={key2} />
              </React.Fragment>
            )}
          </React.Fragment>
        )
      }
    </div>
  );
};

Grid.defaultProps = {
  columnGap: 10,
  rowGap: 10,
  justifyContent: 'normal',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignContent: 'stretch',
  alignItems: 'stretch'
} as Partial<gridProp>;

export default Grid