import { Transforms, Range, Editor, Node } from 'slate'

import { Registry } from '@react-registry'

import imageExtensions from './imageExtensions'
import { placeholderImageSrc } from './placeholderImageSrc'
import { isSelectionCollapsed } from '../../../utils/index'
import { getNormalTextElement } from '../paragraph/utils'
import { insertImage, isImageUrl } from './utils'

export const handleImageError = (event) => {
  event.target.src = placeholderImageSrc
}

export const withImage = (editor) => {
  const { isVoid, insertData, deleteBackward, insertBreak } = editor

  editor.isVoid = (element) => {
    return element.type === 'img' ? true : isVoid(element)
  }

  editor.insertData = async (data) => {
    const text = data.getData('text/plain')
    const { files } = data
    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')
        if (mime === 'image') {
          reader.addEventListener('load', async () => {
            const url = reader.result
            await insertImage(editor, url)
          })
          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      await insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  editor.insertBreak = function () {
    const { selection } = editor;
    const isExpanded = !isSelectionCollapsed(selection);
    if (isExpanded) { // if expanded or no selection
      return insertBreak();
    }
    const currSelectionPath = selection?.anchor?.path;
    const currSelectionPathLen = currSelectionPath.length;
    const currSelectedComponent = Node.get(editor, currSelectionPath.slice(0, currSelectionPathLen - 1));
    if (!Editor.isVoid(editor, currSelectedComponent)) return insertBreak();
    Transforms.insertNodes(editor, getNormalTextElement(), {
      at: [...currSelectionPath.slice(0, currSelectionPathLen - 2), currSelectionPath[currSelectionPathLen - 2] + 1],
      select: true
    });
  }

  editor.deleteBackward = (unit) => {
    const { selection } = editor
    const isAtStart = selection?.anchor?.offset === 0
    const selectionPath = selection?.anchor?.path
    const selectionPathLength = selectionPath?.length - 1;
    if (isAtStart && selectionPathLength >= 1 && selectionPath[selectionPathLength - 1] !== 0) {

      //CASE 1: if curr node is void delete it
      const currNode = Node.get(editor, selectionPath.slice(0, selectionPathLength));
      if (Editor.isVoid(editor, currNode)) {
        return deleteBackward(unit);
      }

      // CASE 2: if prev sibling is void select it
      const prevSiblingPath = [
        ...selectionPath.slice(0, selectionPathLength - 1),
        selectionPath[selectionPathLength - 1] - 1
      ]
      const prevSibling = Node.get(editor, prevSiblingPath)

      if (Editor.isVoid(editor, prevSibling)) {
        return Transforms.select(editor, [...prevSiblingPath, 0]);
      }
      return deleteBackward(unit);
    }
    return deleteBackward(unit)
  }

  return editor
}



Registry.register(withImage, { id: 'withImage', registry: 'plugin' })
