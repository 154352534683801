import React, { useEffect } from 'react'
import cn from 'classnames'
import Textarea from '../Textarea/Textarea'
import Icon from '../Icon2'

function MultilineField(props: any) {
  let defaultMinHeight = 30
  let defaultMaxHeight = 500
  let defaultId = 'resizeableTextarea' + Date.now() //to create unique id

  const revisedMinHeight = props.minHeight < defaultMinHeight ? props.minHeight : defaultMinHeight
  const minHeight = props.minHeight ? revisedMinHeight : defaultMinHeight
  const reviseddMaxHeight = props.maxHeight < minHeight ? defaultMaxHeight : props.maxHeight
  const maxHeight = props.maxHeight ? reviseddMaxHeight : defaultMaxHeight
  const id = props.id ? props.id : defaultId
  const valueLimit = props.value?.length > 150 ? 100 : null
  const heightToFitContent = props.value ? valueLimit : null
  const testId = props.testId ? props.testId : 'cs-multiline-field'

  const classNames = cn(props.className, {
    'Textarea--negative': props.error
  })

  // The current y-position of mouse
  let y = 0

  // The height of the element
  let h = 0

  const mouseDownHandler = (e: { clientY: number }) => {
    const ele = document.getElementById(id)
    y = e.clientY

    // calculate the dimension of element
    const styles = getComputedStyle(ele)
    h = parseInt(styles.height, 0)

    // attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }

  const mouseUpHandler = () => {
    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler)
  }

  const mouseMoveHandler = (e: { clientY: number }) => {
    const ele = document.getElementById(id)

    const dy = e.clientY - y
    ele.style.height = `${h + dy}px`
  }

  useEffect(() => {
    let textarea = document.querySelector(`#${id}`)

    if (textarea) {
      textarea.addEventListener('input', autoResize, false)
    }
    function autoResize() {
      //autoresize only if scroll height is lessthan 100
      if (this.scrollHeight !== 40 && this.scrollHeight <= 100) {
        if (this.scrollHeight <= maxHeight) {
          this.style.height = 'inherit'
          this.style.height = this.scrollHeight + 'px'
        }
      }
    }
    return () => {
      textarea.removeEventListener('input', autoResize, false)
    }
  }, [])
  return (
    <div className="Textarea--resize" data-test-id={testId}>
      <Textarea
        style={{ maxHeight, minHeight: heightToFitContent || minHeight }}
        className={classNames}
        id={id}
        {...props}
      />
      <div className="Textarea--resize--hover">
        <div className="Textarea--resize__icon" onMouseDown={mouseDownHandler}>
          <Icon icon="Resize" />
        </div>
        {props.version !== "v2" && <div className="Textarea--resize__line" onMouseDown={mouseDownHandler}>
          <div></div>
        </div>}
      </div>
    </div>
  )
}

export default MultilineField
