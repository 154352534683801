import React, { useRef } from 'react'
import { DragObjectWithType, useDrop } from 'react-dnd'
import { Transforms, Editor } from 'slate'
import { GridDrop, GridDropLeft } from '../../elements/Element/grid'
import { isEqual } from "lodash"
//@ts-ignore
import styles from './style.module.css'


declare interface ICustomItem extends DragObjectWithType {
  path: any,
  dropped: any,
  canDrop: any,
}

export const DndBlock = (props) => {
  const { children, path, editor, collection, element, disablegriddnd } = props
  const accept = collection ? collection : 'doc'
  const dndBlockRef = useRef(null)
  const [{ over }, drop] = useDrop({
    accept: accept,
    collect: (monitor) => {
      if (!monitor.isOver() && props.singleLineRef?.current) {

        if (!monitor.canDrop()) {
          props.singleLineRef.current.style['opacity'] = '0'
          props.singleLineRef.current.style['transition'] = 'none'
        }
      }
      return {
        over: monitor.isOver()
      }
    },
    hover: (item: ICustomItem, monitor) => {
      let itemPath = item?.path || null
      if (accept === item.type) {
        //console.log("path", path, itemPath)
        if (JSON.stringify(path) === JSON.stringify(itemPath)) {
          return {}
        }
        if (itemPath && itemPath.length < path.length) {
          let sameLevelPath = path.slice(0, itemPath.length)
          if (isEqual(sameLevelPath, item.path)) {
            return {}
          }
        }
        const clientOffset = monitor.getClientOffset()
        if (dndBlockRef.current && clientOffset) {
          const hoverBoundingRect = dndBlockRef.current.getBoundingClientRect()
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
          const hoverClientY = clientOffset.y - hoverBoundingRect.top
          if (hoverClientY <= hoverMiddleY) {
            props.singleLineRef.current.style['width'] = `${hoverBoundingRect.right - hoverBoundingRect.left}px`;
            props.singleLineRef.current.style['left'] = `${hoverBoundingRect.left}px`;
            props.singleLineRef.current.style['top'] = `${hoverBoundingRect.top}px`;
            props.singleLineRef.current.style['opacity'] = '1'

          } else {
            props.singleLineRef.current.style['width'] = `${hoverBoundingRect.right - hoverBoundingRect.left}px`;
            props.singleLineRef.current.style['left'] = `${hoverBoundingRect.left}px`;
            props.singleLineRef.current.style['top'] = `${hoverBoundingRect.bottom}px`
            props.singleLineRef.current.style['opacity'] = '1'
          }
        }
      }
    },
    drop: (item, monitor) => {
      if (!item.dropped && item.canDrop) {
        if (dndBlockRef.current) {
          const hoverBoundingRect = dndBlockRef.current.getBoundingClientRect()
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
          const clientOffset = monitor.getClientOffset()
          const hoverClientY = clientOffset.y - hoverBoundingRect.top
          if (hoverClientY <= hoverMiddleY) {
            let newPath = item.path.slice()
            let isAllowed = true
            if (newPath[newPath.length - 1] + 1 === path[path.length - 1]) {
              isAllowed = false
            }
            let itemPathStr = item.path.join('')
            let dropPathStr = path.join('')
            if (item.path !== path && isAllowed) {
              newPath = path.slice()
              if (itemPathStr < dropPathStr && newPath[newPath.length - 1] !== 0) {
                newPath[newPath.length - 1] -= 1
              }
              Transforms.moveNodes(editor, { at: item.path, to: [...newPath] })
              Transforms.select(
                editor,
                Editor.range(editor, [...newPath, 0], [...newPath, 0])
              );
            }
          } else {
            let newPath = path.slice()
            newPath[newPath.length - 1] += 1
            let itemPathStr = item.path.join('')
            let dropPathStr = path.join('')
            if (item.path !== newPath && item.path !== path) {
              if (itemPathStr < dropPathStr && path[path.length - 1] !== 0) {
                newPath = path
              }
              Transforms.moveNodes(editor, { at: item.path, to: [...newPath] })
              Transforms.select(
                editor,
                Editor.range(editor, [...newPath, 0], [...newPath, 0])
              );
            }
          }
        }
      }
      item.dropped = true
    },
  })

  return (
    <div data-testid={'dnd-block'} className={styles['p-relative']} >
      <div
        ref={drop}
        style={{ paddingLeft: '30px', marginLeft: '-30px', ...(props.styles || {}) }}
      >
        <div ref={dndBlockRef} className={over ? styles['div-block-action'] : ''}>
          {children}
        </div>
      </div>
      {!disablegriddnd && <GridDropLeft editor={editor} element={element} accept={accept} />}
      {!disablegriddnd && <GridDrop editor={editor} element={element} accept={accept} />}
    </div>
  )
}
