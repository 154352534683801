//@ts-nocheck
import React, { useEffect, useState } from 'react'
import cn from 'classnames';

import { getAssets, getUsers } from '../../commons/request';
import { checkFileValidation } from '../../commons/util';

import { ListView, ThumbnailView, AssetBreadCrumb, AssetSearch } from '.';

const emptyStyle = {
  color: '#4a5568',
  fontSize: '1.375rem',
  fontWeight: '800',
  letterSpacing: '-.00019em',
  lineHeight: '1.6875rem',
  marginTop: 0,
}

const NoAssets = ({ length, children }) => {
  return length === 0 ? (
    <div style={{ textAlign: 'center', height: '292px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <p style={emptyStyle}>No Records Found</p>
    </div>
  ) : children
}

function SelectImage(props) {
  const { isEmbed, type, fileTypes, minSize, maxSize, excludeAssetUids } = props;
  const [state, setState] = useState({
    assets: [],
    loading: false,
    itemStatusMap: {},
    loadedImageUid: {},
    displayType: 'List',
    startIndex: 0,
    count: 30,
    stopIndex: 30,
    query: '',
    breadcrumb: [{
      displayName: 'All Assets',
      action: () => {
        setAssetKey('root')
        setShowSelected(false)
        setState(currState => ({
          ...currState,
          breadcrumb: currState.breadcrumb.slice(0, 1),
          folderUid: 'cs_root'
        }))
      }
    }],
    folderUid: 'cs_root',
    users: {},
    skip: null,
    limit: 0
  });

  const [showSelected, setShowSelected] = useState(false)
  const [selectedAssets, setSelectedAssets] = useState([])
  const [selectedAssestMap, setSelectedAssetsMap] = useState({ root: [] })
  const [assetKey, setAssetKey] = useState('root')

  /* runs only once */
  useEffect(() => {
    fetchOnChange({ skip: 0, limit: 30, startIndex: 0, stopIndex: 30 });
  }, [state.query, state.folderUid]);

  /* one render */
  useEffect(() => {
    getUsers({})
      .then(res => setState(prevState => ({ ...prevState, users: res })))
      .catch(console.error)
  }, [])

  const handleShowSelectedClick = () => {
    const selectedCount = selectedAssets.length
    if (selectedCount === 0) return
    // toggle selected view
    setShowSelected(!showSelected)
  }

  const fetchOnChange = async ({ sortBy = null, skip, limit, startIndex, stopIndex }) => {
    /* rendered here */
    const instanceItemStatusMapped = {};

    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loading';
    }
    setState(prevState => ({
      ...prevState,
      itemStatusMap: {
        ...instanceItemStatusMapped
      },
      loading: true
    }));
    let sort = {
      asc_field: '',
      dsc_field: ''
    };

    if (sortBy) {
      sort[`${sortBy['sortingDirection']}_field`] = sortBy['id'];
    }
    const response = await getAssets({ limit, 'skipSize': skip, query: state.query, folderUid: state.folderUid, isEmbed, type, excludeAssetUids, ...sort });
    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loaded'
    }

    if (fileTypes || minSize || maxSize)
      response?.assets.forEach((asset: any) => {
        checkFileValidation(asset, { extensions: fileTypes, minSize, maxSize })
      })

    /* rendered here */
    setState(prevState => ({
      ...prevState,
      assets: [
        ...response.assets
      ],
      itemStatusMap: {
        ...instanceItemStatusMapped
      },
      startIndex,
      stopIndex,
      loading: false,
      count: response.count,
    }));

  }

  const loadMore = async ({ sortBy = null, skip, limit, startIndex, stopIndex }) => {
    if (state.skip === skip && state.limit === limit) return;

    let sort = {
      asc_field: '',
      dsc_field: ''
    };

    if (sortBy) {
      sort[`${sortBy['sortingDirection']}_field`] = sortBy['id'];
    }
    skip = skip === 0 ? skip : skip + 1;
    const response = await getAssets({ limit, 'skipSize': skip, query: state.query, folderUid: state.folderUid, type, excludeAssetUids, ...sort });

    const instanceItemStatusMapped = {};

    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loaded';
    }

    if (fileTypes)
      response?.assets.forEach((asset: any) => {
        checkFileValidation(asset, { extensions: fileTypes })
      })

    setState(prevState => ({
      ...prevState,
      assets: [
        ...prevState.assets,
        ...response.assets
      ],
      itemStatusMap: {
        ...prevState.itemStatusMap,
        ...instanceItemStatusMapped
      },
      startIndex,
      stopIndex,
      skip,
      limit
    }))

  }

  const handleClick = (asset) => {
    if (asset && asset.is_dir) {
      setAssetKey(asset.uid)
      setShowSelected(false)
      setState(currState => ({
        ...currState,
        breadcrumb: [...currState.breadcrumb, {
          displayName: asset.name,
          action: (len = state.breadcrumb.length, uid = asset.uid) => {
            setState(prevState => {
              return {
                ...prevState,
                breadcrumb: prevState.breadcrumb.slice(0, len + 1),
                folderUid: uid,
                query: ''
              }
            });
          }
        }],
        folderUid: asset.uid,
        query: ''
      }));
    } else if (!props?.multiple) {
      props.setImages([asset])
      setSelectedAssets([asset])
    }
  }

  const updateSelection = (selectedAssets) => {
    setSelectedAssets(selectedAssets)
    props.setImages(selectedAssets)
  }

  const loadMoreGrid = () => {
    const skip = state.stopIndex;
    const limit = 30;
    const startIndex = state.stopIndex + 1;
    const stopIndex = startIndex + limit;
    loadMore({ skip, startIndex, limit, stopIndex });
  }

  const selectedCount = selectedAssets.length
  const selectedAssetsClassNames = cn('selected-assets', {
    'selected-assets__disabled': selectedCount === 0
  })

  const getChangeSelectionViewBox = () => {
    if (showSelected) return (<span>Show all assets ({state.count})</span>)
    else return (<span>Show selected assets ({selectedAssets.length})</span>)
  }

  return (
    <div style={{ fontFamily: 'Inter' }} data-testid="select-image-wrapper">
      <div className="asset-box">
        <AssetBreadCrumb breadcrumbs={state.breadcrumb} />
        <div className={selectedAssetsClassNames} onClick={handleShowSelectedClick}>
          {getChangeSelectionViewBox()}
        </div>
      </div>
      <AssetSearch showSearchBox={!showSelected} selectedAssetsCount={selectedCount} assetsCount={state.count} listType={state.displayType} setState={setState} />
      <NoAssets length={state.count}>
        {
          state.displayType === 'Grid' ? (
            <ThumbnailView
              readOnly={showSelected}
              assetKey={assetKey}
              selectedAssestMap={selectedAssestMap}
              setSelectedAssetsMap={setSelectedAssetsMap}
              selectedAssets={selectedAssets}
              updateSelection={updateSelection}
              multiple={props?.multiple}
              state={state}
              handleClick={handleClick}
              loadMoreGrid={loadMoreGrid} />
          ) :
            <ListView
              assetKey={assetKey}
              selectedAssestMap={selectedAssestMap}
              setSelectedAssetsMap={setSelectedAssetsMap}
              selectedAssets={selectedAssets}
              readOnly={showSelected}
              updateSelection={updateSelection}
              multiple={props?.multiple}
              users={state.users}
              loading={state.loading}
              state={state}
              handleClick={handleClick}
              fetchOnChange={fetchOnChange}
              loadMore={loadMore} />
        }
      </NoAssets>

    </div>
  )
}


export default SelectImage;
