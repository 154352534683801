//@ts-nocheck
import React from 'react'
import { Registry } from '@react-registry'
import { Node } from 'slate'
import { useSlate, useSelected, ReactEditor } from 'slate-react'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'

export * from './deserializer'

const TableCell = (props) => {
  const selected = useSelected()

  let className = cx(styles['div-table-cell'], { [styles['div-table-cell-focus']]: selected })
  return (
    <div
      data-type="td"
      data-testid="td"
      className={className}
      {...props.attributes}>
      <div style={{ overflow: 'hidden', flexWrap: 'wrap', width: '100%' }}>{props.children}</div>
    </div>
  )
}


export default TableCell

Registry.register(
  {
    Component: TableCell,
    title: 'Table Cell',
    iconName: 'ri-layout-column-line',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
      inSlashToolbar: false,
    },
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
  },
  { id: 'td', registry: 'v2.element' }
)
