import React from 'react'

export const DeleteTable = (props) => {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 1700 1500"
            preserveAspectRatio="none"
            {...props}
            fill='currentColor'
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M267.362 1461.35H1394.84c1.28 0 2.55-.04 3.81-.11 1.27.07 2.55.11 3.85.11 25.2 0 45-14.19 45-32.25v-11.97c0-.26.01-.52.01-.78s-.01-.52-.01-.78V875.133c0-.26.01-.52.01-.781V874.126l-.01-.101V590.602c0-2.533-.39-4.99-1.13-7.342.54-2.846.83-5.794.83-8.82V281.516l-90.3 90.299V529.28h-157.47l-90.32 90.32h248.39v209.752H899.362l-128.86 128.86v141.938h-141.94l-90.32 90.32h232.26v180.88h-413.14l-90 90zm6.144-212.45l-90 90v-186.71c-.33-2.24-.5-4.54-.5-6.88s.17-4.64.5-6.87V590.602c0-1.944.229-3.843.67-5.684a46.71 46.71 0 01-1.178-10.478V213.16c0-25.29 19.866-45.16 45.15-45.16H1354.41l-90.32 90.32H273.298v270.96h719.83l-90.32 90.32h-42.306v42.307l-90 90V619.6H273.506v209.752h419.551l-90 90H273.506v180.798h148.75l-90.32 90.32h-58.43v58.43zM1357.5 1371.35H860.502v-180.88H1357.5v180.88zm0-271.2V919.352H860.502v180.798H1357.5z"
            />
            <path
                d="M119.553 1573.29L1573.29 119.552c31.31-31.312 41.92-69.914 24.1-87.733-17.82-17.82-56.43-7.218-87.74 24.093L55.913 1509.65c-31.311 31.31-41.912 69.92-24.093 87.74 17.819 17.81 56.421 7.21 87.733-24.1z"
            />
        </svg>
    )
}