import BarGraphComponent from './BarGraph'
import PieChartComponent from './PieChart'
import StackedBarGraphComponent from './StackedBarGraph'
import LineGraphComponent from './LineGraph'
import RadialBarChartComponent from './RadialBarChart';

export const BarGraph = BarGraphComponent
export const PieChart = PieChartComponent
export const StackedBarGraph = StackedBarGraphComponent
export const LineGraph = LineGraphComponent
export const RadialBarGraph = RadialBarChartComponent
