import React, { useCallback, useEffect, useRef, useState } from 'react'
import './Pill.css'
import cn from 'classnames'
import { v4 } from 'uuid'
import { IPill, PillStatus, PillVariant } from './type'
import PillItem from './PillItem'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { reorder, setAttributeCollectionOfElement, setCaretPos } from '../../utils/index'
import { usePrevious } from '../../utils/hooks'

const focusLastEditablePill = (parentNode) => {
  const inputNodes = parentNode.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>

  if (parentNode.tabIndex === 0) {
    parentNode.setAttribute('tabindex', '-1')
    setAttributeCollectionOfElement(inputNodes, 'tabindex', '0')
  }

  if (inputNodes.length) {
    const lastInputEle = inputNodes[inputNodes.length ? inputNodes.length - 1 : 0]
    if (lastInputEle) {
      lastInputEle.tabIndex = 0
      setCaretPos(lastInputEle, lastInputEle.value.length)
    }
  }
}

const focusFirstEditablePill = (parentNode, addNewPillItem) => {
  const inputNodes = parentNode.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>
  parentNode.tabIndex = -1
  setAttributeCollectionOfElement(inputNodes, 'tabindex', '0')
  if (inputNodes.length) {
    const firstInputEle = inputNodes[0]
    if (firstInputEle) {
      setCaretPos(firstInputEle, firstInputEle.value.length)
    }
  }
  if (!inputNodes.length) addNewPillItem()
}

interface PillProps {
  background?: string
  status?: PillStatus
  variant?: PillVariant
  testId?: string
  isDisabled?: boolean
  items: IPill[]
  isEditable?: boolean
  onChange?: (data: IPill[]) => void
  isSortable?: boolean
  placeholder?: string
  shouldHaveBorder?: boolean
  stroke?: string
}

const Pill: React.FC<PillProps> = (props) => {
  const {
    variant,
    testId,
    isDisabled,
    items,
    status,
    isEditable,
    onChange,
    isSortable,
    placeholder,
    background,
    shouldHaveBorder,
    stroke
  } = props
  const dropStateRef = useRef<{ isDragging: boolean }>({ isDragging: false })
  const pillWrapRef = useRef<HTMLDivElement>()
  const prevItems = usePrevious(items)

  const onItemChange = (updateItemData: Omit<IPill, 'id'>, index: number) => {
    const newItem = [...items]
    const newUpdatedData = { ...updateItemData, id: items[index].id }
    newItem[index] = newUpdatedData
    onChange(newItem)
  }

  const handleNewItemAdd = useCallback(() => {
    if (dropStateRef.current.isDragging) return
    if (items.length && items[items.length - 1].text === '') return
    const newItem = [...items]
    newItem.push({ id: v4().split('-').join(''), text: '' })
    onChange(newItem)
  }, [items, onChange, isEditable])

  const handleItemRemove = (index: number) => {
    const newItem = [...items]
    newItem.splice(index, 1)
    onChange(newItem)
  }

  const handleDragEnd = (result) => {
    dropStateRef.current.isDragging = false

    if (!result?.destination) return
    if (result?.source?.index === result?.destination?.index) return
    onChange(reorder(items, result.source.index, result.destination.index))
  }

  const handleDragStart = () => {
    dropStateRef.current.isDragging = true
  }

  useEffect(() => {
    if (isEditable) {
      const keyUpListener = (event) => {
        if (event.code === 'Space') {
          focusFirstEditablePill(pillWrapRef.current, handleNewItemAdd)
        }
      }
      const focusListener = () => {
        document.addEventListener('keyup', keyUpListener)
      }
      const blurListener = () => {
        document.removeEventListener('keyup', keyUpListener)
      }
      pillWrapRef.current.addEventListener('focus', focusListener)
      pillWrapRef.current.addEventListener('blur', blurListener)
      return () => {
        document.removeEventListener('keyup', keyUpListener)
        pillWrapRef.current.removeEventListener('focus', focusListener)
        pillWrapRef.current.removeEventListener('blur', focusListener)
      }
    }
    return () => {}
  }, [pillWrapRef.current, handleNewItemAdd, isEditable])

  useEffect(() => {
    if (isEditable) {
      const prevItemCount = prevItems?.length || 0
      const latestItemCount = items?.length || 0
      if (prevItems && latestItemCount > prevItemCount) {
        focusLastEditablePill(pillWrapRef.current)
      }
    }
  }, [items?.length, isEditable])

  const wrapClassName = cn(
    'Pill',
    `Pill__${variant}`,
    { [`Pill__disabled`]: isDisabled },
    { [`Pill--border`]: shouldHaveBorder }
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Droppable droppableId="PillDroppable" direction="horizontal" isDropDisabled={!isSortable || isDisabled}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={(el) => {
              provided.innerRef(el)
              pillWrapRef.current = el
            }}
            className={wrapClassName}
            data-test-id={testId}
            tabIndex={shouldHaveBorder ? 0 : undefined}
            onClick={isEditable && !isDisabled ? handleNewItemAdd : undefined}>
            {!items.length && placeholder ? (
              <div className="Pill__placeholder">{placeholder}</div>
            ) : (
              items.map((eachItem, index) => {
                const { text, leadingIcon, trailingIcon, id } = eachItem
                return (
                  <PillItem
                    key={id}
                    itemId={id}
                    index={index}
                    text={text}
                    leadingIcon={leadingIcon}
                    trailingIcon={trailingIcon}
                    variant={variant}
                    status={status}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    onChangeItem={isEditable ? onItemChange : undefined}
                    onNewItemAdd={isEditable ? handleNewItemAdd : undefined}
                    onRemoveItem={isEditable ? handleItemRemove : undefined}
                    isSortable={isSortable}
                    background={background}
                    stroke={stroke}
                  />
                )
              })
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

Pill.defaultProps = {
  variant: 'label',
  testId: 'cs-pill',
  isDisabled: false,
  items: [],
  shouldHaveBorder: true
}

export default Pill
