
export const createHeadCell = () => {
    const newcol = {
        type: 'th', attrs: {}, children: []
    }
    let child = {
        type: 'p',
        attrs: {},
        children: [
            {
                text: ''
            }
        ]
    }
    newcol.children = [child]
    return JSON.parse(JSON.stringify(newcol))
}