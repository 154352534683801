import { ReactEditor } from "slate-react";

const events = (editor) => {

  const isFocused = () => ReactEditor.isFocused(editor);
  const focus = () => ReactEditor.focus(editor);
  const blur = () => ReactEditor.blur(editor);

  return {
    isFocused,
    focus,
    blur
  }
};

export default events;