import { BaseEditor, Editor, Path, Range } from "slate"
import DOMPurify from 'dompurify';

import { ICommentState, IDiscussionDTO, IElement, IMentionedList, IMentionItem, IMessageDTO, IUserDTO, IUserState } from "../../../utils/types"
import { maxMessageLength, mentionLimit } from "./contants";
import { uniqBy } from "lodash";

export const isCommentsAllowedForElement = (editor: BaseEditor, element: IElement, path: Path) => {
  const restrictedElements = ['doc', 'fragment', 'ol', 'ul']
  return Editor.isBlock(editor, element) && !restrictedElements.includes(element.type) && (path.length === 2 || element.type === "li")
}

export const getCurrentDiscussion = (discussions: { [key: string]: IDiscussionDTO }, element: IElement): IDiscussionDTO | undefined => {
  if (discussions && discussions[element.uid]) {
    return discussions[element.uid]
  }
  return undefined
}

export const isNewDiscussion = (activeDiscussion: string) => {
  return activeDiscussion.slice(0, 3) === "new"
}

export const checkIsRangeComment = (activeDiscussion: string) => {
  return activeDiscussion.startsWith('range') || activeDiscussion === 'new-range-comment'
}

export const getDiscussionTitle = (commentCount: number) => {
  let title = ""
  switch (commentCount) {
    case 1:
      title = `${commentCount} Comment`
      break
    case 0:
      title = `Add new Comment`
      break
    default:
      title = `${commentCount} Comments`
  }
  return title
}

export const getUserName = (user: IUserDTO) => {
  return (user.first_name && user.last_name) ? user.first_name + ' ' + user.last_name : user.first_name || user.last_name || user.email;
}

export const getCommentBody = (state: ICommentState) => {
  const comment: { message: string, to_users: Array<string>, to_roles: Array<string> } = {
    message: "",
    to_users: [],
    to_roles: []
  }
  let finalMessage = state.message.slice()
  const updateMentionToUID = (entity: IMentionItem, result: Array<string>) => {
    const displayName = entity.display
    if (finalMessage.indexOf(displayName) !== -1) {
      const regexString = displayName.replace(/\+/g, '\\+');
      const regexUser = new RegExp(regexString);
      finalMessage = finalMessage.replace(regexUser, '{{' + entity.id + '}}')
      result.push(entity.id)
    }
  }
  state.to_users.forEach((user) => updateMentionToUID(user, comment.to_users))
  state.to_roles.forEach((role) => updateMentionToUID(role, comment.to_roles))

  comment.message = finalMessage
  return comment
}

export const getMessageWithDisplayName = (comment: IMessageDTO | undefined, userState: IUserState, profile: "text" | "html") => {
  if (!comment) {
    return undefined
  }
  let tempText = comment.message
  if (comment?.to_users?.length) {
    comment.to_users.forEach((user) => {
      const userPattern = new RegExp(`{{${user}}}`, "g")
      const userData = userState.userMap[user]
      if (profile === "html") {
        tempText = tempText.replace(userPattern, `<b>@${userData.display || getUserName(userData)}</b>`)
      } else {
        tempText = tempText.replace(userPattern, `@${userData.display || getUserName(userData)}`)
      }
    })
  }
  if (comment?.to_roles?.length) {
    comment.to_roles.forEach((role) => {
      const rolePattern = new RegExp(`{{${role}}}`, "g")
      const roleData = userState.roleMap[role]
      if (profile === "html") {
        tempText = tempText.replace(rolePattern, `<b>@${roleData.name}</b>`)
      } else {
        tempText = tempText.replace(rolePattern, `@${roleData.name}`)
      }
    })
  }
  return tempText
}
export const sanitizeData = (dirty: any) => {
  return DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } });
}

export const getError = (comment: string, to_roles: IMentionedList, to_users: IMentionedList) => {
  if (comment.length > maxMessageLength) {
    return `Limit exceeded you can have a maximum length of ${maxMessageLength} character(s).`
  }
  if (to_users.length > mentionLimit || to_roles.length > mentionLimit) {
    return `Limit exceeded you can tag max ${mentionLimit} users and ${mentionLimit} roles`
  }
  return ""
}

export const removeDeletedMentions = (message: string, to_roles: IMentionedList, to_users: IMentionedList) => {
  let to_users_temp: IMentionedList = []
  let to_roles_temp: IMentionedList = []
  to_roles.forEach((role) => {
    if (message.indexOf(role.display) !== -1) {
      to_roles_temp.push(role)
    }
  })
  to_users.forEach((user) => {
    if (message.indexOf(user.display) !== -1) {
      to_users_temp.push(user)
    }
  })
  to_users_temp = uniqBy(to_users_temp, "id")
  to_roles_temp = uniqBy(to_roles_temp, "id")
  return {
    to_users: to_users_temp,
    to_roles: to_roles_temp
  }
}

export const parseDiscussionUID = (discussionUid: string) => {
  if (checkIsRangeComment(discussionUid)) return discussionUid.replace('range-', '')
  return discussionUid
}

export const getTargetDOM = (activeDiscussion : string, isNewDiscussion: boolean) => {
  const targetDiscussionDOM = document.querySelector(`[data-discussion-uid="${parseDiscussionUID(activeDiscussion)}"]`)
  if (targetDiscussionDOM) return targetDiscussionDOM

  if (isNewDiscussion) {
    const domSelection = document.getSelection()
    if (!domSelection || domSelection.rangeCount < 1) return

    const domRange = domSelection.getRangeAt(0)
    return domRange
  }

}