import { Transforms, Editor, Node } from 'slate'
import { ElementWithType } from '../../../../../utils/types'

const LIST_TYPES = ['ol', 'ul']

declare type customNode = Node & { type: any }

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n: customNode) => LIST_TYPES.includes(n.type),
    split: true,
  })

  let type;
  if (isActive) {
    type = 'p'
  } else if (isList) {
    type = 'li'
  } else {
    type = format
  }
  Transforms.setNodes(editor, {
    type: type,
  } as ElementWithType)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n: ElementWithType) => n.type === format
  })

  return !!match
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}
