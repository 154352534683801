import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { Editor, Node, Transforms } from 'slate'
import { useSlate } from 'slate-react'

import { formatShortcut } from '../../../../utils/shortcuts/formatShortcut'
import { deleteCol, insertColAfter, insertColBefore } from '../utils/index'
import { TableDeleteColumnIcon, TableAddColumnAboveIcon, TableAddColumnBelowIcon } from '../../../utils/Icon'
import ToolTip from '../../../../../../Tooltip/Tooltip'
//@ts-ignore
import styles from '../style.module.css'
import Tippy from '@tippyjs/react'
import { ElementWithType } from '../../../../../../../utils/types'
import { cx } from '@emotion/css'
export default (props) => {
  const { index, children, width, table, tablePath, type, disabled } = props
  const editor = useSlate()
  const tooltipRef = useRef(null)
  const getFirstCellPath = () => {
    const [[, firstRowPath]] = Editor.nodes(editor, {
      at: tablePath,
      match: (node) => {
        //@ts-ignore
        return node.type === 'tr'
      }
    })
    return [...firstRowPath, index]
  }
  const [{ over }, drop] = useDrop({
    accept: type,
    canDrop: () => !disabled,
    collect: (monitor) => {
      return {
        over: monitor.isOver(),
      }
    },
    drop: (item: any, monitor) => {
      Array.from(table.children).map((tableChild: any, tableChildIndex) => {
        Array.from(tableChild.children).map((row: any, rowIndex) => {
          if (row.type === 'trgrp') {
            const rowGrp = row
            const rowGrpIndex = rowIndex
            rowGrp.children.forEach((row, rowIndex) => {
              const sourcePath = [...tablePath, tableChildIndex, rowGrpIndex, rowIndex, item.index]
              const destinationPath = [...tablePath, tableChildIndex, rowGrpIndex, rowIndex, index]
              Transforms.moveNodes(editor, { at: sourcePath, to: destinationPath })
            })
          } else {
            const sourcePath = [...tablePath, tableChildIndex, rowIndex, item.index]
            const destinationPath = [...tablePath, tableChildIndex, rowIndex, index]
            Transforms.moveNodes(editor, { at: sourcePath, to: destinationPath })
          }
        })
      })
      let colsWidth = table.attrs.colWidths.slice()
      const sourceValue = colsWidth.splice(item.index, 1)
      colsWidth.splice(index, 0, sourceValue[0])
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table.attrs,
            colWidths: colsWidth,
          },
        } as ElementWithType,
        { at: tablePath }
      )
    },
  })
  const closeTooltip = (dom) => {
    if (dom) {
      if (dom.className === 'scrte-table-column-dnd') {
        return
      }
      if (dom?._tippy) {
        dom._tippy.hide()
        return
      }
      closeTooltip(dom.parentElement)
    } else {
      return
    }
  }
  return (
    <ToolTip position="top" content={
      <div data-testid="col-toolbar" style={{ display: "flex", alignItems: "center", padding: "2px 8px" }} ref={tooltipRef}>
        <span data-testid="delete-col-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
          e.preventDefault()
          const firstCellPath = getFirstCellPath()
          Transforms.select(editor, firstCellPath)
          deleteCol(e, { editor })
          closeTooltip(tooltipRef.current)

        }}
        ><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="delete-col-icon-text" className={styles['scrte-table-tooltip-text']} >Delete Column</span>} ><TableDeleteColumnIcon /></Tippy></span>
        <span data-testid="insert-col-before-icon" className={styles['table-column-toolbarIcon']} style={{ margin: '0px 10px' }} onMouseDown={(e) => {
          e.preventDefault()
          closeTooltip(tooltipRef.current)
          const firstCellPath = getFirstCellPath()
          Transforms.select(editor, firstCellPath)
          insertColBefore(e, { editor })
          Transforms.select(editor, firstCellPath)

        }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="insert-col-before-icon-text" className={styles['scrte-table-tooltip-text']} >Add Column to the left  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+left')}</span></span>} ><TableAddColumnAboveIcon /></Tippy></span>
        <span data-testid="insert-col-after-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
          e.preventDefault()

          const firstCellPath = getFirstCellPath()
          Transforms.select(editor, firstCellPath)
          insertColAfter(e, { editor })
          closeTooltip(tooltipRef.current)
          Transforms.select(editor, [...firstCellPath.slice(0, -1), index + 1])

        }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="insert-col-after-icon-text" className={styles['scrte-table-tooltip-text']} >Add Column to the right  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+right')}</span></span>} ><TableAddColumnBelowIcon /></Tippy></span>

      </div>
    } variantType="light"
      trigger="click"
      disabled={disabled}
    >
      <div
        ref={drop}
        contentEditable={false}
        style={{
          width: `${width}px`,
          display: 'inline-block',
          height: '14px',
          marginBottom: '-2.8px',
          textAlign: 'center',
          opacity: `${over ? '1' : ''}`,
          borderLeft: `${over ? '2px solid skyblue' : ''}`,
          borderRight: `${over ? '2px solid skyblue' : ''}`,
        }}
        className={cx(styles['table-column-draggable-container'], { [styles['disabled']]: disabled })}
        data-testid="table-column-draggable-container">
        {children}
      </div>
    </ToolTip>
  )
}
