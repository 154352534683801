import React, { useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual';
import './ContentEditor.css'

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
}

const autoSize = (node) => {
  let mirror = document.querySelector('.Tag__item__mirror')
  if (!mirror) {
    mirror = document.createElement('div')
    mirror.setAttribute('class', 'Tag__item__mirror')
    document.body.appendChild(mirror)
  }
  mirror.textContent = node.value
  node.style.width = getComputedStyle(mirror).width
}

const ContentEditor = (props) => {
  const [value, setValue] = useState(props.value)
  const inputRef = useRef(null)

  useEffect(() => {
    const node = inputRef.current
    setValue(props.value)
    autoSize(node)
  }, [props.value])

  const handleBlur = () => {
    if (value && value.trim()) {
      props.updateOption({ label: value, id: props.option.id })
    } else {
      props.updateOption({ label: props.option.label, id: props.option.id })
    }
  }

  const handleChange = (e) => {
    const node = inputRef.current
    autoSize(node)
    const value = e.target.value
    setValue(value)
  }

  const handleKeyDown = (event) => {
    event.stopPropagation()
    if (event.key === 'Enter') {
      event.preventDefault()
      const node = inputRef.current
      node.blur()
    }
  }

  return (
    <input
      data-test-id="content-editor-input"
      type="text"
      value={value}
      ref={inputRef}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    />
  )
}

export default React.memo(ContentEditor, areEqual)
