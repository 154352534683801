//@ts-nocheck
import React from 'react'
import { Transforms, Editor, Range, Node } from 'slate'
import { ReactEditor } from 'slate-react'
import { v4 } from 'uuid';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Icon,
  Field,
  TextInput,
  Checkbox
} from '../../../../../../../../index'
import { Editor as ModifiedEditor } from '../../../../../utils/types'
import { insertImage } from '../../../image'
import { checkProps } from '../../utils/checkProps'
import { InvalidPropsMsg } from '../../utils/InvalidPropsMessage/InvalidPropsMsg'
import styles from "../../reference/utils/style.module.css"
import { cx } from "@emotion/css"
import SelectImage from '../SelectImage'
import './style.css'
import { getReferenceNode, isEmbedAssetLink, setAssetInEditor } from '../../utils';
import { updateAssetLink } from '../../reference/utils/utils';
import { getAssetType } from './util';


declare interface ModifiedEditorSecond extends ModifiedEditor, ReactEditor { }

export declare type ModalComponentProps = {
  editor: ModifiedEditorSecond
  closeModal: Function
}

export const insertEmbedAsset = ({ asset, editor, isInline }) => {
  const selection = editor.selection || editor.savedSelection;
  const assetType = getAssetType(asset && asset['content_type'] || "");
  const node = {
    type: 'reference',
    attrs: {
      'display-type': assetType === null ? 'display' : 'download',
      'asset-uid': asset.uid,
      'content-type-uid': 'sys_assets',
      "asset-link": asset.url,
      "asset-name": asset.title,
      "asset-type": asset.content_type,
      "type": 'asset',
      'class-name': 'embedded-asset',
      inline: isInline,
    },
    uid: v4().split('-').join(''),
    children: [{ text: '' }]
  }

  if (!editor?.requestProps) {
    editor.requestProps = {}
  }
  if (!editor?.requestProps?.uidToEntry) {
    editor.requestProps['uidToEntry'] = {};
  }
  asset['_embed_permission'] = {
    read: true,
    update: true,
    create: true,
    publish: true,
    delete: true
  }
  editor['requestProps']['uidToEntry'][asset.uid] = asset;
  if (selection && !Range.isCollapsed(selection)) {
    Transforms.select(editor, selection);
    Transforms.delete(editor, { at: selection });
    Transforms.insertNodes(editor, node);
  } else if (selection) {
    const [match] = Editor.nodes(editor, {
      match: (n: ElementWithType) => n.type === 'p',
      at: selection
    })
    if (match && Node.string(match[0])?.length === 0 && !isInline) {
      Transforms.insertNodes(editor, node, { at: match[1] })
    }
    else {
      Transforms.insertNodes(editor, node, { at: selection });
    }
  } else {
    Transforms.insertNodes(editor, node, { at: Editor.end(editor, []) });
  }
}

const PreviewCard = ({ children, footer, active, onClick, testId }) => {
  return (
    <div className={cx(styles['preview_card'], active && styles['preview_card--active'])} onClick={onClick} data-testid={testId || "cs-asset-preview-card"}>
      <div className={styles['preview_card__body']}>
        {children}
      </div>
      <div className={styles['preview_card__footer']}>
        {footer}
      </div>
    </div>
  )
}

const Divider = ({ title }) => {
  return (
    <div className="cs__divider">
      <span className='cs__title'>{title}</span>
      <span className='cs__ruler'></span>
    </div>
  )
}
export const SelectEmbedAssetType = ({ imageSelected, isInline, setIsInline }) => {
  const assetType = getAssetType(imageSelected?.['content_type']);
  const isImage = !assetType;
  const url = isImage && imageSelected?.['url'];

  return (imageSelected.url && <div style={{ padding: "20px" }}>
    <Divider title="Embed Type" />
    <div className={styles['reference__body--container']}>
      <PreviewCard footer="Embed as block" active={!isInline} onClick={() => { setIsInline(false) }} testId={"asset-embed-block"}>
        {url ? <span className={cx(styles['img__preview__icon--block'], styles['img__preview__icon'])} style={{ background: `url(${url})` }}></span> : <Icon icon={assetType} size='large' />}
      </PreviewCard>
      <PreviewCard footer="Embed as a part of text" active={isInline} onClick={() => { setIsInline(true) }} testId={"asset-embed-inline"}>
        <div>Lorem {url ? <span className={cx(styles['img__preview__icon--inline'], styles['img__preview__icon'])} style={{ background: `url(${url})` }} /> : <Icon icon={assetType} />} Ipsum</div>
      </PreviewCard>
    </div>
  </div>)
}

export const SelectEmbedAssetTypeUpload = ({ imageSelected, isInline, setIsInline }) => {
  return (<div style={{ padding: "20px" }}>
    <Divider title="Embed Type" />
    <div className={styles['reference__body--container']}>
      <PreviewCard footer="Embed as block" active={!isInline} onClick={() => { setIsInline(false) }} testId="asset-upload-block">
        <Icon icon={'Asset'} size='large' />
      </PreviewCard>
      <PreviewCard footer="Embed as a part of text" active={isInline} onClick={() => { setIsInline(true) }} testId="asset-upload-inline">
        <div>Lorem <Icon icon={'Asset'} /> Ipsum</div>
      </PreviewCard>
    </div>
  </div>)
}

const EmbedAssetLink = ({ imageSelected, state, setState }) => {
  const { linkTitle, newTab } = state['linkDetails'];
  return (imageSelected && <div style={{ padding: "20px" }}>
    <Divider title="Link Details" />
    <div style={{ padding: '20px 0 ' }}>
      <Field labelText="Display text">
        <TextInput
          name="text"
          value={linkTitle}
          onChange={(e) => {
            e.persist();
            const linkTitle = e.target.value
            setState(prevState => ({
              ...prevState, linkDetails: {
                ...prevState.linkDetails,
                linkTitle: linkTitle,
              }
            }))
          }}
          type="text"
          placeholder="Enter Link Text"
          autoComplete="off"
          data-testid={'asset-link-text'}
        />
      </Field>

      <div style={{ paddingLeft: '.55rem' }}>
        <Checkbox
          checked={newTab}
          text="Open link in new Tab"
          testId={'asset-link-checkbox'}
          onChange={(e) => {
            e.persist();
            const newTab = e.target.checked
            setState(prevState => ({
              ...prevState, linkDetails: {
                ...prevState.linkDetails,
                newTab: newTab
              }
            }))
          }} />
      </div>
    </div>
  </div>
  )
}

const getDefaultState = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'reference' && n.attrs?.['display-type'] === 'link' });
  const selection = editor.selection || editor.savedSelection;
  const linkDetailObject = {
    linkTitle: '',
    href: '',
    newTab: false,
  }
  if (!selection) return linkDetailObject
  if (Boolean(link)) {
    const attrs: any = link[0].attrs
    linkDetailObject.newTab = attrs.target === '_blank'
    linkDetailObject.linkTitle = Editor.string(editor, link[1]);
    linkDetailObject.href = attrs?.['href'];
  } else if (Range.isExpanded(selection)) {
    linkDetailObject.linkTitle = Editor.string(editor, selection);
  }
  return linkDetailObject
}

const insertReferenceLink = ({ editor, state }) => {
  const { linkTitle, newTab } = state['linkDetails'];
  let selection = editor.selection || editor.savedSelection;
  if (!selection) {
    ReactEditor.focus(editor);
    Transforms.select(editor, Editor.end(editor, []));
  }
  selection = editor.selection || editor.savedSelection;
  const node = getReferenceNode({
    type: 'link', asset: {
      ...state.image,
      contentTypeUid: 'sys_assets'
    },
    linkDetails: {
      text: linkTitle || state.image?.url,
      inNewTab: newTab
    }
  });
  setAssetInEditor(editor, state.image);
  if (isEmbedAssetLink(editor)) {
    // update embed link;
    updateAssetLink(editor, state);
    return;
  }

  Transforms.select(editor, selection);
  if (selection && Range.isCollapsed(selection)) {
    Transforms.insertNodes(editor, node, { at: selection });
  } else {
    Transforms.wrapNodes(editor, node, { split: true });
    Transforms.insertText(editor, state.linkDetails.linkTitle || '/undefined', { at: editor.selection });
  }
}

export default class extends React.Component<ModalComponentProps> {
  state = {
    linkDetails: getDefaultState(this.props.editor),
    image: {
      url: '',
      filename: '',
      uid: ''
    },
    newTab: false,
    isInline: false
  }

  setImage = (asset) => {
    this.setState({
      ...this.state,
      image: asset
    })
  }
  setIsInline = (value) => {
    this.setState({
      ...this.state,
      isInline: value
    })
  }

  handleSubmit = () => {
    const url = this.state.image?.url;
    const asset_uid = this.state.image?.uid;
    const editor = this.props.editor;
    if (url) {
      if (this.props?.type === 'asset') {
        insertEmbedAsset({ editor, asset: this.state.image, isInline: this.state.isInline });
      } else if (this.props?.type === 'link' || this.props?.type === 'assetLink') {
        insertReferenceLink({ editor, state: this.state })
      } else {
        insertImage(editor, url, { asset_uid }, this.props.type)
      }
    }
    this.handleReset()
  }

  handleReset = () => {
    this.props.closeModal()
  }

  render() {
    const isImageSelected = !this.state.image.url
    const isInline = this.state.isInline
    const editor = this.props.editor
    const isEmbed = this.props.type === 'asset';
    const isAssetLink = this.props.type === 'assetLink'
    const isLink = this.props.type === 'link' || this.props.type === 'assetLink';
    let title = 'Insert Image';
    if (isEmbed) {
      title = 'Select Asset'
    } else if (isLink) {
      title = 'Link Asset'
    }
    const isPropsValid = checkProps({ editor });
    return (
      <div id="scrte-image-modal" data-testid={'scrte-image-modal'}>
        <ModalHeader title={title} closeModal={this.props.closeModal} />

        <ModalBody className="modalBodyCustomClass">
          {isPropsValid ? (
            <>
              <SelectImage editor={this.props.editor} isEmbed={isEmbed || isAssetLink} type={this.props.type} setImage={this.setImage} imageSelected={!isImageSelected} />
              {isEmbed && <SelectEmbedAssetType imageSelected={this.state.image} isInline={isInline} setIsInline={this.setIsInline} />}
              {isLink && <EmbedAssetLink imageSelected={!isImageSelected} state={this.state} setState={this.setState.bind(this)} />}
            </>) : (
            <InvalidPropsMsg asset="Images" />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleReset} buttonType="light" testId={"asset-modal-reset"}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} disabled={isImageSelected} icon="CheckedWhite" testId={"asset-modal-submit"}>
              {isLink ? 'Embed Asset as Link' : 'Embed Selected Asset'}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}
