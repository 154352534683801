//@ts-nocheck

import React, { useEffect, useState } from 'react'

import { getFolders } from '../../commons/request';

import { AssetBreadCrumb, AssetSearch } from '../SelectImage';
import { ListView } from './View/ListView';
import { ThumbnailView } from './View/UploadThumbnailView'

const emptyStyle = {
  color: '#4a5568',
  fontSize: '1.375rem',
  fontWeight: '800',
  letterSpacing: '-.00019em',
  lineHeight: '1.6875rem',
  marginTop: 0,
}

export const NoAssets = ({ length, children }) => {
  return length === 0 ? (
    <div style={{ textAlign: 'center', height: '292px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <p style={emptyStyle}>No Records Found</p>
    </div>
  ) : children
}

function UploadImage(props) {
  const [state, setState] = useState({
    assets: [],
    loading: false,
    selectedImage: {},
    itemStatusMap: {},
    displayType: 'List',
    startIndex: 0,
    count: 12,
    stopIndex: 8,
    query: '',
    breadcrumb: [{
      displayName: 'All Assets',
      action: () => {
        setState(currState => ({
          ...currState,
          breadcrumb: currState.breadcrumb.slice(0, 1),
          folderUid: 'cs_root'
        }))
      }
    }],
    folderUid: 'cs_root',
    uploading: false
  });

  useEffect(() => {
    fetchOnChange({ skip: 0, limit: 12, startIndex: 0, stopIndex: 12 })
  }, [state.query, state.folderUid]);

  useEffect(() => {
    let instanceItemStatusMapped = [];
    for (let i = 0; i <= 12; i++) {
      instanceItemStatusMapped[i] = props.loading ? 'loading' : 'loaded';
    }
    setState(prevState => ({ ...prevState, loading: props.loading, itemStatusMap: { ...prevState.itemStatusMap, ...instanceItemStatusMapped } }));
  }, [props.loading]);

  const fetchOnChange = async ({ sortBy = null, skip, limit, startIndex, stopIndex }) => {
    const instanceItemStatusMapped = {};

    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loading';
    }

    setState({
      ...state,
      itemStatusMap: {
        ...instanceItemStatusMapped
      },
      loading: true
    });
    let sort = {
      asc_field: '',
      dsc_field: ''
    };

    if (sortBy) {
      sort[`${sortBy['sortingDirection']}_field`] = sortBy['id'];
    }

    const response = await getFolders({ limit, 'skipSize': skip, query: state.query, folderUid: state.folderUid, ...sort });


    for (let i = startIndex; i <= stopIndex + 1; i++) {
      instanceItemStatusMapped[i] = 'loaded'
    }

    setState({
      ...state,
      assets: [
        ...response.assets
      ],
      itemStatusMap: {
        ...instanceItemStatusMapped
      },
      startIndex,
      stopIndex,
      loading: false,
      count: response.count,
    });

  }

  const loadMore = async ({ sortBy = null, skip, limit, startIndex, stopIndex }) => {
    if (stopIndex <= state.startIndex) return;
    startIndex = Math.max(state.startIndex, startIndex);
    stopIndex = Math.max(state.stopIndex, stopIndex);

    let sort = {
      asc_field: '',
      dsc_field: ''
    };

    if (sortBy) {
      sort[`${sortBy['sortingDirection']}_field`] = sortBy['id'];
    }
    const response = await getFolders({ limit, 'skipSize': skip, query: state.query, folderUid: state.folderUid, ...sort });

    const instanceItemStatusMapped = {};


    for (let i = startIndex; i <= stopIndex + 1; i++) {
      instanceItemStatusMapped[i] = 'loaded'
    }

    setState({
      ...state,
      assets: [
        ...state.assets,
        ...response.assets
      ],
      itemStatusMap: {
        ...state.itemStatusMap,
        ...instanceItemStatusMapped
      },
      startIndex,
      stopIndex
    })

  }

  const handleClick = (asset) => {
    props.setFolderUid(asset.uid);
    setState(currState => ({
      ...currState,
      breadcrumb: [...currState.breadcrumb, {
        displayName: asset.name,
        action: (len = state.breadcrumb.length, uid = asset.uid) => {
          setState(prevState => {
            return {
              ...prevState,
              breadcrumb: prevState.breadcrumb.slice(0, len + 1),
              folderUid: uid
            }
          });
        }
      }],
      folderUid: asset.uid
    }));
  }

  const loadMoreGrid = () => {
    const skip = state.stopIndex;
    const limit = 12;
    const startIndex = state.stopIndex + 1;
    const stopIndex = startIndex + limit;
    loadMore({ skip, startIndex, limit, stopIndex });
  }



  return (
    <div style={{ fontFamily: 'Inter' }}>
      <AssetBreadCrumb breadcrumbs={state.breadcrumb} />
      <AssetSearch listType={state.displayType} assetsCount={state.count} setState={setState} />
      {
        state.displayType === 'List' ?
          <ListView setState={setState} folderUid={state.folderUid} loading={state.loading} state={state} handleClick={handleClick} fetchOnChange={fetchOnChange} loadMore={loadMore} /> :
          <ThumbnailView folderUid={state.folderUid} state={state} handleClick={handleClick} loadMoreGrid={loadMoreGrid} multiple={props.multiple} size={props.size} handleUpload={props.handleUpload} setState={setState} />
      }
    </div>
  )
}



export default UploadImage;
