import { getRangeBefore } from '../utils/getRangeBefore'
import { markdownBlock } from './markdownBlock'

export const markdownInlineBlock = (
  editor,
  { type, markup, preFormat, format }
) => {
  const markupRange = getRangeBefore(editor, editor.selection, {
    matchString: markup,
    skipInvalid: true
  })


  if (markupRange) {
    markdownBlock(editor, type, markupRange, {
      preFormat: () => {
        editor.insertBreak()
        preFormat && preFormat(editor)
      },
      format
    })

    return true
  }
}
