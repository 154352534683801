//@ts-nocheck
import React from 'react'
import { useSelected, useFocused } from 'slate-react'
import { Registry } from '@react-registry'

import { ShortcutAddDivider, insertDivider } from './DividerButton'
//@ts-ignore
import styles from './style.module.css'
import { DividerIcon } from '../../utils/Icon'
import { cx, css } from '@emotion/css'
import { elementCategories } from '../elementCategories'

const Divider = (props) => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div
      {...props.attributes}
      className={cx(styles['divider'], { [styles['divider--focused']]: selected && focused })}
    >
      {props.children}
      <div contentEditable={false}>
        <div
          data-testid='divider'
          className={
            css`
              border-top: 1px solid #71809630;
              display: flex;
              clear: both;
              width: 100%;
              min-width: 100%;
            `
          }
        ></div>
      </div>
      {props.children}
    </div>
  )
}
export default Divider

Registry.register(
  {
    Component: Divider,
    handleMouseDown: insertDivider,
    category: elementCategories.MULTIMEDIA_SEE_MORE,
    iconName: <DividerIcon />,
    slashIconName: <DividerIcon />,
    title: 'Divider',
    slashTitle: 'Divider',
    shortcut: { key: 'mod+h', callback: ShortcutAddDivider },
    // subtitle: 'Visual division for site',
    isContentStackElement: true,
    inBasicToolbar: false,
    keywords: ['divider'],
    toolbar: {
      inHoveringToolbar: false
    },
    category: elementCategories.MULTIMEDIA_SEE_MORE
  },
  { id: 'hr', registry: 'v2.element' }
)
