import React from 'react'
import './FilterList.css'
import Checkbox from '../Checkbox/Checkbox'
import Search from '../Search/Search'

interface InfoProps {}

const Info = (props: InfoProps) => {
  return (
    <div className="FilterList">
      <div className="FilterList__section">
        <div className="FilterList__section-heading">Status</div>
        <div className="FilterList__section-items">
          <div className="FilterList__checkbox">
            <Checkbox text="Published" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Unpublished" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Scheduled Publish" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Scheduled Unpublish" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Deployed" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Scheduled Deploy" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Archived" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Scheduled Archive" />
          </div>
        </div>
      </div>
      <div className="FilterList__section-divider" />
      <div className="FilterList__section">
        <div className="FilterList__section-heading">User</div>
        <div className="FilterList__searchbox">
          <Search width="full" placeholder="Search Users" />
        </div>
        <div className="FilterList__section-items">
          <div className="FilterList__checkbox">
            <Checkbox text="Lionel Messi" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Cristiano Ronaldo" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Luis Suarez" />
          </div>
          <div className="FilterList__checkbox">
            <Checkbox text="Paulo Dybala" />
          </div>
        </div>
      </div>
    </div>
  )
}

Info.defaultProps = {} as Partial<InfoProps>

export default Info
