import React, { useContext } from 'react'
import { useSlate } from 'slate-react'
import { Icon } from '../../../../../../index'

import { Registry } from '@react-registry'
import { EditorProvider } from '../../../components/ContextProvider/EditorProvider'
import { leafCategories } from '../../Leaf/leafCategories'
export const EditHtmlButton = (props) => {
  const { showEditHtml, setEditHtml } = useContext(EditorProvider)
  const editor = useSlate()
  const handleMouseDown = (event) => {
    editor.selection = null
    setEditHtml(!showEditHtml)
  }
  return (
    <React.Fragment>
      {props.children({ handleMouseDown, active: showEditHtml })}
    </React.Fragment>
  )
}

Registry.register(
  {
    title: 'HTML',
    iconName: <Icon icon="Html" />,
    IngressComponent: EditHtmlButton,
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE,
    inBasicToolbar: true,
    toolbar: {
      inHoveringToolbar: false
    }
  },
  { id: 'Html', registry: 'leaf' }
)
