import React from 'react'
import './BranchDisplay.css'

const BranchDisplay: React.FunctionComponent<any> = props => {

  const { moduleType, moduleVal, titleKey } = props

  const getColumnTitle = () => {
    let columnTitle = "Name(s)";
    switch (moduleType) {
      case "content type":
        columnTitle = "Content Type(s)";
        break;
      case "entry":
        columnTitle = "Entry(ies)";
        break;
      case "field":
        columnTitle = "Field(s)";
        break;
      case "asset":
        columnTitle = "Asset(s)";
        break;
      case "folder":
        columnTitle = "Asset Folder(s)";
        break;
      case "locale":
        columnTitle = "Language(s)";
        break;
    }
    return columnTitle;
  };

  return (
    <>
      <div className={`BranchDisplay ${moduleType === 'field' ? 'BranchDisplay--field' : ''}`}>
        {/* head */}
        <div className="BranchDisplay__head">
          <div className="BranchDisplay__head__type">
            {(moduleType === 'locale') ?
              <>
                <div className="BranchDisplay__head__locale">  Language Code </div>
                <div className="BranchDisplay__head__value"> {moduleVal.code}</div>  </>
              :
              <>
                <div className="BranchDisplay__head__uid"> UID</div>
                <div className="BranchDisplay__head__value"> {moduleVal.uid} </div></>}
          </div>

          <div className="BranchDisplay__head__column">
            <div className={`BranchDisplay__head__column__cell`}>
              {getColumnTitle()}
            </div>
            <div className={`BranchDisplay__head__column__cell ${moduleType === 'field' ? 'BranchDisplay__head__column__cell--field' : ''}`}>
              Branch(es)
            </div>
            {moduleType === 'field' && <div className="BranchDisplay__head__text__cell BranchDisplay__head__column__cell--field">Datatype(s)</div>}
          </div>
        </div>

        {/* body */}
        <div className="BranchDisplay__body">
          {moduleVal.deleted ?
            <>
              <div className="left w-50">
                <span>{(moduleType === 'locale') ? moduleVal.code : moduleVal.uid}</span>
              </div>
              <div className="left w-50 branch-col">
                <span>--</span>
              </div>
            </>
            :
            <>

              {moduleVal &&
                moduleVal[titleKey] &&
                moduleVal[titleKey].length &&
                Array.isArray(moduleVal[titleKey]) &&
                moduleVal[titleKey].map((title: any, index: any) => {
                  return (
                    <div className={`BranchDisplay__body__row ${!moduleVal[titleKey][index + 1] ? 'BranchDisplay__body__row--last' : ''}`}>
                      <div className="BranchDisplay__body__row__column">
                        <span className="">{title}</span>
                      </div>

                      <div className={`BranchDisplay__body__row__column ${moduleType === 'field' ? 'BranchDisplay__body__row__column--field' : ''}`}>
                        {moduleVal._branches[index].map((branch: any, i: any) => {
                          return (
                            <span>{branch}
                              {moduleVal._branches[index][i + 1] ? ', ' : ''}
                            </span>
                          )
                        })}
                      </div>
                      {moduleType === 'field' && <div className="BranchDisplay__body__row__column     BranchDisplay__body__row__column--field">
                        {moduleVal.data_type && <span>{(moduleVal.data_type === 'group' || moduleVal.data_type === 'global_field' || moduleVal.data_type === 'blocks') ? moduleVal.data_type : moduleVal.data_type[index]}</span>}

                      </div>}

                    </div>
                  )
                })}

            </>
          }
        </div>
      </div>
    </>
  )
}

export default BranchDisplay
