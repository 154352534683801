import React, { useState, Fragment, useEffect } from 'react'
// import { DefinedRange } from 'react-date-range'
import DatePicker from 'react-datepicker'
import { getMinDate, getMaxDate } from '../DateTime/helper'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import isSunday from 'date-fns/isSunday'
import isSaturday from 'date-fns/isSaturday'
import Button from '../Button/Button'
import './DateRangePicker.css'
import Header from '../Datepicker/Header'

interface IDateRangePicker {
  fromDate?: string
  toDate?: string
  onChange?: (data?: any) => void
  onCancel?: () => void
  onSave?: (data?: any) => void
  minStartDate?: any,
  maxEndDate?: any,
  testId?: string,
  header?: React.ReactNode
}

const DateRangePicker = (props: IDateRangePicker) => {
  const { fromDate, toDate, onChange, onCancel, onSave, testId = 'cs-date-range-picker', minStartDate, maxEndDate } = props
  let currentDate = new Date()
  let sDate = new Date(fromDate).getTime() ? new Date(fromDate) : currentDate
  let eDate = new Date(toDate).getTime() ? new Date(toDate) : addDays(sDate, 7)
  const [state, setState] = useState([
    {
      startDate: sDate,
      endDate: eDate,
      key: 'selection',
      type: 'initial'
    }
  ])

  const onChangeDate = (date: any, key: string) => {
    const item = {
      ...state[0],
      [key]: date
    }
    if (onChange) {
      onChange(item)
    }
    setState([item])
  }

  const saveHandler = () => {
    onSave(state)
  }

  const { startDate, endDate } = state[0]
  let minDate: any = getMinDate(currentDate)
  let maxDate: any = getMaxDate(currentDate)

  const findAncestor = (el, cls) => {
    if (el.classList.contains(cls)) {
      return true;
    }
    if (el.parentElement) {
      if (el.classList.contains(cls)) {
        return true;
      } else {
        el = el.parentElement;
        return findAncestor(el, cls);
      }
    } else {
      return false;
    }
  }

  const checkForParents = (e, parentsArr) => {
    let found = false;
    let temp;
    if (parentsArr && Array.isArray(parentsArr)) {
      parentsArr.map((id) => {
        temp = findAncestor(e, id);
        if (temp) {
          found = true;
          return false;
        };
      });
    }
    if (found) {
      return false;
    } else {
      return true
    }
  }
  let isCursorInside = false
  let ignoreContainers = ['DateRangePickerWrapper', 'DateRangePicker', 'react-datepicker__day','Dropdown__menu--primary'];

  const handleClickOutside = (event) => {
    let ignoreParent = checkForParents(event.target, ignoreContainers);
    if (!isCursorInside && (ignoreContainers && ignoreContainers.length ? ignoreParent : true)) {
      setTimeout(() => {
        onCancel();
      })
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  return (
    <Fragment>
      <div className="DateRangePicker" data-test-id={testId}>
        {props.header}
        <div className="DateRangePicker__main">
          <div className="DateRangePicker__right">
            <div className="DateRangePicker__start-container">
              <div className="DateRangePicker__section">
                <div className="DateRangePicker__section-title">From</div>
                <div className="DateRangePicker__section-text">{format(startDate, 'yyyy/MM/dd')}</div>
              </div>
              <div className="DateRangePicker__calender Datepicker no-border">
                <DatePicker
                  inline
                  renderCustomHeader={(headerProps) => (
                    <Header
                      {...headerProps}
                      maxDate={maxDate}
                      minDate={minDate}
                      minAllowedDate={minStartDate}
                      maxAllowedDate={endDate}
                    />
                  )}
                  minDate={minStartDate}
                  maxDate={maxEndDate}
                  renderDayContents={renderDayContents}
                  selected={startDate}
                  onChange={(date) => onChangeDate(date, 'startDate')}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dayClassName={(date) => getCustomDayClassname(date)}
                />
              </div>
            </div>
            <div className="DateRangePicker__end-container">
              <div className="DateRangePicker__section right">
                <div className="DateRangePicker__section-title">To</div>
                <div className="DateRangePicker__section-text">{format(endDate, 'yyyy/MM/dd')}</div>
              </div>
              <div className="DateRangePicker__calender Datepicker no-border">
                <DatePicker
                  inline
                  renderCustomHeader={(headerProps) => (
                    <Header {...headerProps} maxDate={maxDate} minDate={minDate} minAllowedDate={startDate} maxAllowedDate={maxEndDate} />
                  )}
                  renderDayContents={renderDayContents}
                  selected={endDate}
                  onChange={(date) => onChangeDate(date, 'endDate')}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={maxEndDate}
                  dayClassName={(date) => getCustomDayClassname(date)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="DateRangePicker__footer">
          <Button aria-label="Cancel" onClick={onCancel} buttonType="light" className="DateRangePicker__footer--cancel-btn">
            Cancel
          </Button>
          <Button aria-label="Apply Filter" icon="CheckedWhite" data-test-id="apply-button" onClick={saveHandler}>
            Apply
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

const getCustomDayClassname = (date: number | Date) => {
  if (isSunday(date)) {
    return 'is-sunday'
  }
  if (isSaturday(date)) {
    return 'is-saturday'
  }
  return ''
}

const renderDayContents = (
  _: any,
  date: { getDate: () => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal }
) => {
  return (
    <div className="custom-day-date">
      <span className="custom-day-date__text">{date.getDate()}</span>
    </div>
  )
}

export default DateRangePicker

