import React from 'react'
import { Registry } from '@react-registry'
import classNames from 'classnames'

const SPAN = ({ attributes, children, attrs }) => {
    return (
        <span {...attributes} className={classNames(attrs['class-name'])} style={{ ...attrs.style }}>{children}</span>
    )
}

export default SPAN
export * from './withSpan'
Registry.register(
    {
        Component: SPAN,
        isContentStackElement: true,
        dndOptions: {
            DisableDND: true,
            DisableSelectionHalo: true
        }
    },
    { id: 'span', registry: 'element' }
)