import React from 'react'
import { Dropdown, Search, Icon, Tooltip } from '../../../../../../../../../../index'
//@ts-ignore
import styles from './style.module.css';

export function AssetSearch({ listType, setState, assetsCount }) {
  const dropdownList = [
    {
      label: <span className={styles['dropdown-item']}><Icon icon='List' className={styles['margin-right']} /> List</span>,
      action: () => {
        setState(state => ({ ...state, displayType: 'List' }))
      },
      default: listType === 'List',
    },
    {
      label: <span className={styles['dropdown-item']}><Icon icon='Thumbnail' className={styles['margin-right']} /> Thumbnail</span>,
      action: () => {
        setState(state => ({ ...state, displayType: 'Grid' }))
      },
      default: listType === 'Grid',
    },
  ]

  function handleSearchChange(searchInput) {
    setState(state => ({
      ...state,
      query: searchInput,
      loading: true
    }));
  }

  return (
    <div className={styles['scrte-search']}>
      <Search
        width="full"
        placeholder="Search Assets"
        corners="regular"
        debounceSearch={true}
        onChange={handleSearchChange}
      />
      <p
        data-testid="asset-search-count"
        className={styles['asset-count']}>
        {assetsCount} items
      </p>
      <div className="Dropdown-wrapper" data-testid={"asset-search-dropdown--" + listType}>
        <Dropdown closeAfterSelect={true} list={dropdownList} type="click" highlightActive={true} withArrow={true} dropDownPosition="left" >
          <Tooltip content={'Change view'} position="top">
            {listType === 'List' ? <Icon icon="List" className="List__icon" /> : <Icon icon="Thumbnail" className="List__icon" />}
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  )
}
