//@ts-nocheck
import React from 'react'
import Editor from 'react-simple-code-editor'
import Highlight, { defaultProps } from 'prism-react-renderer'

import './styles.css'

const colors = {
  char: '#D8DEE9',
  comment: '#999999',
  keyword: '#c5a5c5',
  primitive: '#5a9bcf',
  string: '#8dc891',
  variable: '#d7deea',
  boolean: '#ff8b50',
  punctuation: '#5FB3B3',
  tag: '#fc929e',
  function: '#79b6f2',
  className: '#FAC863',
  method: '#6699CC',
  operator: '#fc929e',
}
const theme = {
  plain: {
    color: '#D5DAE0',
    backgroundColor: '#4C5566',
    fontFamily: 'IBM Plex Mono, monospace',
    fontSize: '0.85rem'
  },
  styles: [
    {
      types: ['attr-name'],
      style: {
        color: colors.keyword,
      },
    },
    {
      types: ['attr-value'],
      style: {
        color: colors.string,
      },
    },
    {
      types: ['comment', 'block-comment', 'prolog', 'doctype', 'cdata', 'shebang'],
      style: {
        color: colors.comment,
      },
    },
    {
      types: ['property', 'number', 'function-name', 'constant', 'symbol', 'deleted'],
      style: {
        color: colors.primitive,
      },
    },
    {
      types: ['boolean'],
      style: {
        color: colors.boolean,
      },
    },
    {
      types: ['tag'],
      style: {
        color: colors.tag,
      },
    },
    {
      types: ['string'],
      style: {
        color: colors.string,
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: colors.string,
      },
    },
    {
      types: ['selector', 'char', 'builtin', 'inserted'],
      style: {
        color: colors.char,
      },
    },
    {
      types: ['function'],
      style: {
        color: colors.function,
      },
    },
    {
      types: ['operator', 'entity', 'url', 'variable'],
      style: {
        color: colors.variable,
      },
    },
    {
      types: ['keyword'],
      style: {
        color: colors.keyword,
      },
    },
    {
      types: ['at-rule', 'class-name'],
      style: {
        color: colors.className,
      },
    },
    {
      types: ['important'],
      style: {
        fontWeight: '400',
      },
    },
    {
      types: ['bold'],
      style: {
        fontWeight: 'bold',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['namespace'],
      style: {
        opacity: 0.7,
      },
    },
  ],
}

const highlight = (code) => (
  <Highlight {...defaultProps} theme={theme} code={code} language="html">
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <React.Fragment key={tokens}>
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </React.Fragment>
    )}
  </Highlight>
)
export const HtmlEditComponent = (props) => {
  const { valueHtml, setValueHtml } = props
  const handleChange = (code) => {
    setValueHtml(code)
  }
  return (
    <Editor
      value={valueHtml}
      onValueChange={handleChange}
      highlight={highlight}
      padding={10}
      data-testid="edit-html-component"
      style={{ ...theme.plain, minHeight: '100%', marginLeft: '5px', lineHeight: '1.4' }}
    />
  )
}
