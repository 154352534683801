import { Registry } from '@react-registry'

export const withSpan = (editor) => {
    const { isInline } = editor

    editor.isInline = (element) => {
        return element.type === 'span' ? true : isInline(element)
    }

    return editor
}

Registry.register(withSpan, { id: 'withSpan', registry: 'v2.plugin' })