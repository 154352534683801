import { Transforms, Editor } from 'slate'
import { getPointBefore } from '../utils/getPointBefore'

export const markdownInline = (editor, { type, between, markup, ignoreTrim, format }) => {
  const selection = editor.selection

  const startMarkup = between ? between[0] : markup
  const endMarkup = between ? between[1] : ''

  let endMarkupPointBefore = selection.anchor
  if (endMarkup) {
    endMarkupPointBefore = getPointBefore(editor, selection, {
      matchString: endMarkup
    })
    if (!endMarkupPointBefore) return false
  }

  const startMarkupPointAfter = getPointBefore(editor, endMarkupPointBefore, {
    matchString: startMarkup,
    skipInvalid: true,
    afterMatch: true
  })

  if (!startMarkupPointAfter) return false

  // found

  const markupRange = {
    anchor: startMarkupPointAfter,
    focus: endMarkupPointBefore
  }

  if (!ignoreTrim) {
    const markupText = Editor.string(editor, markupRange)
    if (markupText.trim() !== markupText) return false
  }

  // delete end markup
  if (endMarkup === startMarkup) {
    endMarkupPointBefore = getPointBefore(editor, selection, {
      matchString: endMarkup
    })
    Transforms.delete(editor, {
      at: {
        anchor: endMarkupPointBefore,
        focus: selection.anchor
      }
    })
  }

  if (format) {
    format(editor)
  } else {
    // add mark to the text between the markups
    Transforms.select(editor, markupRange)
    editor.addMark(type, true)
    Transforms.collapse(editor, { edge: 'end' })
    editor.removeMark(type, false)
  }

  // delete start markup
  const startMarkupPointBefore = getPointBefore(editor, selection, {
    matchString: startMarkup,
    skipInvalid: true
  })
  if (endMarkup === startMarkup)
    Transforms.delete(editor, {
      at: {
        anchor: startMarkupPointBefore,
        focus: startMarkupPointAfter
      }
    })

  return true
}
