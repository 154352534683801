import { Editor } from 'slate'
import { ElementWithType } from '../../../../../../../utils/types'
export const calculateColSize = (event, editor) => {
    const [table] = Editor.nodes(editor, {
        match: (n: ElementWithType) => {
            return n.type === 'table'
        },
        mode: 'lowest'
    }) as any;
    if (table) {
        return table[0].children[0].children[0].children.length
    }
    return 0
}