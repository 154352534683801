import React, { Component } from 'react'
import cn from 'classnames'
import './HelpText.css'

export type HelpTextProps = {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
  testId?: string
  disabled?: boolean
} & typeof defaultProps

const defaultProps = {
  testId: 'cs-help-text',
  disabled: false
}

export class HelpText extends Component<HelpTextProps> {
  static defaultProps = defaultProps

  render() {
    const { className, children, testId, disabled, ...otherProps } = this.props

    const classNames = cn('HelpText', className, {
      'HelpText--disabled': disabled === true
    })

    return (
      <p data-test-id={testId} {...otherProps} className={classNames}>
        {children}
      </p>
    )
  }
}

export default HelpText
