import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Help from '../../Help/Help'

const Title = (props: any) => {
  const { onChange, uniqueKey, schema, type, isNonLocalizable, hasNonLocalizablePlan } = props
  const [initialSchema, setSchema] = useState(schema)
  const { display_name, uid, field_metadata, error_messages = {} } = schema

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef.current.focus()
  }, [displayNameRef])

  const onchange = () => {
    console.log("Title log");

    onChange && onChange(schema)

    setSchema({ ...schema })

  };

  const onDisplayNameChange = (event: any) => {

    schema.display_name = event.target.value

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: "Display Name cannot be empty"
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ""
      }
      schema.error = Object.keys(schema.error_details).filter(fi => schema.error_details[fi].length).length ? true : false;
    }
    onchange()
  }
  const onPlaceholderChange = (event: any) => {
    field_metadata.placeholder = event.target.value
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onMinChange = (event: any) => {
    schema.min = parseInt(event.target.value) || null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && (schema.min <= 0 || (schema.min > schema.max))) {
      schema.error_details = {
        ...schema.error_details,
        min: "minimum value cannot be zero or larger than max value"
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        min: ""
      }
      schema.error = Object.keys(schema.error_details).filter(fi => schema.error_details[fi].length).length ? true : false
    }
    onchange()
  }
  const onMaxChange = (event: any) => {
    schema.max = parseInt(event.target.value) || null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && (schema.max <= 0 || (schema.max < schema.min))) {
      schema.error_details = {
        ...schema.error_details,
        max: "max value cannot be lower than min value"
      }
      schema.error = true
    } else {

      schema.error_details = {
        ...schema.error_details,
        max: ""
      }
      schema.error = Object.keys(schema.error_details).filter(fi => schema.error_details[fi].length).length ? true : false
    }
    onchange()
  }
  const onValChnage = (event: any) => {
    schema.format = event.target.value
    onchange()
  }
  const onValErrChange = (event: any) => {
    if (schema.error_messages) {
      schema.error_messages.format = event.target.value
    } else {
      schema.error_messages = {
        format: event.target.value,
      }
    }
    onchange()
  }
  const onOptionChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }

  if (type === 'basic') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              Display Name
            </FieldLabel>
            <TextInput
              required
              name={`${uniqueKey}.displayName`}
              value={display_name}
              onChange={onDisplayNameChange}
              inputRef={displayNameRef}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="placeholder">Placeholder Value</FieldLabel>
            <TextInput
              name={`${uniqueKey}.placeholder`}
              value={field_metadata.placeholder || ''}
              onChange={onPlaceholderChange}
              placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
            <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
            <Textarea
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')}
            // multiline={true}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              Unique ID
            </FieldLabel>
            <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
            <TextInput required name={`${uniqueKey}.uid`} value={uid} disabled error={schema.error && schema.error_details.uid ? true : false} placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')} />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
            <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
            <Textarea
              name={`${uniqueKey}.instruction`}
              value={field_metadata.instruction || ''}
              onChange={onInstructionChange}
              placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')}
            // multiline={true}
            />
          </Field>
        </div>
      </div>
    )
  } else if (type === 'advanced') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel htmlFor="minMax">Number of Characters</FieldLabel>
            <div className="FormFields__group-fields">
              <TextInput
                type="number"
                name={`${uniqueKey}.minimum`}

                value={schema.min || ''}
                min="0"
                onChange={onMinChange}
                error={schema.error && schema.error_details.min ? true : false}
                placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.min')}
              />
              <span className="FormFields__group-separator">to</span>
              <TextInput
                type="number"
                name={`${uniqueKey}.maximum`}

                value={schema.max || ''}
                min="1"
                onChange={onMaxChange}
                error={schema.error && schema.error_details.max ? true : false}
                placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.max')}
              />
            </div>
          </Field>
          <Field>
            <FieldLabel htmlFor="validationErrMsg">Validation Error Message</FieldLabel>
            <TextInput
              name={`${uniqueKey}.validationErrMsg`}
              value={
                schema.error_messages
                  ? schema.error_messages.format
                    ? schema.error_messages.format
                    : ''
                  : ''
              }
              onChange={onValErrChange}
              placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel htmlFor="validation">Validation (Regex)</FieldLabel>
            <TextInput
              name={`${uniqueKey}.validation`}
              // value={field_metadata.format || ''}
              value={schema && schema.format ? schema.format : ''}
              onChange={onValChnage}
              placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.enter_value')}
            />
          </Field>
          {hasNonLocalizablePlan ?
            <Field>
              <FieldLabel htmlFor="options">Options</FieldLabel>
              <div className="FormFields__selection-wrapper">
                {isNonLocalizable ?
                  <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                    <Checkbox
                      name={`${uniqueKey}.nonLocalization`}
                      text="Non-localizable"
                      checked={schema.non_localizable}
                      onChange={onOptionChange}
                      disabled={isNonLocalizable}
                    />
                  </Tooltip>
                  :
                  <Checkbox
                    name={`${uniqueKey}.nonLocalization`}
                    text="Non-localizable"
                    checked={schema.non_localizable}
                    onChange={onOptionChange}
                    disabled={isNonLocalizable}
                  />
                }
                <div className="nl-note">If enabled, editing this field is restricted in localized entries. The field will use the value of the master-language entry in all localized entries.</div>
              </div>
            </Field>
            : null}
        </div>
      </div>
    )
  }
}

export default React.memo(Title)