import { Editor, Node } from "slate"

export const isColumnComplete = ({editor ,tablePath, colIndex}) => {

    const [table] = Editor.node(editor, tablePath)
    // @ts-ignore
    if(colIndex >= table.attrs.cols)
    return false
    
    const rows = Array.from(Editor.nodes(editor, {
        at: tablePath,
        mode: 'highest',
        // @ts-ignore
        match: (node) => node.type === 'tr'
    }))
    // @ts-ignore
    return rows.every(([row]) => !!row.children[colIndex])
}