//@ts-nocheck

import isHotkey from 'is-hotkey'
import { Registry } from '@react-registry'
import { Editor, Range, Transforms, Node } from 'slate'

import { outdentListItem, indentListItem } from '../elements/Element/list/ListItem/utils'
import { LIST_TYPES } from '../elements/Element/list/utils'

import { insertRowAtEnd } from '../elements/Element/table/utils/insertRowAtEnd'

export const handleSoftBreak = ({ editor, event }) => {
  if (isHotkey('tab', event)) {
    event.preventDefault()

    const { selection } = editor

    if (selection) {
      const [li] = Editor.nodes(editor, {
        match: (n) => n.type === 'li',
        mode: 'lowest'
      })
      const [cell] = Editor.nodes(editor, {
        match: (n) => n.type === 'td' || n.type === 'th',
        mode: 'lowest'
      })

      if (li && Range.isCollapsed(selection) && selection.anchor.offset === 0) {
        indentListItem(editor, li)
      } 
      else if (cell) {
        const [table] = Editor.nodes(editor, {
          match: (n) => n.type === 'table',
          mode: 'lowest'
        })
        const [row] = Editor.nodes(editor, {
          match: (n) => n.type === 'tr',
          mode: 'lowest'
        })
        const [rowParent] = Editor.nodes(editor, {
          match: (n) => n.type === 'tbody' || n.type === 'thead',
          mode: 'lowest'
        })
        const nextCell = Editor.next(editor, {
          match: (n) => ['td', 'th'].includes(n.type) && !editor.isVoid(n),
          mode: 'lowest',
          at: cell[1],
        })
        if(table && rowParent && row ){
          if(nextCell){
            Transforms.select(editor, nextCell[1])
            return
          }
          insertRowAtEnd(editor, table[1])
          handleSoftBreak({editor, event})
          return
        }
      } else {
        editor.insertText('\t')
      }
    }
  }

  if (isHotkey('shift+enter', event)) {
    event.preventDefault()
    editor.insertText('\n')
  }

  if (isHotkey('shift+tab', event)) {
    event.preventDefault()

    const { selection } = editor

    if (selection) {
      const [li] = Editor.nodes(editor, {
        match: (n) => n.type === 'li',
        mode: 'lowest'
      })

      if (li && li[1].length >= 4) {
        let grandparent: any = Node.get(editor, li[1].slice(0, -4))
        if (LIST_TYPES.includes(grandparent.type)) {
          outdentListItem(editor, li)
        }
      }
    }
  }
}

Registry.register(handleSoftBreak, {
  id: 'softbreak',
  registry: 'keydown'
})
