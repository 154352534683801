import React from 'react';
import { StateProvider } from './redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const ReduxProvider = (props) => {
  return (
    <StateProvider>
      <DndProvider backend={HTML5Backend} context={window}>
        {props.children}
      </DndProvider>
    </StateProvider>
  )
}