import React, { useRef, useState } from "react";

export const useDiscussion = () => {
  const [activeDiscussion, setActiveDiscussion] = useState(null)
  const [discussions, setDiscussions] = useState({})

  const removeDiscussion = (blockUID: string) => {
    setDiscussions((prevState) => {
      const newDiscussion = { ...prevState }
      delete newDiscussion[blockUID]
      return newDiscussion
    })
  }
  return {
    activeDiscussion,
    setActiveDiscussion,
    discussions,
    setDiscussions,
    removeDiscussion
  }
}