import React, { useRef } from 'react'
import { useSlate } from 'slate-react'
import Tippy from "@tippyjs/react"
//@ts-ignore
import styles from '../style.module.css'
import TableColDrop from './tableColDrop'
import TableColDrag from './tableColDrag'
import Icon from './TableAddColumnIcon'
import { insertColAtEnd } from '../utils/insertColAtEnd'
import { Tooltip } from '../../../../../../../index'
import TableDragIcon from "./TableDragIcon"
import TableDeleteIcon from "./TableDeleteIcon"
import { deleteTable, isColumnComplete } from "../utils/index"
import { InsertHeaderIcon } from '../utils/CustomTableIcon/insertHeader'
import { insertHeader } from '../utils/insertHeader'
import { Transforms } from 'slate'
import { doesTableHeaderExist, getTableHeadLocation } from '../utils/headerToggleSwitch'
import { deleteHead } from '../utils/deleteHead'
import TableResizer from '../utils/Resizer/TableResizer'
import { cx } from '@emotion/css'
export default (props) => {
  const editor = useSlate()
  const { element, tablePath, tableRef } = props
  const tooltipRef = useRef(null)

  let { cols, colWidths, disabledCols = [] } = element?.attrs ?? {}
  const key = tablePath.join('')
  const closeTooltip = (dom) => {
    if (dom) {
      if (dom.className === 'scrte-table-column-dnd') {
        return
      }
      if (dom?._tippy) {
        dom._tippy.hide()
        return
      }
      closeTooltip(dom.parentElement)
    } else {
      return
    }
  }
  const handleHeaderToggle = (event, editor) => {
    const headerExist = doesTableHeaderExist({ editor })
    event?.preventDefault()
    if (!headerExist) {
      insertHeader(event, { editor })
    } else {
      const tableHeadLocation = getTableHeadLocation(editor)
      if (tableHeadLocation) {
        Transforms.select(editor, tableHeadLocation)
        deleteHead(event, { editor })
      }
    }
  }
  return (
    <div
      contentEditable={false}
      className="scrte-table-column-dnd"
      style={{ display: 'flex' }}
    >
      <Tooltip
        position="right"
        content={
          <div data-testid="table-toolbar" style={{ display: "flex", alignItems: "center", padding: "3px 8px" }} ref={tooltipRef}>
            <TableDragIcon path={tablePath} element={element} />
            <span
              data-testid="delete-table-icon"
              className={styles['table-column-toolbarIcon']} style={{ marginLeft: '10px' }} onMouseDown={(e) => {
                e.preventDefault()

                deleteTable(e, { editor })
                closeTooltip(tooltipRef.current)
              }}>
              <Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="delete-table-icon-text" className={styles['scrte-table-tooltip-text']} >Delete Table</span>} ><TableDeleteIcon /></Tippy>
            </span>
            <span
              data-testid="toggle-header-icon"
              className={styles['table-column-toolbarIcon']}
              style={{ marginLeft: '10px' }}
              onMouseDown={(e) => {
                e.preventDefault()
                handleHeaderToggle(e, editor)
                closeTooltip(tooltipRef.current)
              }}>
              <Tooltip position="top" className="tippy-box-dark scrte-table-tooltip-text" offset={() => [0, 20]} content={<span data-testid="toggle-header-icon-text" className={styles['scrte-table-tooltip-text']} >Toggle Header</span>}>
                <InsertHeaderIcon />
              </Tooltip>
            </span>
          </div>
        }
        variantType="light"
        trigger="click"
        className="tippy-box-light tippy-box-light--primary scrte-table-selector-tooltip"
        appendTo={document.body}>
        <span
          data-testid="table-selector"
          className={styles['scrte-table-selector']}
          onMouseDown={(e) => {
            e.preventDefault()
          }}></span>
      </Tooltip>
      <div className={styles['table-dnd-wrapper']}>
        {Array.from({ length: cols }).map((child, index) => {
          const isCurrentColumnComplete = props.isSelected && isColumnComplete({ editor, tablePath, colIndex: index })
          const disabled = !isCurrentColumnComplete || disabledCols.includes(index)
          return (
            <div key={`tableColDND${key}${index}`} className={cx(
              styles['column-dnd-wrapper'],
              { [styles['disabledCol']]: disabled },
            )}>
              <TableColDrop
                key={`tableColDND${key}${index}`}
                type={`tableColDND${key}`}
                width={colWidths[index]}
                index={index}
                table={element}
                disabled={disabled}
                tablePath={tablePath}>
                <TableColDrag
                  index={index}
                  tableRef={tableRef}
                  type={`tableColDND${key}`}
                  width={colWidths[index]}></TableColDrag>


              </TableColDrop>
              <TableResizer columnNumber={index} table={element} tablePath={tablePath} wrapperColumn={styles['column-dnd-wrapper']} editor={editor} element={props.element} tableRef={tableRef}></TableResizer>
            </div>
          )
        }
        )}
      </div>
      <Tooltip showArrow={false} position={'top'} content={<span data-testid="add-column-text">Add Column</span>}>
        <div
          contentEditable={false}
          data-testid='table-column-add'
          className={styles['table-column-add']}
          onMouseDown={(e) => {
            e.preventDefault()
            insertColAtEnd(editor, tablePath)
          }}>
          <Icon icon="PlusSign" size="extraSmall" />
        </div>
      </Tooltip>
    </div>
  )
}
