import React, { useState, useMemo, useCallback } from 'react'
import cn from 'classnames'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'

import Icon from '../Icon2/Icon'
import Line from '../Line/Line'

import './Accordion.css'

export type accordionProps = {
  accordionLock?: boolean
  accordionData?: string
  addLeftComponent?: React.ReactNode
  addRightComponent?: React.ReactNode
  children?: React.ReactNode
  className?: string
  noChevron?: boolean
  preRender?: boolean
  renderExpanded?: boolean
  setTitleOnHide?: any | React.ReactNode
  title: string | React.ReactNode
  accordionDataCount?: number
  hasBackgroundColor?: boolean
  /**
   * Actions List of {component: ReactNode, onClick: () => void}
   */
  actions?: AccordionActionsList
  isAccordionOpen?: boolean | undefined
  onTitleClick?: () => void
  errorMessage?: string
  dashedLineVisibility?: 'hover' | 'always'
  accordionTitlePropagation?: boolean
  testId?: string
  version?: string
  isAccordionParent?: boolean
  stickyIcon?: React.ElementType
  titleIcon?: React.ReactNode
  accordionDescription?: React.ReactNode
}

type accordionTitleProps = {
  accordionLock?: boolean
  addLeftComponent?: React.ReactNode
  addRightComponent?: React.ReactNode
  accordionDataCount: number
  noChevron?: boolean
  setTitleOnHide: any | React.ReactNode | undefined
  title: string | React.ReactNode
  toggle: Function
  toggleStat?: boolean
  actions?: AccordionActionsList
  onClick?: () => void
  error?: string
  hasBackgroundColor?: boolean
  dashedLineVisibility?: 'hover' | 'always'
  accordionTitlePropagation?: boolean
  version?: string
  isAccordionParent?: boolean
  titleIcon?: React.ReactNode
  stickyIcon?: React.ReactNode
  accordionDescription?: React.ReactNode
}

type accordionDataProps = {
  accordionData: string | React.ReactNode
  version?: string
  isAccordionParent?: boolean
}
type AccordionAction = {
  component: React.ReactNode
  onClick?: () => void
  actionClassName?: string
  version?: string
}

export type AccordionActionsList = AccordionAction[]

const getAccordionTitle = (toggleStat, setTitleOnHide, title) => {
  if (!toggleStat) {
    return setTitleOnHide ? setTitleOnHide : title
  }
  return title
}

const AccordionTitle = (props: accordionTitleProps) => {
  // const noDefaultIcon = props.noDefaultIcon !== undefined ? props.noDefaultIcon : false;

  const dashedLineClassname = cn('Accordion__heading__line', {
    visible: props.dashedLineVisibility === 'always'
  })

  const { accordionTitlePropagation } = props || { accordionTitlePropagation: true }

  const accordionHeadingClassname = useCallback(() => {
    let className = 'Accordion__heading'
    if (props.version === 'v2') {
      if (props.isAccordionParent) {
        className = className + ' Accordion-v2__heading-parent'
      }
      if (!props.toggleStat) {
        className = className + ' Accordion-v2__heading Accordion__heading__collapsed'
      } else {
        className = className + ' Accordion-v2__heading'
      }
    }
    if (props.accordionLock) {
      className = className + ' Accordion__heading-disabled'
    }
    return className
  }, [props.version, props.isAccordionParent, props.toggleStat, props.accordionLock])

  const decriptionClassName = useCallback(() => {
    let className = ''
    if (props.version === 'v2') {
      className = 'Accordion__description'
      if (!props.isAccordionParent) {
        className = className + ' Accordion__description-child'
      } else {
        if (props.toggleStat) {
          className = className + ' Accordion__description-parent-open'
        } else {
          className = className + ' Accordion__description-parent-close'
        }
      }
    }
    return className
  }, [props.version, props.isAccordionParent, props.toggleStat, props.accordionLock])

  return useMemo(() => {
    const handleToggle = () => {
      if (!props.accordionLock) {
        if (props.onClick) props.onClick()
        props.toggle()
      }
    }

    return (
      <>
        <div className={accordionHeadingClassname()} onClick={handleToggle}>
          {props.version === 'v2' && props.titleIcon && <div className="mr-10">{props.titleIcon}</div>}
          <div className="flex">
            {props.addLeftComponent ? props.addLeftComponent : null}
            <div
              title={
                typeof props.title == 'string'
                  ? getAccordionTitle(props.toggleStat, props.setTitleOnHide, props.title)
                  : null
              }
              className={`Accordion__heading__title ${
                props.version === 'v2'
                  ? props.isAccordionParent
                    ? 'Accordion__heading__v2__title-parent'
                    : 'Accordion__heading__v2__title'
                  : ''
              } ${props.addLeftComponent ? 'ml-8' : ''} ${
                typeof props.title == 'string' ? 'Accordion__heading__title__ellipsis' : ''
              }`}
              onClick={(e) => {
                if (!accordionTitlePropagation) {
                  e.stopPropagation()
                }
              }}>
              {getAccordionTitle(props.toggleStat, props.setTitleOnHide, props.title)}
            </div>
          </div>
          {props.toggleStat === false && props.accordionDataCount ? (
            <div className="Accordion__heading__field-count">{props.accordionDataCount}</div>
          ) : (
            ''
          )}
          {props.addRightComponent ? (
            props.addRightComponent
          ) : (
            <>
              {!props.noChevron &&
                <div onKeyDown={(e: any)=>{
                  if(e.key === "Enter" || e.key === " " || (props.toggleStat === false && e.key === "Tab")){
                    e.preventDefault();
                    e.stopPropagation();
                    handleToggle();
                  }
                }} tabIndex={0} className={props.accordionLock ? 'Accordion__heading__toggle-disabled' : 'Accordion__heading__toggle'}>
                  <Icon
                    className={props.toggleStat === true ? 'rotate-180' : ''}
                    icon={props.accordionLock ? 'DownArrowDisabled' : 'DownArrowEnabled'}
                  />
                </div>
              }
              <div className="flex-v-center flex-grow-1">
                {props.error && <div className="Accordion__heading__errorMsg">{props.error}</div>}
                {!props.isAccordionParent && (
                  <span className={dashedLineClassname}>
                    <Line type="dashed" />
                  </span>
                )}
              </div>
              <div className="flex mr-20">
                {props.actions && <AccordionActions version={props.version} actions={props.actions} />}
                {props.version === 'v2' && props.stickyIcon && props.stickyIcon}
              </div>
            </>
          )}
        </div>
        {props.version === 'v2' && props.accordionDescription && (
          <div className={decriptionClassName()}>{props.accordionDescription}</div>
        )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.toggleStat,
    props.accordionDataCount,
    props.title,
    props.error,
    props.addLeftComponent,
    props.addRightComponent,
    props.version,
    props.isAccordionParent
  ])
}

export const AccordionData = (props: accordionDataProps) => {
  return (
    <div className={`Accordion__data ${props.version === 'v2' && props.isAccordionParent ? 'Accordion-v2__data' : ''}`}>
      {props.accordionData}
    </div>
  )
}

const AccordionActions = ({ actions, version }: { actions: AccordionAction[]; version?: string }) => {
  return useMemo(() => {
    return (
      <div className={`Accordion__actions ${version === 'v2' ? 'Accordion-v2__actions' : ''}`}>
        {actions?.map((action: AccordionAction, index) => {
          const actionClassname = cn('actions_list_item', action.actionClassName)
          return (
            <div
              className={actionClassname}
              onClick={(event) => {
                event.stopPropagation()
                action.onClick()
              }}
              key={index}>
              {action.component}
            </div>
          )
        })}
      </div>
    )
  }, [actions?.length, version])
}

const Accordion = (props: accordionProps) => {
  const [toggleShow, setToggleShow] = useState(props.renderExpanded)

  const {
    title,
    accordionData,
    children,
    noChevron,
    accordionDataCount,
    hasBackgroundColor,
    dashedLineVisibility,
    accordionTitlePropagation,
    testId,
    version,
    isAccordionParent,
    stickyIcon,
    titleIcon,
    accordionDescription
  }: accordionProps = props

  const [preRender, setPreRender] = useState(props.preRender)

  const accordionDataValue = accordionData !== undefined ? accordionData : children

  const accordionDataCountValue = accordionDataCount

  const toggleView = () => {
    setToggleShow(!toggleShow)
    if (preRender === false) {
      setPreRender(true)
    }
  }

  let toggleState = toggleShow

  const classNames = cn('Accordion', props.className, {
    'Accordion--backgroundColor': hasBackgroundColor && !props.accordionLock,
    'Accordion-v2': props.version === 'v2',
    'Accordion-v2-parent': isAccordionParent && props.version === 'v2',
    'pb-20':
      isAccordionParent && props.version === 'v2' && props.isAccordionOpen !== undefined
        ? props.isAccordionOpen
        : toggleState
  })

  if (props.isAccordionOpen !== undefined) {
    toggleState = props.isAccordionOpen
  }

  return (
    <div className={classNames} data-test-id={testId}>
      <AccordionTitle
        title={title}
        setTitleOnHide={props.setTitleOnHide}
        toggleStat={toggleState}
        toggle={toggleView}
        version={version}
        isAccordionParent={isAccordionParent}
        hasBackgroundColor={hasBackgroundColor}
        noChevron={noChevron}
        addLeftComponent={props.addLeftComponent}
        addRightComponent={props.addRightComponent}
        accordionDataCount={accordionDataCountValue}
        accordionLock={props.accordionLock}
        actions={props.actions}
        onClick={props.onTitleClick}
        error={props.errorMessage}
        dashedLineVisibility={dashedLineVisibility}
        accordionTitlePropagation={accordionTitlePropagation}
        stickyIcon={stickyIcon}
        titleIcon={titleIcon}
        accordionDescription={accordionDescription}
      />
      {preRender === false ? (
        toggleState === true ? (
          <div className="Accordion__open">
            <AccordionData version={version} isAccordionParent={isAccordionParent} accordionData={accordionDataValue} />
          </div>
        ) : null
      ) : (
        <div className={`${toggleState ? 'Accordion__open' : 'Accordion__collapsed'}`}>
          <AccordionData version={version} isAccordionParent={isAccordionParent} accordionData={accordionDataValue} />
        </div>
      )}
    </div>
  )
}

Accordion.defaultProps = {
  renderExpanded: false,
  preRender: false,
  noChevron: false,
  accordionLock: false,
  hasBackgroundColor: false,
  dashedLineVisibility: 'hover',
  accordionTitlePropagation: true,
  testId: 'cs-accordion',
  version: 'v1',
  isAccordionParent: false
} as Partial<accordionProps>

export default withDeprecatedProp(Accordion, {
  accordiondata: 'accordionData',
  accordiondataCount: 'accordionDataCount',
  accordionDataLock: 'accordionLock',
  accordionState: 'isAccordionOpen',
  backGroundColor: 'hasBackgroundColor',
  errorMsg: 'errorMessage',
  onClick: 'onTitleClick',
  noDefaultIcon: 'noChevron',
  actions: [
    {
      className: 'actionClassName'
    }
  ]
})
