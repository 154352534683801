import { Registry } from '@react-registry'


export const markDeserializer = () => {
    return {
        mark: true
    }
}

Registry.register(markDeserializer, { id: 'inline-mark', registry: 'inline-deserializer' })

