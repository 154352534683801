//@ts-nocheck

import React, { useState, useEffect } from 'react'
import { InfiniteScrollTable, Icon } from '../../../../../index'
import CreateFolder from '../CreateFolder';
import { getUsers } from '../../../commons/request';
import './index.css';
import styles from './index.module.css'
import { formatTime } from '../../../commons/util';

const iconFromType = (contentType) => {
  if (contentType.startsWith('image/')) return null
  else if (contentType.startsWith('application/')) return 'Group'
  else return 'File'
}
const FolderCreate = ({ folderUid, editor, setState }) => {
  return (
    <div role="row" className="Table__body__row " data-testid="folder-create-wrapper" style={{ height: '60px', width: '100%' }}>
      <div role="cell" className="Table__body__column ">
        <div className="flex-v-center">
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <CreateFolder editor={editor} folderUid={folderUid} setUploading={setState} />
          </div>
        </div>
      </div>
      <div role="cell" className="Table__body__column "></div>
      <div role="cell" className="Table__body__column "></div>
    </div>
  )
}

const GetIcon = ({ data }) => {
  return (
    <React.Fragment>
      <span className={styles['list-icon']} style={{ background: `${data.url ? `url(${data.url})` : 'inherit'}` }}>
        {iconFromType(data.content_type) && <Icon size='medium' icon={iconFromType(data.content_type)} />}
      </span>
      {data.name ? data.name : data.filename}
    </React.Fragment>
  )
}

export const NoAssets = ({ length, children, folderUid, setState }) => {
  return length === 0 ? (
    <>
      <FolderCreate folderUid={folderUid} setState={setState} />
    </>
  ) : children
}

export const ListView = ({ state, fetchOnChange, loadMore, handleClick, loading, editor, folderUid, setState }) => {
  const [users, setUser] = useState({});

  useEffect(() => {
    getUsers()
      .then(res => setUser(res))
      .catch(console.error)
  }, []);

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (data) => {
        return (
          <div className={'flex-v-center'}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <GetIcon editor={editor} data={data} folderUid={folderUid} setState={setState} />
            </div>
          </div>
        )
      },
      default: false,
      addToColumnSelector: true,
    },
    {
      Header: 'Unique Id',
      accessor: (data) => data.uid,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
    {
      Header: 'Last Modified By',
      accessor: (data) => <div className={'last-updated-by'}><span>{users?.[data?.updated_by] || 'Anonymous'}</span> <span className='last-updated-by-time'>{formatTime(data?.updated_at)}</span></div>,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
  ]

  return (
    <NoAssets length={state.count} folderUid={folderUid} setState={setState}>
      <div style={{ height: '290px' }} data-testid="upload-list-view">
        <InfiniteScrollTable
          tableHeight={290}
          data={state.assets}
          columns={columns}
          uniqueKey={'uid'}
          fetchTableData={fetchOnChange}
          loadMoreItems={loadMore}
          loading={loading}
          staticRowCount={1}
          customRowAdd={true}
          customRowComponent={<FolderCreate editor={editor} folderUid={folderUid} setState={setState} />}
          totalCounts={state.count}
          onRowClick={handleClick}
          singleSelectedRowId={state.selectedImage.uid}
          itemStatusMap={state.itemStatusMap}
          columnSelector={false}
          minBatchSizeToFetch={30}
          itemSize={60}
          name={{ singular: 'Asset', plural: 'Assets' }}
        />
      </div>
    </NoAssets>
  )
}
