//@ts-nocheck
import { Transforms } from 'slate'
import { ReactEditor } from 'slate-react';

export const handleDelete = (editor, element) => {
    if (element['type'] === 'code' && editor.requestProps?.['userAnalytics']) {
        const userAnalytics = editor.requestProps.userAnalytics;
        const metadata = {
            content_type_uid: editor.requestProps['content_type_uid'],
            stack_api_key: editor.requestProps['apiKey'],
            json_rte_field_uid: editor.requestProps['field_uid'],
            json_rte_field_type: editor.requestProps['field_type'],
            code_block_language: element.attrs?.['language'],
            mixpanel: true
        };
        userAnalytics.trackEvent("Delete a code block within JSON RTE", metadata);
    }

    if (element['type'] === 'reference' && editor.requestProps?.['userAnalytics']) {
        const userAnalytics = editor.requestProps['userAnalytics'];
        const metaData = {
            stack_api_key: editor.requestProps['apiKey'],
            content_type_uid: element['attrs']['content-type-uid'],
            content_type_name: editor.requestProps['content_type_name'],
            json_rte_field_uid: editor.requestProps['field_uid'],
            json_rte_field_type: editor.requestProps['field_type'],
            mixpanel: true
        }
        switch (element['attrs']['type']) {
            case 'entry': {
                metaData['embedded_entry_type'] = element['attrs']['display-type'];
                metaData['embedded_entry_uid'] = element['attrs']['entry-uid'];
                metaData['embedded_entry_url'] = editor.requestProps?.uidToEntry[metaData['embedded_entry_uid']]?.['url'];
                userAnalytics.trackEvent(`Remove Embedded Entry within JSON RTE`, metaData);
                break;
            }
            case 'asset': {
                metaData['embedded_entry_type'] = element['attrs']['display-type'];
                metaData['embedded_asset_uid'] = element['attrs']['asset-uid'];
                delete metaData['content_type_name'];
                delete metaData['content_type_uid'];
                userAnalytics.trackEvent(`Remove Embedded Asset`, metaData);
                break;
            }
        }
    }

    let path = ReactEditor.findPath(editor, element);
    let pathLength = path.length;
    let siblingPath = [...path.slice(0, pathLength - 1), path[pathLength - 1] + 1];
    Transforms.insertNodes(editor, { type: 'p', children: [{ text: '' }] }, { at: siblingPath })
    Transforms.delete(editor, {
        at: path
    })
}
