import React from 'react'
import { useSlate } from 'slate-react'
import { Editor, Path, Transforms } from 'slate'
import { cx } from "@emotion/css"

import { Icon } from '../../../../Icon2'
import styles from './style.module.css'
import { IDiscussionProps, IElement } from '../../utils/types'
import { getCurrentDiscussion } from './utils'

interface IDiscussionIcon {
  element: IElement,
  discussion: IDiscussionProps
  elementPath: Path
  editor: Editor
}
const DiscussionIcon: React.FC<IDiscussionIcon> = ({ element, discussion, elementPath, editor }) => {
  const { discussions, setActiveDiscussion } = discussion
  const currentDiscussion = getCurrentDiscussion(discussions, element)
  const discussionUid = currentDiscussion?.uid || `new-${element.uid}`
  const iconName = currentDiscussion ? 'ReadComment' : 'AddComment'

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    Transforms.select(editor, elementPath)
    setActiveDiscussion(discussionUid)
  }
  return (
    <div
      className={cx(styles['scrte-discussion-icon'], 'scrte-discussion-icon', currentDiscussion && styles['scrte-discussion-icon--view'])}
      contentEditable={false}
      data-testid='scrte-discussion-icon'
      onClick={handleClick}
      data-discussion-block-uid={element.uid}
      data-discussion-uid={discussionUid}
    ><Icon icon={iconName} height={"16px"} /></div>
  )
}

export default DiscussionIcon