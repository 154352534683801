import React, { useRef, useEffect, useState } from 'react'
import { useSlate, ReactEditor } from 'slate-react'
import { Transforms, Node } from 'slate'
//@ts-ignore
import styles from '../../style.module.css'
import { ElementWithType } from '../../../../../../../../utils/types'
const minWidth = 20
export default (props) => {
  const { editor, element } = props
  const [resizerHeight, setResizerHeight] = useState(0)
  let pageX,
    curCol,
    curColWidth,
    nxtCol,
    nxtColWidth,
    colWidths
        
  const {table, columnNumber, tablePath, tableRef} = props

  const divRef = useRef<HTMLDivElement>(null)
  function resizer(e) {
    e.preventDefault()
    if (curCol) {
      const diff = e.pageX - pageX
      if (minWidth >= curColWidth + diff || (nxtColWidth && minWidth >= nxtColWidth - diff)) {
        return
      }
      colWidths = JSON.parse(JSON.stringify(table.attrs.colWidths))
      if (nxtCol) {
        colWidths[columnNumber + 1] = nxtColWidth - diff
      }
      colWidths[columnNumber] = curColWidth + diff
      const templateColumns = colWidths.map(w => `${w}px`).join(' ')
      tableRef.current.style.setProperty('--template-column', templateColumns)
    }
  }
  function resizerHandler(e) {
    e.preventDefault()
    curCol = e.target.parentElement
    pageX = e.pageX
    curColWidth = curCol.offsetWidth
    nxtCol = e.target.parentElement.nextElementSibling

    if (!nxtCol?.classList.contains(styles['column-dnd-wrapper'])) {
      nxtCol = undefined
    }
    if (nxtCol) {
      nxtColWidth = nxtCol.offsetWidth
    }
    window.addEventListener('mousemove', resizer, true)
    window.addEventListener('mouseup', stopResizer, true)
  }
  function stopResizer(e) {
    e.preventDefault()
    curCol = undefined
    pageX = undefined
    curColWidth = undefined
    nxtColWidth = undefined
    nxtCol = undefined
    if (colWidths) {
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table.attrs,
            colWidths,
          },
        } as ElementWithType,
        { at: tablePath }
      )
    }

    colWidths = undefined
    window.removeEventListener('mousemove', resizer, true)
  }
  useEffect(() => {
    if(!divRef.current) return
    divRef.current.addEventListener('mousedown', resizerHandler, true)

    const tableChilds = Array.from(tableRef.current?.children) as HTMLElement[]
    tableChilds.splice(-1, 1)
    setResizerHeight(tableChilds.reduce((height, elem) => height + elem.offsetHeight, 0) as number)

    return () => {
      window.removeEventListener('mousemove', resizer, true)
      window.removeEventListener('mouseup', stopResizer, true)
      divRef.current?.removeEventListener('mousedown', resizerHandler, true)
    }
  }, [element])

  return (
    <React.Fragment>
      <div data-testid="table-resizer" style={{height: `${resizerHeight}px`}} contentEditable={false} ref={divRef} className={styles['table-resizer']} ></div>
    </React.Fragment>
  )
}
