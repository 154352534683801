//@ts-nocheck

import isHotkey from 'is-hotkey'
import { Registry } from '@react-registry'
import { Editor, Range, Transforms, Node } from 'slate'

import { outdentListItem, indentListItem } from '../elements/Element/list/ListItem/utils'
import { LIST_TYPES } from '../elements/Element/list/utils'

import { insertRowAtEnd } from '../elements/Element/table/utils/insertRowAtEnd'

export const handleSoftBreak = ({ editor, event }) => {
  if (isHotkey('tab', event)) {
    if (event.defaultPrevented) {
      return
    }
    event.preventDefault()
    const { selection } = editor
    if (selection) {
      const [li] = Editor.nodes(editor, {
        match: (n) => n.type === 'li',
      })
      const [cell] = Editor.nodes(editor, {
        match: (n) => n.type === 'td' || n.type === 'th',
        mode: 'lowest',
      })

      if (li && Range.isCollapsed(selection) && selection.anchor.offset === 0) {
        indentListItem(editor, li)
      } else if (cell) {

        const [table] = Editor.nodes(editor, {
          match: (n) => n.type === 'table',
          mode: 'lowest',
        })
        const [row] = Editor.nodes(editor, {
          match: (n) => n.type === 'tr',
          mode: 'lowest',
        })
        const [rowParent] = Editor.nodes(editor, {
          match: (n) => n.type === 'tbody' || n.type === 'thead',
          mode: 'lowest',
        })

        if (table && row && rowParent) {
          const cellIndex = cell[1][cell[1].length - 1]
          const rowIndex = row[1][row[1].length - 1]
          const rowParentIndex = rowParent[1][rowParent[1].length - 1]
          if (cellIndex < row[0].children.length - 1) {
            let path = cell[1].slice()
            path[path.length - 1] += 1
            Transforms.select(editor, path)
            return
          } else {
            if (rowIndex < rowParent[0].children.length - 1) {
              let path = [...rowParent[1], rowIndex + 1, 0]
              Transforms.select(editor, path)
              return
            } else {
              if (rowParentIndex < table[0].children.length - 1) {
                let path = [...table[1], rowParentIndex + 1, 0, 0]
                Transforms.select(editor, path)
                return
              } else {
                insertRowAtEnd(editor, table[1])
                let path = [...rowParent[1], rowIndex + 1, 0]
                Transforms.select(editor, path)
                return
              }
            }
          }
        }
      } else {
        editor.insertText('\t')
      }
    }
  }

  if (isHotkey('shift+enter', event)) {
    event.preventDefault()
    editor.insertNode({
      text: '\n',
      break: true,
    })
  }

  if (isHotkey('shift+tab', event)) {
    event.preventDefault()

    const { selection } = editor

    if (selection) {
      const [li] = Editor.nodes(editor, {
        match: (n) => n.type === 'li',
      })

      if (li && li[1].length >= 4) {
        let grandparent: any = Node.get(editor, li[1].slice(0, li[1].length - 2))
        if (LIST_TYPES.includes(grandparent.type)) {
          outdentListItem(editor, li)
        }
      }
    }
  }
}

Registry.register(handleSoftBreak, {
  id: 'softbreak',
  registry: 'v2.keydown',
})
