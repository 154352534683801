import React from 'react'
import { Registry } from '@react-registry'
import { UnderlineIcon } from '../../utils/Icon'
import { leafCategories } from '../leafCategories'

export const Underline = ({ children }) => <u style={{ textDecoration: 'underline' }}>{children}</u>

export * from './deserializer'

Registry.register(
  {
    Component: Underline,
    title: 'Underline',
    iconName: <UnderlineIcon />,
    shortcut: { key: 'mod+u' },
    isContentStackElement: true,
    inBasicToolbar: true,
    category: leafCategories.TEXT_STYLE,
  },
  { id: 'underline', registry: 'v2.leaf' }
)
