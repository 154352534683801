//@ts-nocheck
import React, { memo, createContext, useContext } from 'react';
import { Registry } from '@react-registry';
import { TriggerButton } from './Button/TriggerButton';
import { Buttons } from './toolbarComponents';
import styles from './style.module.css';


const toolbarContext = createContext({});
export const useToolbarProps = () => useContext(toolbarContext);

const types = ['leaf', 'element', 'editorButtons'];


const ToolbarTest = memo((props) => {
    const {
        toolbarType = 'mainToolbar',
        contentStack = false,
        toolbarMode = 'advance',
        customToolbarComponents = {},
        fieldName,
        editorRef
    } = props;

    return (
        <div className='toolbar-parent'>
            {toolbarType !== 'hoveringToolbar' && <div className={'rte-field-name'}>
                {fieldName}
            </div>}
            <div className={styles['toolbar-child-inline']}>
                <toolbarContext.Provider value={{ toolbarType, contentStack, toolbarMode, customToolbarComponents, editorRef }}>
                    {types.map((type) => <Buttons type={type} key={type} />)}
                </toolbarContext.Provider>
            </div>
            {toolbarType !== 'hoveringToolbar' &&
                <div className={styles['toolbar-child-inline']}>
                    <TriggerButton
                        key={'fullscreen'}
                        value={Registry.get({
                            id: 'fullscreen',
                            registry: 'element',
                        })}
                        format={'fullscreen'}
                        toolbarType={toolbarType}
                        title={'Fullscreen'}
                    />
                </div>
            }
        </div>

    )
}, () => true);

export default ToolbarTest;