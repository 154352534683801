import React from 'react';
import { handlePublishDetailClick } from './PublishDetails';
import styles from '../style.module.css';
import Icon from '../../../../../../../../Icon2';

export const PublishStatusComponent = ({ data = {}, environments, users, locales }) => {
  let publish_details = data['publish_details'];
  if (!publish_details) return <></>;
  const publish_details_len = data['publish_details'].length;
  const moreThanTwo = publish_details_len > 2;
  if (moreThanTwo) {
    publish_details = publish_details.slice(0, 2);
  }
  return (
    <ul>
      {publish_details.map((pd, index) =>
        <li key={pd['environment']} className={'entry-publish-details'} data-testid={'scrte-published-detail'}>
          <Icon icon='EnvironmentColor' fill={environments?.[pd['environment']]?.['color']} /> {environments?.[pd['environment']]?.['name']}
          {moreThanTwo && index === 1 && <span data-testid={"scrte-load-more-items"} className={styles['more_items']} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handlePublishDetailClick({ data: data, environments, users, locales }) }}>+{publish_details_len - 2} more</span>}
        </li>
      )}
    </ul>
  )
}