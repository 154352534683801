// export * from './deserializer'
//@ts-nocheck

import React, { useState } from 'react'
import { Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import {
  cbModal,
  ModalBody,
  ModalHeader,
  Button,
  TextInput,
  Icon,
  Field
} from '../../../../../../index'
import { Registry } from '@react-registry'

import { setClass, setId, getClass, getId } from './utils'
import { setOldSelection } from '../../../utils/setOldSelection';
import { leafCategories } from '../leafCategories'

function openPropertyModalInline(event, editor) {
  event?.preventDefault()
  if (editor.selection) {
    const initialClassName = getClass(editor, editor.selection)
    const initialId = getId(editor, editor.selection)
    editor.setFullScreen();
    cbModal({
      modalProps: {
        shouldReturnFocusAfterClose: false,
        onClose: () => setOldSelection(editor),
      },
      component: (props) => (
        <PropertyOverlayModalInline
          {...props}
          editor={editor}
          selection={editor.selection}
          initialClassName={initialClassName}
          initialId={initialId}
        />
      )
    })
  }
}

export function PropertyOverlayWrapperInline(props) {
  const editor = useSlateStatic()
  const handleMouseDown = (event) => {
    openPropertyModalInline(event, editor)
  }

  return <React.Fragment>{props.children({ handleMouseDown })}</React.Fragment>
}

function PropertyOverlayModalInline(props) {
  const { selection, initialClassName, initialId, editor } = props

  const [classVal, setClassVal] = useState(initialClassName)
  const [idVal, setIdVal] = useState(initialId)

  const [disableClassButton, setDisableClassButton] = useState(false)
  const [disableIdButton, setDisableIdButton] = useState(false)

  const handleClassOnChange = (e) => {
    setClassVal(e.target.value)
    setDisableClassButton(false)

  }
  const handleClassPlace = () => {
    Transforms.select(editor, selection)
    setClass(editor, classVal, selection)
    setDisableClassButton(true)
  }
  const handleClassClear = () => {
    Transforms.select(editor, selection)
    setClassVal('')
    setClass(editor, '', selection)
    setDisableClassButton(false)

  }

  // Id functions
  const handleIdOnChange = (e) => {
    setIdVal(e.target.value)
    setDisableIdButton(false)

  }
  const handleIdPlace = () => {
    Transforms.select(editor, selection)
    setId(editor, idVal, selection)
    setDisableIdButton(true)

  }
  const handleIdClear = () => {
    Transforms.select(editor, selection)
    setIdVal('')
    setId(editor, '', selection)
    setDisableIdButton(false)
  }

  return (
    <div>
      <ModalHeader title="Place Class / Id" closeModal={props.closeModal} />

      <ModalBody className="modalBodyCustomClass">
        {/* <p>Class</p> */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Field labelText="Class" style={{ width: "65%" }}>
            <TextInput
              autoFocus
              type="text"
              name="classProperty"
              value={classVal}
              onChange={handleClassOnChange}
              required
              placeholder="Enter Class property"
              data-testid="property_class_input_inline"
            />
          </Field>
          {/* <ButtonGroup> */}
          <div>
            <Button buttonType="light" disabled={!classVal} onClick={handleClassClear}>
              Clear
            </Button>
          </div>
          <div>
            <Button id="applyClassBtn" disabled={disableClassButton} icon={disableClassButton ? "CheckedWhite" : ""} onClick={handleClassPlace}>{disableClassButton ? "Applied" : "Apply"}</Button>
          </div>

          {/* </ButtonGroup> */}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Field labelText="ID" style={{ width: "65%" }}>
            <TextInput
              type="text"
              name="IdProperty"
              value={idVal}
              onChange={handleIdOnChange}
              required
              placeholder="Enter Id property"
              data-testid="property_id_input_inline"
            />
          </Field>
          <div>
            <Button buttonType="light" disabled={!idVal} onClick={handleIdClear}>
              Clear
            </Button>
          </div>
          <div>
            <Button id="applyIdBtn" disabled={disableIdButton} icon={disableIdButton ? "CheckedWhite" : ""} onClick={handleIdPlace}>{disableIdButton ? "Applied" : "Apply"}</Button>
          </div>
        </div>
      </ModalBody>
    </div>
  )
}

export function shortcutOpenPropertyModalInline(event, editor, format, registryObj) {
  openPropertyModalInline(event, editor)
}

Registry.register(
  {
    title: 'Properties',
    iconName: <Icon icon="Properties" />,
    IngressComponent: PropertyOverlayWrapperInline,
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE_SEE_MORE,
    inBasicToolbar: true,
  },
  { id: 'property', registry: 'v2.leaf' }
)
