import { Editor, Node } from 'slate'
import { Registry } from '@react-registry'
import { ElementWithType } from '../../../../../../utils/types'

export const codePreInsert = (editor, fragment) => {
  const [match] = Editor.nodes(editor, {
    match: (n: ElementWithType) => n.type === 'code'
  })
  if (!!match) {
    return [
      {
        type: 'p',
        children: [{ text: fragment.map((node) => Node.string(node)).join('\n').trimStart().trimEnd() }]
      }
    ]
  }
  return fragment
}

Registry.register(codePreInsert, { id: 'pre-insert', registry: 'v2.withPreInsert' })
