import React, { Fragment, useCallback, useState } from 'react'
import './SliderBar.css'

interface SliderProps {
  defaultValue?: number
  start?: number
  end?: number
  step?: number
  fill?: String
  background?: String
  /**
   * An optional function to be called when form is saved
   **/
  onChange?: (arg?: any) => void
}

const SliderBar = (props: SliderProps) => {
  const { start = 0, end = 100, defaultValue = 0, step = 1, fill, background, onChange } = props
  const [value, setValue] = useState(defaultValue)

  const onSlide = event => {
    const newValue = event.target.value
    setValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  const settings = {
    fill: fill || '#A9B6CB',
    background: background || '#EDF1F7',
  }

  const percentage = (100 * (value - start)) / (end - start)

  const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background} ${percentage + 0.1
    }%)`

  return (
    <div className="SliderBar">
      <input
        type="range"
        min={start}
        max={end}
        className="SliderBar__track"
        style={{ background: bg, color: `${settings.fill}` }}
        value={value}
        onChange={onSlide}
        step={step}
      />
      <div className="SliderBar__numbers flex">
        <span className="SliderBar__start-number">{start}</span>
        <span className="SliderBar__start-number">{end}</span>
      </div>
    </div>
  )
}

SliderBar.defaultProps = {
  defaultValue: 0,
  start: 0,
  end: 100,
  fill: '#A9B6CB',
  background: '#EDF1F7',
  step: 1,
} as Partial<SliderProps>

export default SliderBar
