import { Registry } from '@react-registry'

export const italicDeserializer = () => {
  return {
    italic: true,
  }
}

Registry.register(italicDeserializer, { id: 'I', registry: 'inline-deserializer' })
Registry.register(italicDeserializer, { id: 'EM', registry: 'inline-deserializer' })
