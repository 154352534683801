import React from 'react'
import AssetImage from '../../SelectImage/GridView/AssetImage/AssetImage';
import Dropable from '../Dropable';
import CreateFolder from '../CreateFolder';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../SelectImage/GridView/Loader';
import '../index.css';

export function GridComponent({ state, handleClick, handleUpload, editor, folderUid, setState }) {
  return state.assets.map(asset => (
    asset.new ? <CreateFolder key={'create-folder'} editor={editor} type='grid' folderUid={folderUid} setUploading={setState} /> :
      <Dropable key={asset.uid} config={{ accept: 'image/*', multiple: true, onDrop: (files) => handleUpload(files, asset.uid), noDragEventsBubbling: true, noClick: true }}>
        <AssetImage checked={state.selectedImage.uid === asset.uid} asset={asset} handleClick={handleClick}></AssetImage>
      </Dropable>
  )
  )
}

export function ThumbnailView({ state, handleClick, loadMoreGrid, handleUpload, editor, folderUid, setState }) {
  return (
    <div id='scrollableDiv' className='scrte-asset-grid-view' data-testid="asset-grid-view">
      {state.assets.length === 0 && (
        <div style={{ textAlign: 'center' }} data-testid="asset-grid-empty">
          <h5 style={{ marginBottom: '0.5rem' }}>No Records Found</h5>
          Add some Assets
        </div>
      )}
      <InfiniteScroll
        loader={<Loader />}
        dataLength={state.assets.length}
        hasMore={state.assets.length < state.count}
        next={loadMoreGrid}
        style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
        scrollableTarget='scrollableDiv'
      >
        {state.loading && <Loader />}
        <GridComponent setState={setState} editor={editor} state={state} handleClick={handleClick} handleUpload={handleUpload} folderUid={folderUid} />
      </InfiniteScroll>
    </div>
  )
}