import React, { useEffect, useState } from 'react'

import { Avatar } from '../../../../../index'
import CommentTextArea from './CommentTextArea'
import { IUserState, IMessageDTO, IUserDTO } from '../../utils/types'

import styles from './style.module.css'
import { getUserName } from './utils'
import moment from 'moment'
import CommentActionBar from './CommentActionBar'
import CommentResolvedText from './CommentResolvedText'

interface ICommentCard {
  comment: IMessageDTO | null
  userState: IUserState,
  mode: "edit" | "view",
  handleOnSave: React.MutableRefObject<any>
}
const CommentCard: React.FC<ICommentCard> = ({ userState, comment, handleOnSave, mode }) => {
  const [commentUser, setCommentUser] = useState<IUserDTO>()

  useEffect(() => {
    let commentUser: IUserDTO
    if (!comment) {
      commentUser = userState.currentUser
    } else {
      commentUser = userState.userMap[comment.created_by]
    }
    setCommentUser(commentUser)
  }, [userState])

  // TODO: loader
  if (!commentUser) {
    return null
  }
  return (
    <div className={styles['scrte-discussion-comment--wrapper']}>
      <div className={styles['scrte-discussion-comment--user-details']}>
        {/* @ts-ignore */}
        <Avatar avatars={[{ name: getUserName(commentUser), id: commentUser.uid }]} />
        <div className={styles['scrte-discussion-comment--user-details__text']}>
          <div className={styles['scrte-discussion-comment--user-name']}>
            {getUserName(commentUser)}
          </div>
          {comment && <div className={styles['scrte-discussion-comment--time-details']}>{moment(comment.created_at).format('MMM DD, YYYY, hh:mm A')}</div>}
        </div>
        <CommentActionBar mode={mode} commentUser={commentUser} currentUser={userState.currentUser} commentUid={comment?.uid} />
      </div>
      {
        mode === "edit"
          ?
          <CommentTextArea
            userState={userState}
            handleOnSave={handleOnSave}
            comment={comment}
          />
          : comment && (
            <CommentResolvedText comment={comment} userState={userState} />
          )
      }
    </div>
  )
}

export default CommentCard