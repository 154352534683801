import React from 'react'
import { Registry } from '@react-registry'
import { BoldIcon } from '../../utils/Icon'
import { leafCategories } from '../leafCategories'

export const Bold = (props) => <strong>{props['children']}</strong>

export * from './deserializer'

Registry.register(
  {
    Component: Bold,
    title: 'Bold',
    iconName: <BoldIcon />,
    shortcut: { key: 'mod+b' },
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE,
    inBasicToolbar: true,
  },
  { id: 'bold', registry: 'leaf' }
)
