import { getUrlFromIframe, isIframe } from "./EmbededOverlay"
var YOUTUBE = 'youtube'
var VIMEO = 'vimeo'
var DAILYMOTION = 'dailymotion'

var VIMEO_MATCH_RE = /^(?:\/video|\/channels\/[\w-]+|\/groups\/[\w-]+\/videos)?\/(\d+)/


export default function embed(url = '') {
  if (typeof (url) !== typeof ('')) return undefined
  url = /^\/\//.test(url) ? "http:" + url : url;
  if (isIframe(url)) {
    url = getUrlFromIframe(url, false)?.url
  }
  var res = embed.info(url)
  return res && embed[res.source] && embed[res.source](res.id)
}

embed.info = function (url) {
  url = new URL(url)
  var id

  id = detectYoutube(url)
  if (id) {
    return {
      id: id,
      source: YOUTUBE,
      url: url.href
    }
  }

  id = detectVimeo(url)
  if (id) {
    return {
      id: id,
      source: VIMEO,
      url: url.href
    }
  }

  id = detectDailymotion(url)
  if (id) {
    return {
      id: id,
      source: DAILYMOTION,
      url: url.href
    }
  }
}

// For compat with <=2.0.1
embed.videoSource = embed.info

function detectVimeo(url) {
  var match = VIMEO_MATCH_RE.exec(url.pathname)
  return (url.hostname === 'vimeo.com' || 'player.vimeo.com' && match) ? match[1] : null
}

function detectYoutube(url) {
  if (url.hostname?.indexOf('youtube.com') > -1) {
    return url.searchParams.get('v') || url.pathname.split('/')[2]
  }

  if (url.hostname === 'youtu.be') {
    return url.pathname.split('/')[1]
  }

  return null
}

function detectDailymotion(url) {
  if (url.hostname?.indexOf('dailymotion.com') > -1) {
    return url.pathname.split('/').length === 3 ? url.pathname.split('/')[2].split('_')[0] : url.pathname.split('/')[3].split('_')[0]
  }

  if (url.hostname === 'dai.ly') {
    return url.pathname.split('/')[1]
  }

  return null
}

embed.vimeo = function (id, opts) {
  opts = parseOptions(opts)
  return 'https://player.vimeo.com/video/' + id + opts.query
}

embed.youtube = function (id, opts) {
  opts = parseOptions(opts)
  return 'https://www.youtube.com/embed/' + id + opts.query
}

embed.dailymotion = function (id, opts) {
  opts = parseOptions(opts)
  return 'https://www.dailymotion.com/embed/video/' + id + opts.query
}


function parseOptions(opts) {
  var queryString = ''
  var attributes = ''

  if (opts && opts.hasOwnProperty('attr')) {
    attributes = ' ' + Object.keys(opts.attr).map(function (key) {
      return key + '="' + escape(opts.attr[key]) + '"'
    }).join(' ')
  }

  return { query: queryString, attr: attributes }
}



