import React, { memo, useState, useEffect } from 'react';
import './index.css';


const Loader = memo(() => {
  const [loaders, setLoaders] = useState([]);

  useEffect(() => {
    let tempLoader = [];
    for (let i = 0; i < 16; i++) tempLoader.push(i);
    setLoaders(tempLoader);
    setTimeout(() => setLoaders([]), 1500);
  }, []);

  return (
    <React.Fragment>
      {
        loaders.map(loader => (
          <div key={loader} id='create-folder-grid-card' className={`create-folder-asset-card`}>
            <div className={`flex-v-center flex-h-center create-folder-asset-body loading-folder`}>
            </div>
            <div className='create-folder-asset-description'>
            </div>
          </div>
        ))
      }
    </React.Fragment>
  )
}, () => true);


export default Loader;