import React from 'react'
import { Registry } from '@react-registry'
import { useSlate } from 'slate-react'
import { Editor, Node, Transforms } from 'slate'
import { cx } from '@emotion/css'

//@ts-ignore
import styles from '../style.module.css'
import { elementCategories } from '../../elementCategories'
import { insertHeader } from './insertHeader'
import { deleteHead } from './deleteHead'
import { Switch } from './Switch/Switch'
import { ElementWithType, ExtendedEditor } from '../../../../../../../utils/types'

const tableHeaderButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n: ElementWithType) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  return !table
}

export const doesTableHeaderExist = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n: ElementWithType) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  }) as any;

  if (table) {
    return table[0]?.children[0]?.type === 'thead'
  }

  return false
}

export const getTableHeadLocation = (editor) => {
  const [table] = Editor.nodes(editor, {
    match: (n: ElementWithType) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  }) as any;

  if (table) {
    if (table[0]?.children[0]?.type === 'thead') {
      return [...table[1], 0]
    }
  }

  return false
}

const handleHeaderToggle = (event, editor, addHeader) => {
  event?.preventDefault()
  if (addHeader) {
    insertHeader(event, { editor })
  } else {
    const tableHeadLocation = getTableHeadLocation(editor)
    if (tableHeadLocation) {
      Transforms.select(editor, tableHeadLocation)
      deleteHead(event, { editor })
    }
  }
}

export default function HeaderToggleSwitch() {
  const editor: ExtendedEditor = useSlate()
  const isTableDisable = tableHeaderButtonDisabler({ editor })
  const headerExist = doesTableHeaderExist({ editor })
  const selection: any = editor.selection || editor.savedSelection

  const handleMouseDown = (event) => {
    if (selection) {
      Transforms.select(editor, selection)
      if (!isTableDisable) {
        handleHeaderToggle(event, editor, !headerExist)
      }
    }
  }
  const handleCheckbox = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (selection) {
      Transforms.select(editor, selection)
      if (!isTableDisable) {
        handleHeaderToggle(event, editor, !headerExist)
      }
    }
  }
  return (
    <span
      data-testid="table-header-btn"
      className={cx(styles['table-header-btn'], {
        [styles['table-header-btn--disable']]: isTableDisable
      })}
      onMouseDown={handleMouseDown}>
      <span>Header row </span>
      <span style={{ display: 'flex' }}>
        <Switch disabled={isTableDisable} onChange={handleCheckbox} checked={headerExist} />
      </span>
    </span>
  )
}

Registry.register(
  {
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
      RenderComponent: HeaderToggleSwitch
    },
    canCustomize: false,
    title: 'Header is Here 2',
    isContentStackElement: true
  },
  { id: 'tableHeaderBtn', registry: 'element' }
)
