import React, { useCallback } from 'react'

import Icon from '../../../../../components/Icon2/Icon'

import styles from './style.module.css'
import { IUserDTO } from '../../utils/types'
import { cx } from '@emotion/css'
import { DiscussionProvider } from '../ContextProvider'
import { deleteSingleComment, failureNotification } from './utils/request'
import { findIndex } from 'lodash'

interface ICommentActionBar {
  mode: "edit" | "view",
  commentUser: IUserDTO,
  currentUser: IUserDTO,
  commentUid: string | undefined
}
const className = cx(styles['scrte-discussion-comment-action--wrapper'], 'scrte-discussion-comment-action--wrapper')
const iconProps: any = {
  size: "mini",
  withTooltip: true,
  tooltipPosition: 'bottom'
}
const CommentActionBar: React.FC<ICommentActionBar> = ({ mode, commentUser, currentUser, commentUid }) => {
  const { editorMetadata, entryMetadata, discussionUID, setDiscussionState } = React.useContext(DiscussionProvider)
  const setEditComment = useCallback((commentUid: string) => {
    setDiscussionState((prevState) => ({ ...prevState, editComment: commentUid }))
  }, [setDiscussionState])

  if (mode === "edit" && commentUid) {
    const handleCancel = () => {
      setEditComment("")
    }
    return <div className={className}>
      <Icon icon="Cancel" tooltipContent="Cancel" onClick={handleCancel} {...iconProps} />
    </div>
  }
  else if (commentUser.uid !== currentUser.uid || !commentUid) {
    return null
  }
  const handleCommentDelete = () => {
    deleteSingleComment(editorMetadata, entryMetadata, discussionUID, commentUid)
      .then((res) => {
        setDiscussionState((prevState) => {
          const commentIndex = findIndex(prevState.comments, { uid: commentUid })
          const newComments = [...prevState.comments]
          newComments.splice(commentIndex, 1)
          return {
            ...prevState,
            comments: newComments,
            commentCount: prevState.commentCount - 1
          }
        })
      })
      .catch((error) => {
        failureNotification(error?.data?.error_message || "Error while deleting comments.", error?.data?.errors)
      })
  }
  const handleCommentEdit = () => {
    setEditComment(commentUid)
  }
  return (
    <div className={className} data-test-id='discussion-action-wrapper'>
      <Icon icon="Edit" tooltipContent="Edit" onClick={handleCommentEdit} {...iconProps} />
      <Icon icon="Trash" tooltipContent="Delete" onClick={handleCommentDelete} {...iconProps} />
    </div>
  )
}

export default CommentActionBar