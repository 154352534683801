const properties = (editor) => {
  const { rich_text_type, reference_to, options, title, uid } = editor.requestProps;
  return {
    fieldConfig: {
      rich_text_type,
      reference_to,
      options,
      title,
      uid
    }
  }
};

export default properties;