import React, { useEffect } from "react";

export const useOutsideClick = (ref: any, handleOutsideClick?: (event) => any) => {
  useEffect(() => {
    if (handleOutsideClick && ref.current) {
      document.addEventListener('click', handleOutsideClick, true)
      return () => {
        document.removeEventListener('click', handleOutsideClick, true)
      }
    }
  }, [])
}