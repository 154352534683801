//@ts-nocheck

import React, { createRef, useState } from 'react';
import { Icon } from '../../../../index'
import styles from './View/index.module.css';
import './index.css';

import { createFolder } from '../../commons/request';
// import { NewFolderIcon, NewFolderIconLarge } from '../../../../../utils/Icon';

const CreateFolder = ({ editor, folderUid, type = 'list', setUploading }) => {
  const [state, setState] = useState({
    folderName: 'New Folder',
    isDisable: true,
  });
  const inputRef = createRef();
  const handleEdit = (e) => {
    e.preventDefault();
    if (!state.isDisable) return;
    inputRef.current.focus();
    setState(prevState => ({ ...prevState, isDisable: false, folderName: '' }));
  }

  const handleChange = (e) => {
    e.persist();
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState(prevState => ({ ...prevState, isDisable: true, folderName: 'New Folder' }))
  }

  const handleOk = (e) => {
    e.preventDefault();
    e.stopPropagation();
    folderUid = folderUid === 'cs_root' ? '' : folderUid;
    setUploading(prevState => ({ ...prevState, uploading: true }));
    createFolder({ parentUid: folderUid, folderName: state.folderName })
      .then(res => setUploading(prevState => {
        let assets = prevState.assets;
        let newCount = prevState.count + 1;
        assets.push(res.asset)
        setState(prevState => ({ ...prevState, isDisable: true, folderName: 'New Folder' }));
        return { ...prevState, assets: assets, uploading: false, count: newCount };
      }))
      .catch(err => { console.error(err) });
  }

  return (

    type === 'list' ? (
      <div data-testid="list-wrapper" onClick={handleEdit} style={{ display: 'flex', alignItems: 'center' }}>
        <span className={styles['list-icon']}>
          <Icon icon='AddCircle' />
        </span>
        <input ref={inputRef} className='new-folder-input' autoFocus={!state.isDisable} placeholder="Enter folder name" value={state.folderName} onChange={handleChange} name='folderName' />
        {!state.isDisable &&
          (
            <React.Fragment>
              <Icon data-testid="cancel-edit" style={{ marginRight: '0.5rem' }} icon='CancelCircle' onClick={handleCancel}></Icon>
              <Icon data-testid="handle-ok" icon='CheckedCircle' onClick={handleOk}></Icon>
            </React.Fragment>
          )}
      </div>
    ) : (
      <div data-testid="grid-wrapper" onClick={handleEdit} id='create-folder-grid-card' className={`create-folder-asset-card`}>
        <div className={`flex-v-center flex-h-center create-folder-asset-body`}>
          {state.isDisable ? <Icon icon='AddCircle' size='large' /> :
            (
              <div style={{ textAlign: 'right' }}>
                <Icon data-testid="cancel-edit" size='large' style={{ marginRight: '0.5rem' }} icon='CancelCircle' onClick={handleCancel}></Icon>
                <Icon size='large' icon='CheckedCircle' onClick={handleOk}></Icon>
              </div>
            )}
        </div>
        <div className='create-folder-asset-description'>
          <input autoComplete='false' ref={inputRef} style={{ 'textAlign': state.isDisable ? 'center' : 'left' }} className={`new-folder-input full-width GridAssetName GridCreateFolder flex-v-center flex-h-center ${state.isDisable ? 'non-edit' : ''}`} autoFocus={!state.isDisable} placeholder="Enter folder name" value={state.folderName} onChange={handleChange} name='folderName' />
        </div>
      </div>
    )


  )
}


export default CreateFolder;