//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'

import { calculateColSize } from './calculateColSize'
import { deleteTable } from './deleteTable'
import { elementCategories } from '../../elementCategories'
import { useSlate } from 'slate-react'
import { TableDeleteColumnIcon } from '../../../utils/Icon'

const handleDeleteCell = (editor, row, path) => {
    Transforms.removeNodes(editor, { at: path })
}

export const deleteCol = (event, { editor }) => {
  const [headCell] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'th'
    },
    mode: 'lowest'
  })
  const [bodyCell] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'td'
    },
    mode: 'lowest'
  })
  let cell
  if (bodyCell && !headCell) {
    cell = bodyCell
  } else if (headCell && !bodyCell) {
    cell = headCell
  } else if (headCell && bodyCell) {
    cell = bodyCell
  }
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  if (cell && table) {
    const cellPath = cell[1]
    const cellPos = cellPath[cellPath.length - 1]
    const tablePath = table[1]
    const tableCol = calculateColSize(event, editor)

    if (tableCol > 1) {
      Array.from(table[0].children).forEach((tableChild, tableChildPos) => {
        Array.from(tableChild.children).forEach((row, rowPos) => {
          if (row.type === 'trgrp') {
            const rowgrp = row
            const rowGrpPos = rowPos
            rowgrp.children.forEach((row, rowPos) => {
              const path = [...tablePath, tableChildPos, rowGrpPos, rowPos, cellPos]
              handleDeleteCell(editor, row, path)
            })
            return
          }
          const path = [...tablePath, tableChildPos, rowPos, cellPos]
          handleDeleteCell(editor, row, path)
        })
      })
      let colWidths = table[0].attrs.colWidths.slice()
      colWidths.splice(cellPos, 1)
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table[0].attrs,
            colWidths,
            cols: tableCol - 1
          }
        },
        { at: table[1] }
      )
    } else {
      deleteTable(event, { editor })
    }
  }
}
const DeleteColButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: deleteCol,
        isDisable: { func: deleteColButtonDisabler, params: { editor } },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}
const deleteColButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  return !table
}
Registry.register(
  {
    iconName: <TableDeleteColumnIcon />,
    IngressComponent: DeleteColButton,
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
    },
    canCustomize: false,
    title: 'Delete column',
    isContentStackElement: true
  },
  {
    id: 'table-create-header',
    registry: 'element'
  }
)
