//@ts-nocheck
import { Transforms, Node, Editor, Element } from 'slate'

export const setClass = (editor, classText, selection) => {
  for (const [node, path] of Editor.nodes(editor, { at: selection })) {
    if (Element.isElement(node) && node.type !== 'doc') {
      const oldAttributes = node.attrs
      const elementAttribute = {
        attrs: {
          ...oldAttributes,
          "class-name": classText
        }
      }
      Transforms.setNodes(editor, elementAttribute, { at: path })
    }
  }
}

export const setId = (editor, idText, selection) => {

  for (const [node, path] of Editor.nodes(editor, { at: selection })) {
    if (Element.isElement(node) && node.type !== 'doc') {
      const oldAttributes = node.attrs
      const elementAttribute = {
        attrs: {
          ...oldAttributes,
          id: idText
        }
      }
      Transforms.setNodes(editor, elementAttribute, { at: path })
    }
  }

}

export const getClass = (editor, selection) => {
  if (selection.focus.path) {
    const attributes = Node.parent(editor, selection.focus.path).attrs
    if (attributes) {
      return attributes["class-name"]
    }
  }
}

export const getId = (editor, selection) => {
  if (selection.focus.path) {
    const attributes = Node.parent(editor, selection.focus.path).attrs
    if (attributes) {
      return attributes.id
    }
  }
}
