import React, { useState } from 'react'
import cbModal from '../Modal/Modal'
import Icon from '../Icon2/Icon'

import './SideBarModal.css'

interface ISideBarModalProps {
  children: React.ReactNode
  shouldCloseOnOverlayClick?: boolean
  modalContent: React.ReactNode
  // modalPosition: string
  modalContentPosition?: string
  store?: any
  customClass?: string
}

interface IGetModalContentProps {
  modalContent: React.ReactNode
  shouldCloseOnOverlayClick?: boolean
  // modalPosition: string
  closeModal: (data?: any) => void
}

export const GetModalContent: React.FunctionComponent<IGetModalContentProps> = props => {
  const { closeModal, modalContent: ModalContent } = props
  return (
    <>
      <div
        onClick={closeModal}
        className="RightSideHide flex-center SidebarWindow__hide-icon-container"
      >
        <Icon icon="ChevronRight" />
      </div>
      <div>{typeof ModalContent === "function" ? <ModalContent closeModal={closeModal} /> : ModalContent}</div>

    </>
  )
}

const SideBarModal = (props: ISideBarModalProps) => {
  const { children, modalContent, modalContentPosition, store, customClass, shouldCloseOnOverlayClick } = props

  const modalPosition = modalContentPosition !== undefined ? modalContentPosition : 'right'

  const getSideBarModal = () => {
    cbModal({
      component: (props: any) => (
        <GetModalContent modalContent={modalContent} closeModal={props.closeModal} />
      ),
      modalProps: {
        modalContentPosition: modalPosition,
        isSidebar: true,
        shouldCloseOnEsc: false,
        store: store,
        customClass: customClass,
        shouldCloseOnOverlayClick: shouldCloseOnOverlayClick,
        size: 'dynamic',
        style: {
          overlay: {
            backgroundColor: 'rgba(74, 85, 104, 0.5)',
            zIndex: '1000',
          },
          content: {},
        },
      },
    })
  }

  return <div onClick={getSideBarModal}>{children}</div>
}

SideBarModal.defaultProps = {
  shouldCloseOnOverlayClick: false
} as Partial<ISideBarModalProps>

export default SideBarModal
