import React, { useState } from 'react'
import { cx } from '@emotion/css'

import CommentCard from './CommentCard'
import DiscussionBodyLoader from './loader/DiscussionBody'

import { IEditorRequestProps, IMessageDTO, IStackMetadata, IUserState } from '../../utils/types'
import styles from './style.module.css'

interface IDiscussionBody {
  isLoading: boolean,
  handleOnSave: React.MutableRefObject<any>,
  userState: IUserState,
  comments: Array<IMessageDTO>,
  fetchingMore: boolean,
  editComment: string
  editorMetadata: IEditorRequestProps,
}

const DiscussionBody: React.FC<IDiscussionBody> = ({ isLoading, userState, comments, fetchingMore, handleOnSave, editComment, editorMetadata }) => {
  const isRestrictedWindow = editorMetadata.isPlugin && !editorMetadata.isFullScreen
  return (
    <div className={styles['scrte-discussion-body--wrapper']}>
      {
        isLoading ?
          Array.from({ length: 1 }).map((_d, index) => <DiscussionBodyLoader key={`scrte-discussion-body--comment-loader-${index}`} />)
          :
          <div
            className={cx(
              styles[`scrte-discussion-comment--list`],
              isRestrictedWindow && styles[`scrte-discussion-comment--list_plugin${editComment ? "__edit" : ""}`]
            )}
            id="scrte-discussion-comment--list"
          >
            {comments.map((comment) =>
            (
              <CommentCard
                userState={userState}
                comment={comment}
                handleOnSave={handleOnSave}
                key={comment.uid}
                mode={editComment === comment.uid ? "edit" : "view"}
              />
            ))}
            {fetchingMore ? <DiscussionBodyLoader /> : <></>}
          </div>
      }
      {
        editComment === "" ?
          <CommentCard
            userState={userState}
            comment={null}
            handleOnSave={handleOnSave}
            mode="edit"
          /> : <></>
      }
    </div>
  )
}

export default DiscussionBody