//@ts-nocheck
import ComponentRegistry from '../../../../utils/react-registry/src/lib/ComponentRegistry'

export const getRegistry = (registry) => {
    const instance = ComponentRegistry.getInstance().getRegistry(registry)['registry']['map'];
    let newInstance = {}
    Object.entries(instance).forEach(([key, val]) => {
        newInstance[key] = {
            components: val.components.slice().reverse()
        }
        //console.log(val.get(false, { overide: true }))
    })
    return newInstance
}