import React from 'react'
import { Registry } from '@react-registry'


const SCRIPT = (props) => {
  return <span {...props.attributes} contentEditable={false} >{props.children}</span>
}

export default SCRIPT
export * from './withScript'
Registry.register(
  {
    Component: SCRIPT,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'script', registry: 'element' }
)