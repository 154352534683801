import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import debounce from 'lodash/debounce'

import Icon from '../../Icon2/Icon'
import SkeletonTile from '../../SkeletonTile/SkeletonTile'
import Checkbox from '../../Checkbox/Checkbox'
import Datepicker from '../../Datepicker/Datepicker'

import {
  limitToFetch,
  stringOperators,
  basicOperators,
  allOperators,
} from '../util/constants'

import { fetchFilterData } from './utils'
import { getLocalizedOptions, truncate } from '../util/helpers'
import { getOperatorTextContent } from '../util/common'
import SearchTooltip from '../SearchTooltip'

const SkeletonLoader = ({ size = 2 }) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={8}
                tileWidth={110}
                tileRadius={6}
                tileBottomSpace={12}
                tileTopSpace={12}
                tileleftSpace={18}
              />
            </div>
          )
        })}
    </div>
  )
}

const HighlightMatch = ({ string, option, isOptionCheck }: any) => {
  let fullString = option.label
  let OptValue = option.value

  let styleObj: any = isOptionCheck
    ? { color: '#6c5ce7', fontWeight: 'bold' }
    : { color: '', fontWeight: '' }

  let { truncatedText, isOverflow } = truncate(fullString);

  if (!string || !(string && fullString.toLowerCase().indexOf(string.toLowerCase()) !== -1)) {
    return isOverflow
      ? <SearchTooltip
        content={fullString}
        position="right"
      >
        {truncatedText}
      </SearchTooltip>
      : fullString
  }

  const matchStart = fullString.toLowerCase().indexOf('' + string.toLowerCase() + '')
  const matchEnd = matchStart + string.length - 1

  const beforeMatch = truncatedText.slice(0, matchStart)
  const matchText = truncatedText.slice(matchStart, matchEnd + 1)
  const afterMatch = truncatedText.slice(matchEnd + 1)

  const MatchTextComponent = () => {
    if (isOverflow && (OptValue === 'freeText' || option.type === 'text')) {
      return (
        <span style={styleObj}>
          "
          {beforeMatch}
          <span><strong id="react-select">{matchText}</strong></span>
          {afterMatch}
          "
        </span >
      )
    }

    return (
      <span style={styleObj}>
        {beforeMatch}
        <span>
          <strong id="react-select">{(OptValue === 'freeText' || option.type === 'text') ? `"${matchText}"` : matchText}</strong>
        </span>
        {afterMatch}
      </span >
    )
  }

  if (isOverflow) {
    return (
      <SearchTooltip
        content={fullString}
        position="right"
      >
        <MatchTextComponent />
      </SearchTooltip>
    )
  }
  return <MatchTextComponent />
}

const operatorsByCurrentQuery = {
  listDate: allOperators.filter((op) => op.value != '$ne'),
  fetchContentTypes: stringOperators,
}

const operatorsByType = {
  isText: stringOperators,
  isNumeric: allOperators,
}

const getOperators = (filterObject: any) => {
  const sortOperators = (operators, defaultOperator) => {
    const sortedOps = operators.filter((operator) => operator.value !== defaultOperator.value)
    sortedOps.unshift(defaultOperator)
    return sortedOps;
  }

  let selectedOperators = basicOperators;

  if (filterObject.currentQuery in operatorsByCurrentQuery) {
    selectedOperators = operatorsByCurrentQuery[filterObject.currentQuery];
  }
  else if (('isText' in filterObject) && filterObject.type === 'uid') {
    selectedOperators = basicOperators
  }
  else if (('isText' in filterObject) && filterObject.type === 'textLabel') {
    selectedOperators = [stringOperators[1]]
  }
  else if (('isText' in filterObject) && filterObject.isText) {
    selectedOperators = operatorsByType.isText;
  }
  else if (('isNumeric' in filterObject) && filterObject.isNumeric) {
    selectedOperators = operatorsByType.isNumeric;
  }

  return sortOperators(selectedOperators, filterObject.defaultOperator);
}

class FilterDropdown extends Component<any> {
  state = {
    suggestions: [],
    needOperator: true,
    queryCursor: -1,
    suggestionsLength: 0,
    showSuggestions: true,
    hasMore: true,
    skip: 0,
    count: 0,
    loading: false,
    operators: getOperators(this.props.filterObject),
  }

  suggestionContainerRef: any = React.createRef();
  suggestionsRef: any = React.createRef()
  isCursorInside: any = false

  async componentDidMount() {
    try {
      document.addEventListener('keyup', this.regiterKeyBindings, false)
      document.addEventListener('click', this.handleClickOutside, false)


      /** set prev value to inputValue for edit op */
      if (this.props.filterObject.isText) {
        const value = this.props.filterObject.value;
        if (value) {
          const prevSelectedValue = this.props.filterObject.value.value
          this.props.setInputValue(prevSelectedValue);
        }
        this.setState({
          suggestions: [{ value: this.props.inputValue || '', label: this.props.inputValue || '', type: 'text' }],
          suggestionsLength: 1,
          count: 1,
          hasMore: false,
          loading: false,
        })
        return;
      }

      /**case for file size */
      if (this.props.filterObject.isNumeric) {
        const value = this.props.filterObject.value;
        if (value) {
          const prevSelectedValue = this.props.filterObject.value.enteredValue || this.props.filterObject.value.value;
          this.props.setInputValue(prevSelectedValue);
        }
      }

      const currentQuery = this.props.filterObject.currentQuery
      if (currentQuery === 'fetchEntriesTags' || currentQuery === 'fetchAssetTags') {
        this.props.setInputType && this.props.setInputType('tagLabelCase')
      }

      this.setState({ loading: true })
      const response = await fetchFilterData({
        skip: 0,
        limit: limitToFetch,
        inputValue: this.props.inputValue,
        fetchData: this.props.fetchData,
        currentQuery: this.props.filterObject.currentQuery,
        languages: this.props.languages,
        filters: this.props.filters
      })

      const { data, count } = response

      this.setState({
        suggestions: data,
        suggestionsLength: data.length,
        count: count,
        hasMore: data.length >= count ? false : true,
        loading: false,
      })


    } catch (error) {
      this.setState({ loading: false })
      console.log('AutoComplete componentDidMount error', error)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { queryCursor, suggestionsLength } = this.state
    if (prevState.queryCursor !== queryCursor && suggestionsLength > 9) {
      let scrollDropdownPosition = (queryCursor - 8) * 35
      this.suggestionsRef.current.scrollTop = scrollDropdownPosition
    }
    if (prevProps.inputValue !== this.props.inputValue) {

      /**clears input value */
      if ((this.props.filterObject.isText || this.props.filterObject.isNumeric) && !this.props.inputValue) {
        this.props.updateFilter({
          type: 'value',
          isMulti: false,
          value: '',
          uid: this.props.filterObject.uid,
          isText: this.props.filterObject.isText,
          isNumeric: this.props.filterObject.isNumeric
        })
      }

      this.setState({ loading: true })
      this.debounceInputChange()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.regiterKeyBindings, false)
    document.removeEventListener('click', this.handleClickOutside, false)
    this.props.setInputType && this.props.inputType !== 'text' && this.props.setInputType('text')
  }

  debounceInputChange = debounce(async () => {
    try {
      const response = await fetchFilterData({
        skip: 0,
        limit: limitToFetch,
        inputValue: this.props.inputValue,
        fetchData: this.props.fetchData,
        currentQuery: this.props.filterObject.currentQuery,
        isText: this.props.filterObject.isText,
        filters: this.props.filters,
        languages: this.props.languages,
      })

      const { data, count } = response

      this.setState({
        skip: 0,
        suggestions: data,
        suggestionsLength: data.length,
        count: count,
        hasMore: data.length >= count ? false : true,
        loading: false,
      })
    } catch (error) {
      console.log('debounceInputChange error', error)
      this.setState({ loading: false })
    }
  }, 300)

  handleClickOutside = (e) => {
    if (!this.isCursorInside && e.target.name !== this.props.inputName) {
      console.log('Click outside detected closing filterDropdown')
      this.props.closeAutoComplete()
    }
  }

  regiterKeyBindings = (event) => {
    const { key } = event
    let { needOperator, queryCursor, suggestionsLength, operators } = this.state

    if (
      key === 'ArrowDown' &&
      queryCursor <
      +`${needOperator ? suggestionsLength + operators.length - 1 : suggestionsLength - 1}`
    ) {
      queryCursor = queryCursor + 1
      console.log('ArrowDown -> queryCursor', queryCursor)
      this.setState({ queryCursor })
    } else if (key === 'ArrowUp' && queryCursor > 0) {
      queryCursor = queryCursor - 1
      console.log('ArrowUp -> queryCursor', queryCursor)
      this.setState({ queryCursor })
    }

    if (key === 'Enter' && queryCursor >= 0) {
      if (queryCursor < operators.length) {
        this.handleOperatorChange(operators[queryCursor], queryCursor)
      } else {
        const updatedCursor = queryCursor - operators.length
        const selectedoption = this.state.suggestions[updatedCursor]
        const { isMulti, value } = this.props.filterObject

        if (isMulti) {
          const foundOpt = value.find((v) => v.value === selectedoption.value)

          const checkStatus = foundOpt ? false : true

          this.updateMultipleValue(checkStatus, selectedoption)
        } else {
          this.updateSingleValue(false, selectedoption)
        }
      }
    }
  }

  fetchOnScroll = async () => {
    try {
      let { skip, suggestions, suggestionsLength, count } = this.state
      const currentQuery = this.props.filterObject.currentQuery
      if (suggestionsLength >= count) {
        this.setState({ hasMore: false })
        return
      }

      const response = await fetchFilterData({
        skip: skip + limitToFetch,
        limit: limitToFetch,
        inputValue: this.props.inputValue,
        fetchData: this.props.fetchData,
        currentQuery: this.props.filterObject.currentQuery,
      })

      const updatedSuggestions = [...suggestions, ...response.data]

      this.setState({
        skip: skip + limitToFetch,
        suggestions: updatedSuggestions,
        suggestionsLength: updatedSuggestions.length,
        count: response.count,
        loading: false,
      })

      if ((currentQuery === 'fetchEntriesTags' || currentQuery === 'fetchAssetTags') && !response.data.length) {
        this.setState({ hasMore: false })
      }

    } catch (error) {
      this.setState({ loading: false })

      console.log('fetchOnScroll  error', error)
    }
  }

  setMultipleValues = (values, uid = null) => {
    this.props.updateFilter({
      type: 'value',
      action: 'set',
      isMulti: true,
      value: values,
      uid: uid || this.props.filterObject.uid
    });
  }

  updateMultipleValue = (checkStatus, option, isLocalized = false) => {
    if (isLocalized && option.value === '$any') {
      let values = []
      if (checkStatus) {
        values = this.state.suggestions;
      }
      this.setMultipleValues(values);
      return;
    }

    this.props.updateFilter({
      type: 'value',
      isMulti: true,
      value: option,
      isChecked: checkStatus,
      uid: this.props.filterObject.uid,
    })
  }

  updateSingleValue = (isMulti, selectedOpt, isLanguage = false) => {
    if (isMulti) {
      return
    }

    if (isLanguage) {
      const languageFilter = this.props.filters.find((filter) => filter.uid === this.props.filterObject.uid);
      if (!languageFilter.value.value || (languageFilter.value.value !== selectedOpt.value)) {
        const localizedOptions = getLocalizedOptions({
          languages: this.props.languages,
          selectedLanguageValue: selectedOpt.value
        })
        const localizedFilter = this.props.filters.find((filter) => filter.key.value === 'localization');
        this.setMultipleValues(localizedOptions, localizedFilter.uid);
      }
    }

    this.props.updateFilter({
      type: 'value',
      isMulti: false,
      value: selectedOpt,
      uid: this.props.filterObject.uid,
      isText: this.props.filterObject.isText
    })

  }

  handleOperatorChange = (selectedOperator, index) => {
    this.setState({ queryCursor: index })
    this.props.updateFilter({
      type: 'operator',
      value: selectedOperator,
      uid: this.props.filterObject.uid,
    })
  }


  onChangeDate = (selectedDate) => {
    this.props.updateFilter({
      type: 'value',
      isMulti: false,
      value: { label: selectedDate, value: selectedDate },
      uid: this.props.filterObject.uid,
    })
  }

  render() {
    const {
      needOperator,
      queryCursor,
      suggestionsLength,
      suggestions,
      loading,
      operators,
    } = this.state;

    const { textContent } = this.props;

    const { isMulti, operator, value, currentQuery, isText = false, isNumeric = false, queryType } = this.props.filterObject

    const shouldOperatorRender =
      (needOperator && suggestionsLength > 0) || currentQuery === 'listDate' || isText || isNumeric

    const topOffset = 8;
    const leftOffset = 0;

    const { top: inputTop, left: inputLeft, height } = this.props.inputRef.current.getBoundingClientRect();
    const { top: gsTop, left: gsLeft } = document.querySelector('#AdvanceSearchComponent').getBoundingClientRect();

    let top = (inputTop - gsTop) + height + topOffset;
    let left = (inputLeft - gsLeft) + leftOffset;

    return (
      <div
        style={{ top, left }}
        className={`AdvancedSearch__suggestion-dropdown ${this.state.showSuggestions ? ' AdvancedSearch__suggestion-dropdown--show' : ''}`}
        onMouseOver={() => { this.isCursorInside = true }}
        onMouseOut={() => { this.isCursorInside = false }}
      >

        <OperatorSection
          shouldOperatorRender={shouldOperatorRender}
          operators={operators}
          textContent={textContent}
          queryCursor={queryCursor}
          operator={operator}
          queryType={queryType}
          handleOperatorChange={this.handleOperatorChange}
        />

        <SuggestionDropdownBody
          currentQuery={currentQuery}
          value={value}
          suggestionsLength={suggestionsLength}
          fetchOnScroll={this.fetchOnScroll}
          hasMore={this.state.hasMore}
          loading={loading}
          suggestions={suggestions}
          needOperator={needOperator}
          queryCursor={queryCursor}
          operators={operators}
          onChangeDate={this.onChangeDate}
          updateSingleValue={this.updateSingleValue}
          updateMultipleValue={this.updateMultipleValue}
          isMulti={isMulti}
          isText={isText}
          isNumeric={isNumeric}
          suggestionsRef={this.suggestionsRef}
          inputValue={this.props.inputValue}
        />
      </div>
    )
  }
}

const OperatorSection = ({
  shouldOperatorRender,
  operators,
  textContent,
  queryCursor,
  operator,
  handleOperatorChange,
  queryType
}: any) => {

  if (!shouldOperatorRender) { return null; }

  return (
    <div className="AdvancedSearch__operators">
      {operators.map((oneOperator, index) => {

        let tooltipContent = getOperatorTextContent({
          operatorKey: oneOperator,
          queryType,
          textContent
        })

        const isOperatorActive = queryCursor === index || operator.value === oneOperator.value;

        return (
          <SearchTooltip content={tooltipContent} position="top" key={index}>
            <div
              className={`AdvancedSearch__operator${isOperatorActive ? ' AdvancedSearch__operator--active' : ''}`}>
              <button
                className="AdvancedSearch__operator-control"
                onClick={() => { handleOperatorChange(oneOperator, index) }}>
                <Icon icon={oneOperator.icon} className="AdvancedSearch__operator-icon" />
              </button>
            </div>
          </SearchTooltip>
        )
      })}
    </div>
  )
}


const SuggestionDropdownBody = ({
  currentQuery,
  onChangeDate,
  value,
  suggestionsLength,
  fetchOnScroll,
  hasMore,
  loading,
  suggestions,
  needOperator,
  queryCursor,
  operators,
  updateSingleValue,
  updateMultipleValue,
  isMulti,
  isText,
  isNumeric,
  suggestionsRef,
  inputValue,
}: any) => {

  if (currentQuery === 'listDate') {
    return <Datepicker onChange={onChangeDate} initialDate={value.value || new Date()} />
  }
  const isLocalized = currentQuery === 'fetchLocales';
  const isLanguage = currentQuery === 'fetchLanguages';

  const cssClass = `AdvancedSearch__filterDropdown-items ${suggestionsLength > 9 ? ' AdvancedSearch__filterDropdown-items--limit' : ''
    } ${isMulti ? '' : 'AdvancedSearch__filterDropdown-items--no-checkbox'} `


  const ScrollWrapper = ({ children }) => {
    return (
      <ul
        className={cssClass}
        id="autocompleteScrollableDiv">
        {children}
      </ul>
    )
  }

  if (loading) {
    let loaderSize = 2;
    loaderSize = isText ? 1 : loaderSize;

    return (
      <ScrollWrapper>
        <SkeletonLoader size={loaderSize} />
      </ScrollWrapper>
    )
  }

  if (!suggestionsLength && isNumeric) return null;

  if (!suggestionsLength) {
    return (
      <ScrollWrapper>
        <li className="ml-20 AdvancedSearch__no-result">{isText ? 'No Suggestions' : 'No Available option'}</li>
      </ScrollWrapper>
    );
  }

  return (
    <ul
      className={cssClass}
      ref={suggestionsRef}
      id="autocompleteScrollableDiv">
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={suggestionsLength}
        next={fetchOnScroll}
        hasMore={hasMore}
        loader={<SkeletonLoader />}
        scrollableTarget="autocompleteScrollableDiv"
        hasChildren={true}>
        {suggestions.map((option, index) => {
          const cursor = needOperator ? queryCursor - operators.length : queryCursor
          const isOptionCheck = isMulti ? value.find((v) => v.value === option.value) : value.value === option.value
          let isDisabled = false;

          if (!option.value) { return null }

          if (isLocalized) {
            const isAllSelected = value.find((r) => r.value === '$any');
            const isCurrentAny = option.value === '$any';
            isDisabled = isAllSelected && !isCurrentAny;
          }

          if (option.group) {
            return (
              <li className="AdvancedSearch__suggestion-item--heading" key={option.value}>{option.group}</li>
            )
          }

          return (
            <li
              onClick={() => { updateSingleValue(isMulti, option, isLanguage) }}
              key={option.value}
              className={`AdvancedSearch__suggestion-item AdvancedSearch__suggestion-item--selectable ${cursor === index ? ' AdvancedSearch__suggestion-item--active' : ''
                }`}>
              {isMulti ? (
                <Checkbox
                  disabled={isDisabled}
                  checked={isOptionCheck || false}
                  onChange={(e) => updateMultipleValue(e.target.checked, option, isLocalized)}
                  text={
                    <HighlightMatch
                      string={inputValue}
                      option={option}
                      isOptionCheck={isOptionCheck}
                    />
                  }
                />
              ) : (
                <HighlightMatch
                  string={inputValue}
                  option={option}
                  isOptionCheck={isOptionCheck}
                />
              )}
            </li>
          )
        })}
      </InfiniteScroll>
    </ul>

  )
}




export default FilterDropdown
