//@ts-nocheck
import React from 'react'
import { Transforms, Editor, Range } from 'slate'
import { ReactEditor } from 'slate-react'
import { v4 } from 'uuid';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Icon
} from '../../../../../../../../index'
import { Editor as ModifiedEditor } from '../../../../../utils/types'
import { insertImage } from '../../../image'
import { checkProps } from '../../utils/checkProps'
import { InvalidPropsMsg } from '../../utils/InvalidPropsMessage/InvalidPropsMsg'
import SelectImage from '../SelectImage'
import './style.css'

export const getImageMarkdown = (href, text = 'Enter your image caption here') => {
  text = text === '' ? href : text
  return `![${text}](${href})`
}

declare interface ModifiedEditorSecond extends ModifiedEditor, ReactEditor { }

export declare type ModalComponentProps = {
  editor: ModifiedEditorSecond
  closeModal: Function
}

const insertEmbedAsset = ({ asset, editor }) => {
  const selection = editor.selection || editor.savedSelection;
  const node = {
    type: 'reference',
    attrs: {
      'display-type': 'asset',
      'asset-uid': asset.uid,
      'content-type-uid': 'sys_assets',
      "asset-link": asset.url,
      "display-type": "asset",
      "asset-name": asset.title,
      "asset-type": asset.content_type,
      "type": 'asset',
      'class-name': 'embedded-asset'
    },
    uid: v4().split('-').join(''),
    children: [{ text: '' }]
  }
  if (!editor?.requestProps?.uidToEntry) {
    editor.requestProps['uidToEntry'] = {};
  }
  asset['_embed_permission'] = {
    read: true,
    update: true,
    create: true,
    publish: true,
    delete: true
  }
  editor['requestProps']['uidToEntry'][asset.uid] = asset;
  if (selection && !Range.isCollapsed(selection)) {
    Transforms.select(editor, selection);
    Transforms.delete(editor, { at: selection });
    Transforms.insertNodes(editor, node);
  } else if (selection) {
    Transforms.insertNodes(editor, node, { at: selection });
  } else {
    Transforms.insertNodes(editor, node, { at: Editor.end(editor, []) });
  }
}

export default class extends React.Component<ModalComponentProps> {
  state = {
    image: {
      url: '',
      filename: '',
      uid: ''
    }
  }

  setImage = (asset) => {
    this.setState({
      image: asset
    })
  }

  handleSubmit = () => {
    const url = this.state.image?.url;
    const asset_uid = this.state.image?.uid;
    const editor = this.props.editor;
    if (!url) {
    } else {
      if (this.props?.type === 'asset') {
        insertEmbedAsset({ editor, asset: this.state.image });
      } else {
        insertImage(editor, url, { asset_uid }, this.props.type)
      }
    }
    this.handleReset()
  }

  handleReset = () => {
    this.props.closeModal()
  }

  render() {
    const isImageSelected = !this.state.image.url
    const editor = this.props.editor
    const isEmbed = this.props.type === 'asset';
    const isPropsValid = checkProps({ editor })
    return (
      <div id="scrte-image-modal" data-testid={'scrte-image-modal'}>
        <ModalHeader title={isEmbed ? "Select Asset" : "Insert Image"} closeModal={this.props.closeModal} />

        <ModalBody className="modalBodyCustomClass">
          {isPropsValid ? (
            <SelectImage editor={this.props.editor} isEmbed={isEmbed} setImage={this.setImage} />
          ) : (
            <InvalidPropsMsg asset="Images" />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleReset} buttonType="light" testId={"asset-modal-reset"}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} disabled={isImageSelected} icon="CheckedWhite" testId={"asset-modal-submit"}>
              Add {isEmbed ? "Asset" : "Image"}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}
