//@ts-nocheck
import { Editor, Transforms, Range } from 'slate';
//@ts-ignore

export const tableHead = [
  {
    Header: 'Name',
    accessor: 'title',
    id: 'title',
    default: true
  },
  {
    Header: 'Last Modified',
    accessor: 'updated_by',
    id: 'uid',
    default: true,
    disableSortBy: true,
  },
  {
    Header: 'Publish Status',
    accessor: 'url',
    id: 'publish_details',
    default: true,
    disableSortBy: true,
  }
]

export const getEmbedLinkDetails = (editor) => {
  //@ts-ignore
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'reference' && n.attrs?.['display-type'] === 'link' });
  const selection = editor.selection || editor.savedSelection;
  const linkDetailObject = {
    href: '/',
    inNewTab: false,
    text: '',
    isActive: false
  }
  if (!selection) return linkDetailObject
  if (Boolean(link)) {
    const attrs: any = link[0].attrs
    linkDetailObject.href = attrs.href
    linkDetailObject.inNewTab = attrs.target === '_blank'
    linkDetailObject.text = Editor.string(editor, link[1]);
    linkDetailObject['isActive'] = true;
  } else if (Range.isExpanded(selection)) {
    linkDetailObject.text = Editor.string(editor, selection);
  }
  return linkDetailObject
}

export const updateLink = (editor, state, singleSelectedRowId, contentTypeUid) => {
  const selection = editor.selection || editor.savedSelection;
  //@ts-ignore
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'reference' && n.attrs?.['display-type'] === 'link', at: selection })
  const linkObj: any = link[0]
  const linkPath = link[1]
  let { href, inNewTab, text } = state.linkDetails;
  const updatedAttrs = {
    href,
    target: inNewTab ? '_blank' : '_self'
  };
  if (singleSelectedRowId.key !== '') {
    updatedAttrs['href'] = singleSelectedRowId.data.url;
    updatedAttrs['entry-uid'] = singleSelectedRowId.data.uid
    updatedAttrs['content-type-uid'] = contentTypeUid;
  }

  Transforms.select(editor, linkPath)
  Transforms.setNodes(
    editor,
    { attrs: { ...linkObj.attrs, ...updatedAttrs } },
    {
      //@ts-ignore
      match: (n) => n.type === 'reference' && n.attrs?.['display-type'] === 'link'
    }
  )
  Transforms.insertText(editor, text, { at: editor.selection })
  Transforms.collapse(editor, { edge: 'end' })
}