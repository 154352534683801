import React, { memo } from 'react'

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps
} from 'recharts'
import { CurveType } from 'recharts/types/shape/Curve'
import { Margin } from 'recharts/types/util/types'

export interface ILine {
  type?: CurveType;
  dataKey?: string;
  stroke?: string;
  strokeWidth?: string | number;
  connectNulls?: boolean;
}

export type LineGraphProps = {
  margin?: Margin;
  data: Record<string, any>[];
  gridStrokeWidth?: string | number;
  xAxisProps?: Pick<XAxisProps, "dataKey" | "domain" | "scale" | "type" | "tickFormatter">;
  yAxisProps?: Pick<YAxisProps, "domain" | "tickFormatter">;
  CustomTooltip?: any;
  lines: ILine[];
  isTooltipEnabled?: boolean;
}

const LineGraphComponent = (props: LineGraphProps) => {
  const {
    margin,
    data,
    gridStrokeWidth = DefaultValues.gridStrokeWidth,
    xAxisProps = {},
    yAxisProps = {},
    CustomTooltip,
    lines,
    isTooltipEnabled = true,
  } = props;
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={margin}
      >
        <CartesianGrid stroke={'#EDF1F7'} strokeWidth={gridStrokeWidth} />
        <XAxis
          dataKey={xAxisProps.dataKey}
          domain={xAxisProps.domain}
          scale={xAxisProps.scale}
          tickFormatter={xAxisProps.tickFormatter}
          style={styles.xAxis}
          type={xAxisProps.type}
        />
        <YAxis
          domain={yAxisProps.domain}
          tickFormatter={yAxisProps.tickFormatter}
          style={styles.yAxis}
        />
        {isTooltipEnabled && (
          <Tooltip content={CustomTooltip ?? undefined} cursor={false} />
        )}
        {lines.map((line, index) => (
          <Line
            key={index}
            type={line.type}
            dataKey={line.dataKey}
            stroke={line.stroke}
            connectNulls={line.connectNulls}
            strokeWidth={line.strokeWidth ?? DefaultValues.lineStrokeWidth}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

enum DefaultValues {
  gridStrokeWidth = 0.7,
  lineStrokeWidth = 2,
}

const styles = {
  xAxis: {
    fill: '#647696',
    stroke: "#EDF1F7",
    strokeWidth: 0.08,
    fontSize: '0.6875rem', // 11px
  },
  yAxis: {
    fill: '#647696',
    stroke: "#EDF1F7",
    strokeWidth: 0.05,
    fontSize: '0.6875rem', // 11px
  }
}

export default memo(LineGraphComponent)