import React from 'react'
import { Registry } from '@react-registry'
import { ImageOverlay } from '../../image/handleMouseDown'
import { elementCategories } from '../../../elementCategories'
import { Icon } from '../../../../../../../Icon2'
  // import { Icon } from '../../../../../../../index'
//@ts-ignore
export const AssetEmbed = (props) => <ImageOverlay {...props} type="asset" />

Registry.register(
  {
    title: 'Choose from assets',
    slashTitle: 'Asset',
    slashIconName: <Icon icon="Asset"/>,
    keywords: ['asset'],
    IngressComponent: AssetEmbed,
    category: elementCategories.IMAGE,
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true,
      inSlashToolbar: true
    },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'assetReference', registry: 'v2.element' }
)

export * from './deserializer'
