
import React from 'react';

const layerStyles: any = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};
export const DndPreview = (props) => {
    const { previewstyleclass, previewref } = props
    return (<div contentEditable={false} style={layerStyles}>
        <div id='scrte-dnd-preview' data-testid="scrte-dnd-preview" style={{ display: 'none', maxWidth: '800px', overflow: "hidden" }} ref={previewref}>
            <div style={{ opacity: 0.5 }} className={previewstyleclass?.['preview'] ? previewstyleclass['preview'] : null}>
                {props.children}
            </div>
        </div>
    </div>);
};
