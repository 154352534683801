import React, { useMemo } from 'react';
import { cx } from '@emotion/css';
import { useFocused, useSelected } from 'slate-react';
//@ts-ignore
import styles from '../style.module.css'
import { InfoIcon, EmbedBtnGrp } from '.'

export const ReferenceComponent = ({ content_type_uid, attributes, children, type, entry, handleEdit, handleDelete, editor }) => {
  const selected = useSelected();
  const focused = useFocused();
  const highlight = selected && focused;
  const isError = entry['error'];
  const publish_details = entry['publish_details'];
  const isDisabled = entry['disable'];
  const isPublished = useMemo(() => publish_details && publish_details.every(env => env['version'] === entry['_version']), [publish_details]);
  return (
    <span {...attributes} data-type={type} className={cx(styles['embed'], styles[`embed--${type}`], highlight && styles['embed--active'], isError && styles['embed--error'], styles[isPublished ? 'embed--published' : 'embed--draft'], isDisabled && styles['embed--disabled'])} data-testid={'reference-wrapper'}>
      <span className={styles['embed--title']}>
        <span data-testid={"embed-entry-title"}>{entry['title']}</span>
        <InfoIcon error={entry['error']} isDisabled={isDisabled} />
      </span>
      {type === 'block' && <span className={styles['embed--content_type_uid']} data-testid={"embed-ct-uid"}>{isDisabled ? "Content type Title" : content_type_uid}</span>}
      <div className={styles['embed--btn-group']}>
        <EmbedBtnGrp isDisabled={isDisabled} isError={isError} handleCaption={undefined} type={undefined} handleDelete={handleDelete} handleEdit={handleEdit} editor={editor} attributes={attributes} handleUnlink={undefined} />
      </div>
      {children}
    </span>
  )
}
