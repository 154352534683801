import { Registry } from '@react-registry'

export const tableRowDeserializer = (el) => {
    return {
        type: 'tr',
        attrs: {}
    }
}


Registry.register(tableRowDeserializer, { id: 'tr', registry: 'v2.deserializer' })