import { cloneDeep, isEmpty } from "lodash";
import { BaseEditor, Editor, Element, Node, NodeEntry, Transforms } from "slate";


export const wrapChildren = (editor: BaseEditor, whoseChildrenToWrap: NodeEntry<Node>, wrapWith: Element) => {
  const [node, path] = whoseChildrenToWrap
  if (!Element.isElement(node) || !Element.isElement(wrapWith)) return
  if (!isEmpty(wrapWith.children)) return

  let childrenRefs = Array.from(Node.children(editor, path)).map(([, childPath]) => Editor.pathRef(editor, childPath))

  for (let ref of childrenRefs) {
    Transforms.wrapNodes(editor, wrapWith, { at: ref.current })
    ref.unref()
  }
  childrenRefs = Array.from(Node.children(editor, path)).map(([, childPath]) => Editor.pathRef(editor, childPath))

  for (let ref of childrenRefs) {
    Transforms.mergeNodes(editor, { at: ref.current })
    ref.unref()
  }

  // ALTERNATIVE.

  // const newNode = cloneDeep(node)
  // wrapWith.children = newNode.children
  // newNode.children = [wrapWith]

  // Editor.withoutNormalizing(editor, () => {
  //   Transforms.removeNodes(editor, { at: path })
  //   Transforms.insertNodes(editor, newNode, { at: path })
  // })
}