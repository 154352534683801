import React from 'react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../styles.module.css'

function GridRow({ children, attributes }) {
  return (
    <div data-testid="row" {...attributes} className={styles['scrte-grid-row']}>
      {children}
    </div>
  )
}

const ComponentMeta = {
  Component: GridRow,
  dndOptions: {
    DisableDND: true,
    DisableSelectionHalo: true,
  },
}

const registryCondition = {
  id: 'row',
  registry: 'element',
}

Registry.register(ComponentMeta, registryCondition)

export default GridRow
