import React from 'react'
import {
  Title,
  SingleLineText,
  MultiLineText,
  Url,
  RichTextEditor,
  Markdown,
  SelectField,
  ModularBlocks,
  Number,
  Boolean,
  DateFieldProp,
  File,
  Group,
  Link,
  Global,
  Custom,
  Reference,
  JsonRte,
  ExperienceContainer
} from './ContentTypeFieldProperties'

export const fieldForm = (
  schema: any,
  options,
  parentInfo,
  uniqueKey = 'key.0',
  globalFieldOptions: any,
  extensionFieldOptions: any,
  referenceContentType: any,
  jsonRteContentType: any,
  rteContentType,
  handleChange: any,
  type: 'basic' | 'advanced',
  selectAsNumberText: any,
  selectAsKeyValue: any,
  selectOptionsLimit: any,
  currentFieldPath: any,
  enableEmbeddedObj: boolean,
  hasNonLocalizablePlan: boolean,
  isCTBuilder: boolean,
  t?: any,
  setOptions?: any,
  isNonLocalizable?: boolean
) => {
  const changehndlr = () => {
    handleChange()
  }

  const setCTOptions = (options) => {
    setOptions(options)
  }

  let fieldName = schema.fieldName || schema.field_name
  switch (fieldName) {
    case 'Title':
      return (
        <Title
          type={type}
          isCTBuilder={isCTBuilder}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'URL':
      return (
        <Url
          type={type}
          schema={schema}
          options={options}
          setOptions={setCTOptions}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Single Line Textbox':
      return (
        <SingleLineText
          type={type}
          isCTBuilder={isCTBuilder}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          options={options}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Multi Line Textbox':
      return (
        <MultiLineText
          type={type}
          isCTBuilder={isCTBuilder}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Rich Text Editor':
      return (
        <RichTextEditor
          type={type}
          enableEmbeddedObj={enableEmbeddedObj}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          options={options}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
          {...rteContentType}
        />
      )
    case 'JSON Rich Text Editor':
      return (
        <JsonRte
          currentFieldPath={currentFieldPath}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          enableEmbeddedObj={enableEmbeddedObj}
          type={type}
          options={options}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
          {...jsonRteContentType}
        />
      )
    case 'Markdown':
      return (
        <Markdown
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Select':
      return (
        <SelectField
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          selectAsNumberText={selectAsNumberText}
          selectAsKeyValue={selectAsKeyValue}
          selectOptionsLimit={selectOptionsLimit}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Modular Blocks':
      return (
        <ModularBlocks
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Number':
      return (
        <Number
          type={type}
          options={options}
          isCTBuilder={isCTBuilder}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Boolean':
      return (
        <Boolean
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Date':
      return (
        <DateFieldProp
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'File':
      return (
        <File
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Group':
      return (
        <Group
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )

    case 'Link':
      return (
        <Link
          type={type}
          isCTBuilder={isCTBuilder}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          options={options}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Global':
      return (
        <Global
          type={type}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          options={options}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          globalFieldOptions={globalFieldOptions}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'Custom':
      return (
        <Custom
          type={type}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          options={options}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          extensionFieldOption={extensionFieldOptions}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    case 'ExperienceContainer':
      return (
        <ExperienceContainer
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          extensionFieldOption={extensionFieldOptions}
          t={t}
        />
      )
    case 'Reference':
      return (
        <Reference
          type={type}
          options={options}
          hasNonLocalizablePlan={hasNonLocalizablePlan}
          parentInfo={parentInfo}
          schema={schema}
          onChange={changehndlr}
          uniqueKey={uniqueKey}
          reference={referenceContentType}
          t={t}
          isNonLocalizable={isNonLocalizable || false}
        />
      )
    default:
      return null
  }
}

export const addFieldName = (schema: any, parentInfo: any) => {
  schema.fieldIdentifier = schema.fieldIdentifier || new Date().getTime()
  schema.fieldUid = parentInfo.parentFieldUid ? parentInfo.parentFieldUid + '.' + schema.uid : schema.uid
  if (schema.display_type) {
    schema.fieldName = 'Select'
  } else {
    if (schema.field_metadata && schema.field_metadata.extension) {
      schema.fieldName = 'Custom'
    } else {
      switch (schema.data_type) {
        case 'text':
          if (schema.field_metadata) {
            if (schema.field_metadata._default) {
              if (schema.uid === 'title') {
                schema.fieldName = 'Title'
              }
              if (schema.uid === 'url') {
                schema.fieldName = 'URL'
              }
            } else if (schema.field_metadata.multiline) {
              console.log('iam in add line, ', schema)
              schema.fieldName = 'Multi Line Textbox'
            } else if (schema.field_metadata.allow_rich_text) {
              schema.fieldName = 'Rich Text Editor'
              schema.field_metadata = schema.field_metadata || {}
            } else if (schema.field_metadata.markdown) {
              schema.fieldName = 'Markdown'
            } else {
              schema.fieldName = 'Single Line Textbox'
            }
          }
          break
        case 'json':
          if (schema.field_metadata && schema.field_metadata.allow_json_rte) schema.fieldName = 'JSON Rich Text Editor'
          break
        case 'number':
          schema.fieldName = 'Number'
          break
        case 'isodate':
          schema.fieldName = 'Date'
          break
        case 'boolean':
          schema.fieldName = 'Boolean'
          break
        case 'reference':
          schema.fieldName = 'Reference'
          break
        case 'link':
          schema.fieldName = 'Link'
          break
        case 'file':
          schema.fieldName = 'File'
          if (schema.data_type == 'file') {
            if (schema.min && schema.min >= 701) {
              schema.min = parseFloat((schema.min / (1024 * 1024)).toFixed(3)) //Number((schema.min / (1024 * 1024)).toFixed(3))
            }
            if (schema.max && schema.max >= 701) {
              schema.max = parseFloat((schema.max / (1024 * 1024)).toFixed(3)) //Number((schema.max / (1024 * 1024)).toFixed(3))
            }
          }
          break
        case 'blocks':
          schema.fieldName = 'Modular Blocks'
          break
        case 'group':
          schema.fieldName = 'Group'
          if (schema.multiple) {
            schema.isInitialMultiple = true
          } else {
            schema.isInitialSingle = true
          }
          break
        case 'experience_container':
          schema.fieldName = 'ExperienceContainer'
          break
        case 'global_field':
          schema.fieldName = 'Global'
          if (schema.multiple) {
            schema.isInitialMultiple = true
          } else {
            schema.isInitialSingle = true
          }
          break
      }
    }
  }
}

export const wrapperStyle = {
  top: '10px',
  left: 'auto !important',
  right: 0,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  background: 'transparent !important'
}

export const fieldUidRegex = (uid: any, schema: any) => {
  const uidRegexErr = new RegExp('^[a-z][_a-z0-9]*$')
  schema.uid = uid
  return !uidRegexErr.test(schema.uid)
}
