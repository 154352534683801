import React from 'react'
import { Icon } from '../../../../../../../../index'
import styles from '../../../style.module.css'

export function AssetBreadCrumb({ breadcrumbs }) {
  return (
    <div className={styles['breadcrumb']} data-testid="asset-breadcrumb">
      <Icon icon='Folder' className={styles['mr-5']} />
      {breadcrumbs.map((item, idx) => (
        <span key={idx} style={{ cursor: 'pointer' }} onClick={() => item.action(idx)}>
          {item.displayName} /&nbsp;
        </span>
      ))}
    </div>
  )
}
