import { Registry } from '@react-registry'


export const withSelection = (editor) => {
    editor.variable = {
        selection: null
    };
    editor.setSelection = () => {
        editor.variable.selection = editor.selection;
    }
    editor.getSelection = () => {
        return editor?.variable?.selection;
    }
    return editor;
}

Registry.register(withSelection, {
    id: 'withSelection',
    registry: 'plugin'
})