//@ts-nocheck
import { Registry } from '@react-registry'
import { Editor, Range, Transforms, Node, Element, Text, Path } from 'slate'
import { v4 } from "uuid"
import { outdentListItem, exitList } from './utils'
import { LIST_TYPES } from '../utils'
import { cloneDeep } from 'lodash'
import { JsonNode } from 'components/RichTextEditor/SuperChargedRte/utils/types'

export const withListItem = (editor) => {
  const { deleteBackward, deleteForward, normalizeNode, insertBreak } = editor

  editor.normalizeNode = (entry) => {
    if (editor.stopNormalizeNode) {
      return
    }
    // convert stranded li to paragraph
    if (entry[0] && Element.isElement(entry[0]) && entry[0].type === 'li' && entry[1]?.length >= 1) {
      const parent: any = Node.get(editor, entry[1].slice(0, entry[1].length - 1))
      if (Element.isElement(parent) && !LIST_TYPES.includes(String(parent.type))) {
        Transforms.setNodes(editor, { type: 'p' }, { at: entry[1] })
      }
    }

    // convert child of ol and ul to li if not already
    if (entry[0] && LIST_TYPES.includes(entry[0].type) && entry[0]?.children?.length) {
      for (const [i, child] of entry[0].children.entries()) {
        if (!(Element.isElement(child) && (child.type === 'li' || LIST_TYPES.includes(String(child.type))))) {
          if (!child.hasOwnProperty('text') && child?.type !== "fragment") {
            Transforms.wrapNodes(editor, { type: "li", attrs: {}, uid: v4().split('-').join('') }, { at: [...entry[1], i] })
          }
          //Transforms.setNodes(editor, { type: 'li' }, { at: [...entry[1], i] })
        }
      }
    }

    if (entry[0] && LIST_TYPES.includes(entry[0].type) && entry[0]?.children.length >= 2) {
      let child = []
      child.push(entry[0].children[0])
      let fixed = false
      for (var i = 0, j = 1; i < entry[0].children.length - 1; i++, j++) {
        const el = entry[0].children[j]
        if (LIST_TYPES.includes(el.type) && el.type === entry[0].children[i].type) {
          let prev = child.pop()
          let orderChild = [...prev.children, ...el.children]
          let newChild = { ...prev }
          newChild.children = orderChild
          child.push(newChild)
          fixed = true
        } else {
          child.push(el)
        }
      }
      if (fixed) {
        let newNode = { ...entry[0] }
        newNode.children = child
        Transforms.removeNodes(editor, { at: entry[1] })
        Transforms.insertNodes(editor, newNode, { at: entry[1] })
        return
      }
    }
    if (LIST_TYPES.includes(entry[0].type) && entry[0].children.length === 0) {
      Transforms.removeNodes(editor, { at: entry[1] })
      return
    }

    if(entry[0] && Element.isElement(entry[0]) && entry[0].type === 'li'){
      const liNode = entry[0]
      if (liNode.children[0].type === 'fragment') {
        //  append the children of single nested list to previous li
        if(LIST_TYPES.includes(liNode.children?.[0]?.children?.[0]?.type)){
          appendListToPreviousListItem(editor, entry)
          Transforms.removeNodes(editor, {at: entry[1]})
          return
        }
      }
      // Every li should have a fragment as first child.
      else{
        const newLi = cloneDeep(entry[0])
        newLi.children = [{type: 'fragment', children: newLi.children}]
        Transforms.removeNodes(editor, {at: entry[1]})
        Transforms.insertNodes(editor, newLi, {at: entry[1]})
        Transforms.select(editor, entry[1])
        Transforms.collapse(editor, {edge: 'end'})
      }
      return

    }
    // If li contains two consecutive fragments remove one
    if(entry[0] && Element.isElement(entry[0]) && entry[0].type === 'fragment'){
      
      if(Node.parent(editor, entry[1]).type === 'li' && entry[0].children.length === 1 && entry[0].children[0].type === 'fragment'){
        Transforms.unwrapNodes(editor, {at :entry[1]})
      }
    }

    normalizeNode(entry)
  }
  editor.deleteBackward = (data) => {
    const { selection } = editor
    if (selection) {
      const [li] = Editor.nodes(editor, { match: (n) => n.type === 'li', mode: 'lowest' })

      if (li && Range.isCollapsed(selection) && selection.anchor.offset === 0) {
          if (li[1].length >= 4) {
          // ol -> li -> fragment -> ol 
          const grandparent: any = Node.get(editor, li[1].slice(0, -4))
          if (LIST_TYPES.includes(grandparent.type)) {
            outdentListItem(editor, li)
          } else {
            Transforms.unwrapNodes(editor, {
              match: (n: any) => LIST_TYPES.includes(n.type),
              split: true,
            })
            Transforms.setNodes(editor, { type: 'p' })
          }
          return
        } else {
          // if list item is not first element then merge it with previous one
          if (li[1][li[1].length - 1]) {
            deleteBackward(data)
          }
          // remove the list marker
          else {
            Transforms.unwrapNodes(editor, {
              match: (n: any) => LIST_TYPES.includes(n.type),
              split: true,
            })
            Transforms.setNodes(editor, { type: 'p' })
          }
          return
        }
      }

      if (li && Range.isCollapsed(selection) && selection.anchor.offset === 0) {
        const currentPath = selection.anchor.path.slice(0, selection.anchor.path.length - 1)
        const currentPathLastIndex = currentPath.length - 1

        if (currentPath[currentPathLastIndex]) {
          const prevSiblingPath = [...currentPath.slice(0, currentPathLastIndex), currentPath[currentPathLastIndex] - 1]
          const prevSibling: any = Node.get(editor, prevSiblingPath)

          const getLastChild = (prevSiblingPath) => {
            let currentPath = [...prevSiblingPath]
            let currentNode = Node.get(editor, currentPath)
            let offset = 0

            while (true) {
              if (Element.isElement(currentNode)) {
                currentPath.push(currentNode.children.length - 1)
                currentNode = Node.get(editor, currentPath)
                continue
              } else if (Text.isText(currentNode)) {
                offset = currentNode.text.length
                break
              } else {
                // console.error('List must have child as li')
                break
              }
            }
            return { path: currentPath, offset }
          }

          if (LIST_TYPES.includes(prevSibling.type)) {
            const parent = Node.parent(editor, currentPath)
            if (parent.children.length - 1 > currentPath[currentPathLastIndex]) {
              const nextSibling: any = Node.get(editor, [
                ...currentPath.slice(0, currentPathLastIndex),
                currentPath[currentPathLastIndex] + 1,
              ])

              if (LIST_TYPES.includes(nextSibling.type) && prevSibling.type === nextSibling.type) {
                const cursorPoint = getLastChild(prevSiblingPath)

                if (cursorPoint.offset) {
                  deleteBackward(data)
                } else {
                  const replacementPath = cursorPoint.path.slice(0, cursorPoint.path.length - 1)
                  const replacementNode = {
                    type: 'li',
                    children: [...Node.get(editor, currentPath).children],
                  }

                  Transforms.removeNodes(editor, { at: replacementPath })
                  Transforms.removeNodes(editor, { at: currentPath })
                  Transforms.insertNodes(editor, replacementNode, { at: replacementPath })
                }
                const modifiedPrevSibling = Node.get(editor, prevSiblingPath)
                const newChildren = [...modifiedPrevSibling.children, ...nextSibling.children]
                Transforms.removeNodes(editor, { at: currentPath })
                Transforms.removeNodes(editor, { at: prevSiblingPath })
                Transforms.insertNodes(
                  editor,
                  { type: prevSibling.type, children: newChildren },
                  { at: prevSiblingPath }
                )

                Transforms.select(editor, cursorPoint)
                return
              }
            }
          }
        }
      }
    }

    deleteBackward(data)
  }

  editor.insertBreak = () => {
    if (!exitList(editor)) {
      insertBreak()
    }
  }

  return editor
}

Registry.register(withListItem, { id: 'withListItem', registry: 'plugin' })

const appendListToPreviousListItem = (editor ,li) => {
  const [previousLi, previousLiPath] = Editor.previous(editor, { at: li[1] })
  Transforms.select(editor, previousLiPath)
  Transforms.collapse(editor, {edge: 'end'})
  if (!Element.isElement(previousLi)) return

  const [[nextList]] = Editor.nodes<Element>(editor, {
    at: li[1],
    match: (node: JsonNode, path) =>
      Element.isElement(node) && LIST_TYPES.includes(node.type) && Path.isAncestor(li[1], path)
  })

  const [previousListEntry] = Editor.nodes<Element>(editor, {
    at: previousLiPath,
    match: (node: JsonNode, path) =>
      Element.isElement(node) && LIST_TYPES.includes(node.type) && Path.isAncestor(previousLiPath, path)
  })

  let previousListPath = [...previousLiPath, previousLi.children.length]
  let newPreviousList = nextList

  if(previousListEntry){
    previousListPath = previousListEntry[1]

    newPreviousList = cloneDeep(previousListEntry[0])

    newPreviousList.children = [...previousListEntry[0].children, ...nextList.children]
  }

  Editor.withoutNormalizing(editor, () => {
    if(previousListEntry){
      Transforms.removeNodes(editor, { at: previousListPath })
    }

    Transforms.insertNodes(editor, newPreviousList, { at: previousListPath })
    const span = [
      [...previousLiPath, 0],
      [...previousLiPath, previousLi.children.length]
    ]
    if(previousListEntry){
      Transforms.wrapNodes(editor, { type: 'fragment' }, { at: span })
    }
  })
}