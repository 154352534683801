import React, { useState, useEffect } from 'react';

import cn from 'classnames';

import './Tabs.css';

type tabInfoArray = {
    id: string
    title?: string
    componentTitle?: React.ReactNode
    componentData?: React.ReactNode
    data?: string
    callBack?: Function | undefined,
    disabled?: boolean
};

export type tabProps = {
    tabInfo: Array<tabInfoArray>
    /**
   * A Tab can be a controlled component
   */
    activeTab?: string | undefined
    type?: 'primary' | 'secondary'
    className?: string,
    testId?: string
};

type tabCreatorProps = {
    // callBack: (event: any) => void
    id: any
    tabHeader: string | undefined
    tabComponentHeader: React.ReactNode | undefined,
    disabled?: boolean
    TabIndex: number
    type?: 'primary' | 'secondary'
};

type tabDataCreatorProps = {
    tabData: string | undefined
    tabComponent: React.ReactNode | undefined
};

const TabCreator = (props: tabCreatorProps) => {
    let displayHeader: any;

    if (props.tabHeader !== undefined) {
        displayHeader = props.tabHeader;
    }
    else if (props.tabComponentHeader !== undefined) {
        displayHeader = props.tabComponentHeader;
    }
    else {
        displayHeader = null;
    };
    return displayHeader === null ? null : displayHeader

}

const TabDataCreator = (props: tabDataCreatorProps) => {
    let displayData: any;
    if (props.tabData !== undefined) {
        displayData = props.tabData;
    }
    else if (props.tabComponent !== undefined) {
        displayData = props.tabComponent;
    }
    else {
        displayData = null;
    };
    return displayData === null ? null : <div>{displayData}</div>
};

const TabIndexSetter = (Tabs: Array<tabInfoArray>, activeTab: string | undefined) => {
    let index: any;
    if (activeTab === undefined) {
        index = 0;
    }
    else {
        Tabs.map((indiTabs: any) => {
            if (indiTabs.id === activeTab) {
                index = Tabs.indexOf(indiTabs);
            };
        });
    };
    return index;
};

const Tabs = (props: tabProps) => {
    const [TabIndex, setTabIndex] = useState(TabIndexSetter(props.tabInfo, props.activeTab))
    const [prevActiveTab, setPrevActiveTab] = useState(props.activeTab)
    const TabInfo = props.tabInfo;
    const { type, className, testId } = props;
    const TabType = `Tab--${type}`;
    const TabItemType = `Tab__item--${type}`;
    const classNames = cn('Tab', [TabType], className);


    useEffect(() => {
        if (props.activeTab !== undefined) {
            if (prevActiveTab !== props.activeTab) {
                setTabIndex(TabIndexSetter(TabInfo, props.activeTab));
                setPrevActiveTab(props.activeTab);
            };
        };
    }, [props.activeTab]);

    const handleClick = (event: any) => {
        let id = event.currentTarget.id;
        if (!props.activeTab) {
            setTabIndex(parseInt(event.currentTarget.id));
        }
        if (TabInfo[event.currentTarget.id].callBack) {
            TabInfo[event.currentTarget.id].callBack(id);
        };
    };

    return (
        <div className="TabWrapper" data-test-id={testId}>
            <div className={classNames}>
                {TabInfo.map((tabData, key: any) => {
                    return <div
                        className={`Tab__item  ${tabData.disabled !== undefined && tabData.disabled === true ? 'Tab__disabled' : ''} ${TabItemType} ${key === TabIndex ? 'Tab__selected' : ''}`}
                        key={key}
                        onClick={(tabData.disabled !== undefined && tabData.disabled === true) ? undefined : handleClick}
                        id={key}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !(tabData.disabled !== undefined && tabData.disabled === true)) {
                                handleClick(e)
                            }
                        }}
                        tabIndex={(tabData.disabled !== undefined && tabData.disabled === true) ? -1 : 0}>
                        <TabCreator disabled={tabData.disabled} tabComponentHeader={tabData.componentTitle} tabHeader={tabData.title} id={key} TabIndex={TabIndex} type={props.type} /></div>
                })}
            </div>
            {TabInfo && TabInfo.length > 0 ?
                <div className="Tab__Info">
                    <TabDataCreator tabData={TabInfo[TabIndex].data} tabComponent={TabInfo[TabIndex].componentData} />
                </div> : ''}
        </div>
    );
};

Tabs.defaultProps = {
    type: 'primary',
    tabInfo: [],
    testId: 'cs-tabs'
} as Partial<tabProps>;

export default Tabs;