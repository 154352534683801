import { Registry } from '@react-registry'
import { Editor } from 'slate'


export const withSoftLineBreak = (editor) => {
  const { insertText } = editor
  editor.insertText = (text) => {
    const [node] = Editor.node(editor, editor.selection)
    // @ts-ignore
    if (node.break) {
      editor.insertNode({ text: text })
      return
    }
    insertText(text)
  }
  return editor
}
Registry.register(withSoftLineBreak, { id: 'withSoftLineBreak', registry: 'v2.plugin' })
