import React from 'react'
import { cx, css } from '@emotion/css';
import { InfiniteScrollTable, Icon } from '../../../../../index'
import { formatTime, getAssetType } from '../../../commons/util';
import styles from './index.module.css'

const GetIcon = ({ data }) => {
  let icon = getAssetType(data.content_type);
  return (
    <React.Fragment>
      <span className={cx(styles['list-icon'], css`
        background: ${data.url ? `url(${data.url})` : 'inherit'};
      `)}>
        {icon && <Icon icon={icon} />}
      </span>
      {data.name ? data.name : data.filename}
    </React.Fragment>
  )
}

export const ListView = ({
  state,
  readOnly,
  selectedAssets,
  fetchOnChange,
  loadMore,
  handleClick,
  loading,
  users = {},
  multiple,
  assetKey,
  selectedAssestMap,
  setSelectedAssetsMap,
  updateSelection
}: any) => {
  const isMultiple = multiple
  let selectedAssetsObj: any = {}
  selectedAssets?.forEach(asset => selectedAssetsObj[asset.uid] = true)

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (data) => {
        return (
          <div className='flex-v-center'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <GetIcon data={data} />
            </div>
          </div>
        )
      },
      default: false,
      addToColumnSelector: true,
    },
    {
      Header: 'Unique Id',
      accessor: 'uid',
      id: 'uid',
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
    {
      Header: 'Last Modified By',
      id: 'updated_by',
      accessor: (data) => <div className={'last-updated-by'}><span>{users[data.updated_by] || 'Anonymous'}</span> <span className='last-updated-by-time'>{formatTime(data.updated_at)}</span></div>,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
  ]

  const getSelectedRow = (_singleSelectedRowIds) => {
    let dataObj = state.assets.filter((obj: any) => {
      return _singleSelectedRowIds.includes(obj.uid)
    });
    let updatedObj = { ...selectedAssestMap, [assetKey]: dataObj }
    setSelectedAssetsMap(updatedObj)
    let arr: any = []
    Object.values(updatedObj).forEach((ctArr: any) => {
      if (ctArr.length) {
        arr = [...arr, ...ctArr]
      }
    })
    updateSelection(arr)
  }

  const getOnlySelectedRows = () => {
    if (readOnly) {
      return (
        <InfiniteScrollTable
          tableHeight={290}
          data={selectedAssets}
          columns={columns}
          uniqueKey={'uid'}
          fetchTableData={() => { }}
          loadMoreItems={loadMore}
          loading={loading}
          totalCounts={selectedAssets.length}
          itemStatusMap={state.itemStatusMap}
          minBatchSizeToFetch={30}
          name={{ singular: 'Asset', plural: 'Assets' }}
        />
      )
    }
    else return null
  }

  return (
    <div style={{ height: '290px', overflow: "hidden" }} data-testid="list-view" className='scrte-infinite-list'>
      {!readOnly && (isMultiple ?
        <InfiniteScrollTable
          rowSelectCheckboxProp={{ key: 'is_dir', value: false }}
          tableHeight={290}
          data={state.assets}
          columns={columns}
          uniqueKey={'uid'}
          fetchTableData={fetchOnChange}
          loading={loading}
          totalCounts={state.count}
          loadMoreItems={loadMore}
          itemStatusMap={state.itemStatusMap}
          minBatchSizeToFetch={30}
          initialSortBy={[{ id: 'name', desc: true }]}
          columnSelector={false}
          fullRowSelect={true}
          initialSelectedRowIds={selectedAssetsObj}
          isRowSelect={true}
          onRowSelectProp={[]}
          getSelectedRow={getSelectedRow}
          maxSelect={typeof multiple !== 'boolean' && multiple?.max}
          name={{ singular: 'Asset', plural: 'Assets' }}
          onRowHoverText={(data: any) => data.errorMessage}
          rowDisableProp={{ key: 'invalid_asset', value: true }}
          onRowClick={handleClick}
        />
        : <InfiniteScrollTable
          tableHeight={290}
          data={state.assets}
          columns={columns}
          uniqueKey={'uid'}
          fetchTableData={fetchOnChange}
          loadMoreItems={loadMore}
          loading={loading}
          totalCounts={state.count}
          onRowClick={handleClick}
          singleSelectedRowId={selectedAssets?.length && selectedAssets[0].uid}
          itemStatusMap={state.itemStatusMap}
          columnSelector={false}
          minBatchSizeToFetch={30}
          name={{ singular: 'Asset', plural: 'Assets' }}
          rowDisableProp={{ key: 'invalid_asset', value: true }}
        />
      )}
      {getOnlySelectedRows()}
    </div>
  )
}
