import React from 'react'
import { Registry } from '@react-registry'

import { InlineCodeIcon } from '../../utils/Icon'
import { leafCategories } from '../leafCategories'

//@ts-ignore
import styles from './style.module.css'

export const InlineCode = (props) => (
  <span data-type="inlineCode" className={styles['inline-code']}>
    {props['children']}
  </span>
)

Registry.register(
  {
    Component: InlineCode,
    title: 'Inline code',
    iconName: <InlineCodeIcon />,
    shortcut: { key: 'mod+shift+k' },
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE_SEE_MORE,
    inBasicToolbar: true,
  },
  { id: 'inlineCode', registry: 'leaf' }
)
