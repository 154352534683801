import React from 'react'
import { Registry } from '@react-registry'


const DIV = ({ attributes, children }) => {

    return (
        <div {...attributes}>{children}</div>
    )
}

export default DIV

Registry.register(
    {
        Component: DIV,
        isContentStackElement: true,
        dndOptions: {
            DisableDND: true,
            DisableSelectionHalo: true
        },
        toolbar: {
            inHoveringToolbar: false
        },
    },
    { id: 'div', registry: 'v2.element' }
)