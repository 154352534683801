import React from 'react';

export const BoldIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5609 7.31641C12.5259 6.60877 13.1231 5.42833 12.9785 4.10878C12.7809 2.30548 11.1432 0.99999 9.32916 1H2V2L3.44721 2.72361C3.786 2.893 4 3.23926 4 3.61803V12.382C4 12.7607 3.786 13.107 3.44721 13.2764L2 14V15H9.82266C11.9265 15 13.8031 13.4525 13.9845 11.3565C14.1409 9.55075 13.0963 7.96788 11.5609 7.31641ZM7 3H8C9.10455 3 10 3.89539 10 5C10 6.10455 9.10455 7 8 7H7V3ZM9 13H7V9H9C10.1046 9 11 9.89539 11 11C11 12.1046 10.1046 13 9 13Z" fill="#647696" />
    </svg>
  )
}


export const CodeIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3998 10.9998C13.0132 11.3864 12.3864 11.3864 11.9998 10.9998C11.6132 10.6132 11.6132 9.9864 11.9998 9.59981L13.5998 7.9998L11.9998 6.3998C11.6132 6.0132 11.6132 5.3864 11.9998 4.9998C12.3864 4.61321 13.0132 4.61321 13.3998 4.9998L15.6998 7.2998C16.0998 7.6998 16.0998 8.2998 15.6998 8.6998L13.3998 10.9998Z" fill="#647696" />
      <path d="M4 10.9998C3.6134 11.3864 2.9866 11.3864 2.6 10.9998L0.3 8.6998C-0.1 8.2998 -0.1 7.6998 0.3 7.2998L2.6 4.99981C2.9866 4.61321 3.6134 4.61321 4 4.9998C4.3866 5.3864 4.3866 6.01321 4 6.3998L2.4 7.9998L4 9.5998C4.3866 9.9864 4.3866 10.6132 4 10.9998Z" fill="#647696" />
      <path d="M5.99976 15.0001C5.89976 15.0001 5.79976 15.0001 5.69976 14.9001C5.19976 14.7001 4.89976 14.2001 5.09976 13.6001L9.09976 1.60015C9.29976 1.10015 9.79976 0.800146 10.3998 1.00015C10.8998 1.20015 11.1998 1.70015 10.9998 2.30015L6.99976 14.3001C6.79976 14.7001 6.39976 15.0001 5.99976 15.0001Z" fill="#647696" />
    </svg>

  )
}


export const DividerIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="6.5" width="16" height="3" fill="#647696" />
    </svg>

  )
}


export const ImageIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 16H1C0.4 16 0 15.6 0 15V1C0 0.4 0.4 0 1 0H15C15.6 0 16 0.4 16 1V15C16 15.6 15.6 16 15 16ZM2 14H14V2H2V14Z" fill="#647696" />
      <path d="M6 4C6.6 4 7 4.4 7 5C7 5.6 6.6 6 6 6C5.4 6 5 5.6 5 5C5 4.4 5.4 4 6 4Z" fill="#647696" />
      <path d="M3 12L5 8L7 10L10 6L13 12H3Z" fill="#647696" />
    </svg>

  )
}


export const ItalicIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2V0H6V2H7.27066C7.57493 2 7.80867 2.26949 7.76563 2.57071L6.25553 13.1414C6.18515 13.6341 5.76323 14 5.26558 14H4V16H10V14H8.72934C8.42506 14 8.19133 13.7305 8.23436 13.4293L9.74447 2.85858C9.81485 2.36593 10.2368 2 10.7344 2H12Z" fill="#647696" />
    </svg>

  )
}


export const QuoteIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M16 8.4926L12.1193 8.4926C12.1856 5.66431 12.9878 5.34326 13.9953 5.25163L14.3838 5.20347L14.3838 2.09349L13.936 2.11749C12.6204 2.19151 11.1658 2.42868 10.1954 3.62666C9.34473 4.67684 8.9697 6.39256 8.9697 9.02633L8.9697 13.9067L16 13.9067L16 8.4926Z" fill="#647696" />
        <path d="M7.03027 13.9067L7.03027 8.4926L3.20134 8.4926C3.26769 5.66431 4.04393 5.34326 5.05144 5.25163L5.41411 5.20347L5.41411 2.09349L4.99213 2.11749C3.67649 2.19151 2.20894 2.42868 1.23851 3.62666C0.387929 4.67684 -3.02208e-05 6.39256 -2.99906e-05 9.02633L-2.95639e-05 13.9067L7.03027 13.9067Z" fill="#647696" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export const OrderedListIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 1H6V3H16V1Z" fill="#475161" />
      <path d="M16 7H6V9H16V7Z" fill="#475161" />
      <path d="M16 13H6V15H16V13Z" fill="#475161" />
      <path d="M2.4039 9.62843L2.39494 9.59405L2.97708 8.96236C3.35533 8.55072 3.61471 8.23625 3.75565 8.02742C3.8964 7.81864 3.96683 7.57623 3.96683 7.30446C3.96683 6.90071 3.83433 6.58158 3.5695 6.34901C3.30458 6.11672 2.93573 6 2.46302 6C2.01788 6 1.66035 6.13811 1.39049 6.41478C1.12064 6.69145 0.990608 7.02453 1.00053 7.42242L1.0065 7.43592H1.98143V7.436C1.98143 7.2606 2.0243 7.0811 2.10999 6.9541C2.19568 6.82702 2.31336 6.76563 2.46306 6.76563C2.63046 6.76563 2.75606 6.81586 2.8398 6.91426C2.92346 7.01281 2.96537 7.14535 2.96537 7.31084C2.96537 7.42704 2.92398 7.5642 2.84127 7.72167C2.75857 7.87927 2.61345 8.05862 2.43805 8.25951L1.03921 9.7134V10.33H4.02089V9.62843H2.4039Z" fill="#475161" />
      <path d="M3.00834 3.54413V0L1 0.300035V1.01184H1.96052V3.54413H1V4.33H3.96885V3.54413H3.00834Z" fill="#475161" />
      <path d="M3.85084 14.1565C3.72344 13.9876 3.54693 13.8623 3.32121 13.7793C3.51989 13.6886 3.67861 13.5628 3.79723 13.4018C3.91592 13.2407 3.97518 13.0645 3.97518 12.8737C3.97518 12.4898 3.83819 12.1931 3.56429 11.9839C3.29039 11.7744 2.92484 11.6699 2.46755 11.6699C2.07398 11.6699 1.73929 11.7741 1.4634 11.9825C1.18751 12.1909 1.05531 12.4778 1.06694 12.8135L1.07279 12.8459H2.02197C2.02197 12.6741 2.07008 12.6206 2.16661 12.5472C2.26306 12.4739 2.375 12.4298 2.50228 12.4298C2.66435 12.4298 2.78777 12.4714 2.87275 12.5621C2.95761 12.6528 3.00002 12.7627 3.00002 12.8959C3.00002 13.0637 2.95277 13.2029 2.85831 13.2974C2.76373 13.392 2.62873 13.4471 2.45315 13.4471H1.99474V14.1343H2.45315C2.64805 14.1343 2.79897 14.1918 2.90607 14.2853C3.01317 14.3789 3.0667 14.5334 3.0667 14.7378C3.0667 14.8845 3.01551 15.0087 2.9134 15.1052C2.8111 15.2016 2.67411 15.2511 2.50239 15.2511C2.3519 15.2511 2.2242 15.1863 2.11897 15.0947C2.01374 15.0031 1.96126 14.9073 1.96126 14.7355H1.00619L1.00046 14.7726C0.990818 15.1662 1.13401 15.4765 1.43015 15.6839C1.7263 15.8913 2.07211 15.9999 2.46755 15.9999C2.92675 15.9999 3.30389 15.892 3.5991 15.6711C3.8943 15.4503 4.04179 15.1481 4.04179 14.7622C4.04179 14.5268 3.97823 14.3254 3.85084 14.1565Z" fill="#475161" />
    </svg>


  )
}

export const UnorderedListIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="#475161" />
      <path d="M2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6C0.89543 6 0 6.89543 0 8C0 9.10457 0.89543 10 2 10Z" fill="#475161" />
      <path d="M2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.89543 12 0 12.8954 0 14C0 15.1046 0.89543 16 2 16Z" fill="#475161" />
      <path d="M16 1H6V3H16V1Z" fill="#475161" />
      <path d="M16 7H6V9H16V7Z" fill="#475161" />
      <path d="M16 13H6V15H16V13Z" fill="#475161" />
    </svg>


  )
}

export const UndoIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 8.00023C16 11.7798 12.9243 14.8549 9.14528 14.8549C8.45642 14.8549 7.89824 14.2968 7.89824 13.6079C7.89824 12.919 8.45642 12.3609 9.14528 12.3609C11.5491 12.3609 13.5059 10.4045 13.5059 8.00023C13.5059 5.59594 11.5491 3.63958 9.14528 3.63958C6.89113 3.63958 5.03305 5.357 4.80758 7.55129H7.13456L3.56803 12.2107L0 7.55129H2.31301C2.54645 3.98177 5.5174 1.14551 9.14528 1.14551C12.9243 1.14551 16 4.2207 16 8.00023Z" fill="#647696" />
    </svg>


  )
}

export const RedoIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8.00023C0 11.7798 3.0757 14.8549 6.85472 14.8549C7.54358 14.8549 8.10176 14.2968 8.10176 13.6079C8.10176 12.919 7.54358 12.3609 6.85472 12.3609C4.45093 12.3609 2.49408 10.4045 2.49408 8.00023C2.49408 5.59594 4.45093 3.63958 6.85472 3.63958C9.10887 3.63958 10.967 5.357 11.1924 7.55129H8.86544L12.432 12.2107L16 7.55129H13.687C13.4535 3.98177 10.4826 1.14551 6.85472 1.14551C3.0757 1.14551 0 4.2207 0 8.00023Z" fill="#647696" />
    </svg>
  )
}

export const HeadingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.34906 14.9996V14.1761H9.60827C9.83154 14.1761 10.04 14.1599 10.235 14.1289C10.4308 14.0966 10.5997 14.0305 10.743 13.9322C10.8863 13.8338 11.0018 13.6909 11.0885 13.5063C11.1748 13.3212 11.2174 13.0755 11.2174 12.7682V8.17296H4.83962V12.7682C4.83962 13.075 4.8823 13.3203 4.96855 13.5067C5.05481 13.6909 5.16981 13.8342 5.31357 13.9322C5.45732 14.0314 5.62758 14.0961 5.8257 14.1289C6.02426 14.1604 6.23091 14.1765 6.44834 14.1765H6.70755V15H1V14.1765H1.24933C1.4726 14.1765 1.68104 14.1604 1.87646 14.1289C2.07143 14.097 2.24169 14.0314 2.38904 13.9322C2.53549 13.8329 2.65049 13.6909 2.7345 13.5067C2.81716 13.3217 2.85804 13.075 2.85804 12.7682V3.15454C2.85804 2.86748 2.81491 2.63657 2.72911 2.46047C2.64196 2.28437 2.52516 2.14915 2.37871 2.05346C2.23181 1.95777 2.06155 1.89533 1.86568 1.86658C1.67206 1.83738 1.46586 1.82345 1.24888 1.82345H1V1H6.70755V1.82345H6.44834C6.23136 1.82345 6.02471 1.83917 5.8257 1.87152C5.62803 1.90297 5.45777 1.969 5.31357 2.06783C5.17026 2.16667 5.05526 2.30863 4.96855 2.49416C4.8823 2.6788 4.83962 2.92498 4.83962 3.23136V7.19497H11.2165V3.23136C11.2165 2.92453 11.1734 2.6788 11.0876 2.49371C11.0018 2.30863 10.8863 2.16622 10.7426 2.06783C10.5988 1.969 10.4299 1.90341 10.2345 1.87152C10.0391 1.83917 9.83154 1.82345 9.60737 1.82345H9.34816V1H15.0557V1.82345H14.8073C14.5836 1.82345 14.3756 1.83917 14.1801 1.87152C13.9852 1.90297 13.8154 1.969 13.6676 2.06783C13.5211 2.16667 13.4061 2.30863 13.3226 2.49371C13.239 2.67835 13.1981 2.92453 13.1981 3.23136V12.8639C13.1981 13.1514 13.2417 13.3827 13.327 13.558C13.4133 13.7341 13.5305 13.8666 13.677 13.9551C13.8235 14.0449 13.9937 14.1038 14.1896 14.133C14.3845 14.1617 14.5903 14.1761 14.8064 14.1761H15.0562V14.9996H9.34906Z" fill="#647696" />
    </svg>
  )
}

export const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1.00003 15.9999C0.802281 15.9999 0.608982 15.9412 0.444573 15.8313C0.280163 15.7214 0.152023 15.5653 0.0763531 15.3826C0.000683047 15.1999 -0.0191199 14.9988 0.0194476 14.8049C0.0580152 14.6109 0.153222 14.4328 0.293031 14.2929L5.29303 9.29292C5.48163 9.11076 5.73424 9.00997 5.99643 9.01224C6.25863 9.01452 6.50944 9.11969 6.69485 9.3051C6.88026 9.49051 6.98543 9.74132 6.98771 10.0035C6.98998 10.2657 6.88919 10.5183 6.70703 10.7069L1.70703 15.7069C1.51954 15.8945 1.26523 15.9999 1.00003 15.9999Z" fill="#647696" />
      <path d="M0.96609 10.7918L2.48809 9.26882L2.43309 9.14082C2.42709 9.12482 1.85709 7.55682 2.70809 6.70582L6.73809 2.67582C7.58009 1.83382 9.13809 2.38882 9.13809 2.38782L9.30109 2.45782L10.8231 0.934821L9.89009 0.534821C9.77709 0.490821 7.10009 -0.511179 5.32309 1.26282L1.29309 5.29282C-0.47991 7.06382 0.52309 9.74582 0.58109 9.89382L0.96609 10.7918Z" fill="#647696" />
      <path d="M5.20801 15.0338L6.73101 13.5118L6.85901 13.5668C6.87501 13.5728 8.44301 14.1428 9.29401 13.2918L13.324 9.26178C14.166 8.41978 13.611 6.86178 13.612 6.86178L13.542 6.69878L15.065 5.17578L15.465 6.10878C15.509 6.22178 16.511 8.90278 14.737 10.6758L10.707 14.7058C8.93601 16.4788 6.25401 15.4758 6.10701 15.4178L5.20801 15.0338Z" fill="#647696" />
      <path d="M10 6.99992C9.80228 6.99988 9.60898 6.94121 9.44457 6.83132C9.28016 6.72144 9.15202 6.56527 9.07635 6.38257C9.00068 6.19987 8.98088 5.99884 9.01945 5.80489C9.05802 5.61093 9.15322 5.43277 9.29303 5.29292L14.293 0.292919C14.4816 0.110761 14.7342 0.00996641 14.9964 0.0122448C15.2586 0.0145233 15.5094 0.119692 15.6948 0.3051C15.8803 0.490508 15.9854 0.741321 15.9877 1.00352C15.99 1.26571 15.8892 1.51832 15.707 1.70692L10.707 6.70692C10.5195 6.89447 10.2652 6.99986 10 6.99992Z" fill="#647696" />
    </svg>
  )
}

export const resizerSvgString = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M0 6.75L8 0.75V2L0.000285109 7.99979L0 6.75Z" fill="#647696"/>
    <path d="M4.95028 7.99979H3.31016L8 4.39222V5.65385L4.95028 7.99979Z" fill="#647696"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="8" height="8" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `;