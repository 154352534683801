import { Editor, Point } from 'slate'

export const isEntireEditorSelected = (editor) => {
  const { selection } = editor
  return (
    selection &&
    selection.focus &&
    selection.anchor &&
    ((Point.equals(selection.focus, Editor.start(editor, [])) &&
      Point.equals(selection.anchor, Editor.end(editor, []))) ||
      (Point.equals(selection.focus, Editor.end(editor, [])) &&
        Point.equals(selection.anchor, Editor.start(editor, []))))
  )
}
