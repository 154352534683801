import React from 'react'
import { cx } from "@emotion/css"
import { Registry } from "@react-registry"
import { Editor, Transforms } from "slate"
import { useSlate } from "slate-react"

import { ExtendedEditor, ElementWithType } from "utils/types"
import { unMergeCells, isValidForUnMerging } from "./unMergeCells"
import { MergeCellsIcon, UnMergeCellsIcon } from "../../../utils/Icon"
import { isPathInRectangle, isValidForMerging, mergeCells } from "./mergeCells"
import { elementCategories } from "../../elementCategories"

import styles from '../style.module.css'

export default function MergeUnMergeCellsButton(props) {
  const editor: ExtendedEditor = useSlate()

  const [table] = Editor.nodes(editor, {
    match: (n: ElementWithType) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })

  let isDisable = !table
  const selection = editor.selection || editor.savedSelection
  const selectedCells = Array.from(
    Editor.nodes(editor, {
      match: (node: ElementWithType, path) => node.type === 'th' || (node.type === 'td' && isPathInRectangle(editor, path, table[1]))
    })
  )
  let title = "Merge Cells"
  let callback = mergeCells
  let Icon = MergeCellsIcon

  if (!selectedCells.length)
    isDisable = true

  else if (selectedCells.length > 1) {
    if (!isDisable && !isValidForMerging(editor, selectedCells)) {
      isDisable = true
    }
  }
  else {
    title = "Unmerge Cells"
    callback = unMergeCells
    Icon = UnMergeCellsIcon
    if (!isDisable && !isValidForUnMerging(selectedCells[0])) {
      isDisable = true
    }
  }

  const handleMouseDown = (event) => {
    if (selection) {
      Transforms.select(editor, selection)
      if (!isDisable) {
        callback(event, { editor })
      }
    }
  }

  return (
    <span
      data-testid="cells-merge-unmerge"
      className={cx(styles['cells-merge-unmerge'], {
        [styles['cells-merge-unmerge--disable']]: isDisable
      })}
      onMouseDown={handleMouseDown}>
      <span className={styles['merge-unmerge-icon-wrapper']}>
        <Icon />
      </span>
      <span>{title}</span>
      <span style={{ display: 'flex' }}>
      </span>
    </span>
  )
}


Registry.register(
  {
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
      RenderComponent: MergeUnMergeCellsButton
    },
    title: 'Merge Cells',
    canCustomize: false,
    isContentStackElement: true
  },
  { id: 'table-merge-cells', registry: 'element' }
)
