import commands from './commands';



export const handleMouseDown = (e, editor, pattern, side = 'both', placeholder = '', id = '', meta = undefined) => {
  e.preventDefault();
  const state = new editor.TextareaState(editor.panels)
  if (!state || (!state?.text && state?.text !== "")) {
    return;
  }
  const chunks = state.getChunks();
  const fixupInputArea = function () {

    editor.panels.input.focus();

    if (chunks) {
      state.setChunks(chunks);
    }

    state.restore();
    editor?.onUpdate && state?.text && editor.onUpdate(state?.text) // to update the markdown data for save
    editor.previewManager.refresh();
  };
  const noCleanup = commands[id](chunks, fixupInputArea, editor, meta);

  if (!noCleanup) {
    fixupInputArea();
  }
}