import React, { useState } from 'react'
import { Transforms, Range, Editor, Point } from 'slate'
import { useSlateStatic, useSlate } from 'slate-react'
import {
  cbModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  ButtonGroup,
  TextInput,
  Field
} from '../../../../../../index'

import { disableOnCodeChild } from '../code/utils'
import { Editor as RteEditor } from '../../../utils/types'
import { onEnter } from '../../../utils/runOnKeyDown'
import { iFrameUrl, blockQuoteUrl, isValidUrl } from './utils/index'
import styles from './style.module.css'
import { Youtube, Vimeo, Facebook, Twitter, Instagram, Tiktok } from './svgs'


const isIframe = (data) => {
  return (data?.match(/<iframe|<video/gi) !== null);
}

const isBlockQuote = (data) => {
  return (data?.match(/<blockquote/gi) !== null);
}

const getUpdatedAttrs = (attrs) => {
  if (typeof attrs === 'string') {
    return {
      src: attrs
    }
  }
  else return { ...attrs }
}

export const addSocialEmbed = (editor, attrs) => {
  let embeded = {
    type: 'social-embeds',
    attrs: getUpdatedAttrs(attrs),
    children: [{ text: '' }]
  }
  editor.insertNode(embeded)
}

export default (props) => {
  const editor: RteEditor = useSlateStatic()
  const useSlateEditor = useSlate()

  const handleSubmitForm = React.useCallback((values, selection) => {
    if (selection) Transforms.select(editor, selection)

    if (isIframe(values['embeded_url'])) {
      addSocialEmbed(editor, iFrameUrl(values['embeded_url']))
      return;
    }
    if (isBlockQuote(values['embeded_url'])) {
      if (blockQuoteUrl(values['embeded_url'])) {
        addSocialEmbed(editor, blockQuoteUrl(values['embeded_url']))
      }
      return;
    }
    addSocialEmbed(editor, values['embeded_url'])
  }, [])

  const openModal = () => {
    const selection: Range | Point = editor.selection || editor.savedSelection || Editor.end(editor, []);
    if (!selection) return
    cbModal({
      modalProps: {
        //@ts-ignore
        shouldReturnFocusAfterClose: false,
      },
      component: (props) => <ModalComponent handleSubmitForm={handleSubmitForm} selection={selection} {...props} />
    })
  }

  const ModalComponent = (props) => {
    const [formData, setFormData] = useState({
      embeded_url: ''
    })

    const handleChange = (e) => {
      e.persist()
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleSubmit = (e) => {
      props.handleSubmitForm(formData, props.selection)
      props.closeModal()
    }

    return (
      <div
        onKeyDown={(event) =>
          onEnter(event, () => {
            handleSubmit(event)
          })
        }>
        <ModalHeader title="Social Embeds" closeModal={props.closeModal} />

        <ModalBody>
          <Field labelText="Embed Url">
            <TextInput
              autoFocus
              name="embeded_url"
              placeholder="Enter Embeded url"
              type="url"
              value={formData.embeded_url}
              onChange={handleChange}
              data-testid="social_embed_url"
            />
          </Field>
          <div className={styles['field-style']}>
            <Field labelText="Allowed Embeds:">
              <div className={styles['embed-icons-container']}>
                <div><Youtube /></div>
                <div><Vimeo /></div>
                <div><Facebook /></div>
                <div><Twitter /></div>
                <div><Instagram /></div>
                <div><Tiktok /></div>
              </div>
            </Field>
          </div>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button key="cancel" buttonType="light" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button key="add" icon="CheckedWhite" onClick={handleSubmit} data-testid="addSocialEmbedBtn">
              Add
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: openModal,
        isDisable: {
          func: disableOnCodeChild,
          params: { editor: useSlateEditor }
        }
      })}
    </React.Fragment>
  )
}

