//@ts-nocheck
import React, { useContext, memo } from 'react'

import { EditorProvider } from '../../ContextProvider/EditorProvider'
import { Button } from '../Button'
import { TriggerButton } from '../Button/TriggerButton'
import { cx, css } from '@emotion/css'

export const MemoToolbarButton = memo((props) => {
  const { showEditHtml, keyData, element, toolbarType, DefaultComponent } = props;
  const { handleMouseDown, IngressComponent, iconName, title, toolbar, shortcut } = element;

  if (toolbar?.RenderComponent) {
    const RenderComponent = toolbar.RenderComponent

    return (
      <div
        className={cx(
          css`
            ${Boolean(showEditHtml && keyData !== 'Html') ? 'pointer-events: none;' : ''}
            ${Boolean(showEditHtml && keyData !== 'Html') ? 'cursor:not-allowed !important;' : ''}
          `
        )}
      >
        <RenderComponent key={keyData} />
      </div>
    )
  }

  if (handleMouseDown) {
    return (
      <Button
        disabled={Boolean(showEditHtml && keyData !== 'Html')}
        onMouseDown={(e) => {
          e.preventDefault()
          handleMouseDown() // put editor here
        }}
        format={keyData}
        title={title}
      >
        {iconName || 'icon-here'}
      </Button>
    )
  }
  if (IngressComponent) {
    return (
      <TriggerButton
      shortcut={shortcut}
        key={keyData}
        value={element}
        format={keyData}
        toolbarType={toolbarType}
        title={title}
      />
    )
  } else if (iconName) {
    return (
      <DefaultComponent
        key={keyData}
        element={element}
        keyData={keyData}
        toolbarType={toolbarType}
      />
    )
  } else return null
})

export const ToolbarButton = (props) => {
  const { showEditHtml } = useContext(EditorProvider)
  return <MemoToolbarButton showEditHtml={showEditHtml} {...props} />
}
