import isObject from 'lodash/isObject'
import { Editor, Node, Text } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'
import { isInlineElement } from "../../../utils/queries"
import { getNormalTextElement } from "../../Element/paragraph/utils"

// export const wrapWithParagraph = (editor, fragment) => {
//   let newFragment = []
//   let currentSegment = []
//   for (let i = 0; i < fragment.length; ++i) {
//     if (Text.isText(fragment[i]) || (Node.isNode(fragment[i]) && Editor.isInline(editor, fragment[i]))) {
//       currentSegment.push(fragment[i])
//     } else {
//       if (currentSegment.length) {
//         newFragment.push({
//           type: 'p',
//           attrs: {},
//           children: currentSegment
//         })
//         currentSegment = []
//       }
//       newFragment.push(fragment[i])
//     }
//   }
//   if (currentSegment.length) {
//     newFragment.push({
//       type: 'p',
//       attrs: {},
//       children: currentSegment
//     })
//   }

//   return newFragment
// }

// export const withpreInsert = (editor, fragment) => {
//   const [match] = Editor.nodes(editor, {
//     match: (n: ElementWithType) => n.type === 'code'
//   })

//   if (!!match) {
//     let newFragment = [
//       {
//         type: 'p',
//         children: [{ text: fragment.map((node) => Node.string(node)).join('\n') }]
//       }
//     ]

//     return newFragment
//   }
//   return fragment
// }

// export const preInsertFormatter = (editor, fragment) => {
//   let fragmentLength = fragment.length
//   let currentFragment = [...fragment]

//   while (fragmentLength) {
//     if (Text.isText(currentFragment[0])) {
//       let text = String(currentFragment[0].text).replace(/\n/g, '')

//       if (text === '') {
//         currentFragment.shift()
//         --fragmentLength
//       } else {
//         break
//       }
//     } else {
//       break
//     }
//   }
//   while (fragmentLength) {
//     if (Text.isText(currentFragment[fragmentLength - 1])) {
//       let text = String(currentFragment[fragmentLength - 1].text).replace(/\n/g, '')
//       if (text === '') {
//         currentFragment.pop()
//         --fragmentLength
//       } else {
//         break
//       }
//     } else {
//       break
//     }
//   }

//   currentFragment = wrapWithParagraph(editor, fragment)

//   const padding = { type: 'p', children: [{ text: '' }] }

//   return currentFragment
// }

export const preInsert = (editor, fragment) => {
  const firstNodeType = fragment[0]?.type || undefined
  if (firstNodeType && !isInlineElement(fragment[0]) && firstNodeType !== 'fragment' && firstNodeType !== 'p') {
    fragment.unshift(getNormalTextElement())
    fragment.push(getNormalTextElement())
  }
  return fragment
}

export const applyDirty = (fragment) => {
  if (!fragment) return;

  if (Array.isArray(fragment)) {
    return fragment.forEach(applyDirty);
  }

  if (!isObject(fragment) || !fragment['children']) return;

  // add dirty attrs
  if (!fragment['attrs']) fragment['attrs'] = {};
  fragment['attrs']['dirty'] = true;
  applyDirty(fragment['children']);
}