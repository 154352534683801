import React, { useEffect, useState } from 'react'

import { getAssets, getUsers } from './request';

import { ListView, ThumbnailView, AssetBreadCrumb, AssetSearch } from './Modal/SelectImage';
import { NoAssets } from '../../../../RichTextEditor/SuperChargedRte/elements/Element/contentstack/image/Modal/Upload/UploadComponent';


function SelectImage(props) {
  const { editor } = props;
  const [state, setState] = useState({
    assets: [],
    loading: false,
    selectedImage: {},
    itemStatusMap: {},
    loadedImageUid: {},
    displayType: 'List',
    startIndex: 0,
    count: 12,
    stopIndex: 30,
    query: '',
    breadcrumb: [{
      displayName: 'All Assets',
      action: () => {
        setState(currState => ({
          ...currState,
          breadcrumb: currState.breadcrumb.slice(0, 1),
          folderUid: 'cs_root'
        }))
      }
    }],
    folderUid: 'cs_root',
    users: {},
    skip: null,
    limit: 0
  });

  /* runs only once */
  useEffect(() => {
    (async () => {
      if (!editor.requestProps.apiKey) {
        setState(state => ({
          ...state,
          count: 0,
          loading: false,
          assets: []
        }));
        return;
      }
      fetchOnChange({ skip: 0, limit: 30, startIndex: 0, stopIndex: 30 });
    })();
  }, [state.query, state.folderUid]);

  /* one render */
  useEffect(() => {
    getUsers({ ...editor.requestProps })
      .then(res => setState(state => ({ ...state, users: res })))
  }, [])

  const fetchOnChange = async ({ sortBy = null, skip, limit, startIndex, stopIndex }) => {
    /* rendered here */
    const instanceItemStatusMapped = {};

    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loading';
    }
    setState(state => ({
      ...state,
      itemStatusMap: {
        ...instanceItemStatusMapped
      },
      loading: true
    }));
    let sort = {
      asc_field: '',
      dsc_field: ''
    };

    if (sortBy) {
      sort[`${sortBy['sortingDirection']}_field`] = sortBy['id'];
    }

    const response = await getAssets({ ...editor.requestProps, limit, 'skipSize': skip, query: state.query, folderUid: state.folderUid, ...sort });

    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loaded'
    }

    /* rendered here */
    setState(state => ({
      ...state,
      assets: [
        ...response.assets
      ],
      itemStatusMap: {
        ...instanceItemStatusMapped
      },
      startIndex,
      stopIndex,
      loading: false,
      count: response.count,
    }));

  }

  const loadMore = async ({ sortBy = null, skip, limit, startIndex, stopIndex }) => {
    if (state.skip === skip && state.limit === limit) return;

    let sort = {
      asc_field: '',
      dsc_field: ''
    };

    if (sortBy) {
      sort[`${sortBy['sortingDirection']}_field`] = sortBy['id'];
    }
    skip = skip === 0 ? skip : skip + 1;
    const response = await getAssets({ ...editor.requestProps, limit, 'skipSize': skip, folderUid: state.folderUid, query: state.query, ...sort });

    const instanceItemStatusMapped = {};

    for (let i = startIndex; i <= stopIndex; i++) {
      instanceItemStatusMapped[i] = 'loaded';
    }

    setState(state => ({
      ...state,
      assets: [
        ...state.assets,
        ...response.assets
      ],
      itemStatusMap: {
        ...state.itemStatusMap,
        ...instanceItemStatusMapped
      },
      startIndex,
      stopIndex,
      skip,
      limit
    }))

  }

  const handleClick = (asset) => {
    if (asset && asset.is_dir) {
      setState(currState => ({
        ...currState,
        breadcrumb: [...currState.breadcrumb, {
          displayName: asset.name,
          action: (len = state.breadcrumb.length, uid = asset.uid) => {
            setState(state => {
              return {
                ...state,
                breadcrumb: state.breadcrumb.slice(0, len + 1),
                folderUid: uid,
                query: ''
              }
            });
          }
        }],
        folderUid: asset.uid,
        query: ''
      }));
    }
    else {
      props.setImage(asset);
      setState(state => ({
        ...state,
        selectedImage: asset
      }))
    }
  }

  const loadMoreGrid = () => {
    const skip = state.stopIndex;
    const limit = 30;
    const startIndex = state.stopIndex + 1;
    const stopIndex = startIndex + limit;
    loadMore({ skip, startIndex, limit, stopIndex });
  }

  return (
    <div >
      <AssetBreadCrumb breadcrumbs={state.breadcrumb} />
      <AssetSearch assetsCount={state.count} listType={state.displayType} setState={setState} />
      <NoAssets length={state.count}>
        {
          state.displayType === 'Grid' ? (
            <ThumbnailView state={state} handleClick={handleClick} loadMoreGrid={loadMoreGrid} />

          ) :
            <ListView users={state.users} loading={state.loading} state={state} handleClick={handleClick} fetchOnChange={fetchOnChange} loadMore={loadMore} />
        }
      </NoAssets>
    </div>
  )
}


export default SelectImage;
