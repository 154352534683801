import React from 'react'
import { useSlate } from 'slate-react'
import { Registry } from '@react-registry'
import { isNodeAligned, alignNode } from '../utils'
import { elementCategories } from '../../elementCategories'
import { LeftAlignmentIcon } from '../../../utils/Icon'

export const ShortcutAlignmentLeft = (event, editor, format, registryObj) => {
  alignNode(editor, 'left')
}

export const AlignLeft = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    alignNode(editor, 'left')
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isNodeAligned(editor, 'left')
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <LeftAlignmentIcon />,
    slashIconName: <LeftAlignmentIcon />,
    style: 'left',
    shortcut: { key: { win: 'mod+alt+l', mac: 'cmd+ctrl+l' }, callback: ShortcutAlignmentLeft },
    IngressComponent: AlignLeft,
    category: elementCategories.ALIGNMENT_DROPDOWN,
    template: '',
    title: 'Left',
    slashTitle: 'Left',
    isContentStackElement: true,
    inBasicToolbar: true,
    canCustomize: false,
    keywords: ['left']
  },
  { id: 'left-align', registry: 'v2.element' }
)
