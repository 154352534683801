import React, { useContext, useReducer } from 'react'

export const store = React.createContext(null);

const { Provider } = store;

export const StateProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'toggle':
        return {
          open: !state.open
        }
      default:
        return state
    }
  }, {

  })

  return <Provider value={{ state, dispatch }}>{props.children}</Provider>
}

export const connect = (mapStateToProps, mapDispatchToProps) => {
  return function (Component) {
    return function (props) {
      const { state, dispatch } = useContext(store);
      const stateToProps = mapStateToProps(state);
      const dispatchToProps = mapDispatchToProps(dispatch);
      props = { ...props, ...stateToProps, ...dispatchToProps };
      return <Component  {...props} />
    }
  }
}
