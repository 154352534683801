import { getRegistry } from '../../getRegistry'


export const keyDownHooks = (props) => {
  const hooks = getRegistry('keydown')
  Object.entries(hooks).forEach((val: any) => {
    const value = val[1]
    let hook = value.components[0]['component']
    hook(props)
  })
}
