import React from 'react';

//@ts-ignore
import UploadComponent from './UploadComponent';

export function Upload({ editor, handleUpload, setFolderUid, loading }) {
    return (
        <UploadComponent loading={loading} editor={editor} handleUpload={handleUpload} setFolderUid={setFolderUid} />
    )
}
