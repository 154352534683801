import React, { Component } from "react";

import Icon from "../Icon2/Icon";
import AsyncLoader from "../AsyncLoader/AsyncLoader";
import Button from "../Button/Button";
import SkeletonTile from "../SkeletonTile/SkeletonTile";
import Tooltip from "../Tooltip/Tooltip";

import AdvanceSearchQuery from "./AdvanceSearchQuery";
import SearchFilter from "./SearchFilter/SearchFilter";

import { iconName, advanceQueryOpts, viewAllCtaId } from "./util/constants";
import {
  getSuggestedSyntaxes,
  maxSuggetsedItemsCount,
  maxSavedSearchesCount,
  getRecentUsedFieldsOrder,
} from "./util/common";
// import { getOS } from "./util/helpers";

const maxShowItemsSaveSearchCount = 5;
const maxLoadMoreItemsCount = 5;

const SkeletonLoader = ({ size = 2 }) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={8}
                tileWidth={110}
                tileRadius={6}
                tileBottomSpace={12}
                tileTopSpace={12}
                tileleftSpace={18}
              />
            </div>
          );
        })}
    </div>
  );
};

const RecentSearch = (props) => {
  const { textContent, recentSearches, vCursor, handleRecentSearchClick, isLoadingSearches, isBuildingRecentSearches } =
    props;

  if (isLoadingSearches || isBuildingRecentSearches) {
    return (
      <div className="AdvancedSearch__predefined-options AdvancedSearch__predefined-options--recent-searches">
        <h4 className="AdvancedSearch__option-title">{textContent.advance_search_ui.recent_searches.title}</h4>

        <ul className="AdvancedSearch__recent-items">
          <li className="AdvancedSearch__recent-item">
            <SkeletonLoader size={1} />
          </li>
        </ul>
      </div>
    );
  }

  if (!recentSearches.length) {
    return null;
  }

  return (
    <div className="AdvancedSearch__predefined-options AdvancedSearch__predefined-options--recent-searches">
      <div className="flex-justify">
        <h4 className="AdvancedSearch__option-title font-size-medium">
          {textContent.advance_search_ui.recent_searches.title}
        </h4>
        <h4 className="AdvancedSearch__option-button" onClick={props.resetRecentSearch}>
          CLEAR ALL
        </h4>
      </div>
      <ul className="AdvancedSearch__recent-items">
        {recentSearches.map((search, i) => {
          const liClass = `AdvancedSearch__recent-item ${vCursor === i ? "AdvancedSearch__recent-item--hover" : ""}`;
          return (
            <li key={i} className={liClass}>
              <span onClick={() => handleRecentSearchClick(search)} className="AdvancedSearch__recent-search">
                {search.search_text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export class AdvanceSearchBody extends Component<any, any> {
  state = {
    savedSearchUid: "",
    statusMapSyntaxes: [],
    suggestedSyntaxes: [],
  };

  componentDidMount() {
    try {
      const suggestedSyntaxes = getSuggestedSyntaxes(this.props.selectedOption.value);
      const storedRecentUsedFields = this.props.storedRecentUsedFields[this.props.selectedOption.value];
      const orderedSuggestedSyntax = getRecentUsedFieldsOrder({
        queryArray: this.props.queryArray,
        suggestedSyntaxes,
        storedRecentUsedFields,
        action: "init",
        selectedModule: this.props.selectedOption.value,
      });
      const statusMapSyntaxes = this.getInitStatusMap(orderedSuggestedSyntax);
      this.setState({ statusMapSyntaxes, suggestedSyntaxes: orderedSuggestedSyntax });
    } catch (error) {
      console.log("componentDidMount -> error", error);
      this.handleErrorState();
    }
  }

  componentDidUpdate(prevProps) {
    try {
      if (prevProps.selectedOption.value !== this.props.selectedOption.value) {
        const suggestedSyntaxes = getSuggestedSyntaxes(this.props.selectedOption.value);
        const storedRecentUsedFields = this.props.storedRecentUsedFields[this.props.selectedOption.value];


        const orderedSuggestedSyntax = getRecentUsedFieldsOrder({
          queryArray: this.props.queryArray,
          suggestedSyntaxes,
          storedRecentUsedFields,
          action: "init",
          selectedModule: this.props.selectedOption.value,
        });
        const statusMapSyntaxes = this.getInitStatusMap(orderedSuggestedSyntax);
        this.setState({ statusMapSyntaxes, suggestedSyntaxes: orderedSuggestedSyntax });
      }
    } catch (error) {
      console.log("componentDidUpdate -> error", error);
      this.handleErrorState();
    }
  }

  handleErrorState = () => {
    const suggestedSyntaxes = getSuggestedSyntaxes(this.props.selectedOption.value);
    const statusMapSyntaxes = this.getInitStatusMap(suggestedSyntaxes);
    this.setState({ statusMapSyntaxes, suggestedSyntaxes });
  };

  updateSuggestedSyntaxOrder = (queryArray) => {
    try {
      const orderedSuggestedSyntax = getRecentUsedFieldsOrder({
        queryArray,
        suggestedSyntaxes: this.state.suggestedSyntaxes,
        selectedModule: this.props.selectedOption.value,
      });
      const statusMapSyntaxes = this.getInitStatusMap(orderedSuggestedSyntax);
      this.setState({ statusMapSyntaxes, suggestedSyntaxes: orderedSuggestedSyntax });

      let topFiveFields = [];
      const slicedFields = orderedSuggestedSyntax.slice(0, 5);
      slicedFields.forEach((field) => {
        if (field && field.uid) {
          topFiveFields.push(field.uid);
        }
      });
      if (this.props.onChangeRecentUsedFieldsOrder) {
        this.props.onChangeRecentUsedFieldsOrder(topFiveFields, this.props.selectedOption.value);
      }
    } catch (error) {
      console.log(" updateSuggestedSyntaxOrder error", error);
    }
  };

  getInitStatusMap = (data = [], type = "syntaxes") => {
    const statusMap = [];
    let maxCount = maxSuggetsedItemsCount[this.props.selectedOption.value];

    if (type === "save_search") {
      maxCount = maxShowItemsSaveSearchCount;
    }

    for (let i = 0; i < Math.min(data.length, maxCount); i++) {
      statusMap.push("show");
    }
    return statusMap;
  };

  handleShowMore = (type) => {
    if (type === "syntaxes") {
      const suggestedSyntaxes = this.state.suggestedSyntaxes;
      const moreSyntaxItemsCount = Math.min(
        suggestedSyntaxes.length - maxSuggetsedItemsCount.entries,
        suggestedSyntaxes.length - (this.state.statusMapSyntaxes.lastIndexOf("show") + 1)
      );
      const statusMap = [...this.state.statusMapSyntaxes, ...Array(moreSyntaxItemsCount).fill("show")];
      const data = {
        vCursor: this.props.recentSearches.length,
        [this.props.selectedOption.value]: statusMap.length,
      };
      this.props.updateHLengthByVCursor(data);
      this.setState({ statusMapSyntaxes: statusMap });
    }
  };

  simulateCursorClick = (cursor, type) => {
    const { statusMapSyntaxes, suggestedSyntaxes } = this.state;
    const { savedSearches } = this.props;
    if (type === "syntaxes") {
      let isCurrentIndexShowMore =
        statusMapSyntaxes.length < suggestedSyntaxes.length && statusMapSyntaxes.length === cursor;
      let isCurrentIndexSeeLess =
        statusMapSyntaxes.length === suggestedSyntaxes.length && statusMapSyntaxes.length === cursor;

      if (isCurrentIndexShowMore) {
        this.handleShowMore(type);
      } else if (isCurrentIndexSeeLess) {
        this.handleShowLess(type);
      } else if (this.props.hCursor >= 0 && this.props.hCursor < suggestedSyntaxes.length) {
        const syntax = suggestedSyntaxes[this.props.hCursor];
        this.props.onClickSuggestedSyntax(syntax.value);
      }
    }
    if (type === "save_search") {
      if (this.props.hCursor >= 0 && this.props.hCursor < maxSavedSearchesCount) {
        const search = savedSearches[this.props.hCursor];
        this.setState({ savedSearchUid: search.uid });
        this.props.handleSavedSearchClick(search);
      }
    }
  };

  handleShowLess = (type) => {
    if (type === "syntaxes") {
      const suggestedSyntaxes = this.state.suggestedSyntaxes;

      const statusMap = this.getInitStatusMap(suggestedSyntaxes);
      console.log("handleShowLess -> statusMap", statusMap);
      const data = {
        vCursor: this.props.recentSearches.length,
        [this.props.selectedOption.value]: statusMap.length,
        resetHcursor: true,
      };
      this.props.updateHLengthByVCursor(data);
      this.setState({ statusMapSyntaxes: statusMap });
    }
  };

  render() {
    const { textContent, selectedOption, savedSearches } = this.props;
    const { statusMapSyntaxes } = this.state;

    const maxShowItemsCount = maxSuggetsedItemsCount[selectedOption.value];
    const suggestedSyntaxes = this.state.suggestedSyntaxes;

    const moreSyntaxItemsCount = Math.min(
      maxLoadMoreItemsCount,
      suggestedSyntaxes.length - (statusMapSyntaxes.lastIndexOf("show") + 1)
    );

    const showSeeLessSyntaxes =
      suggestedSyntaxes.length !== 0 && moreSyntaxItemsCount === 0 && maxShowItemsCount < suggestedSyntaxes.length;

    // Cursors
    const isCursorMoreSyntaxes =
      statusMapSyntaxes.length < suggestedSyntaxes.length && statusMapSyntaxes.length === this.props.hCursor;
    const isCursorLessSyntaxes =
      statusMapSyntaxes.length === suggestedSyntaxes.length && statusMapSyntaxes.length === this.props.hCursor;
    return (
      <div>
        <div className="AdvancedSearch__searchedQuery-wrapper">
          {this.props.searchCase === "advanceQuery" && (
            <AdvanceSearchQuery
              // textContent={textContent}
              advanceQueryArray={this.props.advanceQueryArray}
              fetchData={this.props.fetchData}
              selectedOption={this.props.selectedOption}
              horizontalAdvQueryLength={this.props.horizontalAdvQueryLength}
            />
          )}
        </div>
        {this.props.searchCase === "basicQuery" && !this.props.queryArray.length && (
          <div className="AdvancedSearch__options-wrapper">
            <RecentSearch
              textContent={textContent}
              vCursor={this.props.vCursor}
              recentSearches={this.props.recentSearches}
              handleRecentSearchClick={this.props.handleRecentSearchClick}
              isLoadingSearches={this.props.isLoadingSearches}
              isBuildingRecentSearches={this.props.isBuildingRecentSearches}
              resetRecentSearch={this.props.resetRecentSearch}
            />
            <div className="AdvancedSearch__predefined-options AdvancedSearch__predefined-options--syntax">
              <h4 className="AdvancedSearch__option-title font-size-medium mb-8">RECENTLY USED FIELDS</h4>
              <div className="AdvancedSearch__options">
                {suggestedSyntaxes.map((syntax, index) => {
                  if (statusMapSyntaxes[index] !== "show") return null;
                  return (
                    <Tooltip content={syntax.info || syntax.label} position="top" interactive={false} key={index}>
                      <button
                        key={index}
                        className={`AdvancedSearch__option ${this.props.vCursor === this.props.recentSearches.length && this.props.hCursor === index
                          ? "AdvancedSearch__matched-unit-active"
                          : ""
                          }`}
                        onClick={() => this.props.onClickSuggestedSyntax(syntax.value)}
                      >
                        {syntax.label}
                      </button>
                    </Tooltip>
                  );
                })}
                {moreSyntaxItemsCount > 0 && (
                  <button
                    className={`AdvancedSearch__option font-bold ${this.props.vCursor === this.props.recentSearches.length && isCursorMoreSyntaxes
                      ? "AdvancedSearch__matched-unit-active"
                      : ""
                      }`}
                    onClick={() => this.handleShowMore("syntaxes")}
                  >
                    View All
                  </button>
                )}

                {showSeeLessSyntaxes && (
                  <button
                    className={`AdvancedSearch__option font-bold ${this.props.vCursor === this.props.recentSearches.length && isCursorLessSyntaxes
                      ? "AdvancedSearch__matched-unit-active"
                      : ""
                      }`}
                    onClick={() => this.handleShowLess("syntaxes")}
                  >
                    View Less
                  </button>
                )}
              </div>
            </div>

            {(savedSearches.length > 0 || this.props.isLoadingSearches) && (
              <div className="AdvancedSearch__predefined-options AdvancedSearch__predefined-options--saved-search">
                <div className="flex-justify ">
                  <h4 className="AdvancedSearch__option-title font-size-medium mb-8">
                    {textContent.advance_search_ui.saved_searches.title}
                  </h4>
                  <h4
                    onClick={() => {
                      this.props.savedViewRef.current.openSavedView();
                    }}
                    className="AdvancedSearch__option-button"
                    id={viewAllCtaId}
                  >
                    VIEW ALL
                  </h4>
                </div>
                <div className="AdvancedSearch__options">
                  {this.props.isLoadingSearches ? (
                    <SkeletonLoader size={1} />
                  ) : (
                    savedSearches.map((search, index) => {
                      return (
                        <button
                          key={index}
                          className={`AdvancedSearch__option ${this.props.vCursor === this.props.recentSearches.length + 1 && this.props.hCursor === index
                            ? "AdvancedSearch__matched-unit-active"
                            : ""
                            }`}
                          onClick={() => {
                            this.setState({ savedSearchUid: search.uid });
                            this.props.handleSavedSearchClick(search);
                          }}
                        >
                          {this.state.savedSearchUid === search.uid && this.props.isLoading ? (
                            <AsyncLoader color="#6C5CE7" />
                          ) : (
                            search.name
                          )}
                        </button>
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export const AdvanceSearchFooter = (props) => {
  return (
    <div>
      {props.case === "basicQuery" || props.case === "searchFilter" ? (
        <div className="AdvancedSearch__footer">
          <div className="flex-v-center">
            <Button
              aria-label="Advanced Search"
              className="AdvancedSearch__footer__leftButton"
              onClick={() => props.openAdvanceSearchView(advanceQueryOpts[0].value)}
            >
              <Icon icon={iconName.SettingsOutline} size="tiny" className="purpleIcon"></Icon>
              Advanced Search

            </Button>

            <div className="AdvancedSearch__footer__linkWrapper">
              <a
                href="https://www.contentstack.com/docs/content-managers/search-content/using-the-new-search-real-world-scenarios"
                target="_blank"
                className="AdvancedSearch__footer__linkWrapper__link"
              >
                View Docs
                <Icon
                  icon={iconName.NewTab}
                  size="tiny"
                  className="AdvancedSearch__footer__linkWrapper__newTabIcon"
                ></Icon>
              </a>
            </div>
          </div>

          <div className="AdvancedSearch__learn-more">
            <div
              className={`AdvancedSearch__apply-filters ${props.case === "searchFilter" ? "AdvancedSearch__apply-filters--open" : ""
                }`}
            >
              <Button aria-label="Filters" className="AdvancedSearch__filters-button" onClick={props.onClickFilters}>
                {props.case === "searchFilter" ? (
                  <Icon icon="FilterFilled" size="tiny" className="purpleIcon mr-5" />
                ) : (
                  <Icon icon="FilterNew" size="tiny" className="purpleIcon mr-5" />
                )}
                Filters
                <Icon icon={iconName.DropdownDark} className="AdvancedSearch__filters-button-icon"></Icon>
              </Button>
              <SearchFilter
                textContent={props.textContent}
                selectedOption={props.selectedOption}
                fetchData={props.fetchData}
                searchCase={props.case}
                updateFilter={props.updateFilter}
                onClickFilterResult={props.onClickFilterResult}
                filters={props.filters}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="AdvancedSearch__footer">
          <div className="AdvancedSearch__keyboard-controls">
            {props.errorMessage ? (
              <span className="AdvancedSearch__error-message">{props.errorMessage}</span>
            ) : (
              <span>
                Use the arrow keys to navigate and{" "}
                <span className="AdvancedSearch__keyboard-control">Enter</span> to confirm
              </span>
            )}
          </div>
          <div className="AdvancedSearch__see-results">
            <Button aria-label="Apply" icon="RightArrow" iconAlignment="right" onClick={props.onClickSeeResults}>
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

// (
//   <div className="AdvancedSearch__predefined-options AdvancedSearch__predefined-options--recent-searches">
//     <h4 className="AdvancedSearch__option-title font-size-medium">
//       {textContent.advance_search_ui.recent_searches.title}
//     </h4>
//     <ul className="AdvancedSearch__recent-items">
//       <li className="AdvancedSearch__recent-item">
//         <span className="AdvancedSearch__no-result">{textContent.empty_states.recent_searches.title}</span>
//       </li>
//     </ul>
//   </div>
// );

/* <span className="font-regular font-size-small ml-3">
                {getOS() === "macOS" ? shortcutKeyMap[0].macLabel : shortcutKeyMap[0].otherLabel}
              </span> */

/* <span className="font-regular font-size-small ml-3">
                  {getOS() === "macOS" ? shortcutKeyMap[1].macLabel : shortcutKeyMap[1].otherLabel}
                </span> */
