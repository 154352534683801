import React from 'react'
import { useSlate } from 'slate-react'
import { Registry } from '@react-registry'

import { isNodeAligned, alignNode } from '../utils'
import { elementCategories } from '../../elementCategories'
import { RightAlignmentIcon } from '../../../utils/Icon'

export const ShortcutAlignmentRight = (event, editor, format, registryObj) => {
  alignNode(editor, 'right')
}

export const AlignRight = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    alignNode(editor, 'right')
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isNodeAligned(editor, 'right')
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <RightAlignmentIcon />,
    slashIconName: <RightAlignmentIcon />,
    style: 'right',
    shortcut: { key: { win: 'mod+alt+r', mac: 'cmd+ctrl+r' }, callback: ShortcutAlignmentRight },
    title: 'Right',
    slashTitle: 'Right',
    template: '',
    IngressComponent: AlignRight,
    category: elementCategories.ALIGNMENT_DROPDOWN,
    isContentStackElement: true,
    inBasicToolbar: true,
    canCustomize: false,
    keywords: ['right']
  },
  { id: 'right-align', registry: 'v2.element' }
)
