import { Registry } from '@react-registry'

export const tableDeserializer = (el) => {
  let colWidths = el
    .getAttribute('data-colwidth')
    .split(',')
    .map((child) => parseInt(child))

  return {
    type: 'table',
    attrs: {
      rows: Number(el.getAttribute('data-row')),
      cols: Number(el.getAttribute('data-col')),
      colWidths: colWidths
    }
  }
}

Registry.register(tableDeserializer, { id: 'table', registry: 'v2.deserializer' })
