import React, { useState, useRef, useEffect, Fragment } from 'react'
import ToolTip from '../../Tooltip/Tooltip'
import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Radio from '../../Radio'
import Help from '../../Help/Help'
import Icon from '../../Icon2'
import Info from '../../Info/Info'
import TagAsSelect from '../../TagAsSelect/TagAsSelect'
import ValidationMessage from '../../ValidationMessage/ValidationMessage'
import { fieldUidRegex } from '../helper'
const FormattingOptions = [
  {
    icon: 'Undo',
    title: 'Undo',
    name: 'undo'
  },
  {
    icon: 'Redo',
    title: 'Redo',
    name: 'redo'
  },
  {
    icon: 'Quote',
    title: 'Quote',
    name: 'blockquote'
  },
  {
    icon: 'Code2',
    title: 'Code',
    name: 'pre'
  },
  {
    icon: 'H1',
    title: 'Heading 1',
    name: 'h1'
  },
  {
    icon: 'H2',
    title: 'Heading 2',
    name: 'h2'
  },
  {
    icon: 'H3',
    title: 'Heading 3',
    name: 'h3'
  },
  {
    icon: 'H4',
    title: 'Heading 4',
    name: 'h4'
  },
  {
    icon: 'H5',
    title: 'Heading 5',
    name: 'h5'
  },
  {
    icon: 'H6',
    title: 'Heading 6',
    name: 'h6'
  },
  {
    icon: 'Bold',
    title: 'Bold',
    name: 'bold'
  },
  {
    icon: 'Underline',
    title: 'Underline',
    name: 'underline'
  },
  {
    icon: 'Italic',
    title: 'Italic',
    name: 'italic'
  },
  {
    icon: 'StrikeThrough',
    title: 'Strikethrough',
    name: 'deleted'
  },
  {
    icon: 'SuperScript',
    title: 'Superscript',
    name: 'sup'
  },
  {
    icon: 'SubScript',
    title: 'Subscript',
    name: 'sub'
  },
  {
    icon: 'List2',
    title: 'Lists',
    name: 'lists'
  },
  {
    icon: 'Alignment',
    title: 'Alignment',
    name: 'alignment'
  }
]

const JsonRte = (props: any) => {
  const FormattingObjects = [
    {
      icon: 'Video2',
      title: 'Video',
      name: 'video'
    },
    {
      icon: 'Asset',
      title: 'Asset',
      name: 'imagemanager'
    },
    {
      icon: 'Table',
      title: 'Table',
      name: 'table'
    },
    {
      icon: 'Properties',
      title: 'Properties',
      name: 'properties'
    },
    {
      icon: 'Link2',
      title: 'Link',
      name: 'link'
    },
    {
      icon: 'Embed',
      title: 'Embed',
      name: 'widget'
    }
  ]
  const {
    onChange,
    options,
    parentInfo,
    uniqueKey,
    schema,
    type,
    openModalCb,
    openExtensionModalCb,
    enableEmbeddedObj,
    enableSocialEmbed,
    plans = {},
    maxMultipleJsonRte = 100,
    isNonLocalizable,
    hasNonLocalizablePlan
  } = props

  const { display_name, uid, field_metadata, reference_to, plugins } = schema

  const preSelectedVal =
    reference_to && reference_to.length
      ? reference_to
          .filter((val) => val !== 'sys_assets')
          .map((val) => {
            return {
              id: val,
              label: val,
              title: val
            }
          })
      : []
  const preSelectedPluginsVal =
    plugins && plugins.length
      ? plugins.map((val) => ({
          id: val,
          label: val,
          title: val
        }))
      : []

  const [initialSchema, setSchema] = useState(schema)
  const [referenceValue, setReference] = useState(preSelectedVal)
  const [pluginsValue, setPlugins] = useState(preSelectedPluginsVal)

  const [selectall, setSelectAll] = useState(false)
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef?.current?.focus()
  }, [displayNameRef])

  const correctEmbedState = () => {
    const widgetIndex = field_metadata.options.indexOf('widget')
    const embedEntry = field_metadata['embed_entry']
    if (embedEntry && widgetIndex === -1) {
      field_metadata.options.push('widget')
    } else if (!embedEntry && widgetIndex !== -1) {
      field_metadata.options.splice(widgetIndex, 1)
    }
  }

  useEffect(() => {
    if (field_metadata.rich_text_type === 'custom') {
      correctEmbedState()
      onchange()
    }
  }, [field_metadata.rich_text_type])

  if (enableSocialEmbed && field_metadata.rich_text_type === 'custom') {
    if (!FormattingObjects.some((format) => format['name'] === 'social-embeds')) {
      FormattingObjects.push({
        icon: 'SocialEmbed',
        title: 'Social Embed',
        name: 'social-embeds'
      })
    }
  }

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.embed_object`) {
      if (event.target.checked) {
        field_metadata['embed_entry'] = true
        field_metadata['ref_multiple_content_types'] = true
      } else {
        field_metadata['embed_entry'] = false
      }

      if (field_metadata.rich_text_type === 'custom') {
        const widgetIdx = field_metadata.options.indexOf('widget'),
          isWidget = widgetIdx !== -1
        if (event.target.checked && !isWidget) {
          field_metadata.options.push('widget')
        } else if (!event.target.checked && isWidget) {
          field_metadata.options.splice(widgetIdx, 1)
        }
      }

      schema['reference_to'] = ['sys_assets']
    }
    if (event.target.name === `${uniqueKey}.mandatory`) {
      schema.mandatory = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.multiple`) {
      schema.multiple = event.target.checked
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
      if (!schema.multiple) {
        delete schema.max_instance
      }
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }
  const onEditorTypeChange = (event: any) => {
    if (event.target.value === 'basic') {
      field_metadata.rich_text_type = 'basic'
    }
    if (event.target.value === 'advanced') {
      field_metadata.rich_text_type = 'advanced'
    }
    if (event.target.value === 'custom') {
      field_metadata.rich_text_type = 'custom'
    }
    onchange()
  }
  const onFormatOptionsChange = (e: any, value: any) => {
    e.preventDefault()

    if (!(field_metadata.options && Array.isArray(field_metadata.options))) {
      field_metadata.options = []
    }

    let currentPosition = field_metadata.options.indexOf(value)
    if (currentPosition != -1) {
      field_metadata.options.splice(currentPosition, 1)
    } else {
      field_metadata.options.push(value)
    }
    if (value === 'widget') {
      field_metadata['embed_entry'] = currentPosition === -1
    }
    onchange()
  }

  const onMaxInstance = (event: any) => {
    schema.max_instance = event.target.value ? event.target.value : null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && schema.max_instance < 1) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'max instance cannot be less than 1'
      }
      schema.error = true
    } else if (Number.parseInt(event.target.value) > maxMultipleJsonRte) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: `max instance for json rte cannot exceed ${maxMultipleJsonRte}`
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }

  const onSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectAll(true)

      if (!field_metadata.options) field_metadata.options = []

      FormattingOptions.forEach((item) => {
        if (field_metadata.options.indexOf(item.name) == -1) {
          field_metadata.options.push(item.name)
        }
      })

      FormattingObjects.forEach((item) => {
        if (field_metadata.options.indexOf(item.name) == -1) {
          field_metadata.options.push(item.name)
        }
      })
      field_metadata['embed_entry'] = true
    } else {
      setSelectAll(false)
      field_metadata['embed_entry'] = false
      field_metadata.options = []
    }

    onchange()
  }

  const handleRefUpdate = (selectedRefs) => {
    let newSelectedRef = selectedRefs.length
      ? selectedRefs
          .filter((val) => val !== 'sys_assets')
          .map((val) => {
            return {
              id: val,
              label: val,
              title: val
            }
          })
      : []

    setReference(newSelectedRef)
    if (Array.isArray(selectedRefs) && selectedRefs.length) schema.reference_to = selectedRefs
    else delete schema.reference_to
    onchange()
  }

  const handlePluginUpdate = (selectedPlugins) => {
    let newSelectedPlugin = selectedPlugins.length
      ? selectedPlugins.map((val) => {
          return {
            id: val,
            label: val,
            title: val
          }
        })
      : []
    setPlugins(newSelectedPlugin)
    if (Array.isArray(selectedPlugins) && selectedPlugins.length) schema.plugins = selectedPlugins
    else delete schema.plugins
    onchange()
  }

  if (type === 'basic') {
    return (
      <Fragment>
        <Info
          style={{ marginTop: '-0.625rem', marginBottom: '0.725rem' }}
          icon={<Icon icon="InfoCircleWhite" />}
          content={'This field is available only in the new Contentstack interface.'}
        />
        <div className="FormFields">
          <div className="FormFields__column">
            <Field>
              <FieldLabel required htmlFor="displayName">
                Display Name
              </FieldLabel>
              <TextInput
                required
                name={`${uniqueKey}.displayName`}
                value={display_name}
                onChange={onDisplayNameChange}
                inputRef={displayNameRef}
                error={schema.error && schema.error_details.display_name ? true : false}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
              <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
              <Textarea
                name={`${uniqueKey}.helpText`}
                value={field_metadata.description || ''}
                onChange={onHelpTextChange}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
                // multiline={true}
              />
            </Field>
          </div>
          <div className="FormFields__column">
            <Field>
              <FieldLabel required htmlFor="uid">
                Unique ID
              </FieldLabel>
              <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
              <TextInput
                required
                name={`${uniqueKey}.uid`}
                value={uid}
                onChange={onUidChange}
                error={(schema.error && schema.error_details.uid) || error ? true : false}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
              <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
              <Textarea
                name={`${uniqueKey}.instruction`}
                value={field_metadata.instruction || ''}
                onChange={onInstructionChange}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
                // multiline={true}
              />
            </Field>
            {plans['rtePlugin'] && (
              <Field>
                <FieldLabel htmlFor="referencedExtension">Select JSON RTE Plugin(s)</FieldLabel>
                <Help
                  text={
                    'Extend the functionality of the JSON Rich Text Editor with features such as social embeds, word count, highlights, etc.'
                  }
                />
                <TagAsSelect
                  placeholder={'Select Plugin(s)'}
                  showEmptyState={true}
                  showCount={true}
                  tagName={'Plugin'}
                  tags={pluginsValue.length ? pluginsValue : []}
                  updateTag={handlePluginUpdate}
                  openModalCb={(callback) => openExtensionModalCb(callback)}
                />
              </Field>
            )}
          </div>
        </div>
      </Fragment>
    )
  } else if (type === 'advanced') {
    return (
      <>
        <div className="FormFields row">
          <Field>
            <FieldLabel htmlFor="editorType">Editor Type</FieldLabel>
            <div className="FormFields__selection-wrapper FormFields__selection-wrapper--inline">
              <Radio
                name={`${uniqueKey}.editorType`}
                value="basic"
                checked={field_metadata.rich_text_type === 'basic'}
                text="Basic"
                onChange={onEditorTypeChange}
              />
              <Radio
                name={`${uniqueKey}.editorType`}
                value="advanced"
                checked={field_metadata.rich_text_type === 'advanced'}
                text="Advanced"
                onChange={onEditorTypeChange}
              />
              <Radio
                name={`${uniqueKey}.editorType`}
                value="custom"
                checked={field_metadata.rich_text_type === 'custom'}
                text="Custom"
                onChange={onEditorTypeChange}
              />
            </div>
          </Field>

          {field_metadata.rich_text_type === 'basic' && (
            <Info
              style={{ marginTop: '-0.625rem', marginBottom: '0.725rem' }}
              icon={<Icon icon="InfoCircleWhite" />}
              content={
                'Provides basic formatting tools, which include undo, redo, Code, Quote, Heading 1 - Heading 6, bold, italic, underline, strike-through, alignment options'
              }
            />
          )}
          {field_metadata.rich_text_type === 'advanced' && (
            <Info
              style={{ marginTop: '-0.625rem', marginBottom: '0.725rem' }}
              icon={<Icon icon="InfoCircleWhite" />}
              content={
                'Provides basic formatting tools as well as options to insert images, videos, tables, links, and custom class or ID.'
              }
            />
          )}

          {field_metadata.rich_text_type === 'custom' ? (
            <>
              <div className="FormFields__row FormFields__row--separate">
                <div className="FormFields__format-heading flex-v-center">
                  <FieldLabel htmlFor="formateoptions">Formatting Options</FieldLabel>
                  <Checkbox
                    name={`${uniqueKey}.selectall`}
                    text="Select All"
                    checked={selectall}
                    onChange={onSelectAll}
                  />
                </div>

                <div className="FormFields__formating-options">
                  {FormattingOptions.map((item, index) => {
                    return (
                      <ToolTip key={item.title} content={item.title} position="top">
                        <div
                          className={[
                            'FormFields__formating-option',
                            field_metadata.options && field_metadata.options.indexOf(item.name) !== -1
                              ? ' FormFields__formating-option--active'
                              : ''
                          ].join(' ')}
                          onClick={(e) => onFormatOptionsChange(e, item.name)}>
                          <Icon className="FormFields__formating-icon" key={index} icon={item.icon} />
                        </div>
                      </ToolTip>
                    )
                  })}
                </div>
              </div>
              <div className="FormFields__row">
                <div className="FormFields__format-heading">
                  <FieldLabel htmlFor="formateoptions">Insert Object</FieldLabel>
                </div>
                <div className="FormFields__formating-options">
                  {FormattingObjects.map((item, index) => {
                    return (
                      <ToolTip key={item.title} content={item.title} position="top">
                        <div
                          className={[
                            'FormFields__formating-option',
                            field_metadata.options && field_metadata.options.indexOf(item.name) != -1
                              ? ' FormFields__formating-option--active'
                              : ''
                          ].join(' ')}
                          onClick={(e) => onFormatOptionsChange(e, item.name)}>
                          <Icon className="FormFields__formating-icon" key={index} icon={item.icon} />
                        </div>
                      </ToolTip>
                    )
                  })}
                </div>
              </div>
            </>
          ) : null}

          <div className="FormFields">
            {/* <div className="FormFields__column"> */}
            <Field>
              <FieldLabel htmlFor="options">Options</FieldLabel>
              <div className="FormFields__selection-wrapper">
                {field_metadata.rich_text_type !== 'basic' && (
                  <Field>
                    <Checkbox
                      text={
                        <span style={{ wordBreak: 'break-all' }}>
                          <span style={{ marginRight: '.625rem' }}>Embed Object(s)</span>
                          <Help
                            tippyProps={{ appendTo: document.body }}
                            alignment="right"
                            text={
                              <Fragment>
                                Lets you embed entries within the JSON Rich Text Editor field when creating entries.{' '}
                                <a
                                  style={{ color: 'white' }}
                                  href={
                                    'https://www.contentstack.com/docs/developers/json-rich-text-editor/about-embedded-entries-or-assets-within-json-rte/'
                                  }
                                  target="_blank">
                                  Learn more.
                                </a>
                              </Fragment>
                            }
                          />
                        </span>
                      }
                      name={`${uniqueKey}.embed_object`}
                      checked={field_metadata['embed_entry']}
                      onChange={onOptionsChange}
                    />

                    {field_metadata['embed_entry'] && (
                      <div className="embed-object-dropdown">
                        <TagAsSelect
                          selectText="Embed Object"
                          placeholder={'Select Object(s)'}
                          showEmptyState={true}
                          showCount={true}
                          tags={referenceValue.length ? referenceValue : []}
                          updateTag={handleRefUpdate}
                          openModalCb={(callback) => openModalCb(callback)}
                        />
                      </div>
                    )}
                  </Field>
                )}
                <Checkbox
                  text="Mandatory"
                  name={`${uniqueKey}.mandatory`}
                  checked={schema.mandatory}
                  onChange={onOptionsChange}
                />
                <Checkbox
                  text={
                    <span style={{ wordBreak: 'break-all' }}>
                      <span style={{ marginRight: '.625rem' }}>Multiple</span>
                      <Help
                        tippyProps={{ appendTo: document.body }}
                        alignment="right"
                        text={`Use this field property to set a maximum limit to the number of instances that can be created for this ‘Multiple’ field.
`}
                      />
                    </span>
                  }
                  name={`${uniqueKey}.multiple`}
                  checked={schema.multiple}
                  onChange={onOptionsChange}
                />
                {schema.multiple ? (
                  <div>
                    <Field>
                      <FieldLabel className="ml-0" htmlFor="validation">
                        Set Maximum Limit
                      </FieldLabel>
                      <TextInput
                        type="number"
                        name={`${uniqueKey}.maxinstance`}
                        value={schema.max_instance || null}
                        onChange={onMaxInstance}
                        // placeholder="Maximum"
                        placeholder={props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.maximum'
                        )}
                        error={schema.error && schema.max_instance ? true : false}
                      />
                    </Field>
                  </div>
                ) : null}
                {(parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
                  hasNonLocalizablePlan ? (
                    <>
                      {isNonLocalizable ? (
                        <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                          <Checkbox
                            name={`${uniqueKey}.nonLocalization`}
                            text="Non-localizable"
                            checked={schema.non_localizable}
                            disabled={isNonLocalizable}
                            onChange={onOptionsChange}
                          />
                        </Tooltip>
                      ) : (
                        <Checkbox
                          name={`${uniqueKey}.nonLocalization`}
                          text="Non-localizable"
                          checked={schema.non_localizable}
                          disabled={isNonLocalizable}
                          onChange={onOptionsChange}
                        />
                      )}
                      <div className="nl-note">
                        If enabled, editing this field is restricted in localized entries. The field will use the value
                        of the master-language entry in all localized entries.
                      </div>{' '}
                    </>
                  ) : null
                ) : null}
              </div>
            </Field>
            {/* </div> */}
          </div>
        </div>
      </>
    )
  }
}

export default React.memo(JsonRte)
