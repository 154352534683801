import { parseFromString } from '.';
import { Range } from 'slate'

export { applyPlugins } from './applyPlugins';
export * from './markdown/withMarkdown'

export * from './getRegistry'

export * from './softbreak'

export * from './handleTableArrows'

// export * from './selectAll' // use default selectAll from slate.js

export * from './hooks/withId'

export * from "./parseStringToHtml"

export * from './hooks'

export * from './isMac'

export * from './deserializer';

export const htmlSanitizer = (html) => {
  let parsedHtml = parseFromString(html)
  clean(parsedHtml)
  function clean(html) {
    let nodes = html.children;
    for (let node of nodes) {
      removeAttributes(node);
      clean(node);
    }
  }

  function removeAttributes(elem) {
    let atts = elem.attributes;
    for (let { name, value } of atts) {
      if (!isPossiblyDangerous(name, value)) continue;
      elem.removeAttribute(name);
    }
  }

  function isPossiblyDangerous(name, value) {
    let val = value.replace(/\s+/g, '').toLowerCase();
    if (['src', 'href', 'xlink:href', 'data'].includes(name)) {
      if (val.includes('javascript:') || val.includes('data:text/html')) return true;
    }
    if (name.startsWith('on')) return true;
  }
  return parsedHtml

}

export const isSelectionCollapsed = (selection) => {
  return Range.isCollapsed(selection)
}