import { Editor, Transforms } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'
import { generateId } from '../../../utils/hooks/withId'
import { isSelectionCollapsed } from '../../../utils/queries'

export const LIST_TYPES = ['ol', 'ul']

export const listWrapperElement = (format) => {
  return { type: format, children: [], id: generateId() }
}

export const toggleList = (event, editor, format) => {
  event?.preventDefault()
  const selection = editor.selection || editor.savedSeleciton
  if (selection) {
    // incase selection is from savedSelection
    Transforms.select(editor, selection)

    if (isSelectionCollapsed(selection)) {
      const [currentElement, currentElementPath] = Editor.parent(editor, selection) as any;

      // if it already a list
      if (currentElement.type === 'li') {
        const listType = Editor.parent(editor, currentElementPath) as any;

        // different list then convert
        if (listType?.[0]?.type !== format) {
          Transforms.setNodes(editor, { type: format } as ElementWithType, { at: listType[1] })
        }
        return
      }

      // if prevSibling is also a same list
      if (currentElementPath[currentElementPath.length - 1] > 0) {
        const prevSiblingPath = currentElementPath.slice()
        prevSiblingPath[prevSiblingPath.length - 1] -= 1

        const [prevSibling] = Editor.node(editor, prevSiblingPath) as any;
        if (prevSibling.type === format) {
          Transforms.setNodes(editor, {
            type: 'li'
          } as ElementWithType)

          //@ts-ignore
          const movePosition = [...prevSiblingPath, prevSibling.children.length]
          Transforms.moveNodes(editor, { to: movePosition })
          return
        }
      }
    } else {
      let fixed = false

      // already a list
      for (const [, listItemPath] of Editor.nodes(editor, {
        match: (n: ElementWithType) => n.type === 'li'
      })) {
        fixed = true
        const [listType, listTypePath] = Editor.parent(editor, listItemPath) as any;
        if (listType.type !== format) {
          Transforms.setNodes(editor, { type: format } as ElementWithType, { at: listTypePath })
        }
      }

      if (fixed) return
    }

    // create a new list
    editor.stopNormalizeNode = true
    Transforms.setNodes(editor, {
      type: 'li'
    } as ElementWithType)
    const block = listWrapperElement(format)
    editor.stopNormalizeNode = false;
    Transforms.wrapNodes(editor, block)
  }
}
