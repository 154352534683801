//@ts-nocheck
import React from 'react'
import { Transforms, Editor, Node } from 'slate'
import { useDrop } from 'react-dnd'
import { cx } from '@emotion/css'
import { ReactEditor } from 'slate-react'

import { makeColumn, makeRow, isInsideRow, createNewColumn } from './gridActions'
//@ts-ignore
import styles from '../styles.module.css'
import './style.css';


function GridDropLeft({ editor, element, accept }) {
  const [{ leftOver }, gridDropRight] = useDrop({
    accept: accept,
    collect: (monitor) => {
      return {
        leftOver: monitor.isOver(),
      }
    },
    drop: (item: any, monitor) => {
      const destinationPath = ReactEditor.findPath(editor, element);
      const sourcePath = ReactEditor.findPath(editor, item.element);
      const destinationPathLen = destinationPath.length;
      const sourcePathLen = sourcePath.length;

      /**
       *  CASE 1 : If Source and Destination are same.
       *  output: skip
       */
      if (JSON.stringify(sourcePath) === JSON.stringify(destinationPath)) return

      /**
       *  CASE 2 : If dnd is done inside row
       *  output : change position of dnd
       */
      const isDestinationInsideRow = isInsideRow(editor, destinationPath)
      const isSourceInsideRow = isInsideRow(editor, sourcePath)
      const haveSameParent =
        JSON.stringify(sourcePath.slice(0, sourcePathLen - 2)) ===
        JSON.stringify(destinationPath.slice(0, destinationPathLen - 2))

      if (haveSameParent && isSourceInsideRow && isDestinationInsideRow) {
        /**
         * CASE 1: single element column is dnd
         */
        let rowPath = sourcePath.slice(0, sourcePathLen - 2)
        let row = Node.get(editor, rowPath)
        let sourceColIndex = sourcePath[sourcePathLen - 2]

        if (row.children[sourceColIndex].children.length === 1) {
          Transforms.moveNodes(editor, {
            at: sourcePath.slice(0, sourcePathLen - 1),
            to: [
              ...destinationPath.slice(0, destinationPathLen - 2),
              destinationPath[destinationPathLen - 2],
            ],
          })
        } else {
          createNewColumn(editor, sourcePath, destinationPath)
        }
        return
      }

      /**
       *  CASE 3 : If first time Row is Created
       *  output : two column in a row.
       */

      if (!isDestinationInsideRow) {
        makeColumn(editor, destinationPath, 0.5)
        makeRow(editor, destinationPath)
        Transforms.insertNodes(
          editor,
          {
            type: 'column',
            meta: {
              width: 0.5,
            },
            children: [{ text: '' }],
          },
          { at: destinationPath.concat(0) }
        )
        Transforms.moveNodes(editor, { at: sourcePath, to: destinationPath.concat(0).concat(0) })
      } else {
        /**
         *  CASE 4 : Create new Column
         *  output : create new column
         */
        createNewColumn(editor, sourcePath, destinationPath, 'left')
        return
      }
    },
  })
  return (
    <span
      contentEditable="false"
      ref={gridDropRight}
      className={cx(styles['new-col-left'], leftOver && styles['active-new-col-left'], 'new-col-disable')}
    ></span>
  )
}

function GridDrop({ editor, element, accept }) {
  const [{ rightOver }, gridDropRight] = useDrop({
    accept: accept,
    collect: (monitor) => {
      return {
        rightOver: monitor.isOver(),
      }
    },
    drop: (item: any, monitor) => {
      const destinationPath = ReactEditor.findPath(editor, element)
      const sourcePath = ReactEditor.findPath(editor, item.element)
      const destinationPathLen = destinationPath.length
      const sourcePathLen = sourcePath.length

      /**
       *  CASE 1 : If Source and Destination are same.
       *  output: skip
       */
      if (JSON.stringify(sourcePath) === JSON.stringify(destinationPath)) return

      /**
       *  CASE 2 : If dnd is done inside row
       *  output : change position of dnd
       */
      const isDestinationInsideRow = isInsideRow(editor, destinationPath)
      const isSourceInsideRow = isInsideRow(editor, sourcePath)
      const haveSameParent =
        JSON.stringify(sourcePath.slice(0, sourcePathLen - 2)) ===
        JSON.stringify(destinationPath.slice(0, destinationPathLen - 2))

      if (haveSameParent && isSourceInsideRow && isDestinationInsideRow) {
        /**
         * CASE 1: single element column is dnd
         */
        let rowPath = sourcePath.slice(0, sourcePathLen - 2)
        let row = Node.get(editor, rowPath)
        let sourceColIndex = sourcePath[sourcePathLen - 2]

        if (row.children[sourceColIndex].children.length === 1) {
          Transforms.moveNodes(editor, {
            at: sourcePath.slice(0, sourcePathLen - 1),
            to: [
              ...destinationPath.slice(0, destinationPathLen - 2),
              destinationPath[destinationPathLen - 2] + 1,
            ],
          })
        } else {
          createNewColumn(editor, sourcePath, destinationPath)
        }
        return
      }

      /**
       *  CASE 3 : If first time Row is Created
       *  output : two column in a row.
       */

      if (!isDestinationInsideRow) {
        makeColumn(editor, destinationPath, 0.5)
        makeRow(editor, destinationPath)
        Transforms.insertNodes(
          editor,
          {
            type: 'column',
            meta: {
              width: 0.5,
            },
            children: [{ text: '' }],
          },
          { at: destinationPath.concat(1) }
        )
        Transforms.moveNodes(editor, { at: sourcePath, to: destinationPath.concat(1).concat(0) })
      } else {
        /**
         *  CASE 4 : Create new Column
         *  output : create new column
         */
        createNewColumn(editor, sourcePath, destinationPath)
        return
      }
    },
  });
  return (
    <span
      contentEditable="false"
      ref={gridDropRight}
      className={cx(styles['new-col-right'], rightOver && styles['active-new-col-right'], 'new-col-disable')}
    ></span>
  )
}

/*
 */

export { GridDrop, GridDropLeft }
