import React, { useMemo } from 'react'

//@ts-ignore
import styles from './style.module.css'
import { handleDelete } from '../../../../utils/actions'

import { cx } from '@emotion/css';
import { useFocused, useSelected } from 'slate-react';
import { handleClick } from '../utils/referenceButton'
import Icon from '../../../../../../../Icon2';
import Tooltip from '../../../../../../../Tooltip/Tooltip';
import { ReferenceComponent } from './components';

const EmbedBtn = ({ content, onClick, children, title, testId = "scrte-embed-btn" }) => {
  return (
    <Tooltip position='bottom' content={content}>

      <button id={title} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
        data-testid={testId}
      >
        {children}
      </button>
    </Tooltip>
  )
}

//  to be moved in components
export const EmbedBtnGrp = ({ isError, isDisabled, handleEdit, editor, attributes, handleDelete, handleUnlink, handleCaption, type }) => (
  <>
    {!isError && !isDisabled &&
      <EmbedBtn title='edit' content={type === 'asset' ? 'View Asset' : 'Edit Entry'} onClick={handleEdit} testId={"embed-asset-view"}>
        <Icon icon={type === 'asset' ? 'Eye' : 'Rename'} />
      </EmbedBtn>
    }
    {type !== 'asset' && !isDisabled && handleClick &&
      <EmbedBtn title='replace' content='Replace' onClick={(e) => handleClick(e, editor, { title: 'Replace Entry', type, prefilled: true, attributes })} testId={"embed-entry-replace"} >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="3" width="11" height="2" fill="#647696" />
          <path d="M11 7V1L15 4L11 7Z" fill="#647696" stroke="#647696" strokeLinejoin="round" />
          <rect width="11" height="2" transform="matrix(-1 0 0 1 16 11)" fill="#647696" />
          <path d="M5 15V9L1 12L5 15Z" fill="#647696" stroke="#647696" strokeLinejoin="round" />
        </svg>
      </EmbedBtn>}
    {handleUnlink && !isDisabled &&
      <EmbedBtn title='unlink' content='Unlink' onClick={handleUnlink} testId={"embed-asset-unlink"}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.8203 8.22485L13.881 7.16419C15.2478 5.79736 15.2478 3.58128 13.881 2.21445V2.21445C12.5141 0.847611 10.2981 0.847611 8.93123 2.21445L7.87057 3.27511" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
          <path d="M8.22461 12.821L7.16395 13.8817C5.79711 15.2485 3.58104 15.2485 2.2142 13.8817V13.8817C0.847367 12.5149 0.847367 10.2988 2.2142 8.93196L3.27486 7.8713" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
          <path d="M14.0479 14L2.04785 2" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
          <path d="M11.7598 4.33569L9.992 6.10346" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
          <path d="M4.33496 11.7605L6.10273 9.99273" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </EmbedBtn>}
    {type === 'asset' && !isDisabled && handleCaption &&
      <EmbedBtn title='caption' content='Edit' onClick={handleCaption} testId={"embed-asset-edit"}>
        <Icon icon='Rename' />
      </EmbedBtn>
    }
    {handleDelete &&
      <EmbedBtn title='delete' content='Delete' onClick={handleDelete} testId={"embed-asset-delete"}>
        <Icon icon='Delete'></Icon>
      </EmbedBtn>}
  </>
)

export const InfoIcon = ({ error, isDisabled }) => {
  if (error) {
    return (
      <Tooltip className={'embed-error'} content={error} position='top' variantType='light'>
        <svg style={{ width: '14px' }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.82106 2.8538L1.24076 12.7743C0.303345 14.4409 1.50763 16.5 3.41969 16.5H14.5803C16.4924 16.5 17.6967 14.4409 16.7592 12.7743L11.1789 2.85381C10.2232 1.15468 7.77682 1.15467 6.82106 2.8538Z" fill="#FF5630" stroke="#FF5630" />
          <rect x="7.75" y="8" width="2.5" height="6" rx="1" fill="white" />
          <circle cx="9" cy="5.25" r="1.25" fill="white" />
        </svg>
      </Tooltip>
    );
  }

  if (isDisabled) {
    return (
      <Tooltip appendTo={document.body} className={'embed-error'} content='This feature is not available in your plan.' position='top' variantType='light'>
        <Icon icon="Docs" />
      </Tooltip>
    )
  }

  return null;
}


// to be moved in components


export const InlineReference = ({ entry, editor, element, ...props }) => {
  const { history, apiKey } = editor?.requestProps || {};
  const contentTypeUid = element?.attrs?.['content-type-uid'],
    entryUid = element?.attrs?.['entry-uid'],
    locale = element?.attrs?.['locale'];

  const handleEdit = (e: Event) => {
    history.push(`/stack/${apiKey}/content-type/${contentTypeUid}/${locale}/entry/${entryUid}/edit`)
  }
  return (
    <ReferenceComponent type='inline' entry={entry} editor={editor} content_type_uid={contentTypeUid} handleEdit={handleEdit} handleDelete={() => handleDelete(editor, element)} attributes={{ ...props.attributes, ...props.attrs, element }} children={props.children} />
  )
}

export * from './withInlineReference'
export * from './deserializer';
export * from './components'
