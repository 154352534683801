//@ts-nocheck
import React, { useState, useCallback } from 'react'
import {
  cbModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  ButtonGroup,
  Checkbox,
  TextInput,
  Field,
  ValidationMessage
} from '../../../../../../index'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'

import { isLinkActive, wrapLink, unwrapLink, getLinkDetail } from './utils'
import { onEnter } from '../../../utils/runOnKeyDown'
import { setOldSelection } from '../../../utils/setOldSelection'

const ModalComponent = (props) => {
  const { editor } = props
  const selection = editor.selection || editor.savedSelection
  const linkActive = isLinkActive(editor)
  const [formData, setFormData] = useState(getLinkDetail(editor, selection))
  const [errorMessage, setErrorMessages] = useState(formData['url'] ? '' : 'This field is required.')
  getLinkDetail(editor, selection)

  const handleChange = (e) => {
    e.persist()
    if (e.target.name === 'url') {
      if (e.target.value) {
        setErrorMessages('')
      } else {
        setErrorMessages('The URL provided is invalid. Please provide a valid one.')
      }
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleToggle = (e) => {
    e.persist()
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.checked
      }
    })
  }

  const handleSubmit = () => {
    if (selection) Transforms.select(editor, selection)
    if (formData['url']) {
      wrapLink(editor, formData['url'], formData['text'], formData['inNewTab'])
    } else {
      wrapLink(editor, '#', formData['text'], formData['inNewTab'])
    }
    props.closeModal()
  }

  const removeLink = () => {
    if (selection) Transforms.select(editor, selection)
    unwrapLink(editor)
    props.closeModal()
  }

  return (
    <div
      onKeyDown={(event) =>
        onEnter(event, () => {
          if (!errorMessage) {
            handleSubmit()
          }
        })
      }>
      <ModalHeader title="Hyperlink" closeModal={props.closeModal} />
      <ModalBody>
        <Field labelText="URL" required={true}>
          <TextInput
            autoFocus
            data-test="hyperlink-input"
            name="url"
            value={formData['url']}
            onChange={handleChange}
            required
            placeholder="Enter URL"
            id="url"
            error={!!errorMessage}
          />
          <ValidationMessage style={{ marginLeft: '10px', marginTop: '5px' }}>{errorMessage}</ValidationMessage>
        </Field>

        <Field labelText="Display text">
          <TextInput
            name="text"
            value={formData['text']}
            onChange={handleChange}
            type="text"
            placeholder="Enter Text"
            autoComplete="off"
          />
        </Field>

        <Field style={{ paddingLeft: '0.6rem' }}>
          <Checkbox
            tabIndex={3}
            name="inNewTab"
            checked={formData['inNewTab']}
            text="Open link in new tab"
            onChange={handleToggle}
          />
        </Field>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button data-testid="hyperlink_delete_button" disabled={!linkActive} onClick={removeLink} key="cancel" buttonType="delete" icon="Delete">
            <span>Remove Link</span>
          </Button>
          <Button data-testid="hyperlink_add_button" key="addButton" id="addUrlBtn" onClick={handleSubmit} icon="CheckedWhite" disabled={!!errorMessage}>
            <span>Add Link</span>
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  )
}

export const handleLinkMouseDown = (event, editor: ReactEditor) => {
  if (event) event.preventDefault()
  if (!(editor.selection || editor.savedSelection)) {
    return
  }

  cbModal({
    modalProps: {
      shouldReturnFocusAfterClose: false,
      onClose: () => setOldSelection(editor)
    },
    component: (thisProps) => <ModalComponent editor={editor} {...thisProps} />
  })
}

export const HyperLinkOverlay = (props) => {
  const editor = useSlate();
  const handleMouseDown = useCallback((event) => {

    if (event) event.preventDefault()
    if (!(editor.selection || editor.savedSelection)) {
      return
    }
    editor.setFullScreen();
    cbModal({
      modalProps: {
        shouldReturnFocusAfterClose: false,
        onClose: () => setOldSelection(editor)
      },
      component: (thisProps) => <ModalComponent editor={editor} {...thisProps} {...props} />
    })
  }, [])

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isLinkActive(editor)
      })}
    </React.Fragment>
  )
}

export const ShortcutOpenHyperlinkOverlay = (event, editor, format, registryObj) => {

  event.preventDefault()

  if (!(editor.selection || editor.savedSelection)) {
    return
  }

  cbModal({
    modalProps: {
      shouldReturnFocusAfterClose: false,
      onClose: () => setOldSelection(editor)
    },
    component: (thisProps) => <ModalComponent editor={editor} {...thisProps} />
  })

}

export default HyperLinkOverlay
