import React from 'react'
import { Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import { ElementWithType } from '../../../../../../utils/types'

export const insertDivider = (e, { editor }) => {
  e.preventDefault()
  Transforms.insertNodes(editor, {
    type: 'hr',
    children: [{ text: '' }],
  } as ElementWithType);
}

export const AddDividerButton = (props) => {
  const editor = useSlateStatic()
  return (
    <React.Fragment>{props.children({ handleMouseDown: insertDivider, additionalParams: { editor } })}</React.Fragment>
  )
}

export const ShortcutAddDivider = (event, editor, format, registryObj) => {
  insertDivider(event, { editor })
}
