import { createRowWithColSize } from './createRowWithColSize'
export const createTableBody = (cols) => {
    let body = {
        type: 'tbody',
        attrs: {},
        children: []
    }
    let row = createRowWithColSize(cols)
    body.children = [row]
    return JSON.parse(JSON.stringify(body))
}