import React, { useContext } from 'react'
import { Registry } from '@react-registry'
import { FullscreenIcon, RestoreScreenIcon } from '../../utils/Icon'
import { EditorProvider } from '../../../components/ContextProvider/EditorProvider'
//@ts-ignore
import '../../../components/Editor/fullscreen.css';
import styles from './style.module.css';
import { useRteFullScreen } from '../../../../../../utils';

export const Fullscreen = (props) => {
  const { editorRef } = useContext(EditorProvider)

  const handleMouseDown = () => {
    let classes = editorRef.current.classList;
    if (classes.contains('fullscreen')) {
      classes.remove('fullscreen')
    } else {
      classes.add('fullscreen')
    }
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: useRteFullScreen(editorRef)
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <FullscreenIcon className={styles['fullscreen-icon']} name='Expand' />,
    activeIcon: <RestoreScreenIcon className={styles['fullscreen-icon']} name='Compress' />,
    title: 'Expand RTE',
    activeTitle: 'Minimize RTE',
    IngressComponent: Fullscreen,
    isContentStackElement: true,
    inBasicToolbar: true,
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false
    }
  },
  { id: 'fullscreen', registry: 'element' }
)
