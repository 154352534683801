import React, { Component } from 'react'

import Tooltip from '../Tooltip/Tooltip'
import Icon from '../Icon2'
import cn from 'classnames'

import { getAssetIconSmall } from '../../utils'

import './AssetSmallView.css'

export type DimensionProps = {
  height: number
  width: number
}

export type AssetSmallViewProps = {
  className?: string
  name?: string
  type?: string
  url?: string
  dimensions?: DimensionProps
  testId?: string
  version?: 'v1' | 'v2'
  description?: string
} & typeof defaultProps

const defaultProps = {
  testId: 'cs-asset-small-view'
}

export type AssetDetailProp = {
  name: string
  description?: string
}

export const AssetDetail = ({ name, description }: AssetDetailProp) => {
  const UI = (
    <div className="AssetDetail" data-test-id="cs-asset-detail">
      <div className="AssetDetail--primary">{name}</div>
      <div className="AssetDetail--secondary">{description}</div>
    </div>
  )

  return name.length > 25 ? (
    <Tooltip delay={600} content={name} position="top">
      {UI}
    </Tooltip>
  ) : (
    UI
  )
}

export class AssetSmallView extends Component<AssetSmallViewProps> {
  static defaultProps = defaultProps

  render() {
    let { name, type, url, dimensions, testId, version, description }: any = this.props

    if (type === 'image' && !url) {
      type = 'Document'
    }

    const classCreator = (): string => {
      if (dimensions && dimensions.height && dimensions.width) {
        if (dimensions.height === dimensions.width && dimensions.height >= 40) {
          return `ThumbImage__image_full`
        }
        if (dimensions.height > 40 && dimensions.width > 40) {
          if (dimensions.height < dimensions.width) {
            return `ThumbImage__image-height-full`
          }
          if (dimensions.height > dimensions.width) {
            return `ThumbImage__image-width-full`
          }
        }
        if (dimensions.height >= 40 && dimensions.width < 40) {
          return `ThumbImage__image-height-auto`
        }
        if (dimensions.width >= 40 && dimensions.height < 40) {
          return `ThumbImage__image-width-auto`
        }
      }
      return ''
    }
    const getThumbImageName = () => {
      if (name.length > 25) {
        return (
          <div className="ThumbImageName">
            <Tooltip delay={600} content={name} position="top">
              <div>
                {name.slice(0, 15)}
                <span>...</span>
                {name.slice(name.length - 8, name.length)}
              </div>
            </Tooltip>
          </div>
        )
      }
      return <div className="ThumbImageName">{name}</div>
    }

    const warperCN = cn('AssetSmallView flex flex-v-center', { AssetSmallViewV2: version === 'v2' })

    const assetViewBox = getAssetIconSmall(type) === 'Folder' ? '0 0 14 14' : '0 0 60 60'
    return (
      <div className={warperCN} data-test-id={testId}>
        {type === 'image' ? (
          <>
            <div className="ThumbImage flex-center">
              <img src={url} alt={name} className={classCreator()} />
            </div>
            {version === 'v2' ? <AssetDetail name={name} description={description} /> : getThumbImageName()}
          </>
        ) : (
          <>
            <div className="AssetThumbIcon flex-v-center flex-h-center">
              <Icon size="medium" icon={getAssetIconSmall(type)} viewBox={assetViewBox} />
            </div>
            {version === 'v2' ? <AssetDetail name={name} description={description} /> : getThumbImageName()}
          </>
        )}
      </div>
    )
  }
}

export default AssetSmallView
