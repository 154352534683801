import React, { CSSProperties } from 'react'
import cn from 'classnames'
import './Line.css'

export type LineProps = {
  type: 'dashed' | 'solid',
  className?: string,
  style?: CSSProperties,
  /**
  * value in "px"
  */
  width?: number,
  testId?: string
}

const Line = (props: LineProps) => {
  const { type, className, style, width, testId } = props;

  const classNames = cn('Line', className, {
    'Line--Dashed': type === 'dashed',
    'Line--Solid': type === 'solid',
  })
  return (
    <div
      data-test-id={testId}
      className={classNames}
      style={{ width: width, ...style }}
    />
  )
}
Line.defaultProps = {
  testId: 'cs-line'
}
export default Line;