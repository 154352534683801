//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'

import { createRowWithColSize } from './createRowWithColSize'
import { elementCategories } from '../../elementCategories'
import { TableAddRowAfterIcon } from '../../../utils/Icon'

export const insertRowAfter = (event, { editor }) => {
  const [row] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'tr'
    },
    mode: 'lowest'
  })
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  if (row && table) {
    if (table[0].attrs && table[0].attrs.cols) {
      const newRow = createRowWithColSize(table[0].attrs.cols)
      let path = row[1]
      path[path.length - 1] += 1
      Transforms.insertNodes(editor, newRow, { at: path })
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table[0].attrs,
            rows: table[0].attrs.rows + 1
          }
        },
        { at: table[1] }
      )
    }
  }
}
const insertRowAfterButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  return !table
}
const InsertRowBottomButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: insertRowAfter,
        isDisable: { func: insertRowAfterButtonDisabler, params: { editor } },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}

const shortcutInsertRowAfter = (event, editor, format, registryObj) => {
  insertRowAfter(event, { editor })
}
Registry.register(
  {
    iconName: <TableAddRowAfterIcon />,
    IngressComponent: InsertRowBottomButton,
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false
    },
    shortcut: { key: 'ctrl+shift+down', callback: shortcutInsertRowAfter },
    canCustomize: false,
    title: 'Insert row below',
    isContentStackElement: true
  },
  {
    id: 'table-insert-row-bottom',
    registry: 'v2.element'
  }
)
