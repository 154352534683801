import React from 'react'
import { Node } from 'slate'
import { useSlateStatic } from 'slate-react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../styles.module.css'
import { GridResizer } from '../utils'

function GridCol({ children, attributes, element, slatePath }) {
  const width = element.meta.width
  let isFirst = false
  let isLast = false
  const editor = useSlateStatic()
  if (slatePath) {
    let lastBit = slatePath[slatePath?.length - 1]
    isFirst = lastBit === 0
    let row: any = Node.get(editor, slatePath.slice(0, slatePath.length - 1))
    if (row) {
      isLast = lastBit === row.children.length - 1
    }
  }
  return (
    <div
      {...attributes}
      data-testid='col'
      width={width}
      style={{ width: `calc(100% * ${width})`, paddingLeft: `${isFirst ? 0 : 35}px` }}
      className={styles['scrte-grid-col']}
    >
      {children}
      {!isLast && <GridResizer element={element} />}
    </div>
  )
}

function GridColReadOnly({ children, attributes, element, slatePath }) {
  const width = element.meta.width
  let isFirst = false
  if (slatePath) {
    let lastBit = slatePath[slatePath?.length - 1]
    isFirst = lastBit === 0
  }
  return (
    <div
      {...attributes}
      data-type='column'
      width={width}
      style={{ width: `calc(100% * ${width})`, paddingLeft: `${isFirst ? 0 : 35}px` }}
      className={styles['scrte-grid-col']}
    >
      {children}
    </div>
  )
}

const ComponentMeta = {
  Component: GridCol,
  dndOptions: {
    DisableDND: true,
    DisableSelectionHalo: true,
  },
  ReadOnlyComponent: GridColReadOnly,
}

const registryCondition = {
  id: 'column',
  registry: 'element',
}

Registry.register(ComponentMeta, registryCondition)

export default GridCol
