import { keyDownHooks } from '../hooks'
import { allElements } from '../../components/Toolbar/utils/toolbarElements'
import isHotkey from 'is-hotkey'
import { toggleBlock, toggleMark } from '../../components/Toolbar/Button/buttonUtils'
import { isString } from 'lodash'
import { isMac } from '../isMac'
import { IScrteShortcut } from '../types'
// from element, leaf registry
// from shortcut registry

// leaf -> toggleMark
// element -> toggleBlock

// global shortcuts

const registries = [
  {
    registry: 'element',
    callback: (event, editor, format, registryObj) => {
      toggleBlock(editor, format)
    }
  },
  {
    registry: 'leaf',
    callback: (event, editor, format, registryObj) => {
      toggleMark(editor, format)
    }
  }
]

declare interface ItoolbarConfig {
  customToolbarComponents: any
  contentStack: Boolean
  toolbarMode: string
}
export const handleShortcut = (event, editor, toolbarConfig: ItoolbarConfig) => {
  // global shortcuts from "keyDownHooks" registry
  keyDownHooks({ event, editor })

  registries.forEach((registry) => {
    const elements = allElements(registry.registry)

    elements.forEach(([format, registryObj]) => {
      // filters
      if (!registryObj.shortcut) return
      if (
        toolbarConfig.contentStack &&
        registryObj.isContentStackElement !== undefined &&
        !registryObj.isContentStackElement
      )
        return
      if (toolbarConfig.toolbarMode) {
        switch (toolbarConfig.toolbarMode) {
          case 'basic':
            if (registryObj.inBasicToolbar !== undefined && !registryObj.inBasicToolbar) return
            break
          case 'custom':
            if (
              toolbarConfig.customToolbarComponents !== undefined &&
              //@ts-ignore
              !toolbarConfig.customToolbarComponents?.[registry.registry]?.includes(format)
            )
              return
        }
      }

      const shortcut: IScrteShortcut = registryObj.shortcut

      if (shortcut.isDisable && shortcut.isDisable(event, editor, format, registryObj)) return
      if (!shortcut.key) return

      let shortcutText: string = null
      if (isString(shortcut.key)) {
        shortcutText = shortcut.key
      } else if (isMac() && shortcut.key.mac) {
        shortcutText = shortcut.key.mac
      } else if (shortcut.key.win) {
        shortcutText = shortcut.key.win
      } else return

      if (isHotkey(shortcutText, { byKey: Boolean(shortcut.byKey) }, event)) {
        event.preventDefault()
        if (shortcut.callback) {
          shortcut.callback(event, editor, format, registryObj)
        } else {
          registry.callback(event, editor, format, registryObj)
        }
      }
    })
  })
}
