import './PersonalizationQueryBuilder.scss'

import React, { useState } from 'react'

import { QueryCombinationBuilder } from './query-combination-builder'
import {
  Condition,
  Property,
  PropertyConditionConstraint,
  Query,
  QueryCombination,
  Translations
} from './types'
import { className } from './utils'

export const PersonalizationQueryBuilder = (
  {
    properties,
    conditions,
    onUpdateQuery,
    existingQuery,
    propertyConditionConstraints,
    translations = {
      matchAll: 'All',
      matchAny: 'Any',
      propertyLabel: 'Property',
      conditionLabel: 'Match Condition',
    },
  }: {
    properties: Property[],
    conditions: Condition[],
    onUpdateQuery: (query: Query) => void,
    existingQuery?: Query,
    propertyConditionConstraints?: PropertyConditionConstraint[],
    translations?: Translations,
  }) =>
{
  const [query, updateQuery] = useState<Query | undefined>(existingQuery);

  return (
    <>
      <QueryCombinationBuilder
        query={query as QueryCombination | undefined}
        onUpdateQuery={(newQuery) => {
          updateQuery(newQuery);
          onUpdateQuery(newQuery);
        }}
        properties={properties}
        conditions={conditions}
        translations={translations}
        propertyConditionConstraints={propertyConditionConstraints || []} />
      {
        query && <div className={className('QueryFooter')} />
      }
    </>
  );
}
