
export const createCell = () => {
    let newcol = {
        type: 'td', attrs: {}, children: [{ text: '' }]
    }
    let child: any = {
        type: 'p',
        attrs: {},
        children: [
            {
                text: ''
            }
        ]
    }
    newcol.children = [child]
    return JSON.parse(JSON.stringify(newcol))
}
