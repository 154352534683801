import React from 'react'
import { Registry } from '@react-registry'


const FRAGMENT = ({ attributes, children }) => {
  return (
    <span style={{ display: "inline" }}>{children}</span>
  )
}

export default FRAGMENT
Registry.register(
  {
    Component: FRAGMENT,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'fragment', registry: 'element' }
)