import React from 'react'

interface Props {
  className?: string
  scrollable?: boolean
  children?: React.ReactNode | Element
}

const OAuthCardContent: React.FC<Props> = ({
  scrollable = false,
  className = '',
  children
}: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${className} OAuth_Card_Content ${scrollable ? 'OAuth_Card_Content_Scrollable' : ''}`}>
      {children}
    </div>
  )
}

export default React.memo(OAuthCardContent)
