//@ts-nocheck
import React from 'react'
import { useSlateStatic, useReadOnly, ReactEditor } from 'slate-react'
import { Transforms } from 'slate'
import { Registry } from '@react-registry'
import { Checkbox } from '../../../../../../../index'

import './index.css'
import { elementCategories } from '../../elementCategories'

import JSX from './template'
import { cx } from '@emotion/css'
let alignSetting = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
  justify: 'flex-start',
}
const CheckListItemElement = ({ attributes, children, element, attrs }) => {
  const editor = useSlateStatic()
  const readOnly = useReadOnly()
  const { checked } = element
  const onToggle = (event) => {
    const path = ReactEditor.findPath(editor, element)
    Transforms.setNodes(editor, { checked: event.target.checked }, { at: path })
  }
  let alignment

  if (attrs?.style?.["text-align"]) {
    alignment = {
      justifyContent: alignSetting[attrs.style["text-align"]],
    }
  }
  return (
    <div
      {...attrs}
      className={cx('scrte--checklist', attrs.className)}
      data-type="check-list"
      data-checked={checked}
      style={{ ...alignment }}
    >
      <div
        contentEditable={false}
      // className={styles['scrte--checklist-checkbox']}
      >
        <Checkbox
          type="checkbox"
          checked={checked}
          onChange={onToggle}
          style={{ display: 'inline' }}
        />
      </div>
      <span
        {...attributes}
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className={
          checked ? 'scrte--checklist-text-done' : 'scrte--checklist-text-notDone'
        }
        style={{ display: 'inline-block', marginBottom: '5px' }}
      >
        {children}
      </span>
    </div>
  )
}

Registry.register(
  {
    Component: CheckListItemElement,
    template: JSX.trim(),
    iconName: 'ri-checkbox-multiple-line',
    title: 'Check List',
    subtitle: 'Track tasks with Checklist',
    category: elementCategories.LIST,
    isContentStackElement: true,
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
    },
  },
  { id: 'check-list', registry: 'element' }
)

export * from './deserializer'
