//@ts-nocheck
import { Editor, Transforms } from 'slate'

import { getText, isSelectionCollapsed } from '../../../utils/queries'

export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'a' })
  return Boolean(link)
}

export const getLinkDetail = (editor, selection) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'a' })

  const linkDetailObject = {
    url: '',
    text: '',
    inNewTab: false,
  }

  if (Boolean(link)) {
    const attrs: any = link[0].attrs

    linkDetailObject.url = attrs.url
    linkDetailObject.text = Editor.string(editor, link[1])
    linkDetailObject.inNewTab = attrs.target === '_blank'
  } else if (!isSelectionCollapsed(selection)) {
    linkDetailObject.text = getText(editor, selection)
  }

  return linkDetailObject
}

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'a',
  })
  Transforms.setNodes(
    editor,
    {
      underline: false,
    },
    { match: (n: any) => n.underline }
  )
}

export const wrapLink = (editor?, url?, linkText?, newTab = false) => {
  const selection = editor.selection || editor.savedSelection
  if (!selection) {
    return
  }
  const text = linkText || url

  const linkAttribute = {
    type: 'a',
    attrs: { url, target: newTab ? '_blank' : '_self' },
    children: [{ text: text }],
  }

  if (isLinkActive(editor)) {
    // update existing data
    const [link] = Editor.nodes(editor, { match: (n) => n.type === 'a' })
    const linkObj: any = link[0]
    const linkPath = link[1]

    Transforms.select(editor, linkPath)
    Transforms.setNodes(
      editor,
      { attrs: { ...linkObj.attrs, ...linkAttribute.attrs } },
      {
        match: (n) => n.type === 'a',
      }
    )
    Transforms.insertText(editor, text, { at: editor.selection })
    Transforms.collapse(editor, { edge: 'end' })
  } else if (isSelectionCollapsed(selection)) {
    // create new node
    Transforms.insertNodes(editor, linkAttribute)
  } else {
    // wrap url
    Transforms.wrapNodes(editor, linkAttribute, { split: true })
    Transforms.insertText(editor, text, { at: editor.selection })
    Transforms.collapse(editor, { edge: 'end' })
  }
}
