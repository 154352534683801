import React, { useState, useEffect, memo } from 'react'
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts'
import truncate from 'lodash/truncate'


import './Graph.css'


const prettyNumber = (labelValue: number | string) => {
  return Math.abs(Number(labelValue)) >= 1.0e+9
    ? Math.abs((Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
    : Math.abs(Number(labelValue)) >= 1.0e+6
      ? Math.abs((Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
      : Math.abs(Number(labelValue)) >= 1.0e+3
        ? Math.abs((Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
        : Math.abs(Number(labelValue));
}

// use tspan to handle long text
const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props

  const valueCopy = payload.value
  return (
    <g>
      <text
        x={cx}
        y={cy - 20}
        dy={8}
        textAnchor="middle"
        fill={'#475161'}
        fontSize="18"
        fontWeight={800}
      >
        {prettyNumber(valueCopy)}
      </text>
      <foreignObject x={cx - 50} y={cy - 5} width="100" height="200">
        <div className="GraphModule-Piechart-text-center">
          {truncate(payload.name, { length: 40 })}
        </div>
      </foreignObject>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        className="GraphModule-Piechart-sector"
      />
    </g>
  )
}


const PieChartComponent = (props) => {

  const data = props.data || []
  const [activeIndex, updateIndex] = useState(-1)

  useEffect(() => {
    updateIndex(props.activeIndex)
  }, [props.activeIndex])

  const onPieEnter = (_, index) => {
    updateIndex(index)
  }

  const onPieLeave = () => {
    updateIndex(-1)
  }

  const isAnimationActive = props.isAnimationActive || false
  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          dataKey={props.dataKey}
          data={props.data}
          cy={props.cy}
          innerRadius={props.innerRadius}
          outerRadius={props.outerRadius}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          startAngle={180}
          endAngle={-180}
          paddingAngle={0.5}
          isAnimationActive={isAnimationActive}
        >
          {data.map((entry, index) =>
            <Cell key={`cell-${index}`} fill={entry.color} />
          )}
        </Pie>

      </PieChart>
    </ResponsiveContainer>
  )
}

export default memo(PieChartComponent)