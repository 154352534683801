import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../elementCategories'
//@ts-ignore
import styles from './style.module.css'

import { cx } from '@emotion/css'


const code = (props) => {
  return (
    <div {...props.attributes} {...props.attrs} className={cx(styles['code'], 'scrte-code', props.className)}>
      <pre>
        <code>{props.children}</code>
      </pre>
    </div>
  )
}

export default code
export * from './deserializer'

Registry.register(
  {
    Component: code,
    // iconName: 'ri-code-view',
    title: (
      <span className={styles['code']} style={{ width: '100' }}>
        <pre>
          <code style={{ color: '#222' }}>Code Snippet</code>
        </pre>
      </span>
    ),
    subtitle: 'Write some Code',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+8' },
    isContentStackElement: true,
    inBasicToolbar: true
  },
  { id: 'code', registry: 'element' }
)
