//@ts-nocheck
import { Transforms, Editor } from 'slate'
import { Registry } from '@react-registry'

export const withBlockquote = (editor) => {
  const { insertBreak } = editor

  editor.insertBreak = () => {
    if (editor.selection) {
      const [blockquote] = Editor.nodes(editor, {
        match: (n) => {
          return n.type === 'blockquote'
        }
      })

      if (blockquote) {
        const { selection } = editor
        const nodePath = selection.anchor.path.slice(0, selection.anchor.path.length - 1)
        const insertPath = [...nodePath.slice(0, nodePath.length - 1), nodePath[nodePath.length - 1] + 1]
        Transforms.insertNodes(
          editor,
          { type: 'p', attrs: {}, children: [{ text: '' }] },
          {
            at: insertPath
          }
        )
        Transforms.select(editor, insertPath)
        return
      }
    }
    insertBreak()
  }

  return editor
}
Registry.register(withBlockquote, { id: 'withBlockquote', registry: 'plugin' })
