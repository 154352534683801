import { jsx } from 'slate-hyperscript'

import { getRegistry } from '../../../../utils/getRegistry'
let ELEMENT_TAGS = getRegistry('deserializer')

let TEXT_TAGS = getRegistry('inline-deserializer')
const whiteCharPattern = /^[\s ]+$/
export const deserialize = (el) => {
  if (el.nodeName === 'BODY') {
    ELEMENT_TAGS = getRegistry('deserializer');
    TEXT_TAGS = getRegistry('inline-deserializer');
  }
  if (el.nodeType === 3) {
    if (whiteCharPattern.test(el.textContent)) return null
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return jsx('element', { type: 'p' }, { text: '' })
  }
  const { nodeName } = el
  let parent = el

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }
  //@ts-ignore
  let children = Array.from(parent.childNodes).map(deserialize).flat()

  children = children.filter((child) => child !== null && child !== undefined)

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }
  if (el.getAttribute('data-type') === "ignore-deserializer") {
    return
  }
  if (ELEMENT_TAGS[nodeName] || ELEMENT_TAGS[el.getAttribute('data-type')]) {
    let key = ELEMENT_TAGS[el.getAttribute('data-type')] ? el.getAttribute('data-type') : nodeName

    if (children.length === 0) {
      children = [{ text: '' }]
    }
    const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
    return jsx('element', attrs, children)
  }
  if (TEXT_TAGS[nodeName] || TEXT_TAGS[el.getAttribute('data-type')]) {
    let key = TEXT_TAGS[nodeName] ? nodeName : el.getAttribute('data-type')
    const attrs = TEXT_TAGS[key].components[0]['component'](el)
    return children.map((child) => jsx('text', attrs, child))
  }
  return children
}
