//@ts-nocheck
import React, { useState } from 'react'
import { Transforms, Editor, Node } from 'slate'
import { ReactEditor } from 'slate-react'
import { ModalBody, ModalHeader, ModalFooter, Button, ButtonGroup, Field, TextInput } from '../../../../../../../../index';
import { ImageEditForm } from '../../../image/ImageEditForm'
//@ts-ignore
import styles from './style.module.css'
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';


export const EditCaptionAlt = ({ element, editor, slatePath, entry, ...props }) => {
  const { type } = element.attrs
  const [updatedFormData, setUpdatedFormData] = useState()
  const [hasError, setHasError] = useState(false)

  const updateFormData = (formData) => {
    setUpdatedFormData(formData)
  }

  const handleFormSubmit = (values) => {
    let fields = { ...values }

    if (fields['inline']) {
      if (fields['position'] === 'center' || !fields['position']) {
        fields['position'] = 'left'
      }
    }
    const path = ReactEditor.findPath(editor, element)
    const newAttributes = {
      ...element.attrs,
      "redactor-attributes": {
        ...element.attrs?.["redactor-attributes"]
      },
      style: {
        ...element.attrs?.style
      }
    }
    Object.entries(fields).forEach(([f, v]) => {
      const field = f
      const value: any = v
      if (value && value !== '') {
        newAttributes["redactor-attributes"][field] = value
        if (field === 'inline') {
          newAttributes['inline'] = value
        }

        if (field === 'alt') {
          if (type === 'asset')
            newAttributes['asset-alt'] = value.trim();
          else
            newAttributes['alt'] = value
        }

        if (field === 'url') {
          newAttributes['url'] = value
        }
        if (field === 'caption' || field === 'anchorLink') {
          if (type === 'asset') {
            if (field === 'caption')
              newAttributes['asset-caption'] = value.trim();
            else newAttributes['link'] = value.trim();
          }
          else
            newAttributes[field] = value.trim()
        }

        if (field === 'position') {
          if (type === 'asset') {
            newAttributes['position'] = value;
          }
          if (value === 'none') {
            newAttributes["redactor-attributes"]['position'] = 'none'
            delete newAttributes.style["text-align"]
            delete newAttributes.style["text-align"]
            delete newAttributes.style["float"]
            delete newAttributes.style["width"]
            delete newAttributes.style["max-width"]
            delete newAttributes['max-width']
          } else {
            newAttributes.style["text-align"] = value
            newAttributes["redactor-attributes"]['position'] = value

            if (value !== 'center') {
              if (newAttributes['max-width']) {
                newAttributes.style["max-width"] = `${newAttributes['max-width']}px`
                newAttributes['max-width'] = newAttributes['max-width']
                newAttributes.style["width"] = `${newAttributes.width}px`
                newAttributes['width'] = newAttributes.width
              }
              else {
                newAttributes.style["max-width"] = `${newAttributes['width']}px`
                newAttributes['max-width'] = newAttributes['width']
              }
              newAttributes.style["float"] = value
            }
            else {
              if (newAttributes['max-width']) {
                newAttributes['width'] = newAttributes['max-width']
                delete newAttributes.style["float"]
                delete newAttributes.style["width"]
                delete newAttributes.style["max-width"]
                delete newAttributes['max-width']
              }
            }
          }
        }
        if (field === 'target') {
          newAttributes.target = value ? '_blank' : '_self';
        }
      } else {
        if (field === 'position') {
          delete newAttributes.style["text-align"]
        }
        if (field === 'alt') {
          delete newAttributes['asset-alt']
        }
        if (field === 'caption') {
          delete newAttributes['asset-caption']
        }
        if (field === 'anchorLink') {
          delete newAttributes[`link`]
        }
        delete newAttributes[field]
        delete newAttributes["redactor-attributes"][field]
      }
    })
    let node = Node.get(editor, path)
    let newNode = cloneDeep(node)
    newNode.attrs = { ...newAttributes }
    if (newAttributes.inline) {
      if (Editor.isInline(editor, node)) {
        Transforms.setNodes(editor, { attrs: newAttributes }, { at: path })
      }
      else {
        Transforms.removeNodes(editor, { at: path })
        const inlineReference = {
          type: 'p',
          attrs: {},
          uid: v4().split('-').join(''),
          children: [
            { text: '' },
            newNode,
            { text: '' }
          ]
        }
        Transforms.insertNodes(editor, inlineReference, { at: path })
      }
    }
    else {
      if (Editor.isBlock(editor, node)) {
        Transforms.setNodes(editor, { attrs: newAttributes }, { at: path })
      }
      else {
        Transforms.removeNodes(editor, { at: path })
        path.pop()
        Transforms.insertNodes(editor, newNode, { at: path });
      }
    }

    if (editor.requestProps?.['userAnalytics']) {
      const userAnalytics = editor.requestProps['userAnalytics'];
      const metaData = {
        stack_api_key: editor.requestProps['apiKey'],
        json_rte_field_uid: editor.requestProps['field_uid'],
        json_rte_field_type: editor.requestProps['field_type'],
        embedded_asset_type: element['attrs']['display-type'],
        embedded_asset_uid: element['attrs']['asset-uid'],
        mixpanel: true
      }
      userAnalytics.trackEvent(`Edit Embedded Asset Details`, metaData);
    }
    props.closeModal()
  }

  const handleClose = () => {
    if (editor.requestProps?.['userAnalytics']) {
      const userAnalytics = editor.requestProps['userAnalytics'];
      const metaData = {
        stack_api_key: editor.requestProps['apiKey'],
        json_rte_field_uid: editor.requestProps['field_uid'],
        json_rte_field_type: editor.requestProps['field_type'],
        embedded_asset_type: element['attrs']['display-type'],
        embedded_asset_uid: element['attrs']['asset-uid'],
        mixpanel: true
      }
      userAnalytics.trackEvent(`Cancel ‘Edit Embedded Asset Details’`, metaData);
    }
    props.closeModal();
  }

  return (
    <div>
      <ModalHeader title="Edit Image" closeModal={props.closeModal} />

      <ModalBody>
        <div className={styles['scrte-form-container']}>
          <div>
            <img src={type === 'asset' ? (entry?.url || element.attrs?.['asset-link']) : element.attrs.url} className={styles['modal']} data-testid={'asset-edit-properties__preview'} />
          </div >
          <div>
            <ImageEditForm
              attributes={{
                ...element?.attrs,
                ...element?.attrs?.["redactor-attributes"]
              }}
              updateFormData={updateFormData}
              hasError={{ value: hasError, callback: setHasError }}
            />
          </div>
        </div >
      </ModalBody >

      <ModalFooter>
        <ButtonGroup>
          <Button key="cancel" buttonType="light" onClick={handleClose} data-testid="asset-edit-properties__cancel">
            Cancel
          </Button>
          <Button
            icon={'SaveWhite'}
            key="add"
            disabled={hasError}
            onClick={() => {
              handleFormSubmit(updatedFormData)
            }}
            data-testid="asset-edit-properties__submit"
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div >
  )
}
