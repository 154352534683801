export const lineHeightProvider = (element) => {
  let lineHeightProps = {};
  if (element.type === 'tr') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '100%'
      }
    }
  } else if (element.type === 'h1') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '35px',
        '--scrte-padding-top': '32px'
      }
    }
  } else if (element.type === 'h2') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '32.5px',
        '--scrte-padding-top': '24px'
      }
    }
  } else if (element.type === 'h3') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '27.48px',
        '--scrte-padding-top': '18px'
      }
    }
  } else if (element.type === 'h4') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '26.4px',
        '--scrte-padding-top': '16px'
      }
    }
  } else if (element.type === 'h5') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '24px',
        '--scrte-padding-top': '12px'
      }
    }
  } else if (element.type === 'h6') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '18px',
        '--scrte-padding-top': '8px'
      }
    }
  } else if (element.type === 'p') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '29px'
      }
    }
  } else if (element.type === 'table') {
    lineHeightProps = {
      style: {
        '--scrte-line-height': '50px'
      }
    }
  }
  return lineHeightProps
}
