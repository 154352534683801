//@ts-nocheck
import { Node, Transforms } from 'slate'
import { createRowWithColSize } from './createRowWithColSize'

import { createTableBody } from './createTableBody'
export const insertRowAtEnd = (editor, tablePath) => {
    const table = Node.get(editor, tablePath)
    let tableBodyIndex, noOfRowInTable, tableBodyFlag
    if (table) {
        Array.from(table.children).forEach((child, index0) => {
            if (child.type === 'tbody') {
                tableBodyIndex = index0
                noOfRowInTable = child.children.length
                tableBodyFlag = true
            }
            return
        })

        if (tableBodyFlag) {
            Transforms.insertNodes(editor, createRowWithColSize(table.attrs.cols), { at: [...tablePath, tableBodyIndex, noOfRowInTable] })
            Transforms.setNodes(editor, {
                attrs: {
                    ...table.attrs,
                    rows: table.attrs.rows + 1,
                }
            }, { at: tablePath })
        } else {
            if (table.children.length === 1) {
                Transforms.insertNodes(editor, createTableBody(table.attrs.cols), { at: [...tablePath, 1] })
                Transforms.setNodes(editor, {
                    attrs: {
                        ...table.attrs,
                        rows: table.attrs.rows + 1,
                    }
                }, { at: tablePath })
            }
        }
    }
    return
}
