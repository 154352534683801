import React, { ReactNode, Component } from 'react'
import cn from 'classnames'

import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import Radio from '../Radio/Radio'
import ProgressBar from '../ProgressBar/ProgressBar'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import Tooltip from '../Tooltip/Tooltip'

import { formatBytes, getAssetIcon, classCreator } from '../../utils'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'

import './AssetCardVertical.css'

const defaultProps = {
  assetType: 'imageBroken',
  // imageHeight: 100,
  // imageWidth: 200,
  canHover: true,
  checked: false,
  isRadio: false,
  isCardDisabled: false,
  testId: 'cs-asset-card-vertical'
}

export interface AssetCardVerticalState {
  hoverState: boolean
}

export type Actions = {
  actionTitle: string
  actionIcon: ReactNode
  actionOnClick: Function
}

export type AssetCardVerticalProps = {
  // className?: string
  assetType?:
    | 'image'
    | 'folder'
    | 'code'
    | 'pdf'
    | 'excel'
    | 'presentation'
    | 'document'
    | 'json'
    | 'text/plain'
    | 'zip'
    | 'video'
    | 'audio'
    | 'imageBroken'
    | 'image/tiff'
  assetUrl?: string
  title?: string
  size?: number
  height?: any
  width?: any
  onCardClick?: Function
  onEditClick?: Function
  onCopyClick?: Function
  onDeleteClick?: Function
  onFullScreenClick?: Function
  onChecked?: Function
  fullScreenComponent?: React.ReactNode
  cardData?: any
  // imageHeight?: any
  // imageWidth?: any
  checked?: boolean
  isRadio?: boolean
  progressBar?: number
  canHover?: boolean
  onReloadClick?: Function
  actions?: Actions[]
  isCardDisabled?: boolean
  hoverText?: string
  testId?: string
} & typeof defaultProps

class AssetCardVertical extends Component<AssetCardVerticalProps, AssetCardVerticalState> {
  constructor(props: any) {
    super(props)
    this.state = {
      hoverState: false
    }
  }

  static defaultProps = defaultProps

  handleOnClick = () => {
    if (this.props.onCardClick) {
      this.props.onCardClick(this.props.cardData)
    }
  }

  handleMouseEnter = () => {
    if (this.props.progressBar === -1) {
      this.setState({ hoverState: true })
    }
  }

  handleMouseLeave = () => {
    if (this.props.progressBar === -1) {
      this.setState({ hoverState: false })
    }
  }

  handleOnCheck = (event: object) => {
    this.props.onChecked(event, this.props.cardData)
  }

  handleOnEditClick = () => {
    this.props.onEditClick(this.props.cardData)
  }

  handleOnDeleteClick = () => {
    this.props.onDeleteClick(this.props.cardData)
  }
  handleOnCopyClick = () => {
    this.props.onCopyClick(this.props.cardData)
  }
  handleOnFullScreenClick = () => {
    this.props.onFullScreenClick(this.props.cardData)
  }

  handleOnReloadClick = (event: object) => {
    this.props.onReloadClick(event, this.props.cardData)
  }

  updateActions = (actions) => {
    let updatedActions
    let length = 0

    if (this.props.onEditClick) length++
    if (this.props.onDeleteClick) length++
    if (this.props.onCopyClick) length++

    updatedActions = actions ? actions.slice(0, 4 - length) : null

    return updatedActions
  }

  render() {
    let {
      // className,
      assetType,
      assetUrl,
      title,
      size,
      height,
      width,
      onEditClick,
      onCopyClick,
      onDeleteClick,
      onFullScreenClick,
      onChecked,
      // imageHeight,
      // imageWidth,
      cardData,
      checked,
      isRadio,
      fullScreenComponent,
      progressBar,
      canHover,
      onReloadClick,
      actions,
      isCardDisabled,
      hoverText,
      testId
    } = this.props

    const dimension = height && width ? true : false

    if (assetType === 'image' && !assetUrl) {
      assetType = 'imageBroken'
    }

    //url = `${url}?width=${imageWidth}&height=${imageHeight}&resize-filter=nearest`
    // For quick fix for demo we are removing height and making width as 200 static
    assetUrl = `${assetUrl}`
    const assetTypeClassnames = cn('AssetCard', `AssetCard__${assetType}`, {
      'wo-dimensions': !dimension,
      'checkbox-checked': checked,
      'Radio-checked': isRadio && checked,
      'onhover-disabled': !canHover
    })
    console.log('this.props.onCardClick:', this.props.onCardClick)
    return (
      <div data-test-id={testId} data-testid={testId} title={hoverText}>
        <div className={`${isCardDisabled ? 'AssetCard__disabled' : ''}`}>
          <div
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            className={assetTypeClassnames}>
            {onChecked && progressBar !== -1 ? (
              !isRadio ? (
                <Checkbox checked={checked} onClick={this.handleOnCheck} />
              ) : (
                <Radio
                  aria-label={'cs-asset-card-vertical-radio'}
                  checked={checked}
                  className={`${checked ? 'Radiobox-checked' : ''}`}
                  onClick={this.handleOnCheck}
                />
              )
            ) : null}
            {assetType === 'image' ? (
              <div
                // className={`AssetCard__asset ${assetType === 'image'
                //   ? 'AssetCard__asset--image'
                //   : 'AssetCard__icon flex-v-center flex-h-center'
                //   } ${classCreator(height, width, assetType)}`}
                className={`AssetCard__asset AssetCard__asset--image ${classCreator(height, width, assetType)}  ${
                  !this.props.onCardClick ? 'not--clickable' : ''
                }`}
                onClick={this.handleOnClick}
                data-testid={testId + '--image'}
                style={{ backgroundImage: `url('${assetUrl}')` }}
                // style={assetType === 'image' ? { backgroundImage: `url('${assetUrl}')` } : {}}
              ></div>
            ) : (
              <div
                // className={`AssetCard__asset ${assetType === 'image'
                //   ? 'AssetCard__asset--image'
                //   : 'AssetCard__icon flex-v-center flex-h-center'
                //   }`}
                className={`AssetCard__asset AssetCard__icon flex-v-center flex-h-center ${
                  !this.props.onCardClick ? 'not--clickable' : ''
                }`}
                onClick={this.handleOnClick}>
                <Icon icon={getAssetIcon(assetType)} />
              </div>
            )}
            {onEditClick || onDeleteClick || onCopyClick || actions ? (
              <div className="AssetCard__action flex-v-center">
                {onEditClick && progressBar !== -1 ? (
                  <div onClick={this.handleOnEditClick} className="AssetCard__action__edit">
                    <Tooltip content="Edit" position="top" showArrow={false}>
                      <Icon icon="Edit" width="12px" height="12px" viewBox="0 0 16 16" />
                    </Tooltip>
                  </div>
                ) : null}
                {onCopyClick && progressBar !== -1 ? (
                  <div onClick={this.handleOnCopyClick} className="AssetCard__action__copy">
                    <Tooltip content="Copy" position="top" showArrow={false}>
                      <Icon icon="Copy" width="12px" height="12px" viewBox="0 0 16 16" />
                    </Tooltip>
                  </div>
                ) : null}
                {onDeleteClick ? (
                  <div onClick={this.handleOnDeleteClick} className="AssetCard__action__delete">
                    <Tooltip content="Delete" position="top" showArrow={false}>
                      <Icon icon="DeleteSmall" width="12px" height="12px" viewBox="0 0 14 14" />
                    </Tooltip>
                  </div>
                ) : null}
                {this.updateActions(actions)?.map((action, index) => (
                  <div
                    onClick={() => action.actionOnClick && action.actionOnClick(cardData)}
                    className="AssetCard__action__custom"
                    key={index}>
                    <Tooltip content={action.actionTitle} position="top" showArrow={false}>
                      <div>{action.actionIcon ? action.actionIcon : null} </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            ) : null}
            {onFullScreenClick && !fullScreenComponent && progressBar !== -1 ? (
              <div onClick={this.handleOnFullScreenClick} className="AssetCard__fullScreen">
                <Tooltip content="Expand" position="top" showArrow={false}>
                  <div className="AssetFullScreen__icon-wrapper">
                    <Icon icon="AssetFullScreen" width="12px" height="12px" />
                  </div>
                </Tooltip>
              </div>
            ) : null}
            {fullScreenComponent && !onFullScreenClick && progressBar !== -1 ? (
              <div className="AssetCard__fullScreen flex-v-center flex-h-center">{fullScreenComponent}</div>
            ) : null}
            <div className={`AssetCard__shortinfo ${this.state.hoverState ? 'AssetCard__failed-status' : ''}`}>
              <div className="AssetCard__shortinfo__name">
                <Tooltip content={title} position="top" showArrow={false}>
                  <span>{title}</span>
                </Tooltip>
              </div>
              <div className="AssetCard__shortinfo__smalldetails">
                {progressBar !== -1 && size ? (
                  <div className="AssetCard__shortinfo__smalldetails__size">File Size: {formatBytes(size)} </div>
                ) : null}
                {assetType === 'image' &&
                  height &&
                  width &&
                  progressBar !== -1 &&
                  (progressBar === undefined ||
                  progressBar === null ||
                  (progressBar !== undefined && progressBar !== null && progressBar === 100) ? (
                    <div className="AssetCard__shortinfo__smalldetails__dimensions">
                      {`Dimensions: ${height} x ${width}`}
                    </div>
                  ) : null)}
                {progressBar !== undefined && progressBar !== null && progressBar === -1 ? (
                  <div className="flex-v-center">
                    <ValidationMessage>Failed</ValidationMessage>
                    {onReloadClick ? (
                      <div onClick={this.handleOnReloadClick} data-test-id="cs-reload-click">
                        <Icon icon={'Reload'} className="AssetCard__reload" />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {progressBar !== undefined && progressBar !== null && progressBar !== 100 ? (
                <span className={`${this.state.hoverState && progressBar === -1 ? 'progress-bar-failed' : ''}`}>
                  <ProgressBar
                    percentage={progressBar === -1 ? 100 : progressBar}
                    color={progressBar === -1 ? 'red' : ''}
                  />
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withDeprecatedProp(AssetCardVertical, {
  type: 'assetType',
  url: 'assetUrl',
  onClick: 'onCardClick',
  data: 'cardData',
  isSingle: 'isRadio',
  onHover: 'canHover',
  onReload: 'onReloadClick',
  cardDisabled: 'isCardDisabled',
  actions: [
    {
      title: 'actionTitle',
      icon: 'actionIcon',
      method: 'actionOnClick'
    }
  ]
})
