
import React, { Component } from 'react';
import styles from './style.module.css'

export default class ErrorBoundary extends React.Component {
  state = { error: null }
  iframeRef = React.createRef()

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    const iframe = this.iframeRef.current;
    if (iframe) {
      (iframe as HTMLIFrameElement).onload = function () {
        (iframe as HTMLIFrameElement).parentElement.parentElement.style.width = '100%'
        var doc = (iframe as HTMLIFrameElement).contentDocument || (iframe as HTMLIFrameElement).contentWindow.document;
        var div = doc.createElement('div');
        div.style.display = 'flex'
        div.style.flexDirection = 'column'
        div.style.alignItems = 'center'
        div.style.justifyContent = 'center'
        var InvalidIcon = doc.createElement('div')
        InvalidIcon.style.margin = '0px 0px 15px 32px'
        div.appendChild(InvalidIcon)
        InvalidIcon.style.content = "-webkit-image-set(url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIAQMAAABvIyEEAAAABlBMVEUAAABTU1OoaSf/AAAAAXRSTlMAQObYZgAAAENJREFUeF7tzbEJACEQRNGBLeAasBCza2lLEGx0CxFGG9hBMDDxRy/72O9FMnIFapGylsu1fgoBdkXfUHLrQgdfrlJN1BdYBjQQm3UAAAAASUVORK5CYII=) 1x,url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQAQMAAADdiHD7AAAABlBMVEUAAABTU1OoaSf/AAAAAXRSTlMAQObYZgAAAFJJREFUeF7t0cENgDAMQ9FwYgxG6WjpaIzCCAxQxVggFuDiCvlLOeRdHR9yzjncHVoq3npu+wQUrUuJHylSTmBaespJyJQoObUeyxDQb3bEm5Au81c0pSCD8HYAAAAASUVORK5CYII=) 2x)"
        var InvalidText = doc.createElement('div')
        div.appendChild(InvalidText)
        InvalidText.innerText = 'Invalid Url!'
        InvalidText.style.fontFamily = 'Inter'
        InvalidText.style.color = '#222222'
        doc.body.style.display = 'flex'
        doc.body.style.alignItems = 'center'
        doc.body.style.justifyContent = 'center'
        doc.body.appendChild(div);
        (iframe as HTMLIFrameElement).contentWindow.document.close();
      };
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('logErrorToService: ', errorInfo);
  }

  render() {
    const { error } = this.state
    if (error) {
      return (<iframe ref={this.iframeRef as React.RefObject<HTMLIFrameElement>} src='' width='100%' height='284px' className={styles['invalid-iframe']} />)
    }

    return this.props.children;
  }
}

