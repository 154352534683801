//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'

import { createHeader } from './createHeader'
import { calculateColSize } from './calculateColSize'
import { InsertHeaderIcon } from './CustomTableIcon/insertHeader'
import { elementCategories } from '../../elementCategories'

export const insertHeader = (event, { editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => n.type === 'table',
    mode: 'lowest',
  })
  if (table) {
    const tablecol = calculateColSize(event, editor)
    Transforms.insertNodes(editor, createHeader(tablecol), {
      at: [...table[1], 0],
    })
    Transforms.setNodes(
      editor,
      {
        attrs: {
          ...table[0].attrs,
          rows: table[0].attrs.rows + 1,
        },
      },
      { at: table[1] }
    )
  }
}
const insertHeaderButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => n.type === 'table',
    mode: 'lowest',
  })
  if (table) {
    if (table[0].children[0] && table[0].children[0].type === 'thead') {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
const InsertHeaderButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: insertHeader,
        isDisable: { func: insertHeaderButtonDisabler, params: { editor } },
        CustomIcon: InsertHeaderIcon,
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}
Registry.register(
  {
    IngressComponent: InsertHeaderButton,

    toolbar: {
      inSlashToolbar: false,
      inMainToolbar: false,
      inHoveringToolbar: false,
    },
    canCustomize: false,
    title: 'Insert Header',
    isContentStackElement: true,
  },
  {
    id: 'table-insert-header',
    registry: 'element',
  }
)
