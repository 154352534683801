import { JsonNode } from 'components/RichTextEditor/SuperChargedRte/utils/types'
import { Transforms, NodeEntry, Range, Editor, Element } from 'slate'
import { isPathInRectangle } from '../table'
import { getLowestElementAtSelection } from '../utils'

function addAttribute(editor, nodeEntry: NodeEntry<JsonNode>, key, value) {
  const [node, path] = nodeEntry
  const oldAttributes = node.attrs
  const elementAttribute: any = {
    attrs: {
      ...oldAttributes,
      [key]: value
    }
  }
  Transforms.setNodes(editor, elementAttribute, { at: path })
}

function addAttributeToNodes(editor, nodeEntries: NodeEntry<JsonNode>[], key, value) {
  for (let nodeEntry of nodeEntries) {
    addAttribute(editor, nodeEntry, key, value)
  }
}

export const setElementAttribute = (attributeKey) => (editor, attributeValue, selection) => {
  const excludedElements = ['table', 'tbody', 'tr', 'thead', 'docs', 'ul', 'ol']

  if (!selection) return

  if (Range.isExpanded(selection)) {
    const [table] = Editor.nodes<JsonNode>(editor, { at: selection, match: (n: JsonNode) => n.type === 'table' })

    // If table and Selection is only in table, then apply properties to selected cells
    if (table) {
      const [, tablePath] = table
      const tableRange = Editor.range(editor, tablePath)

      const isSelectionOnlyInTable =
        Range.includes(tableRange, Range.start(editor.selection)) &&
        Range.includes(tableRange, Range.end(editor.selection))

      if (isSelectionOnlyInTable) {
        const nodes = Editor.nodes(editor, {
          match: (node: JsonNode, path) =>
            node.type === 'th' || (node.type === 'td' && isPathInRectangle(editor, path, tablePath))
        })
        addAttributeToNodes(editor, Array.from(nodes), attributeKey, attributeValue)
        return
      }
    }

    const nodes = Editor.nodes<JsonNode>(editor, {
      at: selection,
      match: (node: JsonNode) => Element.isElement(node) && !excludedElements.includes(node.type)
    })

    addAttributeToNodes(editor, Array.from(nodes), attributeKey, attributeValue)
    
  } else {
    const lowestElementAtSelection = getLowestElementAtSelection(editor, selection)
    addAttribute(editor, lowestElementAtSelection, attributeKey, attributeValue)
  }
}

export const getElementAttribute = (attributeKey) => (editor, selection) => {
  if (Range.isExpanded(selection)) return
  let lowestElementAtSelection = getLowestElementAtSelection(editor, selection)[0]
  if (lowestElementAtSelection) {
    const attributes = lowestElementAtSelection.attrs
    if (attributes) {
      return attributes[attributeKey]
    }
  }
}

export const setClass = setElementAttribute('class-name')

export const setId = setElementAttribute('id')

export const getClass = getElementAttribute('class-name')

export const getId = getElementAttribute('id')
