import React, { useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'
import { Registry } from '@react-registry'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from './style.module.css'

import { DndIcon } from './DndIcon'
import helper from './helper'
import { DndPreview } from './DndPreview'

declare interface custom extends Registry {
  dndOptions?: any
}

export const DndElement = (props) => {
  const {
    path,
    children,
    collection,
    elementtype,
    element,
    previewstyleclass,
  } = props
  const previewRef = useRef(null)
  const contentRef = useRef(null)
  const dragIcon = useRef(null)
  const accept = collection ? collection : 'doc'
  const registryGet: custom = Registry.get({
    id: elementtype,
    registry: 'v2.element',
    conditions: {
      overide: true
    }
  })

  const { dndOptions } = registryGet
  const { CustomDndIcon, DisableAdd, dndStyles, ContainerClassName } = dndOptions || {};
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: accept,
      path,
      element,
      dropped: false,
      canDrop: true
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    begin: (monitor) => {
      console.log("begin started");
      helper.addEventListenerForSidebar('scroller', document)
      helper.addEventListenerForPreview(previewRef, window)
    },
    end: (item, monitor) => {
      helper.removeEventListenerForSidebar()
      helper.removeEventListenerForPreview(window)
    }
  })


  // add "dragging" class to body when dragging
  useEffect(() => {
    if (isDragging && !document.body.classList.contains('dragging')) {
      document.body.classList.add('dragging');
    } else if (document.body.classList.contains('dragging')) {
      document.body.classList.remove('dragging');
    }
  }, [isDragging])


  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])


  return (
    <React.Fragment>
      {isDragging && (
        <DndPreview
          previewstyleclass={previewstyleclass}
          previewref={previewRef}
        >
          {props.children}
        </DndPreview>
      )}

      <div
        data-testid="dnd-container"
        className={
          dndStyles?.['draggable-container']
            ? dndStyles['draggable-container']
            : styles['draggable-container']
        }
        style={{ display: 'block', ...props.style }}
      >
        <span
          ref={drag}
          data-testid="draggable"
          contentEditable={false}
          className={
            cx(dndStyles?.['draggable']
              ? dndStyles['draggable']
              : styles['draggable'], ContainerClassName, css`height:var(--scrte-line-height, 24px) !important;margin-top: var(--scrte-padding-top, 0) !important`)
          }
        >
          {CustomDndIcon ? (
            <CustomDndIcon
              path={path}
              className={
                dndStyles?.['draggable-icon'] ? dndStyles['draggable-icon'] : ''
              }
            />
          ) : (
            <DndIcon
              ref={dragIcon}
              className={
                dndStyles?.['draggable-icon']
                  ? dndStyles['draggable-icon']
                  : styles['draggable-icon']
              }
            />
          )}
        </span>
        <div ref={preview} className={styles['p-relative']}>
          <div ref={contentRef}>{children}</div>
        </div>
      </div>
    </React.Fragment>
  )
}
