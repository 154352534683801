import React from 'react'
import AssetImage from './AssetImage/AssetImage';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from './Loader';
import './index.css';

export function GridComponent({ state, handleClick }) {
    return state.assets.map(asset =>
        <AssetImage checked={state.selectedImage.uid === asset.uid} key={asset.uid} asset={asset} handleClick={handleClick}></AssetImage>
    )
}

export const NoAssets = ({ length, children }) => {
    return length === 0 ? (
        <div style={{ textAlign: 'center' }}>
            <h5 style={{ marginBottom: '0.5rem' }}>No Records Found</h5>
        Add some Assets
        </div>
    ) : children
}

export function ThumbnailView({ state, handleClick, loadMoreGrid }) {

    return (
        <div id='scrollableDiv' style={{ height: 265, overflow: 'auto', fontFamily: 'Inter' }} data-testid="asset-grid-view">
            <InfiniteScroll
                loader={<Loader />}
                dataLength={state.assets.length}
                hasMore={state.assets.length < state.count}
                next={loadMoreGrid}
                style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
                scrollableTarget='scrollableDiv'
            >
                {state.loading && <Loader />}
                <GridComponent state={state} handleClick={handleClick} />
            </InfiniteScroll>
        </div>
    )
}
