export * from './history'

export * from './withClearEditor'

export const nextNodePath = (path) => {
  const pathLen = path.length;
  if (!pathLen) return [];
  let nextLastIdx = path[pathLen - 1] + 1;
  if (pathLen === 1) return [nextLastIdx];
  return [...path.slice(0, pathLen - 1), nextLastIdx];
}