import React from 'react';
import Tippy from '@tippyjs/react/headless';
import Icon from '../Icon2';

const RemoveComponent = ({ onRemove, isCursorActive }) => {
  return (
    <div className={`AdvancedSearch__cancel ${isCursorActive ? 'AdvancedSearch__cancel-active' : ''}`}
      onClick={(e) => onRemove(e)}>
      <Icon icon="CancelSec" className="Cancel__icon" />
    </div>
  )
}

const TagRemoveTooltip = ({ children, onRemove, isCursorActive }) => (
  <Tippy
    placement="right"
    render={(attrs) => <RemoveComponent onRemove={onRemove} isCursorActive={isCursorActive} />}
    interactive={true}
    interactiveBorder={-4}
    popperOptions={{
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          enabled: false,
        },
      ],
    }}
  >
    {children}
  </Tippy>
);


export default TagRemoveTooltip
