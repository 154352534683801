//@ts-nocheck
import { jsx } from 'slate-hyperscript'
import { LIST_TYPES } from '../../../Element/list/utils'
import { cloneDeep, isEmpty } from 'lodash';
import { v4 } from 'uuid'

const ELEMENT_TAGS = {
  A: (el) => ({
    type: 'a',
    attrs: {
      url: el.getAttribute('href') || '#'
    }
  }),
  BLOCKQUOTE: () => ({ type: 'blockquote' }),
  H1: () => ({ type: 'h1', attrs: {} }),
  H2: () => ({ type: 'h2', attrs: {} }),
  H3: () => ({ type: 'h3', attrs: {} }),
  H4: () => ({ type: 'h4', attrs: {} }),
  H5: () => ({ type: 'h5', attrs: {} }),
  H6: () => ({ type: 'h6', attrs: {} }),
  IMG: el => {
    return ({ type: 'img', attrs: { url: el.getAttribute('src') ? el.getAttribute('src') : el.getAttribute('srcset'), width: 100 } })
  },
  SPAN: (el) => { return { type: 'span', attrs: {} } },
  LI: () => ({ type: 'li', attrs: {} }),
  OL: () => ({ type: 'ol', attrs: {} }),
  P: () => ({ type: 'p', attrs: {} }),
  PRE: () => ({ type: 'code', attrs: {} }),
  UL: () => ({ type: 'ul', attrs: {} }),
  IFRAME: (el) => ({ type: 'embed', attrs: { url: el.getAttribute('src') } }),
  EMBEDS: (el) => ({ type: 'social-embeds', attrs: { src: el.getAttribute('src') } }),
  TABLE: (el) => ({ type: 'table', attrs: {} }),
  THEAD: (el) => ({ type: 'thead', attrs: {} }),
  TBODY: (el) => ({ type: 'tbody', attrs: {} }),
  TR: (el) => ({ type: 'tr', attrs: {} }),
  TD: (el) => ({ type: 'td', attrs: {} }),
  TH: (el) => ({ type: 'th', attrs: {} })
}

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  //DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  MARK: () => ({ mark: true }),
  B: () => ({ bold: true })
}

const isInline = ['span', 'a']
const isVoid = ["img", "embed", "social-embeds"]

const generateId = () => v4().split('-').join('')

const generateFragment = (child: any) => {
  return { type: 'fragment', attrs: {}, uid: generateId(), children: [child] }
}
const traverseChildAndWarpChild = (children: Array<Object>, inlineElementAttrs) => {
  let inlineElementIndex: Array<number> = []
  let hasBlockElement = false
  let childrenCopy = cloneDeep(children)
  Array.from(children).forEach((child: any, index) => {
    if (child.hasOwnProperty('text')) {
      inlineElementIndex.push(index)
      return
    }
    if (child.hasOwnProperty('type')) {
      if (isInline.includes(child.type)) {
        if (child.type === 'reference') {
          if (child.attrs && (child.attrs['display-type'] === 'inline' || child.attrs['display-type'] === 'link')) {
            inlineElementIndex.push(index)
          } else {
            hasBlockElement = true
          }
        } else {
          inlineElementIndex.push(index)
        }
      } else {
        hasBlockElement = true
      }
    } else {
      childrenCopy[index] = jsx('text', {}, child)
      inlineElementIndex.push(index)
    }
  })
  return Array.from(children).map((child, index) => {
    if (isVoid.includes(child.type) && child.type === 'img') {
      child.attrs = {
        ...child.attrs,
        anchorLink: inlineElementAttrs.attrs.url
      }
      return jsx('element', { type: 'div', attrs: {} }, child)
    }
    if (inlineElementIndex.includes(index)) {
      return generateFragment(jsx("element", { ...inlineElementAttrs }, child))
    }
    return { ...child, children: traverseChildAndWarpChild(child?.children || [], inlineElementAttrs) }
  })
}

const traverseChildWithBlockAndInline = (children: Array<Object>) => {
  let inlineElementIndex: Array<number> = []
  let hasBlockElement = false
  let childrenCopy = cloneDeep(children)
  Array.from(children).forEach((child: any, index) => {
    if (child.hasOwnProperty('text')) {
      inlineElementIndex.push(index)
      return
    }
    if (child.hasOwnProperty('type')) {
      if (isInline.includes(child.type)) {
        if (child.type === "reference") {
          if (child.attrs && (child.attrs['display-type'] === "inline" || child.attrs['display-type'] === "link")) {
            inlineElementIndex.push(index)
          } else {
            hasBlockElement = true
          }
        } else {
          inlineElementIndex.push(index)
        }
      } else {
        hasBlockElement = true
      }
    } else {
      childrenCopy[index] = jsx("text", {}, child)
      inlineElementIndex.push(index)
    }
  })
  if (hasBlockElement && !isEmpty(inlineElementIndex)) {
    Array.from(inlineElementIndex).forEach((child) => {
      children[child] = generateFragment(childrenCopy[child])
    })
  }
  return children
}

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return { text: '\n', break: true }
  } else if (el.nodeName === 'META') {
    return null
  }
  let { nodeName } = el
  let parent = el
  if (nodeName === 'IFRAME' && el.getAttribute('data-tweet-id') || el.getAttribute('src')?.includes('https://www.facebook.com') || el.getAttribute('data-instgrm-payload-id') || nodeName === 'IFRAME' && el.getAttribute('src')?.includes('https://www.tiktok.com')) {
    nodeName = 'EMBEDS'
  }
  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat()
  children = children.filter((child: any) => child !== null)
  children = traverseChildWithBlockAndInline(children)

  if (el.nodeName === 'BODY') {
    if (LIST_TYPES.includes(children?.[0]?.type)) {
      children.unshift({ type: 'p', children: [{ text: '' }] })
    }
    return jsx('fragment', {}, children)
  }
  if (ELEMENT_TAGS[nodeName]) {
    let attrs = ELEMENT_TAGS[nodeName](el)
    if (attrs['type'] === 'li' && el.childNodes[0]?.nodeName === 'P') {
      const text = deserialize(el.childNodes[0].childNodes[0])
      return jsx('element', attrs, text)
    }
    if (nodeName === 'IMG') {
      return jsx('element', attrs, [{ text: '' }])
    }

    if (nodeName === 'EMBEDS' && el.parentNode.getAttribute('cite')) {
      attrs.attrs.src = el.parentNode.getAttribute('cite')
    }
    if (nodeName === 'TABLE') {
      let row = 0,
        col
      Array.from(el.childNodes).forEach((child: any) => {
        if (child.nodeName === 'TBODY' || child.nodeName === 'THEAD') {
          row += child.childNodes.length
          if (child.childNodes[0]) {
            col = child.childNodes[0].childNodes.length
          }
        }
      })
      attrs = {
        ...attrs,
        attrs: {
          ...attrs['attrs'],
          rows: row,
          cols: col,
          colWidths: Array.from({ length: col }).fill(250)
        }
      }
    }
    if (nodeName === 'SPAN') {
      Array.from(children).forEach((child: any) => {
        if (child?.type) {
          if (!isInline.includes(child.type) && !isVoid.includes(child.type)) {
            attrs = {
              type: 'div',
              attrs: {
                orgType: 'span'
              }
            }
          }
        }
      })
    }
    if (nodeName === 'A') {
      if (children.length > 0 && typeof children[0] === 'string') {
        return jsx('element', attrs, children)
      }
      return jsx('element', { type: 'div', attrs: {} }, traverseChildAndWarpChild(children, attrs))
    }

    return jsx('element', attrs, children)
  }

  if (nodeName === 'INPUT') {
    if (el.getAttribute('type') === 'checkbox') {
      let checklistText: any = ''
      if (el.nextSibling?.nodeName === 'LABEL') {
        checklistText = Array.from(el.nextSibling.childNodes).map((child) => {
          return deserialize(child)
        })
      } else if (el.nextSibling?.nextSibling?.nodeName === 'LABEL') {
        checklistText = Array.from(el.nextSibling?.nextSibling?.childNodes).map((child) => {
          return deserialize(child)
        })
      }
      return jsx('element', { type: 'check-list' }, checklistText)
    }
  }

  if (nodeName === 'LABEL') {
    if ((el.previousSibling?.nodeName === 'INPUT' && el.previousSibling?.getAttribute('type') === 'checkbox') || (el.previousSibling?.previousSibling?.nodeName === 'INPUT' &&
      el.previousSibling?.previousSibling?.getAttribute('type') === 'checkbox')) {
      return null
    }
  }

  if (el.getAttribute('data-slate-fragment')) {
    const JsonString = decodeURIComponent(atob(el.getAttribute('data-slate-fragment')))
    let Json = JSON.parse(JsonString)[0]
    while (Json?.children[0]['children']) {
      Json = Json['children'][0]
    }
    delete Json['id']
    return Json
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)

    return children.map((child) => {
      if (typeof child === 'string') return jsx('text', attrs, child)
      return child
    })
  }

  return children
}
