import React, { forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from './style.module.css'

export declare interface ToolbarProps extends React.AllHTMLAttributes<HTMLDivElement> { }

export const ToolbarWrapper = forwardRef(({ className, ...props }: ToolbarProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    <Menu {...props} ref={ref} className={cx(className, styles['fixed-toolbar'])} />
  )
});

export declare interface MenuProps extends React.AllHTMLAttributes<HTMLDivElement> { }

export const Menu = forwardRef(({ className, ...props }: MenuProps, ref: React.Ref<HTMLDivElement>) => (
  <div
    id='scrte-toolbar'
    onMouseDown={(e) => {
      e.preventDefault()
    }}
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        & > * {
          display: inline-block;
        }
      `
    )}
  />
))

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}
