import { Transforms, Editor } from "slate";
import { ReactEditor } from "slate-react";

export const setOldSelection = (editor) => {
  ReactEditor.focus(editor);
  const selection: Range | null = editor.selection || editor.savedSelection;
  if (selection) {
    //@ts-ignore
    setTimeout(() => Transforms.select(editor, selection));
  } else {
    setTimeout(() => Transforms.select(editor, Editor.end(editor, [])));
  }
}
