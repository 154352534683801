import React, { useState, useCallback, useMemo, useEffect, useRef, useLayoutEffect } from 'react'

import './styles.module.css'
import PagedownEditor from './Editor';

const VenusMarkdown = ({
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  value = '',
  onChange,
  requestProps,
  height,
  required,
  onKeyDown,
  placeholder,
  disabled,
  ...props
}) => {

  return (
    <div className={'cs_markdown'} >
      <PagedownEditor
        onUpdate={onChange}
        defaultValue={value}
        requestProps={requestProps}
        disabled={disabled}
        {...props}
      />
    </div >
  )
}

VenusMarkdown.defaultProps = {
  onChange: () => { },
  value: '',
  onFocus: () => { },
  onBlur: () => { },
  onMouseEnter: () => { },
  onMouseLeave: () => { },
  placeholder: 'Write some markdown...',
  uid: null,
  required: false,
  height: '300px',
  requestProps: {},
  onKeyDown: () => { },
  disabled: false,
}

export default VenusMarkdown
