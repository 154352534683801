//@ts-nocheck
/* istanbul ignore file */
import { useState, useCallback, useMemo } from 'react'
import { Range, Editor } from 'slate'
import { toggleBlock, isBlockActive } from '../Editor/helper'
import { getRegistry } from '../../utils'
import { getText } from '../../utils/queries'
import { handleSlashKeyEvents, shouldSkipSlashForElements } from './utils'
const ELEMENTS = []

Object.entries(getRegistry('v2.element')).forEach(([key, value]) => {
  const { component } = value['components'][0]
  if (component?.toolbar?.inSlashToolbar !== false)
    ELEMENTS.push({
      format: key,
      ...component
    })
})

export const SlashElement = function (editor) {
  const [target, setTarget] = useState(null)
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [choice, setChoice] = useState(null)
  const [startingPoint, setstartingPoint] = useState(null)

  const searchElements = useMemo(() => {
    const elements = ELEMENTS.filter((el) => {
      if (el.keywords) {
        return el.keywords.some((keyword) => {
          return keyword.toLowerCase().includes(search)
        })
      }
    })
    if (elements.length === 0) {
      setstartingPoint(null)
      setTarget(null)
      setSearch('')
      setIndex(0)
    }
    return elements
  }, [search])

  const handleSet = (format) => {
    if (!isBlockActive(editor, format)) toggleBlock(editor, format)
    setTarget(null)
  }

  const onKeyDownSlash = useCallback(
    ({ event, dispatch }) => {
      if (event.key === '/') {
        if (shouldSkipSlashForElements(editor, ['blockquote', 'code'])) {
          return
        }
        setstartingPoint(editor.selection.anchor)
      }

      handleSlashKeyEvents.call({ index, setIndex, searchElements, target, dispatch, editor, setChoice, setTarget, handleSet }, event);
    },
    [index, target, search]
  )
  /* istanbul ignore next */
  const onChangeSlash = useCallback(
    (scrollRef) => {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)

        const beforeRange = startingPoint && Editor.range(editor, startingPoint, start)
        const beforeText = beforeRange && getText(editor, beforeRange)

        const beforeMatch = beforeText && beforeText.match(/\/([\w\s]*)$/)

        if (startingPoint) {
          const [_, selectedText] = beforeText.split('/')
          if (scrollRef?.current) {
            scrollRef.current.classList.add('scroll-hidden')
            let firstChild = scrollRef.current.firstChild
            let applyPadding = firstChild.scrollHeight > firstChild.clientHeight
            applyPadding && scrollRef.current.classList.add('pr-4')
          }
          setSearch(selectedText)
          if (beforeMatch[1] === '' || !selectedText || selectedText === '') {
            let slashSelection = {
              anchor: {
                ...editor.selection.anchor,
                offset: editor.selection.focus.offset - 1
              },
              focus: editor.selection.focus
            }
            setTarget(slashSelection)
          } else {
            setTarget(beforeRange)
          }
          setIndex(0)
          return
        }
      }
      if (scrollRef?.current) {
        scrollRef.current.classList.remove('scroll-hidden')
        scrollRef.current.classList.remove('pr-4')
      }
      setTarget(null)
    },
    [setTarget, setSearch, setIndex, startingPoint]
  )
  return {
    onKeyDownSlash,
    onChangeSlash,

    index,
    search,
    target,
    choice,
    startingPoint,

    setChoice,
    handleSet,
    setTarget,
    searchElements,
    setstartingPoint
  }
}
