import throttle from "lodash/throttle";

const OFFSET = 50;
const PX_DIFF = 6;

let scrollIncrement = 0;
let isScrolling = false;
let sidebarElement = null;
let scrollHeightSidebar = 0;
let clientRectBottom = 0;
let clientRectTop = 0;
let previewElement = null;
let previewRef = null;

const goUp = () => {
    scrollIncrement -= PX_DIFF;
    sidebarElement.scrollTop = scrollIncrement;

    if (isScrolling && scrollIncrement >= 0) {
        window.requestAnimationFrame(goUp);
    }
};

const goDown = () => {
    scrollIncrement += PX_DIFF;
    sidebarElement.scrollTop = scrollIncrement;

    if (isScrolling && scrollIncrement <= scrollHeightSidebar) {
        window.requestAnimationFrame(goDown);
    }
};

const onDragOver = event => {

    const isMouseOnTop =
        scrollIncrement >= 0 &&
        event.clientY > clientRectTop &&
        event.clientY < clientRectTop + OFFSET;
    const isMouseOnBottom =
        scrollIncrement <= scrollHeightSidebar &&
        event.clientY > clientRectBottom - OFFSET &&
        event.clientY <= clientRectBottom;

    if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
        isScrolling = true;
        scrollIncrement = sidebarElement.scrollTop;

        if (isMouseOnTop) {
            window.requestAnimationFrame(goUp);
        } else {
            window.requestAnimationFrame(goDown);
        }
    } else if (!isMouseOnTop && !isMouseOnBottom) {
        isScrolling = false;
    }
};

const throttleOnDragOver = throttle(onDragOver, 150);

const addEventListenerForSidebar = (elementId, doc = null) => {
    const document2 = doc ? doc : document
    sidebarElement = document2.getElementById(elementId);
    scrollHeightSidebar = sidebarElement.scrollHeight;
    const clientRect = sidebarElement.getBoundingClientRect();
    clientRectTop = clientRect.top;
    clientRectBottom = clientRect.bottom;
    sidebarElement.addEventListener("dragover", throttleOnDragOver);
};

const removeEventListenerForSidebar = () => {
    isScrolling = false;

    if (sidebarElement) {
        sidebarElement.removeEventListener("dragover", throttleOnDragOver);
    }
};
const handleMousemove = (event) => {
    previewElement = previewRef.current
    if (previewElement) {
        previewElement.style['display'] = 'block'
        previewElement.style['transform'] = `translate(${event.x + 8}px, calc(${event.y}px - 0.8rem))`
    }
}
const throttleOnMouseMove = handleMousemove;
const addEventListenerForPreview = (previewref, doc = null) => {
    const window = doc ? doc : document
    previewRef = previewref
    window.addEventListener("dragover", throttleOnMouseMove)
}
const removeEventListenerForPreview = (doc = null) => {
    const window = doc ? doc : document
    window.removeEventListener("dragover", throttleOnMouseMove)
}
export default {
    addEventListenerForSidebar,
    removeEventListenerForSidebar,
    addEventListenerForPreview,
    removeEventListenerForPreview
};
