import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Dropdown from '../../DropDown/Dropdown'
import Help from '../../Help/Help'
import CreatableInputOnly from '../../Select/Creatable'
import { id } from 'date-fns/esm/locale'
import { fieldUidRegex } from '../helper'
import InstructionText from '../../InstructionText/InstructionText'

const toBytes = (value, type) => {
  if (type === 'kb') {
    return 1024 * value
  } else if (type === 'mb') {
    return 1024 * 1024 * value
  }
}

const toSize = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return n
}

const NonLocalizableDom = ({ hasNonLocalizablePlan, isNonLocalizable, uniqueKey, schema, onOptionsChange }) => {
  if (hasNonLocalizablePlan) {
    return (
      <>
        {isNonLocalizable ? (
          <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
            <Checkbox
              name={`${uniqueKey}.nonLocalization`}
              text="Non-localizable"
              checked={schema.non_localizable}
              disabled={isNonLocalizable}
              onChange={onOptionsChange}
            />
          </Tooltip>
        ) : (
          <Checkbox
            name={`${uniqueKey}.nonLocalization`}
            text="Non-localizable"
            checked={schema.non_localizable}
            disabled={isNonLocalizable}
            onChange={onOptionsChange}
          />
        )}
        <div className="nl-note">
          If enabled, editing this field is restricted in localized entries. The field will use the value of the
          master-language entry in all localized entries.
        </div>
      </>
    )
  }
  return null
}

export const File = (props: any) => {
  const { onChange, options, parentInfo, uniqueKey, schema, type, isNonLocalizable, hasNonLocalizablePlan } = props
  const [widthDimentions, setWidthDimentions] = useState('min')
  const [heightDimentions, setHeightDimentions] = useState('min')
  const [initialSchema, setSchema] = useState(schema)
  const [widthActive, setWidthActive] = useState(false)
  const [heightActive, setHeightActive] = useState(false)
  const [extensions, setExtensions] = useState(schema.extensions && schema.extensions.length ? schema.extensions : [])
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  console.log('File Schema', schema)

  // const [minFileSize, setminFileSize] = useState(typeof schema.min != 'undefined' && schema.min != null ? toSize(schema.min) : null);
  // const [maxFileSize, setmaxFileSize] = useState(typeof schema.max != 'undefined' && schema.max != null ? toSize(schema.max) : null);
  const [minFileSize, setminFileSize] = useState(
    typeof schema.min != 'undefined' && schema.min != null ? schema.min : null
  )
  const [maxFileSize, setmaxFileSize] = useState(
    typeof schema.max != 'undefined' && schema.max != null ? schema.max : null
  )

  const { display_name, uid, field_metadata } = schema

  // const dimension = { width: { type: "min" }, height: { type: "min" } };

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    if (schema.dimension) {
      if (schema.dimension.width) {
        if (schema.dimension.width.min && schema.dimension.width.max) {
          setWidthDimentions(schema.dimension.width.min === schema.dimension.width.max ? 'exact' : 'range')
        } else {
          setWidthDimentions(schema.dimension.width.max ? 'max' : 'min')
        }
        setWidthActive(true)
      }
      if (schema.dimension.height) {
        if (schema.dimension.height.min && schema.dimension.height.max) {
          setHeightDimentions(schema.dimension.height.min === schema.dimension.height.max ? 'exact' : 'range')
        } else {
          setHeightDimentions(schema.dimension.height.max ? 'max' : 'min')
        }
        setHeightActive(true)
      }
      //   schema.dimension.width.enable = schema.dimension
      //     ? schema.dimension.width
      //       ? schema.dimension.width.min || schema.dimension.width.max
      //       : false
      //     : false

      //   schema.dimension.height.enable = schema.dimension
      //     ? schema.dimension.height
      //       ? schema.dimension.height.min || schema.dimension.height.max
      //       : false
      //     : false
    }
    displayNameRef.current.focus()
  }, [displayNameRef])

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))
    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.mandatory`) {
      schema.mandatory = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.multiple`) {
      schema.multiple = event.target.checked
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
      if (!schema.multiple) {
        delete schema.max_instance
      }
    }
    if (event.target.name === `${uniqueKey}.imagesOnly`) {
      field_metadata.image = event.target.checked

      if (event.target.checked) {
        delete schema.extensions
        schema.dimension = { width: { min: null, max: null }, height: { min: null, max: null } }
        setExtensions([])
      } else {
        schema.extensions = []
        delete schema.dimension
        schema.error_details = {
          ...schema.error_details,
          maxWidth: '',
          minWidth: '',
          exactWidth: '',
          maxHeight: '',
          minHeight: '',
          exactHeight: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
        setExtensions([])
      }
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }

    console.log('feld dat', schema, extensions)
    onchange()
  }
  const onFileTypeChange = (event: any) => {
    let extensions = event.target.value.split(',')
    extensions = extensions.map((ext: any) => ext.trim())
    schema.extensions = extensions
    onchange()
  }
  const onminFileSizeChange = (event: any, type: any) => {
    let storeminSize = minFileSize
    let storemaxSize = maxFileSize
    if (type == 'min') {
      if (event.target.value) {
        setminFileSize(Number(event.target.value))
        // schema.min = toBytes(event.target.value, 'mb');
        schema.min = Number(event.target.value)
        storeminSize = Number(event.target.value)
      } else {
        schema.min = null
      }
    } else if (type == 'max') {
      if (event.target.value) {
        setmaxFileSize(Number(event.target.value))
        // schema.max = toBytes(event.target.value, 'mb');
        schema.max = Number(event.target.value)
        storemaxSize = Number(event.target.value)
      } else {
        schema.max = null
      }
    }

    console.log('onminFileSizeChange', storeminSize, storemaxSize)

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && storeminSize > storemaxSize) {
      schema.error_details = {
        ...schema.error_details,
        max_size: 'max value error'
      }
      schema.error = true
    } else if (event.target.value.length && storeminSize < 0) {
      schema.error_details = {
        ...schema.error_details,
        min_size: 'min value error'
      }
      schema.error = true
    } else if (event.target.value.length && storemaxSize < 0) {
      schema.error_details = {
        ...schema.error_details,
        max_size: 'max value error'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_size: '',
        min_size: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }
  const onmaxFileSizeChange = (event: any) => {
    setmaxFileSize(event.target.value)
    // schema.max = toBytes(event.target.value, 'mb');
    schema.max = parseInt(event.target.value)

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && (maxFileSize < 0 || minFileSize > maxFileSize)) {
      schema.error_details = {
        ...schema.error_details,
        max_size: 'max value error'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_size: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }
  const imageDimWidth = [
    {
      label: 'min',
      action: () => {},
      default: widthDimentions == 'min' //dimension.width && dimension.width.min ? (dimension.width && dimension.width.max ? false : true) : true,
    },
    {
      label: 'max',
      action: () => {},
      default: widthDimentions == 'max' //dimension.width && dimension.width.max ? (dimension.width && dimension.width.min ? false : true) : true,
    },
    {
      label: 'range',
      action: () => {},
      default: widthDimentions == 'range' //dimension.width && dimension.width.min && dimension.width && dimension.width.max ? true : false,
    },
    {
      label: 'exact',
      action: () => {},
      default: widthDimentions == 'exact' //dimension.width && dimension.width.min && dimension.width.max ? dimension.width.min === dimension.width.max ? true : false : false,
    }
  ]
  const imageDimHeight = [
    {
      label: 'min',
      action: () => {},
      default: heightDimentions == 'min' //dimension.height.min ? (dimension.height.max ? false : true) : true,
    },
    {
      label: 'max',
      action: () => {},
      default: heightDimentions == 'max' //dimension.height.max ? (dimension.height.min ? false : true) : true,
    },
    {
      label: 'range',
      action: () => {},
      default: heightDimentions == 'range' //dimension.height.min && dimension.height.max ? true : false,
    },
    {
      label: 'exact',
      action: () => {},
      default: heightDimentions == 'exact' //dimension.height.min && dimension.height.max ? dimension.height.min === dimension.height.max ? true : false : false,
    }
  ]
  const onImageDimWidthminChange = (event: any, type: any) => {
    if (type == 'min') {
      schema.dimension.width.min = Number(event.target.value)
    } else if (type == 'max') {
      schema.dimension.width.max = Number(event.target.value)
    } else if (type == 'exact') {
      schema.dimension.width.min = Number(event.target.value)
      schema.dimension.width.max = Number(event.target.value)
    }

    console.log('onImageDimWidthminChange dimension', widthDimentions, schema.error, schema.error_details)

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (widthDimentions == 'min') {
      if (event.target.value.length && widthDimentions == 'min' && schema.dimension.width.min < 0) {
        schema.error_details = {
          ...schema.error_details,
          minWidth: 'min value error'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          minWidth: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    if (widthDimentions == 'max') {
      if (event.target.value.length && widthDimentions == 'max' && schema.dimension.width.max < 0) {
        schema.error_details = {
          ...schema.error_details,
          maxWidth: 'max value error'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          maxWidth: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    if (widthDimentions == 'exact') {
      if (
        event.target.value.length &&
        widthDimentions == 'exact' &&
        (schema.dimension.width.min < 0 || schema.dimension.width.max < 0)
      ) {
        schema.error_details = {
          ...schema.error_details,
          exactWidth: 'exact value error'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          exactWidth: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    if (widthDimentions == 'range') {
      if (
        event.target.value.length &&
        widthDimentions == 'range' &&
        schema.dimension.width.min > schema.dimension.width.max
      ) {
        schema.error_details = {
          ...schema.error_details,
          maxWidth: 'max value error'
        }
        schema.error = true
      } else if (event.target.value.length && widthDimentions == 'range' && schema.dimension.width.min < 0) {
        schema.error_details = {
          ...schema.error_details,
          minWidth: 'min value error'
        }
      } else if (event.target.value.length && widthDimentions == 'range' && schema.dimension.width.max < 0) {
        schema.error_details = {
          ...schema.error_details,
          maxWidth: 'max value error'
        }
      } else {
        schema.error_details = {
          ...schema.error_details,
          maxWidth: '',
          minWidth: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    onchange()
  }

  // const onImageDimWidthmaxChange = (event: any) => {
  //   dimension.width.max = parseInt(event.target.value)
  //   onchange()
  // }
  // const onImageDimWidthexactChange = (event: any) => {
  //   dimension.width.min = parseInt(event.target.value);
  //   dimension.width.max = parseInt(event.target.value);
  //   onchange();
  // }

  const onImageDimHeightminChange = (event: any, type: any) => {
    if (type == 'min') {
      schema.dimension.height.min = Number(event.target.value)
    } else if (type == 'max') {
      schema.dimension.height.max = Number(event.target.value)
    } else if (type == 'exact') {
      schema.dimension.height.min = Number(event.target.value)
      schema.dimension.height.max = Number(event.target.value)
    }

    console.log('onImageDimWidthminChange dimension', schema.error, schema.error_details)

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (heightDimentions == 'min') {
      if (event.target.value.length && heightDimentions == 'min' && schema.dimension.height.min < 0) {
        schema.error_details = {
          ...schema.error_details,
          minHeight: 'min value error'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          minHeight: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    if (heightDimentions == 'max') {
      if (event.target.value.length && heightDimentions == 'max' && schema.dimension.height.max < 0) {
        schema.error_details = {
          ...schema.error_details,
          maxHeight: 'max value error'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          maxHeight: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    if (heightDimentions == 'exact') {
      if (
        event.target.value.length &&
        heightDimentions == 'exact' &&
        (schema.dimension.height.min < 0 || schema.dimension.height.max < 0)
      ) {
        schema.error_details = {
          ...schema.error_details,
          exactHeight: 'exact value error'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          exactHeight: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    if (heightDimentions == 'range') {
      if (
        event.target.value.length &&
        heightDimentions == 'range' &&
        schema.dimension.height.min > schema.dimension.height.max
      ) {
        schema.error_details = {
          ...schema.error_details,
          maxHeight: 'max value error'
        }
        schema.error = true
      } else if (event.target.value.length && heightDimentions == 'range' && schema.dimension.height.min < 0) {
        schema.error_details = {
          ...schema.error_details,
          minHeight: 'min value error'
        }
      } else if (event.target.value.length && heightDimentions == 'range' && schema.dimension.height.max < 0) {
        schema.error_details = {
          ...schema.error_details,
          maxHeight: 'max value error'
        }
      } else {
        schema.error_details = {
          ...schema.error_details,
          maxHeight: '',
          minHeight: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    onchange()
  }
  // const onImageDimHeightmaxChange = (event: any) => {
  //   dimension.height.max = parseInt(event.target.value)
  //   onchange()
  // }
  // const onImageDimHeightexactChange = (event: any) => {
  //   dimension.height.min = parseInt(event.target.value);
  //   dimension.height.max = parseInt(event.target.value);
  //   onchange();
  // }

  const onmaxInstance = (event: any) => {
    schema.max_instance = parseInt(event.target.value)

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && schema.max_instance < 0) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'max instance cannot be less then zero'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }

  if (type === 'basic') {
    return (
      <div className="FormFields">
        {console.log(
          schema.error && schema.error_details.uid,
          'The error is===>',
          error,
          schema.error,
          schema.error_details
        )}
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              Display Name
            </FieldLabel>
            <TextInput
              required
              name={`${uniqueKey}.displayName`}
              value={display_name}
              onChange={onDisplayNameChange}
              inputRef={displayNameRef}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
            <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
            <Textarea
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              Unique ID
            </FieldLabel>
            <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
            <TextInput
              required
              name={`${uniqueKey}.uid`}
              value={uid}
              onChange={onUidChange}
              error={(schema.error && schema.error_details.uid) || error ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
            <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
            <Textarea
              name={`${uniqueKey}.instruction`}
              value={field_metadata.instruction || ''}
              onChange={onInstructionChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
            />
          </Field>
        </div>
      </div>
    )
  } else if (type === 'advanced') {
    return (
      <>
        <div className="FormFields">
          <div className="FormFields__column">
            <Field>
              <FieldLabel htmlFor="options">Options</FieldLabel>
              <div className="FormFields__selection-wrapper">
                <Checkbox
                  name={`${uniqueKey}.mandatory`}
                  text="Mandatory"
                  checked={schema.mandatory}
                  onChange={onOptionsChange}
                />
                <Checkbox
                  name={`${uniqueKey}.multiple`}
                  text="Multiple"
                  checked={schema.multiple}
                  onChange={onOptionsChange}
                />
                {schema.multiple ? (
                  <div>
                    <Field>
                      <FieldLabel className="ml-0" htmlFor="validation">
                        Set maximum Limit
                      </FieldLabel>
                      <TextInput
                        type="number"
                        name={`${uniqueKey}.maxinstance`}
                        value={schema.max_instance || null}
                        onChange={onmaxInstance}
                        // placeholder="maximum"
                        placeholder={props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.maximum'
                        )}
                        error={schema.error && schema.max_instance ? true : false}
                      />
                    </Field>
                  </div>
                ) : null}

                <Checkbox
                  name={`${uniqueKey}.imagesOnly`}
                  text="Allow Images Only"
                  checked={field_metadata.image || false}
                  onChange={onOptionsChange}
                />
                {(parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
                  <NonLocalizableDom
                    hasNonLocalizablePlan={hasNonLocalizablePlan}
                    isNonLocalizable={isNonLocalizable}
                    uniqueKey={uniqueKey}
                    schema={schema}
                    onOptionsChange={onOptionsChange}
                  />
                ) : null}

                {(
                  parentInfo.hasParent
                    ? parentInfo.isMulti && (parentInfo.data_type == 'group' || parentInfo.data_type == 'global_field')
                    : false
                ) ? (
                  <>
                    <div className="mark-group-title">
                      <Checkbox
                        name={`${uniqueKey}.isTitle`}
                        text="Mark as Group title"
                        checked={field_metadata.isTitle || false}
                        onChange={(event) => {
                          let eventData = event.target.checked
                          field_metadata.isTitle = eventData
                          onchange()
                          // groupAsTitle(parentInfo.id, schema, () => {
                          //   console.log('callback triggered groupAsTitle');
                          // });
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </Field>
          </div>
          <div className="FormFields__column">
            <Field>
              <FieldLabel htmlFor="fileSizeLimit">File Size Limit (MB)</FieldLabel>
              <Help text="min and max size (in MB) of file that the user will be allowed to upload." />
              <div className="FormFields__group-fields">
                <TextInput
                  type="number"
                  name={`${uniqueKey}.fileSize.min`}
                  // placeholder="minimum"
                  placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.min')}
                  value={minFileSize}
                  onChange={(e) => onminFileSizeChange(e, 'min')}
                  min={0.001}
                  max={700}
                  error={schema.error && schema.error_details.min_size ? true : false}
                />
                <span className="FormFields__group-separator">to</span>
                <TextInput
                  type="number"
                  name={`${uniqueKey}.fileSize.max`}
                  // placeholder="maximum"
                  placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.max')}
                  value={maxFileSize}
                  max={700}
                  onChange={(e) => onminFileSizeChange(e, 'max')} //{onmaxFileSizeChange}
                  error={schema.error && schema.error_details.max_size ? true : false}
                />
              </div>
            </Field>

            {field_metadata.image ? null : (
              <Field>
                <FieldLabel htmlFor="allowFileType">Allow file type(s)</FieldLabel>
                <Help text="Specific file types or formats that the user will be allowed to upload (e.g., jpg). For multiple values, press 'Enter' after each value." />
                {/* <TextInput
                name={`${uniqueKey}.allowFileType`}
                onChange={onFileTypeChange}
                disabled={field_metadata.image}
                value={schema.extensions ? schema.extensions.join(',') : ''}
              /> */}
                <CreatableInputOnly
                  isClearable
                  placeholder="Enter file types"
                  name={`${uniqueKey}.allowFileType`}
                  onChange={(data) => {
                    console.log('CreatableSelect', data)
                    schema.extensions = data.map((ext) => ext.value)
                    setExtensions(schema.extensions)
                    onchange()
                  }}
                  className="Field__file-types"
                  disabled={field_metadata.image}
                  value={
                    extensions.length
                      ? extensions.map((ext) => ({
                          label: ext,
                          value: ext
                        }))
                      : []
                  }
                  isMulti={true}
                />
              </Field>
            )}
            <div className="file-field-instruction">Each time you add a new file type, press 'Enter.'</div>
          </div>
        </div>
        <div className="FormFields">
          {field_metadata.image ? (
            <Field>
              <FieldLabel htmlFor="imageDimVal">Image Dimension Validation</FieldLabel>

              <div className="FormFields__selection-wrapper">
                <div className="flex-v-center file-dimensions">
                  <div className="file-dimension-property flex-v-center">
                    <Checkbox
                      text="Width"
                      name={`${uniqueKey}.width`}
                      checked={
                        schema.dimension &&
                        schema.dimension.width &&
                        (schema.dimension.width.min || schema.dimension.width.max)
                      }
                      onChange={(event) => {
                        console.log('Width onChange event', event.target.checked, schema.dimension)
                        if (!event.target.checked) {
                          schema.dimension.width = { min: null, max: null }
                          setWidthActive(false)
                        } else {
                          if (schema.dimension && schema.dimension.width) {
                            schema.dimension.width.min = null
                            schema.dimension.width.max = null
                          } else {
                            if (!schema.dimension) {
                              schema.dimension = { width: { min: null, max: null } }
                            } else {
                              schema.dimension.width = { min: null, max: null }
                            }
                          }
                          setWidthActive(true)
                        }
                        // schema.dimension.width.enable = event.target.checked;
                        // schema.widthDimentions = widthDimentions
                        onchange()
                      }}
                    />
                  </div>
                  {widthActive && (
                    <Dropdown
                      type="select"
                      arrowSecondary={true}
                      portalId="content-type-file-field-dropdown"
                      closeAfterSelect={true}
                      list={imageDimWidth}
                      withPortal={true}
                      onChange={(val) => {
                        // schema.widthDimentions = val.label;
                        schema.dimension.width.min = null
                        schema.dimension.width.max = null
                        setWidthDimentions(val.label)
                        onchange()
                      }}
                    />
                  )}
                  {widthActive && widthDimentions == 'min' ? (
                    <div className="ml-5 mr-5">
                      <TextInput
                        name={`${uniqueKey}.width.min`}
                        // placeholder="min (px)"
                        placeholder={`${props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.min'
                        )} (px)`}
                        type="number"
                        onChange={(e) => onImageDimWidthminChange(e, 'min')}
                        value={schema.dimension.width.min || null}
                        error={schema.error && schema.error_details.minWidth ? true : false}
                      />
                    </div>
                  ) : null}
                  {widthActive && widthDimentions == 'range' ? (
                    <div className="ml-5 mr-5">
                      <TextInput
                        name={`${uniqueKey}.width.min`}
                        // placeholder="min (px)"
                        placeholder={`${props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.min'
                        )} (px)`}
                        type="number"
                        onChange={(e) => onImageDimWidthminChange(e, 'min')}
                        value={schema.dimension.width.min || null}
                        error={schema.error && schema.error_details.minWidth ? true : false}
                      />
                    </div>
                  ) : null}
                  {widthActive && widthDimentions == 'exact' ? (
                    <div className="ml-5">
                      <TextInput
                        name={`${uniqueKey}.width.exact`}
                        placeholder="exact (px)"
                        type="number"
                        onChange={(e) => onImageDimWidthminChange(e, 'exact')} //{onImageDimWidthexactChange}
                        value={schema.dimension.width.min || null}
                        error={schema.error && schema.error_details.exactWidth ? true : false}
                      />
                    </div>
                  ) : null}
                  {widthDimentions == 'range' && widthActive ? <div>to</div> : null}
                  {widthActive && (widthDimentions == 'range' || widthDimentions == 'max') ? (
                    <div className="ml-5">
                      <TextInput
                        name={`${uniqueKey}.width.max`}
                        // placeholder="max (px)"
                        placeholder={`${props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.max'
                        )} (px)`}
                        type="number"
                        onChange={(e) => onImageDimWidthminChange(e, 'max')} //{onImageDimWidthmaxChange}
                        value={schema.dimension.width.max || null}
                        error={schema.error && schema.error_details.maxWidth ? true : false}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="flex-v-center file-dimensions">
                  <div className="file-dimension-property flex-v-center">
                    <Checkbox
                      text="Height"
                      name={`${uniqueKey}.height`}
                      //Below commented condition used to give height as checked always
                      // checked={
                      //   schema.dimension && schema.dimension.height && heightActive
                      // }
                      checked={
                        schema.dimension &&
                        schema.dimension.height &&
                        (schema.dimension.height.min || schema.dimension.height.max)
                      }
                      onChange={(event) => {
                        if (!event.target.checked) {
                          schema.dimension.height.min = null
                          schema.dimension.height.max = null
                          setHeightActive(false)
                        } else {
                          if (schema.dimension && schema.dimension.height) {
                            schema.dimension.height.min = null
                            schema.dimension.height.max = null
                          } else {
                            if (!schema.dimension) {
                              schema.dimension = { height: { min: null, max: null } }
                            } else {
                              schema.dimension.height = { min: null, max: null }
                            }
                          }
                          setHeightActive(true)
                        }
                        onchange()
                      }}
                    />
                  </div>
                  {heightActive && (
                    <Dropdown
                      type="select"
                      portalId="content-type-file-field-dropdown"
                      withPortal={true}
                      arrowSecondary={true}
                      closeAfterSelect={true}
                      list={imageDimHeight}
                      onChange={(val) => {
                        schema.dimension.height.min = null
                        schema.dimension.height.max = null
                        setHeightDimentions(val.label)
                        onchange()
                      }}
                    />
                  )}
                  {heightActive && heightDimentions == 'min' ? (
                    <div className="ml-5 mr-5">
                      <TextInput
                        name={`${uniqueKey}.height.min`}
                        // placeholder="min (px)"
                        placeholder={`${props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.min'
                        )} (px)`}
                        type="number"
                        onChange={(e) => onImageDimHeightminChange(e, 'min')}
                        value={schema.dimension.height.min || null}
                        error={schema.error && schema.error_details.minHeight ? true : false}
                      />
                    </div>
                  ) : null}
                  {heightActive && heightDimentions == 'range' ? (
                    <div className="ml-5 mr-5">
                      <TextInput
                        name={`${uniqueKey}.height.min`}
                        // placeholder="min (px)"
                        placeholder={`${props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.min'
                        )} (px)`}
                        type="number"
                        onChange={(e) => onImageDimHeightminChange(e, 'min')}
                        value={schema.dimension.height.min || null}
                        error={schema.error && schema.error_details.minHeight ? true : false}
                      />
                    </div>
                  ) : null}
                  {heightActive && heightDimentions == 'exact' ? (
                    <div className="ml-5">
                      <TextInput
                        name={`${uniqueKey}.height.exact`}
                        placeholder="exact (px)"
                        type="number"
                        onChange={(e) => onImageDimHeightminChange(e, 'exact')}
                        value={schema.dimension.height.min || null}
                        error={schema.error && schema.error_details.exactHeight ? true : false}
                      />
                    </div>
                  ) : null}
                  {heightDimentions == 'range' && heightActive ? <div>to</div> : null}
                  {heightActive && (heightDimentions == 'range' || heightDimentions == 'max') ? (
                    <div className="ml-5">
                      <TextInput
                        name={`${uniqueKey}.height.max`}
                        // placeholder="max (px)"
                        placeholder={`${props.t(
                          'content_type.save_content_type.body.fields.common_properties.placeholders.max'
                        )} (px)`}
                        type="number"
                        onChange={(e) => onImageDimHeightminChange(e, 'max')}
                        value={schema.dimension.height.max || null}
                        error={schema.error && schema.error_details.maxHeight ? true : false}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Field>
          ) : null}
        </div>
      </>
    )
  }
}

export default React.memo(File)
