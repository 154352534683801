import { Registry } from '@react-registry'

export const A = (el) => {
    return {
        type: 'a',
        attrs: {
            url: el.getAttribute('href') || "#",
        }
    }
}

Registry.register(A, { id: 'A', registry: 'deserializer' })