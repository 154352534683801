export type Translations = {
  matchAll: string,
  matchAny: string,
  propertyLabel: string,
  conditionLabel: string,
};

export enum MatchType {
  ANY = 'ANY',
  ALL = 'ALL',
};

export type Query = QueryCombination | QueryFragment;

export type QueryCombination = {
  type: 'QueryCombination',
  matchType: MatchType,
  queries: Query[],
}

export type QueryFragment = {
  type: 'QueryFragment',
  property?: Property,
  condition?: Condition,
}

export type Property = {
  displayName: string,
  id: string,
  value: any,
}

export type ConditionOptionsDisplayName = {
  displayName?: string,
}

export type PropertyConditionConstraint = {
  propertyId: string,
  conditionId: string,
  conditionOptionChoices: (ConditionOptions & ConditionOptionsDisplayName)[],
  conditionDisplayNameOverride?: string,
}

export type Condition = {
  displayName: string,
  id: string,
  value: any,
  conditionOptions?: ConditionOptions,
}

export type ConditionOptions = StringConditionOptions | NumberConditionOptions;

export type StringConditionOptions = {
  type: 'StringConditionOptions',
  value?: string,
}

export type NumberConditionOptions = {
  type: 'NumberConditionOptions',
  value?: number,
}

export const queryCombination = (matchType: MatchType, queries: Query[]): QueryCombination => {
  return {
    type: 'QueryCombination',
    matchType,
    queries,
  };
}

export const queryFragment = (property?: Property, condition?: Condition): QueryFragment => {
  return {
    type: 'QueryFragment',
    property,
    condition,
  };
}
