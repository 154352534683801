import React, { useContext, memo } from 'react'

import { Button } from './index'
//@ts-ignore
import styles from './style.module.css'
//@ts-ignore
import dropdownListStyles from '../style.module.css'
import { EditorProvider } from '../../ContextProvider/EditorProvider'
import { DropdownMenuItem } from './index'
import { cx } from '@emotion/css'
export declare interface TriggerButtonProps {
  value
  toolbarType
  format
  shortcut
  title?: string;
  closeHoveringToolbar: Function
}

export const TriggerButton = ({
  value,
  toolbarType,
  format,
  shortcut,
  closeHoveringToolbar
}: Partial<TriggerButtonProps>) => {
  const { iconName, IngressComponent, activeIcon, activeTitle, title } = value
  const isOnClickEvent = value?.eventType === 'CLICK'
  return (
    <IngressComponent>
      {({ handleMouseDown, active, isDisable, CustomIcon, additionalParams, ref }) => {
        return (
          <Button
            ref={ref}
            active={active}
            isActiveIcon={!!activeIcon}
            onClick={e => {
              e.preventDefault()
              isOnClickEvent && handleMouseDown(e)
              if (title === 'Range Comment') {
                closeHoveringToolbar && closeHoveringToolbar()
              }
            }}
            onMouseDown={(e) => {
              e.preventDefault()
              if (
                isDisable && isDisable.func(isDisable.params)
              ) {
                return
              }
              !isOnClickEvent && handleMouseDown(e, additionalParams)
            }}
            disabled={
              isDisable ? isDisable.func(isDisable.params) : false
            }
            format={format}
            shortcut={shortcut}
            title={title}
            activeTitle={activeTitle}
          >
            {activeIcon && active ? (
              activeIcon
            ) : CustomIcon ? (
              <CustomIcon />
            ) : (
              iconName || 'icon-here'
            )}
          </Button>
        )
      }}
    </IngressComponent>
  )
}

export const TriggerDropdownButton = ({ value, format }) => {
  const { IngressComponent } = value

  return (
    <IngressComponent>
      {({ handleMouseDown, isDisable, CustomIcon, additionalParams, active }) => {
        const disabled = isDisable && isDisable.func(isDisable.params)
        return (
          <span
            className={cx(
              { [styles['trigger-dropdown-disabled']]: disabled },
              { [dropdownListStyles['dropdown__menu__list--active']]: active }
            )}
            onMouseDown={(e) => {
              e.preventDefault()
              if (disabled) {
                return
              }
              handleMouseDown(e, additionalParams)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '.625rem 1.25rem',
              justifyContent: 'space-between'
            }}>
            <DropdownMenuItem title={value.title} shortcut={value.shortcut} icon={value.iconName} format={format} />
          </span>
        )
      }}
    </IngressComponent>
  )
}
