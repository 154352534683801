import axios from "axios";
import Q from '../../../../../../lib/requestModule'
export const fetchContentTypes = async ({ editor }) => {
  const { apiKey, assetUrl, authToken, reference_to, branch, isPlugin } = editor.requestProps;
  const queryParams: Record<string, string> = {
    'include_global_field_schema': 'true',
    'query': JSON.stringify({ "uid": { "$in": reference_to } })
  }
  const params = new URLSearchParams(queryParams).toString();
  const url = `${assetUrl}/content_types?${params}`
  const headers = {
    api_key: apiKey,
    'Content-Type': 'application/json'
  }
  authToken && (headers['authToken'] = authToken);
  branch && (headers['branch'] = branch);
  let res = { data: {} }
  if (isPlugin) {
    res = await Q({
      action: 'getContentTypes',
      params: queryParams
    })
  } else {
    res = await axios({
      method: 'GET',
      url,
      headers
    })
  }
  return res.data
}
