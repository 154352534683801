import React from 'react'
import { useSlate } from 'slate-react'
import { Registry } from '@react-registry'
import { isNodeAligned, alignNode } from '../utils'
import { elementCategories } from '../../elementCategories'
import { JustifyAlignmentIcon } from '../../../utils/Icon'

export const ShortcutAlignmentJustify = (event, editor, format, registryObj) => {
  alignNode(editor, 'justify')
}

export const AlignJustify = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    alignNode(editor, 'justify')
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isNodeAligned(editor, 'justify')
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <JustifyAlignmentIcon />,
    slashIconName: <JustifyAlignmentIcon />,
    category: elementCategories.ALIGNMENT_DROPDOWN,
    shortcut: { key: { win: 'mod+alt+j', mac: 'cmd+ctrl+j' }, callback: ShortcutAlignmentJustify },
    title: 'Justify',
    slashTitle: 'Justify',
    template: '',
    IngressComponent: AlignJustify,
    isContentStackElement: true,
    inBasicToolbar: true,
    canCustomize: false,
    keywords: ['justify']
  },
  { id: 'justify-align', registry: 'v2.element' }
)
