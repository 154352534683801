import React from 'react'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import { toggleMark, isMarkActive } from '../../../components/Toolbar/Button/buttonUtils'

export const toggleSuperscript = (event, editor) => {
  event.preventDefault()
  Editor.removeMark(editor, 'subscript')
  toggleMark(editor, 'superscript')
}

export const shortcutToggleSuperscript = (event, editor, format, registryObj) => {
  toggleSuperscript(event, editor)
}

export const ToggleSuperscriptButton = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    toggleSuperscript(event, editor)
  }

  return (
    <React.Fragment>{props.children({ handleMouseDown, active: isMarkActive(editor, 'superscript') })}</React.Fragment>
  )
}
