export const formatTime = (date) => {
  date = new Date(date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours < 10 ? `0${hours}` : hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return (
    new Date(date).toString().split(" ").slice(1, 4).join(" ") +
    ", " +
    strTime
  );
};