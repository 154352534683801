import castArray from 'lodash/castArray'
import { Editor, Range } from 'slate'
import { Registry } from '@react-registry'

import { getRangeFromBlockStart } from './utils/getRangeFromBlockStart'
import { markdownBlock } from './transforms/markdownBlock'
import { markdownInline } from './transforms/markdownInline'
import { markdownInlineBlock } from './transforms/markdownBlockInline'

import { rules } from './rules'

export const withMarkdown = (editor) => {
  const { insertText } = editor

  editor.insertText = (text) => {
    if (!Range.isCollapsed(editor.selection)) return insertText(text)

    for (const {
      trigger = ' ',
      regexp = false,
      type,
      markup,
      preFormat,
      format,
      mode,
      between,
      ignoreTrim,
      insertTrigger
    } of rules) {
      const triggers = castArray(trigger)

      // Check trigger
      if (!triggers.includes(text)) continue

      const markups = castArray(markup)

      const rangeFromBlockStart = getRangeFromBlockStart(editor)

      const textFromBlockStart = Editor.string(editor, rangeFromBlockStart)

      const valid = () => insertTrigger && insertText(text)

      if (mode === 'block' && regexp) {
        const match = textFromBlockStart.match(markup)
        if (match) {
          if (format?.(editor, match, type, rangeFromBlockStart)) return valid()
        }
      }

      if (markups.includes(textFromBlockStart)) {
        // Start of the block
        markdownBlock(editor, type, rangeFromBlockStart, {
          preFormat,
          format
        })
        return valid()
      }

      if (mode === 'inline-block') {
        if (markdownInlineBlock(editor, { preFormat, markup, format, type })) {
          return valid()
        }
      }

      if (mode === 'inline') {
        if (
          markdownInline(editor, {
            type,
            between,
            ignoreTrim,
            format,
            markup: Array.isArray(markup) ? markup[0] : markup
          })
        ) {
          return valid()
        }
      }
    }

    insertText(text)
  }

  return editor
}

Registry.register(withMarkdown, { id: 'withMarkdown', registry: 'plugin' })
