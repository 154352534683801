import React, { useEffect, useState } from 'react'
import { MentionsInput, Mention } from 'react-mentions'

import { DiscussionProvider } from '../ContextProvider'

import { createNewComments, createNewDiscussion, failureNotification, updateJsonRteDoc, updateSingleComment } from './utils/request'

import { ICommentState, IDiscussionDTO, IMentionItem, IMessageDTO, IRoleDTO, IUserDTO, IUserState } from '../../utils/types'
import styles from './style.module.css'
import "./discussion.css"
import { checkIsRangeComment, getCommentBody, getError, getMessageWithDisplayName, getUserName, isNewDiscussion, removeDeletedMentions } from './utils'
import { cloneDeep, findIndex } from 'lodash'
import { maxMessageLength } from './utils/contants'
import { Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import { getMarkForDiscussionID } from '../../elements/Leaf/rangeComment/helpers'

interface ICommentTextArea {
  userState: IUserState,
  handleOnSave: React.MutableRefObject<any>,
  comment?: IMessageDTO
}
const renderSuggestion = (
  suggestion: any,
  search: any,
  highlightedDisplay: any,
  index: any,
  focused: any,
) => {
  return (
    <div className={'scrte-discussion-body--mention__tagging-title'}>{highlightedDisplay}</div>
  )
}
const defaultStyle = {
  control: {
    width: "312px",
    transition: "height 0.20s ease-in"
  },
  suggestions: {
    marginTop: "30px",
    marginLeft: "10px",
  }
}
const emptyState: ICommentState = {
  message: "",
  to_roles: [],
  to_users: []
}
const CommentTextArea: React.FC<ICommentTextArea> = ({ userState, comment, handleOnSave }) => {
  // TEMP:
  const [state, setState] = useState<ICommentState>(emptyState)
  const editor = useSlateStatic()
  const { fieldMetadata, entryMetadata, editorMetadata, docUID, commentCount, setDiscussions, blockUID, discussionUID, editComment, newDiscussion, setDiscussionState, setActiveDiscussion, error, setError, setFetchingState } = React.useContext(DiscussionProvider)

  // TODO: Refactor useEffect
  useEffect(() => {
    // Disable save button if message is empty
    if (state.message.length === 0) {
      setError({ hasError: true, message: "" })
    }
    const handleNewDiscussion = () => {
      const payloadData = {
        "field": {
          "uid": fieldMetadata.uid,
          "path": fieldMetadata._metadata.path,
          "type": "rteDiscussion",
          "doc_uid": docUID,
          "block_uid": blockUID
        },
        "title": `${fieldMetadata.display_name}-${Date.now()}`
      }
      const isOnlySelection = () => {
        //@ts-ignore
        if (editor.history.undos.length && editor.history.undos[0].length === 1 && editor.history.undos[0][0].type === 'set_selection') {
          //@ts-ignore
          return Array.from(editor.history.undos).every((undo: any) => {
            return Array.from(undo).every((child: any) => child.type === 'split_node' || child.type === 'set_node' || child.type === 'set_selection')
          })
        }
      }

      if (!blockUID) {
        delete payloadData.field.block_uid
      }
      return createNewDiscussion(editorMetadata, entryMetadata, payloadData)
        .then((data) => {
          setFetchingState((prevState) => ({ ...prevState, creatingNewDiscussion: true }))
          setDiscussions((prevState) => {
            const stateClone = { ...prevState }
            if (blockUID) stateClone[blockUID] = data.discussion
            else stateClone[`range-${data.discussion.uid}`] = data.discussion

            return stateClone
          })
          let newActiveDiscussion = data.discussion.uid
          if (!blockUID) {
            Editor.addMark(editor, getMarkForDiscussionID(newActiveDiscussion), true)
            let rteDoc = editor.children[0]
            newActiveDiscussion = 'range-' + newActiveDiscussion;
            if (isOnlySelection()) {
              updateJsonRteDoc(editorMetadata, entryMetadata, payloadData, rteDoc)
            }
          }
          setActiveDiscussion(newActiveDiscussion)
          setFetchingState((prevState) => ({ ...prevState, creatingNewDiscussion: false }))
          return data.discussion
        })

    }
    if (editComment === "") {
      const onSave = () => {
        let createDiscussionPromise: Promise<{ uid: string } | void | IDiscussionDTO> = Promise.resolve({ uid: discussionUID })
        if (newDiscussion) {
          createDiscussionPromise = handleNewDiscussion()
        }
        return createDiscussionPromise
          .then((currentDiscussion) => {
            if (!currentDiscussion) return
            const commentData = {
              ...getCommentBody(state),
              discussion_uid: currentDiscussion.uid
            }
            return createNewComments(editorMetadata, entryMetadata, commentData)
          })
          .then((commentResponse) => {
            setDiscussionState((prevState) => ({
              ...prevState,
              comments: [commentResponse.conversation, ...prevState.comments],
              commentCount: prevState.commentCount + 1,
              newDiscussion: false
            }))
            // Scroll list to top
            setTimeout(() => {
              const commentListContainer = document.getElementById('scrte-discussion-comment--list');
              if (commentListContainer) {
                commentListContainer.scroll({ top: 0, behavior: "smooth" })
              }
            }, 200)
            setState(emptyState)
          })
          .catch((error) => {
            failureNotification(error?.data?.error_message || "Error while creating discussions.", error?.data?.errors)
          })
      }
      handleOnSave.current = onSave
    } else {
      const onUpdate = () => {
        const commentData = {
          ...getCommentBody(state),
          discussion_uid: discussionUID
        }
        return updateSingleComment(editorMetadata, entryMetadata, commentData, comment.uid)
          .then((commentResponse) => {
            // Find the updated comment and update state
            setDiscussionState((prevState) => {
              const cloneComments = cloneDeep(prevState.comments)
              const commentIndex = findIndex(cloneComments, { uid: comment.uid })
              cloneComments.splice(commentIndex, 1, commentResponse.conversation)
              return {
                ...prevState,
                editComment: "",
                comments: cloneComments
              }
            })
          })
          .catch((error) => {
            failureNotification(error?.data?.error_message || "Error while updating comments.", error?.data?.errors)
          })
      }
      handleOnSave.current = onUpdate
    }
  }, [blockUID, discussionUID, state, editComment])

  useEffect(() => {
    if (discussionUID) {
      const element: HTMLTextAreaElement = document.querySelector('.scrte-discussion-body--mention__input')
      if (element && !editorMetadata.isPlugin && (!checkIsRangeComment(discussionUID) || isNewDiscussion(discussionUID))) {
        setTimeout(() => { // need this timeout to diff block selection and input focus
          element.focus()
        }, 100)
      }
    }
  }, [commentCount, discussionUID])

  useEffect(() => {
    // Required to collect intial state for edit
    const to_users: Array<IMentionItem> = []
    const to_roles: Array<IMentionItem> = []
    if (comment?.to_users?.length) {
      comment.to_users.forEach((userId) => {
        const user: IUserDTO = userState.userMap[userId]
        if (user?.active) {
          to_users.push({
            display: `@${user.display}` || getUserName(user),
            id: userId
          })
        }
      })
    }
    if (comment?.to_roles?.length) {
      comment.to_roles.forEach((roleId) => {
        const role: IRoleDTO = userState.roleMap[roleId]
        to_roles.push({
          display: `@${role.name}`,
          id: roleId
        })
      })
    }
    setState({
      message: getMessageWithDisplayName(comment, userState, "text") || "",
      to_roles,
      to_users
    })
  }, [comment, discussionUID])

  useEffect(() => {
    const textAreaElement: HTMLTextAreaElement = document.querySelector('.scrte-discussion-body--mention__input')
    if (state.message.length > 0) {
      textAreaElement.setAttribute('rows', '3')
    } else {
      textAreaElement.setAttribute('rows', '1')
    }

    return () => {
      textAreaElement.setAttribute('rows', '1')
    }
  }, [state.message])

  return (
    <div className={styles['scrte-discussion-body--input']}>
      <>
        {
          userState?.mentionsList?.length &&
          (
            <>
              <MentionsInput
                allowSpaceInQuery={true}
                value={state.message}
                style={defaultStyle}
                onChange={(_event: any, _newValue: any, newPlainTextValue: string, mentions: Array<{ id: string, display: string }>) => {
                  const to_users = state.to_users.slice()
                  const to_roles = state.to_roles.slice()
                  if (mentions.length) {
                    mentions.forEach((mention) => {
                      if (userState.userMap[mention.id]) {
                        to_users.push({ id: mention.id, display: mention.display })
                      }
                      else if (userState.roleMap[mention.id]) {
                        to_roles.push({ id: mention.id, display: mention.display })
                      }
                    })
                  }
                  const updatedMentions = removeDeletedMentions(newPlainTextValue, to_roles, to_users)
                  const errorMessage = getError(newPlainTextValue, updatedMentions.to_roles, updatedMentions.to_users)
                  setError({ hasError: errorMessage !== "", message: errorMessage })
                  setState({
                    message: newPlainTextValue,
                    to_roles: updatedMentions.to_roles,
                    to_users: updatedMentions.to_users
                  })
                }}
                maxLength={maxMessageLength}
                placeholder={"Enter a comment or tag others using '@'"}
                className="scrte-discussion-body--mention"
              >
                <Mention
                  type="user"
                  trigger={/(\@([A-Za-z0-9_.]*))$/}
                  data={userState.mentionsList}
                  markup="@{{__display__||__id__}}"
                  displayTransform={(id: any, display: any) => {
                    return `@${display}`
                  }}
                  renderSuggestion={renderSuggestion}
                  appendSpaceOnAdd
                />
              </MentionsInput>
              <div className={styles['scrte-discussion-input-indicator--wrapper']}>
                <div className={styles['scrte-discussion-input-indicator--error']}>{error.message}</div>
                <div className={styles['scrte-discussion-input-indicator--count']}>{state.message.length}/{maxMessageLength}</div>
              </div>
            </>
          )
        }
      </>
    </div>
  )
}

export default CommentTextArea