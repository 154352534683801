import React from 'react'
import { Node } from 'slate'
import { useSlateStatic } from 'slate-react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../styles.module.css'
import { GridResizer } from '../utils'
import { cx, css } from '@emotion/css'

function GridCol({ children, attributes, element, slatePath }) {
  const width = element.meta.width
  let isFirst = false
  let isLast = false
  const editor = useSlateStatic()
  if (slatePath) {
    const len = slatePath.length;
    let lastBit = slatePath[len - 1];
    isFirst = lastBit === 0
    let row: any = Node.get(editor, slatePath.slice(0, len - 1))
    if (row) {
      isLast = lastBit === row.children.length - 1
    }
  }
  return (
    <div
      {...attributes}
      data-testid='col'
      className={cx(
        styles['scrte-grid-col'],
        css`
          width: calc(100% * ${width});
          padding-left: ${isFirst ? 0 : 35}px;
        `
      )}
    >
      {children}
      {!isLast && <GridResizer element={element} />}
    </div>
  )
}

const ComponentMeta = {
  Component: GridCol,
  dndOptions: {
    DisableDND: true,
    DisableSelectionHalo: true,
  }
}

const registryCondition = {
  id: 'column',
  registry: 'v2.element',
}

Registry.register(ComponentMeta, registryCondition)

export default GridCol
