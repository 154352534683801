import React from 'react'
import './AssetCardHorizontal.css'
import Icon from '../Icon2'
import ProgressBar from '../ProgressBar/ProgressBar'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import { formatBytes, getAssetIcon } from '../../utils'

export type AssetCardHorizontalProps = {
  url?: string
  name?: string
  size?: any
  description?: string
  onDelete?: (data?: any) => void
  onEdit?: (data?: any) => void
  data?: any
  type?: any
  imageWidth?: any
  imageHeight?: any
  progressBar?: number
  onAssetIconClick?: Function
  onReload?: Function
  withDragAction?: boolean
}

const AssetCardHorizontal = (props: AssetCardHorizontalProps) => {
  let {
    url,
    name,
    size,
    description,
    onDelete,
    onEdit,
    onAssetIconClick,
    data,
    type,
    imageHeight,
    imageWidth,
    progressBar,
    onReload,
    withDragAction
  } = props

  if (type === 'image' && !url) {
    type = 'imageBroken'
  }

  if (progressBar > 100 || (progressBar < 0 && progressBar !== -1)) {
    progressBar = undefined
  }

  url = `${url}`
  // url = `${url}?width=${imageWidth}&height=${imageHeight}&resize-filter=nearest`;

  const classCreator = () => {
    if (imageHeight && imageWidth) {
      if (imageHeight === imageWidth && imageHeight >= 60) {
        return `ImageCard__image_full`
      }
      if (imageHeight > 60 && imageWidth > 60) {
        if (imageHeight < imageWidth) {
          return `ImageCard__image-height-full`
        }
        if (imageHeight > imageWidth) {
          return `ImageCard__image-width-full`
        }
      }
      if (imageHeight >= 60 && imageWidth < 60) {
        return `ImageCard__image-height-auto`
      }
      if (imageWidth >= 60 && imageHeight < 60) {
        return `ImageCard__image-width-auto`
      }
    }
    return ''
  }

  const handleOnClick = () => {
    if (props?.onAssetIconClick) {
      props.onAssetIconClick(props?.data)
    }
  }
  return (
    <div className="ImageCard flex">
      {type === 'image' ? (
        <div className="ImageCard__image-wrapper flex-center">
          <img
            src={url}
            alt={name}
            className={`${classCreator()} ${onAssetIconClick ? 'action--click' : ''}`}
            onClick={handleOnClick}
          />
        </div>
      ) : (
        <div className="ImageCard__image-wrapper ImageCard__image-iconbox flex-center">
          <Icon
            className={`placeholder ${onAssetIconClick ? 'action--click' : ''}`}
            icon={getAssetIcon(type)}
            onClick={handleOnClick}
          />
        </div>
      )}
      <div className="ImageCard__details">
        <div className="ImageCard__name">{name}</div>
        {size &&
        (progressBar === undefined ||
          progressBar === null ||
          (progressBar !== undefined && progressBar !== null && progressBar !== -1)) ? (
          <div className="ImageCard__size">{formatBytes(size)}</div>
        ) : null}
        {progressBar !== undefined && progressBar !== null && progressBar !== 100 ? (
          <>
            {progressBar !== -1 ? (
              <div className="ImageCard__progress">
                <ProgressBar percentage={progressBar} color="#6C5CE7" />
              </div>
            ) : (
              <div className="flex-v-center">
                <ValidationMessage>Failed</ValidationMessage>
                {onReload ? (
                  <>
                    <div
                      onClick={(event: any) => {
                        onReload(event, data)
                      }}>
                      <Icon icon={'Reload'} className="ImageCard__reload" />
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </>
        ) : null}
        {description ? (
          <>
            {progressBar === undefined ||
            progressBar === null ||
            (progressBar !== undefined && progressBar !== null && (progressBar === 100 || progressBar === -1)) ? (
              <div className="ImageCard__description">{description}</div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className="ImageCard__actions flex">
        {onDelete ? (
          <div
            className="ImageCard__action--delete flex"
            onClick={(event: any) => {
              onDelete && onDelete(data)
            }}>
            <Icon icon="TrashMini" />
          </div>
        ) : null}
        {onEdit ? (
          <>
            {progressBar === undefined || (progressBar !== undefined && progressBar !== null && progressBar !== -1) ? (
              <div
                className="ImageCard__action--edit flex"
                onClick={(event: any) => {
                  onEdit && onEdit(data)
                }}>
                <Icon icon="EditSmall" />
              </div>
            ) : null}
          </>
        ) : null}
        {withDragAction && (
          <div className="ImageCard__action-bar-icon">
            <Icon icon="ActionBar" />
          </div>
        )}
      </div>
    </div>
  )
}

AssetCardHorizontal.defaultProps = {
  type: 'imageBroken',
  imageHeight: 100,
  imageWidth: 200,
  withDragAction: true
} as Partial<AssetCardHorizontalProps>

export default AssetCardHorizontal
