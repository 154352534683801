import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from '../style.module.css'
export * from './deserializer'
export const H3 = (props) => {
  const { className } = props.attrs || {}
  return (
    <div {...props.attrs} className={styles['heading']}>
      <h3 {...props.attributes} className={cx(
        className,
        css`padding-top: var(--scrte-padding-top, 0);`
      )}>
        {props.children}
      </h3>
    </div>
  )
}

Registry.register(
  {
    Component: H3,
    // iconName: 'ri-h-3',
    title: <h3 className={styles['h3']}>Heading 3</h3>,
    subtitle: 'Add heading level 3',
    category: elementCategories.TEXT_FORMAT,
    shortcut: { key: 'mod+alt+3' },
    isContentStackElement: true,
    inBasicToolbar: true
  },
  { id: 'h3', registry: 'element' }
)
