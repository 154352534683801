//@ts-nocheck
import { findEmbedType } from '../Embeds'

export const iframeHeightOnResizeStop = (src, ref, el) => {
  if (src?.indexOf('facebook.com') > -1) {
    if (ref.current.getElementsByTagName('iframe')[0]) {
      const urlArray = ref.current.getElementsByTagName('iframe')[0].src.split("&")
      if (urlArray[urlArray.length - 1]) {
        urlArray[urlArray.length - 1] = `width=${el.style.width}`
      }
      ref.current.getElementsByTagName('iframe')[0].src = urlArray.join("&")
    }
    const handleEvent = (e) => {
      if (e.origin === "https://www.facebook.com" && (e.data)) {
        if (ref.current.getElementsByTagName('iframe')[0].contentWindow === e.source) {
          el.style.height = `${e.data.split("&")[e.data.split("&").length - 1].split("=")[1]}px`
          window.removeEventListener('message', handleEvent);
        }
      }
    }
    if (ref.current) {
      window.addEventListener('message', handleEvent)
    }
  }
  if (src?.indexOf('twitter.com') > -1) {
    const handleEvent = (e) => {
      if (e.origin === "https://platform.twitter.com" && (e.data)) {
        if (ref.current.getElementsByTagName('iframe')[0].contentWindow === e.source) {
          el.style.height = `${e.data['twttr.embed'].params[0].height + 20}px`
          window.removeEventListener('message', handleEvent)
        }
      }
    }
    if (ref.current) {
      window.addEventListener('message', handleEvent)
    }

  }
  if (src?.indexOf('instagram.com') > -1) {
    const handleEvent = (e) => {
      if (e.origin === "https://www.instagram.com" && (e.data)) {
        if (ref.current.getElementsByTagName('iframe')[0].contentWindow === e.source) {
          el.style.height = `${JSON.parse(e.data).details.height}px`
          window.removeEventListener('message', handleEvent)
        }
      }
    }
    if (ref.current) {
      window.addEventListener('message', handleEvent)
    }
  }
}



export const iFrameUrl = (url) => {
  let newAttrs;
  let data = url;
  var allowed = ['iframe', 'video', 'source'];
  var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

  data = data?.replace(/<p(.*?[^>]?)>([\w\W]*?)<\/p>/gi, '');
  data = data?.replace(tags, function ($0, $1) {
    return (allowed.indexOf($1.toLowerCase()) === -1) ? '' : $0;
  });
  const srcReg = /(src=").*?(?=[\*"])/g;
  const parser = new DOMParser();
  const htmlElement = parser.parseFromString(data, "text/html");
  if (data) {

    let matches = [...data.matchAll(srcReg)];

    matches?.forEach(match => {
      if (match[0].indexOf('youtube.com') > -1) {
        newAttrs = {
          src: match[0],
        }
        htmlElement.getElementsByTagName('iframe')[0].getAttributeNames().forEach((attribute) => {
          newAttrs[attribute] = htmlElement.getElementsByTagName('iframe')[0].getAttribute(attribute)
        })
      }
      if (match[0].indexOf('facebook.com') > -1) {
        if (match[0].indexOf('href=') > -1) {
          let href = match[0].substr(match[0].indexOf('href=') + 5).replaceAll('%2F', '/').replace('%3A', ':')
          newAttrs = {
            src: href,
          }
          htmlElement.getElementsByTagName('iframe')[0].getAttributeNames().forEach((attribute) => {
            if (attribute !== 'src') {
              newAttrs[attribute] = htmlElement.getElementsByTagName('iframe')[0].getAttribute(attribute)
            }
          })
        }
      }
      if (match[0].indexOf('vimeo.com') > -1) {
        newAttrs = {
          src: match[0],
        }
        htmlElement.getElementsByTagName('iframe')[0].getAttributeNames().forEach((attribute) => {
          newAttrs[attribute] = htmlElement.getElementsByTagName('iframe')[0].getAttribute(attribute)
        })
      }
    })
  }
  return newAttrs
}

export const blockQuoteUrl = (url) => {
  let newAttrs;
  let data = url;
  if (data) {

    const parser = new DOMParser();
    const htmlElement = parser.parseFromString(data, "text/html");

    const blockQuoteElement = htmlElement.getElementsByTagName('blockquote');
    const embedType = blockQuoteElement[0]?.className
    const attrs = blockQuoteElement[0]?.getAttributeNames()
    switch (embedType) {
      case 'twitter-tweet':
        newAttrs = blockQuoteElement[0].lastChild?.href
        break;
      case 'tiktok-embed':
        newAttrs = {
          src: blockQuoteElement[0].getAttribute('cite'),
        }
        attrs.forEach((attribute) => {
          if (attribute === 'style') {
            newAttrs[attribute] = getStyleObjectFromString(blockQuoteElement[0].getAttribute(attribute))
          }
          else {
            newAttrs[attribute] = blockQuoteElement[0].getAttribute(attribute)
          }
        })
        break;
      case 'instagram-media':
        newAttrs = {
          src: blockQuoteElement[0].getAttribute('data-instgrm-permalink'),
        }
        attrs.forEach((attribute) => {
          if (attribute === 'style') {
            newAttrs[attribute] = getStyleObjectFromString(blockQuoteElement[0].getAttribute(attribute))
          }
          else {
            newAttrs[attribute] = blockQuoteElement[0].getAttribute(attribute)
          }
        })
        break;
      default:
        break;
    }
  }
  return newAttrs
}

export const isValidUrl = (url) => {
  return (findEmbedType(url))
}

const formatStringToCamelCase = str => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

const getStyleObjectFromString = str => {
  const style = {};
  str.split(";").forEach(el => {
    const [property, value] = el.split(":");
    if (!property) return;

    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};


export const changeIframeWidth = (src, width, ref) => {
  if (src?.indexOf('tiktok.com') > -1) {
    const handleEvent = (e) => {
      if (e.origin === "https://www.tiktok.com" && (e.data)) {
        if (ref.current.getElementsByTagName('iframe')[0].contentWindow === e.source) {
          if (JSON.parse(e.data).width !== 0) {
            ref.current.getElementsByTagName('iframe')[0].style.width = `${width}px`
            window.removeEventListener('message', handleEvent)
          }
        }
      }
    }
    if (ref.current) {
      window.addEventListener('message', handleEvent)
    }
  }
  else if (src?.indexOf('facebook.com') > -1) {
    const handleEvent = (e) => {
      if (e.origin === "https://www.facebook.com" && (e.data)) {
        if (ref.current?.getElementsByTagName('iframe')[0].contentWindow === e.source) {
          const urlArray = ref.current.getElementsByTagName('iframe')[0].src.split("&")
          if (urlArray[urlArray.length - 1]) {
            urlArray[urlArray.length - 1] = `width=${width}`
          }
          ref.current.getElementsByTagName('iframe')[0].src = urlArray.join("&")
          window.removeEventListener('message', handleEvent)
        }
      }
    }
    if (ref.current) {
      window.addEventListener('message', handleEvent)
    }
  }
}
