export const dropdownKeyboardNavigation = (dropdownType: "click" | "select" | "selectSearchInput" | "dropdownHeader", e: any, dropdownProps: any) => {
  const { dropDownType, handleClickDropdown, handleSelect, withSearch, isComponentVisible, setIsComponentVisible, index, item, list } = dropdownProps || {}

  switch (dropdownType) {
    case "click":
      if (e.key === "Enter" || e.key === " ") {
        handleClickDropdown(e, index, item)
      }
      if (e.key === "Tab" && index === list.length - 1) {
        setIsComponentVisible(!isComponentVisible)
      }
      if (e.key === "ArrowDown" && isComponentVisible) {
        const liDomElemet: any = document.getElementsByClassName("Dropdown__menu__list__item")[index + 1]
        liDomElemet?.focus()
        if (!liDomElemet && index === list.length - 1) {
          let dropdownHeaderDom: any = document.getElementsByClassName("Dropdown--open")[0]
          dropdownHeaderDom?.focus()
          setIsComponentVisible(!isComponentVisible)
        }
      }
      if (e.key === "ArrowUp" && isComponentVisible) {
        const liDomElemet: any = document.getElementsByClassName("Dropdown__menu__list__item")[index - 1]
        liDomElemet?.focus()
        if (!liDomElemet && index === 0) {
          let dropdownHeaderDom: any = document.getElementsByClassName("Dropdown--open")[0]
          dropdownHeaderDom?.focus()
          setIsComponentVisible(!isComponentVisible)
        }
      }
      break;

    case "select":
      if (e.key === "Enter" || e.key === " ") {
        if (!item.disable) handleSelect(item.action ? item.action : () => { /* TODO document why this arrow function is empty */ }, index, item)
        let dropdownHeaderDom: any = e.target?.closest(".Dropdown--primary")
        dropdownHeaderDom?.focus()
      }
      if ((e.key === "Tab" && index === list.length - 1) || (e.key === "Tab" && e.shiftKey && (!withSearch && index === 0))) {
        setIsComponentVisible(!isComponentVisible)
      }
      if (e.key === "ArrowDown" && isComponentVisible) {
        const liDomElemet: any = document.getElementsByClassName("Dropdown__menu__list__item")[index + 1]
        liDomElemet?.focus()
        if (!liDomElemet && index === list.length - 1) {
          setIsComponentVisible(!isComponentVisible)
          let dropdownHeaderDom: any = document.getElementsByClassName("Dropdown--open")[0]
          dropdownHeaderDom?.focus()
        }
      }
      if (e.key === "ArrowUp" && isComponentVisible) {
        if (index === 0 && withSearch) {
          const searchDomElemet: any = document.getElementsByClassName("Dropdown__menu__searchBox")[0]
          searchDomElemet?.getElementsByTagName("input")[0]?.focus()
        } else if (index === 0 && !withSearch) {
          setIsComponentVisible(!isComponentVisible)
          let dropdownHeaderDom: any = document.getElementsByClassName("Dropdown--open")[0]
          dropdownHeaderDom?.focus()
        }
        const liDomElemet: any = document.getElementsByClassName("Dropdown__menu__list__item")[index - 1]
        liDomElemet?.focus()
      }
      break;

    case "selectSearchInput":
      if (e.key === "ArrowDown") {
        const liDomElemet: any = document.getElementsByClassName("Dropdown__menu__list__item")[0]
        liDomElemet?.focus()
      }
      if (e.key === "ArrowUp") {
        setIsComponentVisible(!isComponentVisible)
        let dropdownHeaderDom: any = document.getElementsByClassName("Dropdown--open")[0]
        dropdownHeaderDom?.focus()
      }
      if ((e.key === "Tab" && e.shiftKey)) {
        setIsComponentVisible(!isComponentVisible)
      }
      break;

    case "dropdownHeader":
      if ((e?.key === "Enter" || e?.key === " ") && e?.target?.tagName !== "INPUT") {
        if (dropDownType === 'tertiary') {
          e.preventDefault()
          e.stopPropagation()
        }
        setIsComponentVisible(!isComponentVisible)
      }
      if (e.shiftKey && e.key === "Tab" && e.target?.classList?.contains("Dropdown--open")) {
        isComponentVisible && setIsComponentVisible(false)
      }
      break;
  }
}