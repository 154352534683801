import React, { useEffect, useRef } from 'react'
import { Resizable } from 're-resizable'
import { Transforms, Editor } from 'slate'
import { useFocused, useSelected, ReactEditor } from 'slate-react'
import { cx } from '@emotion/css'

//@ts-ignore
import styles from './style.module.css'
import { getAssetType } from '../../image/Modal/util'
import { Tooltip, Icon } from '../../../../../../../../index'
import { EmbedBtnGrp } from '../inlineReference'
import { handleDelete } from '../../../../utils/actions'
import { cbModal } from '../../../../../../../..'
import { EditCaptionAlt } from './EditCaptionAlt'
import { placeholderImageSrc } from '../../../image/placeholderImageSrc'
import { InfoIcon } from './components'
import { ElementWithType } from '../../../../../../../../utils/types';

const Asset = (props) => {
  const { children, attributes, editor, entry, element, attrs, slatePath } = props
  const icon = getAssetType(element?.attrs?.['asset-type'])
  const isFocused = useFocused()
  const isSelected = useSelected()
  const isHighlight = isFocused && isSelected
  const isError = entry['error'],
    canUpdate = entry?.['_embed_permission']?.['update'],
    canRead = entry?.['_embed_permission']?.['read']
  const isDisabled = entry['disable']

  const { history, apiKey } = editor?.requestProps || {}
  const assetUid = element?.attrs?.['asset-uid'],
    displayType = element?.attrs?.['display-type']
  const imgRef = useRef(null)
  const parentRef = useRef(null)
  const isInline = element?.attrs?.inline
  const isInlineImage = isInline && !icon
  const isDefault = element?.attrs?.['default']
  const alignment = element?.attrs?.style?.['text-align']
  const handleEdit = () => {
    if (editor.requestProps?.['userAnalytics']) {
      const userAnalytics = editor.requestProps['userAnalytics']
      const metaData = {
        stack_api_key: editor.requestProps['apiKey'],
        json_rte_field_uid: editor.requestProps['field_uid'],
        json_rte_field_type: editor.requestProps['field_type'],
        embedded_asset_type: element['attrs']['display-type'],
        embedded_asset_uid: element['attrs']['asset-uid'],
        mixpanel: true
      }
      userAnalytics.trackEvent(`View Embedded Asset Details`, metaData)
    }
    history.push(`/stack/${apiKey}/assets/${assetUid}`)
  }

  const handleCaption = () => {
    editor.setFullScreen()
    return cbModal({
      component: (props) => <EditCaptionAlt entry={entry} editor={editor} element={element} slatePath={slatePath} {...props}></EditCaptionAlt>
    })
  }

  useEffect(() => {
    try{
      if(!isInline && !alignment){
        return
      }
      const [assetNode] = Editor.node(editor, ReactEditor.findPath(editor, element))
        const assetWrapper = assetNode && ReactEditor.toDOMNode(editor, assetNode)?.parentElement
        if (assetWrapper && assetWrapper.nodeName === 'P') {
          assetWrapper.setAttribute('style', 'overflow: hidden')
        }
    }
    catch(err){
      console.log('err', err)
    }
  }, [element])

  const applyAssetStyles = (attrs, width, height) => {
    const newAttrs = { ...attrs };
    newAttrs['width'] = width;
    const newStyles = { ...newAttrs.style }
    const newRedactorAttrs = { ...newAttrs['redactor-attributes'] }
    newAttrs.style = { ...newStyles }
    newAttrs['redactor-attributes'] = { ...newRedactorAttrs }
    newAttrs['redactor-attributes']['height'] = `${height}`
    newAttrs.style['max-width'] = `${width}px`
    newAttrs.style['width'] = `${width}px`
    newAttrs.style['max-height'] = `${height}px`
    newAttrs.style['height'] = `${height}px`
    newAttrs['max-width'] = `${width}`
    newAttrs['width'] = `${width}`
    newAttrs['max-height'] = `${height}`
    newAttrs['height'] = `${height}`
    return newAttrs
  }

  const onResizeStop = (event, dir, el, size) => {
    let path = ReactEditor.findPath(editor, element)
    let { attrs } = element
    let width = imgRef.current.clientWidth
    let height = imgRef.current.clientHeight
    let newAttrs = applyAssetStyles(attrs, width, height)
    if (newAttrs) {
      Transforms.setNodes(
        editor,
        //@ts-ignore
        { attrs: { ...newAttrs } },
        {
          at: path
        }
      )
    }
  }

  const handleImageError = (e) => {
    e.target.src = placeholderImageSrc
  }

  const initialDimensions = {
    width: `${element.attrs.width ? `${element.attrs.width}px` : displayType === 'download' ? '180px' : 'auto'}`,
    height: `${element.attrs.height ? `${element.attrs.height}px` : displayType === 'download' ? '180px' : 'auto'}`
  }

  let alignmentStyle
  if (alignment) {
    if (alignment === 'center' || alignment === 'justify' || !isInline) {
      delete attrs.style['float']
    }
    const marginAlignment = {
      center: { margin: 'auto' },
      left: { marginRight: 'auto' },
      right: { marginLeft: 'auto' }
    }
    alignmentStyle = marginAlignment[alignment]
  }

  if (isInline) {
    alignmentStyle = { display: 'inline-block' }
    if (alignment) {
      alignmentStyle.float = alignment
    }
  }

  alignmentStyle = isInline ? { ...alignmentStyle } : alignmentStyle
  return (
    <div {...attributes} {...attrs} style={{ ...alignmentStyle, ...attrs.style }} data-testid={"embed-asset-wrapper"}>
      <Tooltip
        zIndex={909}
        className="p-0"
        position="bottom"
        variantType="light"
        appendTo={parentRef.current}
        offset={[0, -15]}
        content={
          <div contentEditable={false} className={styles['embed--btn-group']}>
            <EmbedBtnGrp
              isDefault={isDefault}
              isDisabled={isDisabled}
              isError={isError}
              handleCaption={(canUpdate || isDefault) && handleCaption}
              handleEdit={canRead && handleEdit}
              handleDelete={(e) => handleDelete(editor, element)}
              handleUnlink={undefined}
              editor={editor}
              type="asset"
              attributes={{ ...props.attributes, ...props.attrs, element }}
            />
          </div>
        }>
        <span {...attrs} data-type="asset" contentEditable={false}>
          {children}
          <div ref={parentRef} contentEditable={false}>
            <Resizable
              lockAspectRatio={true}
              onResizeStop={onResizeStop}
              size={initialDimensions}
              className={styles['scrte--img-container']}
              handleClasses={{ right: "scrte-img-resizer--right" }}
              handleStyles={{
                right: { right: 0, width: '15px' },
                left: { left: 0, width: '15px' },
                bottom: { bottom: '0px' }
              }}>
              <div ref={imgRef} contentEditable={false} style={{ width: '100%', height: '100%' }} data-testid={'embed-asset-img-element'}>
                <div
                  className={cx(
                    !isInlineImage && styles['embed-asset'],
                    !isInlineImage && displayType === 'download' && styles['embed-download'],
                    isInlineImage && styles['embed-asset--inline'],
                    isHighlight && styles['embed-asset-active']
                  )}>
                  {!icon && (
                    <img
                      onError={handleImageError}
                      src={entry.url || placeholderImageSrc}
                      style={{ width: '100%', height: 'auto' }}
                      data-testid={"embed-image"}
                    />
                  )}
                  {!isInlineImage && (
                    <div className={styles['embed-icon']}>
                      <Icon icon="Embed" />
                    </div>
                  )}
                  {isError || isDisabled ? (
                    <InfoIcon className={styles['center']} isDisabled={isDisabled} error={entry['error']} />
                  ) : (
                    <Icon className={styles['center']} icon={icon} size="large" />
                  )}
                </div>
              </div>
              <span contentEditable={false} className={styles['scrte-image-resizer-feedback-left']}>
                <span contentEditable={false} className={styles['scrte-image-resizer-feedback']}></span>
              </span>
              <span contentEditable={false} className={styles['scrte-image-resizer-feedback-right']} data-testid={"asset-resizable--right"}>
                <span contentEditable={false} className={styles['scrte-image-resizer-feedback']}></span>
              </span>
              {element?.attrs?.['asset-caption'] && (
                <p className={styles['scrte--caption']} data-testid={'embed-asset-caption'}>{element.attrs['asset-caption']}</p>
              )}
            </Resizable>
          </div>
        </span>
      </Tooltip>
    </div>
  )
}

export default Asset
