import React from 'react'
import { InfiniteScrollTable, Icon } from '../../../../../../../../index'
import { formatTime } from '../../util'
import styles from './index.module.css'

const iconFromType = (contentType) => {
  if (contentType.startsWith('image/')) return null
  else if (contentType.startsWith('application/')) return 'Group'
  else return 'File'
}

const GetIcon = ({ data }) => {
  return (
    <React.Fragment>
      <span className={styles['list-icon']} style={{ background: `url(${data.url})` }}>
        {iconFromType(data.content_type) && <Icon icon={iconFromType(data.content_type)} />}
      </span>
      {data.name ? data.name : data.filename}
    </React.Fragment>
  )
}

export const ListView = ({ state, fetchOnChange, loadMore, handleClick, loading, users }) => {
  const columns = [
    {
      Header: 'Title',
      id: 'title',
      accessor: (data) => {
        return (
          <div className='flex-v-center'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <GetIcon data={data} />
            </div>
          </div>
        )
      },
      default: false,
      addToColumnSelector: true,
    },
    {
      Header: 'Unique ID',
      accessor: 'uid',
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
    {
      Header: 'Last Modified',
      accessor: (data) => <div className={'last-updated-by'}><span>{users[data.updated_by] || 'Anonymous'}</span> <span className='last-updated-by-time'>{formatTime(data.updated_at)}</span></div>,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
  ]

  return (
    <div className='scrte-infinite-list' >
      <InfiniteScrollTable
        tableHeight={250}
        data={state.assets}
        columns={columns}
        uniqueKey={'uid'}
        fetchTableData={fetchOnChange}
        loadMoreItems={loadMore}
        loading={loading}
        totalCounts={state.count}
        onRowClick={handleClick}
        singleSelectedRowId={state.selectedImage.uid}
        itemStatusMap={state.itemStatusMap}
        columnSelector={false}
        minBatchSizeToFetch={30}
        name={{ singular: 'Asset', plural: 'Assets' }}
      />
    </div >
  )
}
