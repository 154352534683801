import { Transforms } from 'slate'
import { toggleBlock } from '../../../components/Toolbar/Button/buttonUtils'

export const markdownBlock = (editor, type, at, { preFormat, format }) => {
  Transforms.delete(editor, { at })

  preFormat && preFormat(editor)

  if (!format) {
    toggleBlock(editor, type)
  } else {
    format(editor)
  }
}
