//@ts-nocheck
import React, { useState, useEffect } from 'react'
import { Registry } from '@react-registry'
import { useSlateStatic, ReactEditor } from 'slate-react'
import { Transforms } from 'slate'

import { elementCategories } from '../../elementCategories'
import ingressReferenceButton from './utils/ingressReferenceButton'
//@ts-ignore

import { BlockReference } from './blockReference'
import { EmbedLinkReference } from './linkReference'
import { InlineReference } from './inlineReference'
import Asset from './assetReference/Asset'

// to be moved in utils
const deleteReference = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.removeNodes(editor, { at: path })
}

export const ReferenceComponent = (props) => {
  const [state, setState] = useState({
    entry: {
      name: '',
    },
  })
  const { element } = props;

  const editor = useSlateStatic();

  useEffect(() => {
    if (editor.requestProps && !editor.requestProps.enableEmbedObj) {
      // if disabled embed feature
      setState({
        entry: {
          disable: true,
          title: 'Entry Title',
          url: ''
        }
      })
      return;
    }
    const loadState = async (e?) => {
      if (e) e.preventDefault();

      setState(prevState => ({
        ...prevState,
        loading: true
      }))

      let contentTypeUid = element?.attrs?.['content-type-uid'],
        entryUid = element?.attrs?.['entry-uid'],
        assetUid = element?.attrs?.['asset-uid'];
      const { uidToEntry } = editor.requestProps || {};
      if (contentTypeUid === 'sys_assets') {
        if (!uidToEntry?.[assetUid]) {
          return setState(prevState => ({
            ...prevState, loading: false, entry: {
              title: assetUid, error: 'The asset is either deleted or you do not have access to it.'
            }
          }))
        }
        else if (uidToEntry?.[assetUid]) {
          const asset = uidToEntry[assetUid];
          const assetPermission = asset['_embed_permission'] || {};
          const isAssetEditable = assetPermission['update'];
          const isReadable = assetPermission['read'];
          if ((!isReadable && !isAssetEditable) || !asset['url']) {
            asset['error'] = 'The asset is either deleted or you do not have access to it.'
            return setState(prevState => ({
              ...prevState, loading: false, entry: asset
            }))
          } else {
            setState(prevState => ({ ...prevState, loading: false, entry: asset }));
          }
        }
      } else {
        if (uidToEntry?.[entryUid]) {
          const entry = uidToEntry[entryUid];
          const entryPermission = entry['_embed_permission'] || {};
          const isEntryEditable = entryPermission['update'] && entryPermission['read'];
          if (!isEntryEditable || !entry['title']) {
            return setState(prevState => ({
              ...prevState, loading: false, entry: {
                ...entry,
                title: entry['title'] || entry['uid'] || entryUid,
                error: `The entry is either invalid or you do not have access to it.`
              }
            }))
          } else {
            setState(prevState => ({ ...prevState, loading: false, entry }));
          }
        } else {
          return setState(prevState => ({
            ...prevState, loading: false, entry: {
              title: entryUid, error: `The entry is either invalid or you do not have access to it.`
            }
          }))
        }
      }
    }
    loadState()
  }, [element?.attrs?.['entry-uid'], element?.attrs?.['asset-uid'], editor.requestProps])

  const { loading, entry } = state;

  switch (element?.attrs?.['display-type']) {
    case 'block':
      return <BlockReference editor={editor} loading1={loading} entry={entry} deleteReference={deleteReference} {...props} />
    case 'link':
      return <EmbedLinkReference editor={editor} entry={entry} {...props} />
    case 'asset':
      return <Asset loading={loading} entry={entry} editor={editor} {...props} />
    default:
      return <InlineReference editor={editor} loading={loading} entry={entry} deleteReference={deleteReference} {...props} />

  }
}
const beforeElementRender = (props) => {
  let { element } = props;
  if (element['type'] === 'reference' && !['link', 'inline'].includes(element?.['attrs']?.['display-type'])) {
    props.DisableDND = false;
  }
}

Registry.register(
  {
    Component: ReferenceComponent,
    title: 'Entry',
    IngressComponent: ingressReferenceButton,
    category: elementCategories.CONTENTSTACK_REFERENCE,
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true
    },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
    beforeElementRender
  },
  { id: 'reference', registry: 'element' }
)


export * from './inlineReference'

export * from './linkReference'

export * from './assetReference'