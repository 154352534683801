import React from "react"
import Tippy from "@tippyjs/react"
import { useDrag } from "react-dnd"
import styles from "../style.module.css"
interface IconProps {
  "path": Array<number>,
  "element": object
}
const SvgComponent = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { path, element } = props
  const [, drag] = useDrag({
    item: {
      type: "docs",
      path,
      element,
      dropped: false,
      canDrop: true
    }
  })
  return (
    <span data-testid="table-drag-icon" className={styles['table-column-toolbarIcon']} ref={drag}>
      <Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="table-drag-icon-text" className={styles['scrte-table-tooltip-text']}>Hold and drag to move table</span>} >
        <span>
          <svg
            width={14}
            height={14}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
          >
            <rect x={2.052} width={3.002} height={3.002} rx={1.501} fill="#647696" />
            <rect
              x={2.052}
              y={4.499}
              width={3.002}
              height={3.002}
              rx={1.501}
              fill="#647696"
            />
            <rect
              x={2.052}
              y={8.998}
              width={3.002}
              height={3.002}
              rx={1.501}
              fill="#647696"
            />
            <rect x={6.946} width={3.002} height={3.002} rx={1.501} fill="#647696" />
            <rect
              x={6.946}
              y={4.499}
              width={3.002}
              height={3.002}
              rx={1.501}
              fill="#647696"
            />
            <rect
              x={6.946}
              y={8.998}
              width={3.002}
              height={3.002}
              rx={1.501}
              fill="#647696"
            />
          </svg>
        </span>
      </Tippy>
    </span>
  )
})

export default SvgComponent
