import React from 'react';
import { Tooltip } from '../../../../../../../../../index';

export const EmbedBtn = ({ content, onClick, children, title, testId = "scrte-embed-btn" }) => {
  return (
    <Tooltip position='bottom' content={content}>
      <button id={title} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
        data-testid={testId}
      >
        {children}
      </button>
    </Tooltip>
  )
}