import React from 'react'

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup
} from '../../../../../../index'
import Dropable from './Upload/Dropable'
import { Upload } from './Upload'
import { uploadImage } from '../request';
import './style.css'
import { ExtendedEditor } from '../../../../../../utils/types';
import commands from '../../../utils/commands'

export const getImageMarkdown = (href, text = 'Enter your image caption here') => {
  text = text === '' ? href : text
  return `![${text}](${href})`
}

export declare type ModalComponentProps = {
  editor: any,
  closeModal: Function
  state: Record<string, any>
}

declare type image = {

}

export default class extends React.Component<ModalComponentProps> {
  state = {
    folderUid: 'cs_root',
    loading: false
  }

  setFolderUid = (uid) => {
    this.setState({
      folderUid: uid
    });
  }

  handleSubmit = (res) => {
    res.forEach(instance => {
      const asset = instance.asset;
      const url = asset?.url
      const editor = this.props.editor
      const state = this.props.state
      if (!state || (!state?.text && state?.text !== "")) {
        this.handleReset()
        return;
      }
      const chunks = state.getChunks();
      const fixupInputArea = function () {

        editor.panels.input.focus();

        if (chunks) {
          state.setChunks(chunks);
        }

        state.restore();
        editor?.onUpdate && state?.text && editor.onUpdate(state?.text) // to update the markdown data for save
        editor.previewManager.refresh();
      };
      const noCleanup = commands['doLinkOrImage'](chunks, fixupInputArea, editor, true, url)
      if (!noCleanup) {
        fixupInputArea();
      }
    })
    this.handleReset()
  }

  handleReset = () => {
    this.props.closeModal();
  }

  setLoading = isLoading => this.setState({ loading: isLoading });

  handleUpload = (files, uid = '') => {
    const editor = this.props.editor as ExtendedEditor;
    const requestProps: Object = editor.requestProps || {};
    let folderUid = uid.length !== 0 ? uid : this.state.folderUid;
    folderUid = folderUid === 'cs_root' ? '' : folderUid;
    this.setState({ loading: true });
    uploadImage({ ...requestProps, files: files, folderUid })
      .then(res => { this.handleSubmit(res); this.setLoading(false) })
      .catch(err => this.setLoading(false));
    ;
  };

  render() {
    return (
      <div id='scrte-image-modal'>
        <ModalHeader title="Select a folder to upload asset(s)" closeModal={this.props.closeModal} />

        <ModalBody className="modalBodyCustomClass">
          <Upload loading={this.state.loading} editor={this.props.editor} handleUpload={this.handleUpload} setFolderUid={this.setFolderUid} />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleReset} buttonType="tertiary">
              Cancel
            </Button>
            <Dropable config={{ noDrag: true, multiple: true, accept: "image/*", onDrop: this.handleUpload }}>
              <Button loading={this.state.loading}>
                Upload Here
              </Button>
            </Dropable>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}
