import React from 'react'
import cbModal from '../Modal/Modal'
import ImageModal from './components/ModalComponent'
import UploadModal from './components/UploadModal'
import { Asset } from './utils/types';

declare global {
  interface Window {
    contentstackSDK: any;
    postRobot: any;
  }
}

export const openChooseAssetModal = (args?: {
  sdk: any,
  onSubmit: (images: Asset[]) => void,
  onCancel?: () => void,
  multiple?: boolean | {
    max: number
  },
  only?: "image" | "video" | "audio" | "document" | "code" | "executable" | "archive",
  fileTypes?: string,
  size?: {
    min?: number,
    max?: number
  },
  excludeAssetUids?: string[]
}) => {
  if (!args.sdk) throw new Error("Contentstack SDK is required argument")
  if (!args.onSubmit) throw new Error("onSubmit is required argument")
  args.sdk && (window.contentstackSDK = args.sdk)
  window.postRobot = args.sdk.postRobot
  cbModal({
    component: (extraProps) => <ImageModal {...args} {...extraProps} closeModal={() => {
      extraProps.closeModal()
      if (args.onCancel) {
        args.onCancel()
      }
    }} />,
    modalProps: {
      size: 'max',
      customClass: 'reference__modal'
    },
  })
}

export const openUploadAssetModal = (args: {
  sdk: any,
  onSubmit: (images: Asset[]) => void,
  onCancel?: () => void,
  multiple?: boolean | {
    max: number
  },
  only?: "image" | "video" | "audio" | "document" | "code" | "executable" | "archive",
  fileTypes?: string,
  size?: {
    min?: number,
    max?: number
  }
}) => {
  if (!args.sdk) throw new Error("Contentstack SDK is required argument")
  if (!args.onSubmit) throw new Error("onSubmit is required argument")
  args.sdk && (window.contentstackSDK = args.sdk)
  window.postRobot = args.sdk.postRobot
  cbModal({
    component: (props) => <UploadModal {...args} {...props} closeModal={() => {
      props.closeModal()
      if (args.onCancel) {
        args.onCancel()
      }
    }} />,
    modalProps: {
      size: 'max',
      customClass: 'reference__modal',
    },
  })
}