import React, { Component, RefObject, FocusEvent, useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

import cn from 'classnames'
import './Search.css'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'

export type SearchProps = {
  /**
   *Sets the width of the input text area of the search component. It can be ‘small,’ ‘medium,’ ‘large,’ or ‘full’.
   */
  width?: 'small' | 'medium' | 'large' | 'full'
  /**
   *Determines the type of search you want to use. It can be ‘primary’ ‘secondary’.
   */
  type?: 'primary' | 'secondary'
  /**
   *Determines the type of corners you want to use for your search bar. It can be ‘regular’ or ‘oval’.
   */
  corners?: 'regular' | 'oval'
  /**
   *Specifies the name attribute of the input element.
   */
  name?: string
  /**
   *Specifies the id attribute of the input element.
   */
  id?: string
  /**
   *Pass the class names to be appended to this prop.
   */
  className?: string
  /**
   *Lets you to add the value in the input text area that is to be searched.
   */
  value?: string
  /**
   *Lets you add the reference to the input text area of the search component.
   */
  inputRef?: RefObject<HTMLInputElement>
  /**
   *Gives you the provision to virtually indicate an error.
   */
  error?: boolean
  /**
   *This event occurs when the value on an element has been changed.
   */
  onChange?: Function
  /**
   *Lets you display a cancel icon to clear the text of the input area.
   */
  onClear?: boolean
  /**
   *Displays the hint of the expected value in the input.
   */
  placeholder?: string
  /**
   *Lets you disable the input field of the search component.
   */
  disabled?: boolean
  /**
   *Lets you delay the processing of events until the user stops typing.
   */
  debounceSearch?: boolean
  /**
   *Shows the input text area when the search icon is clicked.
   */
  dynamicInput?: boolean
  /**
   *Pass an ID that can be used for testing purposes. It is applied as a data attribute(data-test-id).
   */
  testId?: string
  visibilityValue?: any
} & JSX.IntrinsicElements['input'] &
  typeof defaultProps

export interface SearchState {
  value?: string
}

const defaultProps = {
  disabled: false,
  width: 'medium',
  type: 'primary',
  corners: 'oval',
  debounceSearch: false,
  dynamicInput: false,
  testId: 'cs-search'
}

function useComponentVisible(initialIsVisible: Boolean, props: any) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref: any = useRef(null)

  const handleClickOutside = (event: any) => {
    if (
      (props.dynamicInput && ref.current && !ref.current.contains(event.target)) ||
      document.activeElement === document.getElementById('sidebar-extension') ||
      document.activeElement.clientWidth === 315
    ) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

function componentVisibleHook(Component) {
  return function WrappedComponent(props) {
    const visibilityValue = useComponentVisible(!props.dynamicInput ? true : false, props)
    return <Component {...props} visibilityValue={visibilityValue} />
  }
}
export class Search extends Component<SearchProps> {
  static defaultProps = defaultProps

  state = {
    value: this.props.value || ''
  }

  defaultInputRef = React.createRef()

  controlledRef: any = this.props.inputRef || this.defaultInputRef

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  debounceSearch = debounce(() => {
    const value: any = this.state.value
    this.props.onChange(value)
  }, 500)

  onChange = (e) => {
    const value = e.target.value
    this.setState({ value })
    if (this.props.onChange) {
      if (this.props.debounceSearch) {
        this.debounceSearch()
        return
      }
      this.props.onChange(value)
    }
  }

  handleFocus = (e: FocusEvent) => {
    if (this.props.disabled) {
      (e.target as HTMLInputElement).select()
    }
  }

  hanldeClearSearch = (e: any) => {
    const value: any = '';
    if (this.props.onClear) {
      this.setState({ value })
      this.props.onChange(value);
    }
  }

  handleToggleInput = (event: any) => {
    if (this.props.dynamicInput) {
      this.props.visibilityValue.setIsComponentVisible(!this.props.visibilityValue.isComponentVisible)
    }
  }

  onClickSearchIcon = () => {
    if (this.controlledRef?.current) {
      this.controlledRef?.current?.focus()
    }
  }
  render() {
    const {
      className,
      placeholder,
      maxLength,
      disabled,
      onChange,
      onBlur,
      error,
      width,
      value,
      type,
      name,
      id,
      inputRef,
      corners = 'oval',
      debounceSearch,
      onClear,
      dynamicInput,
      visibilityValue,
      testId,
      ...otherProps
    } = this.props;

    const { ref, isComponentVisible } = visibilityValue;
    const widthClass = `Search--${width}`
    const typeClass = `Search--${type}`
    const classNames = cn(['Search'], className, [widthClass], [typeClass], { 'Search--disabled': disabled })

    return (
      <div ref={!this.state.value ? ref : null} className={classNames} data-test-id={testId}>
        <div
          onClick={this.handleToggleInput}
          className={`${this.props.dynamicInput ? 'ml-3' : ''} ${
            !isComponentVisible && this.props.dynamicInput ? 'Search__icon-wrapper' : ''
          }`}>
          <span data-test-id="cs-search-icon" onClick={this.onClickSearchIcon}>
            <Icon
              className={`Search__search-icon ${
                !isComponentVisible && this.props.dynamicInput ? 'Search__search-icon__hidden' : ''
              }`}
              icon="Search"
              size="mini"
            />
          </span>
        </div>
        <div className={isComponentVisible ? 'Search-input-show' : 'Search-input-hide'}>
          <input
            aria-label="Search"
            className={cn('Search__input', {
              'regular-corners': corners === 'regular',
            })}
            data-test-id="cs-search-input-field"
            id={id}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={this.handleFocus}
            onChange={this.onChange}
            value={this.state.value}
            autoFocus={this.props.dynamicInput ? true : false}
            type={type}
            ref={this.controlledRef}
            {...otherProps}
          />
          <div onClick={this.hanldeClearSearch} className="Search__cancel-icon">
            {(onClear && this.state.value) &&
              <Tooltip content="Cancel Search" position="top">
                <Icon icon="Cancel" size="extraSmall" />
              </Tooltip>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default componentVisibleHook(Search)