import React from 'react'
import isEmpty from 'lodash/isEmpty';
import isHotkey from 'is-hotkey';

import {
  cbModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Field,
  TextInput,
  ButtonGroup,
  ValidationMessage,
} from '../../../../../index'
import { Editor } from '../../lib/node-pagedown'
import commands from '../../utils/commands';
export declare type ModalComponentProps = {
  editor: typeof Editor,
  closeModal: Function
  state: Record<string, any>
}

class ModalComponent extends React.Component<ModalComponentProps> {
  state = {
    url: '',
    errorMessage: null
  }

  handleSubmit = () => {
    const url = this.state.url;

    if (isEmpty(url)) {
      this.setState({ errorMessage: 'Please enter a valid url.' });
      return;
    }

    const editor = this.props.editor;
    const state = this.props.state

    if (!state || (!state?.text && state?.text !== "")) {
      this.handleReset()
      return;
    }
    const chunks = state.getChunks();
    const fixupInputArea = function () {

      editor.panels.input.focus();

      if (chunks) {
        state.setChunks(chunks);
      }

      state.restore();
      editor?.onUpdate && state?.text && editor.onUpdate(state?.text) // to update the markdown data for save
      editor.previewManager.refresh();
    };
    const noCleanup = commands['doLinkOrImage'](chunks, fixupInputArea, editor, false, url)
    if (!noCleanup) {
      fixupInputArea();
    }
    this.handleReset()
  }

  handleReset = () => {
    this.props.closeModal();
    // this.setState({ url: '' });
  }

  handleCancel = () => {
    this.props.closeModal();
  }

  handleChange = (e) => {
    if (isEmpty(e.target.value)) {
      this.setState({ url: e.target.value, errorMessage: 'Please enter a valid url.' });
    } else {
      this.setState({ url: e.target.value, errorMessage: null });
    }
  }

  handleKeyDown = (e) => {
    if (isHotkey('enter', e)) {
      this.handleSubmit();
    }
  }


  render() {
    return (
      <div>
        <ModalHeader title="Insert Link" closeModal={this.handleCancel} />

        <ModalBody className="modalBodyCustomClass">
          <Field>
            <TextInput
              required
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              value={this.state.url}
              placeholder="http://example.com/"
              name="url"
              error={!!this.state.errorMessage}
              autoFocus
            ></TextInput>
            <ValidationMessage style={{ marginLeft: "10px", marginTop: "5px" }}>{this.state.errorMessage}</ValidationMessage>
          </Field>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleCancel} buttonType="outline" key="cancel">
              Cancel
            </Button>
            <Button icon="CheckedWhite" onClick={this.handleSubmit}>Add Link</Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}


export const linkHandleMouseDown = (e, editor) => {
  e.preventDefault()

  const state = new editor.TextareaState(editor.panels)

  cbModal({
    modalProps: {
      //@ts-ignore
      shouldReturnFocusAfterClose: false,
      onClose: () => { }
    },
    component: (props) => <ModalComponent editor={editor} state={state} {...props} />,
  })
}
