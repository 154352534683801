import { Editor } from 'slate';
import { v4 } from 'uuid';

export * from './requests'
export * from './checkProps'

export const isEmbedLink = (editor) => {
  const selection = editor.selection || editor.savedSelection;
  if (!selection) return false;
  const [embedLink] = Editor.nodes(editor, {
    //@ts-ignore
    match: (n) => n.type === 'reference' && n?.attrs?.['display-type'] === 'link',
    at: selection
  })
  return Boolean(embedLink)
}

export const isEmbedAssetLink = (editor) => {
  const selection = editor.selection || editor.savedSelection;
  if (!selection) return false;
  const [embedLink] = Editor.nodes(editor, {
    //@ts-ignore
    match: (n) => n.type === 'reference' && n?.attrs?.['display-type'] === 'link' && n.attrs.type === 'asset',
    at: selection
  })
  return Boolean(embedLink)
}

export const isEmbedLinkActive = (editor) => {
  const [embedLink] = Editor.nodes(editor, {
    //@ts-ignore
    match: (n) => n.type === 'reference' && n?.attrs?.['display-type'] === 'link'
  })
  return Boolean(embedLink)
}

export const isEmbedAssetLinkActive = (editor) => {
  const [embedLink] = Editor.nodes(editor, {
    //@ts-ignore
    match: (n) => n.type === 'reference' && n?.attrs?.['display-type'] === 'link' && n.attrs.type === 'asset'
  })
  return Boolean(embedLink)
}


export const setEntryInEditor = (editor, entry) => {
  const { key, data } = entry;
  if (typeof editor.requestProps?.['uidToEntry'] === 'object')
    editor.requestProps['uidToEntry'][key] = {
      locale: data['locale'],
      title: data['title'],
      uid: data['uid'],
      url: data['url'],
      publish_details: data['publish_details'],
      _version: data['_version'],
      _embed_permission: {
        read: true,
        update: true,
        create: true,
        publish: true,
        delete: true
      }
    };
}
export const setAssetInEditor = (editor, asset) => {
  const key = asset['uid'];
  if (typeof editor.requestProps?.['uidToEntry'] === 'object')
    editor.requestProps['uidToEntry'][key] = {
      ...asset,
      _embed_permission: {
        read: true,
        update: true,
        create: true,
        publish: true,
        delete: true
      }
    };
}

export const getReferenceNode = ({ type, displayType, entry, linkDetails, asset }) => {
  const node = {
    type: 'reference',
    attrs: {
      'display-type': type || displayType,
      'type': asset ? 'asset' : 'entry',
      'class-name': displayType === 'link' ? 'embedded-entry' : `embedded-entry redactor-component ${displayType}-entry`
    },
    uid: v4().split('-').join(''),
    children: [{ text: type === 'link' ? (linkDetails.text || entry.url || '/undefined') : '' }]
  }
  if (entry) {
    node['attrs']['entry-uid'] = entry.uid;
    node['attrs']['locale'] = entry.locale;
    node['attrs']['content-type-uid'] = entry['contentTypeUid'];
  }
  if (asset) {
    node['attrs']['asset-uid'] = asset.uid;
    node['attrs']['content-type-uid'] = asset['contentTypeUid'];
  }
  if (type === 'link') {
    node['attrs'] = {
      ...node['attrs'],
      //@ts-ignore
      target: linkDetails.inNewTab ? '_blank' : '_self'
    }
    if (entry) {
      node['attrs']['href'] = entry.url;
    }
    if (asset) {
      node['attrs']['href'] = asset.url;
    }
  }
  return node;
}