import { Registry } from '@react-registry'
import { deserialize as defaultDeserialize } from './deserializer/defaultDeserializer'
import { deserialize as googleDeserialize } from './deserializer/googleDocsSerializer'
import { deserialize as csDeserialize } from './deserializer/superchargedRTEDeserializer'
import { deserialize as wordDeserialize } from './deserializer/wordDeserializer'
import { deserialize as onlineWordDeserialize } from './deserializer/onlineWordDeserializer'
import collapse from "collapse-whitespace"
import { applyDirty, preInsert } from './utils'
import { Transforms } from 'slate'
import { getRegistry } from '../../../utils/getRegistry'
import {detect} from 'detect-browser'

export const getCustomInserts = () => {
  return Object.entries(getRegistry('v2.withPreInsert')).map(
    ([key, value]) => {
      return [key, value['components'][0]['component']]
    }
  )
}

const getUserAgentDetails = () => {
  const systemDetails = detect()
  return {
    platform: systemDetails.os,
    browser: systemDetails.name,
    browserVersion: systemDetails.version
  }
}

const getFragmentFromCopyPasteScript = (copyPasteConfig, docTypeFromScript, parsedHtml, wordPasteOptions, googlePasteOptions) => {
  try{
    const userAgentDetails = getUserAgentDetails()
    const docCopyPasteConfig = copyPasteConfig?.docCopyPasteConfig
    let configMatch = Array.from(docCopyPasteConfig.config).find((config: any) => {
      return  config.type === docTypeFromScript.docType && 
      config?.browser === userAgentDetails.browser && 
      userAgentDetails.platform.includes(config?.platform) && 
      config?.mode === docTypeFromScript.mode &&
      config.shouldVisit(parsedHtml)
    })
    if(configMatch){
     //@ts-ignore
     return configMatch.parser(parsedHtml, wordPasteOptions, googlePasteOptions)
    }
    return null
   } catch(err) {
    console.log("Error while copy pasting data using external script", err)
   }
}


const pattern = /^docs-internal-guid/
const withHtml = editor => {

  const { insertData } = editor

  editor.insertData = async data => {
    const customPasteOptions = editor.customPasteOptions || {}
    const copyPasteConfig = editor.copyPasteConfig || {}
    const wordPasteOptions: object = customPasteOptions && customPasteOptions.wordDoc || {}
    const googlePasteOptions = customPasteOptions && customPasteOptions.googleDoc || {}

    const html = data.getData('text/html');

    if (html) {
      let parsedHtml: any = new DOMParser().parseFromString(html, 'text/html').body;
      let docTypeFromScript = copyPasteConfig?.findDocType?.(parsedHtml)
      let fragment;
      let googlePlainHtml = "";

      let isGoogleDoc = Array.from(parsedHtml.childNodes).some((node: any) => {
        if (node.nodeName === 'B' && pattern.test(node['id'])) {
          googlePlainHtml = node.innerHTML
          return true
        }
        return false
      })
      const isCSContent =
        parsedHtml.querySelector('[data-editor-type*="scrte"]')
      if (!isCSContent) {
        collapse(parsedHtml)
      }
     const isWord =
        parsedHtml.getElementsByTagName('O:P').length ||
        (parsedHtml.querySelector('[class*="Mso"]' || parsedHtml.querySelector('[style*="mso"]')))
      const isOnlineWord = parsedHtml.querySelector('[class*="BCX"]')

      const fragmentFromCopyPasteScript = getFragmentFromCopyPasteScript(copyPasteConfig, docTypeFromScript, parsedHtml, wordPasteOptions, googlePasteOptions)

      if(fragmentFromCopyPasteScript) {
        fragment = fragmentFromCopyPasteScript
     }  else if (isGoogleDoc) {
        parsedHtml = new DOMParser().parseFromString(googlePlainHtml, 'text/html').body
        fragment = googleDeserialize(parsedHtml, googlePasteOptions);
      } else if (isCSContent) {
        fragment = csDeserialize(parsedHtml, editor)
      } else if (isWord) {
        console.error("Error while loading word copy paste script. Running deserilizer from venus-components.")
        fragment = wordDeserialize(parsedHtml, wordPasteOptions)
      } else if (isOnlineWord) {
        console.error("Error while loading word copy paste script. Running deserilizer from venus-components.")
        fragment = onlineWordDeserialize(parsedHtml)
      }
      else {
        fragment = defaultDeserialize(parsedHtml);
      }

      if (fragment.length > 1) {
        fragment = preInsert(editor, fragment)
        let customInserts = getCustomInserts();
        if (customInserts?.length) {
          customInserts.forEach((element) => {
            try {
              fragment = element[1](editor, fragment)
              Array.isArray(fragment)
              fragment.every((element) => typeof element === 'object')
            }
            catch (err) {
              console.log("Invalid Fragment: ", err)
            }
          })
        }
      }
      applyDirty(fragment);
      Transforms.insertFragment(editor, fragment);
      return;
    }
    insertData(data);
  }
  return editor
}
Registry.register(withHtml, { id: 'withHtml', registry: 'v2.plugin' })