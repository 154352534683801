import React from 'react'
import { cx, css } from '@emotion/css';
import { InfiniteScrollTable, Icon } from '../../../../../../../../../../index'
import { formatTime, getAssetType } from '../../util';
import styles from './index.module.css'

const GetIcon = ({ data }) => {
  let icon = getAssetType(data.content_type);
  return (
    <React.Fragment>
      <span className={cx(styles['list-icon'], css`
        background: ${data.url ? `url(${data.url})` : 'inherit'};
      `)}>
        {icon && <Icon icon={icon} />}
      </span>
      {data.name ? data.name : data.title}
    </React.Fragment>
  )
}

export const ListView = ({ state, fetchOnChange, loadMore, handleClick, loading, users = {} }) => {
  const columns = [
    {
      Header: 'Title',
      id: 'title',
      accessor: (data) => {
        return (
          <div className='flex-v-center'>
            <div data-testid="asset-list-title" className={'scrte-flex-center'}>
              <GetIcon data={data} />
            </div>
          </div>
        )
      },
      default: true,
      addToColumnSelector: true,
    },
    {
      Header: 'Unique ID',
      accessor: 'uid',
      id: 'uid',
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
    {
      Header: 'Last Modified',
      id: 'updated_by',
      accessor: (data) => <div className={'last-updated-by'}><span data-testid="asset-list-updated-by">{users[data.updated_by] || 'Anonymous'}</span> <span data-testid="asset-list-updated-at" className='last-updated-by-time'>{formatTime(data.updated_at)}</span></div>,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
  ]
  let tableHeight = 400
  if (state && state.selectedImage && Object.keys(state.selectedImage).length) {
    tableHeight = 200
  }
  return (
    <div data-testid="list-view" className={cx('scrte-infinite-list', styles['overflow-hidden'])}>
      <InfiniteScrollTable
        tableHeight={tableHeight}
        data={state.assets}
        columns={columns}
        uniqueKey={'uid'}
        fetchTableData={fetchOnChange}
        loadMoreItems={loadMore}
        loading={loading}
        totalCounts={state.count}
        onRowClick={handleClick}
        singleSelectedRowId={state.selectedImage.uid}
        itemStatusMap={state.itemStatusMap}
        columnSelector={false}
        minBatchSizeToFetch={30}
        name={{ singular: 'Asset', plural: 'Assets' }}
      />
    </div>
  )
}
