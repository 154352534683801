//@ts-nocheck
import { createHeadCell } from "./createHeadCell"

export const createHeader = (cols: any) => {
    let head = {
        type: 'thead',
        attrs: {},
        children: [],
    }
    let row = {
        type: 'tr',
        attrs: {},
        children: [],
    }
    let col = new Array(cols).fill().map((child) => createHeadCell())
    row.children = col.slice()
    head.children = [JSON.parse(JSON.stringify(row))]
    return Object.assign({}, head)
}
