//@ts-nocheck
import React from 'react'
import { cbModal } from '../../../../../index'
import { setOldSelection } from '../../utils/setOldSelection'

import ImageModal from './Modal'
import UploadModal from './Modal/UploadModal'

import './style.module.css'

export const imageHandleMouseDown = (e, editor) => {
  e?.preventDefault();

  const state = new editor.TextareaState(editor.panels)

  cbModal({
    component: (props) => <ImageModal editor={editor} state={state} {...props} />,
    modalProps: {
      size: 'max',
      shouldReturnFocusAfterClose: false,
      onClose: () => { }
    },
  })
}

export const uploadImageHandleMouseDown = (e, editor) => {
  e?.preventDefault();

  const state = new editor.TextareaState(editor.panels)

  cbModal({
    component: (props) => <UploadModal editor={editor} state={state} {...props} />,
    modalProps: {
      size: 'max',
      shouldReturnFocusAfterClose: false,
      onClose: () => { }
    },
  })
}