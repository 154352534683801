import React from 'react'

import {
  OrderedListIcon,
  TableIcon,
  AlignmentIcon,
  ParagraphIcon,
  ImageIcon,
  SeeMoreIcon,
  LinkIcon,
} from '../utils/Icon'

import { Icon } from '../../../../Icon2';


export const elementCategories = {
  TEXT_FORMAT: {
    id: 'text-format',
    title: 'Format',
    rootCategory: false,
    icon: <ParagraphIcon />,
    toolbar: {
      inHoveringToolbar: false,
    },
  },
  CONTENTSTACK_REFERENCE: {
    id: 'contentstack_reference',
    title: 'Embed',
    rootCategory: false,
    icon: <Icon icon="Embed" />,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: false,
    },
  },
  LINK_DROPDOWN: {
    id: 'link_dropdown',
    title: 'Link',
    rootCategory: false,
    icon: <LinkIcon />,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: false,
    },
  },
  ALIGNMENT_DROPDOWN: {
    id: 'alignment-dropdown',
    title: 'Align',
    rootCategory: false,
    icon: <AlignmentIcon />,
    toolbar: {
      inHoveringToolbar: false,
    },
  },
  LIST_ITEMS: {
    id: 'list_items',
    title: 'List',
    rootCategory: false,
    icon: <OrderedListIcon />,
    toolbar: {
      inHoveringToolbar: false,
    },
  },
  TABLE_ACTION: {
    id: 'table',
    title: 'Table',
    rootCategory: false,
    icon: <TableIcon />,
    toolbar: {
      inHoveringToolbar: false,
    },
  },
  IMAGE: {
    id: 'img',
    title: 'Image',
    rootCategory: false,
    icon: <ImageIcon />,
    toolbar: {
      inHoveringToolbar: false,
    },
  },
  VIDEO: {
    id: 'video',
    rootCategory: false,
    isButton: true,
    toolbar: {
      inHoveringToolbar: false,
    },
  },
  SOCIAL_EMBEDS: {
    id: 'social-embeds',
    rootCategory: false,
    isButton: true,
    toolbar: {
      inHoveringToolbar: false,
    },
  },

  // see more
  MULTIMEDIA_SEE_MORE: {
    id: 'multimedia_see_more',
    title: 'More Options',
    rootCategory: false,
    icon: <SeeMoreIcon />,
    toolbar: {
      inHoveringToolbar: false,
    }
  },

  // root categories
  ALIGNMENT_AND_LIST: {
    id: 'alignment_and_list',
    title: 'alignments and list',
    rootCategory: true,
    subCategories: ['TEXT_FORMAT', 'ALIGNMENT_DROPDOWN', 'LIST_ITEMS'],
    seeMore: null,
  },
  MULTIMEDIA: {
    id: 'multimedia',
    title: 'Multimedia',
    rootCategory: true,
    subCategories: ['IMAGE', 'VIDEO', 'SOCIAL_EMBEDS', 'TABLE_ACTION', 'CONTENTSTACK_REFERENCE', 'LINK_DROPDOWN'],
    seeMore: 'MULTIMEDIA_SEE_MORE',
    toolbar: {
      inHoveringToolbar: false,
    },
  },
}
