import React from 'react'
import { Registry } from '@react-registry'

import { ItalicIcon } from '../../utils/Icon'
import { leafCategories } from '../leafCategories'

export const Italic = ({ children }) => <em>{children}</em>

export * from './deserializer'

Registry.register(
  {
    Component: Italic,
    title: 'Italic',
    iconName: <ItalicIcon />,
    shortcut: { key: 'mod+i' },
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE,
    inBasicToolbar: true,
  },
  { id: 'italic', registry: 'v2.leaf' }
)
