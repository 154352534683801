import { Registry } from '@react-registry'
import { getAttributesObject } from '../../../../../utils'

export const inlineReferenceDeserializer = (el) => {
  return {
    type: 'reference',
    attrs: getAttributesObject(el),
    children: [{ text: '' }]
  }
}

Registry.register(inlineReferenceDeserializer, {
  id: 'inline',
  registry: 'deserializer'
})
