import React from 'react';

const Connector = ({ depth = 1, type = null }: any) => {
  if (type) {
    if (type === 'and') {
      return (
        <span className="AdvancedSearch__connectors AdvancedSearch__connectors--custom">
          <span className="AdvancedSearch__connector"></span>
          <div className="AdvancedSearch__connector-count"><span>{'&'}</span></div>
        </span>
      )
    }
  }

  if (depth > 4) {
    return (
      <span className="AdvancedSearch__connectors">
        <span className="AdvancedSearch__connector"></span>
        <div className="AdvancedSearch__connector-count"><span>{depth}</span></div>
      </span>
    )
  }
  const connectors = [];
  for (let d = 0; d < depth; d++) {
    connectors.push(<span key={d} className="AdvancedSearch__connector"></span>)
  }

  if (!connectors.length) {
    connectors.push(<span className="AdvancedSearch__connector"></span>)
  }

  return (
    <span className="AdvancedSearch__connectors">
      {connectors}
    </span>
  )
}

export default Connector; 