import { cloneDeep } from "lodash"
import React from "react"
import { Editor, Element, Node, NodeEntry, Path, Range, Transforms } from "slate"
import { jsx } from "slate-hyperscript"
import { ElementWithType } from "utils/types"
import { cellType, isCellSpanning } from "./mergeCells"


export const unMergeCells = (event, { editor }) => {

  const { selection } = editor

  if (!selection && Range.isExpanded(selection)) return

  const [table] = Editor.nodes(editor, { match: (node: ElementWithType) => node.type === 'table' })

  const trgrp = jsx('element', { type: 'trgrp' })

  const cell = Editor.above(editor, {
    match: (node: ElementWithType) => cellType.includes(node.type)
  })
  if (!cell) return

  if (!isValidForUnMerging(cell)) return

  const setCellUnVoid = (path: Path) => {
    const cellProps = jsx('element', {
      // @ts-ignore
      type: cell[0]?.type, "attrs": {
        "redactor-attributes": {}
      }
    }, [{ text: '' }])
    try {

      Transforms.removeNodes(editor, { at: path })
      Transforms.insertNodes(editor, cellProps, { at: path })
    }
    catch { }
  }

  const removeSpans = ([cell, path]: NodeEntry) => {
    // @ts-ignore
    const attrs = cloneDeep(cell?.attrs ?? {})
    delete attrs?.colSpan
    delete attrs?.rowSpan

    delete attrs?.['redactor-attributes']?.['colspan']
    delete attrs?.['redactor-attributes']?.['rowspan']
    Transforms.setNodes(editor, { attrs } as Partial<Element>, {
      at: path
    })
  }

  const setDisabledCols = () => {
    const rows = Array.from(Editor.nodes(editor, {
      at: table[1],
      match: (node: ElementWithType) => node.type === 'tr'
    }))

    let disabledCols = []

    rows.forEach(([row, path]: NodeEntry<Element>) => {
      const disabledColsForRow = row.children.flatMap((cell, index) => {
        const { hasColSpan, hasRowSpan } = isCellSpanning(cell)
        const hasSpanning = hasRowSpan || hasColSpan
        if (editor.isVoid(cell) || hasSpanning)
          return [index]

        return []
      })

      disabledCols = disabledCols.concat(disabledColsForRow)

    });
    disabledCols = Array.from(new Set(disabledCols))
    Transforms.setNodes(editor, {
      attrs: {
        // @ts-ignore
        ...table[0].attrs,
        disabledCols
      }
    } as ElementWithType, { at: table[1] })

  }

  const unWrapAndWrapRowsWithTrgrp = () => {
    Transforms.unwrapNodes(editor, { at: rowParentPath })

    rows.forEach(rowRef => {

      const [row, rowPath] = Editor.node(editor, rowRef.current) as NodeEntry<Element>

      const noOfVoids = row.children.reduce((count, cell) => {
        return count + editor.isVoid(cell)
      }, 0)

      const noOfColSpan = row.children.reduce((count, cell) => {
        // @ts-ignore
        return count + (cell?.attrs?.colSpan ?? 1) - 1
      }, 0)

      const hasVoidCells = Boolean(noOfVoids)

      const hasRowSpan = row.children.some(cell => isCellSpanning(cell).hasRowSpan)

      if (hasRowSpan || (hasVoidCells && noOfVoids !== noOfColSpan)) {
        Transforms.wrapNodes(editor, trgrp, { at: rowPath })
      }

    })

  }

  const mergeTrgrps = () => {
    rows.forEach((rowRef, index) => {
      if (index === 0) return

      const [row, rowPath] = Editor.node(editor, rowRef.current) as NodeEntry<ElementWithType>
      const [parent, parentPath] = Editor.parent(editor, rowPath) as NodeEntry<ElementWithType>
      if (parent.type !== 'trgrp') return

      const previousRowRef = rows[index - 1]
      const [previousRowParent] = Editor.parent(editor, previousRowRef.current) as NodeEntry<ElementWithType>

      if (previousRowParent.type !== 'trgrp') return

      const hasVoidCells = row.children.some(editor.isVoid)

      if (!hasVoidCells) return

      Transforms.mergeNodes(editor, {
        at: parentPath,
        match: (node: ElementWithType) => node.type === 'trgrp'
      })
    })
  }

  const removeVoidCells = () => {
    const cellsToRemoveVoidPaths =
      rowsToCheckRefs.flatMap((rowPathRef) => {
        return Array(colSpan).fill(0).map((_, j) => (
          rowPathRef.current.concat(cellPos + j)
        ))
      })

    cellsToRemoveVoidPaths.shift()

    cellsToRemoveVoidPaths.forEach(setCellUnVoid)
  }
  // @ts-ignore
  const { colSpan = 1, rowSpan = 1 } = cell[0]?.attrs

  if (colSpan < 2 && rowSpan < 2) return

  removeSpans(cell)

  const [, selectedRowPath] = Editor.parent(editor, cell[1])
  const [, cellPath] = cell
  const [rowPos, cellPos] = cellPath.slice(-2)
  const [rowParent, rowParentPath] = Editor.parent(editor, selectedRowPath) as NodeEntry<Element>

  const rows = rowParent.children.map((_, i) => Editor.pathRef(editor, rowParentPath.concat(i)))

  const rowsToCheckRefs = rows.slice(rowPos, rowPos + rowSpan)

  removeVoidCells()

  if (rowSpan > 1) {

    unWrapAndWrapRowsWithTrgrp()
    mergeTrgrps()

  }
  rows.forEach(ref => ref.unref())

  setDisabledCols()

}

export function isValidForUnMerging([cell, path]: NodeEntry) {
  const { hasColSpan, hasRowSpan } = isCellSpanning(cell)
  if (!(hasColSpan || hasRowSpan)) return false
  return true
}