import React, { Fragment, useEffect } from 'react';

var callbacks = [];

declare const window: any;
declare const FB: any;

const addScript = (src, cb) => {
  if (callbacks.length === 0) {
    callbacks.push(cb)
    var s = document.createElement('script')
    s.setAttribute('src', src)
    s.setAttribute('async', "1")
    s.setAttribute('defer', "1")
    s.setAttribute('crossorigin', "anonymous")
    s.onload = () => callbacks.forEach((cb) => cb())
    document.body.appendChild(s)
  }
  else {
    callbacks.push(cb)
  }
}

const Facebook = ({ url, type, show_text, width, height }) => {
  useEffect(() => {
    const renderFacebook = () => {
      window.FB.XFBML.parse();
    }
    if (!window.FB) {
      addScript("https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0", renderFacebook)
    }
    else {
      renderFacebook();
    }
  }, []);

  return (
    <Fragment>
      <div className="fb-post" data-href={url} data-width={width ? width : "500"}></div>
    </Fragment>
  );
}

export default Facebook;