import React from 'react'
import { Registry } from '@react-registry'
import { cx } from '@emotion/css'
//@ts-ignore
import styles from '../style.module.css'

import RowDNDIcon from '../table-row/DndRowIcon'
//@ts-ignoreDndstyles
import Dndstyles from '../table-row/style.module.css'
//@ts-ignoreDndstyles
import PreviewStyleClass from '../table-row/preview.module.css'

export * from './deserializer'
let classNames = {
  divTableRow: styles['div-table-row'],
  tableRowExtraCell: styles['table-add-column-extra-cell']
}
const TableRow = (props) => {

  props = { ...props, styles: { ...classNames } }
  return (
    <div {...props.attributes} className={'scrte-table-row'}>
      <div data-testid="trgrp" data-type="table-row" className={props.styles.divTableRow}>
        {props.children}
      </div>
    </div>
  )
}

const getDroppableContainer = (element, path) => {
  return path.slice(0, path.length - 1).join('')
}
export default TableRow

Registry.register(
  {
    Component: TableRow,
    title: 'Table Row',
    iconName: 'ri-layout-row-line',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
      inSlashToolbar: false
    },
    isContentStackElement: true,
    dndOptions: {
      DisableSelectionHalo: true,
      CustomDndIcon: RowDNDIcon,
      ContainerClassName: 'scrte-table-row-grp-dnd-container',
      DisableAdd: true,
      dndStyles: Dndstyles,
      getDroppableContainer: getDroppableContainer,
      PreviewStyleClass: PreviewStyleClass,
      DisableGridDnd: true
    }
  },
  { id: 'trgrp', registry: 'element' }
)
