import { Transforms } from 'slate'
import { ReactEditor } from 'slate-react';
import { ElementWithType } from '../../../../../../utils/types';

export const handleDelete = (editor, element) => {
    let path = ReactEditor.findPath(editor, element);
    let pathLength = path.length;
    let siblingPath = [...path.slice(0, pathLength - 1), path[pathLength - 1] + 1];
    Transforms.insertNodes(editor, { type: 'p', children: [{ text: '' }] } as ElementWithType, { at: siblingPath })
    Transforms.delete(editor, {
        at: path
    })
}