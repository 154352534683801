import { Registry } from '@react-registry'

export const withStyle = (editor) => {
  const { isVoid } = editor
  editor.isVoid = (element) => {
    return element.type === 'style' ? true : isVoid(element)
  }
  return editor
}

Registry.register(withStyle, { id: 'withStyle', registry: 'plugin' })