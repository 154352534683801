import React from 'react'
import { defaultTextContent } from './util/constants'

const contextData: any = {
  queryArray: [],
  updateQueryArray: () => {},
  // isAutoCompleteOpen: false,
  // openAutoComplete: () => {},
  // closeAutoComplete: () => {},
  updateAdvanceQueryArray: () => {},
  advanceQueryArray: [],
  updateAdvanceQueryMatchCase: () => {},
  onDeleteAdvanceQuery: () => {},
  setDropdownOpenStatus: () => {},
  isDropdownOpen: false,
  selectedCtStatus: null,
  textContent: defaultTextContent
}

export const SearchContext = React.createContext(contextData)
