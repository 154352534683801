
import { jsx } from 'slate-hyperscript'

import { getRegistry } from '../../../../utils/getRegistry'
let ELEMENT_TAGS = getRegistry('v2.deserializer')

let TEXT_TAGS = getRegistry('v2.inline-deserializer')
const whiteCharPattern = /^[\s ]+$/
export const deserialize = (el, editor) => {
  if (el.nodeName === 'BODY') {
    ELEMENT_TAGS = getRegistry('v2.deserializer')
    TEXT_TAGS = getRegistry('v2.inline-deserializer')
  }
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return jsx('element', { type: 'p' }, { text: '' })
  }
  const { nodeName } = el
  let parent = el
  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }
  //@ts-ignore
  let children = Array.from(parent.childNodes)
    .map((child) => deserialize(child, editor))
    .flat()

  children = children.filter((child) => child !== null && child !== undefined)

  if (children.some(child => child.type === 'thead' || child.type === 'tbody')) {
    if (parent.nodeName === 'BODY') {
      let rows = 0
      let cols = 0
      const tableChildren = []
      const prevChildren = [] // to maintain order of other type el
      const nextChildren = [] // to maintain order of other type el
      const tbodyIndex = children.findIndex(child => child.type === 'tbody')
      const theadIndex = children.findIndex(child => child.type === 'thead')
      children.forEach((child, index) => {
        if (child.type === 'tbody') rows = rows + child.children.length
        if (child.type === 'thead') {
          rows = rows + 1
          cols = child.children[0].children.length
        }
        if (child.type === 'tbody' || child.type === 'thead') {
          tableChildren.push(child)
        } else {
          if (tbodyIndex !== -1 || theadIndex !== -1) {
            if (tbodyIndex > index || theadIndex > index) prevChildren.push(child)
            if (tbodyIndex < index || theadIndex < index) nextChildren.push(child)
          }
        }
      })
      const colWidths = new Array(cols); for (let i = 0; i < cols; ++i) colWidths[i] = 250
      const attrs = {
        rows,
        cols,
        colWidths
      }
      const newChildren = [
        ...prevChildren,
        { type: 'table', attrs, children: tableChildren },
        ...nextChildren
      ]
      children = newChildren
    }
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)

  }
  if (el.getAttribute('data-type') === "ignore-deserializer") {
    return
  }
  if (ELEMENT_TAGS[nodeName] || ELEMENT_TAGS[el.getAttribute('data-type')]) {
    let key = ELEMENT_TAGS[el.getAttribute('data-type')] ? el.getAttribute('data-type') : nodeName;
    if (children.length === 0) {
      children = [{ text: "" }]
    }
    if (editor.customToolbarComponents?.['v2.element']?.length || editor.customToolbarComponents?.['v2.leaf']?.length || editor.customToolbarComponents?.['v2.editorButtons']?.length) {
      if (editor.customToolbarComponents?.['v2.element']?.includes(key.toLowerCase())) {
        const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
        return jsx('element', attrs, children)
      }
      else if (key === 'LI') {
        if (editor.customToolbarComponents?.['v2.element']?.includes('ol') || editor.customToolbarComponents?.['v2.element']?.includes('ul')) {
          const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
          return jsx('element', attrs, children)
        }
      }
      else if (key === 'IFRAME' || key === 'SocialEmbeds') {
        if (editor.customToolbarComponents?.['v2.element']?.includes('embed') || editor.customToolbarComponents?.['v2.element']?.includes('social-embeds')) {
          const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
          return jsx('element', attrs, children)
        }
        else {
          return
        }
      }
      else if (key === 'IMG' || key === 'SPAN' || key === 'asset') {
        if (editor.customToolbarComponents?.['v2.element']?.includes('img')) {
          const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
          return jsx('element', attrs, [{ text: '' }])
        }
        else {
          return
        }
      }
      else if (key === 'PRE') {
        if (editor.customToolbarComponents?.['v2.element']?.includes('code')) {
          const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
          return jsx('element', attrs, children)
        }
        else {
          return jsx('element', ELEMENT_TAGS['P'].components[0]['component'](el), children)
        }
      }
      else if (key === 'block' || key === 'inline') {
        if (editor.customToolbarComponents?.['v2.element']?.includes('reference')) {
          const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
          return jsx('element', attrs, [{ text: '' }])
        }
        else {
          return
        }
      }
      else if (key === 'link') {
        if (editor.customToolbarComponents?.['v2.element']?.includes('linkReference') || editor.customToolbarComponents?.['v2.element']?.includes('assetLinkReference')) {
          const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
          return jsx('element', attrs, children)
        }
        else {
          return
        }
      }
      else {
        if (key === 'A') {
          return jsx('element', { type: 'span' }, children)
        }
        return jsx('element', ELEMENT_TAGS['P'].components[0]['component'](el), children)
      }
    }
    const attrs = ELEMENT_TAGS[key].components[0]['component'](el)
    if (key === 'asset' || key === 'IMG') {
      return jsx('element', attrs, [{ text: '' }])
    }
    return jsx('element', attrs, children)
  }


  if (TEXT_TAGS[nodeName] || TEXT_TAGS[el.getAttribute('data-type')]) {
    let key = TEXT_TAGS[nodeName] ? nodeName : el.getAttribute('data-type')
    const attrs = TEXT_TAGS[key].components[0]['component'](el)
    if (!editor.customToolbarComponents?.['v2.leaf']?.includes(Object.keys(attrs)[0])) {
      let property = Object.keys(attrs)[0]
      delete attrs[property]
    }
    return children.map((child) => jsx('text', attrs, child))
  }
  return children
}
