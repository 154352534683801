import { Registry } from '@react-registry'
import { getAttributesObject } from '../../../../../utils'

export const linkReferenceDeserializer = (el) => {
  return {
    type: 'reference',
    attrs: getAttributesObject(el)
  }
}

Registry.register(linkReferenceDeserializer, {
  id: 'link',
  registry: 'deserializer'
})
