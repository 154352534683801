//@ts-nocheck
import { Registry } from '@react-registry'
import { Transforms, Range } from 'slate'

export const withFakeSelection = (editor) => {
  editor.fakeSelection = null
  editor.setFakeSelection = function () {
    const { selection } = editor
    const isExpanded = selection && !Range.isCollapsed(selection)
    if (isExpanded) {
      Transforms.wrapNodes(
        editor,
        {
          type: 'editor-selection',
          children: []
        },
        {
          split: true
        }
      )
      editor.fakeSelection = editor.selection
    }
  }
  editor.unsetFakeSelection = function () {
    Transforms.unsetNodes(editor, {
      at: editor.fakeSelection,
      match: (n) => n.type === 'editor-selection'
    })
    editor.fakeSelection = null
  }
  return editor
}

Registry.register(withFakeSelection, {
  id: 'withFakeSelection',
  registry: 'v2.plugin'
})
