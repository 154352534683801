/**
 * This table component provides infinite scrolling
 * with virtual rendering
 **/
import React, { useEffect, useRef, useState, forwardRef, Ref, useCallback } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useResizeColumns,
  useColumnOrder,
  useBlockLayout
} from 'react-table'
import { FixedSizeList } from 'react-window'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import classnames from 'classnames'

import Button from '../Button/Button'
import Icon from '../Icon2/Icon'
import EmptyState from '../EmptyState/EmptyState'
import TablePanel from './TablePanel'
import { pushCheckboxInRow, RowSelectAction, TableHead, RenderRow, pushActionColumn } from './BitsAndPiecesTable'
import {
  constants,
  viewByName,
  setWidth,
  defaultValue as defaultValueUtil,
  defaultProps as defaultPropsUtil,
  MAX_FREEZE_COUNT,
  appendRule,
  getFreezeColumnCount,
  getTableHeadWidth,
  handleDataOnRowSelect,
  compareColumn,
  getControlledFreezedColumns,
  getControlledListProps,
  getMinMaxStyle
} from './util'
import './Table.css'
import flatten from 'lodash/flatten'

//Note: add any default value in defaultValueUtil object
const defaultValue = {
  ...defaultValueUtil,
  withExportCta: {
    component: <></>,
    showExportCta: false
  }
}

const defaultProps = {
  ...defaultPropsUtil,
  withExportCta: {
    component: <></>,
    showExportCta: false
  }
}

export type InitialSortByProp = {
  id: string
  desc: boolean
}

export type RowSelectCheckboxProp = {
  key: string
  value: boolean
}

export type ActionCbArgProp = {
  data: any[]
  type: string
}

export type onRowSelectProp = {
  label: string | React.ReactNode
  cb: (actionCbArg: ActionCbArgProp) => void
  type?: string
  icon?: string
  showSelected?: boolean
}

export type NameProp = {
  singular: string
  plural: string
}
export type CustomDropDownListProp = {
  label: string | React.ReactNode
  action: (actionCbArg: ActionCbArgProp) => void
  default?: boolean
}
export type CustomDropdownOptionProp = {
  label: string
  value: string
  icon?: string
  className: string
  list: CustomDropDownListProp[]
  activeIcon: string
}

export type ColumnsProp = {
  Header: string
  accessor: string | Function
  default?: boolean
  disableSortBy?: boolean
  Cell?: (props: any) => React.ReactNode
  addToColumnSelector?: boolean | false
  id?: string
  cssClass?: string
  columnWidthMultiplier?: number
}

export type SortByProp = {
  id: string
  sortingDirection: string
}

export type FetchDataArgProp = {
  skip: number
  limit: number
  sortBy?: SortByProp | undefined
  startIndex: number
  stopIndex: number
  searchText?: string
  refresh?: boolean
}

export type ItemStatusMapProp = {
  [index: number]: 'loading' | 'loaded'
}

export type ResizedColumnProp = {
  [id: string]: number
}

export type TooltipListProp = {
  label: string | React.ReactNode
  title: string
  action: Function
  displayType: string
}

export type ExportCtaProp = {
  component: React.ReactNode
  showExportCta: boolean
}

export type RowDisableProp = {
  key: string
  value: boolean
}

export type v2FeaturesProp = {
  tableRowAction?: boolean
  tableBulkAction?: boolean
}

type DefaultProps = Readonly<typeof defaultProps>

export type TableProps = {
  /**
   * The core columns configuration object for the entire table
   */
  columns: ColumnsProp[]
  /**
   * Show and hide, column selectors filters
   */
  columnSelector?: boolean
  /**
   * The Data array that you want to display on table
   */
  data: any[]
  /**
   * Used in rowselect header and table panel items counts
   */
  name?: NameProp
  /**
   * If a column's ID is contained in this array, it will be hidden
   */
  hiddenColumns?: string[]
  /**
   * used for sort data in table initially
   */
  initialSortBy?: InitialSortByProp[] | []
  /**
   * Calls when searchText, sortBy changes with `FetchDataArgProp`
   */
  fetchTableData: (fetchTableDataArg: FetchDataArgProp) => void
  /**
   * Click handler to attach onrowclick, calls with row data
   */
  onRowClick?: (e: any) => void
  /**
   * Used in other parts of table to track status of data
   */
  loading?: boolean
  /**
   * Pass true to add checkbox in row
   */
  isRowSelect?: boolean
  /**
   * Used for bulk opeartion on selected row
   */
  onRowSelectProp?: onRowSelectProp[]
  /**
   * Used internally for table operation, rendering unique row data
   */
  uniqueKey: string
  /**
   * Used for conditinally add select box in a row
   */
  rowSelectCheckboxProp?: RowSelectCheckboxProp
  /**
   * pass the rowIds which will be selected at table initial load
   */
  initialSelectedRowIds?: object
  /**
   * calls getSelectedRow method  with all selected row ids
   */
  getSelectedRow?: Function
  /**
   * Min batch size or data size(limit) to fetch on scroll
   */
  minBatchSizeToFetch?: number
  /**
   * Table uses this to track based of value of index if loading shows skeleton loader, if loaded shows data
   */
  itemStatusMap: ItemStatusMapProp
  /**
   * Table calls this to fetch data with `FetchDataArgProp`
   */
  loadMoreItems: (loadMoreItemsArg: FetchDataArgProp) => void
  /**
   * Total counts of all data
   */
  totalCounts: number
  /**
   * Size of a item basically row height
   */
  itemSize?: number
  /**
   * Pass it if you need to control scroll or smth with ref
   */
  fixedlistRef?: any
  /**
   * Show and hide table view selector
   */
  viewSelector?: boolean
  /**
   * Header text when there is no data available
   */
  emptyHeading?: any
  /**
   * Description for emptystate
   */
  emptyDescription?: any
  /**
   * we can also use this prop for empty state of table
   */
  emptyObj?: any
  /**
   *Get table view when changes, Comfort or Compact
   */
  getViewByValue?: (selectedViewBy: keyof typeof viewByName) => void
  /**
   * get position  value of scrollOffset
   */
  getScrollPostion?: (scrollOffset: number) => void
  /**
   * Pass it for Actions tooltip on hover of row
   */
  tableRowActionList?: Array<TooltipListProp>
  /**
   * use this props to make the entire row of table selectable
   */
  fullRowSelect?: boolean
  /**
   * Pass true to add search bar on top of table
   */
  canSearch?: boolean
  /**
   * Pass true to add refresh icon on top of table
   */
  canRefresh?: boolean
  /**
   * Callback function to be called when refresh icon is clicked
   */
  onRefresh?: Function
  /**
   * Placeholder for search
   */
  searchPlaceholder?: string
  /**
   * Input value for search
   */
  searchValue?: string
  /**
   * Pass it for single row select, use onRowClick to set it
   */
  singleSelectedRowId?: string
  /**
   * Height for table
   */
  tableHeight?: number
  /**
   * Pass it for different set of Actions tooltip on hover of row based on some condition
   */
  onHoverListCondition?: any
  /**
   * determines if single row of table is selectable or not based key and value passed in obj to this prop
   */
  conditionalSingleSelect?: any
  /**
   * With every action that is dispatched to the table's internal `React.useReducer` instance, this reducer is called and is allowed to modify the final state object for updating.
   */
  tableStateReducer?: any
  /**
   * decides maximum selected of the data of table
   */
  maxSelect?: number
  /**
   * table will use this to load with initial selected data
   */
  initialRowSelectedData?: []
  /**
   * Pass the number of static row you add in the table
   */
  staticRowCount?: number
  // selectKeyList?: string[]
  /**
   * will make all columns of table equal in width
   */
  equalWidthColumns?: boolean
  /**
   * calls with updated columnSelector data  when column selector is changed
   */
  onToggleColumnSelector?: Function
  /**
   * use this when we want open in new tab support for table row that has link
   */
  linkToRow?: string | Function
  /**
   * load custom component beside viewSeletor and columnSelector
   */
  withExportCta?: ExportCtaProp
  testId?: string
  customRowAdd?: boolean
  onSaveChange?: Function
  resetSearch?: boolean
  hideTablePanel?: boolean
  LinkComponent?: Function
  customRowComponent?: React.ReactNode
  /**
   * Used for conditinally disable a row
   */
  rowDisableProp?: RowDisableProp
  /**
   * calls onHoverText method  with current row data
   */
  onRowHoverText?: Function
  /**
   * Used for conditinally disable a table resize ß
   */
  isResizable?: boolean
  /**
   * calls for setting the resize widths in localstorage
   */
  onResizeColumn?: Function
  /**
   * Used to get the resized widths from localstorage
   */
  resizedColumnWidths?: ResizedColumnProp
  /**
   * Pass true to enable ordering of table column from column selector
   */
  canOrderColumn?: boolean
  /**
   * Table uses order of column's ID array to set its column order
   */
  columnsOrder?: string[]
  /**
   * calls with updated column orders when column's orders is changed
   */
  onChangeColumnOrder?: Function
  /**
   * Used to support freeze column behaviour
   */
  canFreezeColumn?: boolean
  /**
   * Used to provide custom option in settings dropdown
   */
  dropdownCustomOptions?: CustomDropdownOptionProp[]
  /**
   * calls for setting the freezed column in local storage
   */
  onColumnFreeze?: Function
  /**
   * Used to get the freezed column from local storage
   */
  freezedColumns?: string[]
  /**
   * Enable table v2 specific features
   */
  v2Features?: v2FeaturesProp
} & Partial<DefaultProps>

export const InfiniteScrollTable = (props: TableProps) => {
  //memoize data, column if needed
  const rowSelectCheckboxProp = props.rowSelectCheckboxProp

  const controlledSelectedRowIds =
    typeof props.initialSelectedRowIds === 'object'
      ? props.initialSelectedRowIds
      : defaultValue.controlledSelectedRowIds

  const name = props.name || defaultValue.name

  const columnSelector = typeof props.columnSelector === 'boolean' ? props.columnSelector : defaultValue.columnSelector
  const minBatchSizeToFetch = props.minBatchSizeToFetch || defaultValue.minBatchSizeToFetch
  const viewSelector = typeof props.viewSelector === 'boolean' ? props.viewSelector : defaultValue.viewSelector
  const loading = typeof props.loading === 'boolean' ? props.loading : defaultValue.loading
  const canSearch = typeof props.canSearch === 'boolean' ? props.canSearch : defaultValue.canSearch
  const canRefresh = typeof props.canRefresh === 'boolean' ? props.canRefresh : defaultValue.canRefresh
  const tableHeight = typeof props.tableHeight === 'number' ? props.tableHeight : 0
  const staticRowCount = typeof props.staticRowCount === 'number' ? props.staticRowCount : 0

  const totalCounts = typeof props.totalCounts === 'number' ? props.totalCounts : 30
  const maxSelect = typeof props.maxSelect === 'number' ? props.maxSelect : null
  const linkToRow = props.linkToRow || null
  const withExportCta = props.withExportCta || defaultValue.withExportCta
  const testId = typeof props.testId === 'string' ? props.testId : defaultValue.tableTestId

  const initialRowSelectedData: any = Array.isArray(props.initialRowSelectedData) ? props.initialRowSelectedData : []
  const hiddenColumnsProp: any = Array.isArray(props.hiddenColumns) ? props.hiddenColumns : null

  const rowDisableProp: RowDisableProp = props.rowDisableProp
  const onRowHoverText: any = props.onRowHoverText

  const canOrderColumn = typeof props.canOrderColumn === 'boolean' ? props.canOrderColumn : defaultValue.canOrderColumn
  const columnsOrder = props.columnsOrder || []
  const onChangeColumnOrder = props.onChangeColumnOrder

  const columnWidthsObjectProp = typeof props.resizedColumnWidths === 'object' ? props.resizedColumnWidths : {}

  const v2Features = props.v2Features || defaultValue.v2Features

  let [selectedData, setSelectedData] = useState(initialRowSelectedData)
  const [viewBy, setViewBy] = useState(constants.comfort_view)
  const [searchValue, setSearchValue] = useState(props.searchValue || '')
  const [toolTipPosFromRight, updateTooltipPos] = useState(0)

  const [isOpenAddColumn, setIsOpenAddColumn] = useState(false)
  const [rowActionData, updateRowActionData] = useState({ rowIndex: -1 })
  const [singleColumnWidth, setSingleColumnWidth] = useState(0)

  const itemSize = props.itemSize || (viewBy === constants.compact_view ? 48 : 60)
  let [listData, setListData] = useState([])
  const tableHeadRef = useRef(null)
  const tableRef = useRef(null)

  const tableHeadWdth = tableHeadRef.current && tableHeadRef.current.offsetWidth
  const tableWidth = tableRef.current && tableRef.current.offsetWidth

  const isTableWidthGreater = tableHeadWdth > tableWidth

  const defaultRef = useRef(null)
  const fixedlistRef = props.fixedlistRef || defaultRef

  const tableDefaultReducer = (newState) => newState
  const defaultColumn = () => {
    if (props.isResizable) {
      return getMinMaxStyle(tableWidth)
    }
  }

  const useTableInstance = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        hiddenColumns: props.hiddenColumns || [],
        sortBy: props.initialSortBy || [],
        selectedRowIds: controlledSelectedRowIds
      },
      disableMultiSort: true,
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      autoResetSelectedRows: true,
      autoResetHiddenColumns: false,
      autoResetPage: false,
      getRowId: (row: any) => row[props.uniqueKey],
      stateReducer: props.tableStateReducer || tableDefaultReducer
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder,
    (hooks: any) => {
      if (props.isRowSelect) {
        pushCheckboxInRow(hooks, {
          rowSelectCheckboxProp,
          selectedData,
          maxSelect,
          totalCount: totalCounts,
          uniqueKey: props.uniqueKey,
          rowDisableProp
        })
      }
      if (v2Features.tableRowAction) {
        pushActionColumn(hooks, {
          updateRowActionData,
          rowActionData
        })
      }
      if (props.isResizable) {
        hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
          const headerGroupColumns = headerGroups[0]
          const columnWidthsArrayProp = Object.keys(columnWidthsObjectProp)
          headerGroupColumns.headers.map((headerGroup) => {
            columnWidthsArrayProp.forEach((key, index) => {
              if (headerGroup.id == key) {
                headerGroup.width = setWidth(columnWidthsObjectProp[key], tableWidth)
              }
            })
            headerGroup.canResize = !headerGroup.disableResizing
          })
        })
      }
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy, selectedRowIds, columnResizing },
    setHiddenColumns,
    setColumnOrder
  } = useTableInstance

  const allVisibleColumn = allColumns.filter(
    (column) => column.isVisible && column.id !== 'selection' && column.id !== 'tableActions'
  )
  const hasFreezedColumn = props.canFreezeColumn && getFreezeColumnCount(listData, props.isRowSelect)

  const columnWidthsObjectState = typeof columnResizing.columnWidths === 'object' ? columnResizing.columnWidths : {}
  const totalHeadWidth = getTableHeadWidth(headerGroups)

  const freezedColumnPropsLength = props.freezedColumns?.length || 0

  useEffect(() => {
    try {
      if (props.canFreezeColumn) {
        let filteredAllColumn = allColumns.filter((column) => column.addToColumnSelector)

        let controlledFreezedColumn = []
        if (props.freezedColumns?.length > 0) {
          let columnsToFreeze = getControlledFreezedColumns(props.freezedColumns, filteredAllColumn)

          let freezedColumnObj = columnsToFreeze.map((columnId) => ({ id: columnId }))
          //picking object value from columnId of freezedColumns
          controlledFreezedColumn = compareColumn(allColumns, freezedColumnObj)

          //filtering columnId from unfreezed column
          controlledFreezedColumn.forEach((column) => {
            filteredAllColumn = filteredAllColumn.filter((filterColumn) => {
              return filterColumn.id !== column.id
            })
          })
          setDragListData([controlledFreezedColumn, filteredAllColumn])
          handleScrollPosition(controlledFreezedColumn)
          return
        }

        setListData([[], filteredAllColumn])
      }
    } catch (error) {
      console.log('useEffect', error)
    }
  }, [allColumns.length, freezedColumnPropsLength, props.canFreezeColumn, columnsOrder.length])

  useEffect(() => {
    setColumnOrder(columnsOrder)
  }, [columnsOrder.join(',')])

  useEffect(() => {
    if (hiddenColumnsProp) {
      const controlledHiddenColumn =
        allVisibleColumn.length === 1 && props.columns.length >= 2 && hiddenColumnsProp?.length
          ? hiddenColumnsProp.filter((column, index) => index !== 0)
          : hiddenColumnsProp
      setHiddenColumns(controlledHiddenColumn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumnsProp])

  useEffect(() => {
    const argsData = {
      selectedRowIds,
      rowSelectCheckboxProp,
      data: props.data,
      rowDisableProp,
      uniqueKey: props.uniqueKey,
      selectedData
    }
    const { updatedSelected, selectedRowIdsArr } = handleDataOnRowSelect(argsData)

    setSelectedData(updatedSelected)
    if (props.getSelectedRow) {
      // Passing allRow Selected Data as second Argument
      props.getSelectedRow(selectedRowIdsArr, updatedSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(selectedRowIds).length, props.data.length])

  useEffect(() => {
    let fetchDataArgument: FetchDataArgProp = defaultValue.fetchTableDataArg
    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      // fetchDataArgument.sortBy = { sortingDirection, id };
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }
    // fetchDataArgument.searchText = searchValue;
    fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    if (fixedlistRef && fixedlistRef.current) {
      fixedlistRef.current.scrollToItem(0)
    }
    if (!props.resetSearch) {
      props.fetchTableData(fetchDataArgument)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, searchValue])

  useEffect(() => {
    if (props.resetSearch) {
      setSearchValue('')
    }
  }, [props.resetSearch])

  useEffect(() => {
    let posFromRight = 0
    if (tableHeadWdth && tableWidth) {
      posFromRight = tableHeadWdth + defaultValue.toolTipOffset - tableWidth
    }
    updateTooltipPos(posFromRight)
  }, [tableWidth, tableHeadWdth])

  const columnResizeIndex = allColumns.findIndex((column) => column.isResizing)

  useEffect(() => {
    const columnWidthsArrayState = Object.keys(columnWidthsObjectState)
    if (columnWidthsArrayState.length) {
      let storedWidths = columnWidthsObjectProp
      //set singleColumnWidth to zero, to enable resizing when head is smaller
      if (singleColumnWidth !== 0) {
        setSingleColumnWidth(0)
      }
      columnWidthsArrayState.forEach((key) => {
        if (columnWidthsObjectProp && columnWidthsObjectProp.hasOwnProperty(key)) {
          storedWidths[key] = setWidth(columnWidthsObjectState[key], tableWidth)
        } else {
          storedWidths = { ...storedWidths, [key]: setWidth(columnWidthsObjectState[key], tableWidth) }
        }
        columnWidthsObjectState[key] = setWidth(columnWidthsObjectState[key], tableWidth)
      })

      if (props.onResizeColumn) {
        props.onResizeColumn(storedWidths, columnResizeIndex)
        if (hasFreezedColumn > 0) {
          let [freezed = []] = listData
          handleScrollPosition(freezed)
        }
      }
    }
  }, [columnWidthsObjectState, columnWidthsObjectProp, columnResizeIndex])

  useEffect(() => {
    if (v2Features.tableRowAction) {
      const isTableHeadSmaller =
        typeof totalHeadWidth === 'number' && typeof tableWidth === 'number' && totalHeadWidth < tableWidth

      if (isTableHeadSmaller) {
        const offsetToSubtract = 10 + (v2Features.tableRowAction ? 93 : 0) + (props.isRowSelect ? 42 : 0)
        let updatedTableWidth = tableWidth - offsetToSubtract
        let singleColumnWidth = Math.floor(updatedTableWidth / allVisibleColumn.length)

        if (props.isResizable) {
          let visibleColumnsWidth = {}
          allVisibleColumn.forEach((column) => {
            //syncing width , state and prop data
            columnWidthsObjectState[column.id] = singleColumnWidth
            column.width = singleColumnWidth
            visibleColumnsWidth[column.id] = singleColumnWidth
          })
          let storedWidths = columnWidthsObjectProp || {}
          storedWidths = { ...storedWidths, ...visibleColumnsWidth }
          if (props.onResizeColumn) {
            props.onResizeColumn(storedWidths, columnResizeIndex)
          }
        }

        setSingleColumnWidth(singleColumnWidth)
      } else if (singleColumnWidth !== 0) {
        setSingleColumnWidth(0)
      }
    }
  }, [allVisibleColumn.length, tableWidth])

  const setTableColumnOrder = (updatedColumnOrder) => {
    setColumnOrder(updatedColumnOrder)
    if (onChangeColumnOrder) {
      onChangeColumnOrder(updatedColumnOrder)
    }
  }

  const setDragListData = (data, action = '') => {
    setListData(data)
    const updatedOrderedColumnIds = flatten(data).map((list: any) => list.id)

    setTableColumnOrder(updatedOrderedColumnIds)

    let [freezed = []] = data
    if (action === 'setColumnFreeze') {
      onColumnsFreeze(freezed)
    }
  }

  const handleRowClick = (e, isRowDisabled, row) => {
    if (props.onRowClick && e.target.id !== 'rowSelect' && !isRowDisabled) {
      props.onRowClick(row.original)
    }

    if (props.isRowSelect && props.fullRowSelect) {
      const disableSelect =
        maxSelect &&
        selectedData.length >= maxSelect &&
        row.original &&
        !selectedData.find((data) => data[props.uniqueKey] === row.original[props.uniqueKey])
      if (
        rowSelectCheckboxProp &&
        row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value &&
        !disableSelect
      ) {
        const isSelected = row.isSelected ? false : true
        row.toggleRowSelected(isSelected)
      } else if (!rowSelectCheckboxProp && !disableSelect) {
        const isSelected = row.isSelected ? false : true
        row.toggleRowSelected(isSelected)
      }
    }
  }

  const updateViewBy = (selectedViewBy) => {
    if (viewBy !== selectedViewBy) {
      setViewBy(selectedViewBy)

      if (props.getViewByValue) {
        props.getViewByValue(selectedViewBy)
      }
    }
  }

  const onColumnsFreeze = (freezed) => {
    let freezedIds = freezed.map((item) => item.id)

    props.onColumnFreeze(freezedIds)
  }

  const unFreezeColumn = (freezed, unfreezed, column) => {
    let itemIndex = freezed.findIndex((item) => item.id === column.id)
    if (itemIndex > -1) {
      unfreezed.unshift(freezed[itemIndex])
      freezed.splice(itemIndex, 1)
    }
    setDragListData([freezed, unfreezed])
    handleScrollPosition(freezed)
    onColumnsFreeze(freezed)
  }

  const freezeColumn = (freezed, unfreezed, column) => {
    let itemIndex = unfreezed.findIndex((item) => item.id === column.id)
    if (itemIndex > -1) {
      freezed.push(unfreezed[itemIndex])
      unfreezed.splice(itemIndex, 1)
    }

    setDragListData([freezed, unfreezed])
    handleScrollPosition(freezed)
    onColumnsFreeze(freezed)
  }
  const replaceColumn = (freezed, unfreezed, column) => {
    let itemIndex = unfreezed.findIndex((item) => item.id === column.id)
    if (itemIndex > -1) {
      let poppedItem = freezed.pop()
      freezed.push(unfreezed[itemIndex])
      unfreezed.splice(itemIndex, 1)
      unfreezed.unshift(poppedItem)
    }
    setDragListData([freezed, unfreezed])
    handleScrollPosition(freezed)
    onColumnsFreeze(freezed)
  }

  const freezedUnfreezeColumn = (column) => {
    let [freezed = [], unfreezed = []] = listData
    let isFreezed = freezed.findIndex((item) => item.id === column.id) > -1
    if (isFreezed) {
      //remove column /unfreeze
      unFreezeColumn(freezed, unfreezed, column)
      return
    }
    if (freezed.length < MAX_FREEZE_COUNT) {
      // add or remove column
      if (!isFreezed) {
        // add or freeze column
        freezeColumn(freezed, unfreezed, column)
      }
    } else {
      // if freezed count exceeds MAX_FREEZE_COUNT
      if (!isFreezed) {
        replaceColumn(freezed, unfreezed, column) // replace column
      }
    }
  }

  const dropDownList = [
    {
      label: (
        <>
          <Icon icon="ComfortView" active={viewBy === constants.comfort_view} />
          <div className={`${viewBy === constants.comfort_view ? 'selected' : ''}`}>{constants.comfort_view}</div>
        </>
      ),
      action: () => updateViewBy(constants.comfort_view),
      default: true
    },
    {
      label: (
        <>
          <Icon icon="CompactView" active={viewBy === constants.compact_view} />
          <div className={`${viewBy === constants.compact_view ? 'selected' : ''}`}>{constants.compact_view}</div>
        </>
      ),
      action: () => updateViewBy(constants.compact_view)
    }
  ]

  const loadMoreItems = (startIndex, stopIndex) => {
    /**
     * Don't make call initially for loading case (totalCount not a number)
     * let parent component make call through fetchTableData on mount or
     * if sortBy changes table will call fetch table data
     **/
    if (typeof props.totalCounts !== 'number') {
      return
    }
    const skip = startIndex ? startIndex - 1 : startIndex
    const limit = stopIndex - skip

    let fetchDataArgument: any = { skip, limit, startIndex, stopIndex }

    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }

    if (searchValue) {
      fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    }

    props.loadMoreItems(fetchDataArgument)
  }
  const fetchMoreItems = loading ? () => {} : loadMoreItems

  const onChangeSearch = (value) => {
    setSearchValue(value)
  }

  const onRefresh = () => {
    let fetchDataArgument: FetchDataArgProp = defaultValue.fetchTableDataArg
    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }
    fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    if (fixedlistRef && fixedlistRef.current) {
      fixedlistRef.current.scrollToItem(0)
    }

    if (props.canRefresh && props.onRefresh) {
      props.onRefresh()
    } else {
      props.fetchTableData(fetchDataArgument)
    }
  }

  const handleScrollPosition = (freezed) => {
    let totalFreezedColumn = freezed.filter((column) => column.isVisible).length
    let freezeColumnCount = totalFreezedColumn > 0 && props.isRowSelect ? totalFreezedColumn + 1 : totalFreezedColumn
    const ruleForScroll = appendRule(document.styleSheets[0])
    ruleForScroll.selectorText = '.Table__body::-webkit-scrollbar-track:horizontal'
    let totalWidth = 0
    headerGroups.map((headerGroup) => {
      headerGroup.headers.map((header, index) => {
        if (freezeColumnCount > 0 && index < freezeColumnCount) {
          totalWidth += headerGroup.headers[index].width
        }
      })
    })
    ruleForScroll.style['marginLeft'] = totalWidth + 'px'
    updateToolTipPosition({ nativeElement: { target: { scrollLeft: tableWidth } } })
  }

  const getTooltipPosition = (scrollLeft) => {
    return totalHeadWidth < tableWidth
      ? defaultValue.toolTipOffset
      : totalHeadWidth + defaultValue.toolTipOffset - (tableWidth + scrollLeft)
  }

  const updateToolTipPosition = (event: { nativeElement?: { target: { scrollLeft: any } }; nativeEvent?: any }) => {
    if (hasFreezedColumn) {
      let tableScrollLeft = event.nativeEvent ? event.nativeEvent?.target.scrollLeft : tableWidth
      const scrollLeft =
        tableScrollLeft === tableWidth || tableScrollLeft === tableWidth - defaultValue.freezeColumnBorderWidth
          ? 1
          : tableScrollLeft
      const toolTipPosFromRight = getTooltipPosition(scrollLeft)
      updateTooltipPos(toolTipPosFromRight)
      return
    }

    const scrollLeft = (tableRef.current && tableRef.current.scrollLeft) || 0
    let toolTipPosFromRight = defaultValue?.toolTipOffset
    if (tableHeadWdth && tableWidth) {
      toolTipPosFromRight = tableHeadWdth + defaultValue?.toolTipOffset - (tableWidth + scrollLeft)
    }
    updateTooltipPos(toolTipPosFromRight)
  }

  const isItemLoaded = (index) => {
    if (props.itemStatusMap[index] === 'loaded') {
      return true
    }
    return false
  }

  const itemCount = props.data.length < totalCounts ? props.data.length + minBatchSizeToFetch : props.data.length
  const finalCount = itemCount >= totalCounts ? totalCounts : itemCount

  const emptyHead = props.emptyHeading || 'No Records Found'
  const emptyDesc = props.emptyDescription || ''

  const TableHeadNodes = (
    <TableHead
      isTableWidthGreater={isTableWidthGreater}
      viewBy={viewBy}
      listData={listData}
      tableHeadRef={tableHeadRef}
      isRowSelect={props.isRowSelect}
      isResizable={props.isResizable}
      headerGroups={headerGroups}
      equalWidthColumns={props.equalWidthColumns}
      canFreezeColumn={props.canFreezeColumn}
      columnWidthsObjectState={columnWidthsObjectState}
      columnWidthsObjectProp={columnWidthsObjectProp}
      totalCounts={totalCounts}
      v2Features={v2Features}
      tableWidth={tableWidth}
      singleColumnWidth={singleColumnWidth}
      loading={loading}
    />
  )

  const innerElementType = forwardRef<HTMLDivElement>(({ style, ...rest }: { children: []; style: any }, ref) => (
    <div className="Table__inner__wrapper">
      {TableHeadNodes}
      {props.customRowAdd && props.customRowComponent}
      <div
        ref={ref}
        style={{
          ...style,
          height: `${parseFloat(style?.height)}px`
        }}
        {...rest}
      />
    </div>
  ))

  const AddRemoveDescription = () => {
    const handleAddRemoveClick = () => {
      setIsOpenAddColumn(true)
    }

    return (
      <>
        <div>
          Looks like there isn't any data available for the selected columns. Try selecting different column filters
        </div>
        <Button className="mt-10" onClick={handleAddRemoveClick}>
          Add columns
        </Button>
      </>
    )
  }

  const RenderRowProps = {
    rows: rows,
    totalCounts: totalCounts,
    viewBy: viewBy,
    isResizable: props.isResizable,
    itemStatusMap: props.itemStatusMap,
    prepareRow: prepareRow,
    isRowSelect: props.isRowSelect,
    singleSelectedRowId: props.singleSelectedRowId,
    onHoverListCondition: props.onHoverListCondition,
    conditionalSingleSelect: props.conditionalSingleSelect,
    rowDisableProp: rowDisableProp,
    tableRowActionList: props.tableRowActionList,
    rowSelectCheckboxProp: rowSelectCheckboxProp,
    equalWidthColumns: props.equalWidthColumns,
    handleRowClick: handleRowClick,
    toolTipPosFromRight: toolTipPosFromRight,
    fullRowSelect: props.fullRowSelect,
    linkToRow: linkToRow,
    onRowHoverText: onRowHoverText,
    headerGroups: headerGroups,
    columnWidthsObjectState: columnWidthsObjectState,
    columnWidthsObjectProp: columnWidthsObjectProp,
    columnResizing: columnResizing,
    listData: listData,
    canFreezeColumn: props.canFreezeColumn,
    v2Features: v2Features,
    tableWidth: tableWidth,
    rowActionData: rowActionData,
    updateRowActionData: updateRowActionData,
    singleColumnWidth: singleColumnWidth,
    customRowAdd: props.customRowAdd
  }

  const tablePanelProps = {
    staticRowCount: staticRowCount,
    columnSelector: columnSelector,
    allColumns: allColumns,
    name: name,
    totalCounts: totalCounts,
    viewSelector: viewSelector,
    dropDownList: dropDownList,
    freezedUnfreezeColumn: freezedUnfreezeColumn,
    isResizable: props.isResizable,
    viewBy: viewBy,
    loading: loading,
    onChangeSearch: onChangeSearch,
    onRefresh: onRefresh,
    searchPlaceholder: props.searchPlaceholder,
    searchValue: props.searchValue,
    canSearch: canSearch,
    canRefresh: canRefresh,
    onToggleColumnSelector: props.onToggleColumnSelector,
    withExportCta: withExportCta,
    setTableColumnOrder: setTableColumnOrder,
    canOrderColumn: canOrderColumn,
    canFreezeColumn: props.canFreezeColumn,
    listData: listData,
    setListData,
    setDragListData,
    dropdownCustomOptions: props.dropdownCustomOptions,
    isOpenAddColumn,
    setIsOpenAddColumn,
    hasFreezedColumn: hasFreezedColumn
  }

  return (
    <div data-test-id={testId}>
      {selectedData.length && props.onRowSelectProp && props.onRowSelectProp.length ? (
        <RowSelectAction selectedData={selectedData} name={name} onRowSelectProp={props.onRowSelectProp} />
      ) : props.hideTablePanel === true ? null : (
        <TablePanel {...tablePanelProps} />
      )}
      <div
        {...getTableProps()}
        className={classnames('Table', (hasFreezedColumn || v2Features.tableRowAction) && 'TableColumnSticky')}
        ref={tableRef}
        onScroll={updateToolTipPosition}>
        <div {...getTableBodyProps()} className="Table__rowgroup">
          {totalCounts === 0 && !loading ? (
            <>
              <EmptyState
                heading={(props.emptyObj && props.emptyObj.heading) || emptyHead}
                description={(props.emptyObj && props.emptyObj.description) || emptyDesc}
                displayImage={false}
                type="primary"
                actions={props.emptyObj && props.emptyObj.actions}
                moduleIcon={props.emptyObj && props.emptyObj.moduleIcon}
                forPage={props.emptyObj && props.emptyObj.forPage}
                newEmptyState={props.emptyObj && props.emptyObj.newEmptyState}
              />
            </>
          ) : columnSelector && allVisibleColumn.length === 0 ? (
            <EmptyState heading="No visible columns" description={<AddRemoveDescription />} type="primary" />
          ) : (
            <div
              style={{
                height: tableHeight || '100vh',
                width: 'inherit',
                userSelect: columnResizeIndex == -1 ? 'auto' : 'none'
              }}>
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={finalCount + 1}
                loadMoreItems={fetchMoreItems}
                minimumBatchSize={minBatchSizeToFetch}
                threshold={Math.floor(minBatchSizeToFetch / 2)}>
                {({ onItemsRendered, ref }: any) => (
                  <AutoSizer disableWidth={true}>
                    {({ height }) => {
                      const controlledListProps = getControlledListProps({
                        canFreezeColumn: props.canFreezeColumn,
                        v2Features,
                        height,
                        innerElementType,
                        isTableWidthGreater,
                        isItemLoaded
                      })
                      return (
                        <>
                          {props.canFreezeColumn || v2Features.tableRowAction ? null : TableHeadNodes}
                          {props.customRowAdd &&
                            !(props.canFreezeColumn || v2Features.tableRowAction) &&
                            props.customRowComponent}
                          <FixedSizeList
                            width="100%"
                            itemCount={finalCount}
                            itemSize={itemSize}
                            onItemsRendered={onItemsRendered}
                            ref={(list) => {
                              ref(list)
                              fixedlistRef.current = list
                            }}
                            {...controlledListProps}>
                            {(fixedListProps) => <RenderRow {...RenderRowProps} {...fixedListProps} />}
                          </FixedSizeList>
                        </>
                      )
                    }}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withDeprecatedProp(InfiniteScrollTable, {
  serachValue: 'searchValue',
  onHoverActionList: 'tableRowActionList'
})

// const updateFreezeColumnCount = (count) => {
//   let [freezed = [], unfreezed = []] = listData
//   let visibleColumns = unfreezed.filter((column) => column.isVisible)
//   if (freezed.length === count) {
//     return
//   }
//   if (freezed.length > 0 && count === 0) {
//     unfreezed.unshift(...freezed)
//     freezed.length = 0
//     onColumnsFreeze(freezed)
//     setListData([freezed, unfreezed])
//     handleScrollPosition(freezed)
//     return
//   }
//   if (freezed.length == 0 && count > 0) {
//     let columnsToFreeze = visibleColumns.slice(0, count)
//     freezed.push(...columnsToFreeze)
//     columnsToFreeze.map((column) => {
//       let index = unfreezed.indexOf(column)
//       unfreezed.splice(index, 1)
//     })
//     setDragListData([freezed, unfreezed])
//     onColumnsFreeze(freezed)
//     handleScrollPosition(freezed)
//     return
//   }

//   let columnsToFreeze = []
//   if (freezed.length > 0 && freezed.length < count) {
//     if (count > 1) {
//       columnsToFreeze = visibleColumns.slice(0, count - 1)
//       let index = unfreezed.indexOf(columnsToFreeze[0])
//       freezed.push(...columnsToFreeze)
//       unfreezed.splice(index, count - 1)
//     } else {
//       columnsToFreeze = unfreezed.slice(0, count)
//       freezed.push(...columnsToFreeze)
//       unfreezed.splice(0, 1)
//     }
//   } else {
//     unfreezed.unshift(freezed[freezed.length - 1])
//     freezed.splice(freezed.length - 1, 1)
//   }
//   onColumnsFreeze(freezed)

//   setDragListData([freezed, unfreezed])
//   handleScrollPosition(freezed)
// }
