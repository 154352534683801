import { Registry } from '@react-registry'

export const withInlineReference = editor => {
    const { isInline, isVoid } = editor;

    editor.isVoid = element => {
        let isNotRef = element.type !== 'reference';

        if (isNotRef) return isVoid(element);

        const displayType = element?.attrs?.['display-type'];
        const allowVoid = ['inline', 'block', 'asset'];
        return allowVoid.includes(displayType);
    }

    editor.isInline = (element) => {
        let isNotRef = element.type !== 'reference';

        if (isNotRef) return isInline(element);

        const displayType = element?.attrs?.['display-type'];
        const allowInline = ['inline', 'link'];
        return allowInline.includes(displayType);
    }

    return editor;
}

Registry.register(withInlineReference, {
    id: 'withReference',
    registry: 'plugin'
})