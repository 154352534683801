import { Registry } from '@react-registry'
import { getAttributesObject } from '../../../../../utils'

export const blockReferenceDeserializer = (el) => {
  return {
    type: 'reference',
    attrs: getAttributesObject(el),
    children: [{ text: '' }]
  }
}

Registry.register(blockReferenceDeserializer, {
  id: 'block',
  registry: 'deserializer'
})
