import React from 'react'
import { Registry } from '@react-registry'
import { useSlateStatic } from 'slate-react'
import Tooltip from '../../../../../Tooltip/Tooltip'
import HyperlinkOverlay, { handleLinkMouseDown, ShortcutOpenHyperlinkOverlay } from './hyperlinkOverlay'
import { unwrapLink } from './utils'
//@ts-ignore
import styles from './style.module.css'
import { elementCategories } from '../elementCategories'
import { InfoButton } from './HoverInfoTooltip'
import { ExtendedEditor } from '../../../../../../utils/types'
import Icon from '../../../../../Icon2'
import { LinkIcon } from "../../utils/Icon"
export * from './deserializer'

function UnlinkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8203 8.22485L13.881 7.16419C15.2478 5.79736 15.2478 3.58128 13.881 2.21445V2.21445C12.5141 0.847611 10.2981 0.847611 8.93123 2.21445L7.87057 3.27511" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M8.22461 12.821L7.16395 13.8817C5.79711 15.2485 3.58104 15.2485 2.2142 13.8817V13.8817C0.847367 12.5149 0.847367 10.2988 2.2142 8.93196L3.27486 7.8713" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M14.0479 14L2.04785 2" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M11.7598 4.33569L9.992 6.10346" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M4.33496 11.7605L6.10273 9.99273" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}


export const Hyperlink = (props) => {
  const { url, target } = props.element.attrs || {}
  const editor: ExtendedEditor = useSlateStatic()

  const handleUnlink = () => {
    const selection = editor.selection || editor.savedSelection;
    if (!selection) return;
    unwrapLink(editor)
  }

  const handleOnEdit = (event) => {
    handleLinkMouseDown(event, editor)
  }

  return (
    <span className='tippy-inline'>
      <Tooltip appendTo={document.body} zIndex={909} className="p-0" position='bottom' trigger='click' variantType='light' content={
        <div contentEditable={false} className={styles['hyperlink-info-tooltip']}>
          <a href={url} target='_blank' className={styles['hyperlink-info-tooltip--url']}>{url}</a>
          <div>
            <InfoButton title='unlink' content='Unlink' onClick={handleUnlink}>
              <UnlinkIcon />
            </InfoButton>
            <InfoButton title='edit' content='Edit Link' onClick={handleOnEdit}>
              <Icon icon="Edit" />
            </InfoButton>
          </div>
        </div>
      }
        wrapperElementType='span'
      >
        <span {...props.attributes} className={styles['hyperlink']}>
          <a data-testid="hyperlink_a" href={url} target={target}>
            {props.children}
          </a>
        </span>
      </Tooltip>
    </span>
  )
}

Registry.register(
  {
    Component: Hyperlink,
    IngressComponent: HyperlinkOverlay,
    title: 'URL',
    slashTitle: 'Link',
    slashIconName: <LinkIcon />,
    keywords: ['link'],
    category: elementCategories.LINK_DROPDOWN,
    subtitle: 'Link to other websites',
    isContentStackElement: true,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true,
      inSlashToolbar: true
    },
    shortcut: { key: 'mod+k', callback: ShortcutOpenHyperlinkOverlay },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'a', registry: 'v2.element' }
)

export * from './withHyperlink'
