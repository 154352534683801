import React from 'react';
import { Icon } from '../../../../../../../../../index';
import { EmbedBtn, getEmbedBtnTitle } from '.';
import { handleClick } from '../../utils/referenceButton';

export const EmbedBtnGrp = ({ isError, isDisabled, handleEdit, editor, attributes, handleDelete, handleUnlink, handleCaption, type, isDefault = false }) => (
  <>
    {!isError && !isDisabled && !isDefault &&
      <EmbedBtn title='edit' content={getEmbedBtnTitle(type)} onClick={handleEdit} testId={"embed-asset-view"}>
        <Icon icon={type === 'asset' ? 'Eye' : 'Rename'} />
      </EmbedBtn>
    }
    {type !== 'asset' && !isDisabled && handleClick &&
      <EmbedBtn title='replace' content='Replace' onClick={(e) => handleClick(e, editor, { title: 'Replace Entry', type, prefilled: true, attributes })} testId={"embed-entry-replace"}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="3" width="11" height="2" fill="#647696" />
          <path d="M11 7V1L15 4L11 7Z" fill="#647696" stroke="#647696" strokeLinejoin="round" />
          <rect width="11" height="2" transform="matrix(-1 0 0 1 16 11)" fill="#647696" />
          <path d="M5 15V9L1 12L5 15Z" fill="#647696" stroke="#647696" strokeLinejoin="round" />
        </svg>
      </EmbedBtn>}
    {handleUnlink && !isDisabled &&
      <EmbedBtn title='unlink' content='Unlink' onClick={handleUnlink} testId={"embed-asset-unlink"}>
        <UnlinkIcon />
      </EmbedBtn>}
    {type === 'asset' && !isDisabled && handleCaption &&
      <EmbedBtn title='caption' content='Edit' onClick={handleCaption} testId={"embed-asset-edit"}>
        <Icon icon='Rename' />
      </EmbedBtn>
    }
    {handleDelete &&
      <EmbedBtn title='delete' content='Delete' onClick={handleDelete} testId={"embed-asset-delete"}>
        <Icon icon='Delete'></Icon>
      </EmbedBtn>}
  </>
)


function UnlinkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8203 8.22485L13.881 7.16419C15.2478 5.79736 15.2478 3.58128 13.881 2.21445V2.21445C12.5141 0.847611 10.2981 0.847611 8.93123 2.21445L7.87057 3.27511" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M8.22461 12.821L7.16395 13.8817C5.79711 15.2485 3.58104 15.2485 2.2142 13.8817V13.8817C0.847367 12.5149 0.847367 10.2988 2.2142 8.93196L3.27486 7.8713" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M14.0479 14L2.04785 2" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M11.7598 4.33569L9.992 6.10346" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
      <path d="M4.33496 11.7605L6.10273 9.99273" stroke="#647696" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}