import React from 'react'
import MarkdownEditor from './src'


const Markdown = (props: any) => {
  const { value = '', onChange = null, ...otherProps } = props;
  return (
    <MarkdownEditor
      value={value}
      onChange={(val) => {
        if (onChange) {
          onChange(val)
        }
      }}
      {...otherProps}
    />
  )
}

export default Markdown
