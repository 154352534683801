import React from 'react'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import OutlineTag from '../Tag/OutlineTag'
import DateLabel from '../DateLabel/DateLabel'
import './PublishDetails.css'

type PublishStatusProps = {
  label?: string
  style?: React.CSSProperties
  className?: string
  details?: React.ReactNode | string | undefined
  color?: string
  environment?: string
  version?: string
  locale?: string
  userName?: string
  scheduledAt?: string
  dateTime?: any
  publishAction?: string
  versionOnClick?: Function
  url?: React.ReactNode | string,
  testId?: string
}
const handleVersionClick = (versionOnClick) => {
  if (versionOnClick) {
    versionOnClick();
  }
}

const GetScheduleInfo = ({ environment, version, locale, url, userName, scheduledAt, publishAction, versionOnClick }: PublishStatusProps) => {
  return (<div>
    <div className="flex-v-center mb-3">
      <div className="PublishDetails__env">{environment}</div>
      {publishAction == "publish" ? <OutlineTag content='Publish Scheduled' /> : null}
      {publishAction == "unpublish" ? <OutlineTag content='Unpublish Scheduled' /> : null}
      {publishAction == "scheduled" ? <OutlineTag content='Deploy Scheduled' /> : null}
    </div>
    {version ?
      <div onClick={() => handleVersionClick(versionOnClick)} className={`PublishDetails__version ${versionOnClick ? 'PublishDetails__version__link' : ''}`}>
        <span> {version}</span>
      </div> : null
    }
    <div className="flex-v-center mb-3">
      {publishAction === "publish" || publishAction === "unpublish" || publishAction === "scheduled" ?
        <Tooltip content="Scheduled At" position="left" showArrow={false}>
          <Icon icon="ClockDark" /></Tooltip>
        :
        <Tooltip content="Published At" position="left" showArrow={false}>
          <Icon icon="PublishDark" /></Tooltip>
      }
      <DateLabel date={scheduledAt} />
    </div>
    {locale ? (
      <div className="flex-v-center mb-3">
        <Tooltip content="Locale" position="left" showArrow={false}>
          <Icon icon="LanguageDark" size="tiny" /></Tooltip>
        <span className="PublishDetails__locale">{locale}</span>
      </div>)
      : (null)
    }
    <div className="flex-v-center mb-3">
      <Tooltip content="User" position="left" showArrow={false}>
        <Icon icon="ProfileDark" /></Tooltip>
      <span className="PublishDetails__user">{userName}</span>
    </div>
    {
      url &&
      <div className="flex-v-center">
        <Tooltip content="Url" position="left" showArrow={false}>
          <Icon icon="CopyUrlTiny" />
        </Tooltip>
        <span className="PublishDetails__url">{url || '-'}</span>
      </div>
    }
  </div>);
}
const GetVersionDetails = ({ version, versionOnClick }) => {
  if (version) {
    return (<div onClick={() => handleVersionClick(versionOnClick)} className="PublishDetails__version">
      <span> {version}</span>
    </div>)
  }
  return null
}

const GeUnlocalizePublishDetails = (locale) => {
  if (locale) {
    return (<div className="PublishDetails__unlocalize">The entry was published before localizing</div>)
  }
  return null;
}

const PublishDetails: React.FunctionComponent<PublishStatusProps> = props => {
  const { color, environment, version, locale, userName, scheduledAt, dateTime, publishAction, versionOnClick, url, testId } = props;


  return (
    <div className="PublishDetails" data-test-id={testId}>
      <div>
        <Icon icon='EnvironmentColor' fill={color}></Icon>
        {scheduledAt ? (
          <GetScheduleInfo {...props} />
        ) : (
          <div>
            <div>
              <div className="PublishDetails__env">{environment}</div>
            </div>
            <GetVersionDetails version={version} versionOnClick={versionOnClick} />
            <div className="flex-v-center mb-3">
              <Tooltip content="Published At" position="left" showArrow={false}>
                <Icon icon="PublishDark" /></Tooltip>
              <DateLabel date={dateTime} dateVal={dateTime}></DateLabel>
            </div>
            <div className="flex-v-center mb-3">
              <Tooltip content="User" position="left" showArrow={false}>
                <Icon icon="ProfileDark" />
              </Tooltip>
              <span className="PublishDetails__user">{userName}</span>
            </div>
            {
              url &&
              <div className="flex-v-center">
                <Tooltip content="Url" position="left" showArrow={false}>
                  <Icon icon="CopyUrlTiny" />
                </Tooltip>
                <span className="PublishDetails__url">{url || '-'}</span>
              </div>
            }
            <GeUnlocalizePublishDetails locale={locale} />

          </div>)
        }
      </div>
    </div>
  )
}
PublishDetails.defaultProps = {
  label: '',
  version: '',
  locale: '',
  testId: 'cs-publish-details'
} as Partial<PublishStatusProps>;

export default PublishDetails