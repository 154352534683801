import React from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
} from '../../../../../../index'
import { checkProps } from '../../../../../RichTextEditor/SuperChargedRte/elements/Element/contentstack/utils'
import { InvalidPropsMsg } from '../../../../../RichTextEditor/SuperChargedRte/elements/Element/contentstack/utils/InvalidPropsMessage/InvalidPropsMsg'
import SelectImage from '../SelectImage'
import './style.css'
import { Editor } from '../../../lib/node-pagedown'
import commands from '../../../utils/commands'

export declare type ModalComponentProps = {
  editor: typeof Editor,
  closeModal: Function
  state: Record<string, any>
}

declare type image = {

}

export default class extends React.Component<ModalComponentProps> {

  state = {
    image: {
      url: '',
      filename: ''
    },
  }

  setImage = (asset) => {
    this.setState({
      image: asset,
    })
  }

  handleSubmit = () => {
    const url = this.state.image?.url
    const editor = this.props.editor;

    const state = this.props.state
    if (!state || (!state?.text && state?.text !== "") ) {
      this.handleReset()
      return;
    }
    const chunks = state.getChunks();
    const fixupInputArea = function () {

      editor.panels.input.focus();

      if (chunks) {
        state.setChunks(chunks);
      }

      state.restore();
      editor?.onUpdate && state?.text && editor.onUpdate(state?.text) // to update the markdown data for save
      editor.previewManager.refresh();
    };
    const noCleanup = commands['doLinkOrImage'](chunks, fixupInputArea, editor, true, url)
    if (!noCleanup) {
      fixupInputArea();
    }
    this.handleReset()
  }

  handleReset = () => {
    this.props.closeModal()
  }

  render() {

    const isImageSelected = !this.state.image.url
    const editor = this.props.editor
    //@ts-ignore
    const isPropsValid = checkProps({ editor });

    return (
      <div id='scrte-image-modal'>
        <ModalHeader title="Insert Image" closeModal={this.props.closeModal} />

        <ModalBody className="modalBodyCustomClass">
          {isPropsValid ? (
            <SelectImage editor={this.props.editor} setImage={this.setImage} />
          ) : (
            <InvalidPropsMsg asset="Images" />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleReset} buttonType="tertiary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} disabled={isImageSelected}>
              Add Image
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}
