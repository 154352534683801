//@ts-nocheck
import React from 'react'
import { cbModal } from '../../../../../../../index'
import { useSlateStatic } from 'slate-react'
import ImageModal from './Modal/ModalComponent'
import UploadModal from './Modal/UploadModal'
import { isEmbedAssetLinkActive } from '../utils'

const openChooseFromImageModal = (e, editor, type) => {
  e.preventDefault()
  editor.setFullScreen();
  cbModal({
    component: (props) => <ImageModal editor={editor} type={type} {...props} />,
    modalProps: {
      size: 'max',
      customClass: 'reference__modal scrte-reference__modal',
      shouldReturnFocusAfterClose: false
    },
  })
}

export const ImageOverlay = (props) => {
  const editor = useSlateStatic()

  const handleMouseDown = (e, { editor }) => {
    openChooseFromImageModal(e, editor, props.type)
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: handleMouseDown,
        active: props.type === 'link' && isEmbedAssetLinkActive(editor),
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}

export const shortcutOpenChooseFromImageModal = (event, editor, format, registryObj, type?) => {
  openChooseFromImageModal(event, editor, type)
}

export const UploadOverlay = (props) => {
  const editor = useSlateStatic();

  const handleMouseDown = (e, { editor }) => {
    e.preventDefault()
    editor.setFullScreen();
    cbModal({
      component: (props) => <UploadModal editor={editor} {...props} type='asset' />,
      modalProps: {
        size: 'max',
        shouldReturnFocusAfterClose: false,
        customClass: 'reference__modal scrte-reference__modal',
      },
    })
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: handleMouseDown,
        additionalParams: { editor },
      })}
    </React.Fragment>
  )
}
