const re = window.RegExp

function properlyEncoded(linkdef) {
  return linkdef.replace(/^\s*(.*?)(?:\s+"(.+)")?\s*$/, function (wholematch, link, title) {

    var inQueryString = false;

    // Having `[^\w\d-./]` in there is just a shortcut that lets us skip
    // the most common characters in URLs. Replacing that it with `.` would not change
    // the result, because encodeURI returns those characters unchanged, but it
    // would mean lots of unnecessary replacement calls. Having `[` and `]` in that
    // section as well means we do *not* enocde square brackets. These characters are
    // a strange beast in URLs, but if anything, this causes URLs to be more readable,
    // and we leave it to the browser to make sure that these links are handled without
    // problems.
    link = link.replace(/%(?:[\da-fA-F]{2})|\?|\+|[^\w\d-./[\]]/g, function (match) {
      // Valid percent encoding. Could just return it as is, but we follow RFC3986
      // Section 2.1 which says "For consistency, URI producers and normalizers
      // should use uppercase hexadecimal digits for all percent-encodings."
      // Note that we also handle (illegal) stand-alone percent characters by
      // replacing them with "%25"
      if (match.length === 3 && match.charAt(0) == "%") {
        return match.toUpperCase();
      }
      switch (match) {
        case "?":
          inQueryString = true;
          return "?";
          break;

        // In the query string, a plus and a space are identical -- normalize.
        // Not strictly necessary, but identical behavior to the previous version
        // of this function.
        case "+":
          if (inQueryString)
            return "%20";
          break;
      }
      return encodeURI(match);
    })

    if (title) {
      title = title.trim ? title.trim() : title.replace(/^\s*/, "").replace(/\s*$/, "");
      title = title.replace(/"/g, "quot;").replace(/\(/g, "&#40;").replace(/\)/g, "&#41;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }
    return title ? link + ' "' + title + '"' : link;
  });
}

const SETTINGS = { lineLength: 72 }
const commands: any = {

  // The markdown symbols - 4 spaces = code, > = blockquote, etc.
  prefixes: "(?:\\s{4,}|\\s*>|\\s*-\\s+|\\s*\\d+\\.|=|\\+|-|_|\\*|#|\\s*\\[[^\n]]+\\]:)",

  // Remove markdown symbols from the chunk selection.
  unwrap: function (chunk) {
    var txt = new re("([^\\n])\\n(?!(\\n|" + this.prefixes + "))", "g");
    chunk.selection = chunk.selection.replace(txt, "$1 $2");
  },

  wrap: function (chunk, len) {
    this.unwrap(chunk);
    var regex = new re("(.{1," + len + "})( +|$\\n?)", "gm"),
      that = this;

    chunk.selection = chunk.selection.replace(regex, function (line, marked) {
      if (new re("^" + that.prefixes, "").test(line)) {
        return line;
      }
      return marked + "\n";
    });

    chunk.selection = chunk.selection.replace(/\s+$/, "");
  },

  doBold: function (chunk, postProcessing, editor) {
    return this.doBorI(chunk, postProcessing, 2, editor.commandManager.getString("boldexample"));
  },

  doItalic: function (chunk, postProcessing, editor) {
    return this.doBorI(chunk, postProcessing, 1, editor.commandManager.getString("italicexample"));
  },

  // chunk: The selected region that will be enclosed with */**
  // nStars: 1 for italics, 2 for bold
  // insertText: If you just click the button without highlighting text, this gets inserted
  // doBoldOrItalic
  doBorI: function (chunk, postProcessing, nStars, insertText) {

    // Get rid of whitespace and fixup newlines.
    chunk.trimWhitespace();
    chunk.selection = chunk.selection.replace(/\n{2,}/g, "\n");

    // Look for stars before and after.  Is the chunk already marked up?
    // note that these regex matches cannot fail
    var starsBefore = /(\**$)/.exec(chunk.before)[0];
    var starsAfter = /(^\**)/.exec(chunk.after)[0];

    var prevStars = Math.min(starsBefore.length, starsAfter.length);

    // Remove stars if we have to since the button acts as a toggle.
    if ((prevStars >= nStars) && (prevStars != 2 || nStars != 1)) {
      chunk.before = chunk.before.replace(re("[*]{" + nStars + "}$", ""), "");
      chunk.after = chunk.after.replace(re("^[*]{" + nStars + "}", ""), "");
    }
    else if (!chunk.selection && starsAfter) {
      // It's not really clear why this code is necessary.  It just moves
      // some arbitrary stuff around.
      chunk.after = chunk.after.replace(/^([*_]*)/, "");
      chunk.before = chunk.before.replace(/(\s?)$/, "");
      var whitespace = re.$1;
      chunk.before = chunk.before + starsAfter + whitespace;
    }
    else {

      // In most cases, if you don't have any selected text and click the button
      // you'll get a selected, marked up region with the default text inserted.
      if (!chunk.selection && !starsAfter) {
        chunk.selection = insertText;
      }

      // Add the true markup.
      var markup = nStars <= 1 ? "*" : "**"; // shouldn't the test be = ?
      chunk.before = chunk.before + markup;
      chunk.after = markup + chunk.after;
    }

    return;
  },

  stripLinkDefs: function (text, defsToAdd) {

    text = text.replace(/^[ ]{0,3}\[(\d+)\]:[ \t]*\n?[ \t]*<?(\S+?)>?[ \t]*\n?[ \t]*(?:(\n*)["(](.+?)[")][ \t]*)?(?:\n+|$)/gm,
      function (totalMatch, id, link, newlines, title) {
        defsToAdd[id] = totalMatch.replace(/\s*$/, "");
        if (newlines) {
          // Strip the title and return that separately.
          defsToAdd[id] = totalMatch.replace(/["(](.+?)[")]$/, "");
          return newlines + title;
        }
        return "";
      });

    return text;
  },

  addLinkDef: function (chunk, linkDef, editor) {

    var refNumber = 0; // The current reference number
    var defsToAdd = {}; //
    // Start with a clean slate by removing all previous link definitions.
    chunk.before = this.stripLinkDefs(chunk.before, defsToAdd);
    chunk.selection = this.stripLinkDefs(chunk.selection, defsToAdd);
    chunk.after = this.stripLinkDefs(chunk.after, defsToAdd);

    var defs = "";
    var regex = /(\[)((?:\[[^\]]*\]|[^\[\]])*)(\][ ]?(?:\n[ ]*)?\[)(\d+)(\])/g;

    // The above regex, used to update [foo][13] references after renumbering,
    // is much too liberal; it can catch things that are not actually parsed
    // as references (notably: code). It's impossible to know which matches are
    // real references without performing a markdown conversion, so that's what
    // we do. All matches are replaced with a unique reference number, which is
    // given a unique link. The uniquifier in both cases is the character offset
    // of the match inside the source string. The modified version is then sent
    // through the Markdown renderer. Because link reference are stripped during
    // rendering, the unique link is present in the rendered version if and only
    // if the match at its offset was in fact rendered as a link or image.
    var complete = chunk.before + chunk.selection + chunk.after;
    var rendered = editor.getConverter().makeHtml(complete);
    var testlink = "http://this-is-a-test-link.biz/";

    // If our fake link appears in the rendered version *before* we have added it,
    // this probably means you're a Meta Stack Exchange user who is deliberately
    // trying to break this feature. You can still break this workaround if you
    // attach a plugin to the converter that sometimes (!) inserts this link. In
    // that case, consider yourwindow.self unsupported.
    while (rendered.indexOf(testlink) != -1)
      testlink += "nicetry/";

    var fakedefs = "\n\n";

    // the regex is tested on the (up to) three chunks separately, and on substrings,
    // so in order to have the correct offsets to check against okayToModify(), we
    // have to keep track of how many characters are in the original source before
    // the substring that we're looking at. Note that doLinkOrImage aligns the selection
    // on potential brackets, so there should be no major breakage from the chunk
    // separation.
    var skippedChars = 0;

    var uniquified = complete.replace(regex, function uniquify(wholeMatch, before, inner, afterInner, id, end, offset) {
      skippedChars += offset;
      fakedefs += " [" + skippedChars + "]: " + testlink + skippedChars + "/unicorn\n";
      skippedChars += before.length;
      inner = inner.replace(regex, uniquify);
      skippedChars -= before.length;
      var result = before + inner + afterInner + skippedChars + end;
      skippedChars -= offset;
      return result;
    });

    rendered = editor.getConverter().makeHtml(uniquified + fakedefs);

    var okayToModify = function (offset) {
      return rendered.indexOf(testlink + offset + "/unicorn") !== -1;
    }

    var addDefNumber = function (def) {
      refNumber++;
      def = def.replace(/^[ ]{0,3}\[(\d+)\]:/, "  [" + refNumber + "]:");
      defs += "\n" + def;
    };

    // note that
    // a) the recursive call to getLink cannot go infinite, because by definition
    //    of regex, inner is always a proper substring of wholeMatch, and
    // b) more than one level of nesting is neither supported by the regex
    //    nor making a lot of sense (the only use case for nesting is a linked image)
    var getLink = function (wholeMatch, before, inner, afterInner, id, end, offset) {
      if (!okayToModify(skippedChars + offset))
        return wholeMatch;
      skippedChars += offset + before.length;
      inner = inner.replace(regex, getLink);
      skippedChars -= offset + before.length;
      if (defsToAdd[id]) {
        addDefNumber(defsToAdd[id]);
        return before + inner + afterInner + refNumber + end;
      }
      return wholeMatch;
    };

    var len = chunk.before.length;
    chunk.before = chunk.before.replace(regex, getLink);
    skippedChars += len;

    len = chunk.selection.length;
    if (linkDef) {
      addDefNumber(linkDef);
    }
    else {
      chunk.selection = chunk.selection.replace(regex, getLink);
    }
    skippedChars += len;

    var refOut = refNumber;

    chunk.after = chunk.after.replace(regex, getLink);

    if (chunk.after) {
      chunk.after = chunk.after.replace(/\n*$/, "");
    }
    if (!chunk.after) {
      chunk.selection = chunk.selection.replace(/\n*$/, "");
    }

    chunk.after += "\n\n" + defs;

    return refOut;
  },

  doLinkOrImage: function (chunk, postProcessing, editor, isImage, url) {

    chunk.trimWhitespace();
    chunk.findTags(/\s*!?\[/, /\][ ]?(?:\n[ ]*)?(\[.*?\])?/);
    var background;

    if (chunk.endTag.length > 1 && chunk.startTag.length > 0) {

      chunk.startTag = chunk.startTag.replace(/!?\[/, "");
      chunk.endTag = "";
      this.addLinkDef(chunk, null, editor);

    }
    else {

      // We're moving start and end tag back into the selection, since (as we're in the else block) we're not
      // *removing* a link, but *adding* one, so whatever findTags() found is now back to being part of the
      // link text. linkEnteredCallback takes care of escaping any brackets.
      chunk.selection = chunk.startTag + chunk.selection + chunk.endTag;
      chunk.startTag = chunk.endTag = "";

      if (/\n\n/.test(chunk.selection)) {
        this.addLinkDef(chunk, null, editor);
        return;
      }
      var that = this;
      // The function to be executed when you enter a link and press OK or Cancel.
      // Marks up the link and adds the ref.
      var linkEnteredCallback = function (link) {

        if (link !== null) {
          // (                          $1
          //     [^\\]                  anything that's not a backslash
          //     (?:\\\\)*              an even number (this includes zero) of backslashes
          // )
          // (?=                        followed by
          //     [[\]]                  an opening or closing bracket
          // )
          //
          // In other words, a non-escaped bracket. These have to be escaped now to make sure they
          // don't count as the end of the link or similar.
          // Note that the actual bracket has to be a lookahead, because (in case of to subsequent brackets),
          // the bracket in one match may be the "not a backslash" character in the next match, so it
          // should not be consumed by the first match.
          // The "prepend a space and finally remove it" steps makes sure there is a "not a backslash" at the
          // start of the string, so this also works if the selection begins with a bracket. We cannot solve
          // this by anchoring with ^, because in the case that the selection starts with two brackets, this
          // would mean a zero-width match at the start. Since zero-width matches advance the string position,
          // the first bracket could then not act as the "not a backslash" for the second.
          chunk.selection = (" " + chunk.selection).replace(/([^\\](?:\\\\)*)(?=[[\]])/g, "$1\\").substr(1);

          var linkDef = " [999]: " + properlyEncoded(link);

          var num = that.addLinkDef(chunk, linkDef, editor);
          chunk.startTag = isImage ? "![" : "[";
          chunk.endTag = "][" + num + "]";

          if (!chunk.selection) {
            if (isImage) {
              chunk.selection = editor.commandManager.getString("imagedescription");
            }
            else {
              chunk.selection = editor.commandManager.getString("linkdescription");
            }
          }
        }

        postProcessing();
      };

      linkEnteredCallback(url)
      return true;
    }
  },

  // When making a list, hitting shift-enter will put your cursor on the next line
  // at the current indent level.
  doAutoindent: function (chunk, postProcessing, editor) {

    var commandMgr = this,
      fakeSelection = false;

    chunk.before = chunk.before.replace(/(\n|^)[ ]{0,3}([*+-]|\d+[.])[ \t]*\n$/, "\n\n");
    chunk.before = chunk.before.replace(/(\n|^)[ ]{0,3}>[ \t]*\n$/, "\n\n");
    chunk.before = chunk.before.replace(/(\n|^)[ \t]+\n$/, "\n\n");

    // There's no selection, end the cursor wasn't at the end of the line:
    // The user wants to split the current list item / code line / blockquote line
    // (for the latter it doesn't really matter) in two. Temporarily select the
    // (rest of the) line to achieve this.
    if (!chunk.selection && !/^[ \t]*(?:\n|$)/.test(chunk.after)) {
      chunk.after = chunk.after.replace(/^[^\n]*/, function (wholeMatch) {
        chunk.selection = wholeMatch;
        return "";
      });
      fakeSelection = true;
    }

    if (/(\n|^)[ ]{0,3}([*+-]|\d+[.])[ \t]+.*\n$/.test(chunk.before)) {
      if (commandMgr.doList) {
        commandMgr.doList(chunk, null, editor);
      }
    }
    if (/(\n|^)[ ]{0,3}>[ \t]+.*\n$/.test(chunk.before)) {
      if (commandMgr.doBlockquote) {
        commandMgr.doBlockquote(chunk, null, editor);
      }
    }
    if (/(\n|^)(\t|[ ]{4,}).*\n$/.test(chunk.before)) {
      if (commandMgr.doCode) {
        commandMgr.doCode(chunk, null, editor);
      }
    }

    if (fakeSelection) {
      chunk.after = chunk.selection + chunk.after;
      chunk.selection = "";
    }
  },

  doBlockquote: function (chunk, postProcessing, editor) {

    chunk.selection = chunk.selection.replace(/^(\n*)([^\r]+?)(\n*)$/,
      function (totalMatch, newlinesBefore, text, newlinesAfter) {
        chunk.before += newlinesBefore;
        chunk.after = newlinesAfter + chunk.after;
        return text;
      });

    chunk.before = chunk.before.replace(/(>[ \t]*)$/,
      function (totalMatch, blankLine) {
        chunk.selection = blankLine + chunk.selection;
        return "";
      });

    chunk.selection = chunk.selection.replace(/^(\s|>)+$/, "");
    chunk.selection = chunk.selection || editor.commandManager.getString("quoteexample");

    // The original code uses a regular expression to find out how much of the
    // text *directly before* the selection already was a blockquote:

    /*
    if (chunk.before) {
    chunk.before = chunk.before.replace(/\n?$/, "\n");
    }
    chunk.before = chunk.before.replace(/(((\n|^)(\n[ \t]*)*>(.+\n)*.*)+(\n[ \t]*)*$)/,
    function (totalMatch) {
    chunk.startTag = totalMatch;
    return "";
    });
    */

    // This comes down to:
    // Go backwards as many lines a possible, such that each line
    //  a) starts with ">", or
    //  b) is almost empty, except for whitespace, or
    //  c) is preceeded by an unbroken chain of non-empty lines
    //     leading up to a line that starts with ">" and at least one more character
    // and in addition
    //  d) at least one line fulfills a)
    //
    // Since this is essentially a backwards-moving regex, it's susceptible to
    // catstrophic backtracking and can cause the browser to hang;
    // see e.g. http://meta.stackexchange.com/questions/9807.
    //
    // Hence we replaced this by a simple state machine that just goes through the
    // lines and checks for a), b), and c).

    var match = "",
      leftOver = "",
      line;
    if (chunk.before) {
      var lines = chunk.before.replace(/\n$/, "").split("\n");
      var inChain = false;
      for (var i = 0; i < lines.length; i++) {
        var good = false;
        line = lines[i];
        inChain = inChain && line.length > 0; // c) any non-empty line continues the chain
        if (/^>/.test(line)) {                // a)
          good = true;
          if (!inChain && line.length > 1)  // c) any line that starts with ">" and has at least one more character starts the chain
            inChain = true;
        } else if (/^[ \t]*$/.test(line)) {   // b)
          good = true;
        } else {
          good = inChain;                   // c) the line is not empty and does not start with ">", so it matches if and only if we're in the chain
        }
        if (good) {
          match += line + "\n";
        } else {
          leftOver += match + line;
          match = "\n";
        }
      }
      if (!/(^|\n)>/.test(match)) {             // d)
        leftOver += match;
        match = "";
      }
    }

    chunk.startTag = match;
    chunk.before = leftOver;

    // end of change

    if (chunk.after) {
      chunk.after = chunk.after.replace(/^\n?/, "\n");
    }

    chunk.after = chunk.after.replace(/^(((\n|^)(\n[ \t]*)*>(.+\n)*.*)+(\n[ \t]*)*)/,
      function (totalMatch) {
        chunk.endTag = totalMatch;
        return "";
      }
    );

    var replaceBlanksInTags = function (useBracket) {

      var replacement = useBracket ? "> " : "";

      if (chunk.startTag) {
        chunk.startTag = chunk.startTag.replace(/\n((>|\s)*)\n$/,
          function (totalMatch, markdown) {
            return "\n" + markdown.replace(/^[ ]{0,3}>?[ \t]*$/gm, replacement) + "\n";
          });
      }
      if (chunk.endTag) {
        chunk.endTag = chunk.endTag.replace(/^\n((>|\s)*)\n/,
          function (totalMatch, markdown) {
            return "\n" + markdown.replace(/^[ ]{0,3}>?[ \t]*$/gm, replacement) + "\n";
          });
      }
    };

    if (/^(?![ ]{0,3}>)/m.test(chunk.selection)) {
      this.wrap(chunk, SETTINGS.lineLength - 2);
      chunk.selection = chunk.selection.replace(/^/gm, "> ");
      replaceBlanksInTags(true);
      chunk.skipLines();
    } else {
      chunk.selection = chunk.selection.replace(/^[ ]{0,3}> ?/gm, "");
      this.unwrap(chunk);
      replaceBlanksInTags(false);

      if (!/^(\n|^)[ ]{0,3}>/.test(chunk.selection) && chunk.startTag) {
        chunk.startTag = chunk.startTag.replace(/\n{0,2}$/, "\n\n");
      }

      if (!/(\n|^)[ ]{0,3}>.*$/.test(chunk.selection) && chunk.endTag) {
        chunk.endTag = chunk.endTag.replace(/^\n{0,2}/, "\n\n");
      }
    }

    chunk.selection = editor.hooks.postBlockquoteCreation(chunk.selection);

    if (!/\n/.test(chunk.selection)) {
      chunk.selection = chunk.selection.replace(/^(> *)/,
        function (wholeMatch, blanks) {
          chunk.startTag += blanks;
          return "";
        });
    }
  },

  doCode: function (chunk, postProcessing, editor) {

    var hasTextBefore = /\S[ ]*$/.test(chunk.before);
    var hasTextAfter = /^[ ]*\S/.test(chunk.after);

    // Use 'four space' markdown if the selection is on its own
    // line or is multiline.
    if ((!hasTextAfter && !hasTextBefore) || /\n/.test(chunk.selection)) {

      chunk.before = chunk.before.replace(/[ ]{4}$/,
        function (totalMatch) {
          chunk.selection = totalMatch + chunk.selection;
          return "";
        });

      var nLinesBack = 1;
      var nLinesForward = 1;

      if (/(\n|^)(\t|[ ]{4,}).*\n$/.test(chunk.before)) {
        nLinesBack = 0;
      }
      if (/^\n(\t|[ ]{4,})/.test(chunk.after)) {
        nLinesForward = 0;
      }

      chunk.skipLines(nLinesBack, nLinesForward);

      if (!chunk.selection) {
        chunk.startTag = "    ";
        chunk.selection = editor.commandManager.getString("codeexample");
      }
      else {
        if (/^[ ]{0,3}\S/m.test(chunk.selection)) {
          if (/\n/.test(chunk.selection))
            chunk.selection = chunk.selection.replace(/^/gm, "    ");
          else // if it's not multiline, do not select the four added spaces; this is more consistent with the doList behavior
            chunk.before += "    ";
        }
        else {
          chunk.selection = chunk.selection.replace(/^(?:[ ]{4}|[ ]{0,3}\t)/gm, "");
        }
      }
    }
    else {
      // Use backticks (`) to delimit the code block.

      chunk.trimWhitespace();
      chunk.findTags(/`/, /`/);

      if (!chunk.startTag && !chunk.endTag) {
        chunk.startTag = chunk.endTag = "`";
        if (!chunk.selection) {
          chunk.selection = editor.commandManager.getString("codeexample");
        }
      }
      else if (chunk.endTag && !chunk.startTag) {
        chunk.before += chunk.endTag;
        chunk.endTag = "";
      }
      else {
        chunk.startTag = chunk.endTag = "";
      }
    }
  },

  doList: function (chunk, postProcessing, editor, isNumberedList) {

    // These are identical except at the very beginning and end.
    // Should probably use the regex extension function to make this clearer.
    var previousItemsRegex = /(\n|^)(([ ]{0,3}([*+-]|\d+[.])[ \t]+.*)(\n.+|\n{2,}([*+-].*|\d+[.])[ \t]+.*|\n{2,}[ \t]+\S.*)*)\n*$/;
    var nextItemsRegex = /^\n*(([ ]{0,3}([*+-]|\d+[.])[ \t]+.*)(\n.+|\n{2,}([*+-].*|\d+[.])[ \t]+.*|\n{2,}[ \t]+\S.*)*)\n*/;

    // The default bullet is a dash but others are possible.
    // This has nothing to do with the particular HTML bullet,
    // it's just a markdown bullet.
    var bullet = "-";

    // The number in a numbered list.
    var num = 1;

    // Get the item prefix - e.g. " 1. " for a numbered list, " - " for a bulleted list.
    var getItemPrefix = function () {
      var prefix;
      if (isNumberedList) {
        prefix = " " + num + ". ";
        num++;
      }
      else {
        prefix = " " + bullet + " ";
      }
      return prefix;
    };

    // Fixes the prefixes of the other list items.
    var getPrefixedItem = function (itemText) {

      // The numbering flag is unset when called by autoindent.
      if (isNumberedList === undefined) {
        isNumberedList = /^\s*\d/.test(itemText);
      }

      // Renumber/bullet the list element.
      itemText = itemText.replace(/^[ ]{0,3}([*+-]|\d+[.])\s/gm,
        function (_) {
          return getItemPrefix();
        });

      return itemText;
    };

    chunk.findTags(/(\n|^)*[ ]{0,3}([*+-]|\d+[.])\s+/, null);

    if (chunk.before && !/\n$/.test(chunk.before) && !/^\n/.test(chunk.startTag)) {
      chunk.before += chunk.startTag;
      chunk.startTag = "";
    }

    if (chunk.startTag) {

      var hasDigits = /\d+[.]/.test(chunk.startTag);
      chunk.startTag = "";
      chunk.selection = chunk.selection.replace(/\n[ ]{4}/g, "\n");
      this.unwrap(chunk);
      chunk.skipLines();

      if (hasDigits) {
        // Have to renumber the bullet points if this is a numbered list.
        chunk.after = chunk.after.replace(nextItemsRegex, getPrefixedItem);
      }
      if (isNumberedList == hasDigits) {
        return;
      }
    }

    var nLinesUp = 1;

    chunk.before = chunk.before.replace(previousItemsRegex,
      function (itemText) {
        if (/^\s*([*+-])/.test(itemText)) {
          bullet = re.$1;
        }
        nLinesUp = /[^\n]\n\n[^\n]/.test(itemText) ? 1 : 0;
        return getPrefixedItem(itemText);
      });

    if (!chunk.selection) {
      chunk.selection = editor.commandManager.getString("litem");
    }

    var prefix = getItemPrefix();

    var nLinesDown = 1;

    chunk.after = chunk.after.replace(nextItemsRegex,
      function (itemText) {
        nLinesDown = /[^\n]\n\n[^\n]/.test(itemText) ? 1 : 0;
        return getPrefixedItem(itemText);
      });

    chunk.trimWhitespace(true);
    chunk.skipLines(nLinesUp, nLinesDown, true);
    chunk.startTag = prefix;
    var spaces = prefix.replace(/./g, " ");
    this.wrap(chunk, SETTINGS.lineLength - spaces.length);
    chunk.selection = chunk.selection.replace(/\n/g, "\n" + spaces);

  },

  doHeading: function (chunk, postProcessing, editor) {

    // Remove leading/trailing whitespace and reduce internal spaces to single spaces.
    chunk.selection = chunk.selection.replace(/\s+/g, " ");
    chunk.selection = chunk.selection.replace(/(^\s+|\s+$)/g, "");

    // If we clicked the button with no selected text, we just
    // make a level 2 hash header around some default text.
    if (!chunk.selection) {
      chunk.startTag = "## ";
      chunk.selection = editor.commandManager.getString("headingexample");
      chunk.endTag = " ##";
      return;
    }

    var headerLevel = 0;     // The existing header level of the selected text.

    // Remove any existing hash heading markdown and save the header level.
    chunk.findTags(/#+[ ]*/, /[ ]*#+/);
    if (/#+/.test(chunk.startTag)) {
      headerLevel = re.lastMatch.length;
    }
    chunk.startTag = chunk.endTag = "";

    // Try to get the current header level by looking for - and = in the line
    // below the selection.
    chunk.findTags(null, /\s?(-+|=+)/);
    if (/=+/.test(chunk.endTag)) {
      headerLevel = 1;
    }
    if (/-+/.test(chunk.endTag)) {
      headerLevel = 2;
    }

    // Skip to the next line so we can create the header markdown.
    chunk.startTag = chunk.endTag = "";
    chunk.skipLines(1, 1);

    // We make a level 2 header if there is no current header.
    // If there is a header level, we substract one from the header level.
    // If it's already a level 1 header, it's removed.
    var headerLevelToCreate = headerLevel == 0 ? 2 : headerLevel - 1;

    if (headerLevelToCreate > 0) {

      // The button only creates level 1 and 2 underline headers.
      // Why not have it iterate over hash header levels?  Wouldn't that be easier and cleaner?
      var headerChar = headerLevelToCreate >= 2 ? "-" : "=";
      var len = chunk.selection.length;
      if (len > SETTINGS.lineLength) {
        len = SETTINGS.lineLength;
      }
      chunk.endTag = "\n";
      while (len--) {
        chunk.endTag += headerChar;
      }
    }
  },

  doHorizontalRule: function (chunk, postProcessing) {
    chunk.startTag = "----------\n";
    chunk.selection = "";
    chunk.skipLines(2, 1, true);
  }
}
export default commands;