import { Registry } from '@react-registry'

export const withScript = (editor) => {
    const { isVoid } = editor
    editor.isVoid = (element) => {
        return element.type === 'script' ? true : isVoid(element)
    }

    return editor
}

Registry.register(withScript, { id: 'withScript', registry: 'plugin' })