import React from 'react';
import InfoContainter from '../InfoContainter/InfoContainer';
import { className } from '../PersonalizationQueryBuilder/utils';
import cn from 'classnames'
import './LinkedList.css'

export type InfoRow = {
  title: string | React.ReactNode,
  content: string | React.ReactNode
}

export type LinkedListProps = {
  count?: string | React.ReactNode
  className?: string
  listData: InfoRow[]
  heading?: string
  seeMore?: Function
  contentFontColor?: "base" | "secondary" | "tertiary" | "black"
  contentFontSize?: "tiny" | "small" | "medium" | "large"
  testId?: string
}


const LinkedList = (props: LinkedListProps) => {

  const { count, listData, heading, seeMore, contentFontColor, contentFontSize, testId } = props;

  const classNames = cn('LinkedList', className)

  return (
    <div data-test-id={testId} className={classNames}>
      {heading || count ?
        <div className="flex-v-center">
          {heading && <div className="LinkedList__heading">{heading}</div>}
          {count && <div className="LinkedList__count">{count}</div>}
        </div> : null
      }
      <InfoContainter rows={listData} contentFontColor={contentFontColor} contentFontSize={contentFontSize} seeMoreCb={seeMore} />
    </div>
  );
};

LinkedList.defaultProps = {
  contentFontColor: "black",
  contentFontSize: "large",
  testId: 'cs-linkedlist'
} as Partial<LinkedListProps>;

export default LinkedList;