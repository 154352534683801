import React, { useState, useRef, useEffect, createRef } from 'react'
import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Radio from '../../Radio'
import Dropdown from '../../DropDown/Dropdown'
import Help from '../../Help/Help'
import Textarea from '../../Textarea/Textarea'
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch'
import Button from '../../Button/Button'
import Icon from '../../Icon2'
import Tooltip from '../../Tooltip/Tooltip'
import arrayMove from 'array-move'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { fieldUidRegex } from '../helper'

export const SelectField = (props: any) => {
  const {
    onChange,
    parentInfo,
    uniqueKey,
    schema,
    type,
    selectAsNumberText,
    selectAsKeyValue,
    selectOptionsLimit,
    isNonLocalizable,
    hasNonLocalizablePlan
  } = props
  const [initialSchema, setSchema] = useState(schema)
  const { display_name, uid, field_metadata } = schema
  const [choices, setChoices] = useState('')
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef.current.focus()
  }, [displayNameRef])

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }

  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onChoicesChange = (event: any) => {
    setChoices(event.target.value)
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.mandatory`) {
      schema.mandatory = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }
  const onLimitMinChange = (event: any) => {
    schema.min_instance = parseInt(event.target.value)

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (schema.min_instance < 0 || schema.min_instance > schema.max_instance) {
      schema.error_details = {
        ...schema.error_details,
        min_instance: 'min instance cannot be greater than max instance'
      }
      schema.error = true
    } else {
      console.log('error_details are', schema.error_details)
      schema.error_details = {
        ...schema.error_details,
        min_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onOptionsUpdateError()
    onchange()
  }
  const onLimitMaxChange = (event: any) => {
    schema.max_instance = event.target.value ? parseInt(event.target.value) : null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (schema.max_instance < 0 || schema.max_instance < schema.min_instance) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'max instance cannot be lower than min instance'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onOptionsUpdateError()
    onchange()
  }
  const onDataTypeChoiceChange = (event: any) => {
    let value = event.target.value
    if (schema.enum.choices.length) {
      selectAsNumberText(schema, () => {
        if (schema.field_metadata) {
          schema.field_metadata.default_value = ''
          if (schema.enum.advanced) {
            schema.field_metadata.default_key = ''
          }
        }
        schema.enum.choices = []

        if (value === 'text') {
          schema.data_type = 'text'
        } else {
          schema.data_type = 'number'
        }

        onchange()
      })
    } else {
      if (value === 'text') {
        schema.data_type = 'text'
      } else {
        schema.data_type = 'number'
      }

      onchange()
    }
  }
  const selectionType = [
    {
      label: 'Single Choice',
      action: () => {
        schema.multiple = false
        if (schema.display_type === 'checkbox') {
          schema.display_type = 'radio'
        }
        if (schema.max_instance) {
          schema.max_instance = null
        }
        if (schema.min_instance) {
          schema.min_instance = null
        }
        onchange()
      },
      default: !schema.multiple
    },
    {
      label: 'Multiple Choices',
      action: () => {
        schema.multiple = true
        if (schema.display_type === 'radio') {
          schema.display_type = 'checkbox'
        }
        onchange()
      },
      default: schema.multiple
    }
  ]
  const displayType = [
    {
      label: 'Dropdown',
      action: () => {
        schema.display_type = 'dropdown'
        onchange()
      },
      default: schema.display_type === 'dropdown'
    },
    {
      label: `${schema.multiple ? 'Checkbox' : 'Radio'}`,
      action: () => {
        schema.display_type = `${schema.multiple ? 'checkbox' : 'radio'}`
        onchange()
      },
      default: schema.display_type === 'checkbox' || schema.display_type === 'radio'
    }
  ]

  const onAddChoices = (event: any) => {
    event.preventDefault()
    if (choices) {
      let newValue = choices

      var hasDuplicate = schema.enum.choices.filter((fi) => {
        return newValue.split('\n').filter((val) => {
          let keyValueChoiceData = val.split(':')
          if (schema.enum.advanced) {
            return keyValueChoiceData[1]?.trim() == fi.value || keyValueChoiceData[0]?.trim() == fi.key
          } else {
            return val.trim() == fi.value
          }
        }).length
      }).length
      if (!hasDuplicate) {
        var hasNewDuplicate = newValue.split('\n').filter((newVal, i) => {
          return newValue.split('\n').filter((val, j) => {
            while (i !== j) {
              if (schema.enum.advanced) {
                return (
                  val.split(':')[1]?.trim() == newVal.split(':')[1]?.trim() ||
                  val.split(':')[0]?.trim() == newVal.split(':')[0]?.trim()
                )
              } else {
                return val.trim() == newVal.trim()
              }
            }
          }).length
        }).length
      }

      if (schema.enum.advanced) {
        var isNewKeyValueEmpty = newValue.split('\n').filter((val) => {
          let keyValueChoiceData = val.split(':')
          return keyValueChoiceData[1]?.trim() === '' || keyValueChoiceData[0]?.trim() === ''
        }).length
      } else {
        var isNewValueEmpty = newValue.split('\n').filter((val) => {
          return val.trim() === ''
        }).length
      }

      if (hasDuplicate) {
        schema.error_details = {
          ...schema.error_details,
          duplicate_choice: 'The new choice cannot be same as any existing choice'
        }
        schema.error = true
      } else if (hasNewDuplicate) {
        schema.error_details = {
          ...schema.error_details,
          duplicate_choice: 'The new choices cannot be same'
        }
        schema.error = true
      } else if (isNewKeyValueEmpty) {
        schema.error_details = {
          ...schema.error_details,
          empty_new_choice_data: 'Value or Key cannot be empty'
        }
        schema.error = true
      } else if (isNewValueEmpty) {
        schema.error_details = {
          ...schema.error_details,
          empty_new_choice_data: 'Value cannot be empty'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          duplicate_choice: '',
          empty_new_choice_data: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }

      if (schema.enum.choices.length + newValue.split('\n').length > selectOptionsLimit) {
        schema.error_details = {
          ...schema.error_details,
          max_choices: `The maximum choices cannot be greater than ${selectOptionsLimit}`
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          max_choices: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }

      newValue.split('\n').filter((val: any) => {
        let keyValueChoiceData: any = val.split(':')
        if (
          (schema.enum.advanced && schema.data_type == 'number' && isNaN(keyValueChoiceData[1]?.trim())) ||
          (!schema.enum.advanced && schema.data_type == 'number' && isNaN(val))
        ) {
          schema.error_details = {
            ...schema.error_details,
            add_choices: 'Only number values are allowed'
          }
          schema.error = true
          onchange()
          return false
        } else {
          schema.error_details = {
            ...schema.error_details,
            add_choices: ''
          }
          schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
            ? true
            : false
        }
      })

      if (!hasDuplicate && !hasNewDuplicate && !isNewKeyValueEmpty && !isNewValueEmpty) {
        let allchoices = newValue.split('\n').map((item) => {
          if (item.length) {
            if (schema.enum.advanced) {
              let keyValueChoiceData = item.split(':')
              if (schema.data_type == 'number') {
                return {
                  value: Number(keyValueChoiceData[1].trim()),
                  key: keyValueChoiceData[0].trim()
                }
              } else {
                return {
                  value: keyValueChoiceData[1].trim(),
                  key: keyValueChoiceData[0].trim()
                }
              }
            } else {
              if (schema.data_type == 'number') {
                return {
                  value: Number(item.trim())
                }
              } else {
                return {
                  value: item.trim()
                }
              }
            }
          }
        })

        if (
          schema.error_details &&
          !schema.error_details.add_choices &&
          !schema.error_details.max_choices &&
          !schema.error_details.empty_new_choice_data
        ) {
          schema.enum.choices.push(...allchoices)
          setChoices('')
          if (schema.enum.advanced) {
            schema.mixPanel = true
            schema.mixPanelEventName = 'Add Key-Value Choices'
          }
        }
      }
      onOptionsUpdateError()
      onchange()
    }
  }

  const onOptionsUpdateError = () => {
    if (schema.enum.choices.length < schema.min_instance) {
      schema.error_details = {
        ...schema.error_details,
        min_instance: 'The minimum limit for multiple choices cannot be greater than the total number of choices'
      }
      schema.error = true
    } else if (schema.max_instance && schema.min_instance && schema.max_instance < schema.min_instance) {
      schema.error_details = {
        ...schema.error_details,
        min_instance:
          'The minimum limit for multiple choices cannot be greater than the maximum limit for multiple choices'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        min_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    if (schema.enum.choices.length < schema.max_instance) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'The maximum limit for multiple choices cannot be greater than the total number of choices'
      }
      schema.error = true
    } else if (schema.max_instance && schema.min_instance && schema.max_instance < schema.min_instance) {
      schema.error_details = {
        ...schema.error_details,
        max_instance:
          'The maximum limit for multiple choices cannot be less than the minimum limit for multiple choices'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
  }

  const SortableItem = (props) => {
    const { t }: any = props

    const radioTooltip: any = props.t('global_field.content_type_edit.body.fields.select.item.tooltip_text.default')

    return (
      <Draggable draggableId={`${uniqueKey}.${props.indexVal}`} index={props.index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`SelectField__choice-list flex-justify ${
              field_metadata.default_value == props.item.value ? 'SelectField__choice--default' : ''
            }`}>
            {schema.enum.advanced ? (
              <div className="SelectField__key-value-choice">
                <TextInput
                  name={`${uniqueKey}.addchoices.${props.index}.key`}
                  value={props.item.key}
                  type={'text'}
                  placeholder={'Enter key here'}
                  onKeyUp={(e) => onAddChoiceChange(e, props.indexVal, true)}
                  onBlur={onchange}
                  error={typeof props.item.key === 'undefined' ? true : undefined}
                />
                <span className="colon-seperator">:</span>
                <TextInput
                  name={`${uniqueKey}.addchoices.${props.index}.value`}
                  value={props.item.value}
                  type={schema.data_type}
                  placeholder={'Enter value here'}
                  onKeyUp={(e) => onAddChoiceChange(e, props.indexVal, false)}
                  onBlur={onchange}
                  error={typeof props.item.value === 'undefined' ? true : undefined}
                />
              </div>
            ) : (
              <TextInput
                name={`${uniqueKey}.addchoices.${props.index}`}
                value={props.item.value}
                type={schema.data_type}
                placeholder={'Enter value here'}
                onKeyUp={(e) => onAddChoiceChange(e, props.indexVal, false)}
                onBlur={onchange}
                error={typeof props.item.value === 'undefined' ? true : undefined}
              />
            )}

            <div className="flex-v-center">
              <Tooltip content={'Delete choice'} position="top" showArrow={false}>
                <div
                  className="SelectField__delete-choice"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (schema.enum.advanced) {
                      if (
                        field_metadata.default_value === schema.enum.choices[props.indexVal].value ||
                        field_metadata.default_key === schema.enum.choices[props.indexVal].key
                      ) {
                        schema.field_metadata.default_key = ''
                        schema.field_metadata.default_value = ''
                      }
                    } else {
                      if (schema.enum.choices[props.indexVal].value === field_metadata.default_value) {
                        schema.field_metadata.default_value = ''
                      }
                    }
                    schema.enum.choices.splice(props.indexVal, 1)
                    onOptionsUpdateError()
                    onchange()
                  }}>
                  <Icon icon="Delete" viewBox="0 0 12 12" />
                </div>
              </Tooltip>
              <Tooltip content={radioTooltip ? radioTooltip : 'Default'} position="top">
                <Radio
                  name={`${schema.uid}choices`}
                  onChange={(event: any) => {
                    console.log('field_metadata.default', event, field_metadata, props.item.value)

                    field_metadata.default_value = props.item.value
                    if (schema.enum.advanced) {
                      field_metadata.default_key = props.item.key
                    }
                    onchange()
                  }}
                  onClick={(event: any) => {
                    console.log('field_metadata', event)

                    if (field_metadata.default_value === props.item.value) {
                      field_metadata.default_value = ''
                      if (schema.enum.advanced) {
                        field_metadata.default_key = ''
                      }
                    } else {
                      field_metadata.default_value = props.item.value
                      if (schema.enum.advanced) {
                        field_metadata.default_key = props.item.key
                      }
                    }
                    onchange()
                  }}
                  checked={
                    typeof field_metadata.default_value !== 'undefined' &&
                    field_metadata.default_value === props.item.value
                  }
                />
              </Tooltip>
              <div className="SelectField__drag-icon" {...provided.dragHandleProps}>
                <Icon icon="MoveIcon" />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  const SortableList = ({ choices, t }) => {
    return (
      <div className="SelectField__choices">
        {choices.map((item, index) => {
          return (
            <div key={index}>
              <SortableItem t={t} key={`item-${item}`} item={item} index={index} indexVal={index} />
            </div>
          )
        })}
      </div>
    )
  }

  const onAddChoiceChange = (event: any, idx: any, key: boolean) => {
    console.log('Input Value here', typeof event.target.value, event.target.value)
    if (schema.enum?.advanced) {
      if (
        idx &&
        schema.enum?.choices[idx]?.key === schema.field_metadata.default_key &&
        schema.enum?.choices[idx].value === schema.field_metadata.default_value
      ) {
        if (schema.enum?.choices[idx][key ? 'key' : 'value'] !== event.target.value) {
          schema.field_metadata.default_key = ''
          schema.field_metadata.default_value = ''
        }
      }
    } else {
      if (schema.enum?.choices[idx].value === schema.field_metadata.default_value) {
        if (schema.enum.choices[idx].value !== event.target.value) {
          schema.field_metadata.default_value = ''
        }
      }
    }
    var keyCode = event.keyCode || event.which
    if (key) {
      schema.enum.choices[idx].key = event.target.value
    } else {
      if (schema.data_type === 'number') {
        schema.enum.choices[idx].value = parseInt(event.target.value)
      } else {
        schema.enum.choices[idx].value = event.target.value
      }
    }
    if (schema.enum.advanced) {
      var isEditKeyValueEmpty = schema.enum.choices.filter((val) => {
        if (schema.data_type === 'text') {
          return val.key.trim() === '' || val.value.trim() === ''
        } else {
          return val.key.toString().trim() === '' || val.value.toString().trim() === ''
        }
      }).length
    } else {
      var isEditValueEmpty = schema.enum.choices.filter((val) => {
        if (schema.data_type === 'text') {
          return val.value.trim() === ''
        } else {
          return val.value.toString().trim() === ''
        }
      }).length
    }
    var hasDuplicate = schema.enum.choices.filter((newVal, i) => {
      return schema.enum.choices.filter((val, j) => {
        while (i !== j) {
          if (schema.enum.advanced) {
            if (schema.data_type === 'text') {
              return val.key.trim() == newVal.key.trim() || val.value.trim() == newVal.value.trim()
            } else {
              return (
                val.key.toString().trim() == newVal.key.toString().trim() ||
                val.value.toString().trim() == newVal.value.toString().trim()
              )
            }
          } else {
            if (schema.data_type === 'text') {
              return val.value.trim() == newVal.value.trim()
            } else {
              return val.value.toString().trim() == newVal.value.toString().trim()
            }
          }
        }
      }).length
    }).length
    console.log('Empty Choice Data Error here', isEditKeyValueEmpty, isEditValueEmpty, hasDuplicate)
    if (hasDuplicate) {
      schema.error_details = {
        ...schema.error_details,
        duplicate_choice_data: 'The choices cannot be same'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        duplicate_choice_data: ''
      }
    }
    if (isEditKeyValueEmpty) {
      schema.error_details = {
        ...schema.error_details,
        empty_choice_data: 'Value or Key cannot be empty'
      }
      schema.error = true
    } else if (isEditValueEmpty) {
      schema.error_details = {
        ...schema.error_details,
        empty_choice_data: 'Value cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        empty_choice_data: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    if (keyCode == 13) {
      onOptionsUpdateError()
      onchange()
    }
  }

  const onKeyValueToggle = () => {
    selectAsKeyValue(schema, () => {
      schema.enum.choices = []
      field_metadata.default_value = ''

      if (schema.enum.advanced) {
        delete field_metadata.default_key
        schema.enum.advanced = false
      } else {
        field_metadata.default_key = ''
        schema.enum.advanced = true
      }

      setChoices('')
      onOptionsUpdateError()
      onchange()
    })
  }

  if (type === 'basic') {
    return (
      <>
        <div className="FormFields">
          <div className="FormFields__column">
            <Field>
              <FieldLabel required htmlFor="displayName">
                Display Name
              </FieldLabel>
              <TextInput
                required
                name={`${uniqueKey}.displayName`}
                value={display_name}
                onChange={onDisplayNameChange}
                inputRef={displayNameRef}
                error={schema.error && schema.error_details.display_name ? true : false}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
              <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
              <Textarea
                name={`${uniqueKey}.instruction`}
                value={field_metadata.instruction || ''}
                onChange={onInstructionChange}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="selectionType">Selection Type</FieldLabel>
              <Help text="Select if users can choose single or multiple choices while creating entries" />
              <div className="FormFields__row">
                <Dropdown
                  className="w-80"
                  type="select"
                  arrowSecondary={true}
                  dropDownType="primary"
                  list={selectionType}
                  closeAfterSelect={true}
                />
              </div>
            </Field>
            <Field>
              <FieldLabel htmlFor="displayType">Display Type</FieldLabel>
              <Help text="Decide how the choices must be displayed to the users." />
              <div className="FormFields__row">
                <Dropdown
                  className="w-80"
                  type="select"
                  arrowSecondary={true}
                  list={displayType}
                  closeAfterSelect={true}
                />
              </div>
            </Field>
          </div>
          <div className="FormFields__column">
            <Field>
              <FieldLabel required htmlFor="uid">
                Unique ID
              </FieldLabel>
              <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
              <TextInput
                required
                name={`${uniqueKey}.uid`}
                value={uid}
                onChange={onUidChange}
                error={(schema.error && schema.error_details.uid) || error ? true : false}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
              <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
              <Textarea
                name={`${uniqueKey}.helpText`}
                value={field_metadata.description || ''}
                onChange={onHelpTextChange}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="limitMulChoices">Limit for Multiple Choices</FieldLabel>
              <Help text="Set the minimum and maximum number of choices that can be selected while creating entry." />
              <div className="FormFields__group-fields">
                <TextInput
                  name={`${uniqueKey}.limit.min`}
                  type="number"
                  disabled={!schema.multiple}
                  placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.min')}
                  value={schema.min_instance || null}
                  min="0"
                  onChange={onLimitMinChange}
                  error={schema.error && schema.error_details.min_instance ? true : false}
                />
                <TextInput
                  name={`${uniqueKey}.limit.max`}
                  type="number"
                  disabled={!schema.multiple}
                  value={schema.max_instance || null}
                  max="1"
                  onChange={onLimitMaxChange}
                  error={schema.error && schema.error_details.max_instance ? true : false}
                  placeholder={props.t('content_type.save_content_type.body.fields.common_properties.placeholders.max')}
                />
              </div>
              {schema.error && schema.error_details && schema.error_details.max_instance ? (
                <span>{schema.error_details.max_instance}</span>
              ) : schema.error && schema.error_details && schema.error_details.min_instance ? (
                <span>{schema.error_details.min_instance}</span>
              ) : null}
            </Field>
            <Field>
              <FieldLabel htmlFor="choiceDataType"> Choice Data Type</FieldLabel>
              <Help text="Set a data type in which the values will be added for choices." />
              <div className="FormFields__selection-wrapper FormFields__selection-wrapper--inline">
                <Radio
                  name={`${uniqueKey}.choiceDT`}
                  value="text"
                  text="text"
                  checked={schema.data_type === 'text'}
                  onChange={onDataTypeChoiceChange}
                />
                <Radio
                  name={`${uniqueKey}.choiceDT`}
                  value="number"
                  text="number"
                  checked={schema.data_type === 'number'}
                  onChange={onDataTypeChoiceChange}
                />
              </div>
            </Field>
          </div>
        </div>
        <div className="FormFields">
          <Field>
            <div className="add-choices-with-toggle">
              {/* <div> */}
              <div>
                <FieldLabel htmlFor="addChoices">Add Choices</FieldLabel>
                <Help
                  text={`Enter values that will be visible as choices to the users. A maximum of ${selectOptionsLimit} choices can be added.`}
                />
              </div>
              <ToggleSwitch
                name={'keyValueToggle'}
                id={'keyValueToggle'}
                checked={schema.enum.advanced ? true : false}
                onClick={onKeyValueToggle}
                label={schema.enum.advanced ? 'Key-value enabled' : 'Enable key-value'}
                labelPosition="right"
                labelColor={schema.enum.advanced ? 'primary' : 'secondary'}
              />
            </div>
            <div className="add-choices-key-value-section">
              <form onSubmit={onAddChoices}>
                <div className="flex-v-center flex-justify">
                  <Textarea
                    name={`${uniqueKey}.addchoices`}
                    value={choices}
                    className="choices-input"
                    onChange={onChoicesChange}
                    error={
                      schema.error &&
                      (schema.error_details.add_choices ||
                        schema.error_details.max_choices ||
                        schema.error_details.duplicate_choice)
                        ? true
                        : false
                    }
                    placeholder={
                      schema.enum.advanced ? 'Enter key-value pairs here, e.g., New York:NY' : 'Enter values here'
                    }
                  />
                  <Button icon="AddPlus" name="submit" type="submit">
                    Add
                  </Button>
                </div>
              </form>
              <div className="add-choices-description">
                {schema.enum.advanced ? (
                  <span>
                    Enter key-value pairs (choices) with a colon separating the key from value; use commas to separate
                    multiple key-value pairs (e.g., New York:NY, Texas:TX); and press 'Enter'.
                  </span>
                ) : (
                  <span>Enter values (choices), each in a separate line, and click ‘Add’.</span>
                )}
              </div>
              <DragDropContext
                onDragEnd={(result) => {
                  if (!result.destination) {
                    return
                  }

                  if (result.destination.index === result.source.index) {
                    return
                  }

                  schema.enum.choices = arrayMove(schema.enum.choices, result.source.index, result.destination.index)
                  onchange()
                }}>
                <Droppable droppableId={`${uniqueKey}.choices`}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {schema.enum.advanced && schema.enum.choices.length > 0 ? (
                        <>
                          <div className="choices-table-headers">
                            <div className="choices-table-choice-header">
                              <span>Key(s)</span>
                              <span>Value(s)</span>
                            </div>
                            <span>
                              {schema.enum.choices.length} {schema.enum.choices.length === 1 ? 'choice' : 'choices'}{' '}
                              added
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <SortableList t={props.t} choices={schema.enum.choices} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Field>
        </div>
      </>
    )
  } else if (type === 'advanced') {
    return (
      <>
        <div className="FormFields">
          <Field>
            <FieldLabel htmlFor="options">Options</FieldLabel>
            <div className="FormFields__selection-wrapper">
              <Checkbox
                text="Mandatory"
                name={`${uniqueKey}.mandatory`}
                checked={schema.mandatory}
                onChange={onOptionsChange}
              />
              {hasNonLocalizablePlan ? (
                <>
                  {isNonLocalizable ? (
                    <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                      <Checkbox
                        name={`${uniqueKey}.nonLocalization`}
                        text="Non-localizable"
                        checked={schema.non_localizable}
                        disabled={isNonLocalizable}
                        onChange={onOptionsChange}
                      />
                    </Tooltip>
                  ) : (
                    <Checkbox
                      name={`${uniqueKey}.nonLocalization`}
                      text="Non-localizable"
                      checked={schema.non_localizable}
                      disabled={isNonLocalizable}
                      onChange={onOptionsChange}
                    />
                  )}
                  <div className="nl-note">
                    If enabled, editing this field is restricted in localized entries. The field will use the value of
                    the master-language entry in all localized entries.
                  </div>
                </>
              ) : null}
            </div>
          </Field>

          {(
            parentInfo.hasParent
              ? parentInfo.isMulti && (parentInfo.data_type == 'group' || parentInfo.data_type == 'global_field')
              : false
          ) ? (
            <>
              <div className="mark-group-title">
                <Checkbox
                  name={`${uniqueKey}.isTitle`}
                  text="Mark as Group title"
                  checked={field_metadata.isTitle || false}
                  onChange={(event) => {
                    let eventData = event.target.checked
                    field_metadata.isTitle = eventData
                    onchange()
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      </>
    )
  }
}

export default React.memo(SelectField)
