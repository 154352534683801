import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 13.75A1.75 1.75 0 01.25 12V.25h13.5V12A1.75 1.75 0 0112 13.75H2z"
      />
      <path
        d="M7 4v6M10 7H4"
        stroke="#647696"
        strokeWidth={1.4}
        strokeLinecap="round"
      />
      <path
        d="M2 13.75A1.75 1.75 0 01.25 12V.25h13.5V12A1.75 1.75 0 0112 13.75H2z"
      />
    </svg>
  )
}

export default SvgComponent
