import React from 'react'

import { Registry } from '@react-registry'

export default (props) => {
  let { attributes, children, leaf } = props

  if (children.props.text.attrs && children.props.text.attrs.style) {
    children = (
      <span style={children.props.text.attrs.style} data-type="inline-text-style">
        {children}
      </span>
    )
  }

  Object.entries(leaf).forEach((el) => {
    if (Registry.get({ id: el[0], registry: 'leaf' })) {
      const { Component }: any = Registry.get({ id: el[0], registry: 'leaf' })
      children = <Component>{children}</Component>
    }
  })

  return (
    <span {...attributes} data-editor-type="scrte">
      {children}
    </span>
  )
}

export * from './bold'

export * from './italic'

export * from './underline'

export * from './mark'

export * from './strikethrough'

export * from './inlineCode'

export * from './superscript'

export * from './subscript'

export * from './removeFormatting'
