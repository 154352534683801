import React, { useContext } from 'react'
import { Registry } from '@react-registry'
import { useSlateStatic } from 'slate-react'

import { leafCategories } from '../leafCategories'
import { Icon } from '../../../../../../components/Icon2'
import { RangeCommentIngressComponent } from './IngressComponent'

import styles from './style.module.css'
import { getDiscussionIDFromMark, getDiscussionsOnTextNode, getSmallestDiscussionAtTextNode } from './helpers'
import { cx } from '@emotion/css'
import { parseDiscussionUID } from '../../../components/Discussion/utils'

export const RangeComment = ({ attributes, children, leaf, discussionUtils, ...props }) => {
  const editor = useSlateStatic()
  const { setActiveDiscussion, activeDiscussion } = discussionUtils

  const discussionUIDs = Array.from(getDiscussionsOnTextNode(props.text))
  const isCurrentDiscussionActive = activeDiscussion && discussionUIDs.includes(parseDiscussionUID(activeDiscussion))

  function handleClick(e) {
    // @ts-ignore
    const discussionID = getSmallestDiscussionAtTextNode(editor, leaf)
    if (activeDiscussion === discussionID) return
    setActiveDiscussion?.(`range-${discussionID}`)
  }

  return (
    <span
      onClick={handleClick}
      data-discussion-uid={discussionUIDs.join(' ')}
      className={cx(
        { [styles['range-comment']]: true },
        { [styles['active-range-comment']]: isCurrentDiscussionActive }
      )}>
      {children}
    </span>
  )
}

Registry.register(
  {
    Component: RangeComment,
    title: 'Add Comment',
    IngressComponent: RangeCommentIngressComponent,
    iconName: <Icon icon={'AddComment'} height={'16px'} />,
    isContentStackElement: true,
    category: leafCategories.TEXT_STYLE_SEE_MORE,
    inBasicToolbar: true,
    eventType: 'CLICK' // need onClick event for this
  },
  { id: 'rangeComment', registry: 'v2.leaf' }
)

export * from './deserializer'

export * from './withRangeComment'
