import React from 'react'

interface Props {
  className?: string
  children?: React.ReactNode | Element
}

const OAuthCardHeader: React.FC<Props> = ({ className = '', children }: React.PropsWithChildren<Props>) => {
  return <div className={className}>{children}</div>
}

export default React.memo(OAuthCardHeader)
