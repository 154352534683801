//@ts-nocheck
import React from 'react'
import styles from '../style.module.css'
import '../style.css';
import { cx } from '@emotion/css'
import { ReferenceComponent } from '../../inlineReference';

const PreviewCard = ({ children, footer, active, onClick, testId = "scrte-preview-card" }) => {
  return (
    <div className={cx(styles['preview_card'], active && styles['preview_card--active'])} onClick={onClick} data-testid={testId}>
      <div className={styles['preview_card__body']}>
        {children}
      </div>
      <div className={styles['preview_card__footer']}>
        {footer}
      </div>
    </div>
  )
}

const Divider = ({ title }) => {
  return (
    <div className="cs__divider">
      <span className='cs__title'>{title}</span>
      <span className='cs__ruler'></span>
    </div>
  )
}

export const ReferenceBody = ({ handleDisplayType, singleSelectedRowId, displayType }) => {
  return (
    singleSelectedRowId.key !== '' && (
      <div style={{ padding: '20px' }}>
        <Divider title="Embed Type" />
        <div className={styles['reference__body--container']}>
          <PreviewCard footer="Embed as block" active={displayType === 'block'} onClick={() => handleDisplayType('block')} testId={'embed-as-block'}>
            <ReferenceComponent type='block' entry={{ title: "Entry Name" }} content_type_uid="Content Type Name" attributes={{}} children={null} />
          </PreviewCard>
          <PreviewCard footer="Embed as a part of text" active={displayType === 'inline'} onClick={() => handleDisplayType('inline')} testId={'embed-as-inline'}>
            <span>Lorem <ReferenceComponent type='inline' entry={{ title: "Entry Name" }} content_type_uid="Content Type Name" attributes={{}} children={null} /> Ipsum</span>
          </PreviewCard>
        </div>
      </div>
    )
  )
}
