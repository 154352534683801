import React from 'react'

import { Editor, fromRedactor, toRedactor, parseFromString } from './SuperChargedRte/index'
import { htmlToJson } from './SuperChargedRte/utils/serialize/jsonHtmlTransformations';

const requestProps = {
  'assetUrl': process.env.ASSET_URL,
  'apiKey': process.env.API_KEY,
  'authToken': process.env.AUTH_TOKEN
}

const isLeaf = (node) => typeof node === 'object' && node['text'];

export const handleLocalizeEntry = (requestProps, slateJson) => {
  const { uidToEntry } = requestProps || {};
  const inlineVoid = ['inline'];
  if (!uidToEntry) return;
  function traverseJson(json) {
    if (Array.isArray(json)) {
      //fix children
      const newJson = [];
      json.forEach((block, idx) => {
        if (block['type'] === 'reference' && inlineVoid.includes(block?.attrs?.['display-type'])) {
          let lastEl = newJson.length && newJson[newJson.length - 1];
          if (idx < 1 || (lastEl && !isLeaf(lastEl))) {
            newJson.push({ text: '' });
          }
          newJson.push(block);
          if (idx === json.length - 1) {
            newJson.push({ text: '' });
          }
        } else {
          newJson.push(block);
        }
      })
      // iterate child blocks;
      json.splice(0, json.length, ...newJson);
      json.forEach(traverseJson);
    }
    else if (typeof json === 'object') {
      if (json.text) return;
      if (json.type === 'reference' && json.attrs) {
        const entryUid = json.attrs['entry-uid'];
        if (uidToEntry[entryUid] && uidToEntry[entryUid]['locale']) {
          json.attrs['locale'] = uidToEntry[entryUid]['locale'];
        }
      }
      if (json.children)
        traverseJson(json.children);
    }
  }
  traverseJson(slateJson);
}

const RichTextEditor = (props: any) => {
  const {
    value = '',
    contentStack = false,
    toolbarMode = 'basic',
    onChange = null,
    hideToolbarOnBlur = false,
    customToolbarComponents = {},
    csOnlyBreakline,
    editorType = 'html',
    allowExtraTags = {},
    customPasteOptions = {},
    requestProps,
    ...otherProps
  } = props
  const getJsonValue = (html) => {
    let redactorJson = htmlToJson(html, allowExtraTags)
    handleLocalizeEntry(requestProps, redactorJson);
    return redactorJson
  }

  const editorValue = editorType === 'json' ? value : getJsonValue(value);

  return (
    <div >
      <Editor
        requestProps={requestProps}
        contentStack={true}
        toolbarMode={toolbarMode}
        value={editorValue}
        hideToolbarOnBlur={hideToolbarOnBlur}
        customToolbarComponents={customToolbarComponents}
        csOnlyBreakline={csOnlyBreakline}
        allowExtraTags={allowExtraTags}
        customPasteOptions={customPasteOptions}
        onChange={(receivedJson: any) => {
          if (typeof receivedJson === 'string') {
            return onChange(receivedJson);
          }
          if (onChange) {
            if (editorType === 'json') {
              onChange(receivedJson);
            } else {
              onChange(toRedactor(receivedJson[0]));
            }
          }
        }}
        {...otherProps}
      />
    </div>
  )
}

export default RichTextEditor
export {
  toRedactor,
  fromRedactor
}