import React from 'react';
import cn from 'classnames'
import './SectionHeader.css';

export type SectionHeaderProps = {
  content: string | React.ReactNode
  fontColor?: "white" | "base" | "link" | "black"
  fontWeight?: "bold" | "extra-bold" | "semi-bold"
  fontSize?: "tiny" | "small" | "medium" | "large"
  leftMargin?: string | number
  topMargin?: string | number
  rightMargin?: string | number
  bottomMargin?: string | number
  className?: string,
  testId?: string
}

const SectionHeader = (props: SectionHeaderProps) => {

  const { content, fontColor, fontSize, fontWeight, leftMargin, rightMargin, topMargin, bottomMargin, className, testId } = props || {};

  const classNames = cn('SectionHeader', className, {
    [`SectionHeader--${fontWeight}`]: fontWeight,
    [`SectionHeader--${fontSize}`]: fontSize,
    [`SectionHeader--${fontColor}`]: fontColor,
  })

  return (
    <div data-testid={testId} className={classNames} style={{ marginTop: topMargin, marginBottom: bottomMargin, marginLeft: leftMargin, marginRight: rightMargin }} >
      {content}
    </div>
  );
};

SectionHeader.defaultProps = {
  fontWeight: "extra-bold",
  fontColor: "black",
  fontSize: "medium",
  testId: 'cs-section-header'
} as Partial<SectionHeaderProps>;

export default SectionHeader;