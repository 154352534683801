//@ts-nocheck
import React from 'react'

import { Toolbar as ToolbarWrapper } from './Toolbar'
import { HoveringToolbar } from './HoveringToolbar'
import BaseToolbar from './BaseToolbar';

export declare interface MainToolbarProps {
  contentStack: boolean,
  customToolbarComponents: any,
  toolbarMode: any,
  templateregistrycondition: any,
  className: any,
  fieldName: string,
  editorRef: any
}

const MainToolbar = React.memo((props: Partial<MainToolbarProps>) => {
  const {
    contentStack,
    customToolbarComponents,
    toolbarMode = 'advance',
    fieldName,
    editorRef
  } = props
  let propsCopy = { ...props }
  delete propsCopy['contentStack']
  delete propsCopy['toolbarMode']
  delete propsCopy['customToolbarComponents']

  return (
    <ToolbarWrapper {...propsCopy}>
      <BaseToolbar
        toolbarType={'mainToolbar'}
        contentStack={contentStack}
        toolbarMode={toolbarMode}
        customToolbarComponents={customToolbarComponents}
        fieldName={fieldName}
        editorRef={editorRef}
      />
    </ToolbarWrapper>
  )
}, (prev, next) => {
  return true;
})

export { MainToolbar, HoveringToolbar }
