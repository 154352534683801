//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'

import { deleteHead } from './deleteHead'
import { deleteTable } from './deleteTable'
import { elementCategories } from '../../elementCategories'
import { TableDeleteRowIcon } from '../../../utils/Icon'


export const deleteRow = (event, { editor }) => {
  const [row] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'tr'
    },
    mode: 'lowest'
  })
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  const [tableHead] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'thead'
    },
    mode: 'lowest'
  })
  const [tableBody] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'tbody'
    },
    mode: 'lowest'
  })
  if (row && table) {
    if (table[0].attrs && table[0].attrs.rows > 1) {
      if (tableHead && tableHead[0].children.length === 1) {
        deleteHead(event, { editor })
        Transforms.setNodes(
          editor,
          {
            attrs: {
              ...table[0].attrs,
              rows: table[0].attrs.rows - 1
            }
          },
          { at: table[1] }
        )
        return
      }
      if (tableBody && tableBody[0].children.length === 1) {
        Transforms.removeNodes(editor, { at: tableBody[1] })
        Transforms.setNodes(
          editor,
          {
            attrs: {
              ...table[0].attrs,
              rows: table[0].attrs.rows - 1
            }
          },
          { at: table[1] }
        )
        return
      }
      Transforms.removeNodes(editor, { at: row[1] })
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table[0].attrs,
            rows: table[0].attrs.rows - 1
          }
        },
        { at: table[1] }
      )
    } else {
      deleteTable(event, { editor })
    }
  }
}
const DeleteRowButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: deleteRow,
        isDisable: { func: deleteRowButtonDisabler, params: { editor } },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}
const deleteRowButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  return !table
}
Registry.register(
  {
    iconName: <TableDeleteRowIcon />,
    IngressComponent: DeleteRowButton,
    category: elementCategories.TABLE_ACTION,
    toolbar: {
      inSlashToolbar: false,
    },
    canCustomize: false,
    title: 'Delete row',
    isContentStackElement: true
  },
  {
    id: 'table-delete-row',
    registry: 'v2.element'
  }
)
