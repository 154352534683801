import './Stepper.css'

import React, {
  useMemo,
  useState
} from 'react'

import Icon from '../Icon2/Icon'

export enum StepStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  DISABLED = "DISABLED"
}

export type stepsArray = {
  id: string
  title?: JSX.Element | string | Element,
  data?: React.ElementType 
  status?: StepStatus
};

export type stepperProps = {
  steps: Array<stepsArray>
  className?: string
  emptyStateMsg?: string | JSX.Element,
  stepComponentProps?: any,
  hideTabView?: boolean,
  stepContentClassName?: string,
  stepTitleClassName?: string
  testId?: string
};

const Stepper = (props: stepperProps) => {
  const { steps, className, emptyStateMsg, stepComponentProps, hideTabView, testId } = props;
  const [showStep, setShowStep] = useState(0);
  const [stepsCompleted, setStepsCompleted] = useState([]);

  const handleStepChange = (currentStep: number) => {
    setShowStep(currentStep + 1);
    const stepsCompletedArray: Array<Number> = [];
    for (let i = 0; i <= currentStep; i++) {
      stepsCompletedArray.push(i);
    }
    setStepsCompleted(stepsCompletedArray);
  }

  const setTabStep = (idx: number) => {
    if (stepsCompleted.includes(idx) || stepsCompleted.length === idx) {
      setShowStep(idx);
    }
  }

  const StepsTitleCreator: () => JSX.Element = () => (
    <div className="StepperWrapper__stepsTitleRow">
      {
        steps.map(({ id, title }, idx) => {
          const completedClass = stepsCompleted.includes(idx) ? 'completed' : '';
          const cursorClass =
            (stepsCompleted.includes(idx) || idx === showStep)
              && !stepsCompleted.includes(idx - 1) ? '' : 'not-allowed';
          const activeClass = idx === showStep ? 'active' : '';
          const disableClass =
            !stepsCompleted.includes(idx)
              && idx !== showStep
              && !stepsCompleted.includes(idx - 1) ? 'disableEvents' : '';
          return (
            <div className={`tabWrapper ${cursorClass}`} key={id}>
              <div
                className={`stepWrapper ${completedClass} ${activeClass} ${disableClass}`}
                onClick={setTabStep.bind(null, idx)}
              >
                <Icon icon="SuccessInverted" className="mr-10 successIconCss" />
                <span className="badge flex-center">{idx + 1}</span>
                <div className="title">{title}</div>
              </div>
            </div>
          )
        })
      }
    </div>
  );

  const StepperStepCreator: () => JSX.Element = () => {
    const { data: StepComponent } = steps[showStep];
    return (
      <div className="StepperWrapper__step">
        <StepComponent
          handleStepChange={handleStepChange}
          currentStep={showStep}
          stepsCompleted={stepsCompleted}
          {...(stepComponentProps && { stepComponentProps: stepComponentProps })}
        />
      </div>
    )
  };

  return (
    <div className={`StepperWrapper ${className}`} data-testid={testId}>
      {
        steps.length ? (
          <>
            {!hideTabView && <StepsTitleCreator />}
            <StepperStepCreator />
          </>
        )
          : emptyStateMsg
      }
    </div>
  );
};

export const VerticalStepper = (props: stepperProps) => {
  const {
    steps,
    className = null,
    stepComponentProps,
    stepTitleClassName,
    stepContentClassName,
    testId
  } = props;

  const StepperStepTitleCreator: (
    data
  ) => JSX.Element = (data) => {
    const StepComponent = data
    return (
      <div className="StepperWrapper__stepsTitleRow">
        <StepComponent
          {...(stepComponentProps && {
            stepComponentProps: stepComponentProps,
          })}
        />
      </div>
    );
  };

  return useMemo(() => {
    const setStepClassName = (step: stepsArray, index: number) => {
      let stepClassName = "";

      const stepClassNameObject = {
        [StepStatus.ACTIVE]: "active",
        [StepStatus.COMPLETED]: "completed",
        [StepStatus.DISABLED]: "disabled",
        [`${StepStatus.ACTIVE}__${StepStatus.COMPLETED}`]:
          "active__to__completed",
        [`${StepStatus.ACTIVE}__${StepStatus.ACTIVE}`]: "active__to__active",
        [`${StepStatus.ACTIVE}__${StepStatus.DISABLED}`]:
          "active__to__disabled",
        [`${StepStatus.DISABLED}__${StepStatus.COMPLETED}`]:
          "disabled__to__completed",
        [`${StepStatus.DISABLED}__${StepStatus.ACTIVE}`]:
          "disabled__to__active",
        [`${StepStatus.DISABLED}__${StepStatus.DISABLED}`]:
          "disabled__to__disabled",
        [`${StepStatus.COMPLETED}__${StepStatus.COMPLETED}`]:
          "completed__to__completed",
        [`${StepStatus.COMPLETED}__${StepStatus.ACTIVE}`]:
          "completed__to__active",
        [`${StepStatus.COMPLETED}__${StepStatus.DISABLED}`]:
          "completed__to__disabled",
      };

      if (!step?.status) {
        stepClassName = stepClassNameObject[StepStatus.ACTIVE];
      } else {
        let gradientClassName = "";
        if (index < steps.length - 1) {
          gradientClassName =
            stepClassNameObject[`${step.status}__${steps[index + 1]?.status}`];
        }
        stepClassName = `${stepClassNameObject[step.status]
          } ${gradientClassName}`;
      }

      return stepClassName;
    };

    return (
      <div className={`StepperWrapper ${className}`} data-testid={testId}>
        <ol className="Vertical">
          {steps.map((step, index) => {
            const stepClassName = setStepClassName(step, index);
            return (
              <li id={step.id} className={stepClassName} key={step.id}>
                <div className={`step__title ${stepTitleClassName}`}>
                  {StepperStepTitleCreator(step.title as any)}
                </div>
                <div className={`step__content ${stepContentClassName}`}>
                  <div className="StepperWrapper__step">{step.data}</div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    );
  }, [steps]);
};

Stepper.defaultProps = {
  testId: 'cs-stepper',
  className: '',
  emptyStateMsg: 'The Stepper is unable to create your form due to empty data',
} as Partial<stepperProps>;

export default Stepper;