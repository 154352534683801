import { Editor, Transforms } from "slate"
import { ElementWithType } from "../../../../../utils/types"

export const shouldSkipSlashForElements = (editor, elementUIDs: string[]) => {
  for (let uid of elementUIDs) {
    const [element] = Editor.nodes(editor, {
      match: (n: ElementWithType) => n.type === uid
    });
    if (Boolean(element)) return true;
  }
}

export const handleSlashKeyEvents = function (event: KeyboardEvent) {
  const { index, setIndex, searchElements, target, dispatch, editor, setChoice, setTarget, handleSet } = this;
  if (!target) return;

  switch (event.key) {
    case 'ArrowDown':
      event.preventDefault()
      const prevIndex = index >= searchElements.length ? 0 : index + 1
      setIndex(prevIndex)
      break
    case 'ArrowUp':
      event.preventDefault()
      const nextIndex = index <= 0 ? searchElements.length - 1 : index - 1
      setIndex(nextIndex)
      break
    case 'Tab':
    case 'Enter':
      event.preventDefault()
      if (!searchElements[index]) break

      Transforms.delete(editor, { at: target })
      if (searchElements[index].handleMouseDown) {
        searchElements[index].handleMouseDown({ event, editor, dispatch })
      } else if (searchElements[index].IngressComponent) {
        setChoice(index)
        setTarget(null)
      } else handleSet(searchElements[index].format)
      break
    case 'Escape':
      event.preventDefault()
      setTarget(null)
      break
  }
}

const MAX_SLASH_WIDTH = 250;

export const handleSlashRect = (cursorRect, slashDOM, editableDOM) => {
  editableDOM = editableDOM.getBoundingClientRect();

  let [cursorTop, cursorLeft] = [cursorRect.bottom, cursorRect.left + window.pageXOffset];
  let [editableTopToCursorDiff, editableBottomToCursorDiff] = [cursorTop - editableDOM.top, editableDOM.bottom - cursorTop];

  const cursorIsAtBottom = editableTopToCursorDiff > editableBottomToCursorDiff;
  const isOutsideRight = cursorLeft + MAX_SLASH_WIDTH > editableDOM.right;

  if (isOutsideRight) {
    const takeInside = (cursorLeft + MAX_SLASH_WIDTH) - editableDOM.right;
    cursorLeft -= takeInside;
  }
  const cursorSlashSpace = 8;
  const slashMargin = 16;

  if (cursorIsAtBottom) {
    const maxHeight = Math.min(350, editableTopToCursorDiff);
    cursorTop -= maxHeight + cursorSlashSpace;
    slashDOM.style.height = `${maxHeight - slashMargin}px`;
  } else {
    const maxHeight = Math.min(350, editableBottomToCursorDiff);
    cursorTop += cursorSlashSpace;
    slashDOM.style.height = `${maxHeight - slashMargin}px`
  }

  slashDOM.style.top = `${cursorTop}px`
  slashDOM.style.left = `${cursorLeft}px`;
}