//@ts-nocheck
import { Editor } from 'slate'

export const disableOnCodeChild = ({ editor }) => {
  const [code] = Editor.nodes(editor, {
    match: (n) => n.type === 'code',
    mode: 'lowest'
  })
  return Boolean(code)
}
