import React from 'react'
import { Dropdown, Search, Icon, Tooltip } from '../../../../../index'
import cn from 'classnames'
import styles from './style.module.css';

export function AssetSearch({ listType, setState, assetsCount, selectedAssetsCount, showSearchBox }) {
  const dropdownList = [
    {
      label: <span className={styles['dropdown-item']}><Icon icon='List' className={styles['margin-right']} /> List</span>,
      action: () => {
        setState(state => ({ ...state, displayType: 'List' }))
      },
      default: listType === 'List',
    },
    {
      label: <span className={styles['dropdown-item']}><Icon icon='Thumbnail' className={styles['margin-right']} /> Thumbnail</span>,
      action: () => {
        setState(state => ({ ...state, displayType: 'Grid' }))
      },
      default: listType === 'Grid',
    },
  ]

  function handleSearchChange(searchInput) {
    setState(state => ({
      ...state,
      query: searchInput,
      loading: true
    }));
  }

  const searchContainer = cn(styles['scrte-search'], {
    [styles['exclude-search']]: !showSearchBox
  })

  const dropDownWrapper = cn('Dropdown-wrapper', {
    [styles['dropdown-on-right']]: !showSearchBox
  })

  const assetsCountCn = cn(styles['asset-count'], {
    [styles['selected-assets-count']]: !showSearchBox
  })

  return (
    <div className={searchContainer}>
      {showSearchBox && (<Search
        width="full"
        placeholder="Search Assets"
        corners="regular"
        debounceSearch={true}
        onChange={handleSearchChange}
      />)}
      <div className={styles['asset-view-controls']}>
        <p
          className={assetsCountCn}>
          {showSearchBox ? assetsCount : selectedAssetsCount} items
        </p>
        <div className={dropDownWrapper}>
          <Dropdown closeAfterSelect={true} list={dropdownList} type="click" highlightActive={true} withArrow={true} dropDownPosition="left" >
            <Tooltip content={'Change view'} position="top">
              {listType === 'List' ? <Icon icon="List" className="List__icon" /> : <Icon icon="Thumbnail" className="List__icon" />}
            </Tooltip>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
