import React from 'react';
import { Tooltip, Icon } from '../../../../../../../../../index';


export const InfoIcon = ({ error, isDisabled, ...props }) => {
  if (error) {
    return (
      <span {...props} data-testid='asset-info-error'>
        <Tooltip appendTo={document.body} className={'embed-error'} content={error} position='top' variantType='light'>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.82106 2.8538L1.24076 12.7743C0.303345 14.4409 1.50763 16.5 3.41969 16.5H14.5803C16.4924 16.5 17.6967 14.4409 16.7592 12.7743L11.1789 2.85381C10.2232 1.15468 7.77682 1.15467 6.82106 2.8538Z" fill="#FF5630" stroke="#FF5630" />
            <rect x="7.75" y="8" width="2.5" height="6" rx="1" fill="white" />
            <circle cx="9" cy="5.25" r="1.25" fill="white" />
          </svg>
        </Tooltip>
      </span>
    )
  }
  if (isDisabled) {
    return (
      <span {...props} data-testid='asset-info-disabled'>
        <Tooltip appendTo={document.body} className={'embed-error'} content='This feature is not available in your plan.' position='top' variantType='light'>
          <Icon icon="Docs" size='medium' />
        </Tooltip>
      </span>
    )
  }
  return null;
}