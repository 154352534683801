import React, { Component } from 'react'
import { Treebeard, decorators } from 'react-treebeard'
import InfiniteScroll from 'react-infinite-scroll-component'
import AutoSizer from "react-virtualized-auto-sizer";
import EmptyState from '../EmptyState/EmptyState'
import {
  DefaultHeader,
  DefaultLoading,
  DefaultToggle,
  InfiniteScrollLoader,
  InitialLoader,
  Spinner
} from './CustomDecorators';

export type treeViewProps = {
  // header: string | React.ReactNode | Function
  // scrollableTarget?:
  fetchMoreRootData?: Function
  // onClickHeader?: Function
  fetchMoreNestedData?: Function
  onToggle?: Function
  initialState?: any
  canFetch?: boolean
  locationState?: any
  customDecorators?: any
  customStyles?: any
  asyncClassName?: string
  // height?: any
  limitForRoot?: number
  nestedFolderLimit?: number
  emptyHeading?: string
}

const defaultStyles = {
  tree: {
    base: { backgroundColor: 'transparent' },
    node: {
      header: {
        title: {
          color: '#010101',
          lineHeight: '24px',
          verticalAlign: 'middle',
        },
      },
      activeLink: {
        backgroundColor: 'transparent',
      },
    },
  },
}

class TreeView extends Component<treeViewProps, any> {
  state = {
    data: {
      name: 'rootNode',
      toggled: true,
      children: [],
      loading: true,
    },
    skip: 0,
    cursor: {},
    totalCounts: null,
    hasMore: true,
  }
  // isRootNode = null
  canFetch = typeof this.props.canFetch === 'boolean' ? this.props.canFetch : true
  locationState = this.props.locationState && this.props.locationState.data
  initialState = this.props.initialState && this.props.initialState.data
  nestedFolderLimit = this.props.nestedFolderLimit ? this.props.nestedFolderLimit : 5
  /*
  loadmore on scroll will get called only when scroll reaches to the bottom
  so set limit initially so its overflow data of its parent
   */
  limitForRoot = this.props.limitForRoot ? this.props.limitForRoot : 50


  async componentDidMount() {
    try {
      if (this.locationState) {
        const stateData = this.props.locationState
        this.updateCbRefrence(stateData.data)
        this.setState({ ...stateData })
        return
      }
      if (!this.canFetch) {
        const stateData = this.props.initialState
        this.updateOnToggleRef(stateData.data)
        this.setState({ ...stateData })
        return
      }
      await this.fetchInitRootData()
    } catch (error) {
      console.log('error', error)
    }
  }

  updateCbRefrence = (node) => {
    node.onToggle = () => {
      this.onToggle(node, false)
    }

    if (Array.isArray(node.children)) {
      node.children.forEach((child, index) => {
        if (child.id === 'loadmore') {
          const loadMoreNode = node.children[index]
          node.children.splice(index, 1, {
            ...loadMoreNode,
            onClick: () => this.handleLoadMoreClick(node),
          })
        }
        this.updateCbRefrence(child)
      })
    }
  }

  updateOnToggleRef = (node: any = {}) => {
    node.onToggle = () => {
      this.onToggle(node, false)
    }
    if (Array.isArray(node.children)) {
      node.children.forEach((child) => {
        this.updateOnToggleRef(child)
      })
    }
  }

  fetchInitRootData = async () => {
    try {
      const { data, totalCounts } = await this.props.fetchMoreRootData({
        skip: 0,
        limit: this.limitForRoot,
      })

      const children = data.map((object) => ({
        name: object.name,
        uid: object.uid,
        loading: true,
        children: [],
        root: true,
      }))

      const treeObject = { name: 'rootNode', toggled: true, children }
      this.updateOnToggleRef(treeObject)

      this.setState({
        data: treeObject,
        totalCounts,
        hasMore: true,
        cursor: {},
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  fetchMoreRootData = async () => {
    try {
      if (this.state.data.children.length >= this.state.totalCounts) {
        this.setState({ hasMore: false })
        return
      }
      const { skip, data } = this.state
      const response = await this.props.fetchMoreRootData({
        skip: skip + this.limitForRoot,
        limit: this.limitForRoot,
      })
      const children = response.data.map((object) => ({
        name: object.name,
        uid: object.uid,
        loading: true,
        children: [],
        root: true,
      }))

      const treeObject = { ...data, children: [...data.children, ...children] }
      this.updateOnToggleRef(treeObject)


      this.setState({
        skip: skip + this.limitForRoot,
        data: treeObject,
        totalCounts: response.totalCounts,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  handleLoadMoreClick = async (node) => {
    try {
      const { data } = this.state
      const prevChildren = node.children.filter((object) => object.id !== 'loadmore')
      let skip = node.skip + this.nestedFolderLimit

      const response = await this.props.fetchMoreNestedData({
        uid: node.uid,
        skip,
        limit: this.nestedFolderLimit,
      })

      let nextChildren = response.data.map((asset) => ({
        name: asset.name,
        uid: asset.uid,
        loading: true,
        children: [],
      }))

      const loadMoreObj = {
        name: 'Load More',
        id: 'loadmore',
        toggled: false,
        onClick: () => this.handleLoadMoreClick(node),
      }

      const treeObject = [...prevChildren, ...nextChildren]
      this.updateOnToggleRef(treeObject)
      node.children = treeObject

      if (response.totalCounts > skip + this.nestedFolderLimit) {
        node.children.push(loadMoreObj)
      }
      node.skip = skip
      this.setState({ data: Object.assign({}, data) })
    } catch (error) {
      console.log('error', error)
    }
  }

  onToggle = (node, _toggled) => {

    if (node && node.id === 'loadmore') {
      return
    }

    /*
     ** if node not active and not open -> first case: open it n mark as active
     ** else if node active then
     ** open it not open else close it
     */
    if (node.children && !node.active && !node.toggled) {
      node.toggled = true
    } else if (node.children && node.active) {
      if (node.toggled) {
        node.toggled = false
      } else {
        node.toggled = true
      }
    }

    const { cursor, data }: any = this.state
    if (cursor) {
      cursor.active = false
    }

    node.active = true

    /*
     ** Fetch only when node.toggled is true
     ** In case of this.canFetch true
     ** if node.toggled true -> call ontoggle method after fetch
     ** of else block will call on toggle
     ** In case of this.canFetch false
     ** else block will only get called with toggle
     */
    if (this.canFetch && node.toggled) {
      this.setState({ cursor: node, data: Object.assign({}, data) }, async () => {
        try {
          const { cursor, data }: any = this.state
          if (node.loading) {
            const response = await this.props.fetchMoreNestedData({
              uid: cursor.uid,
              skip: 0,
              limit: this.nestedFolderLimit,
            })

            let initSetChildren = response.data.map((object) => ({
              name: object.name,
              uid: object.uid,
              loading: true,
              children: [],
            }))

            const loadMoreObj = {
              name: 'Load More',
              id: 'loadmore',
              toggled: false,
              onClick: () => this.handleLoadMoreClick(node),
            }

            this.updateOnToggleRef({ children: initSetChildren })
            cursor.children = [...initSetChildren]

            if (response.totalCounts > this.nestedFolderLimit) {
              cursor.skip = 0
              cursor.children.push(loadMoreObj)
            }

            cursor.loading = false
            this.setState({ cursor, data: Object.assign({}, data) })
          }
          if (this.props.onToggle) {
            this.props.onToggle({
              uid: node.uid,
              ...this.state,
            })
          }
        } catch (error) {
          console.log('onToggle -> error', error)
        }
      })
    } else {
      this.setState({ cursor: node, data: Object.assign({}, data) })
      if (this.props.onToggle) {
        this.props.onToggle({
          uid: node.uid,
          ...this.state,
          cursor: node,
        })
      }
    }
  }

  render() {
    const {
      Toggle = DefaultToggle,
      Header = DefaultHeader,
      Loading = DefaultLoading,
    } = this.props.customDecorators || {}
    const styles = this.props.customStyles || defaultStyles

    /**
     * This enables infinite scrolling at root level, fetch data str async and
     * update its root and children, further add loadmore button for nested children
     **/
    if (this.canFetch) {
      return (
        <div style={{ height: '85vh' }}>
          <AutoSizer disableWidth={true}>
            {({ height }) => {
              return (
                <div className={this.props.asyncClassName}>
                  <InfiniteScroll
                    height={height}
                    dataLength={this.state.data.children.length}
                    next={this.fetchMoreRootData}
                    hasMore={this.state.hasMore}
                    loader={<InfiniteScrollLoader />}
                    // scrollableTarget="scrollableDivforAsyncTree"
                    hasChildren={true}
                  >
                    {this.state.totalCounts === 0 ? (
                      this.props.customDecorators && this.props.customDecorators.EmptyState ? (
                        this.props.customDecorators.EmptyState
                      ) : (
                        <EmptyState
                          heading={this.props.emptyHeading}
                          displayImage={false}
                          subType="small"
                          type="tertiary"
                        />
                      )
                    ) : (
                      <Treebeard
                        data={this.state.data}
                        onToggle={this.onToggle}
                        decorators={{
                          ...decorators,
                          Toggle,
                          Header,
                          Loading:
                            typeof this.state.totalCounts === 'number' ? Spinner : InitialLoader,
                        }}
                        style={styles}
                      />
                    )}
                  </InfiniteScroll>
                </div>
              )
            }}
          </AutoSizer>
        </div>
      )
    }

    /**
     * This uses preexisting data and render treeview str, does not have infinite scrolling
     * and loadmore for nested children
     **/
    return (
      <div className="tree__items">
        {/* <div onClick={this.onClickHeader}>{this.props.header}</div> */}
        {this.state.totalCounts === 0 ? (
          this.props.customDecorators && this.props.customDecorators.EmptyState ? (
            this.props.customDecorators.EmptyState
          ) : (
            <EmptyState
              heading={this.props.emptyHeading}
              displayImage={false}
              subType="small"
              type="tertiary"
            />
          )
        ) : (
          <Treebeard
            data={this.state.data}
            onToggle={this.onToggle}
            decorators={{ ...decorators, Toggle, Header, Loading }}
            style={styles}
          />
        )}
      </div>
    )
  }
}

export default TreeView
