//@ts-nocheck

import React from 'react'
import AssetImage from '../../SelectImage/GridView/AssetImage/AssetImage';
import Dropable from '../Dropable';
import CreateFolder from '../CreateFolder';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../SelectImage/GridView/Loader';
import '../index.css';

export function GridComponent({ state, handleClick, handleUpload, updateSelection, editor, folderUid, setState, multiple, size, fileAccept }) {
  return state.assets.map(asset => (
    asset.new ? <CreateFolder key={'create-folder'} editor={editor} type='grid' folderUid={folderUid} setUploading={setState} /> :
      <Dropable
        key={asset.uid}
        config={{
          multiple,
          onDrop: handleUpload,
          noDragEventsBubbling: true,
          noClick: true,
          ...(size?.max && { maxSize: size?.max * (1024 * 1024) }),
          ...(size?.min && { minSize: size?.min * (1024 * 1024) })
        }}
        nativeConfig={{ accept: fileAccept.join() }}
      >
        <AssetImage updateSelection={updateSelection} checked={state.selectedImage.uid === asset.uid} asset={asset} handleClick={handleClick}></AssetImage>
      </Dropable>
  )
  )
}

export function ThumbnailView({ state, handleClick, loadMoreGrid, handleUpload, editor, folderUid, setState, multiple, size, fileTypes, only }: any) {
  const fileAccept = fileTypes?.split(',')?.map(ele => `.${ele}`) || []
  if (only) {
    fileAccept.push(only + '/*');
  }
  const updateSelection = (_currentCheckStatus, asset) => {
    if (asset && asset.is_dir) handleClick(asset);
  }

  return (
    <div id='scrollableDiv' style={{ height: 265, overflow: 'auto' }}>
      <InfiniteScroll
        loader={<Loader />}
        dataLength={state.assets.length}
        hasMore={state.assets.length < state.count}
        next={loadMoreGrid}
        style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
        scrollableTarget='scrollableDiv'
      >
        {state.loading && <Loader />}
        <CreateFolder key={'create-folder'} editor={editor} type='grid' folderUid={folderUid} setUploading={setState} />
        <GridComponent setState={setState} editor={editor} state={state} handleClick={handleClick} handleUpload={handleUpload} updateSelection={updateSelection} folderUid={folderUid} multiple={multiple} size={size} fileAccept={fileAccept} />
      </InfiniteScroll>
    </div>
  )
}