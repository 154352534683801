import React, { useState, useEffect } from 'react'
import Icon from '../Icon2/Icon'
import { Truncate } from '../Truncate/Truncate'
import './TagAsSelect.css'

interface ITagList {
  label: string | React.ReactNode
  value?: string
}

interface ITagAsSelect {
  tags: Array<ITagList>
  updateTag: (tagToUpdate: string[]) => void
  openModalCb: (callback: any) => any
  showCount: boolean
  showEmptyState: boolean
  placeholder?: string
  showSingleItem?: boolean
  error?: Boolean
  width?: string
  seeMoreCount?: number
  selectText?: string
  tagName?: string
  showTooltip?: boolean
  maxCount?: number
  testId?: string
}

function TagAsSelect(props: ITagAsSelect) {
  const tags = props.tags && props.tags.length ? props.tags.map((t: any) => t.label) : []
  let [controlledTags, setControlledTags] = useState([])
  const [showMore, setShowMore] = useState(false)
  const tagName = props.tagName || 'Content Type'

  useEffect(() => {
    setControlledTags(props.tags && props.tags.length ? props.tags : [])
  }, [tags.join(','), props.tags.map((el) => el.value).join(',')])

  const handleClick = (e: any, index: any) => {
    e.stopPropagation()
    const node = e.target
    if (node.className === 'Select__tag__item__name') {
      props.openModalCb((data) => {
        props.updateTag(data)
      })
    }
    if (node.id === 'remove_tag' || node.parentNode.id === 'remove_tag') {
      let filteredTags = controlledTags.filter((tag, i) => i !== index)
      props.updateTag(filteredTags)
    }
  }

  let indexCount: any = showMore ? controlledTags.length : props.seeMoreCount
  return (
    <>
      {controlledTags.length > 0 ? (
        <div
          data-test-id={props.testId}
          className={`${controlledTags.length > 0 ? 'Select__tag' : ''} ${props.error ? 'Select__tag--error' : ''}`}
          style={{ maxWidth: props.width }}
          onClick={(e: any) => {
            e.stopPropagation()
            props.openModalCb((data) => {
              props.updateTag(data)
            })
          }}>
          <div className={`Select__tag__container`}>
            {!props.showCount ? (
              controlledTags.length > 0 &&
              controlledTags.map((tag: ITagList, index: number) => {
                const label: any = tag.label
                return (
                  index < indexCount && (
                    <div key={index} className={`Select__tag__items`} onClick={(e) => handleClick(e, index)}>
                      <div className="Select__tag__item">
                        {props.showTooltip ? (
                          <div className="Select__tag__item__name">
                            <Truncate maxChar={props.maxCount}>{label}</Truncate>
                          </div>
                        ) : (
                          <div className="Select__tag__item__name">{label}</div>
                        )}

                        <a className="Select__tag__item__close" id="remove_tag">
                          <Icon icon="CancelSec" id="remove_tag" />
                        </a>
                      </div>
                    </div>
                  )
                )
              })
            ) : controlledTags.length > 0 ? (
              props.showSingleItem ? (
                <div className={`Select__tag__items`} onClick={(e) => handleClick(e, 0)}>
                  <div className="Select__tag__item">
                    {props.showTooltip ? (
                      <div className="Select__tag__item__name">
                        <Truncate maxChar={props.maxCount}>
                          {controlledTags && controlledTags[0] && controlledTags[0].label}
                        </Truncate>
                      </div>
                    ) : (
                      <div className="Select__tag__item__name">
                        {controlledTags && controlledTags[0] && controlledTags[0].label}
                      </div>
                    )}
                    <a
                      className="Select__tag__item__close"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        props.updateTag([])
                      }}
                      id="remove_tag">
                      <Icon icon="CancelSec" id="remove_tag" />
                    </a>
                  </div>
                </div>
              ) : (
                <div className={`Select__tag__items`} onClick={(e) => handleClick(e, 0)}>
                  <div className="Select__tag__item">
                    <div className="Select__tag__item__name">
                      {controlledTags.length}{' '}
                      {props.selectText
                        ? props.selectText
                        : `${controlledTags.length > 1 ? `${tagName}s` : `${tagName}`}`}
                    </div>
                    <a
                      className="Select__tag__item__close"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        props.updateTag([])
                      }}
                      id="remove_tag">
                      <Icon icon="CancelSec" id="remove_tag" />
                    </a>
                  </div>
                </div>
              )
            ) : (
              <div className={`Select__tag__items`} onClick={(e) => handleClick(e, 0)}>
                <div className="Select__tag__item">
                  <div className="Select__tag__item__name">
                    {controlledTags && controlledTags[0] && controlledTags[0].label}
                  </div>
                  <a
                    className="Select__tag__item__close"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      props.updateTag([])
                    }}
                    id="remove_tag">
                    <Icon icon="CancelSec" id="remove_tag" />
                  </a>
                </div>
              </div>
            )}

            {indexCount > 0 && !props.showCount && indexCount === controlledTags.length && showMore && (
              <div
                className={`Select__tag__items`}
                data-test-id="show-less-button"
                onClick={(e: any) => {
                  e.stopPropagation()
                  setShowMore(false)
                }}>
                <div className="Select__tag__item">
                  <div className="Select__tag__item__name mr-5">{`Show less`}</div>
                </div>
              </div>
            )}
            {!showMore && !props.showCount && controlledTags.length > props.seeMoreCount && (
              <div
                className={`Select__tag__items`}
                data-test-id="show-more-button"
                onClick={(e: any) => {
                  e.stopPropagation()
                  setShowMore(true)
                }}>
                <div className="Select__tag__item">
                  <div className="Select__tag__item__name mr-5">{`+ ${
                    controlledTags.length - props.seeMoreCount
                  } More`}</div>
                </div>
              </div>
            )}
          </div>
          <div className={`Select__tag__action`}>
            {controlledTags.length > 0 && !props.showCount && (
              <>
                <a className="Select__tag__action__items">
                  <Icon
                    className="Cancel__icon"
                    data-test-id="remove-all-button"
                    icon="CancelLarge"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      props.updateTag([])
                      setShowMore(false)
                    }}
                  />
                </a>
                <a className="Select__tag__action__items">
                  <Icon className="Dropdown__icon" icon="DownArrow" height="11" width="12" />
                </a>
              </>
            )}
          </div>
        </div>
      ) : (
        props.showEmptyState && (
          <div
            style={{ maxWidth: props.width }}
            data-test-id="empty-state"
            className={`Select__tag__placeholder flex-v-center flex-justify ${props.error ? 'Select__tag--error' : ''}`}
            onClick={(e: any) => {
              e.stopPropagation()
              props.openModalCb((data) => {
                props.updateTag(data)
              })
            }}>
            <div>{props.placeholder || 'Select Title'}</div>
            <a className="Select__tag__action__items">
              <Icon className="Dropdown__icon" icon="DownArrow" height="11" width="12" />
            </a>
          </div>
        )
      )}
    </>
  )
}

TagAsSelect.defaultProps = {
  showCount: false,
  showEmptyState: false,
  showSingleItem: false,
  seeMoreCount: 3,
  width: '500px',
  showTooltip: false,
  testId: 'cs-tag-as-select'
} as Partial<ITagAsSelect>

export default TagAsSelect
