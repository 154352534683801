//@ts-nocheck
import { Editor, Transforms } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'
import { useSlate } from 'slate-react'

import { createCell } from './createCell'
import { createHeadCell } from './createHeadCell'
import { elementCategories } from '../../elementCategories'
import { TableAddColumnBelowIcon } from '../../../utils/Icon'

const handleAddCell = (editor, row, path) => {
  const newCellPos = path[path.length - 1]
  const previousCell = row.children[newCellPos - 1]

  if (previousCell.type === 'th') {
    Transforms.insertNodes(editor, createHeadCell(), { at: path })
  }
  if (previousCell.type === 'td') {
    Transforms.insertNodes(editor, createCell(), { at: path })
  }
}

export const insertColAfter = (event, { editor }) => {
  const [headCell] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'th'
    },
    mode: 'lowest'
  })
  const [bodyCell] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'td'
    },
    mode: 'lowest'
  })
  let cell
  if (bodyCell && !headCell) {
    cell = bodyCell
  } else if (headCell && !bodyCell) {
    cell = headCell
  } else if (headCell && bodyCell) {
    cell = bodyCell
  }
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })
  if (cell && table) {
    const cellPath = cell[1]
    const newCellPos = cellPath[cellPath.length - 1] + 1
    const tablePath = table[1]

    Array.from(table[0].children).forEach((tableChild, tableChildPos) => {
      Array.from(tableChild.children).forEach((row, rowPos) => {
        if (row.type === 'trgrp') {
          const rowgrp = row
          const rowGrpPos = rowPos
          rowgrp.children.forEach((row, rowPos) => {
            const path = [...tablePath, tableChildPos, rowGrpPos, rowPos, newCellPos]
            handleAddCell(editor, row, path)
          })
          return
        }
        const path = [...tablePath, tableChildPos, rowPos, newCellPos]
        handleAddCell(editor, row, path)
      })
    })
    let colWidths = table[0].attrs.colWidths.slice()
    colWidths.splice(newCellPos, 0, 250)
    Transforms.setNodes(
      editor,
      {
        attrs: {
          ...table[0].attrs,
          colWidths,
          cols: table[0].attrs.cols + 1
        }
      },
      { at: table[1] }
    )
  }
}
const InsertColAfterButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: insertColAfter,
        isDisable: { func: insertColAfterButtonDisabler, params: { editor } },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}

export const shortcutInsertColAfter = (event, editor, format, registryObj) => {
  insertColAfter(event, { editor })
}

const insertColAfterButtonDisabler = ({ editor }) => {
  const [table] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === 'table'
    },
    mode: 'lowest'
  })

  return !table
}
Registry.register(
  {
    IngressComponent: InsertColAfterButton,
    iconName: <TableAddColumnBelowIcon />,
    toolbar: {
      inSlashToolbar: false
    },
    canCustomize: false,
    shortcut: { key: 'ctrl+shift+right', callback: shortcutInsertColAfter },
    category: elementCategories.TABLE_ACTION,
    title: 'Insert column right',
    isContentStackElement: true
  },
  {
    id: 'table-insert-col-after',
    registry: 'element'
  }
)
