import { camelCase, cloneDeep } from "lodash"
export const attrsProvider = (element) => {
    let elementAttrs = {}
    elementAttrs["data-editor-type"] = "scrte"

    if (element.attrs) {

        if (element.attrs['id']) {
            elementAttrs = {
                ...elementAttrs,
                id: element.attrs['id']
            }
        }
        elementAttrs['className'] = elementAttrs['class-name']
        delete elementAttrs['class-name']
        elementAttrs['className'] = `scrte_${element.type} ${elementAttrs['className'] ? element.attrs['className'].join('') : ''}`
        if (element.attrs['style']) {
            let camelCaseStyle = {}
            let styleCopy = cloneDeep(element.attrs['style'])
            Object.entries(styleCopy).forEach(([key, val]) => {
                camelCaseStyle[camelCase(key)] = val
            })
            elementAttrs = {
                ...elementAttrs,
                style: { ...camelCaseStyle }
            }
        }

    }
    let result = { ...element.attrs, ...elementAttrs }
    delete result.dirty
    delete result["redactor-attributes"]
    return result
}
