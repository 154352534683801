//@ts-nocheck
import React, { useState } from 'react'
import { Transforms, Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import { ModalBody, ModalHeader, ModalFooter, Button, ButtonGroup, Icon, Field, TextInput } from '../../../../../../../../index';

//@ts-ignore
import styles from './style.module.css'

export const EditCaptionAlt = ({ element, editor, ...props }) => {
  const attrs = element?.attrs || {};
  const [state, setState] = useState({
    alt: attrs['asset-alt'] || '',
    caption: attrs['asset-caption'] || ''
  })

  const handleChange = (e) => {
    e.persist();
    const targetValue = e.target.value
    const targetName = e.target.name
    setState(state => ({ ...state, [targetName]: targetValue }));
  }

  const handleFormSubmit = () => {
    const { alt, caption } = state;
    const path = ReactEditor.findPath(editor, element);
    const [node,] = Editor.node(editor, path);
    Transforms.setNodes(editor, {
      attrs: {
        ...(node.attrs as Object || {}),
        'asset-alt': alt,
        'asset-caption': caption
      }
    }, { at: path });
    props.closeModal()
  }

  const { alt, caption } = state;

  return (
    <div>
      <ModalHeader title="Edit Asset" closeModal={props.closeModal} />

      <ModalBody>
        <div className={styles['scrte-form-container']}>
          <Field labelText="Alt text">
            <TextInput value={alt} name="alt" onChange={handleChange} data-testid={'edit_caption_alt_alt'}></TextInput>
          </Field>
          <Field labelText="Caption">
            <TextInput
              value={caption}
              type="text"
              placeholder="Enter a caption"
              name="caption"
              onChange={handleChange}
              data-testid={'edit_caption_alt_cap'}
            />
          </Field>
        </div>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button key="cancel" buttonType="light" onClick={() => props.closeModal()} data-testid="asset-edit-properties__cancel">
            Cancel
          </Button>
          <Button
            icon={'SaveWhite'}
            key="add"
            onClick={handleFormSubmit}
            data-testid="asset-edit-properties__submit"
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  )
}
