import React, { useState, useEffect } from 'react'
import { InfiniteScrollTable, Icon } from '../../../../../../../../index'
import CreateFolder from '../CreateFolder';
import { getUsers } from '../../../request';
import styles from './index.module.css'
import { formatTime } from '../../util';

const iconFromType = (contentType) => {
  if (contentType.startsWith('image/')) return null
  else if (contentType.startsWith('application/')) return 'Group'
  else return 'File'
}

const GetIcon = ({ data, editor, folderUid, setState }) => {
  if (data.new) {
    return <CreateFolder editor={editor} folderUid={folderUid} setUploading={setState} />
  }
  return (
    <React.Fragment>
      <span className={styles['list-icon']} style={{ background: `${data.url ? `url(${data.url})` : 'inherit'}` }}>
        {iconFromType(data.content_type) && <Icon size='medium' icon={iconFromType(data.content_type)} />}
      </span>
      {data.name ? data.name : data.filename}
    </React.Fragment>
  )
}


export const ListView = ({ state, fetchOnChange, loadMore, handleClick, loading, editor, folderUid, setState }) => {
  const [users, setUser] = useState({});

  useEffect(() => {
    getUsers({ ...editor.requestProps })
      .then(res => setUser(res))
  }, []);

  const columns = [
    {
      Header: 'Title',
      id: 'title',
      accessor: (data) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <GetIcon editor={editor} data={data} folderUid={folderUid} setState={setState} />
          </div>
        )
      },
      default: false,
      addToColumnSelector: true,
    },
    {
      Header: 'Unique ID',
      accessor: (data) => data.new ? null : data.uid,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
    {
      Header: 'Last Modified By',
      accessor: (data) => data.new ? null : <div className={'last-updated-by'}><span>{users[data.updated_by] || 'Anonymous'}</span> <span className='last-updated-by-time'>{formatTime(data.updated_at)}</span></div>,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
  ]

  return (
    <div>
      <InfiniteScrollTable
        tableHeight={250}
        data={state.assets}
        columns={columns}
        uniqueKey={'uid'}
        fetchTableData={fetchOnChange}
        loadMoreItems={loadMore}
        loading={loading}
        totalCounts={state.count + 1 > state.assets.length ? state.count + 1 : state.assets.length}
        onRowClick={handleClick}
        singleSelectedRowId={state.selectedImage.uid}
        itemStatusMap={state.itemStatusMap}
        columnSelector={false}
        minBatchSizeToFetch={8}
        itemSize={60}
        name={{ singular: 'Asset', plural: 'Assets' }}
      />
    </div>
  )
}
