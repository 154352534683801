import { Registry } from '@react-registry'

export const IFRAME = (el) => {
  return {
    type: 'embed',
    attrs: {
      src: el.getAttribute?.('src')
    },
    children: [
      {
        text: ''
      }
    ]
  }
}

Registry.register(IFRAME, { id: 'IFRAME', registry: 'v2.deserializer' })
