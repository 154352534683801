import React, { useState, useEffect, useRef, useCallback } from 'react'
import TextInput from '../TextInput/TextInput'
import cn from 'classnames'
import Icon from '../Icon2'
import './MultiLevelDropdown.css'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import { components } from 'react-select'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import Tooltip from '../Tooltip/Tooltip'


export type IList = {
  label: String | React.ReactNode
  action?: Function
  default?: boolean
  searchKey?: string
  value?: string | React.ReactNode | Element
  showAsActive?: Boolean
  textLabel?: String //required only when label is html
  disable?: boolean
  closeDropdownOnSelect?: boolean
  stacks: Array<IList>
}

export type IPrimaryList = {
  label: String | React.ReactNode
  action?: Function
  default?: boolean
  searchKey?: string
  value?: string | React.ReactNode | Element
  showAsActive?: Boolean
  textLabel?: String //required only when label is html
  disable?: boolean
  closeDropdownOnSelect?: boolean
  stacks: Array<IList>
  actionTooltip?: boolean
  actionTooltipProps: { list: Array<TooltipList> }

}


export type TooltipList = {
  label: String | React.ReactNode
  action?: Function
  title?: string
}


type IMultiLevelDropdown = {
  primaryList: Array<IPrimaryList>
  secondaryList: Array<IList>
  tertiaryList: Array<IList>
  type: 'click' | 'hover' | 'select'
  children?: React.ReactNode | Element
  withSearch?: Boolean
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  className?: string
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  closeAfterSelect?: boolean
  title?: string
  searchPlaceholder?: string
  onListClick?: (data?: any) => void
  viewAs?: 'label' | 'value'
  primaryLabel?: string
  secondaryLabel?: string
  tertiaryLabel?: string
  emptyPlaceholder?: string
  arrowSecondary?: boolean
  primaryListLoader?: boolean
  secondaryListLoader?: boolean
  onListHover?: (data?: any) => void
  clickState?: boolean
  hoverState?: boolean
  adjustWidthForContent?: boolean
  onChange: Function
  value: any
  limit?: number
  loadMoreOptions: (loadmoreArgs: LoadMoreArgsProps) => LoadMoreOptReturnProps
  initialOptions?: Array<InitArrayProps>
  defaultOptions?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  loadMoreList: Function
  itemStatusMap: object
  defaultOptionList: Array<IList>
  fetchMoreListOptions?: boolean
  totalCount: number
  secondaryArrow?: boolean
  OnHoverSubNestedMenu?: boolean
  setSelectedStack?: string
  withPrimarySearch?: boolean
  searchLimit?: number
}

type IMultiLevelDropdownOption = {
  primaryList: Array<IPrimaryList>
  secondaryList: Array<IList>
  tertiaryList: Array<IList>
  children?: React.ReactNode | Element
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  classNames?: string
  closeAfterSelect?: boolean
  title?: string
  searchPlaceholder?: string
  arrowSecondary?: boolean
  primaryLabel?: string
  secondaryLabel?: string
  tertiaryLabel?: string
  emptyPlaceholder?: string
  onListClick?: (data?: any) => void
  viewAs?: 'label' | 'value'
  withSearch?: Boolean
  primaryListLoader?: boolean
  secondaryListLoader?: boolean
  onListHover?: (data?: any) => void
  clickState?: boolean
  hoverState?: boolean
  adjustWidthForContent?: boolean
  onChange: Function
  value: any
  limit?: number
  loadMoreOptions: (loadmoreArgs: LoadMoreArgsProps) => LoadMoreOptReturnProps
  initialOptions?: Array<InitArrayProps>
  defaultOptions?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  loadMoreList: Function
  itemStatusMap: object
  defaultOptionList: Array<IList>
  fetchMoreListOptions?: boolean
  totalCount: number
  secondaryArrow?: boolean
  OnHoverSubNestedMenu?: boolean
  setSelectedStack?: string

}

type ISelectDropdown = {
  primaryList: Array<IPrimaryList>
  secondaryList: Array<IList>
  tertiaryList: Array<IList>
  children?: React.ReactNode | Element
  withSearch?: Boolean
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  classNames?: string
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  closeAfterSelect?: boolean
  title?: string
  searchPlaceholder?: string
  onListClick?: (data?: any) => void
  viewAs?: 'label' | 'value'
  primaryLabel?: string
  secondaryLabel?: string
  tertiaryLabel?: string
  emptyPlaceholder?: string
  arrowSecondary?: boolean
  primaryListLoader?: boolean
  secondaryListLoader?: boolean
  onListHover?: (data?: any) => void
  clickState?: boolean
  hoverState?: boolean
  adjustWidthForContent?: boolean
  onChange: Function
  value: any
  limit?: number
  loadMoreOptions: (loadmoreArgs: LoadMoreArgsProps) => LoadMoreOptReturnProps
  initialOptions?: Array<InitArrayProps>
  defaultOptions?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  loadMoreList: Function
  itemStatusMap: object
  defaultOptionList: Array<IList>
  fetchMoreListOptions?: boolean
  totalCount: number,
  selectedAsyncValue?: string,
  selectedOrganization?: string
  secondaryArrow?: boolean
  OnHoverSubNestedMenu?: boolean
  selectedCurrentBranch?: string
  topLabelValue?: string
  clickedOrganisation?: string
  setSelectedStack?: string
  withPrimarySearch?: boolean
  searchLimit?: number
}

type LoadMoreArgsProps = {
  search: string
  skip: number
  limit: number
  prevOptions: any
}
type InitArrayProps = {
  label: String,
  value: String
}
type LoadMoreOptReturnProps = {
  data: any,
  hasMore: boolean
}
const emptyObj: any = {}
export function useComponentVisible(initialIsVisible: Boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref: any = useRef(null)

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    }
  }

  const handleClickOutside = (event: any) => {
    if (
      (ref.current && !ref.current.contains(event.target)) ||
      document.activeElement === document.getElementById('sidebar-extension') ||
      document.activeElement.clientWidth == 315
    ) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

const getDropDownMenuType = (dropDownType) => {
  if (dropDownType === 'secondary') {
    return 'MultiLevelDropdown__menu--secondary'
  } else if (dropDownType === 'tertiary') {
    return 'MultiLevelDropdown__menu--tertiary'
  } else if (dropDownType === 'quaternary') {
    return 'MultiLevelDropdown__menu--quaternary'
  } else {
    return 'MultiLevelDropdown__menu--primary'
  }
}

const getDefaultValue = (list, viewAs) => {
  let default_element: any
  list && list.length > 0 && list.map((list_element: any) => {
    if (list_element.hasOwnProperty('default')) {
      if (list_element.default) {
        default_element = list_element
      }
    }
  })
  if (default_element) {
    if (default_element[viewAs]) {
      return default_element[viewAs]
    } else {
      return default_element.label ? default_element.label : default_element.value
    }
  }
}

const getDefaultLabelText = (list, viewAs) => {
  let default_element: any
  list && list.length > 0 && list.map((list_element: any) => {
    if (list_element.hasOwnProperty('default')) {
      if (list_element.default) {
        default_element = list_element
      }
    }
  })
  if (default_element) {
    if (default_element[viewAs]) {
      return default_element.textLabel || default_element[viewAs]
    } else {
      return default_element.label ? default_element.textLabel || default_element.label : default_element.value
    }
  }
}

const LoadingComponent = () => {
  return (
    <SkeletonTile
      numberOfTiles={7}
      tileHeight={12}
      tileWidth={200}
      tileBottomSpace={18}
      tileTopSpace={18}
      tileleftSpace={15}
      tileRadius={10}
    />
  )
}
const Skeleton = () => {
  return (
    <SkeletonTile numberOfTiles={1} tileHeight={15} tileWidth={170} tileBottomSpace={10} tileTopSpace={0} tileleftSpace={5} tileRadius={0} />
  );
}


const Option = (props: any) => {
  return <components.Option {...props} />
}

const MenuList = wrapMenuList((props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.isLoading && <SkeletonLoader size={2} />}
    </components.MenuList>
  )
})

const SkeletonLoader = ({ size = 2 }) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={12}
                tileWidth={145}
                tileRadius={6}
                tileBottomSpace={12}
                tileTopSpace={12}
                tileleftSpace={20}
              />
            </div>
          )
        })}
    </div>
  )
}

const customComponents = () => {
  return {
    Option,
    MenuList,
  }
}

const HeaderWithLabel = (props) => {
  return <div className="MultiLevelDropdown__header__label--top-label  mb-7">{props.topLabelValue}</div>
}
const SelectedDataLabel = (props) => {
  return (
    <div className="MultiLevelDropdown__header__label__header__value">
      {props.selectedData}
    </div>
  )
}

const RenderIconView = (props) => {
  return (
    <Icon
      className={`ml-5 MultiLevelDropdown__chevron ${props.isComponentVisible === true ? 'toggle-icon' : ''}`}
      icon={`${props.arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
      size="original"
    />
  )
}
const SelectMultiLevelDropDown: React.FunctionComponent<ISelectDropdown> = (props) => {

  const [selectedOrg, setSelectedOrg] = useState(getDefaultValue(props.primaryList, props.viewAs))
  const [selectedData, setSelectedData] = useState(getDefaultValue(props.secondaryList, props.viewAs))
  const [selectedDataText, setSelectedDataText] = useState(getDefaultLabelText(props.secondaryList, props.viewAs))
  const [selectedBranch, setSelectedBranch] = useState(getDefaultValue(props.tertiaryList, props.viewAs))
  const [branchesVisible, setStackBranchesVisible] = useState(true)

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const [activeLink, setActiveLink] = useState() // active Link
  const [searchText, setSearchText] = useState('') // Secondary search state
  const [searchList, setSearchList] = useState([]) // Secondary search list data
  const [primarySearch, setPrimarySearch] = useState('') // Primary search state
  const [primarysearchList, setPrimarySearchList] = useState([]) // Primary search list data
  const [branchSearch, setbranchSearch] = useState('') //Branch search state
  const [branchsearchList, setbranchSearchList] = useState([]) // Branch search list data
  const [selectedSecondaryData, setSelectedSecondaryData] = useState(emptyObj)

  useEffect(() => {
    setSelectedData(getDefaultValue(props.secondaryList, props.viewAs))
  }, [props.secondaryList])

  useEffect(() => {
    setSelectedOrg(getDefaultValue(props.primaryList, props.viewAs))
  }, [props.primaryList])

  useEffect(() => {
    setSelectedBranch(getDefaultValue(props.tertiaryList, props.viewAs))
  }, [props.tertiaryList])

  useEffect(() => {
    if (searchText.length === 0) {
      setSearchList([])
    }
  }, [searchText])


  const handleChange = (evt: any) => {
    // evt.stopPropagation()
    // evt.preventDefault()
    const { secondaryList, onListClick, selectedAsyncValue } = props;
    if (evt.target.value.length > 0) {
      let newSearchList: any = []
      secondaryList.map((el: any) => {
        if (el.hasOwnProperty('searchKey') && typeof el.searchKey === 'string') {
          if (el.searchKey.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        } else if (typeof el.label === 'string') {
          if (el.label.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        }
      })
      setSearchList(newSearchList)
    }
    setSearchText(evt.target.value)
  }

  const handleSelect = (itemAction, index) => {
    const { primaryList, secondaryList, onListClick, onListHover, viewAs, clickState, hoverState, closeAfterSelect } = props;
    if (searchText.length > 0) {
      let cbValue
      let chkSearchList = [...searchList] // copy any array using spread operator
      if (chkSearchList[index][viewAs]) {
        if (chkSearchList[index][viewAs] !== selectedData) {
          cbValue = chkSearchList[index][viewAs]
          setSelectedData(chkSearchList[index][viewAs])
          itemAction()
          setIsComponentVisible(!isComponentVisible)
        }

      } else {
        if (chkSearchList[index].label !== selectedData && !chkSearchList[index].value) {
          cbValue = chkSearchList[index].label
          setSelectedData(chkSearchList[index].label)
          if (chkSearchList[index].textLabel) {
            setSelectedDataText(chkSearchList[index].textLabel)
            itemAction()
            setIsComponentVisible(!isComponentVisible)
          }
        }
        else if (chkSearchList[index].value && chkSearchList[index].value !== selectedData) {
          cbValue = chkSearchList[index].value
          setSelectedData(chkSearchList[index].value)
          itemAction()
          setIsComponentVisible(!isComponentVisible)
        }

      }
      if (clickState && onListClick !== undefined) {
        onListClick(primaryList[index])
      }
      if (hoverState && onListHover !== undefined) {
        onListHover(primaryList[index])
      }
      setSearchText('')
      if (primarySearch && primarySearch.length > 0) {
        setPrimarySearch('')
        setPrimarySearchList([])
      }
      // if (chkSearchList[index].label !== selectedData) {
      //   setSelectedData(chkSearchList[index].label)
      //   if (chkSearchList[index].textLabel) {
      //     setSelectedDataText(chkSearchList[index].textLabel)
      //   }
      //   itemAction()
      //   onListClick && onListClick(chkSearchList[index])
      //   setIsComponentVisible(!isComponentVisible)
      // }
      // setSearchText('')
      // if (primarySearch && primarySearch.length > 0) {
      //   setPrimarySearch('')
      //   setPrimarySearchList([])
      // }
    } else {
      let cbValue
      if (secondaryList[index][viewAs]) {
        if (secondaryList[index][viewAs] !== selectedData) {
          cbValue = secondaryList[index][viewAs]
          setSelectedData(secondaryList[index][viewAs])
          itemAction()
          setIsComponentVisible(!isComponentVisible)
        }

      } else {
        if (secondaryList[index].label !== selectedData && !secondaryList[index].value) {
          cbValue = secondaryList[index].label
          setSelectedData(secondaryList[index].label)
          if (secondaryList[index].textLabel) {
            setSelectedDataText(secondaryList[index].textLabel)
            itemAction()
            setIsComponentVisible(!isComponentVisible)
          }
        }
        else if (secondaryList[index].value && secondaryList[index].value !== selectedData) {
          cbValue = secondaryList[index].value
          setSelectedData(secondaryList[index].value)
          itemAction()
          setIsComponentVisible(!isComponentVisible)
        }

      }
      if (clickState && onListClick !== undefined) {
        onListClick(primaryList[index])
      }
      if (hoverState && onListHover !== undefined) {
        onListHover(primaryList[index])
      }
      if (primarySearch && primarySearch.length > 0) {
        setPrimarySearch('')
        setPrimarySearchList([])
      }
    }
    if (closeAfterSelect) {
      setIsComponentVisible(!isComponentVisible)
    }
  }

  const dropdownMenuHandler = (e, index, itemAction) => {
    const { OnHoverSubNestedMenu, selectedOrganization, clickedOrganisation } = props;
    let findSubMenuSelectedNodes = document.querySelectorAll('.MultiLevelDropdown__menu__list__item.MultiLevelDropdown__submenu__list__item--selected.MultiLevelDropdown__menu__list__item--active')
    for (let i = 0; i < findSubMenuSelectedNodes.length; i++) {
      findSubMenuSelectedNodes[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected')
      findSubMenuSelectedNodes[i].classList.remove('MultiLevelDropdown__menu__list__item--active')
    }
    if (e && e.currentTarget) {
      setSelectedOrg(e.currentTarget.innerText)
    }
    let selectedListName = e.currentTarget.innerText
    itemAction()
    if (OnHoverSubNestedMenu) {
      let findShowSubMenuElement = document.querySelectorAll('.MultiLevelDropdown__submenu__list.MultiLevelDropdown__submenu--nested__list.show')
      for (let i = 0; i < findShowSubMenuElement.length; i++) {
        findShowSubMenuElement[i].classList.remove('show');
        findShowSubMenuElement[i].classList.add('hide');
      }
    }

    if (selectedListName !== selectedOrg) {
      let findSelectedClass = document.querySelectorAll('.MultiLevelDropdown__submenu__list__item--selected')
      let findActiveClass = document.querySelectorAll('.MultiLevelDropdown__menu__list__item--active')
      for (let i = 0; i < findSelectedClass.length; i++) {
        findSelectedClass[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected');
      }
      for (let i = 0; i < findActiveClass.length; i++) {
        findActiveClass[i].classList.remove('MultiLevelDropdown__menu__list__item--active');
      }
      let dataID = e.currentTarget.getAttribute('data-id')
      if (dataID == index) {
        e.currentTarget.classList.add('MultiLevelDropdown__submenu__list__item--selected')
        e.currentTarget.classList.add('MultiLevelDropdown__menu__list__item--active')
        setActiveLink(index)
      }
    }
  }

  const handlerNestedMenu = (event, index, secondaryList, branches, key, clickedStack) => {
    event.stopPropagation()
    if (key == 'stack') {
      setStackBranchesVisible(!branchesVisible)
      setSelectedSecondaryData(secondaryList[index])
      // Find PreviousLy Selected Stack Branches and Remove Selection
      let findSubMenuSelectedNodes = document.querySelectorAll('.MultiLevelDropdown__submenu__list__item--withTertiary.MultiLevelDropdown__submenu__list__item--selected.MultiLevelDropdown__menu__list__item--active')
      // console.log('findSubMenuSelectedNodes==', findSubMenuSelectedNodes)
      for (let i = 0; i < findSubMenuSelectedNodes.length; i++) {
        findSubMenuSelectedNodes[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected')
        findSubMenuSelectedNodes[i].classList.remove('MultiLevelDropdown__menu__list__item--active')
      }
      // Find Previous Stack Branches Opens
      let findInnerSelectedClass = document.querySelectorAll('.MultiLevelDropdown__submenu__list.MultiLevelDropdown__submenu--nested__list.show')
      for (let i = 0; i < findInnerSelectedClass.length; i++) {
        findInnerSelectedClass[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected')
        findInnerSelectedClass[i].classList.remove('MultiLevelDropdown__menu__list__item--active')
      }
      // Find Previous Stack Branches Opens
      let findSelectedActiveClass = document.querySelector('.MultiLevelDropdown__submenu__list__item--withTertiaryParent.MultiLevelDropdown__submenu__list__item--selected.MultiLevelDropdown__menu__list__item--active')
      let selectedDataParentID = findSelectedActiveClass ? findSelectedActiveClass.getAttribute('data-id') : null
      if (selectedDataParentID == undefined || selectedDataParentID != index) {
        let previousSelectedLi = document.querySelectorAll('.MultiLevelDropdown__submenu__list__item--withTertiary.MultiLevelDropdown__submenu__list__item--selected.MultiLevelDropdown__menu__list__item--active')
        for (let i = 0; i < previousSelectedLi.length; i++) {
          previousSelectedLi[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected')
          previousSelectedLi[i].classList.remove('MultiLevelDropdown__menu__list__item--active')
        }

        let findSelectedClass = document.querySelectorAll('.MultiLevelDropdown__submenu__list__item--withTertiaryParent.MultiLevelDropdown__submenu__list__item--selected.MultiLevelDropdown__menu__list__item--active')
        for (let i = 0; i < findSelectedClass.length; i++) {
          findSelectedClass[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected')
          findSelectedClass[i].classList.remove('MultiLevelDropdown__menu__list__item--active')
          // console.log('findSelectedClass[i]===', findSelectedClass[i], findSelectedClass[i].children)
          if (findSelectedClass[i].children && findSelectedClass[i].children.length > 0) {
            findSelectedClass[i].children[3].classList.add('hide')
          }

        }
        event.target.classList.add('MultiLevelDropdown__submenu__list__item--selected')
        event.target.classList.add('MultiLevelDropdown__menu__list__item--active')

        let innerChild = event.target && event.target.children[3]
        if (innerChild) {
          innerChild.classList.remove('hide')
          innerChild.classList.add('show')
        }
        setStackBranchesVisible(!branchesVisible)
        let previousHoveeredChild = event.target.previousSibling && event.target.previousSibling.children[3]
        let nextHoveredLI = event.target.nextSibling
        if (nextHoveredLI) {
          nextHoveredLI.classList.remove('MultiLevelDropdown__submenu__list__item--selected')
          nextHoveredLI.classList.remove('MultiLevelDropdown__menu__list__item--active')
        }
        else if (nextHoveredLI == null && previousHoveeredChild) {
          previousHoveeredChild.classList.remove('show')
          previousHoveeredChild.classList.add('hide')

        }
      }
      else if (findSelectedActiveClass) {
        // console.log('clicked===', findSelectedActiveClass, findSelectedActiveClass.children)
        /****clicked stack then if branches present then  show  */
        // if (findSelectedActiveClass.children.length > 0) {
        //   findSelectedActiveClass.children[3].classList.remove('show')
        //   findSelectedActiveClass.children[3].classList.remove('hide')
        // }
        event.target.classList.add('MultiLevelDropdown__submenu__list__item--selected')
        event.target.classList.add('MultiLevelDropdown__menu__list__item--active')
        let innerChild = event.target && event.target.children[3]
        if (innerChild) {
          innerChild.classList.remove('hide')
          innerChild.classList.add('show')
        }
      }

      let previousHoveredLI = event.target.previousSibling
      let nextLI = event.target.nextSibling
      if (previousHoveredLI) {
        previousHoveredLI.classList.remove('MultiLevelDropdown__submenu__list__item--selected')
        previousHoveredLI.classList.remove('MultiLevelDropdown__menu__list__item--active')

        let previousChild = previousHoveredLI && previousHoveredLI.children[3]
        if (previousChild) {
          previousChild.classList.remove('show')
          previousChild.classList.add('hide')
        }
      }
      else if (previousHoveredLI == null && nextLI) {
        nextLI.classList.remove('MultiLevelDropdown__submenu__list__item--selected')
        nextLI.classList.remove('MultiLevelDropdown__menu__list__item--active')
      }
    }
    else if (key == 'branch') {
      let currentDataId = event.target.getAttribute('data-id')
      if (currentDataId != index) {
        let previousSelectedLi = document.querySelectorAll('.MultiLevelDropdown__submenu__list__item--withTertiary.MultiLevelDropdown__submenu__list__item--selected.MultiLevelDropdown__menu__list__item--active')
        for (let i = 0; i < previousSelectedLi.length; i++) {
          previousSelectedLi[i].classList.remove('MultiLevelDropdown__submenu__list__item--selected')
          previousSelectedLi[i].classList.remove('MultiLevelDropdown__menu__list__item--active')
        }
        event.target.classList.add('MultiLevelDropdown__submenu__list__item--selected')
        event.target.classList.add('MultiLevelDropdown__menu__list__item--active')
      }

      let previousHoveredLI = event.target.previousSibling
      if (previousHoveredLI) {
        if (previousHoveredLI.classList) {
          previousHoveredLI.classList.remove('MultiLevelDropdown__submenu__list__item--selected')
          previousHoveredLI.classList.remove('MultiLevelDropdown__menu__list__item--active')
        }
      }
    }
  }

  const handlerNestedSubMenu = (event, index, branches, listAction) => {
    let parentElementValue = event.target.parentNode.parentNode.parentNode.firstChild.nodeValue
    if (parentElementValue) {
      setSelectedData(parentElementValue)
    }
    let cbValue2
    if (branches[index][viewAs]) {
      if (branches[index][viewAs] !== selectedBranch) {
        cbValue2 = branches[index][viewAs]
        setSelectedBranch(branches[index][viewAs])
        setStackBranchesVisible(!branchesVisible)
      }

    } else {
      if (branches[index].label !== selectedBranch && !branches[index].value) {
        cbValue2 = branches[index].label
        setSelectedBranch(branches[index].label)
      }
      else if (branches[index].value && branches[index].value !== selectedBranch) {
        cbValue2 = branches[index].value
        setSelectedBranch(branches[index].value)
        setStackBranchesVisible(!branchesVisible)
      }
    }
    listAction()
    if (branchSearch && branchSearch.length > 0) {
      setbranchSearch('')
      setbranchSearchList([])
    }
    if (searchText && searchList.length > 0) {
      setSearchText('')
      setSearchList([])
    }
    if (primarySearch && primarySearch.length > 0) {
      setPrimarySearch('')
      setPrimarySearchList([])
    }
    setIsComponentVisible(false)
  }

  const allComponents = customComponents()

  const isItemLoaded = (index: any) => !!props.itemStatusMap[index];

  const loadMoreValues = async (startIndex: any, stopIndex: any) => {
    const { loadMoreList } = props;
    // console.log(startIndex, stopIndex, 'load more in');
    const skip = startIndex ? startIndex - 1 : startIndex;
    const limit = stopIndex - skip;
    const fetchDataArgument: any = { skip, limit, startIndex, stopIndex };
    loadMoreList(fetchDataArgument);
  }

  const loadOptions = async (search: any, prevOptions: any, skip, limit) => {
    try {
      let skip = prevOptions.length
      const response: any = await props.loadMoreOptions({ search: search || '', skip, limit: limit || 30, prevOptions })
      return {
        options: response.options,
        hasMore: response.hasMore
      }
    } catch (error) {
      // console.log('loadOptions error', error)
    }
  }

  const RenderWithSearch = (searchPlaceholder, searchText) => {
    return (
      <div className="MultiLevelDropDown__menu__searchBox" onClick={(e) => { e.stopPropagation() }}>
        <Icon className="SearchIcon" icon="Search" size="original" />
        <TextInput
          placeholder={searchPlaceholder || 'Search here...'}
          type="search"
          value={searchText}
          onChange={handleChange}></TextInput>
      </div>
    )
  }

  const RenderWithAsyncSearch = () => {
    const { value, onChange, searchPlaceholder, isClearable, defaultOptions, isSearchable, selectedAsyncValue } = props;
    return (
      <div className="MultiLevelDropDown__menu__searchBox">
        <Icon className="SearchIcon" icon="Search" size="original" />
        <div className="MultiLevelDropDown__async__search">
          <AsyncPaginate
            value={value}
            loadOptions={loadOptions}
            onChange={onChange}
            placeholder={searchPlaceholder || 'Search here...'}
            isClearable={isClearable || false}
            isSearchable={isSearchable}
            defaultOptions={typeof defaultOptions === 'boolean' ? defaultOptions : true}
            components={allComponents}
          />
        </div>
      </div>
    )
  }

  const DisplayList = (index, content, style) => {
    return (
      <div style={style}>
        {props.secondaryListLoader === true ? (
          <div className="MultiLevelDropdown__submenu__loading">{<LoadingComponent />}</div>
        ) :
          <div className="searchList">
            <li
              key={index}
              className={`MultiLevelDropdown__submenu__list__item 
                ${content.value == selectedData ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
                ${content.disable ? 'MultiLevelDropdown__submenu__list__item--disable' : ''} 
                ${content[props.viewAs]
                  ? content[props.viewAs] === selectedData
                    ? 'MultiLevelDropdown__menu__list__item--active'
                    : ''
                  : content.label === selectedData && 'MultiLevelDropdown__menu__list__item--active'
                }
                ${props.withArrow ? 'MultiLevelDropdown__submenu__list__item-icon' : ''}`}
              onClick={(evt) => handleSelect(content.action ? content.action : () => { }, index)}>
              {content.label}
            </li>
          </div>
        }
      </div>
    )
  }

  const Row = (props) => {
    const { index, style } = props
    let content = secondaryList && secondaryList[index];
    if (itemStatusMap && itemStatusMap[index] === 'loaded' && content) {
      return DisplayList(index, content, style)
    }
    return <Skeleton />
  }

  const renderStackBranches = (secondaryList, index, branches, selectedCurrentBranch) => {
    if (branchSearch && branchSearch.length > 0) {
      return renderBranchesDom(branchsearchList || [], selectedSecondaryData, index)
    } else if (secondaryList && secondaryList[index]) {
      return renderBranchesDom(secondaryList[index].branches && secondaryList[index].branches.length > 0 && secondaryList[index].branches || [], secondaryList[index], index)
    }
  }

  const RenderedSecondaryList = (secondaryList, tertiaryLabel, secondaryArrow, selectedCurrentBranch, secondaryListLoader) => {
    return secondaryList && secondaryList.length > 0 && secondaryList.map((item, index) => {
      return (
        <>
          {item && item.branches && item.branches.length > 0 ?
            <li
              key={index}
              className={`MultiLevelDropdown__submenu__list__item MultiLevelDropdown__submenu__list__item--withTertiaryParent
                ${item.label == selectedData ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
                ${item.disable ? 'MultiLevelDropdown__submenu__list__item--disable' : ''} 
                ${item[props.viewAs]
                  ? item[props.viewAs] === selectedData
                    ? 'MultiLevelDropdown__menu__list__item--active'
                    : ''
                  : item.label === selectedData && 'MultiLevelDropdown__menu__list__item--active'
                }
                  ${props.withArrow ? 'MultiLevelDropdown__submenu__list__item-icon' : ''}
                  `}
              //onClick={(evt) => handleSelect(item.action ? item.action : () => { }, index)}
              onClick={(evt) => handlerNestedMenu(evt, index, secondaryList, item.branches, 'stack', true)}
              onMouseEnter={(evt) => {
                handlerNestedMenu(evt, index, secondaryList, item.branches, 'stack', false)
              }}
              onMouseLeave={() => {
                setStackBranchesVisible(false)
              }}
              data-id={index}
            >
              <span title={item.label}>{item.label}</span>
              {secondaryArrow &&
                <>
                  <Icon icon="BranchIcon2" className="branchIcon" />
                  <Icon icon="RightArrow"
                    className="rightArrow"
                  />
                </>}

              {renderStackBranches(secondaryList, index, item.branches, selectedCurrentBranch)}
            </li>
            :
            <li
              key={index}
              className={`MultiLevelDropdown__submenu__list__item 
                  ${item.label == selectedData ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
                  ${item.disable ? 'MultiLevelDropdown__submenu__list__item--disable' : ''} 
                  ${item[props.viewAs]
                  ? item[props.viewAs] === selectedData
                    ? 'MultiLevelDropdown__menu__list__item--active'
                    : ''
                  : item.label === selectedData && 'MultiLevelDropdown__menu__list__item--active'
                }
                    ${props.withArrow ? 'MultiLevelDropdown__submenu__list__item-icon' : ''}`}
              onMouseEnter={(evt) => {
                handlerNestedMenu(evt, index, secondaryList, item.branches, 'stack', false)
              }}
              onMouseLeave={() => {
                setStackBranchesVisible(false)
              }}
              onClick={(evt) => { evt.stopPropagation(); handleSelect(item.action ? item.action : () => { }, index) }}
              data-id={index}
            >
              <span>{item.label}</span>
            </li>
          }
        </>
      )
    })
  }

  const RenderWithDynamicList = (secondaryList, itemStatusMap) => {
    if (secondaryList && secondaryList.length) {
      return (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={props.totalCount}
          loadMoreItems={loadMoreValues}
          minimumBatchSize={30}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className={`MultiLevelDropdown__subMenu__menu ${secondaryList.length === 1 ? 'MultiLevelDropdown__subMenu__menu--visible' : ''}`}
              height={180}
              itemSize={30}
              itemCount={props.totalCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={180}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )
    }

    else {
      return (<div>Loading</div>)
    }
  }

  const RenderedPrimaryList = (item, index) => {
    return (
      <>
        <li
          key={index}
          className={`MultiLevelDropdown__submenu__list__item 
          ${item.value == selectedOrg ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
          ${item.disable ? 'MultiLevelDropdown__submenu__list__item--disable' : ''} 
          ${item[props.viewAs]
              ? item[props.viewAs] === selectedOrg
                ? 'MultiLevelDropdown__menu__list__item--active'
                : ''
              : item.label === selectedOrg && 'MultiLevelDropdown__menu__list__item--active'
            }
            ${props.withArrow ? 'MultiLevelDropdown__submenu__list__item-icon' : ''}`}
          onClick={(evt) => handleSelect(item.action ? item.action : () => { }, index)}>
          {item.label}
        </li>
      </>
    )
  }

  const RenderLabelValue = (selectedData, selectedOrganization, selectedAsyncValue, setSelectedStack) => {
    if (selectedData && selectedAsyncValue == undefined) {
      return <SelectedDataLabel selectedData={selectedData} />
    }
    else if (selectedData && selectedAsyncValue) {
      return <SelectedDataLabel selectedData={selectedAsyncValue} />
    }
    else if (selectedData && selectedOrganization) {
      return <SelectedDataLabel selectedData={selectedData} />
    }
    else if (selectedData == undefined && selectedOrganization && setSelectedStack) {
      return <SelectedDataLabel selectedData={setSelectedStack} />
    }
    else if (selectedOrganization) {
      return <SelectedDataLabel selectedData={selectedOrganization} />
    }
  }

  const RenderBranchValue = (selectedBranch, selectedCurrentBranch) => {
    if (selectedBranch && selectedCurrentBranch == undefined) {
      return (
        <div className="flex branchText">
          <Icon icon="BranchIcon2" size="small" />
          <SelectedDataLabel selectedData={selectedBranch} />
        </div>
      )
    }
    else if (selectedBranch && selectedCurrentBranch) {
      return (
        <div className="flex branchText">
          <Icon icon="BranchIcon2" size="small" />
          <SelectedDataLabel selectedData={selectedCurrentBranch} />
        </div>
      )
    }
    else if (selectedBranch == undefined && selectedCurrentBranch) {
      return (
        <div className="flex branchText">
          <Icon icon="BranchIcon2" size="small" />
          <SelectedDataLabel selectedData={selectedCurrentBranch} />
        </div>
      )
    }
  }

  const settingClickHandler = (data) => {
    if (data && data.action) {
      data.action()
    }
  }

  const handlePrimaryChange = (evt: any) => {
    // evt.stopPropagation();
    // evt.preventDefault();
    const { primaryList } = props;
    if (evt.target.value.length > 0) {
      let newSearchList: any = []
      primaryList.map((el: any) => {
        if (el.hasOwnProperty('searchKey') && typeof el.searchKey === 'string') {
          if (el.searchKey.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        } else if (typeof el.label === 'string') {
          if (el.label.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        }
      })
      setPrimarySearchList(newSearchList)
    }
    setPrimarySearch(evt.target.value)
  }

  const RenderWithPrimarySearch = (searchPlaceholder, searchText) => {
    return (
      <div className="MultiLevelDropDown__menu__searchBox">
        <Icon className="SearchIcon" icon="Search" size="original" />
        <TextInput
          placeholder={searchPlaceholder || 'Search here...'}
          type="search"
          value={searchText}
          onChange={handlePrimaryChange}></TextInput>
      </div>
    )
  }

  const primaryDropdownList = (item: any, index: any) => {
    return (
      <>
        <li
          key={index}
          className={`MultiLevelDropdown__menu__list__item 
            ${item.label == (selectedOrg || selectedOrganization) ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
            ${activeLink === index ? 'MultiLevelDropdown__menu__list__item--active' : ''}
            ${item.disable ? 'MultiLevelDropdown__menu__list__item--disable' : ''} 
            ${item[viewAs]
              ? item[viewAs] === (selectedOrg || selectedOrganization)
                ? 'MultiLevelDropdown__menu__list__item--active'
                : ''
              : item.label === (selectedOrg || selectedOrganization) ? 'MultiLevelDropdown__menu__list__item--active' : ''
            }
            ${withArrow ? 'MultiLevelDropdown__menu__list__item-icon' : ''}
              `}
          onClick={(e) => dropdownMenuHandler(e, index, item.action ? item.action : () => { })}
          data-id={index}>
          {item.label}
          <>
            {item.actionTooltip == true ?
              item.actionTooltipProps && item.actionTooltipProps.list && item.actionTooltipProps.list.length > 0 && item.actionTooltipProps.list.map(data => (
                <span onClick={() => settingClickHandler(data)} className="setting-click">{data.label}</span>
              ))
              : null
            }
            <Icon icon="RightArrow" className="rightArrow" />
          </>

          <ul className="MultiLevelDropdown__submenu__list"
            style={adjustWidthForContent ? { width: 'min-content' } : null}>
            <div>
              {secondaryLabel && (
                <div className="MultiLevelDropdown__header__submenu__label">{secondaryLabel}</div>
              )}
            </div>
            {withSearch && searchLimit && (secondaryList && secondaryList.length >= searchLimit) ? RenderWithSearch(searchPlaceholder, searchText) : <></>}
            {isSearchable && RenderWithAsyncSearch()}
            {searchText && searchText.length > 0 ? (
              searchList && searchList.length > 0 ? (
                <div className="MultiLevelDropdown__submenu__list__item__wrapper">
                  {fetchMoreListOptions === true ?
                    RenderWithDynamicList(searchList, itemStatusMap)
                    :
                    secondaryListLoader === true ?
                      <div className="MultiLevelDropdown__submeneu__loading">{<LoadingComponent />}</div>
                      :
                      RenderedSecondaryList(searchList, tertiaryLabel, secondaryArrow, selectedCurrentBranch, secondaryListLoader)}
                  {/* {searchList.map((item, index) => {
                    return RenderedPrimaryList(item, index)
                  })} */}
                </div>
              ) :
                <div className="no-results">No Result Found</div>
            ) : (
              <div className="MultiLevelDropdown__submenu__list__item__wrapper" id="MultiLevelDropdown__subParent" >
                {fetchMoreListOptions === true ?
                  RenderWithDynamicList(secondaryList, itemStatusMap)
                  :
                  secondaryListLoader === true ?
                    <div className="MultiLevelDropdown__submeneu__loading">{<LoadingComponent />}</div>
                    :
                    RenderedSecondaryList(secondaryList, tertiaryLabel, secondaryArrow, selectedCurrentBranch, secondaryListLoader)}
              </div>
            )}
          </ul>
        </li>
      </>
    )
  }

  const handleBranchChange = (evt: any) => {
    if (evt.target.value) {
      let newSearchList: any = []
      selectedSecondaryData && selectedSecondaryData.branches.map((el: any) => {
        if (el.hasOwnProperty('searchKey') && typeof el.searchKey === 'string') {
          if (el.searchKey.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        } else if (typeof el.label === 'string') {
          if (el.label.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        }
      })
      setbranchSearchList(newSearchList)
    }
    setbranchSearch(evt.target.value)
  }

  const RenderWithBranchesSearch = (searchPlaceholder: any, searchText: any) => {
    return (
      <div className="MultiLevelDropDown__menu__searchBox">
        <Icon className="SearchIcon" icon="Search" size="original" />
        <TextInput
          placeholder={searchPlaceholder || 'Search here...'}
          type="search"
          value={searchText}
          onChange={handleBranchChange}></TextInput>
      </div>
    )
  }

  const renderBranchesDom = (branchListData: any, secondaryListData: any, index: any) => {
    // console.log('sea', secondaryListData)
    let { placeholder }: any = props
    let defaultSubMenValue = false;
    if (secondaryListData && secondaryListData.branches) {
      for (let i = 0; i < secondaryListData.branches.length; i++) {
        let findApiKeyofSelectedData: any
        if (secondaryListData.label === selectedData) {
          findApiKeyofSelectedData = secondaryListData.api_key
          if (secondaryListData.branches[i].label === (selectedBranch || selectedCurrentBranch)) {
            defaultSubMenValue = true
          }
        }
      }
    }
    return (
      <ul className={`MultiLevelDropdown__submenu__list MultiLevelDropdown__submenu--nested__list ${defaultSubMenValue ? 'show' : 'hide'}`}
        style={adjustWidthForContent ? { width: 'max-content' } : null}
      >
        <div>
          {tertiaryLabel && (
            <div className="MultiLevelDropdown__header__submenu__label">{tertiaryLabel}</div>
          )}
        </div>
        {searchLimit && secondaryListData && secondaryListData.branches && secondaryListData.branches.length >= searchLimit && RenderWithBranchesSearch(placeholder, branchSearch)}
        <div className="MultiLevelDropdown__submenu__list__item__wrapper">
          {
            branchSearch && branchSearch.length > 0 && branchListData && branchListData.length === 0 && (
              <div className="no-results">No Result Found</div>
            )
          }
          {branchListData.map((list, i) => {
            let newValue, newArray = [];
            if (list.alias && list.alias.length > 0) {
              list.alias.map(alias => {
                newArray.push(' ' + alias.label)
                newValue = newArray.slice(1)
                return newValue
              })
            }
            return (<li
              key={index + i}
              className={`MultiLevelDropdown__submenu__list__item MultiLevelDropdown__submenu__list__item--withTertiary
            ${list.label == (selectedBranch || selectedCurrentBranch) ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
            ${list.disable ? 'MultiLevelDropdown__submenu__list__item--disable' : ''} 
            ${list[props.viewAs]
                  ? list[props.viewAs] === (selectedBranch || selectedCurrentBranch)
                    ? 'MultiLevelDropdown__menu__list__item--active'
                    : ''
                  : list.label === (selectedBranch || selectedCurrentBranch) && 'MultiLevelDropdown__menu__list__item--active'
                }
            ${props.withArrow ? 'MultiLevelDropdown__submenu__list__item-icon' : ''}
            `}
              onMouseEnter={(e) => handlerNestedMenu(e, i, secondaryListData, secondaryListData.branches, 'branch', false)}
              onMouseLeave={() => {
                setStackBranchesVisible(false)
              }}
              onClick={(e) => handlerNestedSubMenu(e, i, secondaryListData.branches, list.action ? list.action : () => { })}
              data-id={1 + i}
            >
              {/* <Icon icon="BranchIcon"
                className="branchAliasIcon"
                size="small" /> */}
              {(list.label && list.label != undefined && list.label != '') ? <span title={list.label}>{list.label}</span> : null}
              {list.alias && list.alias.length > 0 && list.alias.length == 1 ?
                <>
                  <span className="listAlias">
                    <Icon icon="RestAlias"
                      className="listAliasIcon"
                      size="small" />
                    {list.alias && list.alias[0].label}
                  </span>
                </>
                :
                list.alias && list.alias.length > 1 ?
                  <>
                    <span className="listAlias" title={list.alias[0].label}>
                      <Icon icon="RestAlias"
                        className="listAliasIcon"
                        size="small" />
                      {list.alias && list.alias[0].label}
                    </span>
                    <Tooltip
                      content={newValue.toString()}
                      position="top"
                      showArrow={true}
                    >
                      <p className="aliasCount">+{list.alias && list.alias.length - 1}</p>
                    </Tooltip>
                  </>
                  : null
              }
            </li>
            )
          })}
        </div>
      </ul>)
  }

  const { secondaryLabel, classNames, dropDownType, primaryLabel,
    adjustWidthForContent, hoverState, clickState, primaryList,
    secondaryList, primaryListLoader, secondaryListLoader, viewAs, withArrow,
    withSearch, isSearchable, fetchMoreListOptions, searchPlaceholder, arrowSecondary, itemStatusMap,
    selectedAsyncValue,
    selectedOrganization, tertiaryList, tertiaryLabel, secondaryArrow, selectedCurrentBranch, topLabelValue,
    clickedOrganisation, setSelectedStack, withPrimarySearch, searchLimit } = props;


  return (
    <div ref={ref} className={`${classNames} ${isComponentVisible ? 'MultiLevelDropdown--open' : ''}`}>
      {secondaryLabel && <HeaderWithLabel topLabelValue={topLabelValue} />}
      <div
        onClick={() => { setIsComponentVisible(!isComponentVisible) }}
        className={`MultiLevelDropdown__header flex
                    ${isComponentVisible ? 'MultiLevelDropdown__header--max' : 'MultiLevelDropdown__header--min'}`}>
        {RenderLabelValue(selectedData, selectedOrganization, selectedAsyncValue, setSelectedStack)}
        <RenderIconView
          arrowSecondary={arrowSecondary}
          isComponentVisible={isComponentVisible}
        />

      </div>

      {(selectedBranch || selectedCurrentBranch) ?
        RenderBranchValue(selectedBranch, selectedCurrentBranch)
        : null
      }


      {isComponentVisible && (
        <>
          <div className={getDropDownMenuType(dropDownType)}
            style={adjustWidthForContent ? { width: 'min-content' } : null}>
            {primaryLabel && <div className="MultiLevelDropdown__header__label">{primaryLabel}</div>}
            {withPrimarySearch && searchLimit && (primaryList && primaryList.length >= searchLimit) && RenderWithPrimarySearch(searchPlaceholder, primarySearch)}
            <ul className="primaryDropDown">
              {hoverState === true
                ?
                primaryListLoader === true ? (
                  <div className="MultiLevelDropdown__loading">{<LoadingComponent />}</div>
                )
                  :
                  primaryList.map((item, index) =>
                  (
                    <li
                      key={index}
                      className={`MultiLevelDropdown__menu__list__item 
                        ${item.label == (selectedOrg || selectedOrganization) ? 'MultiLevelDropdown__submenu__list__item--selected' : ''}
                          ${activeLink === index ? 'MultiLevelDropdown__menu__list__item--active' : ''}
                          ${item.disable ? 'MultiLevelDropdown__menu__list__item--disable' : ''} 
                          ${item[viewAs]
                          ? item[viewAs] === (selectedOrg || selectedOrganization)
                            ? 'MultiLevelDropdown__menu__list__item--active'
                            : ''
                          : item.label === (selectedOrg || selectedOrganization) ? 'MultiLevelDropdown__menu__list__item--active' : ''
                        }
                        s${withArrow ? 'MultiLevelDropdown__menu__list__item-icon' : ''}
                           `}
                      onMouseEnter={(e) => dropdownMenuHandler(e, index, item.action ? item.action : () => { })}
                      data-id={index}>
                      {item.label}
                      <>
                        {item.actionTooltip == true ?
                          item.actionTooltipProps && item.actionTooltipProps.list && item.actionTooltipProps.list.length > 0 && item.actionTooltipProps.list.map(data => (
                            <span onClick={() => settingClickHandler(data)}>{data.label}</span>))
                          : null
                        }
                        <Icon icon="RightArrow" className="rightArrow" />
                      </>
                      <ul className="MultiLevelDropdown__submenu__list"
                        style={adjustWidthForContent ? { width: 'min-content' } : null}>
                        <div>
                          {secondaryLabel && (
                            <div className="MultiLevelDropdown__header__submenu__label">{secondaryLabel}</div>
                          )}
                        </div>
                        {withSearch && RenderWithSearch(searchPlaceholder, searchText)}
                        {searchList.length > 0 ? (
                          searchList.length > 0 ? (
                            <div className="MultiLevelDropdown__submenu__list__item__wrapper">
                              {
                                RenderedSecondaryList(searchList, tertiaryLabel, secondaryArrow, selectedCurrentBranch, secondaryListLoader)
                              }
                              {/* {searchList.map((item, index) => {
                                return RenderedPrimaryList(item, index)
                              })} */}
                            </div>
                          )
                            :
                            <div className="no-results">No Result Found</div>

                        ) : (
                          <div className="MultiLevelDropdown__submenu__list__item__wrapper">
                            {RenderedSecondaryList(secondaryList, tertiaryLabel, secondaryArrow, selectedCurrentBranch, secondaryListLoader)}
                          </div>
                        )}
                      </ul>
                    </li>
                  )
                  )
                : clickState === true
                  ? primaryListLoader === true ? (
                    <div className="MultiLevelDropdown__loading">{<LoadingComponent />}</div>
                  )
                    :
                    (primarySearch && primarySearch.length > 0) ?
                      (primarysearchList && primarysearchList.length > 0) ?
                        <>{primarysearchList.map((item, index) => primaryDropdownList(item, index))}</>
                        : <div className="no-results">No Result Found</div>
                      : primaryList.map((item, index) => primaryDropdownList(item, index)
                      )
                  : null}
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

const ClickableMutiLevelDropDown: React.FunctionComponent<IMultiLevelDropdownOption> = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  let labelType: any = props.primaryList[0] ? props.primaryList[0].label : ''
  const { classNames, adjustWidthForContent, children, withArrow, arrowSecondary,
    dropDownType, primaryList, closeAfterSelect } = props;
  return (
    <div
      className={`${classNames} ${isComponentVisible ? 'MultiLevelDropdown--open' : ''}`}
      ref={ref}
      onClick={(evt: any) => {
        evt.stopPropagation()
        setIsComponentVisible(!isComponentVisible)
      }}
      style={adjustWidthForContent ? { width: 'min-content' } : null}
    >
      <div className={`${'MultiLevelDropdown__header'}`}>
        <>
          {children}
          {withArrow && (
            <Icon
              className={`ml-6 MultiLevelDropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
              icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
              size="original"
            />
          )}
        </>
      </div>
      {isComponentVisible && (
        <div className={getDropDownMenuType(dropDownType)}>
          <ul>
            {primaryList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`MultiLevelDropdown__menu__list__item 
                    ${item.disable ? 'MultiLevelDropdown__menu__list__item--disable' : ''} flex`}
                  onClick={(evt: any) => {
                    evt.stopPropagation()

                    if (closeAfterSelect) {
                      setIsComponentVisible(!isComponentVisible)
                    }
                    if (item.action) {
                      item.action()
                    }
                  }}>
                  {item.label}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

const HoverMultiLevelDropDown: React.FunctionComponent<IMultiLevelDropdownOption> = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const [selectedData, setSelectedData] = useState(getDefaultValue(props.primaryList, props.viewAs))
  const [selectedDataText, setSelectedDataText] = useState(getDefaultLabelText(props.primaryList, props.viewAs))
  const [activeLink, setActiveLink] = useState()
  const { classNames, adjustWidthForContent, children, withArrow, arrowSecondary,
    dropDownType, primaryList, closeAfterSelect, primaryListLoader,
    primaryLabel, secondaryLabel, secondaryList, secondaryListLoader } = props;
  return (
    <div
      ref={ref}
      className={`${classNames} ${isComponentVisible ? 'MultiLevelDropdown--open' : ''}`}
      onMouseEnter={() => {
        setIsComponentVisible(true)
      }}
      onMouseLeave={() => {
        setIsComponentVisible(false)
      }}
      style={adjustWidthForContent ? { width: 'min-content' } : null}

    >
      <div className="MultiLevelDropdown__header">
        {children}
        {withArrow && (
          <Icon
            className={`ml-6 MultiLevelDropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
            icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
            size="original"
          />
        )}
      </div>
      {isComponentVisible && (
        <div className={getDropDownMenuType(dropDownType)}>
          <ul className="MultiLevelDropdown__menu__list">
            {primaryList.map((item, index) =>
              primaryListLoader === true ? (
                <div className="MultiLevelDropdown__loading">{<LoadingComponent />}</div>
              ) : (
                <li
                  key={index}
                  className={`MultiLevelDropdown__menu__list__item  flex
                   
                    `}
                  // onMouseOver={e => dropdownHoverHandler(e, index)}
                  data-id={index}>
                  {item.label}
                  <ul className="MultiLevelDropdown__submenu__list" style={{ width: 'fit-content' }}>
                    <div>
                      {secondaryLabel && (
                        <div className="MultiLevelDropdown__header__submenu__label">{secondaryLabel}</div>
                      )}
                    </div>
                    {secondaryList.map((item, index) => {
                      return (
                        <>
                          {secondaryListLoader === true ? (
                            <div className="MultiLevelDropdown__submenu__loading">{<LoadingComponent />}</div>
                          ) : (
                            <li
                              key={index}
                              className={`MultiLevelDropdown__submenu__list__item 
                                      `}
                            // onClick={evt => handleSelect(index)}
                            >
                              {item.label}
                            </li>
                          )}
                        </>
                      )
                    })}
                  </ul>
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

const getMultiLevelDropDown = (props: IMultiLevelDropdown) => {
  const TypeClass = `MultiLevelDropdown--${props.dropDownType}`
  const Position = `MultiLevelDropdown--position-${props.dropDownPosition}`
  const classNames = cn('MultiLevelDropdown', [TypeClass], [Position], props.className)

  switch (props.type) {
    case 'click': {
      return (
        <ClickableMutiLevelDropDown {...props} classNames={classNames} />
      )
    }
    case 'select': {
      return (
        <SelectMultiLevelDropDown {...props} classNames={classNames} />
      )
    }
    case 'hover': {
      return (
        <HoverMultiLevelDropDown {...props} classNames={classNames}
        />
      )
    }
  }
}

const MultiLevelDropdown: React.FunctionComponent<IMultiLevelDropdown> = (props) => {
  return (
    <>
      {getMultiLevelDropDown(props)}
    </>
  )
}

MultiLevelDropdown.defaultProps = {
  dropDownPosition: 'bottom',
  withSearch: false,
  withArrow: true,
  dropDownType: 'primary',
  closeAfterSelect: false,
  viewAs: 'label',
  highlightActive: false,
  arrowSecondary: false,
  primaryListLoader: false,
  secondaryListLoader: false,
  clickState: true,
  hoverState: false,
  adjustWidthForContent: false,
  withPrimarySearch: false,
  searchLimit: 10

} as Partial<IMultiLevelDropdown>

export default MultiLevelDropdown
