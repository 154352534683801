import React from 'react'
import { Registry } from '@react-registry'
import { cx } from '@emotion/css'
import { useSelected, useSlateStatic } from 'slate-react'
import { Editor } from 'slate'

//@ts-ignore
import styles from '../style.module.css'
import TableColumnDnd from '../table-column-dnd/index'
import TableRowAdd from '../table-footer/index'
export * from './deserializer'
const Table = (props) => {
  const { attributes, children, element, slatePath } = props
  let attrs
  if (element.attrs) {
    attrs = element.attrs
  }
  const templateColumns = attrs.colWidths.map(w => `${w}px`).join(' ')
  let isSelected = useSelected()
  const editor = useSlateStatic()

  const isSingleCellSelected = Boolean(Editor.above(editor, {
    // @ts-ignore
    match: (node) => ['td', 'th'].includes(node.type)
  }))

  const disableSelection = editor.selection && !isSingleCellSelected
  return (
    <div
      data-type="table"
      data-testid="table"
      className={cx(styles['div-table'], 'scrte-table', { 'scrte-table-selected': isSelected }, { 'scrte-table-selected--hide-selection': disableSelection })}
      {...attributes}
      data-row={attrs.rows}
      data-col={attrs.cols}
      data-colwidth={attrs.colWidths}
      style={{ '--template-column': templateColumns }}
    >
      <TableColumnDnd
        element={props.element}
        tablePath={slatePath}
        tableRef={attributes.ref}
        isSelected={isSelected}
      ></TableColumnDnd>
      {children}
      {isSelected && <TableRowAdd tablePath={slatePath}></TableRowAdd>}
    </div>
  )
}

const beforeElementRender = (props) => {
  let { isSelected } = props
  // Check if current element if first child
  if (isSelected) {
    props.DisableDND = true
  }
}

export default Table

Registry.register(
  {
    Component: Table,
    isContentStackElement: true,
    toolbar: {
      inHoveringToolbar: false
    },
    beforeElementRender
  },
  { id: 'table', registry: 'element' }
)
