//@ts-nocheck
const Q = (body) => {
  const postRobot = window.postRobot;
  return postRobot.sendToParent('stackQuery', body);
}

const handleUpload = async (files) => {
  if (!window.postRobot || !window.parent) {
    throw new Error("Parent window missing. Extension must be used inside a parent.")
  }
  const _files = [];
  Array.from(files).forEach(_ => {
    const file = new File([_], _.name, { type: _.type });
    _files.push(file);
  })
  const uid = new Date().getUTCMilliseconds();
  const postRobot = window.postRobot;
  const uploadReadyListener = postRobot.on(`uploadReady_${uid}`, function () {
    window.parent.postMessage({
      type: `upload_${uid}`,
      files: _files
    }, '*');

    uploadReadyListener.cancel();
    return Promise.resolve({});
  });
  await postRobot.sendToParent('stackOptionsQuery', {
    action: `upload_${uid}`,
    uid
  });
  return postRobot.sendToParent(`upload_${uid}`, {})
}


export const getAssets = async (props) => {
  const {
    limit = 30,
    skipSize = 0,
    query = '',
    asc_field = '',
    dsc_field = '',
    folderUid,
    type,
    excludeAssetUids = []
  } = props

  const assetTypeMapper = {
    image: 'images',
    document: 'documents',
    video: 'videos'
  }
  const queryParams = {
    skip: skipSize,
    limit: limit,
    include_count: 'true',
    asc: asc_field,
    dsc: dsc_field,
    include_publish_details: 'true',
    include_folders: 'true',
    include_metadata: true,
    folder: folderUid,

  }
  if (type) queryParams.types = [assetTypeMapper[type] || type]
  const excludeCondition = {
    "uid": {
      "$nin": excludeAssetUids
    }
  }
  if (query !== '') {
    delete queryParams['folder'];
    const searchCondition = {
      "$or": [
        {
          "filename": {
            "$regex": query,
            "$options": "i"
          }
        },
        {
          "uid": {
            "$regex": query,
            "$options": "i"
          }
        },
        {
          "name": {
            "$regex": query,
            "$options": "i"
          }
        }
      ]
    }
    let finalCondition = []
    finalCondition.push(searchCondition)
    if (excludeAssetUids.length) finalCondition.push(excludeCondition)
    queryParams['query'] = JSON.stringify({
      "$and": finalCondition
    });
  } else if (excludeAssetUids.length) {
    queryParams['query'] = excludeCondition
  }

  let res = await Q({
    action: 'getAssets',
    params: queryParams
  })

  return res.data;
}

export const getFolders = async (props) => {
  const {
    limit = 30,
    skipSize = 0,
    query = '',
    asc_field = '',
    dsc_field = '',
    folderUid = ''
  } = props

  const queryParams = {
    skip: skipSize,
    limit: limit,
    include_count: 'true',
    asc: asc_field,
    dsc: dsc_field,
    include_publish_details: 'true',
    include_folders: 'true',
    folder: folderUid
  }
  const queryString = `"name":{"$regex":"${query}","$options": "i"},`;
  const queryVal = `{${query !== '' ? queryString : ''} "is_dir": true}`;

  const res = await Q({
    action: 'getAssets',
    params: {
      ...queryParams,
      name: `{"$regex":"${query}","$options": "i"}`,
      query: queryVal
    }
  })
  return res.data;
}

export const uploadImage = async (props) => {
  const { files, isPlugin } = props

  if (isPlugin) {
    const res = await handleUpload(files);
    return res.data;
  }
}

export const createFolder = async (props) => {
  const { folderName, parentUid } = props;
  let body = {
    "asset": {
      "name": folderName,
      "parent_uid": parentUid
    }
  }

  let promise = await Q({
    action: 'createFolder',
    payload: body
  })
  return promise.data;
}

export const getCurrentUserId = async () => {
  const res = await Q({
    action: 'getUser'
  });
  const json = await res.data;
  return json?.user?.uid;
}

export const getUsers = async () => {
  const res = await Q({
    action: 'getStacks',
    params: {
      include_collaborators: true
    }
  });
  const json = await res.data;
  let collaborators = json['stack']?.collaborators || [];
  let collaboratorsMap = {};
  let currentUid = await getCurrentUserId();
  collaborators.forEach(user => collaboratorsMap[user.uid] = (user.uid === currentUid ? 'Me' : `${user.first_name} ${user.last_name}`));
  return collaboratorsMap;
}