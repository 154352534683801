import React, { useState, useEffect } from 'react'

import { TextInput, Checkbox, Select, Field, ValidationMessage } from '../../../../../../index'

export const ImageEditForm = ({ attributes, updateFormData, hasError, className, type }) => {
  const [formData, setFormData] = useState({
    alt: attributes.alt || '',
    position: attributes.position || 'none',
    caption: attributes.caption?.trim() || '',
    anchorLink: attributes.anchorLink?.trim() || '',
    inline: attributes.inline || false,
    target: attributes.target || false
  })
  const [alignmentValue, setAlignmentValue] = useState({
    id: formData.position,
    value: formData.position,
    label: formData.position
  })
  const [errorMessage, setErrorMessages] = useState({
    anchorLink: ''
  })

  useEffect(() => {
    updateFormData(formData)
  }, [formData])

  useEffect(() => {
    let errorExist = false
    Object.values(errorMessage).some((message) => {
      return (errorExist = Boolean(message))
    })
    if (hasError.value !== errorExist) hasError.callback(errorExist)
  }, [errorMessage])

  const handleChange = (e) => {
    e.persist()
    const targetName = e.target.name
    const targetValue = e.target.value

    setFormData((prevState) => {
      return { ...prevState, [targetName]: targetValue }
    })
  }

  const handleToggle = (e) => {
    e.persist()
    const targetName = e.target.name
    const targetValue = e.target.checked
    setFormData((prevState) => {
      return { ...prevState, [targetName]: targetValue }
    })
  }

  return (
    <React.Fragment>
      <Field labelText="Alt text">
        <TextInput value={formData.alt} name="alt" onChange={handleChange}></TextInput>
      </Field>

      <Field labelText="Alignment">
        <Select
          onChange={(data) => {
            setFormData((prevState) => {
              return {
                ...prevState,
                position: data.value
              }
            })

            setAlignmentValue(data)
          }}
          name="position"
          value={alignmentValue}
          placeholder="Alignment"
          options={
            formData.inline
              ? [
                {
                  id: 'none',
                  value: 'none',
                  label: 'none'
                },
                {
                  id: 'left',
                  value: 'left',
                  label: 'left'
                },
                {
                  id: 'right',
                  value: 'right',
                  label: 'right'
                }
              ]
              : [
                {
                  id: 'none',
                  value: 'none',
                  label: 'none'
                },
                {
                  id: 'left',
                  value: 'left',
                  label: 'left'
                },
                {
                  id: 'center',
                  value: 'center',
                  label: 'center'
                },
                {
                  id: 'right',
                  value: 'right',
                  label: 'right'
                }
              ]
          }
          testId={"scrte-image-align"}
        ></Select>
      </Field>

      <Field labelText="Caption">
        <TextInput
          value={formData.caption}
          type="text"
          placeholder="Enter a caption"
          name="caption"
          onChange={handleChange}
        />
      </Field>

      <Field labelText="Embed Link">
        <TextInput
          value={formData.anchorLink}
          type="url"
          placeholder="Enter a link"
          name="anchorLink"
          onChange={handleChange}
          error={!!errorMessage.anchorLink}
        />
        <ValidationMessage style={{ marginLeft: '10px', marginTop: '5px' }}>
          {errorMessage.anchorLink}
        </ValidationMessage>
      </Field>

      <Field>
        <Checkbox
          style={{ padding: '0.6rem' }}
          checked={formData.target}
          name="target"
          text="Open link in new tab"
          onChange={handleToggle}
          data-testid={"scrte-image-new-tab"}
        />
      </Field>

      <Field>
        <Checkbox
          style={{ padding: '0.6rem' }}
          checked={formData.inline}
          text="Inline Image"
          name="inline"
          onChange={handleToggle}
          data-testid={"scrte-image-inline"}
        />
      </Field>
    </React.Fragment>
  )
}
