import { Registry } from '@react-registry'

export const tableRowGroupDeserializer = (el) => {
    return {
        type: 'trgrp',
        attrs: {}
    }
}


Registry.register(tableRowGroupDeserializer, { id: 'row-group', registry: 'deserializer' })