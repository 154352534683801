import React, { useState, memo } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts'

import './Graph.css'

const BarGraphComponent = (props) => {
  const {
    data,
    label,
    value,
    domainY,
    domainX,
    scaleX = 'auto',
    typeX = 'category',
    tickFormatterX,
    tickFormatterY,
    CustomTooltip,
    maxBarSize = 100,
    refrenceLineY = 0,
    RefrenceLineLabel,
    gridStroke = '0.7',
    gridLinesVertical = false
  } = props

  const styleXAxis = {
    fill: '#718096',
    strokeWidth: 0.08,
    fontSize: '12px',
  }

  const styleYAxis = {
    fill: '#A0AEC0',
    strokeWidth: 0.05,
    fontSize: '11px',
  }

  return (
    <ResponsiveContainer>
      <BarChart data={data}>
        <CartesianGrid stroke='#E9ECF3' strokeWidth={gridStroke} vertical={gridLinesVertical} />
        <XAxis
          dataKey={label}
          style={styleXAxis}
          padding={{ left: 30, right: 30 }}
          tickFormatter={tickFormatterX || null}
          scale={scaleX}
          type={typeX}
          domain={domainX}
          interval={data.length < 10 ? 0 : data.length <= 30 ? 2 : Math.floor(data.length / 10)}
          allowDataOverflow={true}
        />
        <YAxis
          interval='preserveStartEnd'
          dataKey={value}
          style={styleYAxis}
          domain={domainY}
          tickFormatter={tickFormatterY || null}
        />
        {CustomTooltip ? (
          <Tooltip
            content={<CustomTooltip />}
            cursor={false}
          />
        ) : (
          <Tooltip cursor={false} />
        )}

        <Bar
          dataKey={value}
          fill='#E2E8F0'

          isAnimationActive={false}
          className='GraphModule-RectBarGraph'
          maxBarSize={maxBarSize}
          stackId='a'
        ></Bar>
        <Bar
          dataKey='limit'
          fill={'#EB5646'}
          isAnimationActive={false}
          maxBarSize={maxBarSize}
          stackId='a'
        />
        {refrenceLineY > 0 && (
          <ReferenceLine
            y={refrenceLineY}
            stroke='#FF5630'
            strokeDasharray='3 3'
            isFront={true}
            label={{ position: 'top', value: RefrenceLineLabel }}
            className='GraphModule-barGraph-Limit-label'
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default memo(BarGraphComponent)

