import { Path, Point, Range } from 'slate'

export const getPointFromLocation = (
  editor,
  { at = editor.selection, focus }: any = {}
) => {
  let point
  if (Range.isRange(at)) point = !focus ? at.anchor : at.focus
  if (Point.isPoint(at)) point = at
  if (Path.isPath(at)) point = { path: at, offset: 0 }

  return point
}
