//@ts-nocheck
import { Editor, Transforms } from 'slate'

export const clearEditor = (editor) => {
  const [docs] = Editor.nodes(editor, {
    at: [0],
    match: (n) => n.type === 'docs'
  })
  let docsCopy
  if (docs) {
    docsCopy = JSON.parse(JSON.stringify(docs[0]))
  } else {
    docsCopy = {
      type: 'docs',
      attrs: {},
      children: []
    }
  }
  docsCopy.children = [{ type: 'p', attrs: {}, children: [] }]
  Transforms.removeNodes(editor, { at: [0] })
  Transforms.insertNodes(editor, [docsCopy], { at: [0] })
  Transforms.select(editor, [0])
}
