import React, { FormEventHandler, FormEvent } from 'react'
import cn from 'classnames'

import './Form.css'

export interface FormProps {
    onSubmit?: FormEventHandler
    style?: React.CSSProperties
    className?: string
    children: React.ReactChild | React.ReactNodeArray
    testId?: string
}

const Form = (props: FormProps) => {
    const { className, children, onSubmit, testId, ...otherProps } = props

    const classNames = cn(Form, className)

    const formItemClassNames = cn('Form__item')


    const handleSubmit = (event: FormEvent) => {
        event.preventDefault()
        if (onSubmit) {
            onSubmit(event)
        }
    }

    return (
        <form data-test-id={testId} className={classNames} onSubmit={handleSubmit} {...otherProps}>
            {React.Children.map(children, (child) => {
                if (child) {
                    return <div className={formItemClassNames}>{child}</div>
                }
                return null
            })}
        </form>
    )
}

Form.defaultProps = {
    testId: 'cs-form'
} as Partial<FormProps>

export default Form
