
import { cx } from '@emotion/css';
import React from 'react';
import styles from './style.module.css'
export const DndPreview = (props) => {
    const { previewstyleclass, previewref } = props
    return (<div contentEditable={false} className={styles['preview-layer-styles']}>
        <div id='scrte-dnd-preview' data-testid="scrte-dnd-preview" className={styles['scrte-dnd-preview']} ref={previewref}>
            <div className={cx(styles['opacity-05'], previewstyleclass?.['preview'] ?? '')}>
                {props.children}
            </div>
        </div>
    </div>);
};
