import React from 'react'
import { Registry } from '@react-registry'

import { shortcutToggleSubScript, ToggleSubscriptButton } from './subscriptButton'
import { SubscriptIcon } from '../../utils/Icon'
//@ts-ignore
import styles from './style.module.css'
import { leafCategories } from '../leafCategories'

export const Subscript = ({ children }) => <sub className={styles['sub']}>{children}</sub>

export * from './deserializer'

Registry.register(
  {
    Component: Subscript,
    title: 'Subscript',
    iconName: <SubscriptIcon />,
    shortcut: { key: 'mod+shift+~', byKey: true, callback: shortcutToggleSubScript },
    IngressComponent: ToggleSubscriptButton,
    category: leafCategories.TEXT_STYLE_SEE_MORE,
    isContentStackElement: true
  },
  { id: 'subscript', registry: 'leaf' }
)
