//@ts-nocheck
import React, { Fragment, useEffect, useState } from 'react'
import { Transforms, Editor, Range } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import {
  cbModal,
  ModalBody,
  ModalHeader,
  InfiniteScrollTable,
  Button,
  ButtonGroup,
  ModalFooter,
  Select
} from '../../../../../../../../index'
import './style.css'
import { fetchEntries, fetchContentTypes, checkProps, isEmbedLink, fetchEnvironment, fetchLocale, setEntryInEditor, getReferenceNode } from '../../utils'
//@ts-ignore
import styles from './style.module.css'
import { InvalidPropsMsg } from '../../utils/InvalidPropsMessage/InvalidPropsMsg'
import { v4 } from 'uuid'
import { EmbedLinkBody, ReferenceBody, PublishStatusComponent } from './components'
import { getEmbedLinkDetails, updateLink } from './utils';
import { getUsers } from '../../image/request';
import { formatTime } from '../../image/Modal/util'
import AsyncLoader from '../../../../../../../AsyncLoader/AsyncLoader'
import { cx } from '@emotion/css'

// to be move in utils
const ModalComponent = (props) => {
  const { editor, type } = props
  const [state, setState] = useState({
    entries: [],
    singleSelectedRowId: { data: {}, key: '' },
    totalEntries: 0,
    displayType: 'block',
    selectedContentType: null,
    loading: false,
    loadingCt: false,
    contentTypeUid: '',
    contentTypes: [],
    contentTypeOptions: [],
    apiQuery: '',
    locales: [],
    checkedUids: [],
    checkedUidDataSource: [],
    itemStatusMap: {},
    newTab: false,
    linkDetails: getEmbedLinkDetails(editor),
    users: {},
    environments: {}
  });
  const entriesPerPage = 12;
  const { customizedPagination } = editor.requestProps || {};
  const { entries, totalEntries, contentTypes, displayType, apiQuery, contentTypeUid, singleSelectedRowId, itemStatusMap, loading, selectedContentType, contentTypeOptions, loadingCt } = state;

  const getEntries = async (contentTypeUid, skipEntries = 0, limit = entriesPerPage) => {
    let res = await fetchEntries({
      contentTypeUid,
      limitPerPage: limit,
      skipEntries,
      query: apiQuery,
      ...props,
    })
    if (props.prefilled && (singleSelectedRowId.key === '')) {
      const entries = res['entries'];
      const entryUid = props?.attributes?.['entry-uid'],
        displayType = props?.attributes?.['display-type'],
        contentTypeUid = props?.attributes?.['content-type-uid'];
      entries.forEach(entry => {
        if (entry['uid'] === entryUid) {
          setState(prevState => ({
            ...prevState, displayType, singleSelectedRowId: {
              data: { ...entry, contentTypeUid }, key: entryUid
            }
          }));
        }
      })
    }
    setState(prevState => ({
      ...prevState,
      entries: [...prevState.entries, ...res['entries']],
      totalEntries: res['count']
    }));
  }

  const tableHead = [
    {
      Header: 'Name',
      accessor: 'title',
      id: 'title',
      default: true,
      disableSortBy: true,
    },
    {
      Header: 'Last Modified',
      accessor: (data) => <div className={'last-updated-by'}><span>{state.users[data.updated_by] || 'Anonymous'}</span> <span className='last-updated-by-time'>{formatTime(data.updated_at)}</span></div>,
      id: 'uid',
      default: true,
      disableSortBy: true,
    },
    {
      Header: 'Publish Status',
      accessor: (data) => {
        return <PublishStatusComponent users={state.users} locales={state.locales} data={data} environments={state.environments} />
      },
      id: 'publish_details',
      default: true,
      disableSortBy: true,
    }
  ]

  const getContentType = async () => {
    setState(prevState => ({ ...prevState, loadingCt: true }));
    let res = await fetchContentTypes(props)
    let reference_to = editor.requestProps['reference_to'] || []
    let ct = res['content_types'].filter((currentCt: { uid: string }) => reference_to.includes(currentCt.uid));
    let contentTypeOptions = ct.map((contentType) => {
      if (type === 'link') {
        let isPage = contentType['options']?.['is_page'];
        if (!isPage) {
          return {
            label: contentType['title'],
            value: contentType['uid'],
            id: contentType['uid'],
            isDisabled: true
          }
        }
      }
      return {
        label: contentType['title'],
        value: contentType['uid'],
        id: contentType['uid']
      }
    })

    contentTypeOptions = contentTypeOptions.filter(Boolean)
    setState(state => ({ ...state, contentTypes: ct, loadingCt: false, contentTypeOptions }));
  }

  useEffect(() => {
    (async () => {
      await getContentType();
      const promises = [
        getUsers(editor?.requestProps),
        fetchEnvironment(editor?.requestProps),
        fetchLocale(editor?.requestProps)
      ]
      Promise.all(promises).then(res => {
        setState(state => ({
          ...state,
          users: res[0],
          environments: res[1],
          locales: res[2]
        }))
      }).catch(err => {
        console.log(err, 'ERROR');
      });
    })();
  }, [])

  useEffect(() => {
    loadMoreItems({ skip: 0, limit: entriesPerPage, startIndex: 0, stopIndex: entriesPerPage });
  }, [contentTypeUid])

  useEffect(() => {
    if (props.prefilled && contentTypes.length) {
      const contentTypeUid = props?.attributes?.['content-type-uid'];
      let selectedCt = undefined;
      contentTypes.forEach(ct => {
        if (ct['uid'] === contentTypeUid) selectedCt = ct;
      });
      if (selectedCt)
        handleMenuClick({ id: contentTypeUid, value: contentTypeUid, label: selectedCt['title'] });
    }
  }, [contentTypes])

  useEffect(() => {
    if (contentTypeUid === '') return;
    loadMoreItems({ skip: 0, limit: entriesPerPage, startIndex: 0, stopIndex: entriesPerPage });
  }, [apiQuery])

  function handleMenuClick(ct) {
    setState(prevState => ({ ...prevState, apiQuery: '', entries: [], totalEntries: entriesPerPage, itemStatusMap: {}, contentTypeUid: ct['id'], selectedContentType: ct }));
  }

  function handleFetchTableData(query) {
    setState(prevState => ({ ...prevState, apiQuery: query['searchText'], entries: [], itemStatusMap: {} }));
  }

  const loadMoreItems = ({ skip, limit, startIndex, stopIndex }) => {
    if (!contentTypeUid) return;
    setState(prevState => {
      let itemStatusMapCopy = { ...prevState.itemStatusMap }
      for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMapCopy[index] = 'loading'
      }
      return ({ ...prevState, itemStatusMap: itemStatusMapCopy, loading: true })
    })
    getEntries(contentTypeUid, skip, limit).finally(() => {
      setState(prevState => {
        let updateditemStatusMapCopy = { ...prevState.itemStatusMap }

        for (let index = startIndex; index <= stopIndex; ++index) {
          updateditemStatusMapCopy[index] = 'loaded'
        }
        return ({ ...prevState, itemStatusMap: updateditemStatusMapCopy, loading: false })
      })
    })

  }

  const handleRowClick = (props) => {
    setState(prevState => ({ ...prevState, singleSelectedRowId: { data: { ...props, contentTypeUid: contentTypeUid }, key: props.key } }));
  }

  const handleDisplayType = (type) => {
    setState(prevState => ({ ...prevState, displayType: type }));
  }

  const handleEmbedSelect = () => {
    // replace logic
    if (props.prefilled && type !== 'link') {
      const { element } = props.attributes;
      const beforeDisplayType = props.attributes['display-type'];
      const path = ReactEditor.findPath(editor, element);
      const before = beforeDisplayType === 'inline' ? Editor.before(editor, path) : path;
      Transforms.select(editor, before);
      Editor.deleteForward(editor, { unit: 'character' });
      if (beforeDisplayType === 'block' && displayType === 'inline') {
        Transforms.insertNodes(editor, { type: 'p', uid: v4().split('-').join(''), attrs: {}, children: [{ text: '' }] })
      }
    }
    if (type === 'link' && isEmbedLink(editor)) {
      // update embed link;
      setEntryInEditor(editor, singleSelectedRowId);
      updateLink(editor, state, singleSelectedRowId, contentTypeUid);
      return props.closeModal();
    }

    let selection = editor.selection || editor.savedSelection;
    if (!selection) {
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, []));
    }
    else if (singleSelectedRowId.key === '') {
      return props.closeModal();
    }

    selection = editor.selection || editor.savedSelection;

    const node = getReferenceNode({ type, displayType, contentTypeUid, entry: singleSelectedRowId.data, linkDetails: state.linkDetails })

    if (type === 'link') {
      Transforms.select(editor, selection);
      if (selection && Range.isCollapsed(selection)) {
        Transforms.insertNodes(editor, node, { at: selection });
      } else {
        Transforms.wrapNodes(editor, node, { split: true });
        Transforms.insertText(editor, state.linkDetails.text || '/undefined', { at: editor.selection });
      }
    }
    else {
      if (!Range.isCollapsed(selection)) {
        Transforms.select(editor, selection);
        Transforms.delete(editor, { at: selection });
        Transforms.insertNodes(editor, node);
      } else {
        Transforms.insertNodes(editor, node, { at: selection });
      }
    }
    Transforms.collapse(editor, { edge: 'end' })
    setEntryInEditor(editor, singleSelectedRowId);
    return props.closeModal();
  }

  const handleClose = () => {
    if (type !== 'link') return props.closeModal();
    const selection = editor.selection || editor.savedSelection;
    if (!selection) return;
    Transforms.unwrapNodes(editor, {
      at: selection,
      match: (node) => (node.type === 'reference' && node?.attrs?.['display-type'] === 'link')
    })
    return props.closeModal();
  }

  const isPropsValid = checkProps(props)
  let heightStyles = {};
  let tableHeight = type === "link" ? 250 : 400
  if (singleSelectedRowId.key !== "" && type !== "link") {
    heightStyles = {
      maxHeight: `249.02px`,
      overflow: "hidden"
    }
    tableHeight = 200
  }
  let customClass = type === "link" ? "scrte-reference-modal-link" : ""
  return (
    <Fragment>
      <ModalHeader title={props.title} closeModal={props.closeModal} />

      <ModalBody className={cx("modalBodyCustomClass", customizedPagination && 'no-count')}>
        {!isPropsValid ? (
          <InvalidPropsMsg asset="Reference" />
        ) : (
          <Fragment>
            <div className={styles['dropdown']}>
              <Select
                value={selectedContentType}
                onChange={handleMenuClick}
                options={contentTypeOptions}
                placeholder={loadingCt ? <span style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>Loading <AsyncLoader color="#647696" /></span> : 'Select Content Type'}
                canEditOption={false}
                width="200px"
              />

            </div>
            <div className={`scrte-infinite-list scrte-reference-modal ${customClass}`} style={{ ...heightStyles }} data-testid={"reference-modal"}>
              <InfiniteScrollTable
                itemSize={60}
                tableHeight={tableHeight || 400}
                canSearch={true}
                searchPlaceholder={'Search Entry'}
                serachValue={apiQuery}
                singleSelectedRowId={singleSelectedRowId.key}
                onRowClick={handleRowClick}
                data={entries}
                columns={tableHead}
                uniqueKey={'key'}
                fetchTableData={handleFetchTableData}
                loading={loading}
                totalCounts={totalEntries}
                loadMoreItems={loadMoreItems}
                itemStatusMap={itemStatusMap}
                minBatchSizeToFetch={entriesPerPage}
                name={{ singular: 'entry', plural: 'entries' }}
                columnSelector={false}
                emptyDescription={contentTypeUid === '' ? <p>Select a content type to choose entries from</p> : <p>No Entries available for this content type.</p>}
              />
            </div>
            {type === 'link' ? <EmbedLinkBody singleSelectedRowId={singleSelectedRowId} state={state} setState={setState} /> : <ReferenceBody singleSelectedRowId={singleSelectedRowId} displayType={displayType} handleDisplayType={handleDisplayType} />}
          </Fragment>
        )}
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button disabled={type !== 'link' ? false : !state.linkDetails['isActive']} buttonType={type === 'link' ? 'delete' : "outline"} icon={type === 'link' ? 'Delete' : ''} onClick={handleClose} testId={'embed-cancel'}>
            {type === 'link' ? <Fragment>Unlink</Fragment> : <Fragment>Cancel</Fragment>}
          </Button>
          <Button icon="CheckedWhite" onClick={handleEmbedSelect} disabled={singleSelectedRowId.key === ''} testId={'embed-submit'}>
            Embed Selected Entry
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Fragment>
  )
}

export const handleClick = (e, editor, props = { title: "Choose Entry", prefilled: false, type, attributes: {} }) => {
  if (e) e.preventDefault()
  cbModal({
    component: (modalProps) => <ModalComponent {...props} {...modalProps} editor={editor} />,
    modalProps: {
      size: 'max',
      shouldReturnFocusAfterClose: false,
      customClass: 'reference__modal scrte-reference__modal'
    }
  })
}

export const ShortcutOpenReferenceOverlay = (event, editor, format, registryObj, props = { title: "Choose Entry", prefilled: false, attributes: {} }) => {
  event.preventDefault()

  if (!(editor.selection || editor.savedSelection)) {
    return
  }

  handleClick(event, editor, props)
}

const ReferenceButton = (props) => {
  const editor = useSlateStatic();
  return <React.Fragment>{props.children({ handleClick: (e) => handleClick(e, editor, props) })}</React.Fragment>
}

export default ReferenceButton;
