import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'


import { stringOperators, comparisonOperator, basicOperators, allOperators } from './constants'
import { searchInStaticData, getLocalizedOptions, groupEntriesByCt } from './helpers'

import {
  getCtUserDefinedFields,
  inTextInput,
  inNumber,
  inBoolean,
  inFile,
  inSelect,
  inReference
} from './advanceQueryEntries'

export const suggestedSyntaxes = [
  { label: 'Text', value: 'textLabel', info: 'Search entries using any keyword', uid: '_text_entries' },
  { label: 'UID', value: 'UID', info: 'Search entries by UID', uid: 'uid' },
  { label: 'Content Type', value: 'Content Type', info: 'Search entries of a content type', uid: '_content_type_uid' },
  { label: 'Language', value: 'Language', info: 'Search entries of specific locale(s)', uid: 'locale' },
  { label: 'Published Environment', value: 'Published Environment', info: 'Search entries by the environment(s) they are published on', uid: '_publish_environment' },
  { label: 'Created By', value: 'Created By', info: 'Search entries created by a user', uid: 'created_by' },
  { label: 'Created At', value: 'Created At', info: 'Search entries by their creation date', uid: 'created_at' },
  { label: 'Tags', value: 'Tags', info: 'Search entries based on the tags applied', uid: 'tags' },
  { label: 'Published At', value: 'Published At', info: 'Search entries published at a specific time', uid: '_published_at' },
  { label: 'Published By', value: 'Published By', info: 'Search entries published by a user', uid: '_published_by' },
  { label: 'Updated At', value: 'Updated At', info: 'Search entries updated at a specific time', uid: 'updated_at' },
  { label: 'Updated By', value: 'Updated By', info: 'Search entries updated by a user', uid: 'updated_by' },
  { label: 'Workflow Stage', value: 'Workflow Stage', info: 'Search entries by their workflow stage', uid: '_workflow' },
]

export const suggestedSyntaxByUid = {
  _text_entries: {
    label: "Text",
    value: "textLabel",
    info: "Search entries using any keyword",
    uid: "_text_entries",
  },
  uid: {
    label: "UID",
    value: "UID",
    info: "Search entries by UID",
    uid: "uid",
  },
  _content_type_uid: {
    label: "Content Type",
    value: "Content Type",
    info: "Search entries of a content type",
    uid: "_content_type_uid",
  },
  locale: {
    label: "Language",
    value: "Language",
    info: "Search entries of specific locale(s)",
    uid: "locale",
  },
  _publish_environment: {
    label: "Published Environment",
    value: "Published Environment",
    info: "Search entries by the environment(s) they are published on",
    uid: "_publish_environment",
  },
  created_by: {
    label: "Created By",
    value: "Created By",
    info: "Search entries created by a user",
    uid: "created_by",
  },
  created_at: {
    label: "Created At",
    value: "Created At",
    info: "Search entries by their creation date",
    uid: "created_at",
  },
  tags: {
    label: "Tags",
    value: "Tags",
    info: "Search entries based on the tags applied",
    uid: "tags",
  },
  _published_at: {
    label: "Published At",
    value: "Published At",
    info: "Search entries published at a specific time",
    uid: "_published_at",
  },
  _published_by: {
    label: "Published By",
    value: "Published By",
    info: "Search entries published by a user",
    uid: "_published_by",
  },
  updated_at: {
    label: "Updated At",
    value: "Updated At",
    info: "Search entries updated at a specific time",
    uid: "updated_at",
  },
  updated_by: {
    label: "Updated By",
    value: "Updated By",
    info: "Search entries updated by a user",
    uid: "updated_by",
  },
  _workflow: {
    label: "Workflow Stage",
    value: "Workflow Stage",
    info: "Search entries by their workflow stage",
    uid: "_workflow",
  },
};



const inSyntax = {
  group: 'Matching Fields',
  queryType: 'inSyntax',
  nextQuery: 'inUser',
  type: 'key',
  options: [
    { label: 'Created By', value: 'created_by' },
    { label: 'Updated By', value: 'updated_by' },
    { label: 'Published By', value: '_published_by' }
  ]
}

const user = {
  group: '',
  queryType: 'inUser',
  type: 'value',
  options: []
}

const contentTypeLabel = {
  group: '',
  queryType: 'contentTypeLabel',
  nextQuery: 'inContentType',
  type: 'key',
  options: [{ label: 'Content Type', value: '_content_type_uid' }]
}

const inContentType = {
  // group: 'In Content Type',
  group: '',
  queryType: 'inContentType',
  type: 'value',
  options: []
}

export const languageLabel = {
  group: '',
  queryType: 'languageLabel',
  nextQuery: 'inLanguage',
  type: 'key',
  options: [{ label: 'Language', value: 'locale' }]
}

const inLanguage = {
  group: '',
  queryType: 'inLanguage',
  type: 'value',
  options: []
}

export const localizedInLabel = {
  group: '',
  queryType: 'localizedInLabel',
  nextQuery: 'inLocalized',
  type: 'key',
  options: [{ label: 'Localized In', value: '$localization' }]
}

const inLocalized = {
  group: '',
  queryType: 'inLocalized',
  type: 'value',
  options: []
}

const entriesTagLabel = {
  group: '',
  queryType: 'entriesTagLabel',
  nextQuery: 'inEntriesTag',
  type: 'key',
  options: [{ label: 'Tags', value: 'tags' }]
}

const inEntriesTag = {
  group: '',
  queryType: 'inEntriesTag',
  type: 'value',
  options: []
}

export const inEntries = {
  group: 'In Entries',
  queryType: 'inEntries',
  type: 'value',
  nextQuery: 'inEntries',
  options: []
}

export const textLabel = {
  group: '',
  queryType: 'textLabel',
  nextQuery: 'inText',
  type: 'key',
  options: [{ label: 'Text', value: '_text_entries' }]
}

export const uidLabel = {
  group: '',
  queryType: 'uidLabel',
  nextQuery: 'inText',
  type: 'key',
  options: [{ label: 'UID', value: 'uid' }]
}

const inText = {
  group: '',
  queryType: 'inText',
  type: 'value',
  options: []
}

export const dateLabel = {
  group: '',
  queryType: 'date',
  nextQuery: 'inDate',
  type: 'key',
  options: [
    { label: 'Created At', value: 'created_at' },
    { label: 'Updated At', value: 'updated_at' },
    { label: 'Published At', value: '_published_at' }
  ]
}

const environmentLabel = {
  group: '',
  queryType: 'environmentLabel',
  nextQuery: 'inEnvironment',
  type: 'key',
  options: [{ label: 'Published Environment', value: '_publish_environment' }]
}

const inEnvironment = {
  group: '',
  queryType: 'inEnvironment',
  type: 'value',
  options: []
}

export const workflowStageLabel = {
  group: '',
  queryType: 'workflowStageLabel',
  nextQuery: 'inWorkflowStage',
  type: 'key',
  options: [
    { label: 'Workflow Stage', value: '_workflow' },
  ]
}

const inWorkflowStage = {
  group: '',
  queryType: 'inWorkflowStage',
  type: 'value',
  options: []
}


const userLabel = {
  group: '',
  queryType: 'userLabel',
  nextQuery: 'inUser',
  type: 'key',
  options: [
    { label: 'Created By', value: 'created_by' },
    { label: 'Updated By', value: 'updated_by' },
    { label: 'Published By', value: '_published_by' }
  ]
}

const systemDefinedLabel = {
  group: 'SYSTEM-DEFINED FIELDS',
  options: []
}

const workflowLabel = {
  group: '',
  queryType: 'workflowLabel',
  nextQuery: 'inWorkflow',
  type: 'key',
  options: [
    // { label: 'Workflow Stage', value: '_workflow' },
  ]
}

const inWorkflow = {
  group: '',
  queryType: 'inWorkflow',
  type: 'value',
  options: []
}

const handleInitForAdvanceQuery = async (ct_status, inputValue, fetchSingleContentType, fetchWorkflowByCtUids) => {
  try {
    let staticQueryData = JSON.parse(
      JSON.stringify([
        contentTypeLabel,
        userLabel,
        environmentLabel,
        dateLabel,
        languageLabel,
        entriesTagLabel,
        uidLabel
      ])
    )

    const isCtObjectExist = ct_status && typeof ct_status === 'object'
    if (isCtObjectExist && ct_status.show_ct) {
      // console.log('show ct case', ct_status)
      staticQueryData = [...staticQueryData]
    } else if (isCtObjectExist && ct_status.isMullti) {
      staticQueryData = staticQueryData.slice(1)

      const ctUids = ct_status.ct_uid
      // console.log('ct_status.isMullti case', ctUids)
      const response = await fetchWorkflowByCtUids(ctUids)
      if (response && response.count) {
        const options = [{ label: 'Workflow Stage', value: '_workflow', valueOptions: response.data }]
        const workflow = { ...workflowLabel, options }
        staticQueryData = [...staticQueryData, workflow]
      }
    } else if (isCtObjectExist && !ct_status.isMullti && ct_status.ct_uid) {
      // const response = await fetchSingleContentType(ct_status.ct_uid)

      const ctUid = ct_status.ct_uid
      const [singleCtResponse, workflowResponse] = await Promise.all([
        fetchSingleContentType(ctUid),
        fetchWorkflowByCtUids([ctUid])
      ])

      staticQueryData = [userLabel, environmentLabel, dateLabel, languageLabel, entriesTagLabel, uidLabel]

      if (workflowResponse && workflowResponse.count) {
        const options = [{ label: 'Workflow Stage', value: '_workflow', valueOptions: workflowResponse.data }]
        const workflow = { ...workflowLabel, options }
        staticQueryData = [...staticQueryData, workflow]
      }

      if (singleCtResponse && singleCtResponse.content_type && singleCtResponse.content_type.schema) {
        const userDefinedFields = getCtUserDefinedFields(singleCtResponse.content_type.schema, [], '', '')
        userDefinedFields[0].group = 'USER-DEFINED FIELDS'
        staticQueryData = [...staticQueryData, ...userDefinedFields]
      }
    } else {
      console.log('==> contentype not seleted')
    }

    staticQueryData = [systemDefinedLabel, ...staticQueryData]

    let searchResponse = searchInStaticData(staticQueryData, inputValue)
    return searchResponse
  } catch (error) {
    console.log('handleInitForAdvanceQuery -> error', error)
    throw error
  }
}

const operatorsByQueryType = {
  date: allOperators.filter((op) => op.value != '$ne'),
  contentTypeLabel: stringOperators,
  advTextInputLabel: [stringOperators[1]],
  numberLabel: [...comparisonOperator, basicOperators[1]],
  linkLabel: stringOperators,
  textInputLabel: stringOperators
}

export const suggestForEntries = async (data) => {
  // console.log('==> suggestForEntries', data)
  try {
    let {
      queryType,
      fetchData,
      queryCase,
      inputValue,
      skip,
      limit,
      ct_status,
      selectedOption,
      currentSearch,
      action
    } = data
    let queryData: any = []
    let withOperator = false
    let isQueryCompleted = false
    let count = 0
    let defaultOperator: any = ''
    //required here to set for edit case
    let operators = operatorsByQueryType[queryType] || basicOperators

    const { value = [] } = currentSearch || {}
    const selectedInputValue = action === 'edit' && value[0].value
    switch (queryType) {
      case 'init': {
        if (queryCase === 'basicQuery') {
          inEntries.options = [{ label: inputValue, value: inputValue, type: 'text' }]
          count = 1

          let staticQueryData = [
            inSyntax,
            dateLabel,
            contentTypeLabel,
            environmentLabel,
            languageLabel,
            entriesTagLabel,
            workflowStageLabel,
            uidLabel
          ]

          if (inputValue.trim() === '') {
            queryData = [...staticQueryData]
            count = count + staticQueryData.length
          } else {
            let searchResponse = searchInStaticData(staticQueryData, inputValue)
            //note textLabel will be always there in suggetsionData but not rendering in ui
            queryData = [inEntries, ...searchResponse.data, textLabel]
            count = count + searchResponse.count
          }

        }
        if (queryCase === 'advanceQuery') {
          const searchResponse = await handleInitForAdvanceQuery(
            ct_status,
            inputValue,
            fetchData.fetchSingleContentType,
            fetchData.fetchWorkflowByCtUids
          )
          queryData = searchResponse.data
          count = searchResponse.count
        }
        break
      }

      case 'textLabel': {
        if (action === 'edit') {
          inText.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inText.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        queryData = [inText]
        count = 1
        defaultOperator = stringOperators[1]
        break
      }

      /**
       * in case of inEntries, queryType and nextqery is same so
       * handling specially as a case identified  by action 
       **/
      case 'inEntries': {
        if (action === 'create') {
          isQueryCompleted = true
        } else if (action === 'edit') {
          inEntries.options = [{ label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }]
          queryData = [inEntries]
          count = 1
          isQueryCompleted = true
        }
        break
      }

      case 'uidLabel': {
        if (action === 'edit') {
          inText.options = [
            { label: inputValue || selectedInputValue, value: inputValue || selectedInputValue, type: 'text' }
          ]
        } else {
          inText.options = [{ label: inputValue || `" "`, value: inputValue || `" "`, type: 'text' }]
        }
        queryData = [inText]
        count = 1
        withOperator = true
        break
      }

      case 'advTextInputLabel': {
        if (action === 'edit') {
          inTextInput.options = [{ label: inputValue || selectedInputValue, value: inputValue || selectedInputValue }]
        } else {
          inTextInput.options = [{ label: inputValue || `" "`, value: inputValue || `" "` }]
        }

        queryData = [inTextInput]
        count = 1
        withOperator = true
        defaultOperator = stringOperators[1]
        break
      }

      case 'linkLabel':
      case 'textInputLabel': {
        if (action === 'edit') {
          inTextInput.options = [{ label: inputValue || selectedInputValue, value: inputValue || selectedInputValue }]
        } else {
          inTextInput.options = [{ label: inputValue || `" "`, value: inputValue || `" "` }]
        }
        // inTextInput.options = [{ label: inputValue || `" "`, value: inputValue || `" "` }]
        queryData = [inTextInput]
        count = 1
        withOperator = true
        break
      }

      case 'numberLabel': {
        if (action === 'edit') {
          inNumber.options = [{ label: inputValue || selectedInputValue, value: inputValue || selectedInputValue }]
        } else {
          inNumber.options = [{ label: inputValue || `" "`, value: inputValue || `" "` }]
        }
        queryData = [inNumber]
        count = 1
        withOperator = true
        break
      }

      case 'selectLabel': {
        let allDropdownOptions = selectedOption.valueOptions

        if (inputValue) {
          allDropdownOptions = allDropdownOptions.filter((option) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase())
          })
        }
        inSelect.options = allDropdownOptions
        queryData = [inSelect]
        // console.log('selectLabel queryData', queryData)
        withOperator = true
        count = selectedOption.valueOptions.length
        break
      }

      case 'booleanLabel': {
        queryData = [inBoolean]
        count = 2
        withOperator = true
        break
      }

      case 'fileLabel': {
        const response: any = await fetchData.fetchAssets({ skip, limit, inputValue })
        inFile.options = response.data
        count = response.count
        queryData = [inFile]
        withOperator = true
        break
      }

      case 'referenceLabel': {
        const valueOptions = selectedOption.valueOptions
        const query = { _content_type_uid: { $in: valueOptions } }
        const response: any = await fetchData.fetchEntries({ inputValue, query, skip, limit })
        const data = groupEntriesByCt(response)
        queryData = [...data]
        count = response.count
        withOperator = true
        break
      }

      case 'workflowLabel': {
        inWorkflow.options = selectedOption.valueOptions
        queryData = [inWorkflow]
        withOperator = true
        count = selectedOption.valueOptions.length
        break
      }

      case 'contentTypeLabel': {
        const response: any = await fetchData.fetchContentTypes({
          skip,
          limit,
          inputValue
        })
        if (inputValue) {
          const freeText = { label: inputValue, value: inputValue, type: 'text' }
          inContentType.options = [freeText, ...response.data]
          count = response.count + 1
        } else {
          inContentType.options = response.data
          count = response.count
        }
        queryData = [inContentType]
        withOperator = true
        break
      }

      case 'environmentLabel': {
        const response: any = await fetchData.fetchEnvironments({ skip, limit, inputValue })
        inEnvironment.options = response.data
        count = response.count
        queryData = [inEnvironment]
        withOperator = true
        break
      }

      case 'localizedInLabel': {
        const response: any = await fetchData.fetchLanguages({})
        const localizedOptions = getLocalizedOptions({
          languages: response.data,
          selectedLanguageValue: currentSearch.selectedLanguage
        })
        inLocalized.options = localizedOptions

        queryData = [inLocalized]
        withOperator = true
        count = localizedOptions.length
        break
      }

      case 'languageLabel': {
        const response: any = await fetchData.fetchLanguages({ skip, limit, inputValue })
        inLanguage.options = response.data
        count = response.count
        queryData = [inLanguage]
        withOperator = true
        break
      }

      case 'entriesTagLabel': {
        const response: any = await fetchData.fetchEntriesTags({ skip, limit })
        let filteredTags = response.data
        //searching tag here as backend does not support searching for tag
        // if (inputValue) {
        //   filteredTags = filteredTags.filter((tag) => {
        //     return tag.label.toLowerCase().includes(inputValue.toLowerCase())
        //   })
        // }
        // filteredTags = sortBy(filteredTags, (tag) => tag.label)
        inEntriesTag.options = filteredTags
        count = response.count
        queryData = [inEntriesTag]
        withOperator = true
        break
      }

      case 'userLabel':
      case 'inSyntax': {
        const response: any = await fetchData.fetchUsers({ skip, limit, inputValue })
        user.options = response.data
        count = response.count
        queryData = [user]
        withOperator = true
        break
      }

      case 'workflowStageLabel': {
        const response: any = await fetchData.fetchWorkflowStage({ inputValue })

        let filteredStages = response.data
        if (inputValue) {
          filteredStages = filteredStages.filter((stage) => {
            return stage.label.toLowerCase().includes(inputValue.toLowerCase())
          })
        }

        if (filteredStages.length) {
          let groupedObj = groupBy(filteredStages, (data) => {
            return [data.workflowName]
          })
          let data = []
          Object.keys(groupedObj).forEach((k) => {
            const valueArray = groupedObj[k]
            data.push({ ...inWorkflowStage, group: valueArray[0].workflowName, options: valueArray })
          })
          queryData = [...data]
          count = filteredStages.length
        } else {
          queryData = []
          count = 0
        }

        withOperator = true
        break
      }

      case 'date': {
        count = 0
        // operators = comparisonOperator
        defaultOperator = allOperators.find((op) => op.value === '$eq')
        withOperator = true
        break
      }

      case 'inWorkflowStage':
      case 'inLocalized':
      case 'inWorkflow':
      case 'inReference':
      case 'inFile':
      case 'inBoolean':
      case 'inNumber':
      case 'inSelect':
      case 'inTextInput':
      case 'inText':
      case 'inLanguage':
      case 'inContentType':
      case 'inUser':
      case 'inDate':
      case 'inEnvironment':
      case 'inEntriesTag': {
        isQueryCompleted = true
        break
      }
    }

    if (defaultOperator && withOperator) {
      operators = operators.filter((operator) => operator.value !== defaultOperator.value)
      operators.unshift(defaultOperator)
    }

    return { withOperator, queryData, isQueryCompleted, count, operators, defaultOperator }
  } catch (error) {
    console.log('suggestForEntries', error)
    throw error
  }
}

// const t = {
//   $and: [
//     { created_by: { $eq: 'blt1de032691636ef76' } },
//     {
//       $or: [
//         { updated_by: { $eq: 'blt1de032691636ef76' } },
//         { _published_by: { $eq: 'blt1de032691636ef76' } }
//       ]
//     },
//     { $and: [{ tags: { $in: ['data'] } }, { tags: { $in: ['abc'] } }] }
//   ],
//   _content_type_uid: '$all'
// }

// export const advanceQueryArray = [
//   {
//     queryType: 'matchAll',
//     root: true,

//     cursorY: 0,
//     queryArray: [
//       {
//         key: [
//           {
//             label: 'Content type',
//             value: 'contenttype'
//           }
//         ],
//         operator: [
//           {
//             name: 'Equals',
//             icon: 'Equals',
//             value: 'equals'
//           }
//         ],
//         value: [
//           {
//             label: 'content type--012345',
//             value: 'content type--123456'
//           }
//         ],
//         isQueryCompleted: true,
//         queryType: 'contentTypeLabel',
//         nextQuery: 'inContentType'
//       }
//     ],
//     children: [
//       {
//         queryType: 'matchAny',

//         cursorY: 1,
//         children: [
//           {
//             queryType: 'matchAll',

//             cursorY: 2,
//             queryCursorY: 3,

//             queryArray: [
//               {
//                 key: [
//                   {
//                     label: 'Content type',
//                     value: 'contenttype'
//                   }
//                 ],
//                 operator: [
//                   {
//                     name: 'Equals',
//                     icon: 'Equals',
//                     value: 'equals'
//                   }
//                 ],
//                 value: [
//                   {
//                     label: 'content type1',
//                     value: 'content type1'
//                   }
//                 ],
//                 isQueryCompleted: true,
//                 queryType: 'contentTypeLabel',
//                 nextQuery: 'inContentType'
//               }
//             ],
//             children: []
//           },
//           {
//             queryType: 'matchAll',

//             cursorY: 4,
//             queryCursorY: 5,

//             queryArray: [
//               {
//                 key: [
//                   {
//                     label: 'Content type',
//                     value: 'contenttype'
//                   }
//                 ],
//                 operator: [
//                   {
//                     name: 'Equals',
//                     icon: 'Equals',
//                     value: 'equals'
//                   }
//                 ],
//                 value: [
//                   {
//                     label: 'content type2',
//                     value: 'content type2'
//                   }
//                 ],
//                 isQueryCompleted: true,
//                 queryType: 'contentTypeLabel',
//                 nextQuery: 'inContentType'
//               }
//             ],
//             children: []
//           }
//         ],
//         queryArray: [
//           {
//             key: [
//               {
//                 label: 'Content type',
//                 value: 'contenttype'
//               }
//             ],
//             operator: [
//               {
//                 name: 'Equals',
//                 icon: 'Equals',
//                 value: 'equals'
//               }
//             ],
//             value: [
//               {
//                 label: 'content type--0',
//                 value: 'content type--0'
//               }
//             ],
//             isQueryCompleted: true,
//             queryType: 'contentTypeLabel',
//             nextQuery: 'inContentType'
//           }
//         ]
//       },
//       {
//         queryType: 'matchAny',

//         cursorY: 6,
//         queryCursorY: 7,

//         queryArray: [
//           {
//             key: [
//               {
//                 label: 'Created by',
//                 value: 'createdby'
//               }
//             ],
//             operator: [
//               {
//                 name: 'Equals',
//                 icon: 'Equals',
//                 value: 'equals'
//               }
//             ],
//             value: [
//               {
//                 label: 'user1',
//                 value: 'user1'
//               }
//             ],
//             isQueryCompleted: true,
//             queryType: 'inSyntax',
//             nextQuery: 'inUser'
//           }
//         ],
//         children: []
//       }
//     ]
//   }
// ]
