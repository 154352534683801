import { Registry } from '@react-registry'

export const gridReferenceDeserializer = (el: HTMLElement) => {
  return {
    type: 'column',
    meta: {
      width: el.getAttribute("width")
    }
  }
}

Registry.register(gridReferenceDeserializer, {
  id: 'column',
  registry: 'deserializer'
})
