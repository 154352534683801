import React from 'react'

interface Props {
  className?: string
  children?: React.ReactNode | Element
}

const OAuthCardFooter: React.FC<Props> = ({ className = '', children }: React.PropsWithChildren<Props>) => {
  return <div className={`flex-right OAuth_Card_Footer ${className}`}>{children}</div>
}

export default React.memo(OAuthCardFooter)
