import { Registry } from '@react-registry'
import { getAttributesObject } from '../../../../../utils'

export const assetReferenceDeserializer = (el) => {
  return {
    type: 'reference',
    attrs: getAttributesObject(el),
    children: [{ text: '' }]
  }
}

Registry.register(assetReferenceDeserializer, {
  id: 'asset',
  registry: 'v2.deserializer'
})
