import React from 'react'
import Tippy from '@tippyjs/react'
//@ts-ignore
import styles from './style.module.css'

export default (props) => {
    const config = {
        placement: 'bottom',
        arrow: false,
        offset: [0, 0],
        delay: [300, 0],
        duration: [0, 0],
        hideOnClick: true,
        theme: 'small',
        ...props.config
    }
    return (
        <Tippy content={props.content} {...config} className={styles['scrte-tooltip']}>
            {props.children}
        </Tippy>
    )
}
