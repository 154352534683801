import React, { useState, useEffect } from 'react';

import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup/ButtonGroup'
import cbModal, { ModalHeader, ModalFooter, ModalBody } from '../Modal/Modal';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import Tooltip from '../Tooltip/Tooltip';

import './ImportFile.css'
import Icon from '../Icon2';

export type importFileProps = {
  details?: React.ReactNode | string
  getFile: Function
  cancelImport?: Function
  label: string
  asIcon?: boolean
  type?: 'light' | 'secondary'
  importTooltipText?: string
}

type importModalProps = {
  closeModal: Function
  cancelImport?: Function
  details?: React.ReactNode | string | undefined
  getFile: Function
  label: string
};

export const ImportModal = (props: importModalProps) => {
  const [filename, setFilename] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoader] = useState(false)

  const handleImport = async () => {
    let doc: any = document;
    if (doc.getElementsByName('fileUpload')[0] && doc.getElementsByName('fileUpload')[0].files && doc.getElementsByName('fileUpload')[0].files[0]) {
      setFilename(doc.getElementsByName('fileUpload')[0].files[0].filename);
      setError(false);
      setLoader(true)

      await props.getFile(doc.getElementsByName('fileUpload')[0].files[0]);
      props.closeModal()
    } else {
      setLoader(false)
      setError(true);
    }
  };

  useEffect(() => {
    document.getElementById('import-input').addEventListener('change', function (e: any) {
      setFilename(e.target.files[0].name);
      setError(false);
    });
  })


  return (
    <>
      <ModalHeader title={`Import ${props.label}`} closeModal={props.closeModal} />
      <ModalBody>
        <span>{props.details}</span>
        <div>
        </div>
        <label className="file-upload">
          <input type="file" name="fileUpload" id="import-input" />
          <div className="flex-v-center">
            <div className="upload-box" aria-label="Choose File to Import">Choose a file</div>
            <div className="import-filename">{filename}</div>
          </div>
        </label>
        {error ? <div className="mt-10"><ValidationMessage>Please select file to import {props.label} </ValidationMessage></div> : null}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button aria-label="Cancel" testId="cs-import-file-cancel" buttonType="light" onClick={() => props.cancelImport()}>
            Cancel
          </Button>
          <Button aria-label="Import File" testId="cs-import-file-import" buttonType="primary" icon="ImportWhite" onClick={handleImport} isLoading={loading}>
            Import
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

const ImportFile = (props: importFileProps) => {
  const label = props.label;
  const details = props.details;
  const asIcon = props.asIcon ? props.asIcon : false;
  const type = props.type ? props.type : 'light';
  const importTooltipText = props.importTooltipText ? props.importTooltipText : 'Import';

  const getFile = (file: any) => {
    props.getFile(file);
  };

  const cancelImport = (modalProps) => {
    modalProps.closeModal()
    if (props.cancelImport) {
      props.cancelImport()
    }
  }

  const handleClick = (event: any) => {
    event.stopPropagation();
    cbModal({
      component: props => (
        <ImportModal
          getFile={getFile}
          label={label}
          closeModal={props.closeModal}
          cancelImport={() => cancelImport(props)}
          details={details}
        />
      ),
      modalProps: {},
    });
  };

  return (
    <>
      {!asIcon ? (
        type === "secondary" ?
          <Button aria-label="Import File" buttonType="secondary" onClick={handleClick} icon="ImportActive">
            <span>Import</span>
          </Button> :
          <Button aria-label="Import File" buttonType="light" onClick={handleClick}>
            <span>Import</span>
          </Button>
      )
        :
        <Tooltip content={importTooltipText} position="left">
          <Icon icon="Import" className="Import__icon" onClick={handleClick} />
        </Tooltip>
      }
    </>
  );
};

export default ImportFile;
