import React, { useState } from 'react'
import { cx, css } from '@emotion/css'
//@ts-ignore
import styles from './switch.module.css'

export const Switch = (props) => {
  const { disabled = false, onChange: propsOnChange = () => { }, checked: propsChecked = false } = props

  const [checked, setOnChange] = useState(propsChecked)

  function handleMouseDown(event: React.MouseEvent) {
    event.preventDefault()
    if (disabled) return
    setOnChange((prevProps) => !prevProps)
    propsOnChange(event)
  }

  return (
    <div
      data-testid="switch-toggle"
      className={cx(
        styles['toggle-btn'],
        { [styles['active']]: !disabled && checked },
        { [styles['disabled']]: disabled }
      )}
      onMouseDown={handleMouseDown}>
      <span className={styles['round-btn']}></span>
    </div>
  )
}
